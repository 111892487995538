import React from 'react';
import DinnerIcon from '../images/icons/dinner.svg?react';
import GuestRoomIcon from '../images/icons/guest_room.svg?react';
import LunchIcon from '../images/icons/lunch.svg?react';
import ProjectorIcon from '../images/icons/projector.svg?react';
import SpaceIcon from '../images/icons/space.svg?react';

const FEATURES_TO_ICONS = {
    av: ProjectorIcon,
    catering: LunchIcon,
    waterfront: SpaceIcon,
};
const SPACE_TYPES_TO_ICONS: { [id: number]: React.FunctionComponent } = {
    1: DinnerIcon,
    2: SpaceIcon,
    3: GuestRoomIcon,
    4: SpaceIcon,
    5: SpaceIcon,
    6: DinnerIcon,
};
const FEATURES_TO_LABELS = {
    av: 'Audio / Visual',
    catering: 'Food & Beverage',
    waterfront: 'Waterfront',
};

export const getVenueFeatures: (venue: Bizly.Venue) => {
    id: number | string;
    icon: React.FunctionComponent<{ style: any }>;
    label: string;
}[] = (venue: Bizly.Venue) => [
    ...(venue.av
        ? [
              {
                  id: 'av',
                  icon: FEATURES_TO_ICONS.av,
                  label: FEATURES_TO_LABELS.av,
              },
          ]
        : []),
    ...(venue.catering
        ? [
              {
                  id: 'catering',
                  icon: FEATURES_TO_ICONS.catering,
                  label: FEATURES_TO_LABELS.catering,
              },
          ]
        : []),
    ...(venue.spaceTypes && venue.spaceTypes.length > 0
        ? venue.spaceTypes.map((s: { id: number; name: string }) => ({
              id: s.id,
              icon: SPACE_TYPES_TO_ICONS[s.id],
              label: s.name,
          }))
        : []),
    ...(venue.waterfront
        ? [
              {
                  id: 'waterfront',
                  icon: FEATURES_TO_ICONS.waterfront,
                  label: FEATURES_TO_LABELS.waterfront,
              },
          ]
        : []),
];
