import { addVenueToInquiryDraft } from 'api';
import { useSnackbar } from 'notistack';
import VenueListingV2 from 'pages/VenueListingV2';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { currentInquiryActions } from 'stores/current-inquiry';
import { i18n } from 'translation';

export default function VenueDetails() {
    const { venueId: paramVenueId } = useParams<{ venueId: string }>();
    const venueId = useMemo(() => paramVenueId ?? '', [paramVenueId]);

    const { enqueueSnackbar } = useSnackbar();
    const onCreateEvent = useCallback(
        async (eventId: number, venue: Bizly.Venue) => {
            const currentInquiry = await currentInquiryActions.load(eventId);

            try {
                const { inquiry } = await addVenueToInquiryDraft(eventId, {
                    inquiryId: currentInquiry?.id ?? undefined,
                    venueId: parseInt(venueId),
                });

                currentInquiryActions.setInquiry(eventId, inquiry);
                currentInquiryActions.mergeVenues(eventId, [{ ...venue, venue: venue, status: 'Added' }]);
            } catch {
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            }
        },
        [venueId, enqueueSnackbar]
    );

    return <VenueListingV2 onCreateEvent={onCreateEvent} />;
}
