import createStore from 'stores';

type State = {
    isBannerVisible: boolean;
};

type Store = State;

const initialState: State = {
    isBannerVisible: true,
};

export const useBannerOptions = createStore<Store>(() => initialState);

const { setState, getState } = useBannerOptions;

const LOCAL_STORAGE_KEY = 'dashboardBanner';

const isState = (parsed: object): parsed is State => {
    const stateKeys = new Set(Object.keys(initialState));

    return Object.keys(parsed).some(key => stateKeys.has(key));
};

export const bannerActions = {
    load: () => {
        const loadedBannerOptions = localStorage.getItem(LOCAL_STORAGE_KEY);
        const parsedBannerOptions = loadedBannerOptions && JSON.parse(loadedBannerOptions);
        if (parsedBannerOptions && isState(parsedBannerOptions)) {
            setState(parsedBannerOptions);
        }
    },

    setBannerVisibility: (isVisible: boolean) => {
        setState({ isBannerVisible: isVisible });
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(getState()));
    },
};
