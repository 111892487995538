import { styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio, { RadioProps } from '@mui/material/Radio';

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .MuiRadio-root': {
        padding: theme.spacing(1),
    },
}));

type LabeledRadioProps = {
    disabled: boolean;
    value: unknown;
    label: React.ReactNode;
    className?: string;
} & RadioProps;

// CHECKBOX
const LabeledRadio = ({ disabled, value, label, className, ...rest }: LabeledRadioProps) => (
    <StyledLabel
        className={className}
        label={label}
        control={<Radio {...rest} />}
        value={value}
        disabled={disabled}
    />
);

export default LabeledRadio;
