import { Dialog, DialogContent, Divider, Tooltip } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { getVenueProposal, loadEvent } from 'api';
import { budgetRequestAction } from 'api/inquiry';
import ConfirmationModal from 'components/ConfirmationModal';
import Form from 'components/Form';
import { FormTextFieldBorders } from 'components/FormFields';
import { SpinnerOverlay } from 'components/Spinner';
import Button from 'components/ui/Button';
import TextButton from 'components/ui/Button/TextButton';
import { H3Headline } from 'components/ui/Headline';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, ExternalLink, LowercaseCopy, Row, Spacer } from 'ui';
import { tzMoment } from 'utils/moment';
import { formatCurrency } from '../../utils';

const LargeDialogContent = styled(DialogContent)`
    width: 40rem;
    padding: 2rem;
`;

const HeadlineBold = styled(H3Headline)`
    font-weight: 600;
`;

const SetColumnWidth = styled(Column)`
    width: 18rem;
    overflow: hidden;

    p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const ColumnWithRightBorder = styled(Column)`
    width: 12.5rem;
    ${({ theme: { getColor, EColors } }) =>
        `
        border-right: 1px solid ${getColor(EColors.grey, 0.7)};
        `}
    margin-right: 1rem;
`;

export const FormTextField = styled(MuiTextField)`
    ${FormTextFieldBorders}

    background: ${({
        disabled,
        theme: {
            getColor,
            EColors: { disabledAction, pureWhite },
        },
    }) => getColor(disabled ? disabledAction : pureWhite)};
    border-radius: 3px;
`;

const formFields = {
    notes: {
        type: 'textarea',
        options: {
            disabled: true,
        },
    },
};

const formSchema = [
    {
        key: 'notes',
        fields: ['notes'],
        spacing: 'medium',
    },
];

const budgetRequestActionFields = {
    approvedAmount: {
        type: 'text',
        options: { numberType: 'currency', currency: 'USD' },
    },
};

const budgetRequestActionSchema = [
    {
        key: 'approvedAmount',
        fields: ['approvedAmount'],
        spacing: 'medium',
    },
];

type ItemState = {
    proposalId: number;
    budgetRequestId: number;
    meetingName: string;
    location?: string;
    startDate?: string;
    endDate?: string;
    globalBudget?: string;
    eventBudget?: number;
    meetingLink: string;
    venueName: string;
    onHoldUntil: string;
    totalCost: string;
    meetingType?: string;
    proposalLink: string;
    notes: string;
};

const ActionItemsModal = ({
    onClose,
    actionTableItem,
    setActionTableItems,
}: {
    onClose: () => void;
    actionTableItem: BizlyAPI.BudgetApproval;
    setActionTableItems: Dispatch<React.SetStateAction<BizlyAPI.BudgetApproval[]>>;
}) => {
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const DEFAULT_AMOUNT = 0;

    const [items, setItems] = useState<ItemState>({} as ItemState);
    const [value, setValue] = useState<{ notes?: string; approvedAmount?: number }>({
        notes: '',
        approvedAmount: DEFAULT_AMOUNT,
    });
    const [rejectReason, setRejectReason] = useState<string>('');
    const PROPOSAL_BUDGET = Number(items.eventBudget || items.globalBudget);

    const { modalShown, showModal, hideModal } = useShowModal();

    const handleBudgetRejection = async () => {
        if (!rejectReason) {
            enqueueSnackbar(i18n.homepage.actionItemsModal.rejectReasonValidation, { variant: 'error' });
            return;
        }

        hideModal();
        const { success, budgetApprovalRequest } = await budgetRequestAction({
            budgetRequestId: items.budgetRequestId,
            proposalId: items.proposalId,
            actionType: 'reject',
            reason: rejectReason ?? '',
        });
        if (success) {
            setActionTableItems((prev: BizlyAPI.BudgetApproval[]) => {
                return prev.filter((item: BizlyAPI.BudgetApproval) => item.id !== budgetApprovalRequest.id);
            });
            onClose();
            enqueueSnackbar(i18n.homepage.actionItemsModal.rejectedProposalRequest, { variant: 'success' });
        } else {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    };

    const isValidBudgetAmount = () => {
        const isApprovedBudgetLessThanOrEqualToProposalTotal =
            value.approvedAmount && value.approvedAmount <= Number(items.totalCost);

        if (isApprovedBudgetLessThanOrEqualToProposalTotal) {
            enqueueSnackbar(i18n.homepage.actionItemsModal.budgetValidation, { variant: 'error' });
            return false;
        }

        return true;
    };

    const handleBudgetApproval = async () => {
        if (!isValidBudgetAmount()) return;

        const { success, budgetApprovalRequest } = await budgetRequestAction({
            budgetRequestId: items.budgetRequestId,
            proposalId: items.proposalId,
            actionType: 'approve',
            approvedAmount: value.approvedAmount,
        });

        if (success) {
            setActionTableItems((prev: BizlyAPI.BudgetApproval[]) => {
                return prev.filter((item: BizlyAPI.BudgetApproval) => item.id !== budgetApprovalRequest.id);
            });
            onClose();
            enqueueSnackbar(i18n.homepage.actionItemsModal.approvedProposalRequest, { variant: 'success' });
        }
    };

    useEffect(() => {
        const load = async () => {
            try {
                const { proposal } = await getVenueProposal(actionTableItem.proposal.id);
                const event = await loadEvent(actionTableItem.event.id);

                const url = new URL(window.location.origin);

                setItems({
                    proposalId: proposal.id,
                    budgetRequestId: proposal.approvalRequests[0].id,
                    meetingName: event.name,
                    location: event.location,
                    startDate: event.startsAt,
                    endDate: event.endsAt,
                    globalBudget: event.budget,
                    eventBudget: user.team?.maximumBudget,
                    meetingLink: `${url}event/${event.id}`,
                    venueName: proposal.venue.name,
                    onHoldUntil: proposal.onHoldUntil,
                    totalCost: proposal.estimatedTotalCost,
                    meetingType: event.type,
                    proposalLink: `${url}event/${event.id}/venue/proposal/${proposal.id}`,
                    notes: proposal.approvalRequests[0].notes,
                });

                setValue({
                    notes: i18n.homepage.actionItemsModal.notes(event.name, proposal.approvalRequests[0].notes ?? ''),
                });
            } catch (error) {
                onClose();
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
                return;
            }
        };

        load();
    }, [actionTableItem.proposal.id, actionTableItem.event.id, enqueueSnackbar, onClose, user?.team?.maximumBudget]);

    const ParagraphWithTooltip = ({ text, externalLink }: { text?: string; externalLink?: string }) => {
        const textRef = useRef<HTMLParagraphElement>(null); // Provide type assertion for textRef
        const [isOverflowing, setIsOverflowing] = useState(false);

        useEffect(() => {
            if (textRef.current) {
                setIsOverflowing(textRef.current.offsetWidth < textRef.current.scrollWidth);
            }
        }, [text]);

        const content = externalLink ? (
            <ExternalLink openInNewTab href={externalLink}>
                {text}
            </ExternalLink>
        ) : (
            <span>{text}</span>
        );

        return (
            <Tooltip title={isOverflowing && text ? text : ''} placement="top-start">
                <p ref={textRef}>{content}</p>
            </Tooltip>
        );
    };

    return !Object.entries(items).length ? (
        <SpinnerOverlay />
    ) : (
        <Dialog open maxWidth="md" onBackdropClick={onClose}>
            <LargeDialogContent>
                <Spacer small />
                <HeadlineBold>{i18n.proposalForm.header.meetingDetails}</HeadlineBold>
                <Spacer small />

                <Row justifyContent="space-between">
                    <SetColumnWidth>
                        <p>{i18n.meetingsPage.meetingName}</p>
                        <p>{i18n.homepage.createMeetingModal.location}</p>
                        <p>{i18n.datetime.startDate}</p>
                        <p>{i18n.datetime.endDate}</p>
                        <p>{i18n.homepage.actionItemsModal.meetDetails.meetingBudget}</p>
                        <p>{i18n.homepage.actionItemsModal.meetDetails.linkToMeeting}</p>
                    </SetColumnWidth>

                    <SetColumnWidth>
                        <ParagraphWithTooltip text={items.meetingName} />
                        <ParagraphWithTooltip text={items.location} />
                        <ParagraphWithTooltip text={tzMoment(items.startDate).format('ll')} />
                        <ParagraphWithTooltip text={tzMoment(items.endDate).format('ll')} />
                        <ParagraphWithTooltip text={formatCurrency(Number(items.globalBudget), 'USD')} />
                        <ParagraphWithTooltip text={items.meetingLink} externalLink={items.meetingLink} />
                    </SetColumnWidth>
                </Row>

                <Spacer medium />

                <Divider />

                <Spacer medium />

                <HeadlineBold>{i18n.homepage.actionItemsModal.proposalDetails.title}</HeadlineBold>
                <Spacer small />

                <Row justifyContent="space-between">
                    <SetColumnWidth>
                        <p>{i18n.homepage.actionItemsModal.proposalDetails.venueName}</p>
                        <p>{i18n.homepage.actionItemsModal.proposalDetails.roomHeldUntil}</p>
                        <p>{i18n.homepage.actionItemsModal.proposalDetails.proposalTotal}</p>
                        <p>{i18n.homepage.actionItemsModal.proposalDetails.meetingType}</p>
                        <p>{i18n.homepage.actionItemsModal.proposalDetails.linkToProposal}</p>
                    </SetColumnWidth>

                    <SetColumnWidth>
                        <ParagraphWithTooltip text={items.venueName} />
                        <ParagraphWithTooltip text={tzMoment(items.onHoldUntil).format('ll')} />
                        <ParagraphWithTooltip text={formatCurrency(Number(items.totalCost), 'USD')} />
                        <ParagraphWithTooltip text={items.meetingType} />
                        <ParagraphWithTooltip text={items.proposalLink} externalLink={items.proposalLink} />
                    </SetColumnWidth>
                </Row>

                <Spacer medium />

                <Divider />

                <Spacer medium />

                <Column itemSpacing="small">
                    <HeadlineBold>{i18n.homepage.actionItemsModal.reasonForBudgetApproval}</HeadlineBold>
                    <Form fields={formFields} schema={formSchema} value={value} onChange={() => {}} />
                </Column>

                <Divider />

                <Spacer medium />

                <Row>
                    <ColumnWithRightBorder>
                        <HeadlineBold>{i18n.homepage.actionItemsModal.currentBudget}</HeadlineBold>

                        <p style={{ marginTop: '1.5rem' }}>{formatCurrency(PROPOSAL_BUDGET, 'USD')}</p>
                    </ColumnWithRightBorder>

                    <Column>
                        <HeadlineBold style={{ textAlign: 'center' }}>
                            {i18n.homepage.actionItemsModal.actionRequired}
                        </HeadlineBold>

                        <Row
                            style={{
                                alignItems: 'baseline',
                                marginTop: '1rem',
                            }}
                        >
                            <Button onClick={showModal} warning>
                                {i18n.homepage.actionItemsModal.reject}
                            </Button>

                            <Spacer />

                            <Form
                                fields={budgetRequestActionFields}
                                schema={budgetRequestActionSchema}
                                value={value}
                                onChange={({ value }: { value: { approvedAmount: number } }) =>
                                    setValue(prev => ({ ...prev, ...value }))
                                }
                            />

                            <Spacer />

                            <Button onClick={handleBudgetApproval} disabled={!value.approvedAmount}>
                                {i18n.homepage.actionItemsModal.approve}
                            </Button>
                        </Row>
                    </Column>
                </Row>
                <Row>
                    <Copy>{i18n.homepage.actionItemsModal.totalAmountNote}</Copy>
                </Row>
            </LargeDialogContent>
            <ConfirmationModal
                isActive={modalShown}
                onDismiss={hideModal}
                headline={i18n.communication.confirmation}
                prompt={i18n.homepage.actionItemsModal.rejectPrompt}
            >
                <Copy small>
                    {i18n.homepage.actionItemsModal.rejectReasonLabel}&nbsp;
                    <LowercaseCopy as="span">({i18n.common.required})</LowercaseCopy>
                </Copy>
                <FormTextField
                    variant="outlined"
                    multiline
                    rows={4}
                    rowsMax={8}
                    placeholder={i18n.homepage.actionItemsModal.reasonPlaceholder}
                    value={rejectReason}
                    onChange={e => setRejectReason(e.target.value)}
                />
                <Spacer large />
                <Row itemSpacing="smallish" justifyContent="flex-end">
                    <TextButton secondary onClick={hideModal}>
                        {i18n.button.cancel}
                    </TextButton>
                    <TextButton onClick={handleBudgetRejection}>{i18n.button.confirm}</TextButton>
                </Row>
            </ConfirmationModal>
        </Dialog>
    );
};

export default ActionItemsModal;
