import { Backdrop } from '@material-ui/core';
import colorFns from 'colorFns';
import {
    endOfDay,
    endOfWeek,
    format,
    getDay,
    isAfter,
    isBefore,
    parse,
    parseISO,
    startOfDay,
    startOfWeek,
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import isEqual from 'lodash/isEqual';
import React from 'react';
import {
    Calendar as BigCal,
    CalendarProps,
    Components,
    NavigateAction,
    View,
    dateFnsLocalizer,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';
import { Column } from 'ui';
import { roundEndOfDayto12AM } from 'utils/date_util';
import { tzMoment } from 'utils/moment';
import EventBlock from './components/EventBlock';
import SelectedPopper from './components/SelectedPopper';
import Toolbar from './components/Toolbar';
import styles, { BACKDROP_Z_INDEX, BLOCK_Z_INDEX } from './styles';
import { getScrollToTime } from './utils';

const GUTTER_TIME_FORMAT = 'h aa';
const DAY_HEADER_FORMAT = "dd'\n'EEE";

const locales = {
    'en-US': enUS,
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const CalCol = styled(Column)<{ view?: View; animateScroll?: boolean }>`
    width: 100%;
    height: 100%;

    ${({ view }) =>
        view !== 'month' && view !== 'agenda'
            ? `
        margin-left: -18px; /* visually center */
    `
            : ``};

    ${styles.NoShadedToday};

    ${styles.Header}
    ${styles.TimeView}
    ${styles.TimeGutter}
    ${styles.WeekColumn}
    ${styles.Slots}
`;

const StyledToolbar = styled(Toolbar)`
    margin-bottom: 32px;
`;

const SelectedBackdrop = styled(Backdrop)`
    background: ${colorFns.pureWhite.alpha(0.5)};
    z-index: ${BACKDROP_Z_INDEX};
`;

type TBaseBlock = {
    id: number;
    name?: string;

    start: Date;
    end: Date;

    colorId?: number | null;

    multiDay?: boolean;
    loading?: boolean;
};

type TBigCalendar<TEvent extends TBaseBlock, TResource extends object> = PartialPick<
    CalendarProps<TEvent, TResource>,
    'localizer'
> & {
    selectedId?: number;
    isDraftId?: (id: number) => boolean;
    renderEventDetails?: (event: TEvent) => React.ReactNode;
    onClearSelected?: () => void;

    initScrollTo?: Date;
    triggerFocus?: boolean;

    noToolbar?: boolean;
    lockView?: boolean;
    onClickBlock?: (event: TEvent) => void;
};

export default function BigCalendar<TEvent extends TBaseBlock, TResource extends object>({
    events,
    defaultView,
    formats,
    view,
    selectedId,
    isDraftId,
    renderEventDetails,
    initScrollTo,
    triggerFocus,

    lockView,
    noToolbar,
    onClickBlock,
    ...props
}: TBigCalendar<TEvent, TResource>) {
    const firstEventStart = events?.[0]?.start;
    const date = React.useMemo(
        () => (typeof props.date === 'string' ? parseISO(props.date) : props.date ?? firstEventStart ?? new Date()),
        [props.date, firstEventStart]
    );
    const startOfView = (view === 'week' ? startOfWeek : startOfDay)(date);
    const endOfView = (view === 'week' ? endOfWeek : endOfDay)(date);

    const selectedEvent = events
        ?.filter(e => isBefore(e.start, endOfView) && isAfter(e.end, startOfView))
        .find(e => e.id === selectedId);

    const [selectedRange, setSelectedRange] = React.useState<
        | {
              continuesEarlier?: boolean;
              continuesLater?: boolean;
          }
        | undefined
    >();

    React.useEffect(() => {
        if (!selectedEvent) setSelectedRange(undefined);
    }, [selectedEvent]);

    const selectedBlockRef = React.useRef<HTMLDivElement | null>(null);

    const blockOnSelectSlot: React.MouseEventHandler = e => e.nativeEvent.stopImmediatePropagation();

    const components: Components<TEvent, TResource> = {
        toolbar: props => (noToolbar ? null : <StyledToolbar {...props} hideRange={lockView} />),
        eventWrapper: eventWrapperProps => {
            const { event, continuesEarlier, continuesLater } = eventWrapperProps;
            const eventRange = { continuesEarlier, continuesLater };

            const selected = event.id === selectedId && (selectedRange ? isEqual(eventRange, selectedRange) : true);

            return (
                <EventBlock
                    {...eventWrapperProps}
                    draft={isDraftId && isDraftId(event.id)}
                    selected={selected}
                    {...(selected ? { blockRef: selectedBlockRef } : {})}
                    style={{
                        ...(selected ? { zIndex: BLOCK_Z_INDEX, xOffset: 0 } : { xOffset: 0 }),
                        ...eventWrapperProps.style,
                    }}
                    onClick={e => {
                        setSelectedRange(eventRange);
                        eventWrapperProps.onClick(e);
                        onClickBlock?.(event);
                    }}
                    onMouseDown={props.onSelectEvent ? blockOnSelectSlot : undefined}
                />
            );
        },
    };

    // setting a proper scroll start removes jerky scrolling between views
    const [scrollStart, setScrollStart] = React.useState<Date>(
        initScrollTo ?? ((events && events.length > 0 && getScrollToTime(events, date, view)) || date)
    );
    const [scrollEnd, setScrollEnd] = React.useState<Date | undefined>(initScrollTo);
    React.useLayoutEffect(() => {
        if (scrollStart && !scrollEnd)
            setScrollEnd(events && events.length > 0 ? getScrollToTime(events, date, view) : undefined);
    }, [scrollStart, scrollEnd, events, date, view]);

    React.useLayoutEffect(() => {
        if (triggerFocus)
            // switc {
            setScrollEnd(curScrollEnd => {
                curScrollEnd && setScrollStart(curScrollEnd);
                return undefined;
            });
    }, [triggerFocus, setScrollEnd, setScrollStart]);

    const onNavigate = (newDate: Date, view: View, action: NavigateAction) => {
        props.onNavigate?.(newDate, view, action);
        setScrollEnd(curScrollEnd => {
            curScrollEnd && setScrollStart(curScrollEnd);
            return undefined;
        });
    };
    const onView = (newView: View) => {
        props.onView?.(newView);
        setScrollEnd(curScrollEnd => {
            curScrollEnd && setScrollStart(curScrollEnd);
            return undefined;
        });
    };

    return (
        <CalCol view={view} animateScroll={scrollStart !== scrollEnd && !!scrollEnd}>
            <BigCal
                localizer={localizer}
                step={60}
                timeslots={1}
                components={components}
                defaultView={defaultView}
                view={view}
                onView={onView}
                onNavigate={onNavigate}
                events={events}
                {...props}
                style={{ height: 'unset', flex: '1 0 0', minHeight: 0 }}
                formats={{
                    selectRangeFormat: ({ start, end }) =>
                        [tzMoment(start).format('LT'), tzMoment(roundEndOfDayto12AM(end)).format('LT')].join(' to '),
                    timeGutterFormat: GUTTER_TIME_FORMAT,
                    dayFormat: DAY_HEADER_FORMAT,

                    ...formats,
                }}
                scrollToTime={scrollEnd || scrollStart}
            />

            {selectedEvent && (
                <>
                    <SelectedBackdrop open />
                    {!isDraftId?.(selectedEvent.id) && renderEventDetails && (
                        <SelectedPopper
                            blockRef={selectedBlockRef}
                            onBackdropClick={() => {
                                props.onClearSelected?.();
                                selectedBlockRef.current = null;
                            }}
                            view={view}
                        >
                            {renderEventDetails(selectedEvent)}
                        </SelectedPopper>
                    )}
                </>
            )}
        </CalCol>
    );
}
