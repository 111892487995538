// This file will serve as an example for a patten we will have to follow moving forwards with some API responses.
// It will be necessary to hardcode an entire example of an API "options-like" object response, so that we can key the
// object as we see fit and make changes to it to work better with the front-end.
// This will require coordination between the front and backend changes that implies an anti-pattern awareness of the
// backend by the front end. However, it will be very useful for a few use-cases, notably translations where the API
// contains English strings.

import { i18n } from 'translation';

export type EventOptionsDictAPI = {
    avOptions: { id: number; name: string; iconUrl: string }[];
    fbOptions: {
        id: number;
        name: string;
        iconUrl: string;
        diningStyles: { id: number; name: string }[];
    }[];
    setupOptions: {
        id: number;
        name: string;
        iconUrl: string;
    }[];
    attendeeQuestionDefinitions: { numId: number; id: string }[];
    dietaryRestrictionOptions: {
        id: number;
        name: string;
        description: string;
    }[];
    foodAllergyOptions: {
        id: number;
        name: string;
        description: string;
    }[];
};

export const diningStylesOptionsDict = {
    1: {
        id: 1,
        name: i18n.api.eventOptions.diningStyles.buffet,
    },
    2: {
        id: 2,
        name: i18n.api.eventOptions.diningStyles.plated,
    },
};

const avOptionsDict = {
    5: {
        id: 5,
        name: i18n.api.eventOptions.avOptions.internet,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562165321/stencil/icons/Internet.svg',
    },
    6: {
        id: 6,
        name: i18n.api.eventOptions.avOptions.audioconferencing,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562165320/stencil/icons/Audio_Conference.svg',
    },
    7: {
        id: 7,
        name: i18n.api.eventOptions.avOptions.videoconferencing,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562165321/stencil/icons/Video_Conference.svg',
    },
    8: {
        id: 8,
        name: i18n.api.eventOptions.avOptions.projectorScreen,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562165321/stencil/icons/Projector.svg',
    },
    9: {
        id: 9,
        name: i18n.api.eventOptions.avOptions.flipChart,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562165321/stencil/icons/Flipchart.svg',
    },
    10: {
        id: 10,
        name: i18n.api.eventOptions.avOptions.flatScreen,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562165321/stencil/icons/Flatscreen.svg',
    },
};

const fbOptionsDict = {
    1: {
        id: 2,
        name: i18n.api.eventOptions.fbOptions.breakfast,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167420/stencil/icons/Breakfast.svg',
        diningStyles: Object.values(diningStylesOptionsDict),
    },
    2: {
        id: 4,
        name: i18n.api.eventOptions.fbOptions.lunch,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167423/stencil/icons/Lunch.svg',
        diningStyles: Object.values(diningStylesOptionsDict),
    },
    3: {
        id: 3,
        name: i18n.api.eventOptions.fbOptions.dinner,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167423/stencil/icons/Dinner.svg',
        diningStyles: Object.values(diningStylesOptionsDict),
    },
    4: {
        id: 5,
        name: i18n.api.eventOptions.fbOptions.morningBreak,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167420/stencil/icons/Morning_Break.svg',
        diningStyles: [],
    },
    5: {
        id: 1,
        name: i18n.api.eventOptions.fbOptions.afternoonBreak,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167421/stencil/icons/Afternoon_Break.svg',
        diningStyles: [],
    },
    6: {
        id: 6,
        name: i18n.api.eventOptions.fbOptions.reception,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167421/stencil/icons/Cocktail_Reception.svg',
        diningStyles: [],
    },
};

const setupOptionsDict = {
    1: {
        id: 1,
        name: i18n.api.eventOptions.setupOptions.banquetRounds,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167930/stencil/icons/setup_banquet-rounds.svg',
    },
    2: {
        id: 2,
        name: i18n.api.eventOptions.setupOptions.conference,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167930/stencil/icons/setup_conference.svg',
    },
    3: {
        id: 3,
        name: i18n.api.eventOptions.setupOptions.classroom,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167931/stencil/icons/setup_classroom.svg',
    },
    4: {
        id: 4,
        name: i18n.api.eventOptions.setupOptions.crescentRounds,
        iconUrl:
            'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167930/stencil/icons/setup_crescent-rounds.svg',
    },
    5: {
        id: 5,
        name: i18n.api.eventOptions.setupOptions.hollowSquare,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167931/stencil/icons/setup_hollow-square.svg',
    },
    6: {
        id: 6,
        name: i18n.api.eventOptions.setupOptions.reception,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167930/stencil/icons/setup_reception.svg',
    },
    7: {
        id: 7,
        name: i18n.api.eventOptions.setupOptions.theater,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167930/stencil/icons/setup_theater.svg',
    },
    8: {
        id: 8,
        name: i18n.api.eventOptions.setupOptions.uShape,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1562167930/stencil/icons/setup_u-shape.svg',
    },
    9: {
        id: 9,
        name: i18n.api.eventOptions.setupOptions.pods,
        iconUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/stencil/icons/setup_pods.svg',
    },
};

const attendeeQuestionDefinitionsDict = {
    1: {
        numId: 1,
        id: 'dietary_restrictions',
    },
    2: {
        numId: 2,
        id: 'food_allergies',
    },
    3: {
        numId: 3,
        id: 'travel_dates',
    },
    4: {
        numId: 4,
        id: 'logistics',
    },
    5: {
        numId: 5,
        id: 'Text Box',
    },
    6: {
        numId: 6,
        id: 'Multiple Choice - Single Answer',
    },
    7: {
        numId: 7,
        id: 'Multiple Choice - Multiple Answers',
    },
    8: {
        numId: 8,
        id: 'Date picker',
    },
    9: {
        numId: 9,
        id: 'Linear Scale',
    },
    11: {
        numId: 11,
        id: 'attendance_type',
    },
};

const dietaryRestrictionOptionsDict = {
    1: {
        id: 1,
        name: i18n.api.eventOptions.dietaryRestrictionOptions.vegan,
        description: i18n.api.eventOptions.dietaryRestrictionOptions.veganDescription,
    },
    2: {
        id: 2,
        name: i18n.api.eventOptions.dietaryRestrictionOptions.vegetarian,
        description: i18n.api.eventOptions.dietaryRestrictionOptions.vegetarianDescription,
    },
    3: {
        id: 3,
        name: i18n.api.eventOptions.dietaryRestrictionOptions.kosher,
        description: i18n.api.eventOptions.dietaryRestrictionOptions.kosherDescription,
    },
    4: {
        id: 4,
        name: i18n.api.eventOptions.dietaryRestrictionOptions.halal,
        description: i18n.api.eventOptions.dietaryRestrictionOptions.halalDescription,
    },
    5: {
        id: 5,
        name: i18n.api.eventOptions.dietaryRestrictionOptions.pescatarian,
        description: i18n.api.eventOptions.dietaryRestrictionOptions.pescatarianDescription,
    },
    6: {
        id: 6,
        name: i18n.api.eventOptions.dietaryRestrictionOptions.glutenFree,
        description: i18n.api.eventOptions.dietaryRestrictionOptions.glutenFreeDescription,
    },
};

const foodAllergyOptionsDict = {
    1: {
        id: 1,
        name: i18n.api.eventOptions.foodAllergyOptions.dairyAllergy,
        description: i18n.api.eventOptions.foodAllergyOptions.dairyAllergyDescription,
    },
    2: {
        id: 2,
        name: i18n.api.eventOptions.foodAllergyOptions.eggAllergy,
        description: i18n.api.eventOptions.foodAllergyOptions.eggAllergyDescription,
    },
    3: {
        id: 3,
        name: i18n.api.eventOptions.foodAllergyOptions.peanutAllergy,
        description: i18n.api.eventOptions.foodAllergyOptions.peanutAllergyDescription,
    },
    4: {
        id: 4,
        name: i18n.api.eventOptions.foodAllergyOptions.treeNutAllergy,
        description: i18n.api.eventOptions.foodAllergyOptions.treeNutAllergyDescription,
    },
    5: {
        id: 5,
        name: i18n.api.eventOptions.foodAllergyOptions.soyAllergy,
        description: i18n.api.eventOptions.foodAllergyOptions.soyAllergyDescription,
    },
    6: {
        id: 6,
        name: i18n.api.eventOptions.foodAllergyOptions.wheatAllergy,
        description: i18n.api.eventOptions.foodAllergyOptions.wheatAllergyDescription,
    },
    7: {
        id: 7,
        name: i18n.api.eventOptions.foodAllergyOptions.fishAllergy,
        description: i18n.api.eventOptions.foodAllergyOptions.fishAllergyDescription,
    },
    8: {
        id: 8,
        name: i18n.api.eventOptions.foodAllergyOptions.shellfishAllergy,
        description: i18n.api.eventOptions.foodAllergyOptions.shellfishAllergyDescription,
    },
};

export const eventOptionsAPIDict = (): EventOptionsDictAPI => ({
    avOptions: Object.values(avOptionsDict),
    fbOptions: Object.values(fbOptionsDict),
    setupOptions: Object.values(setupOptionsDict),
    attendeeQuestionDefinitions: Object.values(attendeeQuestionDefinitionsDict),
    dietaryRestrictionOptions: Object.values(dietaryRestrictionOptionsDict),
    foodAllergyOptions: Object.values(foodAllergyOptionsDict),
});
