import colorFns from 'colorFns';
import GenericNavButtons from 'components/GenericNavButtons';
import CheckIconSvg from 'images/icons/check-mark.svg?react';
import CommunicationIconSvg from 'images/icons/envelope.svg?react';
import CollaborationIconSvg from 'images/icons/guests.svg?react';
import OverviewIconSvg from 'images/icons/overview.svg?react';
import InquiryIconSvg from 'images/icons/registration.svg?react';
import VenuesIconSvg from 'images/icons/venue_pin.svg?react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
    createPlaybookActions,
    ESteps,
    selCurStep,
    stepList,
    stepToTitle as stepToName,
    useCreatePlaybook,
} from './store';

const iconStyles = css<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${colorFns.pureWhite};
    fill: ${colorFns.pureWhite};
    margin-right: 9px;
`;

const OverviewIcon = styled(OverviewIconSvg)`
    ${iconStyles}
`;
const CheckIcon = styled(CheckIconSvg)`
    ${iconStyles}
`;
const VenuesIcon = styled(VenuesIconSvg)`
    ${iconStyles}
`;
const InquiryIcon = styled(InquiryIconSvg)`
    ${iconStyles}
`;
const CollaborationIcon = styled(CollaborationIconSvg)`
    ${iconStyles}
`;
const CommunicationIcon = styled(CommunicationIconSvg)`
    ${iconStyles}
`;

const stepToIcon = {
    [ESteps.basic]: OverviewIcon,
    [ESteps.venues]: VenuesIcon,
    [ESteps.inquiry]: InquiryIcon,
    [ESteps.communication]: CommunicationIcon,
    [ESteps.collaboration]: CollaborationIcon,
    [ESteps.preview]: CheckIcon,
};

export default function NavButtons() {
    const navigate = useNavigate();
    const curStep = useCreatePlaybook(selCurStep);
    const { stepIdx: curStepIdx, id: playbookId } = useCreatePlaybook();

    const navigateHandler = (step: ESteps) => {
        createPlaybookActions.goToStep(step);
        if (curStep === ESteps.communication && step !== ESteps.communication) {
            navigate(`/playbooks/${playbookId}/edit`, { replace: true });
        }
    };

    const linksList = stepList.map((step, stepIdx) => {
        const Icon = stepToIcon[step];
        const name = stepToName[step];

        const disabled = stepIdx > curStepIdx;

        return {
            key: name,
            active: curStep === step,
            disabled,
            onClick: disabled ? () => {} : () => navigateHandler(step),
            children: (
                <>
                    <Icon active={curStep === step} />
                    {name}
                </>
            ),
        };
    });

    return <GenericNavButtons backTo={`/playbooks/${playbookId}`} links={linksList} />;
}
