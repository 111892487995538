import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { CA, DE, ES, FR, IT, MX, NL, PT, US } from 'country-flag-icons/react/3x2';
import { translationsActions, useTranslations } from 'stores/translations';

interface ContainerProps {
    backgroundColor?: string;
}

const Container = styled(Select)<ContainerProps>(({ theme, backgroundColor }) => ({
    borderRadius: '.25rem',
    '&:before': {
        borderBottom: 'none',
    },
    '&:after': {
        borderBottom: 'none',
    },
    backgroundColor: backgroundColor || theme.palette.background.paper,

    '& .MuiSelect-select': {
        paddingTop: '.5rem',
        paddingBottom: '.25rem',
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
    },
}));

const FlagIcon = styled('div')({
    height: '1.125rem',
    width: '1.6875rem',
});

const WhiteFlag = () => <>ZZ</>;

const flags = [
    { value: 'fr-ca', label: <CA /> },
    { value: 'de', label: <DE /> },
    { value: 'en', label: <US /> },
    { value: 'es', label: <ES /> },
    { value: 'fr', label: <FR /> },
    { value: 'it', label: <IT /> },
    { value: 'es-mx', label: <MX /> },
    { value: 'nl', label: <NL /> },
    { value: 'pt', label: <PT /> },
    ...(import.meta.env.VITE_APP_ENV === 'prod' ? [] : [{ value: 'zz', label: <WhiteFlag /> }]),
];

interface LanguageSelectorProps {
    backgroundColor?: string;
}

export const LanguageSelector = ({ backgroundColor }: LanguageSelectorProps) => {
    const { language } = useTranslations();

    const handleChange = (event: SelectChangeEvent<string>) => {
        translationsActions.save({ language: event.target.value as Translations.Language });
    };

    return (
        <Container backgroundColor={backgroundColor} value={language} onChange={handleChange}>
            {flags.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                    <FlagIcon>{label}</FlagIcon>
                </MenuItem>
            ))}
        </Container>
    );
};
