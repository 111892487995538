import { Box, Switch as MuiSwitch } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const Switch = styled(MuiSwitch)(({ theme }) => {
    const { getColor, EColors } = theme;

    return {
        padding: 8,

        '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            backgroundColor: getColor(EColors.pureWhite),
            border: `1px solid ${getColor(EColors.bizlyOSPrimary)}`,
            height: '95%',
        },

        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: '2.5px 2px',
            color: getColor(EColors.bizlyOSBorder),
        },

        '& .Mui-checked': {
            '& .MuiSwitch-thumb': {
                color: getColor(EColors.bizlyOSPrimary),
            },
            '& + .MuiSwitch-track': {
                backgroundColor: getColor(EColors.pureWhite),
                opacity: 1,
            },
        },

        '&.Mui-disabled': {
            '& .MuiSwitch-track': {
                border: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
                opacity: 0.5,
            },
            '& .MuiSwitch-thumb': {
                color: getColor(EColors.bizlyOSBorder),
            },
        },
    };
});

export const ToggleSwitchBox = styled(Box)<{ isOn: boolean }>(({ theme, isOn }) => {
    const { getColor, EColors } = theme;

    return {
        backgroundColor: isOn ? alpha(getColor(EColors.bizlyOSPrimary), 0.1) : theme.palette.grey[200],
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.short,
        }),
    };
});
