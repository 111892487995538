import { TabPanelProps } from '@mui/lab/TabPanel';
import { Tab, TabProps, Tabs, TabsProps, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { TProposalForm } from 'components/ProposalForm/types';
import { useState } from 'react';
import InquiryEventRoom from './InquiryEventRoom';
import InquiryGuestRooms from './InquiryGuestRooms';
import InquiryOverview from './InquiryOverview';

const Container = styled(Box)(({ theme: { getColor, EColors, shape } }) => ({
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    borderRadius: shape.borderRadius,
    overflowY: 'auto',
    maxWidth: '730px',
    minWidth: '400px',
}));

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    boxSizing: 'border-box',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2.5),
    backgroundColor: getColor(EColors.drWhite),
    borderTopLeftRadius: shape.borderRadius,
    borderTopRightRadius: shape.borderRadius,
}));

const BodyContainer = styled(Box)(({ theme }) => ({
    padding: '0 ' + theme.spacing(2.5),
}));

const TabPanelContainer = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(2.5),
}));

const StyledTab = styled(Tab)<TabProps>(({ theme: { getColor, EColors } }) => ({
    '&.MuiTab-root': {
        minWidth: 'unset',
        textTransform: 'unset',
        height: '30px',
        padding: '20px 0px',
        marginRight: '30px',
        color: getColor(EColors.pureBlack),
    },
}));

const StyledTabs = styled(Tabs)<TabsProps>(({ theme: { getColor, EColors } }) => ({
    '&.MuiTabs-root': {
        minHeight: 'unset',
        borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    },
    '& .MuiTabs-indicator': {
        backgroundColor: getColor(EColors.bizlyOSPrimary),
        height: '3px',
    },
}));

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, id } = props;

    return (
        value === id && (
            <TabPanelContainer role="tabpanel" id={`simple-tabpanel-${id}`}>
                {children}
            </TabPanelContainer>
        )
    );
}

type InquiryDetailsProps = {
    proposalInquryForm: Partial<TProposalForm>;
};

export default function InquiryDetails({ proposalInquryForm }: InquiryDetailsProps) {
    const [tabBalue, setTabValue] = useState<string>('Overview');
    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    return (
        <Container>
            <HeaderContainer>
                <Typography variant="body1" fontWeight={600}>
                    Inquiry Details
                </Typography>
            </HeaderContainer>
            <BodyContainer>
                <StyledTabs onChange={handleTabChange} value={tabBalue}>
                    <StyledTab label="Overview" value="Overview" />
                    <StyledTab label="Event Rooms" value="Event Rooms" />
                    <StyledTab label="Guest Rooms" value="Guest Rooms" />
                </StyledTabs>
                <CustomTabPanel value={tabBalue} id="Overview">
                    <InquiryOverview proposalInquryForm={proposalInquryForm} />
                </CustomTabPanel>
                <CustomTabPanel value={tabBalue} id="Event Rooms">
                    <InquiryEventRoom proposalInquryForm={proposalInquryForm} />
                </CustomTabPanel>
                <CustomTabPanel value={tabBalue} id="Guest Rooms">
                    <InquiryGuestRooms proposalInquryForm={proposalInquryForm} />
                </CustomTabPanel>
            </BodyContainer>
        </Container>
    );
}
