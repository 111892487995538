import fontFns from 'fontFns';
import React from 'react';
import styled from 'styled-components';
import { colorStyledFn, EColors, TColorStyledFn } from 'theme';
import { AccentedPlaceholderIcon, Column, Copy } from 'ui';

type HeadlineProps = {
    thin?: boolean;
    small?: boolean;
    large?: boolean;
    noDot?: boolean;
    children?: React.ReactNode;
    className?: string;
} & TColorStyledFn;

export const Headline = styled('h3')<HeadlineProps>`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    font-weight: ${props => (props.thin ? '400' : '600')};
    font-size: ${props => (props.large ? '1.5em' : props.small ? '1em' : '1.25em')};
    text-transform: capitalize;
    white-space: nowrap;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;

    ${colorStyledFn}
`;

export const LargeHeadline = (props: HeadlineProps) => <Headline {...props} large />;

export const SmallHeadline = (props: HeadlineProps) => <Headline {...props} thin small />;

export const ThinHeadline = (props: HeadlineProps) => <Headline {...props} thin />;

export const PageHeadline = styled(Headline)<{ withDescription?: boolean; noMarginBlock?: boolean }>`
    font-size: 2em;
    font-weight: 400;

    ${props => props.noMarginBlock && 'margin-block: 0;'}
`;

export const H1Headline = styled.h1<TColorStyledFn>`
    font-size: 2.5em;
    font-weight: normal;
    ${colorStyledFn}

    margin: 0;
`;

export const H2Headline = styled.h2<TColorStyledFn>`
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.28;
    letter-spacing: -0.5px;
    ${colorStyledFn}

    margin: 0;
`;

export const H3Headline = styled.h3<{ maxLines?: number } & TColorStyledFn>`
    font-size: 1.125em;
    font-weight: normal;
    letter-spacing: -0.1px;
    ${colorStyledFn}

    margin: 0;

    ${props =>
        props.maxLines === 1 &&
        `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

export const H5Headline = styled.h5<TColorStyledFn>`
    margin-top: 0;
    margin-bottom: 0;

    font-size: 2.25em;
    font-weight: 600;
    line-height: 1.22;
    letter-spacing: -1.1px;
    ${colorStyledFn}
`;

const HeadlinerAlignBottom = styled.div`
    display: flex;
    align-items: flex-end;

    height: 3.5em;
`;

export const WhiteHeadliner = styled(LargeHeadline)`
    display: inline-block;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    white-space: normal;
    text-transform: none;

    line-height: 1.1;
    letter-spacing: -1.6px;

    margin: 0;

    ${({ noDot, theme: { getColor, EColors } }) =>
        noDot
            ? ''
            : `&::after {
    content: ".";
    color: ${getColor(EColors.bizlyHeadlineDot)};
    }`}

    ${fontFns.heading.themeOverride('bizly')}
`;

type HeadlinerProps = {
    children: React.ReactNode;
};

export const Headliner = ({ children }: HeadlinerProps) => (
    <HeadlinerAlignBottom>
        <WhiteHeadliner>{children}</WhiteHeadliner>
    </HeadlinerAlignBottom>
);

const IconHeadlineContainer = styled(Column)<{ flexShrink?: number }>`
    flex-shrink: ${({ flexShrink }) => flexShrink || 0};
`;

type IconHeadlineProps = {
    icon?: React.ReactNode;
    headline: string;
    description?: string;
    descriptionColor?: EColors | Themed.Color;
    size?: string;
    flexShrink?: number;
};

export const IconHeadline = ({
    icon,
    headline,
    description,
    descriptionColor,
    size = 'large',
    flexShrink,
}: IconHeadlineProps) => {
    const SizedHeadline = size === 'large' ? ThinHeadline : SmallHeadline;

    return (
        <IconHeadlineContainer flexShrink={flexShrink}>
            <SizedHeadline>
                {icon || <AccentedPlaceholderIcon />}
                <span style={{ marginLeft: '0.5em' }}>{headline}</span>
            </SizedHeadline>
            {description && <Copy $color={descriptionColor}>{description}</Copy>}
        </IconHeadlineContainer>
    );
};
