import { SpinnerOverlay as UISpinnerOverlay } from 'components/Spinner';
import VenueTile, { VenueTileEmpty } from 'components/VenueTile';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import useThemedColor from 'hooks/useThemedColor';
import { useEvent } from 'providers/event';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { currentInquiryActions, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { Column } from 'ui';
import { padArray } from '../util';
import { REJECTED_STATUSES } from './VenueCard';

const Container = styled(Column)`
    position: relative;
    width: fit-content;

    ${SmallCircleButton} {
        align-self: center;
        margin-left: 12px;
    }
`;

const SpinnerOverlay = styled(UISpinnerOverlay)`
    z-index: 1300;
`;

const TILE_WIDTH = '197px',
    TILE_MIN_WIDTH = 'auto',
    TILE_RATIO = 'calc(160 / 208 * 100%)';

const MAX_VENUE_SLOTS = 4;

const CartWrapper = styled.div`
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 20px;
`;

const InquiryCart = ({
    isCreateMeeting,
    hideReturnToSearch,
    onAdd,
}: {
    isCreateMeeting?: boolean;
    hideReturnToSearch?: boolean;
    onAdd?: () => void;
}) => {
    const { loading, inquiry, venues } = useCurrentInquiry();
    const location = useLocation();
    const navigate = useNavigate();
    const { event } = useEvent();
    const { pureWhite } = useThemedColor();

    const validVenues = useMemo(() => (venues || [])?.filter(venue => !REJECTED_STATUSES.has(venue.status)), [venues]);
    const paddedVenues = useMemo(() => padArray(validVenues, MAX_VENUE_SLOTS), [validVenues]);

    const viewVenueListing = (venueId: number) => {
        const pathname = isCreateMeeting
            ? `/events/${event.id}/edit/venue/listing/${venueId}`
            : `/event/${event.id}/venue/listing/${venueId}`;
        navigate(pathname, {
            state: {
                ...location.state,
                shouldGoBack: true,
                backgroundLocation: location,
            },
        });
    };

    const handleRemoveVenue = (venueId: number) => {
        inquiry?.id && currentInquiryActions.removeVenue(inquiry.id, venueId);
    };

    return (
        <Container itemSpacing="medium">
            {loading && <SpinnerOverlay />}
            <CartWrapper>
                {paddedVenues.map((venue, idx) =>
                    venue ? (
                        <VenueTile
                            key={venue.id}
                            venue={{ ...venue.venue, status: venue.status }}
                            format="tile"
                            pillType={inquiry?.submittedAt ? 'inquiryStatus' : 'preferenceCategory'}
                            width={TILE_WIDTH}
                            minWidth={TILE_MIN_WIDTH}
                            fixedRatio={TILE_RATIO}
                            onClick={viewVenueListing}
                            onClose={
                                !inquiry?.submittedAt && event.editable ? () => handleRemoveVenue(venue.id) : undefined
                            }
                        />
                    ) : (
                        <VenueTileEmpty
                            key={'empty-tile-' + idx}
                            width={TILE_WIDTH}
                            minWidth={TILE_MIN_WIDTH}
                            fixedRatio={TILE_RATIO}
                            {...(event.editable && !hideReturnToSearch && idx === validVenues.length
                                ? {
                                      addNew: true,
                                      onClick: onAdd,
                                      backgroundColor: pureWhite,
                                  }
                                : { withPin: true })}
                        />
                    )
                )}
            </CartWrapper>
        </Container>
    );
};

export default InquiryCart;
