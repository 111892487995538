import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { styled } from '@mui/material';
import { useUser } from 'providers/user';
import { i18n } from 'translation';
import { CopyFaded } from 'ui';
import { EventCreateForm, EventCreateFormErrors } from '../formSchema';
import { Description, StepContainer, Title } from './styled';

type PropType = {
    values: EventCreateForm;
    onChange: (key: keyof EventCreateForm) => (value: unknown) => void;
    errors: EventCreateFormErrors;
};

//TODO: Have to do a styled menu item due to default menuitem styles are being overriden
// After upgrading to MUI v5, we should be able to use the default menu item and fix all css issues
const StyledMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        letter-spacing: 0.00938em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        padding: 0.375rem 1rem;
        box-sizing: border-box;
        white-space: nowrap;
        text-decoration: none;
        line-height: 1.5;
    }
`;

const Step7 = ({ values, onChange, errors }: PropType) => {
    const { user } = useUser();
    const handleCostCenterChange = onChange('costCenter');
    const handleInternalReferenceChange = onChange('cventId');
    const handleDepartmentChange = onChange('department');
    const handleMeetingTypeChange = onChange('meetingType');

    const handleCostCenterSelect = (event: SelectChangeEvent) => {
        handleCostCenterChange(event.target.value);
    };

    const handleDepartmentSelect = (event: SelectChangeEvent) => {
        handleDepartmentChange(event.target.value);
    };

    const handleMeetingTypeSelect = (event: SelectChangeEvent) => {
        handleMeetingTypeChange(event.target.value);
    };

    const _costCenterLabel =
        user.team?.meetingCostCenterFieldLabel ?? i18n.meetingDashboard.headerSection.settings.costCenter;
    const costCenterRequired = user.team?.features.requiredInternalFields.includes('costCenter');
    const costCenterLabel = `${_costCenterLabel}${costCenterRequired ? ' (' + i18n.common.required + ')' : ''}`;
    const cventIdRequired = user.team?.features.requiredInternalFields.includes('cventId');
    const internalReferenceLabel =
        user.team?.meetingInternalReferenceFieldLabel ?? i18n.meetingDashboard.headerSection.settings.internalReference;
    const cventIdLabel = `${internalReferenceLabel}${cventIdRequired ? ' (' + i18n.common.required + ')' : ''}`;
    const departmentRequired = user.team?.features.requiredInternalFields.includes('department');
    const departmentLabel = `${i18n.meetingDashboard.headerSection.settings.department}${departmentRequired ? ' (' + i18n.common.required + ')' : ''}`;
    const meetingTypeRequired = user.team?.features.requiredInternalFields.includes('type');
    const meetingTypeLabel = `${i18n.meetingDashboard.headerSection.settings.meetingType}${meetingTypeRequired ? ' (' + i18n.common.required + ')' : ''}`;

    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.additionalInfoTitle}</Title>
            <Description>{i18n.homepage.createMeetingModal.additionalInfoDescription}</Description>
            <Box display="flex" flexDirection="column" gap={2}>
                {user.team?.costCenters ? (
                    <FormControl error={errors.costCenter}>
                        <InputLabel id="event-costCenter-label">{costCenterLabel}</InputLabel>
                        <Select
                            id="event-costCenter"
                            labelId="event-costCenter-label"
                            value={values.costCenter ?? ''}
                            label={costCenterLabel}
                            onChange={handleCostCenterSelect}
                        >
                            {['', ...user.team.costCenters].map(option => (
                                <StyledMenuItem key={option} value={option}>
                                    {option === '' ? (
                                        <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded>
                                    ) : (
                                        option
                                    )}
                                </StyledMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <TextField
                        label={costCenterLabel}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        value={values.costCenter}
                        onChange={e => handleCostCenterChange(e.target.value)}
                        error={errors.costCenter}
                    />
                )}
                <TextField
                    label={cventIdLabel}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    value={values.cventId}
                    onChange={e => handleInternalReferenceChange(e.target.value)}
                    error={errors.cventId}
                />
                {user.team?.departments ? (
                    <FormControl error={errors.department}>
                        <InputLabel id="event-department-label">{departmentLabel}</InputLabel>
                        <Select
                            id="event-department"
                            labelId="event-department-label"
                            value={values.department ?? ''}
                            label={departmentLabel}
                            onChange={handleDepartmentSelect}
                        >
                            {['', ...user.team.departments].map(option => (
                                <StyledMenuItem key={option} value={option}>
                                    {option === '' ? (
                                        <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded>
                                    ) : (
                                        option
                                    )}
                                </StyledMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <TextField
                        label={departmentLabel}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        value={values.department}
                        onChange={e => handleDepartmentChange(e.target.value)}
                        error={errors.department}
                    />
                )}
                <FormControl required={meetingTypeRequired} error={errors.meetingType}>
                    <InputLabel id="event-meeting-type-label">{meetingTypeLabel}</InputLabel>
                    <Select
                        id="event-meeting-type"
                        labelId="event-meeting-type-label"
                        value={values.meetingType ?? ''}
                        label={meetingTypeLabel}
                        onChange={handleMeetingTypeSelect}
                    >
                        {[
                            '',
                            `${i18n.meetingDashboard.headerSection.settings.internal}`,
                            `${i18n.meetingDashboard.headerSection.settings.external}`,
                        ].map(option => (
                            <StyledMenuItem key={option} value={option}>
                                {option === '' ? (
                                    <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded>
                                ) : (
                                    option
                                )}
                            </StyledMenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </StepContainer>
    );
};

export default Step7;
