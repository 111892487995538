import InfoRounded from '@mui/icons-material/InfoRounded';
import { Box, Typography, styled } from '@mui/material';
import { SpinnerOverlay as UISpinnerOverlay } from 'components/Spinner';
import VenueTile from 'components/VenueTile';
import { QueryParamEnum } from 'constants/queryParams';
import { useEvent } from 'providers/event';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { currentInquiryActions, hasAcceptedProposal, useCurrentInquiry } from 'stores/current-inquiry';
import { padArray } from '../util';
import { Button } from './Ui-V2/Button/Button';
import { Spacer } from './Ui-V2/Spacer/Spacer';
import { REJECTED_STATUSES } from './VenueCard';

const SpinnerOverlay = styled(UISpinnerOverlay)`
    z-index: 1300;
`;

const EmptyVenuesMessageContainer = styled(Box)`
    padding: 16px;
    text-align: center;
    background-color: ${({ theme }) => theme.palette.background.paper};
    border-radius: 8px;
`;

const InfoWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.aliceBlue)};
    padding: 16px;
    border-radius: 8px;
`;

const StyledInfoIcon = styled(InfoRounded)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    margin-right: 8px;
`;

const HeadingWrapper = styled(Box)`
    display: flex;
    align-items: center;
`;

const StyledBodyText = styled(Typography)`
    text-align: left;
    padding-left: 32px;
    font-size: 0.875rem;
`;

const MAX_VENUE_SLOTS = 4;

const VenueList = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const InquiryCartRightDrawer = ({
    onClose,
}: {
    onClose: () => void;
    hideReturnToSearch?: boolean;
    onAdd?: () => void;
}) => {
    const { loading, inquiry, venues } = useCurrentInquiry();
    const location = useLocation();
    const navigate = useNavigate();
    const { event } = useEvent();

    const validVenues = useMemo(() => (venues || [])?.filter(venue => !REJECTED_STATUSES.has(venue.status)), [venues]);
    const paddedVenues = useMemo(() => padArray(validVenues, MAX_VENUE_SLOTS), [validVenues]);
    const noVenuesAdded = useMemo(() => validVenues.length === 0, [validVenues]);
    const hasOpenSlots = useMemo(() => validVenues.length < MAX_VENUE_SLOTS, [validVenues]);
    const isBooked = hasAcceptedProposal(validVenues);

    const viewVenueListing = (venueId: number) => {
        onClose();
        const params = new URLSearchParams(location.search);
        params.set(QueryParamEnum.VENUE_ID, venueId.toString());
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    const handleRemoveVenue = (venueId: number) => {
        inquiry?.id && currentInquiryActions.removeVenue(inquiry.id, venueId);
    };

    if (noVenuesAdded) {
        return (
            <EmptyVenuesMessageContainer>
                <InfoWrapper>
                    <Box>
                        <HeadingWrapper>
                            <StyledInfoIcon />
                            <Typography variant="h6" textAlign="left" fontSize="16px">
                                No venues selected yet
                            </Typography>
                        </HeadingWrapper>
                        <StyledBodyText variant="body2">
                            When you select venues to send out your inquiry, they will appear here.
                        </StyledBodyText>
                    </Box>
                </InfoWrapper>
            </EmptyVenuesMessageContainer>
        );
    }

    return (
        <Box>
            {loading && <SpinnerOverlay />}
            <VenueList>
                {paddedVenues.map(
                    venue =>
                        venue && (
                            <VenueTile
                                key={venue.id}
                                venue={{ ...venue.venue, status: venue.status }}
                                format="list"
                                fixedRatio="0"
                                onClick={viewVenueListing}
                                pillType={inquiry?.submittedAt ? 'inquiryStatus' : 'preferenceCategory'}
                                onClose={
                                    !inquiry?.submittedAt && event.editable
                                        ? () => handleRemoveVenue(venue.id)
                                        : undefined
                                }
                            />
                        )
                )}
            </VenueList>
            {hasOpenSlots && !isBooked && (
                <>
                    <Spacer small />
                    <Button onClick={onClose} fullWidth variant="outlined" size="large">
                        <Typography>Add more venues</Typography>
                    </Button>
                </>
            )}
        </Box>
    );
};

export default InquiryCartRightDrawer;
