import React from 'react';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import ToggleButton from '@mui/material/ToggleButton';

import { SwitchGroup } from 'components/Ui-V2/SwitchGroup/SwitchGroup';
import { i18n } from 'translation';
import { EventCreateForm } from '../formSchema';
import { Description, StepContainer, Title } from './styled';

type PropType = {
    value: EventCreateForm['payment'];
    onChange: (value: EventCreateForm['payment']) => void;
};

const Step6 = ({ value, onChange }: PropType) => {
    const handleModeChange = (_: React.MouseEvent, mode: string) => {
        if (mode !== null) {
            onChange({
                cost: value?.cost ?? 0,
                mode,
            });
        }
    };

    const handleCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        onChange({
            cost: Number(inputValue),
            mode: value?.mode ?? 'total',
        });
    };

    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.paymentTitle}</Title>
            <Description>{i18n.homepage.createMeetingModal.paymentDescription}</Description>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ mb: 2 }}>
                <SwitchGroup size="small" value={value?.mode ?? 'total'} exclusive onChange={handleModeChange}>
                    <ToggleButton value={'total'}>{i18n.homepage.createMeetingModal.grandTotal}</ToggleButton>
                    <ToggleButton value={'perPerson'}>{i18n.homepage.createMeetingModal.perPerson}</ToggleButton>
                </SwitchGroup>
            </Box>
            <OutlinedInput
                id="cost"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                type="number"
                value={String(value?.cost ?? 0)}
                onChange={handleCostChange}
                sx={{ width: '100%' }}
            />
        </StepContainer>
    );
};

export default Step6;
