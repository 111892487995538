import { get, post } from '.';

export const getNotifications = () =>
    get(`notifications`) as Promise<{
        success: boolean;
        notifications: BizlyAPI.Notification[];
        total: number;
        unread: number;
    }>;

export const updateReadStatus = (id: number, read: boolean) =>
    post(`/notifications/read`, { id, read }) as Promise<{
        success: boolean;
        notifications: BizlyAPI.Notification[];
        total: number;
        unread: number;
    }>;
