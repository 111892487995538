import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { searchMeetingsActions } from './searchStore';

const debouncedSearch = debounce(searchMeetingsActions.search, 200);

export default function SearchMeetings({ query, page }: { query: string; page?: number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            debouncedSearch(query, page);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    }, [page, query, enqueueSnackbar]);

    return null;
}
