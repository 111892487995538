import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { ChangeEvent } from 'react';

const StyledControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '&.MuiFormControlLabel-root': {
        marginLeft: 0,
    },
    '.MuiFormControlLabel-label': {
        marginLeft: theme.spacing(1.25),
    },
    '& .MuiCheckbox-root': {
        padding: 0,
    },
}));

type LabeledCheckboxProps = {
    disabled: boolean;
    isChecked: boolean;
    label: React.ReactNode;
    onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    customIcon?: React.ReactSVGElement;
    customIconChecked?: React.ReactSVGElement;
} & CheckboxProps;

const LabeledCheckbox = ({
    disabled = false,
    isChecked = false,
    label = '',
    onChange,
    className = '',
    customIcon,
    customIconChecked,
    ...rest
}: LabeledCheckboxProps) => (
    <StyledControlLabel
        className={className}
        label={label}
        control={
            <Checkbox
                color="primary"
                disabled={disabled}
                checked={isChecked}
                onChange={onChange}
                icon={customIcon}
                checkedIcon={customIconChecked}
                {...rest}
            />
        }
    />
);

export default LabeledCheckbox;
