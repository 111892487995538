import { get, post } from '.';

const ORIGIN = `${window.location.origin}/`;

export const sendMagicLink = ({
    email,
    firstName,
    lastName,
    teamName,
    phone,
    role,
    imageUrl,
    redirectUrl,
}: {
    email?: string;
    firstName?: string;
    lastName?: string;
    teamName?: string;
    role?: string;
    phone?: string;
    imageUrl?: string;
    redirectUrl?: string;
}) =>
    post(
        'authenticate',
        {
            email,
            teamName,
            redirectUrl,
            origin: ORIGIN,
            useMagicLink: true,
            ...(firstName ? { firstName } : {}),
            ...(lastName ? { lastName } : {}),
            ...(phone ? { phone } : {}),
            ...(role ? { role } : {}),
            ...(imageUrl ? { imageUrl } : {}),
        },
        { requireToken: false, showErrorCode: true }
    );

export function isAuthenticationPartialSuccess(error: unknown): error is BizlyAPIError.Authentication {
    const expectedError = error as BizlyAPIError.Authentication;
    const teams = expectedError?.raw?.teams;
    return expectedError?.raw?.success === false && Array.isArray(teams) && teams.length === 0;
}

export function isSSOOnlyAccount(error: unknown): error is BizlyAPIError.Authentication {
    const expectedError = error as BizlyAPIError.Authentication;
    return expectedError?.raw?.success === false && typeof expectedError?.raw?.ssoLink === 'string';
}

export const loginWithGoogleId = ({
    email,
    googleIdToken,
    teamName,
    firstName,
    lastName,
    phone,
    role,
    imageUrl,
}: {
    email: string;
    googleIdToken: string;
    firstName?: string;
    lastName?: string;
    teamName?: string;
    role?: string;
    phone?: string;
    imageUrl?: string;
}) =>
    post(
        'authenticate',
        {
            email,
            googleIdToken,
            teamName,
            ...(firstName ? { firstName } : {}),
            ...(lastName ? { lastName } : {}),
            ...(phone ? { phone } : {}),
            ...(role ? { role } : {}),
            ...(imageUrl ? { imageUrl } : {}),
        },
        { requireToken: false, showErrorCode: true }
    ) as Promise<{
        user: BizlyAPI.User;
    }>;

export const exchangeMagicToken = (token: string) =>
    post('exchange-token', { token }, { requireToken: false }).catch(err => {
        console.error('Error at log in:', err);
        return err;
    });

export const acceptTeamInvite = (token: string, email: string) =>
    post('accept-team-invite', { token, email }, { requireToken: false }).catch(err => {
        console.error('Error at log in:', err);
        return err;
    });

export const getLoggedInUser = () => get('me', { requireToken: true }).then(json => json.user);
