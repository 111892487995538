import { Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useThemedColor from 'hooks/useThemedColor';
import React from 'react';
import styled from 'styled-components';

const ModalAction = styled.div`
    position: absolute;
    top: 50px;
    right: 100px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    cursor: pointer;
`;

const ModalGalleryContainer = styled.div`
    width: calc(100vw - 304px);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus-visible {
        outline: none;
    }
`;

const GalleryModal = ({
    children,
    isOpen,
    onClose,
}: {
    children?: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { pureBlack: translucentPureBlack } = useThemedColor(0.9);
    return (
        <Modal open={isOpen} onClose={onClose} BackdropProps={{ style: { background: translucentPureBlack } }}>
            <>
                <ModalAction>
                    <StyledCloseIcon onClick={onClose} />
                </ModalAction>
                <ModalGalleryContainer>{children}</ModalGalleryContainer>
            </>
        </Modal>
    );
};

export default GalleryModal;
