import { NotificationHeader } from 'components/NotificationHeader/NotificationHeader';
import { QueryParamEnum } from 'constants/queryParams';
import VenueSearch from 'pages/VenueSearch';
import { useLocation, useNavigate } from 'react-router-dom';

export enum EventFormFields {
    EVENT = 'event',
}

export type EventFormValues = {
    [EventFormFields.EVENT]: Bizly.Event;
};

export default function Venues() {
    screenY;
    const navigate = useNavigate();
    const location = useLocation();

    const viewVenueListingInPopup = (venueId: number) => {
        const params = new URLSearchParams(location.search);
        params.set(QueryParamEnum.VENUE_ID, venueId.toString());
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    return (
        <>
            <NotificationHeader />
            <VenueSearch<true> hideActions viewVenueListing={viewVenueListingInPopup} />
        </>
    );
}
