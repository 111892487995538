import { TSpacePOST } from 'api';
import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { i18n } from 'translation';
import { TSpace } from './EventSpacesForm/utils';
import { TAction } from './statusUtils';
import { FORM_SECTIONS, TProposalForm, TProposalFormUpdate } from './types';

export const isEmptyString = (str: string | null = '') => !(str || '').trim();

export const isEmptyStringOrNumber = (str: string | number | null = '') =>
    typeof str === 'string' ? isEmptyString(str || '') : typeof str !== 'number';

export const useFormSectionsHistory = (sections: FORM_SECTIONS[], initial = 0) => {
    const [sectionIndex, setSectionIndex] = useState(initial);

    const nextSection = () => {
        if (sectionIndex < sections.length - 1) setSectionIndex(index => index + 1);
    };

    const goBack = sectionIndex ? () => setSectionIndex(index => index - 1) : undefined;

    const restart = () => setSectionIndex(0);

    const goToDateStep = () => setSectionIndex(3);

    const goToEnd = () => setSectionIndex(sections.length - 1);

    return { sectionIndex, goBack, nextSection, restart, goToDateStep, goToEnd };
};

type TValidationFn = (isContinue?: boolean) => false | Partial<TProposalFormUpdate>;

type TValidator = Partial<{ section?: FORM_SECTIONS; validationFn: TValidationFn }>;

export const useFormValidator = (section: FORM_SECTIONS) => {
    const validator = React.useRef<TValidator>({});

    const validationFn = React.useCallback(
        (isContinue?: boolean) => {
            if (validator.current.section === section) {
                return validator.current?.validationFn === undefined ? {} : validator.current?.validationFn(isContinue);
            }

            return {};
        },
        [section]
    );

    const registerValidator = React.useCallback(
        (validationFn: TValidationFn) => (validator.current = { section, validationFn }),
        [section]
    );

    return { validationFn, registerValidator };
};

export function useRegisterValidator<T>(
    data: T,
    registerValidator: (validationFn: TValidationFn) => void,
    getErrorMessage: (data: T, isContinue?: boolean) => React.ReactNode,
    formDataToProposalForm: (data: T) => Partial<TProposalFormUpdate>
) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [snackbarKey, setSnackbarKey] = React.useState<string | number | undefined>();

    React.useEffect(
        () => () => {
            if (snackbarKey) closeSnackbar(snackbarKey);
        },
        [data, snackbarKey, closeSnackbar]
    );

    React.useEffect(() => {
        const validate = (isContinue?: boolean) => {
            const error = getErrorMessage(data, isContinue);
            if (!error) return formDataToProposalForm(data);

            setSnackbarKey(enqueueSnackbar(error, { variant: 'error', persist: true }) || undefined);
            return false;
        };
        registerValidator(validate);
    }, [enqueueSnackbar, data, formDataToProposalForm, getErrorMessage, registerValidator]);
}

export type TFormSectionProps = {
    onChange: () => void;
    onUpdateVenueSpaces: (space: TSpacePOST) => Promise<TSpace[] | false>;
    registerValidator: (validationFn: TValidationFn) => void;
    action: TAction;
    userChangedStatus: boolean;
    disabled?: boolean;
} & Partial<TProposalForm>;

export const useConfirmModal = ({
    showOnLoad,
    prompt,
    onConfirm,
    onDismiss: onDismissProp,
    onSuccess: onSuccessProp,
}: {
    showOnLoad: boolean;
    prompt: React.ReactNode;
    onConfirm: () => Promise<boolean>;
    onDismiss: () => void;
    onSuccess: () => void;
}) => {
    const [showModal, setShowModal] = React.useState(showOnLoad);
    const [loading, setLoading] = React.useState(false);

    const onProceed = React.useCallback(async () => {
        setLoading(true);
        const success = await onConfirm();
        setLoading(false);

        if (success) {
            setShowModal(false);
            onSuccessProp();
        }
    }, [onConfirm, setShowModal, setLoading, onSuccessProp]);

    const onDismiss = () => {
        setShowModal(false);
        onDismissProp();
    };

    const openModal = React.useCallback(() => setShowModal(true), [setShowModal]);

    const renderModal = () => (
        <DeleteConfirmationModal
            isActive={showModal}
            onDismiss={onDismiss}
            onProceed={onProceed}
            prompt={prompt}
            ctaLabel={i18n.button.yes}
            dismissLabel={i18n.button.no}
            loading={loading}
        />
    );

    return { renderModal, loading, openModal };
};

export const SCROLLBAR_WIDTH = 20; // at most
export const WIDE_PAGE_WIDTH = 1366 - SCROLLBAR_WIDTH;

export const sideWidth = 200;
export const topHeight = 140;
export const sidePadding = 18;
export const contentPadding = 36;
