import { TProposalForm } from 'components/ProposalForm/types';
import { deleteCall, get, post, put } from '.';

export type VenuePutType = {
    slug?: string;
    name?: string;
    headline?: string;
    website?: string;
    phone?: string;
    summary?: string;
    google_place_id?: string;
    chain_id?: number;
    travelstar_rating?: number;
    max_capacity?: number;
    room_count?: number;
};

export type GuestRoomResponse = { guestroomCategory: Bizly.GuestroomCategory; success: boolean };

export const getAllProposals = () => get(`proposals/`, { showErrorCode: true });

export const bizlyOSVenueSearch = (body = {}): Promise<{ venues: Bizly.Venue[]; meta: Bizly.SearchMeta }> =>
    post(`venues/search`, {
        ...body,
    }).then(res => ({ venues: res.venues, meta: res.searchMeta }));

export const getProposalForVenue = (venueId: number | string, proposalId: number | string) =>
    get(`venues/${venueId}/proposals/${proposalId}`, { showErrorCode: true }) as Promise<TProposalForm>;

export const getVenueMeta = (): Promise<{
    venueBrands: Bizly.VenueBrandsMeta[];
    venueTypes: Bizly.VenueTypeMeta[];
    success: boolean;
}> => get('venues/meta');

export const getVenueById = (venueId: string): Promise<{ venue: Bizly.Venue; success: boolean }> => {
    return get(`venues/${venueId}`);
};

export const getVenueImagesBatch = (
    venueId: string | number
): Promise<{ images: Bizly.VenueImage[]; success: boolean }> => {
    return post(`venues/${venueId}/images/batch`);
};

export const updateVenue = (
    venueId: string | number,
    data: VenuePutType
): Promise<{ venue: Bizly.Venue; success: boolean }> => {
    return put(`venues/${venueId}`, data);
};

export const getGuestRoomCategories = (
    venueId: string
): Promise<{ guestroomCategories: Bizly.GuestroomCategory[]; success: boolean }> => {
    return get(`venues/${venueId}/guestroom-categories`);
};

export const getGuestRoomCategoryType = (): Promise<{
    guestroomTypes: Bizly.GuestroomCategoryType[];
    success: boolean;
}> => {
    return get(`venues/guestroom-types`);
};

export const addGuestRoomCategory = (venueId: string, data: Bizly.GuestroomCategory): Promise<GuestRoomResponse> => {
    return post(`venues/${venueId}/guestroom-categories`, data);
};

export const updateGuestRoomCategory = (
    venueId: string,
    categoryId: string,
    data: Bizly.GuestroomCategory
): Promise<GuestRoomResponse> => {
    return put(`venues/${venueId}/guestroom-categories/${categoryId}`, data);
};

export const removeGuestRoomCategory = (
    venueId: string,
    ids: number[]
): Promise<any> => {
    return deleteCall(`venues/${venueId}/guestroom-categories`,{ids});
};
export const postVenueImagesBatch = (
    venueId: string | number,
    images: Partial<Bizly.VenueImage>[]
): Promise<{ images: Bizly.VenueImage[]; success: boolean }> => {
    return post(`venues/${venueId}/images/batch`, { images });
};
