import ThemedLogo from 'components/ui/ThemedLogo';
import useThemedColor from 'hooks/useThemedColor';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Column, FixedBackground } from 'ui';

const CenteredColumn = styled(Column)`
    height: 100%;
    box-sizing: border-box;

    justify-content: flex-start;

    margin: 0 auto;
    padding-top: 100px;
`;

const PaddedLogo = styled(ThemedLogo)`
    position: absolute;
    padding: 36px;
`;

export default function LogoBackground(props: PropsWithChildren) {
    const { brand } = useThemedColor();

    return (
        <CenteredColumn>
            <FixedBackground backgroundColor={brand}>
                <PaddedLogo />
            </FixedBackground>
            {props.children}
        </CenteredColumn>
    );
}
