import { Check } from '@mui/icons-material';
import Step from '@mui/material/Step';
import MuiStepLabel from '@mui/material/StepLabel';
import MuiStepper from '@mui/material/Stepper';
import colorFns from 'colorFns';
import styled from 'styled-components';

const Stepper = styled(MuiStepper)<{ large?: boolean }>`
    color: ${colorFns.pureWhite};

    .MuiStepConnector-line {
        border-color: ${({ large, ...props }) =>
            large ? colorFns.pureWhite.alpha(0)(props) : colorFns.pureWhite.alpha(0.5)(props)};

        &::after {
            content: '';
            position: absolute;
            display: block;

            background-color: ${colorFns.primaryAction};
            border-radius: 2px;

            transition: 0.15s 0.15s all ease-out;
            transition-property: height, width;
        }
    }

    .Mui-active, .Mui-completed {
        .MuiStepConnector-line::after {
            transition: 0.15s all ease-out;
            transition-property: height, width;
        }
    }

    /* Horizontal */

    ${({ large }) =>
        large
            ? `
    .MuiStep-horizontal {
        min-width: 90px;
    }
    `
            : ''}

    .MuiStepConnector-horizontal {
        .MuiStepConnector-line {
            min-width: 60px;
            transform: translateY(-1px);
            border-top-width: 2px;

            &::after {
                height: 4px;
                width: 0px;

                transform: translateY(-3px) translateX(-2px);
            }
        }

        &.Mui-active,
        &.Mui-completed {
            .MuiStepConnector-line::after {
                width: 100%;
                padding: 0 2px;
            }
        }
    }

    /* Vertical */

    .MuiStepConnector-vertical {
        .MuiStepConnector-line {
            min-height: 60px;
            transform: translateX(-1px);
            border-left-width: 2px;

            &::after {
                height: 0px;
                width: 4px;

                transform: translateX(-3px) translateY(-2px);
            }
        }

        &.Mui-active,
        &.Mui-completed {
            .MuiStepConnector-line::after {
                height: 64px;
            }
        }
    }

    /* Alternative Label */

    &.MuiStepper-alternativeLabel {
        min-width: 700px;
        padding: 0;
        padding-top: 6px;

        .MuiStepLabel-alternativeLabel {
            margin-right: 0;
        }

        .MuiStepConnector-alternativeLabel {
            ${({ large }) => (large ? 'top: 20px;' : '')}
            left: calc(-50% + 12px);
            right: calc(50% + 12px);
        }
    }
`;

const StepLabel = styled(MuiStepLabel)<{ inverted?: boolean; large?: boolean }>`
    .MuiStepIcon-root {
        color: ${colorFns.pureWhite.alpha(0.5)};
        transition: 0.15s 0s all ease-out;
        ${({ large }) =>
            large
                ? `
        width: 40px;
        height: 40px;
        `
                : ''}
        &.Mui-completed, &.Mui-active {
            color: ${colorFns.primaryAction};
        }
    }

    .MuiStepLabel-iconContainer {
        margin-right: 24px;
    }

    .MuiStepLabel-label {
        color: ${({ inverted, ...props }) =>
            inverted ? colorFns.pureBlack(props) : colorFns.pureWhite(props)} !important;
        opacity: 0.5;
        transition: 0.15s 0s all ease-out;

        &.Mui-completed,
        &.Mui-active {
            opacity: 1;
        }
    }
`;

const Circle = styled.div<{ empty?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: ${({ empty, ...props }) => (empty ? colorFns.pureWhite(props) : colorFns.primaryAction(props))};
    border: 1px solid;
    border-color: ${({ empty, ...props }) =>
        empty ? colorFns.pureBlack.alpha(0.3)(props) : colorFns.primaryAction(props)};
`;

const CheckIcon = styled(Check)`
    width: 24px;
    height: 24px;
`;

function BigStep(props: { active?: boolean; completed?: boolean; className?: string }) {
    const { active, completed, className } = props;

    return (
        <Circle className={className} empty={!active && !completed}>
            {completed && <CheckIcon />}
        </Circle>
    );
}

const StepIconProps = { icon: '' };

type TProgressTracker<TKey extends string | number> = {
    sections: { key: TKey; label: string }[];
    currentSection: number;
    horizontal?: boolean;
    inverted?: boolean;
    large?: boolean;
    onClick?: (key: TKey) => void;
};

export default function ProgressTracker<TKey extends string | number>({
    currentSection,
    sections,
    horizontal,
    inverted,
    large,
    onClick,
}: TProgressTracker<TKey>) {
    return (
        <Stepper
            activeStep={currentSection}
            orientation={horizontal ? 'horizontal' : 'vertical'}
            alternativeLabel={horizontal}
            large={large}
        >
            {sections.map(({ key, label }) => (
                <Step key={key} onClick={() => onClick?.(key)}>
                    <StepLabel
                        StepIconProps={StepIconProps}
                        {...(large ? { StepIconComponent: BigStep, large: true } : {})}
                        inverted={inverted}
                    >
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}
