import ConfirmationModal from 'components/ConfirmationModal';
import React from 'react';
import { Location, useBlocker } from 'react-router-dom';
import { i18n } from 'translation';

export default function useUnsavedPrompt(when?: boolean, isLocationSame?: (newLocation: Location) => boolean) {
    React.useEffect(() => {
        const listener = (event: BeforeUnloadEvent) => {
            if (when) {
                event.preventDefault();
                (event || window.event).returnValue = '';
            }
        };

        window.addEventListener('beforeunload', listener);
        return () => window.removeEventListener('beforeunload', listener);
    }, [when]);

    // Block navigating elsewhere when data has been entered into the input
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => !!when && currentLocation.pathname !== nextLocation.pathname
    );

    const renderPrompt = () =>
        blocker.state === 'blocked' ? (
            <ConfirmationModal
                isActive={blocker.state === 'blocked'}
                warning
                onDismiss={() => blocker.reset()}
                onProceed={() => blocker.proceed()}
                prompt={i18n.modal.unsavedPrompt}
                ctaLabel={i18n.button.yes}
                dismissLabel={i18n.button.cancel}
            />
        ) : null;

    return renderPrompt;
}
