import colorFns from 'colorFns';
import BackArrowSvg from 'images/icons/StealthLeftArrow.svg?react';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, InlineRow } from 'ui';

const SidebarLink = styled.span<{ active?: boolean; icon?: string; disabled?: boolean }>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    padding: 11px 10px;
    display: flex;
    align-items: center;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

    ${props =>
        props.active &&
        `
        background: ${colorFns.sidenavForeground(props)};
        color: ${colorFns.brand(props)};
        font-weight: 600;
        border-radius: 2px;
        box-shadow: 0px 2px 4px 0px ${colorFns.brand(props)}; 

        svg {
            color: ${colorFns.brand(props)};
        }
    `}
`;

const BackArrowIcon = styled(BackArrowSvg)`
    height: 11px;
    width: 20px;
`;

const BackLink = styled(Link)`
    color: ${colorFns.pureWhite};
`;

const BackAnchor = styled.a`
    color: ${colorFns.pureWhite};
    cursor: pointer;
`;

type RouteLink = {
    key: string | number;
    active?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
};

export default function GenericNavButtons({
    backTo,
    onBack,
    links,
}: Readonly<{
    backTo?: LinkProps['to'];
    onBack?: () => void;
    links?: RouteLink[];
}>) {
    return (
        <Column itemSpacing="medium">
            {backTo && (
                <BackLink to={backTo}>
                    <InlineRow alignItems="baseline" itemSpacing="smallish">
                        <BackArrowIcon />
                        {i18n.button.back}
                    </InlineRow>
                </BackLink>
            )}
            {onBack && (
                <BackAnchor onClick={onBack}>
                    <InlineRow alignItems="baseline" itemSpacing="smallish">
                        <BackArrowIcon />
                        {i18n.button.back}
                    </InlineRow>
                </BackAnchor>
            )}
            {links?.map(({ children, ...props }) => <SidebarLink {...props}>{children}</SidebarLink>)}
        </Column>
    );
}
