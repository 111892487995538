import ToggleButtonGroup, { ToggleButtonGroupProps, toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

export interface SwitchGroupProps extends ToggleButtonGroupProps {}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme: { spacing, getColor, EColors } }) => ({
    [`&.${toggleButtonGroupClasses.root}`]: {
        padding: spacing(0.75),
        backgroundColor: getColor(EColors.dashboardCard),
    },
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        padding: spacing(0.75),
        lineHeight: 1,
        border: 'none',
        [`&.${toggleButtonGroupClasses.selected}`]: {
            borderRadius: 6,
            border: `1px ${getColor(EColors.softBorder)}`,
            backgroundColor: getColor(EColors.pureWhite),
        },
    },
}));

export const SwitchGroup = (props: SwitchGroupProps) => {
    return <StyledToggleButtonGroup {...props} />;
};
