import useKey from 'hooks/useKey';
import useThemedColor from 'hooks/useThemedColor';
import React from 'react';
import styled from 'styled-components';
import { BackgroundWithClose, Column, Row } from 'ui';
import ParcelPreviewCard, { ParcelPreviewCardProps } from '../ParcelPreviewCard';

const CenteredColumn = styled(Column)`
    padding: 112px 0;
    margin: auto;
`;

const IconsHeadCentered = styled(Row)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    justify-content: center;
    align-items: center;

    > svg:not(:last-child) {
        margin-right: 32px;
    }
`;

const EditParcelPreview = ({ onClose, ...preview }: { onClose: () => void } & ParcelPreviewCardProps) => {
    const { lightGrey } = useThemedColor();

    useKey('Escape', onClose);

    return (
        <CenteredColumn>
            <IconsHeadCentered style={{ height: 112 }} />
            <BackgroundWithClose backgroundColor={lightGrey} onClose={onClose} />
            <ParcelPreviewCard {...preview} />
        </CenteredColumn>
    );
};

export default EditParcelPreview;
