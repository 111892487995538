import { isBlankTemplateId } from 'api/eventTemplates';
import { EventSettingsFields } from 'components/Events/EventSettingsHeaderSection';
import Form from 'components/Form';
import { getPassthroughProps } from 'components/Form/utils';
import PlaceInput from 'components/PlaceInput';
import { Spinner } from 'components/Spinner';
import Button from 'components/ui/Button';
import { Moment } from 'moment';
import { Link } from 'react-router-dom';
import { i18n } from 'translation';
import { RichTextCopy } from 'ui';

export type City = {
    location: Bizly.Event['location'];
    googlePlaceId: Bizly.Event['googlePlaceId'];
};

type TCityField = {
    field: string;
    defaultValue: City;
    error: object | null;
    onChange: ({ value, field, error }: { value: City; field: string; error: object | null }) => void;
};

const CityField = ({ field, onChange: onChangeProp, defaultValue }: TCityField) => (
    <PlaceInput
        asFormField
        inModalOrPopover
        onChange={(location: string, googlePlaceId: string) =>
            onChangeProp({
                field,
                error: {},
                value: {
                    location,
                    googlePlaceId,
                },
            })
        }
        defaultValue={defaultValue?.location}
        defaultValueId={defaultValue?.googlePlaceId}
    />
);

export type EventForm = Partial<
    {
        city: City;
        templateId: string | number;
        cventEventId: string | number;
    } & Pick<Bizly.Event, 'name' | 'cventId' | 'type' | 'costCenter' | 'budget' | 'format' | 'department'>
>;

type TemplateOption = Omit<Bizly.EventTemplate, 'name'> & { name?: JSX.Element | string };
export type CventOption = BizlyAPI.CventEvent & { id: string | number; name?: JSX.Element | string };

const AugmentedTemplatesField = ({
    field,
    value,
    onChange,
    options,
    playbookDescription,
    onViewTemplate,
}: {
    field: string;
    value: number;
    onChange: (update: { field: string; value: number }) => void;
    options: object[];
    playbookDescription?: string;
    onViewTemplate: (value: number) => void;
}) => {
    const fields = {
        [field]: {
            type: 'select',
            options: {
                options,
            },
        },
    };

    const ViewPlaybookField = {
        type: 'display',
        options: {
            label: (
                <Link to={`/playbooks/${value}`}>
                    <Button
                        onClick={() => {
                            onViewTemplate && onViewTemplate(value);
                        }}
                        width={130}
                    >
                        {i18n.homepage.createMeetingModal.viewPlaybook}
                    </Button>
                </Link>
            ),
        },
    };

    const schema = [
        {
            fields: [field, ...(value && !isBlankTemplateId(value) ? [ViewPlaybookField] : [])],
            spacing: false,
        },
    ];

    const { value: valueProp, onChange: onChangeProp } = getPassthroughProps({ field, value, onChange });

    return options.length ? (
        <>
            <Form
                fields={fields}
                schema={schema}
                value={{ ...valueProp, playbookDescription }}
                onChange={onChangeProp}
            />
            {playbookDescription && <RichTextCopy dangerouslySetInnerHTML={{ __html: playbookDescription || '' }} />}
        </>
    ) : (
        <Spinner suppressMargin />
    );
};

const CventSelectField = ({
    field,
    value,
    onChange,
    options,
    loadingCventEvents,
}: {
    field: string;
    value: number;
    onChange: (update: { field: string; value: number }) => void;
    options: object[];
    loadingCventEvents: boolean;
}) => {
    const fields = {
        [field]: {
            type: 'select',
            options: {
                options,
            },
        },
    };
    const schema = [
        {
            fields: [field],
            spacing: false,
        },
    ];

    const { value: valueProp, onChange: onChangeProp } = getPassthroughProps({ field, value, onChange });

    return !loadingCventEvents ? (
        <>
            <Form fields={fields} schema={schema} value={{ ...valueProp }} onChange={onChangeProp} />
        </>
    ) : (
        <Spinner suppressMargin />
    );
};

type EventCreateFieldProps = {
    meetingInternalReferenceFieldLabel?: string;
    meetingCostCenterFieldLabel?: string;
    disabledFields?: string[];
    requiredFields?: Bizly.EventCreateInternalFields[];
    templates?: TemplateOption[];
    cvents?: CventOption[];
    city?: City | null;
    playbookDescription?: string;
    budgetRequired?: boolean;
    loadingCventEvents: boolean;
    onViewTemplate: () => void;
    costCenterOptions?: Nullable<string[]>;
    departmentOptions?: Nullable<string[]>;
};

export const EventCreateFields = ({
    meetingInternalReferenceFieldLabel,
    meetingCostCenterFieldLabel,
    disabledFields,
    requiredFields,
    templates,
    cvents,
    city,
    playbookDescription,
    budgetRequired,
    loadingCventEvents,
    onViewTemplate,
    costCenterOptions,
    departmentOptions,
}: EventCreateFieldProps) => ({
    ...EventSettingsFields({
        internalReferencePrompt: meetingInternalReferenceFieldLabel,
        meetingCostCenterFieldLabel,
        disabledFields,
        requiredFields,
        costCenterOptions,
        departmentOptions,
    }),
    name: {
        prompt: i18n.homepage.createMeetingModal.meetingName,
        type: 'text',
        disabled: disabledFields?.includes('name'),
        options: {
            autoFocus: true,
        },
    },
    city: {
        prompt: i18n.homepage.createMeetingModal.location,
        type: CityField,
        disabled: disabledFields?.includes('city'),
        ...(city && {
            options: {
                defaultValue: city,
            },
        }),
    },
    budget: {
        prompt: i18n.homepage.createMeetingModal.budget,
        type: 'text',
        optional: !budgetRequired,
        disabled: disabledFields?.includes('budget'),
        perRow: 2,
    },
    templateId: {
        prompt: i18n.homepage.createMeetingModal.playbook,
        type: AugmentedTemplatesField,
        disabled: disabledFields?.includes('templateId'),
        options: {
            options: templates,
            playbookDescription: playbookDescription,
            onViewTemplate,
        },
        optional: true,
    },
    cventEventId: {
        prompt: i18n.homepage.createMeetingModal.cventEvent,
        type: CventSelectField,
        disabled: disabledFields?.includes('cventEventId'),
        options: {
            options: cvents,
            loadingCventEvents: loadingCventEvents,
        },
        optional: true,
    },
    format: {
        prompt: i18n.meetingsPage.meetingFormat,
        type: 'radioselect',
        options: {
            options: [i18n.meetingsPage.inPerson, i18n.meetingsPage.hybrid, i18n.meetingsPage.virtual],
        },
        optional: true,
        perRow: '2/3',
    },
});

export const EventCreateSchema = (hiddenFields: Bizly.EventCreateInternalFields[] = [], includeCventEvents = false) => [
    ...(includeCventEvents
        ? [
              {
                  key: 'cventEventId',
                  fields: ['cventEventId'],
                  spacing: 'smallish',
              },
          ]
        : []),
    {
        key: 'nameRow',
        fields: ['name'],
        spacing: 'smallish',
    },
    {
        key: 'city',
        fields: ['city'],
        spacing: 'smallish',
    },
    {
        key: 'budgetAndCostCenter',
        fields: ['budget', 'costCenter'].filter(field => !(hiddenFields as string[]).includes(field)),
        spacing: 'smallish',
    },
    ...(hiddenFields.includes('cventId')
        ? []
        : [
              {
                  key: 'cventId',
                  fields: ['cventId', 'department'],
                  spacing: 'smallish',
              },
          ]),
    ...(hiddenFields.includes('type')
        ? []
        : [
              {
                  key: 'type',
                  fields: ['type'],
                  spacing: 'smallish',
              },
          ]),
];
export const EventCreateSchemaWithTemplate = (
    hiddenFields?: Bizly.EventCreateInternalFields[],
    includeCventEvents = false
) => [
    {
        key: 'templateId',
        fields: ['templateId'],
        spacing: 'smallish',
    },
    ...EventCreateSchema(hiddenFields, includeCventEvents),
];

export const toBizlyEvent = ({ name, cventId, budget, type, costCenter, city, templateId, department }: EventForm) => ({
    name,
    type,
    cventId,
    budget,
    costCenter,
    templateId,
    department,
    ...city,
});

export const validateForm = (
    { name, cventId, type, costCenter, city, budget, department }: EventForm,
    budgetRequired = false,
    requiredFields: Bizly.EventCreateInternalFields[] = []
) => {
    if (!name?.trim()) return false;

    if (requiredFields.includes('cventId') && !cventId?.toString().trim()) return false;

    if (requiredFields.includes('department') && !department?.toString().trim()) return false;

    if (requiredFields.includes('type') && !type) return false;

    if (requiredFields.includes('costCenter') && !costCenter?.trim()) return false;

    if (!city?.location || !city?.googlePlaceId) return false;

    return !(budgetRequired && !String(budget ?? '').trim());
};

export type EventCreateForm = Partial<{
    eventName: string;
    eventDescription: string;
    requirements: string[];
    eventDate: {
        start?: Moment | null;
        end?: Moment | null;
    };
    guests: number;
    location: {
        location: string;
        googlePlaceId?: string;
    } | null;
    payment: {
        mode: string;
        cost: number;
    };
    costCenter: string;
    cventId: string;
    department: string;
    meetingType: string;
}>;

export type EventCreateFormErrors = Partial<Record<keyof EventCreateForm, boolean>>;
