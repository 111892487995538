import createStore from '../';

export enum units {
    sqft = 'sq. ft',
    sqm = 'sq. m',
    meter = 'meter',
    kilometer = 'km',
    mile = 'mile',
}

export const areaUnits = [units.sqft, units.sqm] as const;
export const distanceUnits = [units.meter, units.mile, units.kilometer] as const;

export type AreaUnit = ArrayType<typeof areaUnits>;
export type DistanceUnit = ArrayType<typeof distanceUnits>;
type DistanceUnitState = units.meter | units.mile;

type IENavigator = Navigator & { userLanguage?: Navigator['language'] };
const locale = (window.navigator as IENavigator).userLanguage || window.navigator.language;

type State = {
    area: AreaUnit;
    distance: DistanceUnitState;
};

export type UnitKeys = keyof State;

type Store = State;

const initialState: State =
    locale === 'en-US'
        ? {
              area: units.sqft,
              distance: units.mile,
          }
        : {
              area: units.sqm,
              distance: units.meter,
          };

export const useMeasurementUnits = createStore<Store>(() => initialState);

const { setState, getState } = useMeasurementUnits;

const LOCAL_STORAGE_KEY = 'measurementUnits';

const isState = (parsed: object): parsed is State => {
    const stateKeys = new Set(Object.keys(initialState));

    return Object.keys(parsed).some(key => stateKeys.has(key));
};

export const measurementUnitsActions = {
    load: () => {
        const loadedUnits = localStorage.getItem(LOCAL_STORAGE_KEY);
        const parsedUnits = loadedUnits && JSON.parse(loadedUnits);
        if (parsedUnits && isState(parsedUnits)) {
            setState(parsedUnits);
        }
    },

    save: (units: Partial<State>) => {
        setState(units);

        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(getState()));
    },
};
