import { default as PopperMui, PopperProps } from '@mui/material/Popper';
import PopperJs from 'popper.js';

export default function Popper(
    props: PopperProps & {
        modifiers?: PopperJs.Modifiers;
        popperOptions?: PopperJs.PopperOptions;
        color?: Themed.Color;
    }
) {
    return <PopperMui {...props} />;
}
