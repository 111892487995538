import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import emailAnimationData from 'animations/magic-email.json';
import Lottie from 'lottie-react';

const AnimationContainer = styled(Box)({
    width: '5rem',
    height: '5rem',
    marginBottom: '1rem',
});

export const InviteSuccessComponent = () => {
    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <AnimationContainer>
                    <Lottie animationData={emailAnimationData} loop={false} />
                </AnimationContainer>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Invites sent!
                </Typography>
            </Box>

            <Typography>
                You colleague will receive an email to activate their account. The link expires in 24 hours and can only
                be used once.
            </Typography>
        </Box>
    );
};
