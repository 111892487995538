import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@mui/material';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import AgendaSection from 'components/EventDashboard/AgendaSection';
import DashboardCard from 'components/EventDashboard/DashboardCard';
import DeleteModalForm from 'components/EventDashboard/DeleteModalForm';
import EventDetails from 'components/EventDashboard/EventDetails';
import EventForm from 'components/EventDashboard/EventForm';
import VenueSection from 'components/EventDashboard/VenueSection';
import HowToPopper from 'components/HowToPopper/HowToPopper';
import { SpinnerOverlay } from 'components/Spinner';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import { useAppConfigContext } from 'providers/app-config';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useMemo, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useCvent } from 'stores/cvent';
import { isUpdating, meetingsActions, selectMeeting, useMeetings } from 'stores/meetings';
import { useSchedule } from 'stores/schedule';
import styled from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Button, Row, Column as UIColumn } from 'ui';
import { getHeaderHeight } from 'utils/header';

const CardsColumn = styled(UIColumn)`
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    padding: 15px 15px 30px;
    border-radius: 8px;
`;
const DashboardCols = styled(Row)`
    height: 100%;

    > ${CardsColumn} {
        flex-basis: 60%;
        flex-grow: 1;
        flex-shrink: 1;
    }
`;

const Dashboard = styled(Row)<{ headerHeight: number; pagePadding: number }>`
    display: grid;
    max-width: 1600px;

    padding-inline: ${({ pagePadding }) => pagePadding}px;
    box-sizing: border-box;

    min-height: 100%;

    justify-content: initial;
`;

const DashboardTopActions = styled(Box)`
    padding-block: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledButtonsContainer = styled(Box)<{ isEditing: boolean }>`
    display: flex;
    flex-direction: ${({ isEditing }) => (isEditing ? 'row-reverse' : 'row')};
`;

const StyledEditButton = styled(Button)<{ isEditing: boolean }>`
    &.MuiButton-root {
        height: 36.5px;
        padding-inline: 2.45em;
        background-color: ${({ theme }) => theme.getColor(EColors.pureWhite)};
        border: ${({ isEditing }) => (isEditing ? `none` : `1px solid ${EColors.primaryAction}`)};
        color: ${({ isEditing }) => (isEditing ? EColors.black : EColors.primaryAction)};
    }
`;
const StyledCancelButton = styled(Button)<{ isEditing: boolean; disabled: boolean }>`
    &.MuiButton-root {
        padding-inline: 1.88em;
        height: 36.5px;
        min-width: 5.875rem;
        background-color: ${({ isEditing, disabled, theme }) => {
            if (disabled) {
                return theme.getColor(EColors.lightGrey);
            }
            return isEditing ? theme.getColor(EColors.primaryAction) : theme.getColor(EColors.pureWhite);
        }};
        color: ${({ isEditing, disabled, theme }) => {
            if (disabled) {
                return theme.getColor(EColors.lightGrey);
            }
            return isEditing ? theme.getColor(EColors.pureWhite) : theme.getColor(EColors.cancelledOrRejectedStatus);
        }};
    }
`;

const StyledGrid = styled(Grid)`
    flex: 0 0 40%;
    height: 100%;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dashboardCard)};
`;

const TopHeading = styled(Typography)`
    font-size: 28px;
    font-weight: 400;
`;

type TEventDashboard = {
    viewVenueListing: (venueId: number) => void;
    viewProposal: (venue?: Bizly.Venue) => void;
    goToVenueSearch: () => void;
    viewInquiries: () => void;
    pagePadding?: number;
};

type EventFormRef = {
    onSubmit: () => void;
};

export default function EventDashboard({
    viewVenueListing,
    viewProposal,
    goToVenueSearch,
    viewInquiries,
    pagePadding = 0,
}: TEventDashboard) {
    const { user, toggles } = useUser();
    const { event, suggestedVenues, refreshEvent, reloadProvider } = useEvent();
    const { cventEvents } = useCvent();
    const { transparentHeader } = useAppConfigContext();
    const meetingUpdating = useMeetings(isUpdating(event.id));
    const meeting = useMeetings(selectMeeting(event.id));
    const [isEditing, setIsEditing] = useState(false);
    const [isEventFormSubmitting, setIsEventFormSubmitting] = useState(false);
    const [cancellationNotes, setCancellationNotes] = useState('');

    const eventFormRef = useRef<EventFormRef>(null);

    const { enqueueSnackbar } = useSnackbar();
    const { modalShown, showModal, hideModal } = useShowModal();

    const headerHeight = getHeaderHeight(transparentHeader);

    const schedule = useSchedule();

    const scheduleItems =
        (schedule.loaded &&
            schedule.eventId === event.id &&
            schedule.items.filter(item => item.date && item.startTime && item.endTime)) ||
        [];
    const hasScheduleItems = schedule.loaded && scheduleItems.length > 0;

    const agendasEnabled = user.team?.useAgendas || hasScheduleItems;

    const bookingDashboardCard = (
        <DashboardCard scrollable>
            <VenueSection
                suggestedVenues={suggestedVenues}
                eventId={event.id}
                editable={event.editable && !event.cancelledAt}
                eventName={event.name}
                location={event.location}
                googlePlaceId={event.googlePlaceId}
                lat={event.lat}
                lng={event.lng}
                startsAt={event.startsAt}
                endsAt={event.endsAt}
                viewVenueListing={viewVenueListing}
                viewProposal={viewProposal}
                onSearch={goToVenueSearch}
                onEditInquiries={viewInquiries}
                cancelledAt={event.cancelledAt}
            />
        </DashboardCard>
    );

    const cventName = useMemo(() => {
        const cvent = cventEvents.find(cv => cv.cventId === event.cventId);
        return cvent?.meetingName ?? i18n.meetingsPage.none;
    }, [cventEvents, event.cventId]);

    const onCloseEditing = (success?: boolean) => {
        setIsEditing(false);
        if (success) {
            refreshEvent();
        }
    };

    if (toggles.gate.createMeetingsFlow) {
        return <Navigate to={`/events/${event.id}`} replace />;
    }

    const handleEventFormSubmit = () => {
        eventFormRef.current?.onSubmit();
    };

    const onDefaultCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        showModal();
        e.stopPropagation();
    };

    const cancelMeeting = async () => {
        if (!cancellationNotes) {
            return enqueueSnackbar(i18n.meetingDashboard.cancelError, { variant: 'error' });
        }
        await meetingsActions.cancel(event.id, cancellationNotes);
        await refreshEvent();
        reloadProvider();
        hideModal();
    };

    const getEditButtonProps = () => {
        if (isEditing) {
            return {
                onClick: () => {
                    onCloseEditing();
                },
                text: i18n.button.cancel,
            };
        } else {
            return {
                onClick: () => {
                    setIsEditing(true);
                },
                text: i18n.button.edit,
            };
        }
    };

    const getCancelButtonProps = () => {
        if (isEditing) {
            return {
                onClick: () => {
                    handleEventFormSubmit();
                },
                text: event?.id ? i18n.button.saveChanges : i18n.button.create,
            };
        } else {
            return {
                onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                    onDefaultCancel(e);
                },
                text: i18n.button.cancelEvent,
            };
        }
    };

    const { onClick, text } = getEditButtonProps();
    const { onClick: onClickCancel, text: textCancel } = getCancelButtonProps();

    return (
        <>
            {!meeting ? (
                <SpinnerOverlay />
            ) : (
                <Dashboard headerHeight={headerHeight} pagePadding={pagePadding}>
                    <DashboardTopActions>
                        <Box display="flex" gap={1} alignItems="center">
                            <TopHeading>{i18n.meetingsPage.eventDetails}</TopHeading>
                            <HowToPopper sectionId="eventDetails" popperPlacement="right-start" />
                        </Box>
                        <Box>
                            {!event.cancelledAt && (
                                <StyledButtonsContainer isEditing={isEditing} display="flex" gap={1}>
                                    <StyledCancelButton
                                        variant="text"
                                        isEditing={isEditing}
                                        onClick={onClickCancel}
                                        disabled={isEventFormSubmitting}
                                    >
                                        {textCancel}
                                    </StyledCancelButton>
                                    <StyledEditButton isEditing={isEditing} onClick={onClick} variant="outlined">
                                        {text}
                                    </StyledEditButton>
                                </StyledButtonsContainer>
                            )}
                        </Box>
                    </DashboardTopActions>
                    <Spacer small />
                    <DashboardCols itemSpacing="default" justifyContent="center">
                        <CardsColumn itemSpacing="default">
                            {!isEditing ? (
                                <EventDetails cventName={cventName} />
                            ) : (
                                <EventForm
                                    setSubmitting={setIsEventFormSubmitting}
                                    submitting={isEventFormSubmitting}
                                    ref={eventFormRef}
                                    event={event}
                                    onClose={onCloseEditing}
                                />
                            )}
                        </CardsColumn>

                        <StyledGrid container>
                            <Grid item xs={12}>
                                {agendasEnabled ? (
                                    <DashboardCard>
                                        <AgendaSection />
                                    </DashboardCard>
                                ) : (
                                    <Box>{bookingDashboardCard}</Box>
                                )}
                            </Grid>
                        </StyledGrid>
                    </DashboardCols>

                    <DeleteConfirmationModal
                        isActive={modalShown && !event.cancelledAt}
                        wide
                        onDismiss={hideModal}
                        onProceed={cancelMeeting}
                        ctaLabel={i18n.button.proceed}
                        prompt={
                            <DeleteModalForm
                                cancellationNotes={cancellationNotes}
                                setCancellationNotes={setCancellationNotes}
                                userCancellationMessage={user.team?.cancellationInformationMessage}
                                userTeamName={user.team?.name}
                            />
                        }
                        loading={meetingUpdating}
                    />
                </Dashboard>
            )}
        </>
    );
}
