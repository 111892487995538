import { Dialog, DialogContent } from '@material-ui/core';
import { addDays, parseISO } from 'date-fns';
import fontFns from 'fontFns';
import { useSnackbar } from 'notistack';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { tzMoment } from 'utils/moment';
import { getPaymentCredentials, submitPaymentCard } from '../../api';
import { useEvent } from '../../providers/event';
import { useUser } from '../../providers/user';
import { Column, Copy, LabeledCheckbox, Row, Spacer } from '../../ui';
import { formatCurrency } from '../../util';
import Form from '../Form';
import { SpinnerOverlay } from '../Spinner';
import TextButton from '../ui/Button/TextButton';
import { H2Headline } from '../ui/Headline';
import ViewPaymentCard from './ViewPaymentCard';

const TIME_FORMAT = 'LL';

const PaddedDialogContent = styled(DialogContent)`
    padding: 32px !important;
`;

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
`;

const Label = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
    margin-right: 12px;
    white-space: nowrap;
`;

const TextRowText = styled(Copy)`
    text-align: right;
`;

const SpendLabel = styled(Copy)`
    letter-spacing: -0.1px;
`;

const SpendAmount = styled(Copy)`
    font-size: 40px;
    font-weight: 300;
    line-height: 0.95;
    letter-spacing: -1.22px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.displayPricing)};
`;

const SpacedColumn = styled(Column)`
    > *:not(:last-child) {
        margin-bottom: 12px;
    }
`;

const ActiveDates = styled(SpacedColumn)`
    width: 170px;
`;

const SpacedRow = styled(Row)`
    align-items: flex-start;
    justify-content: space-between;
`;

const TextRow = ({ label, value }: { label: string; value?: React.ReactText }) => (
    <SpacedRow>
        <Label>{label}</Label>
        <TextRowText>{value}</TextRowText>
    </SpacedRow>
);

const ModalColumns = styled(Row)`
    & > ${SpacedColumn} {
        flex-basis: 50%;

        &:not(:first-child) {
            margin-left: 60px;
        }
    }
`;

const UserIdField = ({ userId, setUserId }: { userId: string; setUserId: (userId: string) => void }) => (
    <Form
        fields={{
            userId: {
                type: 'text',
                prompt: 'User ID:',
            },
        }}
        schema={[{ key: 'userId', fields: ['userId'], spacing: false }]}
        value={{ userId }}
        onChange={({ value }: { value: { userId: string } }) => setUserId(value.userId.trim())}
    />
);

const Checkbox = styled(LabeledCheckbox)`
    margin-right: 0 !important;
`;

const ButtonsRow = styled(Row)`
    justify-content: flex-end;

    > *:not(:first-child) {
        margin-left: 15px;
    }
`;

type TCredentials = {
    start: string;
    end: string;
    expDate: string;
    cardNumber: string;
    securityCode: string;
    zipCode: string;
};

const formatCredentials = ({
    paymentCard,
    cardDetails,
}: {
    paymentCard: Bizly.PaymentCard;
    cardDetails: Bizly.CardDetails;
}) => {
    const { activeAt, inactiveAt, expMonth, expYear } = paymentCard;
    const { cardNumber, securityCode, zipCode } = cardDetails;
    return {
        start: tzMoment(activeAt).format(TIME_FORMAT),
        end: tzMoment(inactiveAt).format(TIME_FORMAT),
        expDate: `${expMonth < 10 ? '0' + expMonth : expMonth}/${expYear}`,
        cardNumber,
        securityCode,
        zipCode,
    };
};

type TVPaymentModal = {
    paymentCardRules: Bizly.PaymentCardRules;
    estimates: Bizly.Estimates;
    paymentCard: Bizly.PaymentCard;
    booking: Bizly.Venue['booking'];
    onClose: () => void;
    updatePaymentCard: (paymentCard: Bizly.PaymentCard) => void;
};

export default function VPaymentModal({
    paymentCardRules,
    estimates,
    paymentCard,
    booking,
    onClose,
    updatePaymentCard,
}: TVPaymentModal) {
    const { event } = useEvent();
    const { user } = useUser();

    const [credentials, setCredentials] = React.useState<TCredentials>();
    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (paymentCard?.id) {
            setLoading(true);
            getPaymentCredentials(paymentCard.id)
                .then(resp => setCredentials(formatCredentials(resp)))
                .catch(e => setError(true))
                .finally(() => setLoading(false));
        }
    }, [paymentCard]);

    const { firstName, lastName, email } = user;
    const fullName = [firstName || '', lastName || ''].join(' ').trim();
    const { name: eventName, cventId: internalReference, endsAt } = event;

    const [userId, setUserId] = React.useState('');
    const [agreementRead, setAgreementRead] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const estimatedSpendAsCurrency = estimates.total
        ? formatCurrency(
              typeof estimates.total === 'string' ? parseInt(estimates.total) : estimates.total,
              event.currency?.code ?? 'USD',
              'always'
          )
        : undefined;

    const { activationDelayDays, deactivationDelayDays } = paymentCardRules;

    const requestStartDate = tzMoment(addDays(new Date(), activationDelayDays)).format(TIME_FORMAT);
    const requestEndDate = endsAt ? tzMoment(addDays(parseISO(endsAt), deactivationDelayDays)).format(TIME_FORMAT) : '';

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const resp = await submitPaymentCard(booking!.id, userId);
            updatePaymentCard(resp.paymentCard);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open onBackdropClick={onClose}>
            <PaddedDialogContent>
                {!paymentCard ? (
                    <>
                        <H2Headline>{i18n.venue.proposal.payment.requestExpress}</H2Headline>
                        <Spacer small />
                        <SpacedCopy>{i18n.venue.proposal.payment.vPayDescription}</SpacedCopy>
                        <Spacer largest />

                        <ModalColumns>
                            <SpacedColumn>
                                <TextRow label={i18n.venue.proposal.payment.name} value={fullName} />
                                <TextRow label={i18n.authFields.email} value={email} />
                                {eventName && (
                                    <TextRow label={i18n.homepage.createMeetingModal.meetingName} value={eventName} />
                                )}
                                {internalReference && (
                                    <TextRow
                                        label={i18n.meetingDashboard.headerSection.settings.internalReference}
                                        value={internalReference}
                                    />
                                )}
                            </SpacedColumn>
                            <SpacedColumn>
                                {estimatedSpendAsCurrency && (
                                    <>
                                        <SpendLabel large>{i18n.venue.proposal.payment.estimatedSpend}</SpendLabel>
                                        <SpendAmount>{estimatedSpendAsCurrency}</SpendAmount>
                                    </>
                                )}
                            </SpacedColumn>
                        </ModalColumns>

                        <Spacer large />

                        <ModalColumns>
                            <SpacedColumn>
                                <UserIdField userId={userId} setUserId={setUserId} />
                            </SpacedColumn>
                            <SpacedColumn>
                                <Copy>{i18n.venue.proposal.payment.accountActivationThrough}</Copy>
                                <ActiveDates>
                                    <TextRow label={i18n.common.start} value={requestStartDate} />
                                    <TextRow label={i18n.common.end} value={requestEndDate} />
                                </ActiveDates>
                            </SpacedColumn>
                        </ModalColumns>

                        <Spacer largest />

                        <Row>
                            <Checkbox
                                label={<Copy small>{i18n.venue.proposal.payment.termsConfirmation}</Copy>}
                                isChecked={agreementRead}
                                onChange={(evt, checked) => setAgreementRead(checked)}
                                disabled={false}
                            />
                        </Row>

                        <Spacer />

                        <ButtonsRow>
                            <TextButton onClick={onClose} secondary>
                                {i18n.button.cancel}
                            </TextButton>
                            <TextButton disabled={!(userId && agreementRead)} onClick={handleSubmit}>
                                {i18n.button.request}
                            </TextButton>
                        </ButtonsRow>
                    </>
                ) : (
                    <ViewPaymentCard credentials={credentials} error={error} />
                )}
                {loading && <SpinnerOverlay />}
            </PaddedDialogContent>
        </Dialog>
    );
}
