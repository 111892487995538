import { Dialog } from '@material-ui/core';
import Form from 'components/Form';
import TextButton from 'components/ui/Button/TextButton';
import { H2Headline } from 'components/ui/Headline';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, InlineRow, Row, Spacer } from 'ui';

const ModalContent = styled(Column)`
    padding: 24px;
    width: 400px;
`;

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
`;

const ConstrainedForm = styled(Form)``;

const currencyLabels: { [code: string]: string } = {
    CAD: i18n.currency.CADLabel,
    EUR: i18n.currency.EURLabel,
    GBP: i18n.currency.GBPLabel,
    MXN: i18n.currency.MXNLabel,
    USD: i18n.currency.USDLabel,
    DKK: i18n.currency.DKKLabel,
    ISK: i18n.currency.ISKLabel,
    SEK: i18n.currency.SEKLabel,
    NOK: i18n.currency.NOKLabel,
};

export const COUNTRY_TO_CURRENCY: Partial<{ [country: string]: string }> = {
    US: 'USD',
    GB: 'GBP',
    MX: 'MXN',
    CA: 'CAD',
    DK: 'DKK',
    IS: 'ISK',
    SE: 'SEK',
    NO: 'NOK',
};

export default function CurrencyField({
    field,
    value = 'USD',
    onChange,
    options = [{ id: value }],
    disabled,
}: {
    field: string;
    value: string;
    onChange: (update: { field: string; value: string }) => void;
    options?: { id: string }[];
    disabled?: boolean;
}) {
    const [currency, setCurrency] = React.useState<string | null>(null);

    const selectOptions = options.map(option => ({
        ...option,
        label: currencyLabels[option.id] ?? option.id,
    }));

    return (
        <InlineRow alignItems="center" style={{ minHeight: 40, margin: '8px 0 4px 0' }}>
            <TextButton
                onClick={() => {
                    setCurrency(value);
                }}
                disabled={disabled}
            >
                {value}
            </TextButton>
            <Dialog open={!!currency} onClose={() => setCurrency(null)}>
                <ModalContent>
                    <Column itemSpacing="small">
                        <H2Headline>{i18n.proposalForm.changeCurrency}</H2Headline>
                        <SpacedCopy>{i18n.proposalForm.changeCurrencyDescription}</SpacedCopy>
                    </Column>
                    <Spacer smallish />
                    <ConstrainedForm
                        fields={{
                            currency: {
                                type: 'select',
                                options: {
                                    options: selectOptions,
                                },
                            },
                        }}
                        schema={[{ fields: ['currency'], spacing: false }]}
                        value={{ currency }}
                        onChange={({ value: { currency } }: { value: { currency: string } }) => {
                            setCurrency(currency);
                        }}
                    />
                    <Spacer large />
                    <Row itemSpacing="smallish">
                        <TextButton
                            onClick={() => {
                                setCurrency(null);
                            }}
                            secondary
                        >
                            {i18n.button.cancel}
                        </TextButton>
                        <TextButton
                            onClick={() => {
                                if (currency) onChange({ field, value: currency });
                                setCurrency(null);
                            }}
                        >
                            {i18n.button.update}
                        </TextButton>
                    </Row>
                </ModalContent>
            </Dialog>
        </InlineRow>
    );
}
