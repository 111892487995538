import { DefaultTheme } from 'styled-components';
import { EFonts, ETeams } from 'theme';

type EFontsKeys = keyof typeof EFonts;

type ThemeOverride<Return> = (themeOverride: ETeams) => Return;

type StyleFnBase = {
    (props: { theme: DefaultTheme }): Themed.Font;
};

type FontFn<TKeys = 'themeOverride'> = StyleFnBase &
    ('themeOverride' extends TKeys
        ? {
              themeOverride: ThemeOverride<FontFn<Exclude<TKeys, 'themeOverride'>>>;
          }
        : {});

export const makeFontFn = (font: EFontsKeys, options: Partial<{ themeOverride: ETeams }> = {}) => {
    const { themeOverride } = options;
    const fontFn = ({ theme: { getFontStyle } }: { theme: DefaultTheme }) => getFontStyle(EFonts[font], themeOverride);

    if (themeOverride === undefined) {
        fontFn.themeOverride = (override: ETeams) => makeFontFn(font, { ...options, themeOverride: override });
    }

    return fontFn;
};

type FontFnsDict = Record<EFontsKeys, FontFn>;

const fontFns = (Object.keys(EFonts) as EFontsKeys[]).reduce<Partial<FontFnsDict>>((dict, fontRef: EFontsKeys) => {
    Object.defineProperty(dict, fontRef, {
        get: () => makeFontFn(fontRef),
    });

    return dict;
}, {}) as FontFnsDict;

export default fontFns;
