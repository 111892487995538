import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Checkbox, CircularProgress, styled, Typography } from '@mui/material';
import { uploadBizlyOSImages } from 'cloudinary';
import PhotosUploadIcon from 'images/bizlyOS/icons/photos-upload-icon.svg?react';
import { forwardRef, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { EColors, getColor } from 'theme';
import { Button } from '../Button/Button';

const EmptyUploadContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing(0.5),
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    border: '1px solid ' + getColor(EColors.bizlyOSPrimary),
    borderRadius: shape.borderRadius,
    cursor: 'pointer',
    padding: spacing(2.5),
}));

const SmallUploadContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    border: '1px solid ' + getColor(EColors.bizlyOSPrimary),
    borderRadius: shape.borderRadius,
    gap: spacing(0.5),
    padding: spacing(0.5),
}));

const Grid = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(3, 1fr)`,
    gridGap: spacing(1.25),
    marginBottom: spacing(1.25),
}));

const ImageContainer = styled(Box)(({ srcUrl }) => ({
    transformOrigin: '0 0',
    width: '100%',
    aspectRatio: 1,
    backgroundImage: `url("${srcUrl}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    position: 'relative',
    cursor: 'grab',
}));

const ImageMask = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: getColor(EColors.pureBlack, 0.6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: spacing(1.25),
}));

const StyledCheckbox = styled(Checkbox)(({}) => ({
    padding: 0,
    '& .MuiSvgIcon-root': {
        color: getColor(EColors.pureWhite),
    },
}));

const handelImageUpload = async (file: File, id: string, fileName: string) => {
    try {
        return await uploadBizlyOSImages(file, id, fileName);
    } catch (error) {
        return null;
    }
};

export interface ImageType {
    id: string;
    srcUrl: string;
    isSelected: boolean;
}

type SortableImageItemProps = {
    onItemSelect?: (id: string) => void;
} & Bizly.VenueImage;

type ImageProps = {
    isMouseOver?: boolean;
    dragStyle?: object;
} & SortableImageItemProps &
    HTMLAttributes<HTMLDivElement>;

const Image = forwardRef<HTMLDivElement, ImageProps>(
    ({ id, dragStyle, isMouseOver, onItemSelect, isSelected, ...props }, ref) => {
        return (
            <ImageContainer ref={ref} sx={dragStyle} {...props}>
                {(isMouseOver || isSelected) && (
                    <ImageMask>
                        <DragIndicatorIcon sx={{ color: getColor(EColors.pureWhite) }} />
                        <StyledCheckbox
                            checked={isSelected}
                            color="primary"
                            onChange={() => onItemSelect && onItemSelect(id)}
                        />
                    </ImageMask>
                )}
            </ImageContainer>
        );
    }
);

const SortableImageItem = (props: SortableImageItemProps) => {
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const sortable = useSortable({ id: props.id });
    const { attributes, listeners, setNodeRef, transform, transition } = sortable;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    return (
        <Image
            isMouseOver={isMouseOver}
            onMouseOver={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
            ref={setNodeRef}
            dragStyle={style}
            {...props}
            {...attributes}
            {...listeners}
        />
    );
};

type ImageInputFieldProps = {
    label: string;
    required: boolean;
    limit?: number;
    error: boolean;
    onChange: (images: Bizly.VenueImage[]) => void;
    value: Bizly.VenueImage[];
    listingId: string;
};

type ImageInputFieldHeaderProps = Partial<ImageInputFieldProps> & { isUploading: boolean };

const ImageInputFieldHeader = ({
    label,
    required,
    limit,
    error,
    isUploading,
    value: images,
    onChange: setImages,
}: ImageInputFieldHeaderProps) => {
    const numberOfSelectedImages = useMemo(() => {
        if (!images) return 0;
        let count = 0;
        images.forEach(image => image.isSelected && count++);
        return count;
    }, [images]);

    const onSelectedPhotosCancel = () => {
        if (!images || !setImages) return;
        const newImages = images.map(image => ({ ...image, isSelected: false }));
        setImages(newImages);
    };

    const onSelectedPhotosDelete = () => {
        if (!images || !setImages) return;
        const newImages = images.filter(image => !image.isSelected);
        setImages(newImages);
    };

    return (
        <Box display="flex" gap={0.5} marginBottom={1} justifyContent="space-between">
            <Box display="flex" gap={0.5}>
                <Typography variant="body2" fontWeight={500}>
                    {label}
                </Typography>
                {required && (
                    <Typography variant="body2" color={getColor(EColors.bizlyOSPrimary)} fontWeight={500}>
                        *
                    </Typography>
                )}
                {limit && (
                    <Typography variant="body2" color={getColor(EColors.tagColor)} fontWeight={500}>
                        (Up to {limit})
                    </Typography>
                )}
                {error && (
                    <Typography color="red" variant="caption" fontWeight={500}>
                        Images are required
                    </Typography>
                )}
            </Box>
            {numberOfSelectedImages > 0 ? (
                <Box display="flex">
                    <Button variant="text" sx={{ padding: 0 }} onClick={onSelectedPhotosCancel}>
                        Cancel
                    </Button>
                    <Button variant="text" sx={{ padding: 0 }} onClick={onSelectedPhotosDelete} color="error">
                        Delete
                    </Button>
                </Box>
            ) : isUploading ? (
                <Box display="flex" gap={0.5} alignItems="center">
                    <CircularProgress size={14} sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                    <Typography variant="body2" fontWeight="500" color={getColor(EColors.bizlyOSPrimary)}>
                        Uploading...
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
};

function ImageInputField({
    label,
    required = false,
    limit,
    value: images,
    onChange: setImages,
    error,
    listingId,
}: ImageInputFieldProps) {
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [active, setActive] = useState<Bizly.VenueImage | null>(null);
    const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { delay: 250, tolerance: 200 } }));

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            try {
                if (acceptedFiles.length === 0) return;

                if (limit && acceptedFiles.length + images.length > limit) {
                    return;
                }

                setIsUploading(true);

                let newImages: Bizly.VenueImage[] = [];

                for (let index = 0; index < acceptedFiles.length; index++) {
                    const file = acceptedFiles[index];
                    let fileNameSplit = file.name.split('.');
                    const fileName = fileNameSplit[0];
                    const uploadResult = await handelImageUpload(file, listingId, fileName);

                    if (uploadResult) {
                        newImages.push({
                            id: `${Date.now()}-${index}`,
                            srcUrl: uploadResult.secure_url,
                            name: fileName,
                        });
                    }
                }

                setImages([...images, ...newImages]);
            } catch (error) {
            } finally {
                setIsUploading(false);
            }
        },
        [images]
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/webp': ['.webp'],
        },
        noClick: true,
        maxFiles: limit,
        maxSize: 1024 * 1024 * 3, // 3mb
    });

    return (
        <Box flex={1} display="flex" flexDirection="column">
            <ImageInputFieldHeader
                label={label}
                required={required}
                limit={limit}
                error={error}
                isUploading={isUploading}
                value={images}
                onChange={setImages}
            />
            {images.length > 0 ? (
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragCancel={handleDragCancel}
                >
                    <SortableContext items={images} strategy={rectSortingStrategy}>
                        <Grid>
                            {images.map(image => (
                                <SortableImageItem key={image.id} {...image} onItemSelect={handleSelectImage} />
                            ))}
                            <SmallUploadContainer {...getRootProps()}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <>
                                        <CloudUploadOutlinedIcon
                                            fontSize="large"
                                            sx={{ color: getColor(EColors.bizlyOSPrimary) }}
                                        />
                                        <Typography variant="caption">Drop the files here</Typography>
                                    </>
                                ) : isUploading ? (
                                    <CircularProgress size={24} sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                                ) : (
                                    <>
                                        <PhotosUploadIcon width={24} height={24} />
                                        <Typography
                                            variant="body2"
                                            textAlign="center"
                                            color={getColor(EColors.bizlyOSPrimary)}
                                            fontWeight="500"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={isUploading ? undefined : open}
                                        >
                                            Click to upload
                                        </Typography>
                                        <Typography variant="body2" lineHeight="14px" textAlign="center">
                                            or drag and drop
                                        </Typography>
                                        <Typography variant="caption" lineHeight="14px" textAlign="center">
                                            SVG, PNG, JPG or GIF (max. 3MB)
                                        </Typography>
                                    </>
                                )}
                            </SmallUploadContainer>
                        </Grid>
                    </SortableContext>
                    <DragOverlay adjustScale={true}>
                        {active ? <Image {...active} isSelected={false} /> : null}
                    </DragOverlay>
                </DndContext>
            ) : (
                <EmptyUploadContainer {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <>
                            <CloudUploadOutlinedIcon
                                fontSize="large"
                                sx={{ color: getColor(EColors.bizlyOSPrimary) }}
                            />
                            <Typography variant="body2">Drop the files here</Typography>
                        </>
                    ) : (
                        <>
                            <PhotosUploadIcon />
                            <Typography variant="body2">
                                <span
                                    style={{
                                        color: getColor(EColors.bizlyOSPrimary),
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                    }}
                                    onClick={isUploading ? undefined : open}
                                >
                                    Click to upload
                                </span>{' '}
                                or drag and drop
                            </Typography>
                            <Typography variant="body2">SVG, PNG, JPG or GIF (max. 3MB)</Typography>
                        </>
                    )}
                </EmptyUploadContainer>
            )}
        </Box>
    );

    function handleSelectImage(id: string) {
        if (!id) return;
        const newImages = images.map(image => {
            if (image.id !== id) return image;
            return {
                ...image,
                isSelected: !image.isSelected,
            };
        });

        setImages(newImages);
    }

    function handleDragStart(event: DragEndEvent) {
        const image = images.find(image => image.id === event.active.id);
        setActive(image || null);
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (!active || !over || active.id === over.id) return;

        let oldIndex = 0;
        let newIndex = 0;

        images.forEach((image, index) => {
            if (image.id === active.id) oldIndex = index;
            if (image.id === over.id) newIndex = index;
        });

        const newImages = arrayMove(images, oldIndex, newIndex);

        setImages(newImages);

        setActive(null);
    }

    function handleDragCancel() {
        setActive(null);
    }
}

export default ImageInputField;
