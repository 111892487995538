function toFixed<T extends number>(number: T, precision = 3) {
    const pow = Math.pow(10, precision);
    return (Math.round(number * pow) / pow) as T;
}

function toSqm(sqft: Area.Sqft, precision = 3) {
    return toFixed((sqft ? sqft / 10.764 : 0) as Area.Sqm, precision);
}

function toSqft(sqm: Area.Sqm, precision = 3) {
    return toFixed((sqm * 10.764) as Area.Sqft, precision);
}

function isNumeric<NumType extends number>(val?: NumType | string | null): val is NumType {
    const parsed = Number(val);
    return !Number.isNaN(parsed);
}

export function sqftToSqm<T extends string | null | undefined>(size: T | Area.Sqft) {
    return isNumeric(size) ? toSqm(Number(size) as Area.Sqft) : (size as T);
}

export function sqmToSqft<T extends string | null | undefined>(size: T | Area.Sqm) {
    return isNumeric(size) ? toSqft(Number(size) as Area.Sqm) : (size as T);
}
