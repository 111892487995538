import { isAddCollaboratorsPartialError } from 'api';
import Collaborator from 'components/Collaborators/Collaborator';
import SearchCollaborators from 'components/Collaborators/SearchCollaborators';
import { DisplayField } from 'components/FormFields';
import { Spinner } from 'components/Spinner';
import DeleteIconSVG from 'images/icons/trash-can.svg?react';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import React from 'react';
import { withInteractibleIconStyles } from 'shared';
import { LoadPlaybookCollaborators, playbooksActions, selectCollaborators, usePlaybooks } from 'stores/playbooks';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Button, Column, Row } from 'ui';

const ListItem = styled(Row)`
    padding: 12px 0;
    width: 500px;
`;

const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG))`
    height: 35px;
    width: 35px;
`;

const SearchCollaboratorsInput = styled(SearchCollaborators)`
    flex: 1;
`;

export default function CollaboratorsList({
    playbookId,
    readonly,
}: {
    playbookId: string | number;
    readonly?: boolean;
}) {
    const playbookCollaborators = usePlaybooks(selectCollaborators(playbookId));
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const [fieldKey, setFieldKey] = React.useState(1);

    const [newCollaborators, setNewCollaborators] = React.useState<BizlyAPI.NewEventCollaborator[]>([]);
    const [newEditor, setEditorPermission] = React.useState<boolean>(false);

    const updateNewCollaboratorsPermission = (collaborators: BizlyAPI.NewEventCollaborator[], editor: boolean) => {
        const _newCollaborators = collaborators.map(c => ({
            ...c,
            editor,
        }));
        setNewCollaborators(_newCollaborators);
    };

    const onCollaboratorsChange = (collaborators: BizlyAPI.NewEventCollaborator[]) => {
        updateNewCollaboratorsPermission(collaborators, newEditor);
    };

    const onPermissionChange = (edit: boolean) => {
        setEditorPermission(edit);
        updateNewCollaboratorsPermission(newCollaborators, edit);
    };

    const handleSaveCollaborators = async () => {
        if (!playbookId) return;
        try {
            await playbooksActions.addPlaybookCollaborators(newCollaborators, playbookId);
            enqueueSnackbar(
                !user.isSsoUser
                    ? i18n.playbookWizard.collaboration.collaboratorsInvited
                    : i18n.playbookWizard.collaboration.collaboratorsAdded,
                {
                    variant: 'info',
                }
            );
            setFieldKey(curKey => curKey + 1);
        } catch (e) {
            const { failedEmails = [] } = isAddCollaboratorsPartialError(e) ? e.raw : {};
            const message = [
                !user.isSsoUser
                    ? i18n.meetingDashboard.headerSection.collaborators.inviteError
                    : i18n.meetingDashboard.headerSection.collaborators.addError,
                ...(failedEmails.length > 0
                    ? [
                          !user.isSsoUser
                              ? i18n.meetingDashboard.headerSection.collaborators.emailInviteError
                              : i18n.meetingDashboard.headerSection.collaborators.emailAddError,
                      ]
                    : []),
                i18n.error.tryAgain,
            ].join(' ');

            enqueueSnackbar(message, { variant: 'error' });
        }
    };

    const changePermission = async (collaboratorId: number, canEdit: boolean) => {
        try {
            await playbooksActions.updatePlaybookCollaborator(+playbookId, collaboratorId, canEdit);
            enqueueSnackbar(
                canEdit
                    ? i18n.meetingDashboard.headerSection.collaborators.collaboratorCanEdit
                    : i18n.meetingDashboard.headerSection.collaborators.collaboratorCanEdit,
                {
                    variant: 'info',
                }
            );
        } catch (e) {
            const message = [
                i18n.meetingDashboard.headerSection.collaborators.changePermissionError,
                i18n.error.tryAgain,
            ].join(' ');
            enqueueSnackbar(message, { variant: 'error' });
        }
    };

    const handleRemoveCollaborator = (collaboratorId: number) => {
        if (!playbookId) return;
        playbooksActions.removePlaybookCollaborator(playbookId, collaboratorId);
    };

    if (!playbookId || !playbookCollaborators)
        return (
            <>
                <LoadPlaybookCollaborators id={playbookId} />
                <Spinner />
            </>
        );

    return readonly && playbookCollaborators.length === 0 ? null : (
        <Column itemSpacing="small">
            <LoadPlaybookCollaborators id={playbookId} />
            {!readonly && (
                <Row alignItems="center" itemSpacing="small">
                    <SearchCollaboratorsInput
                        playbookId={playbookId}
                        existingCollaboratorEmails={[
                            ...playbookCollaborators.map(collaborator => collaborator.email),
                            user.email,
                        ]}
                        onChange={onCollaboratorsChange}
                        editor={newEditor}
                        onPermissionChange={onPermissionChange}
                        allowNewEmails={!user.isSsoUser}
                        key={fieldKey}
                    />
                    <Button onClick={handleSaveCollaborators} disabled={newCollaborators.length === 0}>
                        {i18n.button.add}
                    </Button>
                </Row>
            )}
            {readonly && playbookCollaborators.length === 0 && (
                <DisplayField label={i18n.meetingDashboard.headerSection.collaborators.noCollaborators} />
            )}
            {playbookCollaborators.map(collaborator => (
                <>
                    <ListItem itemSpacing="default" justifyContent="space-around" key={collaborator.id}>
                        <Collaborator
                            key={collaborator.email}
                            collaborator={collaborator}
                            changePermission={(canEdit: boolean) => changePermission(collaborator.id, canEdit)}
                        />
                        {!readonly && <DeleteIcon onClick={() => handleRemoveCollaborator(collaborator.id)} />}
                    </ListItem>
                </>
            ))}
        </Column>
    );
}
