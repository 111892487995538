import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const SPACER_SIZES = {
    xsmall: 0.75, // 6px
    small: 1.5, // 12px
    smallish: 2.5, // 20px
    default: 3, // 24px
    medium: 4, // 32px
    large: 4.5, // 36px
    larger: 6, // 48px
    largest: 7.5, // 60px
} as const;

type TSpacerNames = keyof typeof SPACER_SIZES;

interface SpacerProps {
    col?: boolean;
    xsmall?: boolean;
    small?: boolean;
    smallish?: boolean;
    default?: boolean;
    medium?: boolean;
    large?: boolean;
    larger?: boolean;
    largest?: boolean;
}

const StyledSpacer = styled(Box, {
    shouldForwardProp: prop => prop !== 'col' && !(prop in SPACER_SIZES),
})<SpacerProps>(({ theme, col = false, ...props }) => {
    const size = (Object.keys(SPACER_SIZES) as TSpacerNames[]).find(key => props[key]) || 'default';
    const spaceSize = SPACER_SIZES[size];

    return {
        display: 'inline-block',
        ...(col
            ? {
                  height: '100%',
                  width: theme.spacing(spaceSize),
                  minWidth: theme.spacing(spaceSize),
              }
            : {
                  width: theme.spacing(spaceSize),
                  height: theme.spacing(spaceSize),
                  minHeight: theme.spacing(spaceSize),
              }),
    };
});

export const Spacer = (props: SpacerProps) => {
    return <StyledSpacer {...props} />;
};
