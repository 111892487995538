import { post } from 'api';

// TODO:
// - Need the proper types for the return Promise
export const newsLetterSubscribe = (email: string): Promise<any> => {
    const url = 'mailchimp-api';
    const apiKey = import.meta.env.VITE_APP_MAIL_CHIMP_API_KEY;

    return post(
        url,
        {
            email_address: email,
            status: 'subscribed',
        },
        {
            headers: {
                Authorization: `Basic ${btoa(apiKey)}`,
            },
        }
    ) as Promise<any>;
};
