import { Box, styled } from '@mui/material';
import React from 'react';
import { Spacer } from 'ui';
import { Headline } from './Headline/Headline';

const CenteredBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '2rem',
});

const DescriptionBox = styled(Box)({
    maxWidth: '550px',
    margin: '0 auto',
    fontWeight: 400,
    fontSize: '0.9375rem',
    lineHeight: '1.5rem',
});

type EmptyVisualProps = {
    image?: React.ReactNode;
    title: string;
    description: string;
    actionButton?: React.ReactNode;
};

const EmptyVisual = (props: EmptyVisualProps) => {
    const { image, title, description, actionButton } = props;

    return (
        <CenteredBox>
            {image && <div>{image}</div>}

            <Headline variant="h2">{title}</Headline>

            <DescriptionBox>{description}</DescriptionBox>

            {actionButton && (
                <div>
                    <Spacer large />
                    {actionButton}
                </div>
            )}
        </CenteredBox>
    );
};

export default EmptyVisual;
