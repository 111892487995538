import Form from 'components/Form';
import React from 'react';
import { i18n } from 'translation';

const dietaryIdToField = (id, allergies = false) => `${allergies ? 'allergy' : 'dietary'}-${id}`;
const fieldIdToDietary = id => parseInt(id.split('-')[1]);

export function DietaryQuestionsField({
    value = {
        dietaryRestrictions: [],
        foodAllergies: [],
    },
    errors = {},
    optional,
    readonly,
    densePadding,
    onChange: onChangeProp,
    prompt = i18n.communication.dietaryDefaultPrompt,
    dietaryRestrictionOptions = [],
    foodAllergyOptions = [],
    perRow = 4,
}) {
    // API data to field data by combining both dietary values:
    const { dietaryRestrictions, foodAllergies } = value;
    const fieldValue = {
        dietary: [
            ...dietaryRestrictions.map(id => dietaryIdToField(id)),
            ...foodAllergies.map(id => dietaryIdToField(id, true)),
        ],
    };

    // API options to field options:
    const restrictions = dietaryRestrictionOptions.map(r => ({
        ...r,
        id: dietaryIdToField(r.id),
    }));
    const allergies = foodAllergyOptions.map(r => ({
        ...r,
        id: dietaryIdToField(r.id, true),
    }));
    const restrictionSet = new Set(restrictions.map(r => r.id));
    const allergySet = new Set(allergies.map(a => a.id));

    const fields = {
        dietary: {
            prompt,
            type: 'multiselect',
            optional,
            readonly,
            options: {
                options: [...restrictions, ...allergies],
                perRow,
                placeholder: i18n.communication.dietaryPlaceholder,
            },
        },
    };

    const schema = [
        {
            key: 'dietary',
            fields: ['dietary'],
            spacing: false,
        },
    ];

    const onChange = ({ value: newValue, errors: newErrors, field }) => {
        // field data to API data by splitting dietary value:
        onChangeProp({
            value: {
                dietaryRestrictions: newValue.dietary.filter(v => restrictionSet.has(v)).map(v => fieldIdToDietary(v)),
                foodAllergies: newValue.dietary.filter(v => allergySet.has(v)).map(v => fieldIdToDietary(v)),
            },
            errors: newErrors,
            field,
        });
    };

    const props = {
        fields,
        schema,
        value: fieldValue,
        errors,
        onChange,
        densePadding,
    };
    return <Form {...props} />;
}
