import { i18n } from 'translation';

export const dietaryMap = {
    1: i18n.api.eventOptions.dietaryRestrictionOptions.vegan,
    2: i18n.api.eventOptions.dietaryRestrictionOptions.vegetarian,
    3: i18n.api.eventOptions.dietaryRestrictionOptions.kosher,
    4: i18n.api.eventOptions.dietaryRestrictionOptions.halal,
    5: i18n.api.eventOptions.dietaryRestrictionOptions.pescatarian,
    6: i18n.api.eventOptions.dietaryRestrictionOptions.glutenFree,
};

export const foodAllergyMap = {
    1: i18n.api.eventOptions.foodAllergyOptions.dairyAllergy,
    2: i18n.api.eventOptions.foodAllergyOptions.eggAllergy,
    3: i18n.api.eventOptions.foodAllergyOptions.peanutAllergy,
    4: i18n.api.eventOptions.foodAllergyOptions.treeNutAllergy,
    5: i18n.api.eventOptions.foodAllergyOptions.soyAllergy,
    6: i18n.api.eventOptions.foodAllergyOptions.wheatAllergy,
    7: i18n.api.eventOptions.foodAllergyOptions.fishAllergy,
    8: i18n.api.eventOptions.foodAllergyOptions.shellfishAllergy,
};
