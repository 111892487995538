import { Box, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import Tiptap from './TipTap';

type TextAreaFieldProps = {
    label: string;
    error: FieldError | undefined;
    onChange: (...event: any[]) => void;
    onBlur: () => void;
    value: string | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
    ref: (instance: any) => void;
};

export default function RichTextEditorField({ label, error, required = false, ...field }: TextAreaFieldProps) {
    return (
        <Box>
            <Box display="flex" gap={0.5} marginBottom={1}>
                <Typography variant="body2" fontWeight={500}>
                    {label}
                </Typography>
                {required && (
                    <Typography variant="body2" color={getColor(EColors.bizlyOSPrimary)} fontWeight={500}>
                        *
                    </Typography>
                )}
            </Box>
            <Tiptap description={field.value || ''} onChange={field.onChange} />
            {!!error && (
                <Typography color="red" variant="caption" fontWeight={500} marginBottom={1}>
                    {error.message}
                </Typography>
            )}
        </Box>
    );
}
