import VenueProposal from 'components/VenueProposal';
import { Route, Routes, useParams } from 'react-router-dom';

const Proposal = ({
    viewVenueListing,
    venueBooked,
    setVenueBooked,
}: {
    viewVenueListing: (venueId: number) => void;
    venueBooked?: boolean;
    setVenueBooked?: (booked: boolean) => void;
}) => {
    const { proposalId } = useParams<{ proposalId?: string }>();

    return (
        <Routes>
            <Route
                path={'messages'}
                element={
                    <VenueProposal
                        proposalId={proposalId}
                        venueBooked={venueBooked}
                        setVenueBooked={setVenueBooked}
                        viewVenueListing={viewVenueListing}
                        withMessagesOpen
                    />
                }
            />
            <Route
                path="*"
                element={
                    <VenueProposal
                        proposalId={proposalId}
                        venueBooked={venueBooked}
                        setVenueBooked={setVenueBooked}
                        viewVenueListing={viewVenueListing}
                    />
                }
            />
        </Routes>
    );
};

export default Proposal;
