import { LicenseInfo } from '@mui/x-license';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';

import ThemedApp from 'ThemedApp.tsx';
import './index.css';
import './sentry';

LicenseInfo.setLicenseKey(import.meta.env.VITE_APP_MUI_X_LICENSE);

const GOOGLE_ANALYTICS_ID =
    import.meta.env.VITE_APP_ENV === 'prod'
        ? import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID_PRODUCTION
        : import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID;

// Initialize Google Analytics
if (GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ThemedApp />
    </React.StrictMode>
);
