import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Link, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import StatusPill from 'pages/Meetings/StatusPill';
import { i18n } from 'translation';
import { fullTimestamp, shortTimestamp } from 'utils/date_util';
import { tzMoment, userTimeZone } from 'utils/moment';

const STATUS_ORDER: { [key in BizlyAPI.MeetingStatusCode]: number } = {
    inquiry_response: 1,
    new: 2,
    inquiry_draft: 3,
    inquiry_submitted: 4,
    inquiry_sent: 5,
    proposal_accepted: 6,
    booked: 7,
    booking_over: 8,
    booking_tracked: 9,
    cancelled: 10,
};

export const EventsTableColumns: GridColDef<Bizly.Event>[] = [
    { field: 'name', headerName: i18n.meetingSearch.meetingName, flex: 2 },
    {
        field: 'startsAt',
        headerName: i18n.meetingSearch.date,
        flex: 1,
        type: 'string',
        valueFormatter: params => {
            return params ? tzMoment(params, 'UTC').tz(userTimeZone).format('LL') : '';
        },
        sortComparator: (v1, v2) => {
            if (!v1) {
                return -1;
            }
            if (!v2) {
                return 1;
            }
            return tzMoment(v1).isBefore(tzMoment(v2)) ? -1 : 1;
        },
    },
    {
        field: 'plannedBy',
        headerName: i18n.meetingSearch.owner,
        flex: 1,
        display: 'flex',
        renderCell: ({ value }) => {
            return (
                <Box display="flex" alignItems="center" gap={1}>
                    {value?.imageUrl.includes('default-avatar') ? (
                        <Avatar>
                            <PersonIcon />
                        </Avatar>
                    ) : (
                        <Avatar src={value?.imageUrl} />
                    )}
                    <Typography>{[value?.firstName, value?.lastName].join(' ')}</Typography>
                </Box>
            );
        },
        sortComparator: (v1, v2) => {
            const name1 = [v1.firstName, v1.lastName].join(' ');
            const name2 = [v2.firstName, v2.lastName].join(' ');
            return name1 < name2 ? -1 : 1;
        },
    },
    {
        field: 'status',
        headerName: i18n.meetingSearch.status,
        width: 120,
        flex: 1,
        renderCell: ({ row }) => (
            <Box display="inline-block">
                <StatusPill
                    squared
                    meeting={row}
                    tooltip={
                        row.cancelledAt
                            ? i18n.meetingsPage.cancelledOn(fullTimestamp(row.cancelledAt, row.timeZone))
                            : ''
                    }
                />
            </Box>
        ),
        sortComparator: (v1: BizlyAPI.MeetingStatus, v2: BizlyAPI.MeetingStatus) => {
            return STATUS_ORDER[v1.code] - STATUS_ORDER[v2.code];
        },
    },
    {
        field: 'updatedAt',
        headerName: i18n.meetingSearch.activity,
        flex: 1,
        type: 'string',
        valueFormatter: params => {
            return params ? shortTimestamp(params) : '';
        },
        sortComparator: (v1, v2) => (tzMoment(v1).isBefore(tzMoment(v2)) ? -1 : 1),
    },
];

export const getActionItemsTableColumns = (
    onClick: (item: BizlyAPI.BudgetApproval) => void
): GridColDef<BizlyAPI.BudgetApproval>[] => [
    {
        field: 'eventName',
        headerName: i18n.homepage.actionItems.headCells.meetingName,
        flex: 2,
        valueGetter: (_: string, row: BizlyAPI.BudgetApproval) => row.event.name,
    },
    {
        field: 'eventDate',
        flex: 1,
        headerName: i18n.homepage.actionItems.headCells.date,
        valueGetter: (_: string, row: BizlyAPI.BudgetApproval) => tzMoment(row.event.startsAt).format('LL'),
    },
    {
        field: 'requestedBy',
        headerName: i18n.homepage.actionItems.headCells.owner,
        flex: 1,
        display: 'flex',
        renderCell: ({ value }: { value?: BizlyAPI.Author }) => {
            return (
                <Box display="flex" alignItems="center" gap={1}>
                    {value?.imageUrl.includes('default-avatar') ? (
                        <Avatar>
                            <PersonIcon />
                        </Avatar>
                    ) : (
                        <Avatar src={value?.imageUrl} />
                    )}
                    <Typography>{[value?.firstName, value?.lastName].join(' ')}</Typography>
                </Box>
            );
        },
    },
    {
        field: 'approvalRequestDetails',
        headerName: i18n.homepage.actionItems.headCells.approvalRequestDetails,
        flex: 2,
        display: 'flex',
        renderCell: ({ row }: { row: BizlyAPI.BudgetApproval }) => {
            return <Link onClick={() => onClick(row)}>{i18n.homepage.actionItems.reviewBudgetApprovalRequest}</Link>;
        },
    },
    {
        field: 'proposal',
        headerName: i18n.homepage.actionItems.headCells.proposalExpiryDate,
        flex: 1,
        valueGetter: (value: { onHoldUntil: string }) => shortTimestamp(value.onHoldUntil),
    },
];
