import Dialog from '@material-ui/core/Dialog';
import colorFns from 'colorFns';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import useKey from '../../hooks/useKey';
import { useEventCollaborators } from '../../providers/event-collaborators';
import { AlignedRow, Column } from '../../ui';
import TextButton from '../ui/Button/TextButton';

const DialogContent = styled(Column)`
    padding: 20px;
    width: 400px;
`;

const DialogTitle = styled.h2`
    color: ${colorFns.warningText};

    font-size: 25px;
    line-height: 1.28;
    letter-spacing: -0.5px;

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;

    margin: 0;
`;

const Description = styled.p`
    font-size: 18px;
    line-height: 1.5;
`;

const ButtonRow = styled(AlignedRow)`
    justify-content: flex-end;
    margin-top: 20px;
`;

const RemoveCollaboratorModal = ({
    collaborator,
    open,
    closeModal,
}: {
    collaborator: { id: number; email: string; firstName?: string | null; lastName?: string | null };
    open: boolean;
    closeModal: () => void;
}) => {
    useKey('Escape', closeModal);

    const { removeCollaborator } = useEventCollaborators();

    const { enqueueSnackbar } = useSnackbar();

    async function handleRemove() {
        try {
            await removeCollaborator(collaborator.id);
            enqueueSnackbar('Collaborator successfully removed', {
                variant: 'success',
            });
        } catch {
            const errorMessage = `There was an issue removing this collaborator: ${
                [collaborator.firstName ?? '', collaborator.lastName ?? ''].join(' ') || collaborator.email
            }`;
            enqueueSnackbar(errorMessage, {
                variant: 'error',
            });
        }
    }

    return (
        <Dialog open={open} maxWidth="md">
            <DialogContent>
                <DialogTitle>Are you sure?</DialogTitle>
                <Description>
                    Removing{' '}
                    {[collaborator.firstName ?? '', collaborator.lastName ?? ''].join(' ') || collaborator.email} will
                    completely remove their access from this meeting.
                </Description>
                <ButtonRow>
                    <TextButton onClick={closeModal} secondary>
                        Cancel
                    </TextButton>

                    <TextButton warning onClick={handleRemove}>
                        Remove
                    </TextButton>
                </ButtonRow>
            </DialogContent>
        </Dialog>
    );
};

export default RemoveCollaboratorModal;
