import { CardProps, Card as MuiCard, styled as muiStyled } from '@mui/material';

const StyledCard = muiStyled(MuiCard)(({ theme: { getColor, EColors, spacing } }) => ({
    transition: 'all 0.1s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderWidth: 1,
    borderColor: getColor(EColors.softBorder),
    borderStyle: 'solid',
    borderRadius: spacing(0.5),
    cursor: 'pointer',
    '&.MuiCard-root': {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
        },
    },
}));

export const Card = (props: CardProps) => {
    return <StyledCard elevation={1} {...props} />;
};
