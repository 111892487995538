import { Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { EColors } from 'types/themenum';
import { Column, Copy } from 'ui';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button } from 'components/Ui-V2/Button/Button';
import Box from '@mui/material/Box';

const MenuButton = styled(Button)<{ selected?: boolean }>`
    display: flex;
    justify-content: space-between;

    border: ${({ theme, selected }) =>
        selected
            ? `1px solid ${theme.getColor(EColors.carbonGreen)}`
            : `1px solid ${theme.getColor(EColors.lighterGrey)}`};

    height: 4.375rem;

    &:hover,
    &:focus {
        background-color: transparent;
    }

    &.MuiButton-outlinedPrimary {
        border: ${({ theme }) => `1px solid ${theme.getColor(EColors.lighterGrey)}`};
        background-color: transparent;

        &:hover {
            background-color: transparent;
            border: ${({ theme }) => `.0625rem solid ${theme.getColor(EColors.carbonGreen)}`};
        }
    }

    & .MuiButton-label {
        justify-content: flex-start;
    }
`;

const MenuTitle = styled(Copy)`
    color: ${({ theme }) => {
        return theme.getColor(EColors.darkGrey);
    }};
    font-weight: 500;
`;

const MenuButtonContent = styled.span`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem 0;
`;

const MenuButtonTitle = styled(Copy)`
    font-size: 1.125rem;
    font-weight: 400;
    color: ${({ theme }) => {
        return theme.getColor(EColors.darkerGrey);
    }};
    text-transform: capitalize;
    text-align: left;
`;

const MenuButtonDescription = styled(Copy)`
    font-size: 0.9375rem;
    margin-top: 0.5rem;
    color: ${({ theme }) => {
        return theme.getColor(EColors.darkGrey);
    }};
    letter-spacing: 0;
    text-transform: none;
    text-align: left;
`;

const CheckIcon = styled(TaskAltIcon)<{ selected?: boolean }>`
    fill: transparent;
    stroke: ${({ theme, selected }) =>
        selected ? `${theme.getColor(EColors.carbonGreen)}` : `${theme.getColor(EColors.lighterGrey)}`};
    .MuiSvgIcon-root {
        color: ${({ theme }) => {
            return theme.getColor(EColors.grey);
        }};
    }
`;

const WideDialogContent = styled(DialogContent)`
    width: 30rem;
    padding: 1.5rem;
    &.MuiDialogContent-root {
        padding: 1.5rem;
    }
`;

const StyledBox = styled(Box)`
    text-align: right;
`;

type CommunicationOption = {
    type: Bizly.ParcelType;
    title: string;
    description: string;
    label?: string;
};

const CommunicationOptions: CommunicationOption[] = [
    {
        type: 'registration_page',
        title: i18n.communication.publicEventRegistration,
        description: i18n.communication.publicEventRegistrationDescription,
        label: i18n.communication.eventRegistrationTitle,
    },
    {
        type: 'invite',
        title: i18n.communication.privateInvite,
        description: i18n.communication.privateInviteDescription,
        label: i18n.communication.privateInviteTitle,
    },
    {
        type: 'note',
        title: i18n.communication.surveyNoteTitle,
        description: i18n.communication.surveyNoteDescription,
    },
    {
        type: 'hotel_conf',
        title: i18n.communication.hotelConfTitle,
        description: i18n.communication.hotelConfDescription,
        label: i18n.communication.hotelConfLabel,
    },
];

type ParcelCreateModalProps = {
    open: boolean;
    onClose: () => void;
    onSelectedType: (type: Bizly.ParcelType | undefined) => void;
};

const ParcelCreateModal = ({ open, onClose, onSelectedType }: ParcelCreateModalProps) => {
    const [selectedType, setSelectedType] = useState<Bizly.ParcelType>();

    useEffect(() => {
        if (open) {
            setSelectedType(undefined);
        }
    }, [open]);

    return (
        <Dialog maxWidth="lg" open={open} onClose={onClose}>
            <WideDialogContent>
                <Column itemSpacing="small">
                    {CommunicationOptions.map(option => (
                        <React.Fragment key={option.type}>
                            {option.label && <MenuTitle>{option.label}</MenuTitle>}
                            <MenuButton
                                variant="outlined"
                                onClick={() => onSelectedType(option.type)}
                                aria-label={option.description}
                                selected={selectedType === option.type}
                            >
                                <MenuButtonContent>
                                    <MenuButtonTitle>{option.title}</MenuButtonTitle>
                                    {option.description && (
                                        <MenuButtonDescription>{option.description}</MenuButtonDescription>
                                    )}
                                </MenuButtonContent>
                                <StyledBox>
                                    <CheckIcon selected={selectedType === option.type} />
                                </StyledBox>
                            </MenuButton>
                        </React.Fragment>
                    ))}
                </Column>
            </WideDialogContent>
        </Dialog>
    );
};

export default ParcelCreateModal;
