import { Box, InputAdornment, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { withIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { findPlaces, getVenueByGooglePlaceId } from '../api';
import BuildingSVG from '../images/icons/building-icon.svg?react';
import LocationPinIcon from '../images/icons/location_pin.svg?react';
import { Button, Column, Copy, Row } from '../ui';
import { SpinnerOverlay } from './Spinner';
import AutoComplete from './ui/AutoComplete';

const Container = styled(Column)`
    margin-top: 8px;
    .MuiTextField-root {
        transform: unset; /* Prevents text blur in Safari */
    }

    & .MuiAutocomplete-root .MuiAutocomplete-inputRoot {
        padding-block: 4px;
    }
`;

const Suggestion = styled(Row)`
    align-items: center;
`;

const LocationPin = styled(withIconStyles(LocationPinIcon))`
    min-width: 21px;
    max-width: 21px;
    padding-right: 1px;
`;

const BuildingIcon = styled(withIconStyles(BuildingSVG))`
    width: 16px;
    height: 16px;
`;

const SuggestionText = styled(Copy).attrs({ regular: true })`
    overflow: hidden;
    text-overflow: ellipsis;
`;

type TPlaceInput<TInputAsSuggestion extends boolean | undefined> = {
    disabled?: boolean;
    defaultValue?: string;
    defaultValueId?: string;
    asFormField?: boolean;
    inModalOrPopover?: boolean;
    placeholder?: string;
    citiesOnly?: boolean;
    locationBias?: { lat: number; lng: number };
    allowGlobal?: boolean;
    directVenueLinking?: boolean;

    showSearchIcon?: boolean;
    onBlur?: () => void;
    autoFocus?: boolean;
    selectInputOnBlur?: boolean;
} & (TInputAsSuggestion extends true
    ? {
          inputAsSuggestion: true;
          onChange: (city: string, googlePlaceId?: string | undefined) => void;
      }
    : {
          inputAsSuggestion?: false | undefined;
          onChange: (city: string, googlePlaceId: string) => void;
      }) &
    ({ clearable: true; onRemove?: () => void } | { clearable?: undefined });

type TOption = {
    description: string;
    placeId: string;
    noIcon?: boolean;
    types: any;
};

export default function PlaceInput(props: TPlaceInput<true>): JSX.Element;
export default function PlaceInput(props: TPlaceInput<false>): JSX.Element;
export default function PlaceInput(props: TPlaceInput<true> | TPlaceInput<false>) {
    const {
        disabled,
        defaultValue,
        defaultValueId,
        onChange,
        asFormField,
        inModalOrPopover,
        directVenueLinking,
        placeholder,
        locationBias,
        showSearchIcon,
        onBlur,
        autoFocus,
        inputAsSuggestion,
        selectInputOnBlur,
        clearable,
    } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [query, setQuery] = React.useState(defaultValue);
    const [options, setOptions] = React.useState<TOption[]>();
    const [showSpinner, setShowSpinner] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [noOptionsText, setNoOptionsText] = React.useState(i18n.venue.noLocationsForQuery(query ?? ''));
    const inputOption = {
        description: query ?? defaultValue ?? '',
        placeId: defaultValueId ?? '',
        noIcon: true,
        types: undefined,
    };
    const stagedVal = React.useRef<TOption | undefined>(inputOption);

    const resetOptions = () => {
        setLoading(false);
        setOptions([]);
    };

    async function handleSuggestions(query: string) {
        if (query || props.clearable) stagedVal.current = undefined;
        setQuery(query);
        setNoOptionsText(i18n.venue.noLocationsForQuery(query || ''));

        if (!query) {
            resetOptions();
            return;
        }

        setLoading(true);
        try {
            const response = await findPlaces(query, locationBias?.lat, locationBias?.lng);
            // to handle race conditions, we should check if query is blank here and not update options
            // but AutoComplete will also hide options if there is not query/input value

            if (response.status === 'OK') {
                setOptions(response.predictions);
            } else {
                setOptions([]); // allows the component to show no options text
            }
        } catch {
            setNoOptionsText(i18n.error.default);
        } finally {
            setLoading(false);
        }
    }

    function handleSelection(city: string, placeId: string) {
        resetOptions();
        onChange?.(city, placeId);
    }

    function handleClear() {
        resetOptions();
        if (props.clearable) props.onRemove?.();
    }

    function goToVenueDirectly(e: MouseEvent, option: TOption) {
        e.stopPropagation();
        setShowSpinner(true);
        setLoading(true);
        getVenueByGooglePlaceId(option.placeId).then(result => {
            if (result.success) {
                const currentUrl = location.pathname;
                const newUrl = currentUrl.includes('/venues')
                    ? currentUrl.replace('/venues', '/venues/venue')
                    : currentUrl;
                navigate(`${newUrl}/listing/${result.venue.id}`, {
                    state: {
                        shouldGoBack: true,
                        backgroundLocation: location,
                    },
                });
            }
        });
    }

    const renderOption = (option: TOption) => {
        const desiredTypes = [
            'lodging',
            'art_gallery',
            'bar',
            'bowling_alley',
            'casino',
            'museum',
            'night_club',
            'restaurant',
            'spa',
            'stadium',
        ];

        const hasDesiredType = option?.types?.some((type: string) => desiredTypes.includes(type));
        const hasEstablishmentType = directVenueLinking && option?.types?.includes('establishment') && hasDesiredType;
        return (
            <Suggestion>
                {!option.noIcon && <LocationPin />}
                {<SuggestionText>{option.description}</SuggestionText>}
                {hasEstablishmentType && (
                    <Tooltip title={i18n.venue.goToVenuePage} placement="top" arrow>
                        <Box>
                            <Button
                                style={{ borderRadius: 4, marginLeft: 5 }}
                                onClick={(e: MouseEvent) => goToVenueDirectly(e, option)}
                            >
                                <BuildingIcon />
                            </Button>
                        </Box>
                    </Tooltip>
                )}
            </Suggestion>
        );
    };

    return (
        <>
            {showSpinner && <SpinnerOverlay transparent={false} />}
            <Container>
                <AutoComplete
                    blurOnSelect
                    onBlur={() => {
                        resetOptions();
                        if (selectInputOnBlur && props.inputAsSuggestion) {
                            if (!stagedVal.current) props.onChange(inputOption.description, undefined);
                        }
                        onBlur && onBlur();
                    }}
                    loading={loading}
                    options={options ? [...(inputAsSuggestion ? [inputOption] : []), ...options] : []}
                    disabled={disabled}
                    getOptionLabel={option => option?.description || ''}
                    getOptionSelected={(option, value) => option?.description === value?.description}
                    noOptionsText={noOptionsText}
                    InputProps={{
                        placeholder,
                        autoFocus,
                        ...(defaultValue ? { value: defaultValue } : {}),
                        ...(showSearchIcon
                            ? {
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          <SearchIcon />
                                      </InputAdornment>
                                  ),
                              }
                            : {}),
                    }}
                    // needed for autoHighlight
                    filterOptions={o => o}
                    onInputChange={val => handleSuggestions(val)}
                    defaultValue={{ description: defaultValue || '', placeId: defaultValueId || '', types: undefined }}
                    clearable={clearable}
                    onChange={(e, val) => {
                        if (!e || !val) {
                            stagedVal.current = undefined;
                            handleClear();
                            return;
                        }
                        stagedVal.current = val;
                        handleSelection(val.description, val.placeId);
                    }}
                    renderOption={renderOption}
                    asField={asFormField}
                    inModalOrPopover={inModalOrPopover}
                />
            </Container>
        </>
    );
}
