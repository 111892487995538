import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const FlexContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.getColor(theme.EColors.imagePlaceholderIcon),
    borderRadius: theme.spacing(0.5),
    color: theme.getColor(theme.EColors.grey2),
    boxSizing: 'border-box',
}));

type TPlaceholderImageProps = {
    title?: string;
};

const PlaceholderImage = ({ title }: TPlaceholderImageProps) => {
    return (
        <FlexContainer>
            <RoomOutlinedIcon />
            {title && <Typography>{title}</Typography>}
        </FlexContainer>
    );
};

export default PlaceholderImage;
