import React from 'react';
import styled from 'styled-components';
import { Column as UIColumn, Row as UIRow } from '../../ui';

const Column = styled(UIColumn)`
    padding: 4px 0 8px;
`;

const Headline = styled.h3`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 400;
`;

const Description = styled.h4`
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 400;
`;

const Copy = styled.p`
    margin: 0;
`;

const Row = styled(UIRow)`
    font-size: 14px;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    animation: fadeIn 1s ease;
    div:not(:last-child) {
        margin-right: 24px;
    }
`;

type TSummaryProps = {
    headline: string;
    description: string | React.ReactElement;
    expanded: boolean;
    label: string;
    summary?: string;
};

const PlannerAccordionSummary = ({ headline, description, expanded, label, summary }: TSummaryProps) => (
    <Column>
        <Headline>{headline}</Headline>
        <Description>{description}</Description>
        {!expanded && summary && (
            <Row>
                <Copy>
                    <strong>{label}:</strong> {summary}
                </Copy>
            </Row>
        )}
    </Column>
);

export default PlannerAccordionSummary;
