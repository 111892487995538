import {
    Box,
    Grid,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Card } from 'components/Ui-V2/Card/Card';
export const SkeletonTable = () => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {[...Array(5)].map((_, index) => (
                            <TableCell key={index}>
                                <Skeleton variant="text" width={100} />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...Array(5)].map((_, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {[...Array(5)].map((_, cellIndex) => (
                                <TableCell key={cellIndex}>
                                    <Skeleton variant="text" width={cellIndex === 0 ? 200 : 100} />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const SkeletonCard = () => (
    <Card>
        <Box sx={{ position: 'relative', padding: '.5rem' }}>
            <Skeleton variant="rounded" width="100%" height={200} />
        </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '6.25rem',
                padding: '0 1rem 1rem',
            }}
        >
            <Skeleton variant="text" width="80%" height={24} sx={{ mb: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Skeleton variant="rounded" width={100} height={24} />
                <Skeleton variant="circular" width={32} height={32} />
            </Box>
        </Box>
    </Card>
);

export const SkeletonGridCards = () => {
    return (
        <Grid container spacing={2} sx={{ paddingRight: '4rem' }}>
            {[...Array(8)].map((_, index) => (
                <Grid item md={4} lg={3} key={index}>
                    <SkeletonCard />
                </Grid>
            ))}
        </Grid>
    );
};
