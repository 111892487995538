import Divider from '@mui/material/Divider';
import { venueTypesAPIDict } from 'api/venueTypesApi';
import keyBy from 'lodash/keyBy';
import { units } from 'stores/measurement-units';
import { i18n } from 'translation';
import { EColors } from 'types/themenum';

type TVenueType = { id: BizlyAPI.Venue.Types; label: string; pinColor: EColors };
const venueTypes: TVenueType[] = Object.values(venueTypesAPIDict());

const getVenueTypesWithTeam = (teamName: string): TVenueType[] =>
    venueTypes.map(type => {
        if (type.id === 'corporate office') {
            return {
                id: 'corporate office',
                label: teamName,
                pinColor: EColors.venuePinOffice,
            };
        }
        return type;
    });

// This holds similarities to venueTypesDictAPI, but should stay to maintain functionality of TokenUtils
export const venueTypesDict = keyBy(venueTypes, type => type.id) as Record<BizlyAPI.Venue.Types, TVenueType>;

export const formatRadiusMi = (val: Distance.Mile) => `${val >= 100 ? '100+' : Math.round(val)} miles`;
export const formatRadiusKm = (val: Distance.Kilometer) => `${val >= 150 ? '150+' : Math.round(val)} km`;

const boolOptions = [
    {
        id: true,
        label: i18n.button.yes,
    },
    {
        id: false,
        label: i18n.button.no,
    },
];

export const fields = (
    teamName?: string,
    venueBrands?: Bizly.SelectOption[],
    { disabledBrands, hideBrandPrompt }: { disabledBrands?: boolean; hideBrandPrompt?: boolean } = {}
) => ({
    dinovaOnly: {
        prompt: i18n.venue.filterForm.dinovaOnly,
        type: 'radioselect',
        perRow: 2,
        options: {
            options: boolOptions,
        },
    },
    types: {
        prompt: i18n.venue.filterForm.venueType,
        type: 'multiselect',
        options: {
            options: teamName ? getVenueTypesWithTeam(teamName) : venueTypes,
            perRow: 2,
            placeholder: i18n.venue.filterForm.notFiltered,
        },
        optional: true,
    },
    preferredOnly: {
        prompt: i18n.venue.filterForm.preferredVenuesOnly,
        type: 'radioselect',
        options: {
            perRow: 2,
            options: boolOptions,
        },
    },
    radius: {
        prompt: i18n.venue.filterForm.distance,
        type: 'slider',
        options: {
            min: 0,
            max: 25,
            valueLabelFormat: formatRadiusMi,
            marks: [
                { value: 0, label: '0' },
                { value: 25, label: '25' },
            ],
        },
        optional: true,
    },
    radiusKm: {
        prompt: i18n.venue.filterForm.distance,
        type: 'slider',
        options: {
            min: 1,
            max: 150,
            valueLabelFormat: formatRadiusKm,
        },
        optional: true,
    },
    brandIds: {
        type: 'multiselect',
        ...(hideBrandPrompt
            ? {}
            : {
                  prompt: i18n.venue.filterForm.brands,
              }),
        disabled: disabledBrands,
        options: {
            options: venueBrands ?? [],
            perRow: 2,
        },
        optional: true,
    },
    divider: {
        type: Divider,
        prompt: ' ',
        optional: true,
    },
    guests: {
        prompt: i18n.venue.filterForm.attendeeCount,
        type: 'text',
        optional: true,
        options: {
            numberType: 'integer',
            maxLimit: 1000,
        },
    },
});

export const schema = (unit: units.kilometer | units.mile, showBrands?: boolean) =>
    [
        {
            key: 'types',
            fields: ['types'],
            spacing: 'xsmall',
        },
        {
            key: 'divider1',
            fields: ['divider'],
            spacing: 'smallish',
        },
        {
            key: 'preferredOnly',
            fields: ['preferredOnly'],
            spacing: 'xsmall',
        },
        {
            key: 'divider2',
            fields: ['divider'],
            spacing: 'smallish',
        },
        {
            key: 'guests',
            fields: ['guests'],
            spacing: 'xsmall',
        },
        {
            key: 'divider3',
            fields: ['divider'],
            spacing: 'smallish',
        },
        {
            key: 'radius',
            fields: unit === units.kilometer ? (['radiusKm'] as const) : (['radius'] as const),
            spacing: 'xsmall',
        },
        {
            key: 'divider4',
            fields: ['divider'],
            spacing: 'smallish',
        },
        ...(showBrands
            ? ([
                  {
                      fields: ['brandIds'],
                      spacing: 'xsmall',
                  },
                  {
                      key: 'divider5',
                      fields: ['divider'],
                      spacing: 'smallish',
                  },
              ] as const)
            : []),
        {
            key: 'dinovaOnly',
            fields: ['dinovaOnly'],
            spacing: false,
        },
    ] as const;
