import HowToPopper from 'components/HowToPopper/HowToPopper';
import { Spinner } from 'components/Spinner';
import { PageHeadline } from 'components/ui/Headline';
import { PageDescription } from 'pages/BuildInquiry';
import VendorTile from 'pages/Vendors/components/VendorTile';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAddonsByCategory, useAddons } from 'stores/addons';
import LoadAddons from 'stores/addons/LoadAddons';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Row, Spacer } from 'ui';
import { sortVendors } from './utils';

const Main = styled(Column)`
    width: 100%;
`;

const LogoRow = styled(Row)`
    display: flex;
    max-width: 2048px;
    flex-wrap: wrap;
    margin: -4px;
    & > * {
        margin: 4px;
    }
`;

export default function Vendors() {
    const { user } = useUser();
    // TODO: Maybe translate on the backend?
    const { recommendationCopy } = user.team?.compliance?.virtual || {};
    const { event } = useEvent();
    const { loadingCatalog, loadingSelected, selectedAddonsById } = useAddons();
    const navigate = useNavigate();
    const location = useLocation();

    const vendors = useAddons(getAddonsByCategory('virtual_meeting_service_provider'));

    const goToVendor = (vendorId: number) => {
        navigate(
            {
                pathname: `/event/${event.id}/virtual/${vendorId}`,
            },
            {
                state: location.state,
            }
        );
    };

    const preferredVendorIds =
        user.team?.virtualMeetingServiceProviders.filter(provider => provider.preferred).map(provider => provider.id) ??
        [];
    const showPreferredAddOnsOnly = user.team?.showPreferredAddOnsOnly || false;

    const isPreferred = (vendor: BizlyAPI.Addon) => {
        return preferredVendorIds.some(id => id === vendor.virtualMeetingServiceProvider?.id);
    };

    const isSelected = (vendor: BizlyAPI.Addon) => {
        return !!selectedAddonsById[vendor.id];
    };

    return (
        <Main>
            <LoadAddons eventId={event.id} />
            <Row alignItems="center">
                <PageHeadline withDescription>{i18n.meetingsPage.virtual}</PageHeadline>
                <HowToPopper sectionId="virtual" />
            </Row>
            {recommendationCopy && <PageDescription>{recommendationCopy}</PageDescription>}
            <Spacer large />
            <LogoRow itemSpacing="small">
                {loadingCatalog || loadingSelected ? (
                    <Column>
                        <Spacer large />
                        <Spinner />
                    </Column>
                ) : (
                    sortVendors(vendors, isPreferred, isSelected, showPreferredAddOnsOnly).map(vendor => (
                        <VendorTile
                            vendor={vendor}
                            team={user.team}
                            key={vendor.id}
                            onClick={() => goToVendor(vendor.id)}
                            selected={!!selectedAddonsById[vendor.id]}
                            width="208px"
                            fixedRatio={(190 / 208) * 100 + '%'}
                        />
                    ))
                )}
            </LogoRow>
        </Main>
    );
}
