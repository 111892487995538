import { acceptTeamInvite, exchangeMagicToken } from 'api';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import { parse } from 'query-string';
import React, { useEffect, useRef } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { bannerActions } from 'stores/home/bannerStore';
import mixpanel from 'utils/mixpanel';
import { removeCookie, setCookie } from 'utils/tokens';

export const handleFailToActivate = (navigate: NavigateFunction) => navigate('/sign-in', { replace: true });

export const useAuthenticate = (activateNewUser?: boolean) => {
    const { user, refreshUser, setUserState } = useUser();
    const [authenticating, setAuthenticating] = React.useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const request = useRef(false);

    useEffect(() => {
        if (request.current) return;
        const { token: magicToken, email, redirect } = parse(location.search);
        const parsedRedirect = typeof redirect === 'string' ? redirect : '/';
        const parsedEmail = Array.isArray(email) ? email[0] : email || '';
        if (!magicToken && activateNewUser) handleFailToActivate(navigate);

        if (!user.token && magicToken) {
            setAuthenticating(true);
            request.current = true;
            removeCookie();
            const promise = activateNewUser
                ? acceptTeamInvite(magicToken as string, parsedEmail)
                : exchangeMagicToken(magicToken as string);
            promise
                .then((resp: { user: { authToken: string } }) => setCookie(resp.user.authToken))
                .then(refreshUser)
                .then(user => {
                    setUserState(user);
                    if (!user.token) {
                        throw new Error('No user token');
                    }

                    mixpanel.track('Logged in with magic link');
                    bannerActions.setBannerVisibility(true);
                    let userNeedsToFillProfile = !user.firstName || !user.lastName;
                    if (!user?.isSsoUser) {
                        userNeedsToFillProfile = userNeedsToFillProfile || !user.role || !user.phone;
                    }
                    const nextUrl = userNeedsToFillProfile ? '/profile' : parsedRedirect;

                    navigate(nextUrl, {
                        state: {
                            ...(redirect && { redirectTo: parsedRedirect }),
                            ...(activateNewUser && { firstTimeUser: true }),
                        },
                    });
                })
                .catch(() => {
                    enqueueSnackbar('Your magic link has expired! Please try again.', { variant: 'error' });

                    mixpanel.track('Failed to log in with magic link');

                    if (activateNewUser) handleFailToActivate(navigate);
                })
                .finally(() => {
                    setAuthenticating(false);
                    request.current = false;
                });
        }
    }, [user.token, location.search, activateNewUser]);

    return [authenticating];
};
