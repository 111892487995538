import SideNav from 'components/SideNav';
import Playbook from 'pages/Playbook';
import PlaybookWizard from 'pages/PlaybookWizard';
import NavButtons from 'pages/PlaybookWizard/NavButtons';
import { Templates } from 'pages/Templates/Templates';
import { Route, Routes } from 'react-router-dom';
import { LoadEventTemplateTags } from 'stores/event-template-tags';
import { LoadEventTemplates } from 'stores/eventTemplates';

const ANIMATION_KEY = '1';

export default function TemplatesRoute() {
    return (
        <>
            <LoadEventTemplateTags />
            <Routes>
                <Route
                    path={':playbookId/edit/*'}
                    element={
                        <SideNav
                            fillWidth
                            fillHeight
                            fullWidth
                            hideUser
                            routeChildren={<NavButtons />}
                            key={ANIMATION_KEY}
                        >
                            <PlaybookWizard />
                        </SideNav>
                    }
                />
                <Route
                    path={':playbookId/*'}
                    element={
                        <SideNav key={ANIMATION_KEY}>
                            <LoadEventTemplates />
                            <Playbook />
                        </SideNav>
                    }
                />
                <Route
                    path="*"
                    element={
                        <>
                            <LoadEventTemplates />
                            <SideNav key={ANIMATION_KEY}>
                                <Templates />
                            </SideNav>
                        </>
                    }
                />
            </Routes>
        </>
    );
}
