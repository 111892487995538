import { default as FormBase } from 'components/Form';
import CollaboratorsList from 'components/Playbook/CollaboratorsList';
import ResourcesList from 'components/Playbook/ResourcesList';
import styled from 'styled-components';
import { i18n } from 'translation';

const Form = styled(FormBase)`
    width: 500px;
`;

export default function CollaborationForm({
    playbookId,
    readOnly,
}: {
    playbookId: string | number;
    readOnly?: boolean;
}) {
    const fields = {
        collaborators: {
            type: CollaboratorsList,
            prompt: readOnly
                ? i18n.playbookWizard.collaboration.collaborators
                : i18n.playbookWizard.collaboration.addCollaborators,
            description: readOnly ? '' : i18n.playbookWizard.collaboration.collaboratorsDescription,
            options: { playbookId },
            optional: true,
        },
        resources: {
            type: ResourcesList,
            prompt: i18n.playbookWizard.collaboration.documents,
            description: readOnly ? '' : i18n.playbookWizard.collaboration.resourcesDescription,
            options: { playbookId },
            optional: true,
        },
    };

    return (
        <>
            <Form
                fields={fields}
                schema={[{ fields: ['collaborators'] }, { fields: ['resources'] }]}
                value={{}}
                onChange={() => {}}
                readonly={readOnly}
            />
        </>
    );
}
