import React from 'react';
import styled from 'styled-components';
import { Column, Copy, Row, Spacer } from '../../ui';

const DetailCopy = styled(Copy)`
    line-height: 1.6;
`;

export const RSVPDetailSection = ({
    icon,
    text,
    textArray,
}: {
    icon: React.ReactNode;
    text?: string;
    textArray?: string[];
}) =>
    text || textArray ? (
        <Row>
            <Column>{icon}</Column>
            <Spacer col xsmall />
            <Column>
                {text && <DetailCopy>{text}</DetailCopy>}
                {textArray &&
                    textArray.map(
                        (textLine?: string, idx?: number) =>
                            textLine && <DetailCopy key={textLine + idx}>{textLine}</DetailCopy>
                    )}
            </Column>
        </Row>
    ) : null;
