import { Dialog, DialogContent } from '@material-ui/core';
import { TSpacePOST } from 'api';
import Form from 'components/Form';
import { SpinnerOverlay } from 'components/Spinner';
import TextButton from 'components/ui/Button/TextButton';
import { H2Headline } from 'components/ui/Headline';
import keyBy from 'lodash/keyBy';
import { useSnackbar } from 'notistack';
import React from 'react';
import { units, useMeasurementUnits } from 'stores/measurement-units';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Copy, Row, Spacer } from 'ui';
import { sqftToSqm, sqmToSqft } from 'utils/units';
import { isEmptyString } from '../utils';
import { NEWLY_CREATED_SPACE, SPECIAL_OPTION_IDS, TSpace } from './utils';
import {
    modalFields,
    modalSchema,
    smallFields,
    smallSchema,
    TModalValue,
    TModalValueSqft,
    TModalValueSqm,
} from './venueSpacesModalSchema';

const WideDialogContent = styled(DialogContent)`
    min-width: 800px;
    max-width: 900px;
    padding: 32px !important;
`;

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
`;

const ButtonsRow = styled(Row)`
    justify-content: flex-end;

    > *:not(:first-child) {
        margin-left: 15px;
    }
`;

export const spaceToModalVals = (
    { id, name, description, size, maxCapacity, images = [] }: Partial<TSpace>,
    unit: units.sqft | units.sqm
) =>
    ({
        id,
        name,
        description,
        ...(unit === units.sqm
            ? {
                  size: sqftToSqm(size),
                  sizeUnit: unit,
              }
            : { size, sizeUnit: unit }),
        maxCapacity,
        imageUrl: images[0]?.srcUrl,
    } as const);

const modalValError = (data: Partial<TModalValue> | TModalValue) => {
    const { name, size, maxCapacity, imageUrl } = data;

    return (
        isEmptyString(name) ||
        isEmptyString(imageUrl) ||
        (typeof size === 'string' ? isEmptyString(size || '') : typeof size !== 'number') ||
        (typeof maxCapacity === 'string' ? isEmptyString(maxCapacity || '') : typeof maxCapacity !== 'number')
    );
};

const isComplete = (data: Partial<TModalValue> | TModalValue): data is TModalValue => {
    return !modalValError(data);
};

export default function VenueSpacesModal({
    field,
    value,
    onChange,
    spaces = [],

    onUpdateVenueSpaces,
    disabled,
}: {
    field: string;
    value: number;
    onChange: ({ field, value, error }: any) => void;

    spaces: TSpace[];
    venueId: number | string;
    proposalId: number | string;

    onUpdateVenueSpaces: (space: TSpacePOST) => Promise<TSpace[] | false>;
    disabled?: boolean;
}) {
    const loaded = React.useRef<boolean>(false);
    const spacesDict = React.useMemo(() => keyBy([...spaces, NEWLY_CREATED_SPACE], space => space.id), [spaces]);
    const isCreate = (id: number) => id === SPECIAL_OPTION_IDS.create;

    const { area: areaUnit } = useMeasurementUnits();

    const [openModal, setOpenModal] = React.useState(false);
    const [modalValue, setModalValue] = React.useState<Partial<TModalValue>>({});

    const [preModalValue, setPreModalValue] = React.useState<number>(value);

    React.useEffect(() => {
        loaded.current = false;
    }, [value]);

    React.useEffect(() => {
        const intentToCreateNew = isCreate(value);
        if (intentToCreateNew && !loaded.current) {
            setModalValue(spaceToModalVals(NEWLY_CREATED_SPACE, areaUnit));
            setOpenModal(true);
            loaded.current = true;
        }
        // We'll track the previous value to return the user back if they cancel the modal
        return () => setPreModalValue(value);
    }, [value, areaUnit]);

    const onClose = () => {
        onChange({ field, value: preModalValue });
        setOpenModal(false);
    };

    const onEditSpace = () => {
        const loadedSpace = spacesDict[value];
        if (loadedSpace) {
            setPreModalValue(value);
            setModalValue(spaceToModalVals(loadedSpace, areaUnit));
            setOpenModal(true);
        }
    };

    const [loading, setLoading] = React.useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [snackbarKey, setSnackbarKey] = React.useState<string | number | undefined>();

    React.useEffect(
        () => () => {
            if (snackbarKey) closeSnackbar(snackbarKey);
        },
        [modalValue, value, openModal, snackbarKey, closeSnackbar, setSnackbarKey]
    );

    const onSubmit = async () => {
        if (!modalValue.id) return;

        if (!isComplete(modalValue)) {
            return setSnackbarKey(
                enqueueSnackbar(i18n.error.fillAll, { variant: 'error', persist: true }) || undefined
            );
        }

        setLoading(true);

        try {
            let sqftData: TModalValueSqft;
            if (modalValue.sizeUnit === units.sqm) {
                sqftData = {
                    ...modalValue,
                    size: sqmToSqft(modalValue.size),
                    sizeUnit: units.sqft,
                };
            } else {
                sqftData = modalValue;
            }
            const { id: spaceId, sizeUnit, ...newData } = sqftData;

            const newSpaces = await onUpdateVenueSpaces({ ...newData, ...(isCreate(spaceId) ? {} : { id: spaceId }) });
            if (newSpaces) {
                if (isCreate(spaceId)) {
                    const newSpace = newSpaces.filter(s => !spacesDict[s.id]);

                    onChange({ field, value: newSpace[0].id });
                } else {
                    onChange({ field, value: modalValue.id });
                }

                setOpenModal(false);
            }
        } finally {
            setLoading(false);
        }
    };

    const onFormChange = ({ value }: { field: string; value: { id: string } }) => {
        onChange({ field, value: value.id });
    };

    type NewModalValSqm = Replace<TModalValueSqft, 'sizeUnit', units.sqm>;
    type NewModalValSqft = Replace<TModalValueSqm, 'sizeUnit', units.sqft>;

    const onModalChange = (
        change:
            | {
                  field: Exclude<keyof ReturnType<typeof modalFields>, 'sizeUnit'>;
                  value: TModalValueSqft | TModalValueSqm;
              }
            | { field: 'sizeUnit'; value: NewModalValSqm }
            | { field: 'sizeUnit'; value: NewModalValSqft }
    ) => {
        setModalValue(oldSpaceVals => {
            if (change.field === 'sizeUnit') {
                const { value: newSpaceVals } = change;

                if (newSpaceVals.sizeUnit === units.sqm) {
                    const newSpaceValsSqm = {
                        ...newSpaceVals,
                        size: sqftToSqm(newSpaceVals.size),
                    };
                    return { ...oldSpaceVals, ...newSpaceValsSqm };
                }

                const newSpaceValsSqft = {
                    ...newSpaceVals,
                    size: sqmToSqft(newSpaceVals.size),
                };
                return { ...oldSpaceVals, ...newSpaceValsSqft };
            }

            const { value: newSpaceVals } = change;
            const selectedNewSpace = oldSpaceVals.id !== newSpaceVals.id;
            const loadedSpace = selectedNewSpace && spacesDict[newSpaceVals.id];

            const loadedSpaceVals = loadedSpace ? spaceToModalVals(loadedSpace, oldSpaceVals.sizeUnit || areaUnit) : {};

            return { ...oldSpaceVals, ...newSpaceVals, ...loadedSpaceVals };
        });
    };

    return (
        <>
            <Form
                fields={smallFields(spaces, onEditSpace)}
                schema={smallSchema}
                value={{ id: value }}
                onChange={onFormChange}
                disabled={disabled}
            />

            <Dialog open={openModal} maxWidth="xl" onBackdropClick={loading ? undefined : onClose}>
                <WideDialogContent>
                    <H2Headline>{i18n.proposalForm.eventSpaces.spaceEditor}</H2Headline>
                    <Spacer small />
                    <SpacedCopy>{i18n.proposalForm.eventSpaces.spaceEditorDescription}</SpacedCopy>

                    <Spacer large />

                    <Form
                        fields={modalFields(spaces)}
                        schema={modalSchema}
                        value={modalValue}
                        onChange={onModalChange}
                    />

                    <Spacer largest />

                    <ButtonsRow>
                        <TextButton onClick={onClose} secondary>
                            {i18n.button.cancel}
                        </TextButton>
                        <TextButton onClick={onSubmit}>
                            {modalValue.id === SPECIAL_OPTION_IDS.create ? i18n.button.create : i18n.button.select}
                        </TextButton>
                    </ButtonsRow>
                    {loading && <SpinnerOverlay />}
                </WideDialogContent>
            </Dialog>
        </>
    );
}
