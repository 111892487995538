import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import SpaceIcon from '../../images/icons/space.svg?react';
import { Copy } from '../../ui';
import { getVenueFeatures } from 'utils/getVenueFeatures';

const VenueFeatureLabel = styled(Copy)`
    font-size: 16px;
    letter-spacing: -0.09px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;
const VenueFeaturesGrid = styled(Grid)`
    flex: 1;
    max-width: 35rem;
`;

const VenueFeaturesV2 = ({ venue, narrow }: { venue: Bizly.Venue; narrow: boolean }) => {
    return (
        <VenueFeaturesGrid container spacing={3}>
            {getVenueFeatures(venue).map(f => {
                const Icon = f.icon || SpaceIcon;

                return (
                    <Grid item xs={narrow ? 4 : 3} key={f.id}>
                        <Icon style={{ minWidth: 32 }} />
                        <VenueFeatureLabel>{f.label}</VenueFeatureLabel>
                    </Grid>
                );
            })}
        </VenueFeaturesGrid>
    );
};

export default VenueFeaturesV2;
