import { useParams } from 'react-router-dom';
import { Provider as SlotFillProvider } from 'react-slot-fill';

import Event from 'pages/Event';
import EventProvider from 'providers/event';
import EventOptionsProvider from 'providers/event-options';

const EventDetail = () => {
    const { eventId } = useParams();

    if (!eventId) return null;

    return (
        <SlotFillProvider>
            <EventOptionsProvider eventId={eventId}>
                <EventProvider eventId={Number(eventId)}>
                    <Event />
                </EventProvider>
            </EventOptionsProvider>
        </SlotFillProvider>
    );
};

export default EventDetail;
