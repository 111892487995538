import { filterProtips, ProTipsDropdown } from 'components/ProTip';
import { Spinner, SpinnerOverlay } from 'components/Spinner';
import { H1Headline } from 'components/ui/Headline';
import { addDays, isBefore, set } from 'date-fns';
import { useSnackbar } from 'notistack';
import { TNewScheduleItem } from 'pages/EditSchedule';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isPendingId, isScheduleEmpty, scheduleActions, useSchedule } from 'stores/schedule';
import styled from 'styled-components';
import { Column, Row } from 'ui';
import { API_TIME_FORMAT, datetimeStrToLocalDate, is12AM } from 'utils/date_util';
import { tzMoment } from 'utils/moment';
import EventsCalendar from './EventsCalendar';

const oneHourEarlier = (date: Date) => set(date, { hours: Math.max(date.getHours() - 1, 0) });

const BigCalendarContainer = styled(Column)`
    height: calc(100% - 24px); /* bottom padding */
    width: 100%;
`;

const CALENDAR_PAGE = 'planner',
    SCHEDULE_SECTION = 'schedule';

const blockToAPI = <TBlock extends { start: Date; end: Date }>(block: TBlock) => ({
    ...block,
    date: tzMoment(block.start).format('L'),
    startTime: tzMoment(block.start).format(API_TIME_FORMAT),
    endTime: tzMoment(block.end).format(API_TIME_FORMAT),
});

const APIToBlock = (apiBlock: BizlyAPI.ScheduleBlock) => {
    const start = datetimeStrToLocalDate(apiBlock.date, apiBlock.startTime);
    const end = datetimeStrToLocalDate(apiBlock.date, apiBlock.endTime);

    return {
        ...apiBlock,
        start,
        end: isBefore(end, start) ? addDays(end, 1) : end,
        multiDay: isBefore(end, start) && !is12AM(end),
        loading: isPendingId(apiBlock.id),
    };
};

export default function ScheduleCalendarPage() {
    const schedule = useSchedule();
    const { user } = useUser();
    const { event, template, refreshEvent } = useEvent();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const goToMoreOptions = (data: TNewScheduleItem) => navigate(`/event/${event.id}/agenda/new`, { state: data });
    const goToEditItem = (id: number) => navigate(`/event/${event.id}/agenda/${id}`);

    const proTips = React.useMemo(
        () =>
            filterProtips(
                [...(user.team?.proTips ?? []), ...(template.proTips ?? [])],
                CALENDAR_PAGE,
                SCHEDULE_SECTION
            ),
        [user.team, template]
    );

    const items = React.useMemo(
        () =>
            [...(schedule.loaded ? schedule.items : []), ...schedule.pendingItems]
                .filter(item => item.date && item.startTime && item.endTime)
                .map(APIToBlock)
                .sort((itemA, itemB) => itemA.start.getTime() - itemB.start.getTime()),

        [schedule.loaded, schedule.items, schedule.pendingItems]
    );

    const addItem = async (data: BizlyAPI.ScheduleBlock) => {
        try {
            await scheduleActions.add(event.id, data);
            refreshEvent();
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const deleteItem = async (id: number) => {
        try {
            await scheduleActions.delete(event.id, id);
            if (isScheduleEmpty(schedule)) navigate(`/event/${event.id}`);
            refreshEvent();
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const location = useLocation();
    const [initFocus] = React.useState(() => {
        const { id: focusId, date: focusDate, startTime: focusTime } = location.state ?? {};
        const focusScrollTime =
            focusDate && focusTime ? oneHourEarlier(datetimeStrToLocalDate(focusDate, focusTime)) : undefined;

        if (!focusId || !focusScrollTime) return undefined;
        return {
            id: focusId,
            date: focusScrollTime,
        };
    });

    React.useEffect(() => {
        if (initFocus) navigate({ pathname: location.pathname, search: location.search });
    }, [navigate, location, initFocus]);

    return (
        <BigCalendarContainer itemSpacing="default">
            <Row itemSpacing="default" alignItems="center">
                <H1Headline>Agenda</H1Headline>
                <ProTipsDropdown protips={proTips} />
                {schedule.loading && schedule.loaded && <Spinner unsetMargin />}
            </Row>

            {schedule.loaded && schedule.eventId === event.id ? (
                <EventsCalendar
                    items={items}
                    editable={event.editable}
                    addItem={newItem => addItem(blockToAPI(newItem))}
                    deleteItem={deleteItem}
                    goToMoreOptions={newItem => goToMoreOptions(blockToAPI(newItem))}
                    goToEditItem={goToEditItem}
                    initFocus={initFocus}
                    lastBlockWarning={!user.team?.useAgendas}
                />
            ) : (
                <SpinnerOverlay />
            )}
        </BigCalendarContainer>
    );
}
