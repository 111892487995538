import { Box, Button, ToggleButton, ToggleButtonGroup, Typography, alpha, styled } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { InviteStaffDrawer } from './InviteStaffDrawer';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => {
    const { getColor, EColors } = theme;

    return {
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(0.5),
            border: 0,
            borderRadius: '.25rem',

            '&.Mui-selected': {
                backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.3),
                color: getColor(EColors.pureBlack),
            },

            '&:not(.Mui-selected)': {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,

                '&:hover': {
                    backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.1),
                },
            },

            '&:not(:first-of-type)': {
                borderRadius: '.25rem',
            },

            '&:first-of-type': {
                borderRadius: '.25rem',
            },
        },
    };
});

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    textTransform: 'none',
    padding: '.25rem .75rem',
    fontSize: '.875rem',
    fontWeight: 'medium',

    '&:not(.Mui-selected)': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
    },

    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
}));

const StyledInviteButton = styled(Button)(({ theme }) => {
    const { getColor, EColors } = theme;

    return {
        backgroundColor: getColor(EColors.bizlyOSPrimary),
        color: getColor(EColors.pureWhite),
        borderRadius: '4px',

        '&:hover': {
            backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.8),
        },
    };
});

const StyledTypography = styled(Typography)(({ theme, color }) => {
    const { getColor, EColors } = theme;

    return {
        color:
            color === 'secondary'
                ? theme.palette.text.secondary
                : color === 'primary'
                  ? getColor(EColors.bizlyOSPrimary)
                  : color || getColor(EColors.pureBlack),
    };
});

const UserCountDisplay = ({ totalUsers }: { totalUsers: number }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StyledTypography variant="body2">{totalUsers}</StyledTypography>
        <StyledTypography variant="body2" color="secondary" sx={{ mx: 0.5 }}>
            /
        </StyledTypography>
        <StyledTypography variant="body2" color="primary">
            5 Free Seats Used
        </StyledTypography>
    </Box>
);

interface SettingsToolBarProps {
    onUserStatusChange: (newStatus: string) => void;
    totalUsers: number;
}

export const SettingsToolBar = ({ onUserStatusChange, totalUsers }: SettingsToolBarProps) => {
    const [userStatus, setUserStatus] = useState('active');
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleUserStatusChange = (_: React.MouseEvent<HTMLElement>, newStatus: string | null) => {
        if (newStatus === 'invited') {
            return null;
        }

        if (newStatus !== null) {
            setUserStatus(newStatus);
            onUserStatusChange(newStatus);
        }
    };

    return (
        <GridToolbarContainer>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                {/* <StyledToggleButtonGroup
                    value={userStatus}
                    exclusive
                    onChange={handleUserStatusChange}
                    aria-label="user status"
                    size="small"
                >
                    <StyledToggleButton value="active">Active</StyledToggleButton>
                    <StyledToggleButton value="invited">Invited</StyledToggleButton>
                </StyledToggleButtonGroup> */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
                    {/* <UserCountDisplay totalUsers={totalUsers} /> */}
                    <StyledInviteButton sx={{ marginLeft: 'auto' }} onClick={() => setDrawerOpen(true)}>
                        Invite Users
                    </StyledInviteButton>
                </Box>
            </Box>

            <InviteStaffDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </GridToolbarContainer>
    );
};
