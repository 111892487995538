import { Paper, styled as muiStyled } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead, { tableHeadClasses } from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useMemo, useState } from 'react';
import { shortTimestamp } from 'utils/date_util';
import { tzMoment } from 'utils/moment';
import { TemplateActionPopper } from './TemplateActionPopper';

interface Data {
    id: number;
    name: {
        element: string;
        sortValue: string;
    };
    createdAt: {
        element: string;
        sortValue: number;
    };
    actions: {
        element: React.ReactNode;
        sortValue: number;
    };
}

function createData(template: Bizly.EventTemplate): Data {
    const { id, name, createdAt } = template;
    return {
        id,
        name: {
            element: name || '',
            sortValue: name || '',
        },
        createdAt: {
            element: createdAt ? shortTimestamp(createdAt) : '',
            sortValue: createdAt ? tzMoment(createdAt).valueOf() : 0,
        },
        actions: {
            element: <TemplateActionPopper template={template} />,
            sortValue: id,
        },
    };
}

type Order = 'asc' | 'desc';

function stableSort<T extends Data>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getComparator<Key extends keyof Data>(order: Order, orderBy: Key): (a: Data, b: Data) => number {
    return order === 'desc'
        ? (a, b) => compareValues(getValue(b, orderBy), getValue(a, orderBy))
        : (a, b) => compareValues(getValue(a, orderBy), getValue(b, orderBy));
}

function getValue(obj: Data, key: keyof Data) {
    const value = obj[key];
    if (typeof value === 'object' && value !== null && 'sortValue' in value) {
        return value.sortValue;
    }
    return value;
}

type ComparableValue =
    | string
    | number
    | null
    | undefined
    | React.ReactNode
    | {
          firstName?: string | null;
          lastName?: string | null;
      };

function compareValues(a: ComparableValue, b: ComparableValue): number {
    if (a === null || a === undefined) return b === null || b === undefined ? 0 : 1;
    if (b === null || b === undefined) return -1;

    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    }

    if (a instanceof Date && b instanceof Date) {
        return a.getTime() - b.getTime();
    }

    if (typeof a === 'string' && typeof b === 'string') {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
            return dateA.getTime() - dateB.getTime();
        }
    }

    const aStr = typeof a === 'object' ? JSON.stringify(a) : String(a);
    const bStr = typeof b === 'object' ? JSON.stringify(b) : String(b);

    return aStr.localeCompare(bStr);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    sortable: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Template Name',
        sortable: true,
    },
    {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Created At',
        sortable: true,
    },
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
        sortable: false,
    },
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

const StyledTableHead = muiStyled(TableHead)(({ theme: { getColor, EColors } }) => ({
    [`&.${tableHeadClasses.root}`]: {
        background: getColor(EColors.drWhite),
    },
}));

function EnhancedTableHead({ order, orderBy, onRequestSort }: EnhancedTableProps) {
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent) => {
        onRequestSort(event, property);
    };

    return (
        <StyledTableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell?.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            headCell.label
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </StyledTableHead>
    );
}

export const TemplateListTable = ({ templates }: { templates: Bizly.EventTemplate[] }) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('name');

    const handleRequestSort = (_: React.MouseEvent, property: keyof Data) => {
        const headCell = headCells.find(cell => cell.id === property);
        if (headCell && headCell?.sortable) {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    };

    const rows = useMemo(() => templates.map((template: Bizly.EventTemplate) => createData(template)), [templates]);

    const sortedRows = useMemo(() => stableSort(rows, getComparator(order, orderBy)), [rows, order, orderBy]);

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%' }} elevation={1}>
                <TableContainer>
                    <Table
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: 'none',
                            },
                        }}
                        size="medium"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {sortedRows.map((row: Data) => (
                                <TableRow hover key={row.id} sx={{ cursor: 'pointer' }}>
                                    <TableCell size="small" align="left">
                                        {row.name.element}
                                    </TableCell>
                                    <TableCell size="small" align="left">
                                        {row.createdAt.element}
                                    </TableCell>
                                    <TableCell size="small" align="left">
                                        {row.actions.element}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};
