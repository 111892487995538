import { i18n } from 'translation';
import { timeOptions } from 'utils/date_util';
import { CheckboxDropdownFields, CheckboxFields } from './EventSpaceCheckboxFields';

/* Schedule Section */

export const scheduleFields = {
    date: {
        prompt: i18n.venue.planner.date,
        type: 'date_outlined',
        options: {
            minDate: Date.now(),
            placeholder: i18n.venue.planner.date,
        },
        optional: true,
    },
    startTime: {
        prompt: i18n.venue.planner.startTime,
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: i18n.venue.planner.startTime,
            autoFocusOptionKey: '12:00:00',
        },
        optional: true,
    },
    endTime: {
        prompt: i18n.venue.planner.endTime,
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: i18n.venue.planner.endTime,
            autoFocusOptionKey: '12:00:00',
        },
        optional: true,
    },
};

export const scheduleSchema = [
    {
        key: 'scheduleFields',
        fields: ['date', 'startTime', 'endTime'],
        spacing: false,
    },
];

/* Event Spaces Section */

type TEventOption = { name: string; iconUrl: string; id: number; diningStyle?: { id: number; name: string }[] };
type TEventSpaceFieldOptions = {
    minDateOption?: Date;
    setupOptions: TEventOption[];
    avOptions: TEventOption[];
    fbOptions: TEventOption[];
};

export const makeEventSpaceNameField = ({ onBlur }: { onBlur: () => void }) => ({
    spaceName: {
        type: 'text',
        options: {
            placeholder: i18n.venue.planner.meetingSpaceNamePlaceholder,
            onBlur,
        },
    },
});

export const defaultEventSpaceFields = ({
    minDateOption,
    setupOptions,
    avOptions,
    fbOptions,
}: TEventSpaceFieldOptions) => ({
    date: {
        prompt: i18n.venue.planner.date,
        type: 'date_outlined',
        options: {
            minDate: minDateOption ? minDateOption : Date.now(),
            placeholder: i18n.venue.planner.date,
        },
        optional: true,
    },
    startTime: {
        prompt: i18n.venue.planner.startTime,
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: i18n.venue.planner.startTime,
            autoFocusOptionKey: '12:00:00',
        },
        optional: true,
    },
    endTime: {
        prompt: i18n.venue.planner.endTime,
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: i18n.venue.planner.endTime,
            autoFocusOptionKey: '12:00:00',
        },
        optional: true,
    },
    setupId: {
        prompt: i18n.venue.planner.roomSetup,
        type: 'select',
        options: {
            options: setupOptions,
            placeholder: i18n.venue.planner.selectSetup,
        },
        optional: true,
    },
    attendees: {
        prompt: i18n.venue.planner.noOfAttendees,
        type: 'counter',
        options: {
            min: 0,
        },
        optional: true,
    },
    avNeeds: {
        prompt: i18n.venue.planner.audioVisual,
        type: CheckboxFields,
        options: {
            options: avOptions,
            perRow: 3,
            iconBox: true,
        },
        optional: true,
    },
    fbNeeds: {
        prompt: i18n.venue.planner.FB,
        type: CheckboxDropdownFields,
        options: {
            options: fbOptions,
            perRow: 3,
            iconBox: true,
        },
        optional: true,
    },
});

export const eventSpaceNameSchema = [
    {
        key: 'spaceName',
        fields: ['spaceName'],
        spacing: false,
    },
];

export const eventSpaceSchema = (disableDates = false) => [
    {
        key: 'eventSpaceDates',
        fields: disableDates ? ['startTime', 'endTime'] : ['date', 'startTime', 'endTime'],
        spacing: 'medium',
    },
    {
        key: 'roomAndAttendees',
        fields: ['setupId', 'attendees'],
        spacing: 'medium',
    },
    {
        key: 'avNeeds',
        fields: ['avNeeds'],
        spacing: 'medium',
    },
    {
        key: 'fbNeeds',
        fields: ['fbNeeds'],
        spacing: false,
    },
];

/* Accommodations Section */

export const defaultAccommodationsFields = {
    day: {
        type: 'display',
        options: {
            formatter: (day: string) => {
                if (!day) return i18n.venue.planner.noDatesAdded;
                return day;
            },
        },
        optional: true,
    },
    date: {
        type: 'display',
        options: {
            formatter: (date: string) => {
                if (!date) return '';

                const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
                const utcDate = new Date(date + 'T00:00:00Z');

                return new Date(utcDate).toLocaleDateString(undefined, options as Intl.DateTimeFormatOptions);
            },
        },
    },
    count: {
        type: 'counter',
        options: {
            min: 0,
        },
        optional: true,
        perRow: 'auto',
    },
};

export const accommodationsSchema = [
    {
        key: 'dateAndRooms',
        fields: ['day', 'date', 'count'],
        spacing: false,
    },
];

export const accommodationStartEndDateFields = (startDate?: string) => {
    return {
        startDate: {
            prompt: i18n.venue.planner.checkIn,
            type: 'date',
            optional: true,
        },
        endDate: {
            prompt: i18n.venue.planner.checkOut,
            type: 'date',
            options: {
                minDate: startDate ? startDate : undefined,
            },
            optional: true,
        },
    };
};

export const accommodationStartEndDateSchema = [
    {
        key: 'startAndEndDate',
        fields: ['startDate', 'endDate'],
        spacing: false,
    },
];

/* Virtual Meetings Section */

export const defaultVMFields = (teamServiceTypes: Partial<Bizly.VirtualMeetingServiceProvider>[] = []) => ({
    serviceProvider: {
        prompt: i18n.venue.planner.serviceType,
        type: 'select',
        fixedWidth: 168,
        options: {
            options: teamServiceTypes,
            placeholder: i18n.venue.planner.selectService,
            overflowTruncate: true,
            valueAsOption: true,
        },
    },
    link: {
        prompt: i18n.venue.planner.link,
        type: 'text',
        options: {
            placeholder: 'https://www.host.com/meeting_id',
        },
    },
    notes: {
        prompt: i18n.venue.planner.additionalMeetingInfo,
        type: 'rich_text',
    },
});

export const VMSchemaLink = [
    {
        key: 'serviceProvider',
        fields: ['link'],
        spacing: false,
    },
];

export const VMSchemaNotes = [
    {
        key: 'additionalInfo',
        fields: ['notes'],
        spacing: false,
    },
];
