import { Box, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

const getCountry = () => {
    try {
        const Locale = Intl.Locale;
        return Locale ? new Locale(navigator.language).region?.toLowerCase() : undefined;
    } catch {}
};

type PhoneFieldProps = {
    label: string;
    error: FieldError | undefined;
    onChange: (...event: any[]) => void;
    onBlur: () => void;
    value: string | undefined;
    disabled?: boolean | undefined;
    name: string;
    ref: (instance: any) => void;
};

export default function PhoneField({ label, error, value, onChange }: PhoneFieldProps) {
    return (
        <Box>
            <Typography variant="body2" fontWeight={500} marginBottom={1}>
                {label}
            </Typography>
            <PhoneInput
                value={value}
                onChange={onChange}
                country={getCountry()}
                specialLabel=""
                inputClass="phone-input"
            />
            {!!error && (
                <Typography color="red" variant="caption" fontWeight={500} marginBottom={1}>
                    {error.message}
                </Typography>
            )}
        </Box>
    );
}
