import { OptionsObject, useSnackbar } from 'notistack';
import React from 'react';

/*
    Opening a snackbar provides a key, the key is used to clear the existing snackbar
    1. [new snackbar] Snackbars should be cleared if a new snackbar is opened
        when the key changes
    2. [custom dependencies] Snackbars should be cleared if a custom list of dependencies changes
        this list should function like the dependencies of a hook, shallowly compared
        React will yell if you try to pass in a changing list of dependencies, 
        so we will need to do this by hand
*/

export default function usePersistentSnackbar(deps: any[]) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [snackbarKey, setSnackbarKey] = React.useState<string | number | undefined>();

    const clearSnackbar = React.useCallback(() => {
        if (snackbarKey) closeSnackbar(snackbarKey);
    }, [snackbarKey, closeSnackbar]);

    // 1 [new snackbar]
    React.useEffect(() => clearSnackbar, [clearSnackbar]);

    // 2 [custom dependencies]
    const prevDeps = React.useRef(deps);
    React.useEffect(() => {
        return () => {
            prevDeps.current = deps;
        };
    }, [deps]);
    React.useEffect(() => {
        if (deps.some((dep, idx) => dep !== prevDeps.current[idx])) {
            clearSnackbar();
        }
    }, [deps, prevDeps, clearSnackbar]);

    const persistentSnackbar = React.useCallback(
        (message: React.ReactNode, options: OptionsObject) =>
            setSnackbarKey(
                enqueueSnackbar(message, {
                    ...options,
                    persist: true,
                }) || undefined
            ),
        [enqueueSnackbar, setSnackbarKey]
    );

    return persistentSnackbar;
}
