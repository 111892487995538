import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { TStyledTheme } from 'theme';

type TextButtonProps = {
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    secondary?: boolean;
    warning?: boolean;
    disabled?: boolean;
    asLink?: boolean;
    children: React.ReactNode;
    width?: string;
};

const buttonColorsStyledFn = ({
    warning,
    secondary,
    theme: { getColor, EColors },
}: TextButtonProps & { theme: TStyledTheme }) => {
    const { disabledAction } = EColors;

    const actionColorBase = secondary ? EColors.secondaryTextAction : EColors.primaryAction;
    const actionColor = warning ? EColors.warningTextAction : actionColorBase;

    const actionColorHoverBase = secondary ? EColors.secondaryTextActionHover : EColors.primaryActionHover;
    const actionColorHover = warning ? EColors.warningTextActionHover : actionColorHoverBase;

    const styles = {
        base: {
            color: getColor(actionColor),
        },
        hover: {
            color: getColor(actionColorHover),
        },
        disabled: {
            color: getColor(disabledAction),
        },
    };

    return {
        ...styles.base,
        '&:hover': styles.hover,
        '&:focus': styles.hover,
        '&:disabled': styles.disabled,
    };
};

const Text = styled.span<TextButtonProps>`
    font-size: 15px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};

    text-decoration: ${({ asLink }) => (asLink ? 'underline' : 'unset')};

    ${({ width }) => (width ? `width: ${width}` : '')};

    ${buttonColorsStyledFn}
`;

const TextButton = ({ children, ...props }: TextButtonProps) => <Text {...props}>{children}</Text>;

export default TextButton;
