import TextareaAutosize, { TextareaAutosizeProps } from '@material-ui/core/TextareaAutosize';
import AirplaneSVG from 'images/icons/airplane.svg?react';
import PaperclipSVG from 'images/icons/paperclip.svg?react';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';

const Airplane = withInteractibleIconStyles(AirplaneSVG);
const Paperclip = withInteractibleIconStyles(PaperclipSVG);

const InputArea = styled.div`
    display: flex;
    min-height: 60px;
    border-top: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

    form {
        display: flex;
        align-items: center;
        width: 100%;
        height: inherit;

        button {
            background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
            align-self: start;
            border: none;
            outline: none;
            margin-top: 15px;
            margin-right: 16px;

            svg {
                cursor: pointer;
                outline: none;
            }
        }
    }
`;

const TextArea = styled(TextareaAutosize)`
    width: 100%;
    line-height: 19px;
    resize: none;
    border: none;
    font-size: 13px;
    margin-left: 24px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

    :focus {
        outline: none;
    }
`;

type InputBoxProps = {
    message: string;
    handleUpload: (files: File[]) => void;
    handleSubmit: React.DOMAttributes<HTMLFormElement>['onSubmit'];
    handleChange: TextareaAutosizeProps['onChange'];
    handleKeyPress?: TextareaAutosizeProps['onKeyPress'];
    className?: string;
};

const InputBox = ({ message, handleUpload, handleSubmit, handleChange, handleKeyPress, className }: InputBoxProps) => {
    const onDrop = (files: File[]) => {
        handleUpload(files);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { color: inputColor, ...inputProps } = getInputProps();
    const { color: rootColor, ...rootProps } = getRootProps();

    return (
        <InputArea>
            <form onSubmit={message.length ? handleSubmit : e => e.preventDefault()}>
                <TextArea
                    rows={1}
                    rowsMax={5}
                    value={message}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    placeholder={i18n.message.inputPlaceholder}
                />
                <button>
                    {message.length ? (
                        <Airplane />
                    ) : (
                        <div {...rootProps}>
                            <input {...inputProps} />
                            <Paperclip />
                        </div>
                    )}
                </button>
            </form>
        </InputArea>
    );
};

export default InputBox;
