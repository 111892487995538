import { H2Headline } from 'components/ui/Headline';
import { AlignedRow, Column as ColumnUI, Copy, Spacer } from 'ui';

import { useMemo } from 'react';

import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import {
    Body,
    Cell,
    Header,
    HeaderCell,
    HeaderRow,
    Table,
    Row as TableRow,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import ExpandableText from 'components/ui/ExpandableText';
import BoardRoomIcon from 'images/icons/board-room.png';
import ClassroomIcon from 'images/icons/classroom.png';
import CocktailRoundsIcon from 'images/icons/cocktail-rounds.png';
import CrescentRoundsIcon from 'images/icons/crescent-rounds.png';
import EShapeIcon from 'images/icons/eshape.png';
import HollowSquareIcon from 'images/icons/hollow-square.png';
import PlaceholderImage from 'images/icons/no-image-placeholder.png';
import PerimeterIcon from 'images/icons/perimeter.png';
import TheaterIcon from 'images/icons/theater.png';
import UShapeIcon from 'images/icons/u-shaped.png';
import styled from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';

const TableContainer = styled(ColumnUI)`
    max-height: 800px;
`;

const StickyHeader = styled(Header)`
    display: sticky;
`;

const HeaderCellWithIcon = styled(HeaderCell)`
    align-items: flex-end !important;
`;

const TopAlignedCell = styled(Cell)`
    align-items: flex-start !important;
`;

const CenteredCell = styled(Cell)`
    > div {
        text-align: center;
    }
`;

const NoImage = styled.div`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 1em;
`;

const SpaceImage = styled.img<{ clickable?: boolean }>`
    ${props =>
        props.clickable
            ? `
        cursor: pointer; 
        width: 5rem;
        height: 5rem;
        `
            : `
        width: 3rem;
        margin: 1rem;
    `}
`;

const IconImage = styled.img`
    width: 3rem;
    height: 3rem;
`;

const WrapCell = styled(TopAlignedCell)`
    > div {
        white-space: normal !important;
    }
`;

type FeaturedSpacesProps = {
    spaces: Bizly.Space[];
    onImageClick: (space: Bizly.Space) => void;
};

const FeaturedSpaces = ({ spaces, onImageClick }: FeaturedSpacesProps) => {
    const theme = useTheme([
        getTheme(DEFAULT_OPTIONS),
        {
            Table: `
            --data-table-library_grid-template-columns:  18.75rem 18.75rem 8rem 9.375rem 12.5rem 6.4375rem repeat(9, minmax(9.375rem, 1fr));
            height: 100%;
          `,
            BaseCell: `
            &:nth-of-type(1) {
              left: 0px;
            }

            &:nth-of-type(2) {
              left: 300px;
            }
          `,
        },
    ]);

    const tableData = useMemo(
        () => ({
            nodes: spaces,
        }),
        [spaces]
    );

    return (
        <TableContainer>
            <H2Headline $color={EColors.darkestGrey}>{i18n.venue.featuredSpaces}</H2Headline>
            <Table data={tableData} theme={theme} layout={{ custom: true }}>
                {(tableList: Bizly.Space[]) => (
                    <>
                        <StickyHeader>
                            <HeaderRow>
                                <HeaderCellWithIcon pinLeft>
                                    <Copy>{i18n.venue.meetingRooms}</Copy>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <Copy>{i18n.venue.description}</Copy>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI alignItems="center">
                                        <Copy>{i18n.venue.maximumCapacity}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI alignItems="center">
                                        <Copy>{i18n.venue.banquetRounds}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI itemSpacing="xsmall" alignItems="center">
                                        <IconImage src={BoardRoomIcon} />
                                        <Copy>{i18n.venue.boardRoomConference}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI itemSpacing="xsmall" alignItems="center">
                                        <IconImage src={ClassroomIcon} />
                                        <Copy>{i18n.venue.classroom}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI itemSpacing="xsmall" alignItems="center">
                                        <IconImage src={CocktailRoundsIcon} />
                                        <Copy>{i18n.venue.cocktailRounds}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI itemSpacing="xsmall" alignItems="center">
                                        <IconImage src={CrescentRoundsIcon} />
                                        <Copy>{i18n.venue.crescentRounds}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI itemSpacing="xsmall" alignItems="center">
                                        <IconImage src={EShapeIcon} />
                                        <Copy>{i18n.venue.eshaped}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI itemSpacing="xsmall" alignItems="center">
                                        <IconImage src={HollowSquareIcon} />
                                        <Copy>{i18n.venue.hollowSquare}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI itemSpacing="xsmall" alignItems="center">
                                        <IconImage src={PerimeterIcon} />
                                        <Copy>{i18n.venue.perimeterSeating}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI alignItems="center">
                                        <Copy>{i18n.venue.talkShow}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI itemSpacing="xsmall" alignItems="center">
                                        <IconImage src={TheaterIcon} />
                                        <Copy>{i18n.venue.theater}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI alignItems="center">
                                        <Copy>{i18n.venue.tshaped}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                                <HeaderCellWithIcon>
                                    <ColumnUI itemSpacing="xsmall" alignItems="center">
                                        <IconImage src={UShapeIcon} />
                                        <Copy>{i18n.venue.ushaped}</Copy>
                                    </ColumnUI>
                                </HeaderCellWithIcon>
                            </HeaderRow>
                        </StickyHeader>

                        <Body>
                            {tableList.map(item => (
                                <TableRow key={item.id} item={item}>
                                    <TopAlignedCell pinLeft>
                                        <AlignedRow itemSpacing="small">
                                            {item.images?.length > 0 ? (
                                                <SpaceImage
                                                    src={item.images?.[0]?.srcUrl}
                                                    onClick={() => onImageClick(item)}
                                                    clickable
                                                />
                                            ) : (
                                                <NoImage>
                                                    <SpaceImage src={PlaceholderImage} alt="Placeholder" />
                                                </NoImage>
                                            )}
                                            <Copy>{item.name}</Copy>
                                        </AlignedRow>
                                    </TopAlignedCell>
                                    <WrapCell>
                                        <ExpandableText html={item.description} />
                                    </WrapCell>
                                    <CenteredCell>{item.maxCapacity}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.banquetRounds ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.boardRoomConference ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.classroom ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.cocktailRounds ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.crescentRounds ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.eshaped ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.hollowSquare ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.perimeterSeating ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.talkShow ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.theater ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.tshaped ?? 0}</CenteredCell>
                                    <CenteredCell>{item.capacities?.[0]?.ushaped ?? 0}</CenteredCell>
                                </TableRow>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
            <Spacer larger />
        </TableContainer>
    );
};

export default FeaturedSpaces;
