import { default as FormBase } from 'components/Form';
import { DisplayField } from 'components/FormFields';
import PlaceInput from 'components/PlaceInput';
import { H3Headline } from 'components/ui/Headline';
import { fields, schema } from 'components/VenueSearch/filtersSchema';
import { TFilterValueInitial } from 'components/VenueSearch/VenueSearchFilters';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { useUser } from 'providers/user';
import React, { useMemo } from 'react';
import { units, useMeasurementUnits } from 'stores/measurement-units';
import { usePlaybooks } from 'stores/playbooks';
import styled from 'styled-components';
import { Column, Copy, Spacer, Switch } from 'ui';
import { getBrandOptions } from 'utils/venue';

type Place = Partial<{
    location: BizlyAPI.Complete.Playbook['location'];
    googlePlaceId: BizlyAPI.Complete.Playbook['googlePlaceId'];
}> | null;

type TPlaceField = {
    field: string;
    value?: Place;
    error: Object | null;
    defaultValue: Place;
    onChange: ({ value, field, error }: { value: Place; field: string; error: Object | null }) => void;
    allowGlobal?: boolean;
    readonly?: boolean;
};

const PlaceField = ({
    field,
    onChange: onChangeProp,
    defaultValue,
    allowGlobal = false,
    readonly = false,
}: TPlaceField) => {
    return readonly ? (
        <DisplayField value={defaultValue?.location || 'No City Selected'} />
    ) : (
        <PlaceInput
            asFormField
            inModalOrPopover
            onChange={(location, googlePlaceId) =>
                onChangeProp({
                    field,
                    error: {},
                    value: {
                        location,
                        googlePlaceId,
                    },
                })
            }
            clearable
            onRemove={() =>
                onChangeProp({
                    field,
                    error: {},
                    value: null,
                })
            }
            key={defaultValue?.location ?? undefined}
            defaultValue={defaultValue?.location ?? undefined}
            defaultValueId={defaultValue?.googlePlaceId ?? undefined}
            allowGlobal={allowGlobal}
        />
    );
};

type TLockField = {
    field: string;
    value: boolean;
    onChange: ({ value, field }: { value: boolean; field: string }) => void;
};

const LockField = ({ field, onChange, value }: TLockField) => (
    <Switch checked={value} onChange={e => onChange({ field, value: e.target.checked })} />
);

const venueFilterFields = ({
    teamName,
    venueBrands,
    place,
    hideBrandPrompt,
}: {
    teamName?: string;
    venueBrands?: Bizly.SelectOption[];
    place?: Place;
    hideBrandPrompt?: boolean;
}) => ({
    isLocked: {
        prompt: 'Lock Venue Filters',
        description: 'Locked filters will be unable to be changed by users creating meetings with this playbook.',
        type: LockField,
        optional: true,
    },
    place: {
        prompt: 'Location',
        type: PlaceField,
        perRow: '1/2',
        options: {
            ...(place ? { defaultValue: place } : {}),
        },
        optional: true,
    },
    ...fields(teamName, venueBrands, { hideBrandPrompt }),
});

const lockSchema = [{ fields: ['isLocked'], spacing: 'default' }];
const placeSchema = [{ fields: ['place'], spacing: 'default' }];
const filtersSchema = (unit: units.kilometer | units.mile) => [...schema(unit)] as const;
const brandsSchema = [
    {
        fields: ['brandIds'],
        spacing: false,
    },
];

export type TVenueFiltersValue = Partial<{
    isLocked: boolean;
    place: Place;
    dinovaOnly: boolean;
    types: BizlyAPI.Venue.Types[];
    preferredOnly: boolean;
    radius: Distance.Mile;
    radiusKm: Distance.Kilometer;
    brandIds: number[];
    guests: number;
}>;

const Form = styled(FormBase)``;

type Fields = keyof ReturnType<typeof venueFilterFields>;

export default function VenueFiltersForm({
    value = {},
    onChange,
    readonly,
}: {
    value?: TVenueFiltersValue;
    onChange?: (update: { field: Fields; value: TFilterValueInitial }) => void;
    readonly?: boolean;
}) {
    const { playbookOptions: { venueBrands = [], venueTypes = [] } = {} } = usePlaybooks();
    const { distance: distanceUnit } = useMeasurementUnits();
    const { user } = useUser();
    const teamName = useMemo(() => (user.team ? user.team.name : ''), [user.team]);

    // set "disabled" attribute of the venue brand option
    const brandOptions = useMemo(
        () => getBrandOptions(value.types, venueTypes, venueBrands),
        [value.types, venueTypes, venueBrands]
    );

    const fields = venueFilterFields({
        teamName,
        venueBrands: brandOptions,
        place: value.place,
        hideBrandPrompt: !readonly,
    });

    const formProps = {
        fields,
        value: {
            ...value,
            dinovaOnly: value.dinovaOnly ?? false,
            preferredOnly: value.preferredOnly ?? false,
        },
        onChange,
        readonly,
        hideReadonlyEmpty: true,
    };

    const hidePlacesForm = readonly && (!value.place || isEmpty(pickBy(value.place, v => v)));

    return (
        <Column itemSpacing="default">
            {hidePlacesForm ? null : <Form schema={placeSchema} {...formProps} />}
            <Form schema={lockSchema} {...formProps} />
            <Form schema={filtersSchema(distanceUnit === units.meter ? units.kilometer : units.mile)} {...formProps} />
            {!readonly ? (
                <Column itemSpacing="smallish">
                    <Spacer small />
                    <H3Headline>Venue Brands</H3Headline>
                    <Copy faded>
                        Selecting venue brands for this playbook will restrict this filter
                        <br />
                        for events created from this playbook.
                    </Copy>
                    <Form schema={brandsSchema} {...formProps} />
                </Column>
            ) : (
                <Form schema={brandsSchema} {...formProps} />
            )}
        </Column>
    );
}
