import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import styled from 'styled-components';
import { i18n } from 'translation';
import ImageNotFound from 'images/404-assets/not-found.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Container = styled(Box)`
    padding-top: 7.993rem;
    margin: auto;
    width: 28.063rem;
    height: 33.258rem;
    text-align: center;
`;

const MainTitle = styled.h1`
    font-weight: 600;
    size: 1.375rem;
    line-height: 1.664rem;
`;

const SectionDescription = styled.p`
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-bottom: 3.125rem;
`;

const NotFoundImage = styled.img`
    width: 24.614rem;
    height: 16.206rem;
`;

export const PageNotFound = ({ eventId }: { eventId?: number }) => {
    const destination = eventId ? `/event/${eventId}` : '/';

    return (
        <Container>
            <NotFoundImage src={ImageNotFound} />
            <MainTitle>{i18n.notFound.header}</MainTitle>
            <SectionDescription>
                {i18n.notFound.subHeader}
            </SectionDescription>
            <Button
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIosIcon />}
                onClick={() => {
                    location.href = destination;
                }}
            >
                {i18n.notFound.backToHome}
            </Button>
        </Container>
    );
};
