import { searchCollaborators as searchEventCollaborators } from 'api/collaborators';
import { searchPlaybookCollaborators } from 'api/playbooks';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { emailIsValid } from 'utils';
import { Column, Copy, PreCopy, Row } from '../../ui';
import AutoComplete from '../ui/AutoComplete';
import Avatar from '../ui/Avatar';
import PermissionPill, { Permission } from './PermissionPill';

type CollaboratorSuggestion = BizlyAPI.EventCollaboratorSuggestion;
type NewCollaboratorSuggestion = PartialExcept<CollaboratorSuggestion, 'email'>;

const Suggestion = styled(Row)`
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 13px;
    }

    .description {
        display: none;
    }
`;

const SuggestionName = styled(Copy)`
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const SuggestionEmail = styled(SuggestionName)`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

const getUserName = ({ firstName = '', lastName = '' }: CollaboratorSuggestion | NewCollaboratorSuggestion) =>
    [firstName, lastName].join(' ').trim();
const getUserLabel = (user: CollaboratorSuggestion | NewCollaboratorSuggestion) => getUserName(user) || user.email;

type TSearchCollaborators = {
    existingCollaboratorEmails?: string[];
    onChange?: (collaborators: BizlyAPI.NewEventCollaborator[]) => void;
    editor?: boolean;
    onPermissionChange?: (edit: boolean) => void;
    allowNewEmails?: boolean;
    className?: string;
} & ({ eventId: number | string; playbookId?: undefined } | { eventId?: undefined; playbookId: number | string });

const SearchCollaborators = ({
    existingCollaboratorEmails = [],
    onChange,
    editor,
    onPermissionChange,
    allowNewEmails,
    className,
    eventId,
    playbookId,
}: TSearchCollaborators) => {
    const [options, setOptions] = React.useState<(CollaboratorSuggestion | NewCollaboratorSuggestion)[]>([]);
    const [loading, setLoading] = React.useState(false);

    const existingEmailsSet = React.useMemo(() => new Set(existingCollaboratorEmails), [existingCollaboratorEmails]);

    const resetOptions = () => {
        setLoading(false);
        setOptions([]);
    };
    const searchCollaborators = playbookId ? searchPlaybookCollaborators : searchEventCollaborators;

    async function handleSuggestions(query: string) {
        if (!query) {
            resetOptions();
            return;
        }

        setLoading(true);
        const response = await searchCollaborators(query, eventId ?? playbookId);
        setLoading(false);

        const queryIsEmail = emailIsValid(query);
        const notExisting = !response.suggestions.find(suggestion => suggestion.email === query);
        const queryIsNewEmail = queryIsEmail && notExisting && allowNewEmails;

        setOptions([...(queryIsNewEmail ? [{ email: query }] : []), ...response.suggestions]);
    }

    async function handleSelection(value: (CollaboratorSuggestion | NewCollaboratorSuggestion)[]) {
        resetOptions();
        if (onChange) {
            onChange(value.map(collaborator => ({ ...collaborator, editor: false })));
        }
    }

    return (
        <Column className={className}>
            <AutoComplete
                multiple
                renderTag={tag => ({
                    key: tag.email,
                    component: getUserLabel(tag),
                })}
                onBlur={resetOptions}
                loading={loading}
                options={options}
                getOptionLabel={getUserLabel}
                getOptionSelected={(option, value) => option?.email === value?.email}
                noOptionsText={
                    allowNewEmails
                        ? i18n.meetingDashboard.headerSection.collaborators.enterEmail
                        : i18n.meetingDashboard.headerSection.collaborators.askAdmin
                }
                filterOptions={options => options.filter(o => !existingEmailsSet.has(o.email))}
                InputProps={{
                    placeholder: i18n.meetingDashboard.headerSection.collaborators.invitePrompt,
                    endAdornment: (
                        <PermissionPill
                            value={editor ? 'edit' : 'view'}
                            onChange={(permission: Permission) => onPermissionChange?.(permission === 'edit')}
                        />
                    ),
                    autoFocus: true,
                }}
                onInputChange={handleSuggestions}
                onChange={(e, val) => handleSelection(val)}
                renderOption={option =>
                    option.userId ? (
                        <Suggestion>
                            <Avatar user={option} />
                            <Column>
                                {getUserName(option) && <SuggestionName>{getUserName(option)}</SuggestionName>}
                                <SuggestionEmail>{option.email}</SuggestionEmail>
                            </Column>
                        </Suggestion>
                    ) : (
                        <Suggestion>
                            <Column itemSpacing="small">
                                {getUserName(option) && <SuggestionName>{getUserName(option)}</SuggestionName>}
                                <SuggestionEmail>{option.email}</SuggestionEmail>
                                <SuggestionEmail className="description">
                                    <PreCopy>{i18n.meetingDashboard.headerSection.collaborators.suggestion} </PreCopy>
                                </SuggestionEmail>
                            </Column>
                        </Suggestion>
                    )
                }
            />
        </Column>
    );
};

export default SearchCollaborators;
