import Button, { ButtonProps } from '@mui/material/Button';
import { Theme, styled as muiStyled } from '@mui/material/styles';
import { STATUS_LABELS } from 'constants/event';
import React from 'react';

const StyledButton = muiStyled(Button)<{ teamTemplatePill: string }>(
    ({ theme, teamTemplatePill }: { theme: Theme; teamTemplatePill: string }) => ({
        fontSize: '.5625rem',
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.18,
        textTransform: 'uppercase',
        color: theme.palette.common.white,
        backgroundColor: teamTemplatePill,
        borderRadius: theme.shape.borderRadius * 15,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(0.5, 1),
        height: theme.spacing(2.5),
        '&:hover': {
            backgroundColor: teamTemplatePill,
            boxShadow: theme.shadows[2],
        },
    })
);

interface StatusPillProps extends ButtonProps {
    children?: React.ReactNode;
    event?: BizlyAPI.Meeting;
    teamTemplatePill: string;
}

export const StatusPill = ({ children, event, teamTemplatePill, ...props }: StatusPillProps) => {
    if (event) {
        return (
            <StyledButton teamTemplatePill={teamTemplatePill} disableElevation disableRipple {...props}>
                {STATUS_LABELS[event?.status?.code as keyof typeof STATUS_LABELS]}
            </StyledButton>
        );
    }

    return (
        <StyledButton teamTemplatePill={teamTemplatePill} disableElevation disableRipple {...props}>
            {children}
        </StyledButton>
    );
};
