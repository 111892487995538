import AddIcon from '@mui/icons-material/Add';
import { Box, Stack } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { EventCreateProtocolModal } from 'components/EventCreateModal';
import EventCreateModal from 'components/EventCreateModal/NewEventCreateModal';
import { NotificationHeader } from 'components/NotificationHeader/NotificationHeader';
import { TemplateTable } from 'components/Templates/TemplateTable';
import { ButtonWithIcon } from 'components/Ui-V2/Button/Button';
import { PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { useCreateMeeting } from 'hooks/useCreateMeeting';
import useShowModal from 'hooks/useShowModal';
import { i18n } from 'translation';
import { Column, Spacer } from 'ui';
import PlaybookCreateModal from './components/PlaybookCreateModal';

const StyledAddIcon = muiStyled(AddIcon)`
    font-size: 1.125rem;
`;

const StyledButtonWithIcon = muiStyled(ButtonWithIcon)({
    '&.MuiButton-containedPrimary': {
        marginRight: '0px',
        width: 'fit-content',
    },
});

export const Templates = () => {
    const { createModalShown, protocolModalShown, closeCreateModal, hideProtocolModal } = useCreateMeeting();

    const {
        modalShown: playbookModalShown,
        showModal: showPlaybookModal,
        hideModal: hidePlaybookModal,
    } = useShowModal();

    return (
        <>
            <NotificationHeader />
            <Stack gap={2}>
                <PageHeadline withDescription>{i18n.playbooks.playbooks}</PageHeadline>

                <Box display="flex" flexDirection="row">
                    <StyledButtonWithIcon leftIcon={StyledAddIcon} onClick={showPlaybookModal}>
                        {i18n.playbooks.playbookModalCreate}
                    </StyledButtonWithIcon>
                </Box>
            </Stack>

            <Spacer />
            <TemplateTable />

            <Column alignItems="flex-start">
                <Spacer />
            </Column>

            {playbookModalShown && <PlaybookCreateModal onClose={hidePlaybookModal} />}
            {createModalShown && <EventCreateModal onClose={closeCreateModal} />}
            {protocolModalShown && <EventCreateProtocolModal onBackdropClick={hideProtocolModal} />}
        </>
    );
};
