import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import styled from 'styled-components';
import { i18n } from 'translation';
import RightDrawer from './Drawer/RightDrawer';
import InquiryCartRightDrawer from './InquiryCartRightDrawer';

const RightDrawerContent = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 100%;
`;

type RightDrawerVenuesProps = {
    isOpen: boolean;
    footer?: React.ReactNode;
    onClose?: () => void;
};

export default function RightDrawerVenues(props: RightDrawerVenuesProps) {
    const { isOpen, footer, onClose } = props;

    const handleClose = () => {
        onClose?.();
    };

    return (
        <RightDrawer drawerOpen={isOpen} onClose={handleClose} title={i18n.venue.selectedVenues} footer={footer}>
            <RightDrawerContent>
                <Typography>{i18n.meetingsPage.venueDescription}</Typography>
                <Box>
                    <InquiryCartRightDrawer onClose={handleClose} />
                </Box>
            </RightDrawerContent>
        </RightDrawer>
    );
}
