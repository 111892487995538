import * as Sentry from '@sentry/react';
import {
    GuestRoomResponse,
    VenuePutType,
    addGuestRoomCategory,
    bizlyOSVenueSearch,
    getGuestRoomCategories,
    getGuestRoomCategoryType,
    getVenueById,
    getVenueImagesBatch,
    getVenueMeta,
    postVenueImagesBatch,
    removeGuestRoomCategory,
    updateGuestRoomCategory,
    updateVenue,
} from 'api/bizlyOS';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const LISTINGS_QUERY_KEY = 'listings';
const LISTING_QUERY_KEY = 'listing';
const LISTING_META_QUERY_KEY = 'listing_meta';
const LISTING_IMAGES_BATCH_QUERY_KEY = 'listing_images_batch';
const LISTING_GUESTROOM_TYPES_QUERY_KEY = 'guestroom_types';
const LISTING_GUESTROOM_QUERY_KEY = 'guestroom';

type Response = {
    success: boolean;
    venue: Bizly.Venue;
};

type ImageResponse = {
    success: boolean;
    images: Bizly.VenueImage[];
};

export const useGetListingsQuery = () => {
    const result = useQuery({
        queryKey: [LISTINGS_QUERY_KEY],
        queryFn: () => bizlyOSVenueSearch(),
        select: data => data.venues,
    });

    return result;
};

export const useGetListingMetaQuery = () => {
    const result = useQuery({
        queryKey: [LISTING_META_QUERY_KEY],
        queryFn: () => getVenueMeta(),
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24 * 2,
    });

    return result;
};

export const useGetListingImagesBatchQuery = (listingId: string | number) => {
    const result = useQuery({
        queryKey: [LISTING_IMAGES_BATCH_QUERY_KEY, listingId],
        queryFn: () => getVenueImagesBatch(listingId),
        select: data => data.images,
    });

    return result;
};

export const useGetListingQuery = (listingId: string) => {
    const result = useQuery({
        queryKey: [LISTING_QUERY_KEY, listingId],
        queryFn: () => getVenueById(listingId),
        select: data => data.venue,
    });

    return result;
};

export const useUpdateListingMutation = (listingId: string, onFinish: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<Response, Error, VenuePutType>(listing => updateVenue(listingId, listing), {
        onSuccess: response => {
            if (response.success) {
                queryClient.invalidateQueries([LISTING_QUERY_KEY, listingId]);
                onFinish();
            }
        },
        onError: (error: Error) => {
            console.error('Error updating listing:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useUpdateListingMutation',
                    listingId: listingId,
                },
                extra: {
                    listingId: listingId,
                },
            });

            onFinish();
        },
    });
};

export const useGetGuestroomQuery = (listingId: string) => {
    const result = useQuery({
        queryKey: [LISTING_GUESTROOM_QUERY_KEY, listingId],
        queryFn: () => getGuestRoomCategories(listingId),
        select: data => data.guestroomCategories,
    });

    return result;
};

export const useGetGuestroomTypesQuery = () => {
    const result = useQuery({
        queryKey: [LISTING_GUESTROOM_TYPES_QUERY_KEY],
        queryFn: () => getGuestRoomCategoryType(),
        select: data => data.guestroomTypes,
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24 * 2,
    });

    return result;
};

export const useCreateGuestroomMutation = (listingId: string, onFinish: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<GuestRoomResponse, Error, Bizly.GuestroomCategory>(
        guestroom => addGuestRoomCategory(listingId, guestroom),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([LISTING_GUESTROOM_QUERY_KEY, listingId]);
                    onFinish();
                }
            },
            onError: (error: Error) => {
                console.error('Error adding Guestroom:', error);

                Sentry.captureException(error, {
                    tags: {
                        component: 'useCreateGuestroomMutation',
                        listingId: listingId,
                    },
                    extra: {
                        listingId: listingId,
                    },
                });
            },
        }
    );
};

export const useUpdateGuestroomMutation = (listingId: string, categoryId: string, onFinish: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<GuestRoomResponse, Error, Bizly.GuestroomCategory>(
        guestroom => updateGuestRoomCategory(listingId, categoryId, guestroom),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([LISTING_GUESTROOM_QUERY_KEY, listingId]);
                    onFinish();
                }
            },
            onError: (error: Error) => {
                console.error('Error adding Guestroom:', error);

                Sentry.captureException(error, {
                    tags: {
                        component: 'useUpdateGuestroomMutation',
                        listingId: listingId,
                    },
                    extra: {
                        listingId: listingId,
                    },
                });
            },
        }
    );
};

export const useRemoveGuestroomMutation = (listingId: string, onFinish: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<any, Error, number[]>(guestRoomIds => removeGuestRoomCategory(listingId, guestRoomIds), {
        onSuccess: response => {
            if (response.success) {
                queryClient.invalidateQueries([LISTING_GUESTROOM_QUERY_KEY, listingId]);
                onFinish();
            }
        },
    });
};

export const useListingImagesBatchMutation = (listingId: string, onFinish: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<ImageResponse, Error, Partial<Bizly.VenueImage>[]>(
        images => postVenueImagesBatch(listingId, images),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([LISTING_QUERY_KEY, listingId]);
                    onFinish();
                }
            },
        }
    );
};
