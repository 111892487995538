import { eventOptionsAPIDict } from 'api/eventOptionsApi';
import Form from 'components/Form';
import {
    defaultEventSpaceFields,
    eventSpaceNameSchema,
    eventSpaceSchema,
    makeEventSpaceNameField,
} from 'components/Planner/plannerFormSchema';
import { TEventSpace, TPlannerDataChange, TPlannerSections } from 'components/Planner/types';
import { EVENT_SPACES } from 'components/Planner/utils';
import Button from 'components/ui/Button';
import LongButton from 'components/ui/Button/LongButton';
import TrashIcon from 'images/icons/trash-can.svg?react';
import { useEventOptions } from 'providers/event-options';
import { useMemo } from 'react';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Copy, Row, Column as UIColumn } from 'ui';
import { tzMoment } from 'utils/moment';

const Column = styled(UIColumn)`
    width: 100%;
`;

const Content = styled.div`
    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Head = styled(Row)`
    border-radius: 8px 8px 0px 0px;
    padding: 16px 0 16px 16px;
    justify-content: space-between;
`;

const Body = styled(UIColumn)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-radius: 0px 0px 8px 8px;
    padding: 16px 16px 24px;
`;

const RemoveSpaceButton = styled(withInteractibleIconStyles(TrashIcon))`
    align-self: center;
    margin-right: 24px;
`;

const AddEventSpaceCTA = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    font-size: 16px;
    font-weight: 500;
`;

const SaveButton = styled(Button)`
    align-self: flex-end;
    margin-top: 24px;
    font-weight: 400;
`;

const DuplicateSpaceButton = styled(Button)`
    align-self: center;
    margin-right: 24px;
    margin-left: auto;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

type TEventSpaceUpdate = TPlannerDataChange & { value: TEventSpace };

type TEventSpaceDetailsProps = {
    editable: boolean;
    eventSpaces: TEventSpace[];
    addEventSpace: (targetSection: TPlannerSections, initialValues?: TEventSpace) => void;
    removeEventSpace: (idx: number, targetSection: TPlannerSections) => void;
    setEventSpaceData: (eventSpaceData: TEventSpaceUpdate, idx: number, targetSection: TPlannerSections) => void;
    loading: boolean;
    onSave: () => void;
    disableDates: boolean;
    options?: BizlyAPI.Complete.PlaybookOptions;
    readonly?: boolean;
};

const EventSpaceDetails = ({
    editable,
    eventSpaces,
    addEventSpace,
    removeEventSpace,
    setEventSpaceData,
    loading,
    onSave,
    disableDates,
    options,
    readonly,
}: TEventSpaceDetailsProps) => {
    const baseOptions = useEventOptions();
    const { setupOptions, avOptions, fbOptions } = options ?? baseOptions;

    const eventSpaceFields = useMemo(
        () =>
            defaultEventSpaceFields({
                // this filtering allows us to use the labels from the eventOptionsAPIDict while filtering out options that potentially
                // could have been removed from the database
                minDateOption: tzMoment().toDate(),
                setupOptions: eventOptionsAPIDict().setupOptions.filter(val =>
                    setupOptions.map(opt => opt.id).includes(val.id)
                ),
                avOptions: eventOptionsAPIDict().avOptions.filter(val => avOptions.map(opt => opt.id).includes(val.id)),
                fbOptions: eventOptionsAPIDict().fbOptions.filter(val => fbOptions.map(opt => opt.id).includes(val.id)),
            }),
        [setupOptions, avOptions, fbOptions]
    );

    const handleDuplicateEventSpace = (eventSpace: TEventSpace) => {
        const existingNames = eventSpaces.map(space => space.spaceName);

        const originalName = String(eventSpace.spaceName);
        let copySuffix = 1;

        const match = originalName.match(/^(.*) \((\d+)\)$/);
        let duplicatedName = match
            ? `${match[1]} (${(copySuffix = parseInt(match[2], 10) + 1)})`
            : `${originalName} ${i18n.common.copyMeetingSpace(copySuffix)}`;

        while (existingNames.includes(duplicatedName)) {
            duplicatedName = `${originalName} ${i18n.common.copyMeetingSpace(++copySuffix)}`;
        }

        const duplicatedEventSpace = {
            ...eventSpace,
            id: undefined,
            delete: false,
            spaceName: duplicatedName,
        };

        addEventSpace(EVENT_SPACES, duplicatedEventSpace);

        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    };

    return (
        <Column itemSpacing="small">
            {!!eventSpaces?.length &&
                eventSpaces
                    .map((eventSpace, originIndex) => ({ ...eventSpace, originIndex }))
                    .filter(eventSpace => !eventSpace.delete)
                    .map((eventSpace, idx) => {
                        const eventSpaceNameField = makeEventSpaceNameField({
                            onBlur: () => {
                                if (!eventSpace?.spaceName?.length) {
                                    setEventSpaceData(
                                        {
                                            error: null,
                                            field: 'spaceName',
                                            value: { ...eventSpace, spaceName: i18n.common.meetingSpaceIndex(idx + 1) },
                                        },
                                        eventSpace.originIndex,
                                        EVENT_SPACES
                                    );
                                }
                            },
                        });

                        return (
                            <div key={eventSpace.id || `meetingspace-${idx + 1}`}>
                                <Content>
                                    <Head>
                                        <Form
                                            fields={eventSpaceNameField}
                                            schema={eventSpaceNameSchema}
                                            value={eventSpace}
                                            onChange={(e: TEventSpaceUpdate) =>
                                                setEventSpaceData(e, eventSpace.originIndex, EVENT_SPACES)
                                            }
                                            disabled={!editable}
                                            readonly={readonly}
                                        />
                                        {editable && !readonly && (
                                            <>
                                                <DuplicateSpaceButton
                                                    onClick={() => handleDuplicateEventSpace(eventSpace)}
                                                    height={32}
                                                    disabled={!eventSpace || !eventSpace.spaceName}
                                                >
                                                    {i18n.venue.duplicate}
                                                </DuplicateSpaceButton>
                                                <RemoveSpaceButton
                                                    onClick={() =>
                                                        removeEventSpace(eventSpace.originIndex, EVENT_SPACES)
                                                    }
                                                />
                                            </>
                                        )}
                                    </Head>
                                    <Body>
                                        <Form
                                            fields={eventSpaceFields}
                                            schema={eventSpaceSchema(disableDates)}
                                            value={eventSpace}
                                            onChange={(e: TEventSpaceUpdate) =>
                                                setEventSpaceData(e, eventSpace.originIndex, EVENT_SPACES)
                                            }
                                            disabled={!editable}
                                            readonly={readonly}
                                        />
                                    </Body>
                                </Content>
                            </div>
                        );
                    })}

            {editable && !readonly && (
                <LongButton onClick={() => addEventSpace(EVENT_SPACES)} isSecondary>
                    <AddEventSpaceCTA>{i18n.venue.addMeetingSpace}</AddEventSpaceCTA>
                </LongButton>
            )}
            {editable && !readonly && (
                <SaveButton onClick={onSave} disabled={loading}>
                    {i18n.button.save}
                </SaveButton>
            )}
        </Column>
    );
};

export default EventSpaceDetails;
