// Pinched from https://css-tricks.com/image-upload-manipulation-react and migrated to new react-dropzone api
// Requires React >= 16.8.0

import { Component } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

import FileIconSVG from '../images/icons/file.svg?react';
import DeleteIconSVG from '../images/icons/trash-can.svg?react';
import { withIconStyles, withInteractibleIconStyles } from '../shared';

import Button from '../components/ui/Button';
import { CopyFaded, TopRounded } from '../ui';

const FileIcon = withIconStyles(FileIconSVG);
const DeleteIcon = withInteractibleIconStyles(DeleteIconSVG);

const WideCopyFaded = styled(CopyFaded)`
    flex: 1;
`;

export default class FileUploader extends Component {
    state = {
        uploadedFile: null,
    };

    onDrop = files => {
        this.setState({
            uploadedFile: files[0],
        });

        this.props.onSubmit(files[0]);
    };

    onRemove = e => {
        this.props.onRemove();

        e.stopPropagation(); // prevent triggering drop
    };

    render() {
        const { ctaLabel, prompt, accept, fileName, disabled } = this.props;
        return (
            <form>
                <Dropzone onDrop={this.onDrop} multiple={false} accept={accept} disabled={disabled}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <TopRounded>
                                    {fileName && <FileIcon style={{ marginRight: '16px' }} />}
                                    <WideCopyFaded>{fileName ? fileName : prompt}</WideCopyFaded>
                                    {fileName ? (
                                        <DeleteIcon onClick={this.onRemove} />
                                    ) : (
                                        <Button
                                            onClick={e => {
                                                e.preventDefault(); // since this button exists in a form, it will by default submit to itself (causing a reload)
                                            }}
                                            disabled={disabled}
                                        >
                                            {ctaLabel || 'Upload'}
                                        </Button>
                                    )}
                                </TopRounded>
                                <input {...getInputProps()} />
                            </div>
                        </section>
                    )}
                </Dropzone>
            </form>
        );
    }
}
