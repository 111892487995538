import Menu from '@material-ui/core/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button as UIButton } from 'components/Ui-V2/Button/Button';

const Button = styled(UIButton)`
    ${({ theme: { getColor, EColors } }) => `
        background-color: ${getColor(EColors.pureWhite)};
        color: ${getColor(EColors.darkerGrey)};
    `}
`;

type TButtonMenuProps = {
    children: React.ReactNode;
    label?: string;
};

export const ButtonMenu = ({ children, label }: TButtonMenuProps) => {
    const [menuAnchor, setMenuAnchor] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    return (
        <>
            <Button
                fullWidth={false}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setMenuAnchor(menuAnchor ? null : e.currentTarget)}
            >
                {label}
                <KeyboardArrowDownIcon style={{ marginLeft: '8px' }} />
            </Button>
            <Menu
                anchorEl={menuAnchor}
                open={!!menuAnchor}
                onClick={() => setMenuAnchor(null)}
                onClose={() => setMenuAnchor(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {children}
            </Menu>
        </>
    );
};
