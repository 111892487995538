import PhoneField from 'components/PhoneField';
import React from 'react';
import { i18n } from 'translation';
import { Copy, ExternalLink } from 'ui';

export const fields = (agreementLink?: string, country?: string) => ({
    firstName: {
        prompt: i18n.proposalForm.contactInformation.firstName,
        type: 'text',
    },
    lastName: {
        prompt: i18n.proposalForm.contactInformation.lastName,
        type: 'text',
    },
    title: {
        prompt: i18n.proposalForm.contactInformation.title,
        type: 'text',
        perRow: 2,
    },
    phone: {
        prompt: i18n.proposalForm.contactInformation.phoneNumber,
        type: PhoneField,
        options: {
            initialCountry: country?.toLowerCase(),
        },
    },
    email: {
        prompt: i18n.proposalForm.contactInformation.email,
        type: 'text',
    },
    agreementRead: {
        type: 'checkbox',
        options: {
            // TODO: Copy with JSX
            label: (
                <Copy>
                    {i18n.proposalForm.contactInformation.agreementLabel}{' '}
                    <ExternalLink
                        onClick={
                            e => e.stopPropagation() // buttons in a form will be treated as submit
                        }
                        href={agreementLink}
                        openInNewTab
                    >
                        {i18n.proposalForm.contactInformation.clientAgreement}
                    </ExternalLink>
                    {'. '}
                    {i18n.proposalForm.contactInformation.agreementLabel2}
                </Copy>
            ),
        },
    },
});

export const schema = (agreementLink?: string) => [
    {
        key: 'rowOne',
        fields: ['firstName', 'lastName'],
        spacing: 'medium',
    },
    {
        key: 'rowTwo',
        fields: ['email', 'phone'],
        spacing: 'medium',
    },
    {
        key: 'rowThree',
        fields: ['title'],
        spacing: 'large',
    },
    ...(agreementLink
        ? [
              {
                  key: 'rowFour',
                  fields: ['agreementRead'],
                  spacing: false,
              },
          ]
        : []),
];
