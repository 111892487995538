import { Box, IconButton, styled as muiStyled } from '@mui/material';
import { RichTextDisplay } from 'components/Form/RichTextEditor';
import { useCallback } from 'react';
import { bannerActions, useBannerOptions } from 'stores/home/bannerStore';
import CloseIcon from '../../images/icons/close.svg?react';
import { darken, lighten } from '@mui/material/styles';

const BannerContainer = muiStyled(Box, {
    shouldForwardProp: prop => prop !== 'isMinimized',
})<{ isMinimized: boolean }>(({ theme }) => ({
    position: 'fixed',
    bottom: '1.5rem',
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    borderRadius: theme.shape.borderRadius,
    marginRight: '2em',
}));

const ExpandedBanner = muiStyled(Box)(({ theme: { getColor, EColors } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    position: 'relative',
    gap: '1em',
    backgroundColor: getColor(EColors.brand) || getColor(EColors.pureWhite),
}));

const MinimizedBanner = muiStyled(Box)(({ theme: { getColor, EColors } }) => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: getColor(EColors.brand) || getColor(EColors.pureWhite),
}));

const StyledCloseIcon = muiStyled(CloseIcon)(({ theme: { getColor, EColors, isThemeBright } }) => ({
    width: 30,
    height: 30,
    color: isThemeBright ? darken(getColor(EColors.brand), 0.7) : lighten(getColor(EColors.brand), 1),
    stroke: isThemeBright ? darken(getColor(EColors.brand), 0.7) : lighten(getColor(EColors.brand), 1),
    strokeWidth: '.125rem',
}));

const StyledImg = muiStyled('img')({
    height: '1.625rem',
});

const StyledTextDisplay = muiStyled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    marginTop: '-0.938rem',
    marginBottom: '-0.938rem',
    padding: '1em',
    lineHeight: spacing(2.5),
    color: getColor(EColors.sidenavForeground),
    "& a": {
        color: getColor(EColors.bannerLinkText),
        "& :hover": {
            color: getColor(EColors.bannerLinkText, 0.4),
        }
    }
}));

interface HomeScreenBannerProps {
    message: string;
    teamImage: string;
}

export const BottomBanner = ({ message, teamImage }: Readonly<HomeScreenBannerProps>) => {
    const { isBannerVisible } = useBannerOptions();
    const toggleBanner = useCallback(() => {
        bannerActions.setBannerVisibility(!isBannerVisible);
    }, [isBannerVisible]);

    return (
        <BannerContainer isMinimized={!isBannerVisible}>
            {isBannerVisible ? (
                <ExpandedBanner>
                    <Box display="flex" alignItems="center" gap={2}>
                        <StyledImg src={teamImage} alt="team-logo" />
                        <StyledTextDisplay>
                            <RichTextDisplay value={message} />
                        </StyledTextDisplay>
                    </Box>
                    <IconButton onClick={toggleBanner} size="small" sx={{ color: 'inherit' }}>
                        <StyledCloseIcon />
                    </IconButton>
                </ExpandedBanner>
            ) : (
                <MinimizedBanner onClick={toggleBanner}>
                    <Box display="flex" alignItems="center">
                        <StyledImg src={teamImage} alt="team-logo" />
                    </Box>
                </MinimizedBanner>
            )}
        </BannerContainer>
    );
};
