import AddIcon from '@mui/icons-material/Add';
import { Box, Button, styled, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DataGridPro, GridColDef, GridRowParams, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { GridContainer } from 'components/BizlyOS/GridContainer';
import { ImageWithSkeleton } from 'components/BizlyOS/Image/ImageWithSkeleton';
import { Tab, Tabs } from 'components/BizlyOS/Tabs';
import { PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { StyledGridToolbarQuickFilter } from 'components/Ui-V2/SearchInput/SearchInput';
import { useGetListingsQuery } from 'hooks/queries/BizlyOS/useListingQuery';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { tzMoment, userTimeZone } from 'utils/moment';

type StatusType = 'All' | 'Active' | 'Draft';

type ListingType = {
    id: number;
    imageUrl: string;
    name: string;
    address: string;
    listingType: string;
    updatedAt: string | Date | null;
    status: 'Active' | 'Draft';
};

const VENUE_STATUS_ACTIVE_ID = 1;

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
    flexGrow: 1,
    '& .MuiDataGrid-root': {
        border: 'none',
    },
    '& .MuiToggleButtonGroup-root': {
        margin: theme.spacing(0.5, 0),
    },
    '& .MuiDataGrid-columnHeader': {
        borderRight: 'none',
        backgroundColor: theme.palette.grey[20],
    },
    '& .MuiDataGrid-row': {
        cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
}));

const StyledToolbarContainer = styled(GridToolbarContainer)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.drWhite),
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const StyledAddButton = styled(Button)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.bizlyOSPrimary),
    height: '40px',
    '&:hover': { backgroundColor: getColor(EColors.bizlyOSPrimary, 1.2) },
    '& .MuiButton-icon.MuiButton-startIcon': {
        marginRight: 0,
    },
}));

const columns: GridColDef[] = [
    {
        field: 'name',
        renderHeader: () => (
            <Typography variant="body2" fontWeight={500} px="10px">
                Listing
            </Typography>
        ),
        flex: 1,
        minWidth: 100,
        renderCell: params => {
            return (
                <Box display="flex" padding="10px" gap="20px">
                    <ImageWithSkeleton src={params.row.imageUrl} alt="Primary Venue Image" width="100" height="80" />
                    <Box display="flex" flexDirection="column" justifyContent="center" gap="4px">
                        <Typography variant="body2" fontWeight={500}>
                            {params.value}
                        </Typography>
                        <Typography variant="body2">{params.row.address}</Typography>
                    </Box>
                </Box>
            );
        },
    },
    { field: 'address', headerName: 'Address' },
    { field: 'listingType', headerName: 'Listing Type', width: 150 },
    { field: 'id', headerName: 'Listing ID', width: 150 },
    {
        field: 'updatedAt',
        headerName: 'Last Updated',
        width: 150,
        valueGetter: params => {
            return tzMoment(params, userTimeZone).format('MMM D, YYYY');
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: params => {
            return (
                <Chip
                    sx={{
                        backgroundColor:
                            params.value === 'Active'
                                ? getColor(EColors.bizlyOSPrimary, 0.2)
                                : getColor(EColors.bizlyOSYellow, 0.2),
                        height: 30,
                        width: 100,
                    }}
                    label={params.value}
                />
            );
        },
    },
];

const ListingsToolBar = ({
    filterValue,
    onFilterChange,
}: {
    filterValue: StatusType;
    onFilterChange: (value: StatusType) => void;
    onSerch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <StyledToolbarContainer>
            <Tabs
                value={filterValue}
                onChange={(_: React.SyntheticEvent, newValue: StatusType) => {
                    onFilterChange(newValue);
                }}
            >
                <Tab value="All" label="All" />
                <Tab value="Active" label="Active" />
                <Tab value="Draft" label="Draft" />
            </Tabs>
            <StyledGridToolbarQuickFilter />
        </StyledToolbarContainer>
    );
};

export const Listings = () => {
    const navigate = useNavigate();
    const canViewListingsFlag = useFeatureFlagEnabled('toggleViewListing');
    const canCreateListingsFlag = useFeatureFlagEnabled('toggleCreateListing');

    const { data: rows, isLoading } = useGetListingsQuery();

    const [filter, setFilter] = useState<StatusType>('All');

    const filteredRows = useMemo(() => {
        const filteredRows: ListingType[] = [];

        if (!rows) return filteredRows;

        for (let index = 0; index < rows.length; index++) {
            const listing: ListingType = {
                id: rows[index].id,
                imageUrl: rows[index].imageUrl,
                name: rows[index].name,
                address: rows[index].address,
                listingType: rows[index].type.name,
                updatedAt: rows[index].emissionsReport?.updatedAt || null,
                status: rows[index].statusId === VENUE_STATUS_ACTIVE_ID ? 'Active' : 'Draft',
            };

            if (filter === 'All') {
                filteredRows.push(listing);
            } else {
                if (listing.status === filter) {
                    filteredRows.push(listing);
                }
            }
        }

        return filteredRows;
    }, [rows, filter]);

    const handleRowClick = (params: GridRowParams<ListingType>) => {
        return canViewListingsFlag ? navigate(`/os/listings/${params.row.id}/general-info`) : null;
    };

    return (
        <GridContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <PageHeadline withDescription bold>
                    Your Listings
                </PageHeadline>
                {canCreateListingsFlag && (
                    <StyledAddButton variant="contained" startIcon={<AddIcon sx={{ transform: 'scale(0.7)' }} />}>
                        Create New Listing
                    </StyledAddButton>
                )}
            </Box>
            <StyledDataGridPro
                rows={filteredRows}
                onRowClick={handleRowClick}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            address: false,
                        },
                    },
                    filter: {
                        filterModel: {
                            items: ['name', 'address', 'listingType', 'id'],
                            quickFilterExcludeHiddenColumns: false,
                        },
                    },
                }}
                loading={isLoading}
                rowHeight={100}
                slots={{
                    toolbar: ListingsToolBar,
                }}
                slotProps={{
                    toolbar: {
                        filterValue: filter,
                        onFilterChange: setFilter,
                    },
                }}
                hideFooter
            />
        </GridContainer>
    );
};
