import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { GridContainer } from './GridContainer';

const BorderedContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
}));

const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(4),
    maxWidth: '37.5rem',
    margin: '0 auto',
    height: '100%',
}));

const StyledButton = styled(Button)(({ theme: { getColor, EColors }, theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 4),
    backgroundColor: getColor(EColors.bizlyOSPrimary),
    '&:hover': {
        backgroundColor: getColor(EColors.bizlyOSPrimary),
        opacity: 0.9,
    },
}));

interface ComingSoonMessageProps {
    title: string;
    description: string;
    buttonText: string;
    onButtonClick: () => void;
    Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    pageTitle: string;
}

export const ComingSoonMessage = ({
    title,
    description,
    buttonText,
    onButtonClick,
    Icon,
    pageTitle,
}: ComingSoonMessageProps) => {
    return (
        <GridContainer>
            <PageHeadline withDescription bold>
                {pageTitle}
            </PageHeadline>
            <BorderedContainer>
                <ContentBox>
                    <Icon style={{ width: '12.5rem', height: '12.5rem' }} />
                    <Typography variant="h4" component="h1" gutterBottom fontWeight="500">
                        {title}
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ maxWidth: '30rem' }}>
                        {description}
                    </Typography>
                    <StyledButton variant="contained" color="primary" onClick={onButtonClick}>
                        {buttonText}
                    </StyledButton>
                </ContentBox>
            </BorderedContainer>
        </GridContainer>
    );
};
