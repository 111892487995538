import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from 'translation';
import { scheduleActions } from './store';

export default function LoadSchedule() {
    const { eventId } = useParams<{ eventId: string }>();

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            scheduleActions.load(parseInt(eventId ?? ''));
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    }, [eventId, enqueueSnackbar]);

    return null;
}
