import { TFBOption, TFBRequest, TOption, TSpace } from './EventSpacesForm/utils';

export enum FORM_SECTIONS {
    CONTACT = 'contact',
    GUEST_ROOMS = 'guest_rooms',
    EVENT_SPACES = 'event_spaces',
    NOTES = 'notes',
    PROPOSAL_STATUS = 'proposal_status',
}

type TClient = {
    firstName: string;
    lastName: string;
    companyName: string;
    logoUrl: string;
};

type TEvent = {
    name: string;
    startsAt: string;
    endsAt: string;
    cancelledAt?: string;
};

type TInquiry = {
    notes: string;
    datesFlexible: boolean;
    status: string;
    isValid: boolean;
    rejectionReason: string;
    rejectionNotes: string;
};

type TContact = {
    firstName: string;
    lastName: string;
    title: string;
    phone: string;
    email: string;
} | null;

export type TAttachment = {
    name: string | null;
    url: string;
    format: string;
    bytes: number;
};

export type TCurrency = {
    id: string | number;
    code: string;
};

export type TProposal = {
    resortFee: number | null;
    occupancyTax: number | null;
    gratuity: number | null;
    salesTax: number | null;
    serviceCharge: number | null;
} & Partial<{
    id: number;
    msaDocumentUrl: string;
    commissionable: boolean | null;
    bizlyCollectsCommissions: boolean | null;
    datesFlexibleNotes: string;
    expiryDate: string | null;
    additionalDetails: string;
    attachments: TAttachment[] | null;
    status: string;
    currency: TCurrency | null;
    useDdr: boolean | string | null;
    extensionRequestedAt: string;
    extensionRejectedAt: string;
    guestRoomCommissionRate: number | null;
    eventSpaceCommissionRate: number | null;
    eventSpacesCommissionable: boolean | null;
    fbCommissionRate: number | null;
    fbCommissionable: boolean | null;
    totals: {
        guestRoomNight: number;
        eventSpaces: number;
    }
}>;

export type TBooking = {
    id: number | null;
    contractedAt: string | null;
    contractUrl: string | null;
    contract2Url: string | null;
    invoiceUrl: string | null;
    invoice2Url: string | null;
};

export type TGRBooking = {
    date: string;
} & Partial<{
    requestedGuests: number;
    requestNotes: string;
    proposedRoomCount: number | null;
    image: string | null;
    proposedImageUrl?: File;
    proposedRoomName: string | null;
    proposedRoomRate: number | null;
}>;

export type TGRBookingProposal = Omit<TGRBooking, 'requestedGuests' | 'requestNotes'>;

export type TESBooking = {
    inquirySpaceId: number;
    requestedDate: string;
    requestedStartTime: string;
    requestedEndTime: string;
} & Partial<{
    requestedSpaceName: string | null;
    requestedGuests: number;

    requestedSetupId: number;

    requestedAvIds: number[];
    requestedFb: TFBRequest[];

    // proposed

    proposalSpaceId: number | null;

    proposedDate: string;
    proposedStartTime: string;
    proposedEndTime: string;

    proposedVenueSpaceId: number | null;

    proposedSetupId: number | null;

    proposedFb: TFBRequest[];
    proposedAvIds: number[];

    proposedRoomRate: number | null;
    proposedFbMinimum: number | null;

    proposedFbPerPerson: null;

    proposedRatePerPerson: number | null;
    proposedMinGuests: number | null;
}>;

export type TESRequestKeys =
    | 'requestedDate'
    | 'requestedStartTime'
    | 'requestedEndTime'
    | 'requestedGuests'
    | 'requestedSetupId'
    | 'requestedAvIds'
    | 'requestedFb';

type TESBookingProposal = Omit<TESBooking, TESRequestKeys>;

type TProposalOptions = {
    spaceSetups: TOption[];

    avOptions: TOption[];

    fbOptions: TFBOption[];
    diningStyles: TOption[];

    venueSpaces: TSpace[];

    currencies?: TCurrency[];
};

export type TProposalForm = {
    client: Partial<TClient>;
    event: Partial<TEvent>;
    inquiry: Partial<TInquiry>;
    venue: Partial<Bizly.Venue> & {
        country?: {
            code: string;
            name: string;
        } | null;
    };

    contact: Partial<TContact>;
    proposal: Partial<TProposal>;

    booking: Partial<TBooking>;
    guestRooms: TGRBooking[];
    eventSpaces: TESBooking[];

    options: TProposalOptions;
};

export type TProposalFormUpdate = Pick<TProposalForm, 'contact' | 'proposal'> & {
    guestRooms: TGRBookingProposal[];
    eventSpaces: TESBookingProposal[];
    proposal: { currencyCode?: TCurrency['code'] };
};
