import Box, { BoxProps } from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';

interface ImageWithSkeletonProps extends BoxProps {
    src: string;
    alt: string;
    width?: number | string;
    height?: number | string;
}

export const ImageWithSkeleton = ({ src, alt, width = 300, height = 200, ...props }: ImageWithSkeletonProps) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        const img = new Image();
        img.onload = () => {
            setIsLoading(false);
        };
        img.src = src;

        return () => {
            img.onload = null;
        };
    }, [src]);

    return (
        <Box position="relative" width={`${width}px`} height={`${height}px`} {...props}>
            {isLoading && (
                <Skeleton
                    variant="rounded"
                    width={width}
                    height={height}
                    animation="wave"
                    sx={{ position: 'absolute', top: 0, left: 0 }}
                />
            )}
            <img
                src={src}
                alt={alt}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: `${width}px`,
                    height: `${height}px`,
                    borderRadius: '4px',
                    objectFit: 'cover',
                    opacity: isLoading ? 0 : 1,
                    transition: 'opacity 0.3s ease-in-out',
                }}
            />
        </Box>
    );
};
