import colorFns from 'colorFns';
import styled from 'styled-components';
import { Copy } from 'ui';
import DeleteConfirmationModal, { TDeleteConfirmationModal } from './DeleteConfirmationModal';

const WarningCopy = styled(Copy)`
    color: ${colorFns.warningText};
`;

export default function AgendaPhaseOutWarning(
    props: Pick<TDeleteConfirmationModal, 'onDismiss' | 'onProceed' | 'isActive'>
) {
    return (
        <DeleteConfirmationModal
            {...props}
            title="ARE YOU SURE?"
            prompt={
                <WarningCopy>
                    The agenda feature is being phased out. <br /> <br />
                    This is the last block on your agenda. Deleting this will remove access to the agenda feature for
                    this event.
                </WarningCopy>
            }
            ctaLabel="I understand"
        />
    );
}
