import { Box, styled } from '@mui/material';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Toolbar from './Toolbar';

export const Container = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    height: 'auto',
    border: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(1.25),
    '&:focus-within': {
        borderColor: getColor(EColors.bizlyOSPrimary),
    },
}));

const extensions = [StarterKit, Underline];

type TiptapProps = {
    description: string;
    onChange: (_: string) => void;
};

export default function Tiptap({ description, onChange }: TiptapProps) {
    const editor = useEditor({
        extensions,
        content: description,
        editorProps: {
            attributes: {
                class: 'tiptap-input',
            },
        },
        onUpdate({ editor }) {
            onChange(editor.getHTML());
        },
    });

    return (
        <Container>
            <Toolbar editor={editor} />
            <EditorContent editor={editor} />
        </Container>
    );
}
