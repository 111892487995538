import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { useGetListingMetaQuery, useUpdateListingMutation } from 'hooks/queries/BizlyOS/useListingQuery';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import * as z from 'zod';
import InputField from '../InputFields/InputField';
import PhoneField from '../InputFields/PhoneField';
import RichTextEditorField from '../InputFields/RichTextEditorField';
import SelectField from '../InputFields/SelectField';
import TitleledContainer from '../TitleledContainer';
import ListingActions from './ListingActions';

export const GeneralInfoSchema = z.object({
    name: z.string().min(1, 'Name is required'),
    address: z.string().min(1, 'Address is required'),
    headline: z.string().min(1, 'Headline is required'),
    summary: z.string().min(8, 'Summary is required'),
    phone: z.string().min(1, 'Phone is required'),
    website: z.string().min(1, 'Website is required').url('Website must be a URL'),
    brand: z.number().min(1, 'Brand is required'),
    chain: z.number().min(1, 'Chain is required'),
});

export type GeneralInfoFormData = z.infer<typeof GeneralInfoSchema>;

function ListingGeneralInfo({ listing }: { listing: Bizly.Venue }) {
    const updateListingMutation = useUpdateListingMutation(listing.id.toString(), () => setOpenSnackbar(true));
    const { data: listingMeta, isLoading: isListingMetaLoading } = useGetListingMetaQuery();

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleClose = (_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
        watch,
    } = useForm<GeneralInfoFormData>({
        resolver: zodResolver(GeneralInfoSchema),
        defaultValues: {
            name: listing.name,
            address: listing.address,
            headline: listing.headline,
            summary: listing.summary,
            phone: listing?.phone || '',
            website: listing?.website || '',
            chain: listing.chain?.id,
            brand: listing.brand?.id,
        },
        mode: 'onChange',
    });
    const watchedFields = watch();

    const brandOptions = useMemo(() => {
        if (!listingMeta?.venueBrands) return [];
        if (listingMeta.venueBrands.length === 0) return [];

        return listingMeta.venueBrands.map(brand => ({ value: brand.id, label: brand.name }));
    }, [listingMeta]);

    const chainOptions = useMemo(() => {
        if (!watchedFields.brand) return [];
        if (!listingMeta?.venueBrands) return [];

        const brand = listingMeta.venueBrands.find(brand => brand.id === Number(watchedFields.brand));

        if (!brand) return [];
        if (brand.chains.length === 0) return [];

        return brand.chains.map(chains => ({ value: chains.id, label: chains.name }));
    }, [watchedFields.brand, listingMeta]);

    const onSubmit = (data: GeneralInfoFormData) => {
        const listingUpdatData = {
            name: data.name,
            headline: data.headline,
            summary: data.summary,
            website: data.website,
            phone: data.phone,
            chain_id: Number(data.chain),
            brand_id: Number(data.brand),
            google_place_id: listing.googlePlaceId,
        };
        updateListingMutation.mutate(listingUpdatData);
        reset(data);
    };

    return (
        <>
            <TitleledContainer title="General Info">
                <Box display="flex" flexDirection="column" gap={2.5}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => {
                            return <InputField {...field} error={errors.name} label="Name" />;
                        }}
                    />
                    <Controller
                        name="address"
                        control={control}
                        render={({ field }) => {
                            return <InputField {...field} error={errors.address} label="Venue Address" />;
                        }}
                    />

                    <Controller
                        name="headline"
                        control={control}
                        render={({ field }) => {
                            return <InputField {...field} error={errors.headline} label="Headline" />;
                        }}
                    />

                    <Controller
                        name="summary"
                        control={control}
                        render={({ field }) => {
                            return <RichTextEditorField {...field} error={errors.summary} label="Description" />;
                        }}
                    />

                    <Controller
                        name="website"
                        control={control}
                        render={({ field }) => {
                            return (
                                <InputField {...field} error={errors.website} label="Website" placeholder="https://" />
                            );
                        }}
                    />

                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => {
                            return <PhoneField {...field} error={errors.phone} label="Phone" />;
                        }}
                    />

                    {!isListingMetaLoading && isDirty && (
                        <Box display="flex" gap={2}>
                            <Box flex={1}>
                                <Controller
                                    name="brand"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <SelectField
                                                {...field}
                                                value={field.value || ''}
                                                error={errors.phone}
                                                label="Brand"
                                                options={brandOptions}
                                            />
                                        );
                                    }}
                                />
                            </Box>
                            <Box flex={1}>
                                <Controller
                                    name="chain"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <SelectField
                                                {...field}
                                                value={field.value || ''}
                                                error={errors.phone}
                                                label="Chain"
                                                options={chainOptions}
                                                emptyMessage="Please first select a brand"
                                            />
                                        );
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </TitleledContainer>
            <ListingActions
                state="edit"
                isSaving={updateListingMutation.isLoading}
                isChanges={isDirty}
                onSave={handleSubmit(onSubmit)}
                onCancel={() => reset()}
            />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                sx={{
                    '&.MuiSnackbar-root': { top: '65px', right: '20px' },
                }}
            >
                <Alert
                    severity="success"
                    icon={false}
                    variant="filled"
                    sx={{
                        width: '100%',
                        backgroundColor: updateListingMutation.isError ? 'red' : getColor(EColors.bizlyOSPrimary),
                    }}
                    onClose={handleClose}
                >
                    {updateListingMutation.isError
                        ? updateListingMutation.error.toString() || 'Failed Request'
                        : 'Successfully Saved'}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ListingGeneralInfo;
