import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { meetingsPagesActions } from './pagesStore';

export default function LoadMeetingsPage({ page, scheduled }: { page?: number; scheduled?: boolean }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            meetingsPagesActions.load(page, scheduled);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    }, [page, scheduled, enqueueSnackbar]);

    return null;
}
