import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import FiltersButton from 'components/FilterButton';
import Tokenizer, { TToken } from 'components/Tokenizer';
import { StyledGridToolbarQuickFilter } from 'components/Ui-V2/SearchInput/SearchInput';
import { useEffect, useRef, useState } from 'react';
import { Copy } from 'ui';

const TokenizerWrapper = styled(Box)<{ hasTokens: boolean; isOverflow: boolean }>`
    position: relative;
    max-width: calc(100% - 450px); // filter button 110px, search input 300px, 20px padding
    ${({ hasTokens, theme }) =>
        hasTokens &&
        `
            border-left: 1px solid ${theme.getColor(theme.EColors.grey)};
        `}
    ${({ isOverflow, theme }) =>
        isOverflow &&
        `
            border-right: 1px solid ${theme.getColor(theme.EColors.grey)};
        `}
`;

const TokenizerContainer = styled(Box)`
    display: flex;
    width: 100%;
    overflow-x: auto;
    box-sizing: content-box;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const ScrollButton = styled(IconButton)<{ show: boolean }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    cursor: pointer;
    z-index: 10;
    padding: 0;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }

    &.left {
        left: 2px;
        display: ${({ show }) => (show ? 'flex' : 'none')};
    }

    &.right {
        right: 2px;
        display: ${({ show }) => (show ? 'flex' : 'none')};
    }
`;

type TEventTableToolbarProps = {
    tokens: TToken[];
    onToggleFilters: () => void;
    showEventFilters: boolean;
};

export const EventTableToolbar = ({ showEventFilters, tokens, onToggleFilters }: TEventTableToolbarProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [showScrollButtons, setShowScrollButtons] = useState({ left: false, right: false });

    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            if (container) {
                const { scrollWidth, clientWidth, scrollLeft } = container;
                setShowScrollButtons({
                    left: scrollLeft > 0,
                    right: scrollWidth > clientWidth + scrollLeft,
                });
            }
        };

        handleScroll();

        window.addEventListener('resize', handleScroll);
        container?.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleScroll);
            container?.removeEventListener('scroll', handleScroll);
        };
    }, [tokens]);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    return (
        <GridToolbarContainer>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: showEventFilters ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                    pb: 0.5,
                    boxSizing: 'border-box',
                    minHeight: '55px',
                }}
                gap={1}
            >
                {showEventFilters && (
                    <>
                        <FiltersButton toggleFiltersMenu={onToggleFilters} filterCount={tokens.length} />
                        <TokenizerWrapper
                            display="flex"
                            flex={1}
                            hasTokens={tokens.length > 0}
                            isOverflow={
                                containerRef.current &&
                                containerRef.current.scrollWidth > containerRef.current.clientWidth
                            }
                        >
                            <TokenizerContainer ref={containerRef}>
                                <Copy>
                                    <Tokenizer tokens={tokens} />
                                </Copy>
                            </TokenizerContainer>
                            {showScrollButtons.left && (
                                <ScrollButton
                                    variant="outlined"
                                    className="left"
                                    onClick={scrollLeft}
                                    show={showScrollButtons.left}
                                >
                                    <KeyboardArrowLeftIcon />
                                </ScrollButton>
                            )}
                            {showScrollButtons.right && (
                                <ScrollButton
                                    variant="text"
                                    className="right"
                                    onClick={scrollRight}
                                    show={showScrollButtons.right}
                                >
                                    <KeyboardArrowRightIcon />
                                </ScrollButton>
                            )}
                        </TokenizerWrapper>
                    </>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {/* TODO: Hiding these buttons for now since grid cards need more clarification */}
                    {/* <SwitchGroup value={alignment} exclusive onChange={handleViewChange}>
                        <ToggleButton value="grid">
                            <StyledGridIcon alignment />
                            {i18n.homepage.eventTableActions.grid}
                        </ToggleButton>
                        <ToggleButton value="list">
                            <StyledListIcon alignment />
                            {i18n.homepage.eventTableActions.list}
                        </ToggleButton>
                    </SwitchGroup> */}
                    <StyledGridToolbarQuickFilter placeholder="Search Event Name" />
                </Box>
            </Box>
        </GridToolbarContainer>
    );
};
