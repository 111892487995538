import { BookedState } from 'components/VenueCard';

export enum VenueStatus {
    Added = 'Added',
    Submitted = 'Submitted',
    ProposalReceived = 'Proposal Received',
    ProposalRejected = 'Proposal Rejected',
    ProposalCancelled = 'Proposal Cancelled',
    ExtensionPending = 'Extension Pending',
    ExtensionRejected = 'Extension Rejected',
    ProposalExpired = 'Proposal Expired',
    InquiryRejected = 'Inquiry Rejected',
    InquiryCancelled = 'Inquiry Cancelled',
    ContractPending = 'Contract Pending',
    Booked = 'Booked',
    BookingCancelled = 'Booking Cancelled',
}

export enum TabLabels {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}

export const PROPOSAL_INQUIRY_STATUS = new Set<BookedState>(['Proposal Received', 'Contract Pending', 'Booked', 'Submitted'])
export const CANCELLED_OR_EXPIRED_STATUS = new Set<BookedState>([
    'Inquiry Rejected',
    'Inquiry Cancelled',
    'Proposal Cancelled',
    'Proposal Rejected',
    'Proposal Expired',
    'Extension Pending',
    'Extension Rejected',
]);
