import { i18n } from 'translation';
import { durationOptions, timeOptions } from 'utils/date_util';
import VirtualMeetingField from './VirtualMeetingField';

export const defaultScheduleFormFields = ({
    schedItemDate,
    integratedVmServices,
    vmOnAdd,
    vmOnEdit,
    vmOnDelete,
}: {
    schedItemDate?: string;
    integratedVmServices?: string[];
    vmOnAdd: (service?: string) => void;
    vmOnEdit: () => void;
    vmOnDelete: () => void;
}) => ({
    name: {
        prompt: i18n.proposalForm.contactInformation.title,
        type: 'text',
    },
    date: {
        prompt: i18n.datetime.date,
        type: 'date_outlined',
        options: {
            minDate: Date.now(),
            placeholder: i18n.datetime.date,
        },
    },
    startTime: {
        prompt: i18n.datetime.startTime,
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: i18n.datetime.startTime,
            autoFocusOptionKey: '12:00:00',
        },
    },
    endTime: {
        prompt: i18n.datetime.endTime,
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: i18n.datetime.endTime,
            autoFocusOptionKey: '12:00:00',
        },
    },
    timeZone: {
        prompt: ' ',
        type: 'timezone_select',
        perRow: 'auto',
        options: {
            date: schedItemDate,
        },
    },
    location: {
        prompt: i18n.homepage.createMeetingModal.location,
        type: 'text',
    },
    virtualMeeting: {
        prompt: i18n.meetingDashboard.vendorsSection.virtualMeeting,
        type: VirtualMeetingField,
        options: {
            onAdd: vmOnAdd,
            onEdit: vmOnEdit,
            onDelete: vmOnDelete,
            cta: i18n.meetingDashboard.vendorsSection.addVirtualMeeting,
            limit: 1,
            integratedVmServices,
        },
    },
    description: {
        prompt: i18n.proposalForm.eventSpaces.description,
        type: 'rich_text',
    },
    attachments: {
        prompt: i18n.scheduledForm.attachDocuments,
        type: 'upload_list',
        perRow: 1,
        options: {
            prompt: i18n.scheduledForm.addDocument,
        },
    },
    colorId: {
        prompt: i18n.scheduledForm.selectColor,
        type: 'color_picker',
        perRow: 1,
    },
});

export const scheduleFormSchemaWithTz = [
    // Todo: make this the default schema once Templates V2 is officially released for all
    {
        key: 'schedName',
        fields: ['name'],
        spacing: 'medium',
    },
    {
        key: 'schedDates',
        fields: ['date', 'startTime', 'endTime', 'timeZone'],
        spacing: 'medium',
    },
    {
        key: 'schedLocation',
        fields: ['location'],
        spacing: 'medium',
    },
    {
        key: 'schedVirtualMeeting',
        fields: ['virtualMeeting'],
        spacing: 'medium',
    },
    {
        key: 'schedDescription',
        fields: ['description'],
        spacing: 'medium',
    },
    {
        key: 'schedAttachments',
        fields: ['attachments'],
        spacing: 'medium',
    },
    {
        key: 'schedColor',
        fields: ['colorId'],
        spacing: false,
    },
];

export const agendaFields = {
    title: {
        prompt: i18n.proposalForm.contactInformation.title,
        type: 'text',
        options: {
            placeholder: i18n.scheduledForm.agendaPlaceholder,
        },
    },
    duration: {
        prompt: i18n.scheduledForm.duration,
        type: 'select',
        options: {
            options: durationOptions,
            placeholder: '-',
            autoFocusOptionKey: 35,
        },
    },
    description: {
        prompt: i18n.proposalForm.eventSpaces.description,
        type: 'rich_text',
        options: {
            placeholder: i18n.scheduledForm.descriptionPlaceholder,
        },
    },
};

export const agendaSchema = [
    {
        key: 'titleDuration',
        fields: ['title', 'duration'],
        spacing: 'medium',
    },
    {
        key: 'description',
        fields: ['description'],
        spacing: false,
    },
];

export const defaultVMFields = (teamServiceTypes: Partial<Bizly.VirtualMeetingServiceProvider>[] = []) => ({
    serviceProvider: {
        prompt: i18n.venue.planner.serviceType,
        type: 'select',
        fixedWidth: 168,
        options: {
            options: teamServiceTypes,
            placeholder: i18n.venue.planner.selectService,
            overflowTruncate: true,
            valueAsOption: true,
        },
    },
    link: {
        prompt: i18n.venue.planner.link,
        type: 'text',
        options: {
            placeholder: 'https://www.host.com/meeting_id',
        },
    },
    notes: {
        prompt: i18n.venue.planner.additionalMeetingInfo,
        type: 'rich_text',
        options: {
            backgroundWhite: true,
        },
        optional: true,
    },
});

export const VMSchemaLink = [
    {
        key: 'serviceProvider',
        fields: ['link'],
        spacing: false,
    },
];

export const VMSchemaNotes = [
    {
        key: 'additionalInfo',
        fields: ['notes'],
        spacing: false,
    },
];
