import { alpha, styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { i18n } from 'translation';

const StyledOverlay = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
});

export const MobileSplashBlocker = ({ children }: { children: React.ReactNode }) => {
    const theme = useTheme();
    const { getColor, EColors } = theme;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (!isMobile) {
        return children;
    }

    const pureBlackWithOpacity = alpha(getColor(EColors.pureBlack), 0.8);

    return (
        <StyledOverlay style={{ backgroundColor: pureBlackWithOpacity }}>
            <Typography variant="h5" color={getColor(EColors.pureWhite)} align="center">
                {i18n.error.desktopOnlyMessage}
            </Typography>
        </StyledOverlay>
    );
};
