import { Box, styled } from '@mui/material';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { Tab, Tabs } from 'components/BizlyOS/Tabs';
import { useState } from 'react';
import { EColors, getColor } from 'theme';

type StatusMappingKeys = 'All' | 'New Leads';

const statusMappings: Record<StatusMappingKeys, StatusType> = {
    All: 'All',
    'New Leads': 'New Inquiry',
};

interface ProposalInquiriesToolBarProps {
    onFilterChange: (newFilter: StatusType) => void;
}

const StyledToolbarContainer = styled(GridToolbarContainer)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.drWhite),
    padding: '.625rem 1.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(() => ({
    backgroundColor: getColor(EColors.pureWhite),
    border: '1px solid ' + getColor(EColors.bizlyOSBorder),
    width: '300px',
    padding: '.125rem .625rem',
    borderRadius: 6,
    '& .MuiInputBase-root': {
        fontSize: '0.8rem',
        content: 'none',
    },
    '& .MuiInputBase-root:after': {
        content: 'none',
    },
    '& .MuiInputBase-root:before': {
        content: 'none',
    },
}));

const StyledGridToolbarButton = styled(Box)(({ theme: { getColor, EColors } }) => ({
    '& .MuiButtonBase-root': {
        color: getColor(EColors.pureBlack),
    },

    '& .MuiSvgIcon-root': {
        color: getColor(EColors.pureBlack),
    },
}));

export const ProposalInquiriesToolBar = ({ onFilterChange }: ProposalInquiriesToolBarProps) => {
    const [filter, setFilter] = useState<StatusMappingKeys>('All');

    const handleFilterChange = (_: React.SyntheticEvent, newFilter: StatusMappingKeys) => {
        setFilter(newFilter);
        onFilterChange(statusMappings[newFilter]);
    };

    return (
        <StyledToolbarContainer>
            <Tabs value={filter} onChange={handleFilterChange}>
                {(Object.keys(statusMappings) as StatusMappingKeys[]).map(status => (
                    <Tab key={status} value={status} label={status} />
                ))}
            </Tabs>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <StyledGridToolbarButton>
                    <GridToolbarColumnsButton />
                </StyledGridToolbarButton>
                <StyledGridToolbarButton>
                    <GridToolbarFilterButton />
                </StyledGridToolbarButton>
                <StyledGridToolbarButton>
                    <GridToolbarDensitySelector />
                </StyledGridToolbarButton>
                <StyledGridToolbarButton sx={{ marginRight: 2 }}>
                    <GridToolbarExport />
                </StyledGridToolbarButton>
                <StyledGridToolbarQuickFilter />
            </Box>
        </StyledToolbarContainer>
    );
};
