import colorFns from 'colorFns';
import { SpinnerOverlay } from 'components/Spinner';
import EditIconSVG from 'images/icons/pencil.svg?react';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Card, Column, Copy, Row, Strike } from 'ui';
import { tzMoment, userTimeZone } from 'utils/moment';
import StatusPill from './StatusPill';

const EditIcon = styled(withInteractibleIconStyles(EditIconSVG)).attrs({ viewBox: '1.5 2.5 18 21.5' })`
    height: 21px;
    width: 21px;
`;

const EditCol = styled(Column)``;
const StatusCol = styled(Column)``;
const MeetingStatusPill = styled(StatusPill)`
    display: flex;
    justify-content: flex-end;
`;

const ListItem = styled(Card)`
    margin: 0 !important;
    padding: 12px;
    cursor: pointer;
    background-color: ${colorFns.listItemBackground};

    ${StatusCol} {
        flex: 1;
        align-items: flex-end;
    }
`;

export default function UnschedList({
    meetings,
    loading,
    onClick,
}: {
    meetings: BizlyAPI.Meeting[];
    loading?: boolean;
    onClick: (meeting: BizlyAPI.Meeting) => void;
}) {
    return (
        <Column itemSpacing="default">
            {meetings.map(meeting => {
                const cancelledAtTime =
                    meeting.cancelledAt &&
                    i18n.meetingsPage.cancelledOn(
                        tzMoment(meeting.cancelledAt.date, meeting.cancelledAt.timezone)
                            .tz(userTimeZone)
                            .format('ll LT z')
                    );

                const MeetingName = meeting.name || i18n.homepage.untitled;
                return (
                    <ListItem onClick={() => onClick(meeting)} key={meeting.id}>
                        <Row itemSpacing="default" alignItems="center" justifyContent="space-between">
                            <Column>
                                <Copy>{cancelledAtTime ? <Strike>{MeetingName}</Strike> : MeetingName}</Copy>

                                <Copy small>{[meeting.createdBy.firstName, meeting.createdBy.lastName].join(' ')}</Copy>
                            </Column>
                            <StatusCol>
                                <MeetingStatusPill meeting={meeting} tooltip={cancelledAtTime} />
                            </StatusCol>
                            <EditCol>
                                <EditIcon />
                            </EditCol>
                        </Row>
                    </ListItem>
                );
            })}
            {loading && <SpinnerOverlay transparent clickThrough />}
        </Column>
    );
}
