import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useEventTemplateTags } from 'stores/event-template-tags';
import { i18n } from 'translation';

interface FilterPopperProps {
    anchorEl: HTMLElement | null;
    open: boolean;
    onClose: () => void;
    onApplyFilters: () => void;
    onClear: () => void;
    onTagChange: (tagId: number) => void;
    selectedTags: Set<number>;
}

export const TemplateFilterPopper: React.FC<FilterPopperProps> = ({
    anchorEl,
    open,
    onClose,
    onApplyFilters,
    onClear,
    onTagChange,
    selectedTags,
}) => {
    const { tags, loaded } = useEventTemplateTags();
    const { getColor, EColors } = useTheme();

    if (!loaded) {
        return null;
    }
    return (
        <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
            <ClickAwayListener onClickAway={onClose}>
                <Paper sx={{ p: 2, width: 500 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography sx={{ color: getColor(EColors.darkGrey) }} gutterBottom>
                            {i18n.playbooks.filterByCategories}
                        </Typography>
                    </Box>
                    <Grid container spacing={2} alignItems="center" sx={{ '& .MuiGrid-item': { paddingTop: 0 } }}>
                        {tags?.map(tag => (
                            <Grid item xs={6} key={tag.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedTags.has(tag.id)}
                                            onChange={() => onTagChange(tag.id)}
                                        />
                                    }
                                    label={tag.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onClear} sx={{ marginRight: '1rem', color: getColor(EColors.pureBlack) }}>
                            {i18n.button.reset}
                        </Button>
                        <Button variant="contained" onClick={onApplyFilters}>
                            {i18n.playbooks.apply}
                        </Button>
                    </Box>
                </Paper>
            </ClickAwayListener>
        </Popper>
    );
};
