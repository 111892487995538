import { Tooltip } from '@material-ui/core';
import colorFns from 'colorFns';
import { Spinner } from 'components/Spinner';
import CalendarIconSVG from 'images/icons/calendar.svg?react';
import EditIconSVG from 'images/icons/registration.svg?react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, Row, Strike, TruncatingSingleLineCopy } from 'ui';
import { cancelledAtTime, fullTimestamp, shortTimestamp } from 'utils/date_util';
import { tzMoment, userTimeZone } from 'utils/moment';
import StatusPill from './StatusPill';

const ScrollColumn = styled(Column)`
    overflow-x: scroll;
`;

const EditIcon = styled(EditIconSVG)`
    height: 21px;
    width: 21px;
`;

const CalendarIcon = styled(CalendarIconSVG)`
    width: 22px;
    height: 22px;
`;

const HoverRow = styled(Row)`
    padding: 12px 0;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background-color: ${colorFns.listItemBackground};
    }
`;

const CircleImg = styled.img`
    height: 36px;
    width: 36px;
    border-radius: 50%;
    object-fit: cover;
`;

const CreatedByRow = styled(Row)`
    align-items: center;

    flex: 1 0 180px;
    min-width: 180px;
    max-height: 60px;
`;

const NameCol = styled(Column)`
    flex: 1 0 200px;
    min-width: 200px;
    align-items: flex-start;
    margin-right: 12px;
`;

const FullWidthCol = styled(Column)`
    flex: 1 0 240px;
    position: relative;
`;

const ClockCol = styled(Column)`
    flex: 0 0 16px;
    min-width: 16px;
    align-items: flex-start;
`;
const TimeCol = styled(Column)`
    flex: 1;
`;

const StatusCol = styled(Column)`
    flex: 1 0 180px;
    min-width: 180px;
    align-items: flex-start;
`;

const ActivityCol = styled(Column)`
    position: relative;
    flex: 1 0 110px;
    min-width: 110px;
`;

const ActiveListContainer = styled.div`
    min-height: 360px;
`;

const MeetingName = (meeting: BizlyAPI.Meeting) => (
    <TruncatingSingleLineCopy maxWidth="100%">
        {meeting.name ? (
            meeting.cancelledAt ? (
                <Strike>{meeting.name}</Strike>
            ) : (
                meeting.name
            )
        ) : (
            <i>{i18n.homepage.untitled}</i>
        )}
    </TruncatingSingleLineCopy>
);

export default function ActiveList({
    meetings,
    loading,
    onClick,
}: {
    meetings: BizlyAPI.Meeting[];
    loading?: boolean;
    onClick: (meeting: BizlyAPI.Meeting) => void;
}) {
    const renderFull = (meeting: BizlyAPI.Meeting) => {
        const startsAt = tzMoment(meeting.startsAt, meeting.timeZone).tz(userTimeZone);

        return (
            <>
                <NameCol>{MeetingName(meeting)}</NameCol>
                <FullWidthCol alignItems="flex-start">
                    {meeting.startsAt ? (
                        <Row itemSpacing="small" alignItems="center">
                            <ClockCol>
                                <CalendarIcon />
                            </ClockCol>
                            <TimeCol>
                                <Copy regular>{startsAt.format('LL')}</Copy>
                            </TimeCol>
                        </Row>
                    ) : (
                        <EditIcon />
                    )}
                </FullWidthCol>

                <CreatedByRow itemSpacing="small">
                    <CircleImg src={meeting.createdBy?.imageUrl} />
                    <Copy>{[meeting.createdBy?.firstName, meeting.createdBy?.lastName].join(' ')}</Copy>
                </CreatedByRow>

                <StatusCol>
                    <StatusPill meeting={meeting} tooltip={cancelledAtTime(meeting)} />
                </StatusCol>
                <ActivityCol>
                    <Copy>{i18n.homepage.activity}</Copy>
                    <Tooltip title={fullTimestamp(meeting.updatedAt ?? meeting.createdAt.date)}>
                        <Copy regular>{shortTimestamp(meeting.updatedAt ?? meeting.createdAt.date)}</Copy>
                    </Tooltip>
                </ActivityCol>
            </>
        );
    };

    return (
        <ScrollColumn itemSpacing="small">
            <ActiveListContainer>
                {meetings.length > 0 &&
                    meetings?.map(meeting => (
                        <HoverRow
                            key={meeting.id}
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={() => onClick(meeting)}
                        >
                            {renderFull(meeting)}
                        </HoverRow>
                    ))}
                {loading && <Spinner />}
            </ActiveListContainer>
        </ScrollColumn>
    );
}
