import { Spinner } from 'components/Spinner';
import { Route, Routes } from 'react-router-dom';
import { BorderedContainer } from '../GridContainer';
import ListingContactInfo from './ListingContactInfo';
import ListingEventSpaces from './ListingEventSpaces';
import ListingExperienceAndAmenities from './ListingExperienceAndAmenities';
import ListingFAQs from './ListingFAQs';
import ListingGeneralInfo from './ListingGeneralInfo';
import ListingGuestRooms from './ListingGuestRooms';
import ListingHighlights from './ListingHighlights';
import ListingOtherSpaces from './ListingOtherSpaces';
import ListingPhotos from './ListingPhotos';
import ListingPolicies from './ListingPolicies';
import {
    CONTACT_INFO_PATH,
    EVENT_SPACES_PATH,
    EXPERIENCE_AMENITIES_PATH,
    FAQS_PATH,
    GENERALINFO_PATH,
    GUEST_ROOMS_PATH,
    HIGHLIGHTS_PATH,
    OTHER_SPACES_PATH,
    PHOTOS_PATH,
    POLICIES_PATH,
} from './ListingsSubNavigation';

type ListingBodyProps = {
    listing: Bizly.Venue | undefined;
    isLoading: boolean;
};

function ListingBody({ listing, isLoading }: ListingBodyProps) {
    if (isLoading || !listing) {
        return (
            <BorderedContainer padding={2}>
                <Spinner />
            </BorderedContainer>
        );
    }

    return (
        <Routes>
            <Route path={GENERALINFO_PATH} element={<ListingGeneralInfo listing={listing} />} />
            <Route path={PHOTOS_PATH} element={<ListingPhotos listing={listing} />} />
            <Route path={CONTACT_INFO_PATH} element={<ListingContactInfo />} />
            <Route path={EXPERIENCE_AMENITIES_PATH} element={<ListingExperienceAndAmenities />} />
            <Route path={HIGHLIGHTS_PATH} element={<ListingHighlights />} />
            <Route path={GUEST_ROOMS_PATH} element={<ListingGuestRooms listing={listing} />} />
            <Route path={EVENT_SPACES_PATH} element={<ListingEventSpaces />} />
            <Route path={OTHER_SPACES_PATH} element={<ListingOtherSpaces />} />
            <Route path={FAQS_PATH} element={<ListingFAQs />} />
            <Route path={POLICIES_PATH} element={<ListingPolicies />} />
        </Routes>
    );
}

export default ListingBody;
