import MenuItem from '@material-ui/core/MenuItem';
import { withSnackbar } from 'notistack';
import { Component } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import { loadAttendees, removeAttendee, updateAttendee } from 'api';
import colorFns from 'colorFns';
import AttendeeDrawer from 'components/Attendees/AttendeeDrawer';
import { ButtonMenu } from 'components/ButtonMenu';
import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';
import RightDrawer from 'components/Drawer/RightDrawer';
import { GuestListTable } from 'components/GuestListTable';
import HowToPopper from 'components/HowToPopper/HowToPopper';
import { MaterialTable } from 'components/MaterialTable';
import { Spinner } from 'components/Spinner';
import { Button } from 'components/Ui-V2/Button/Button';
import { PageHeadline } from 'components/ui/Headline';
import TallyDisplay from 'components/ui/TallyDisplay';
import { EventContext } from 'providers/event';
import { contentWidth, statusColors, statusMap } from 'shared';
import { useCurrentInquiry } from 'stores/current-inquiry';
import { i18n } from 'translation';
import { AlignedColumn, Column, Copy, Row, SpacedRow, Spacer, StatusChip } from 'ui';
import { tzMoment } from 'utils/moment';

import { dietaryMap, foodAllergyMap } from 'constants/attendeeTypes';
import { isEqual } from 'lodash';
import * as XLSX from 'xlsx';
import { downloadFromUrl } from '../util';
import { AddAttendees } from './AddAttendees';
import { AddAttendeesImport } from './AddAttendeesImport';
import { PageDescription } from './BuildInquiry';

const BaselineAlignedRow = styled(SpacedRow)`
    align-items: baseline;
`;

const TotalRow = styled(Row)`
    padding-right: 1rem;
    box-sizing: border-box;
`;

const PrimaryLink = styled(Link)`
    color: ${colorFns.primaryAction};
`;

class AttendeesClass extends Component {
    static contextType = EventContext;

    state = {
        attendees: null,
        roomBlocks: null,
        summary: null,
        deleteIntent: {},
        editIntent: null,
        pending: false,
        sort: null,
        drawerOpen: false,
        attendeeDrawerOpen: false,
        selectedAttendeesIds: [],
        isDeleteSelectedAttendees: false,
        filteredAttendeesRows: [],
    };

    updateEventAttendeesCount = attendeesSummary => {
        if (!attendeesSummary) {
            return;
        }
        const { updateAttendeesCount } = this.context;

        const attendeeStatusMap = {
            total: 'total',
            'not sent': 'notSent',
            invited: 'invited',
            attending: 'attending',
            'not attending': 'notAttending',
            tentative: 'tentative',
        };

        const summary = Object.keys(attendeesSummary).reduce(
            (summary, key) => ({ ...summary, [attendeeStatusMap[key]]: attendeesSummary[key] }),
            {}
        );
        this.setState({ summary });

        updateAttendeesCount(summary);
    };

    async refreshAttendees(eventId) {
        const { enqueueSnackbar } = this.props;

        this.setState({
            pending: true,
        });

        try {
            const response = await loadAttendees(eventId);
            this.updateEventAttendeesCount(response.summary);
            this.setState({
                attendees: response.attendees,
                roomBlocks: (response.roomBlock || []).map(roomBlock => ({
                    ...roomBlock,
                    single: roomBlock.single || 0,
                    double: roomBlock.double || 0,
                    suite: roomBlock.suite || 0,
                    handicapAccessible: roomBlock.handicapAccessible || 0,
                })),
                pending: false,
            });
        } catch (e) {
            console.error(e);
            enqueueSnackbar(i18n.error.fetchMeetingData(eventId), {
                variant: 'error',
            });
            this.setState({
                pending: false,
            });
        }
    }

    componentDidMount() {
        const { event } = this.context;
        this.refreshAttendees(event.id);
    }

    handleEditClick(id) {
        const { attendees } = this.state;
        this.setState({
            editIntent: attendees.find(a => a.id === id),
        });
    }

    handleRemoveClick(id) {
        const { attendees } = this.state;
        this.setState({
            deleteIntent: attendees.find(a => a.id === id),
        });
    }

    async handleExportClick() {
        const { attendees, filteredAttendeesRows } = this.state;
        const filteredAttendees = this.getFilteredAttendees(attendees, filteredAttendeesRows);

        if (filteredAttendees.length > 0) {
            const dataToExport = filteredAttendees.map(attendee => ({
                'First Name': attendee.firstName,
                'Last Name': attendee.lastName,
                'Email Address': attendee.email,
                'Phone Number': attendee.phone,
                ID: attendee.externalId,
                'RSVP Status': attendee.status,
                'Check-In': attendee.checkInDate ? tzMoment(attendee.checkInDate).format('MM/DD/YYYY') : '',
                'Check-Out': attendee.checkOutDate ? tzMoment(attendee.checkOutDate).format('MM/DD/YYYY') : '',
                'Preferred Room Type': attendee.preferredRoomType,
                'Dietary Restrictions': this.getCombinedDietaryFoodAllergies(attendee).join(', '),
            }));

            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendees');

            const columnWidths = [20, 20, 20, 20, 20, 20, 20, 20, 30, 40];
            worksheet['!cols'] = columnWidths.map(width => ({ wch: width }));

            XLSX.writeFile(workbook, 'guest_list.xlsx');
        } else {
            this.props.enqueueSnackbar(i18n.guests.error.noAttendeesFound, { variant: 'warning' });
        }
    }

    getFilteredAttendees(attendees, filteredAttendeesRows) {
        const filteredAttendeeIds = filteredAttendeesRows.map(row => row.id);
        return attendees.filter(a => filteredAttendeeIds.includes(a.id));
    }

    getCombinedDietaryFoodAllergies(attendee) {
        return [
            ...(Array.isArray(attendee.dietaryRestrictions) ? attendee.dietaryRestrictions : []).map(
                id => dietaryMap[id]
            ),
            ...(Array.isArray(attendee.foodAllergies) ? attendee.foodAllergies : []).map(id => foodAllergyMap[id]),
        ];
    }

    handleModalDismiss() {
        this.setState({
            deleteIntent: {},
            isDeleteSelectedAttendees: false,
        });
    }

    handleSelectAttendees(attendees) {
        this.setState({
            selectedAttendeesIds: attendees,
        });
    }

    handleConfirmDeleteSelectedAttendees() {
        this.setState({
            isDeleteSelectedAttendees: true,
        });
    }

    async handleDeleteSelectedAttendees() {
        const { enqueueSnackbar } = this.props;
        const { event } = this.context;
        const { attendees, selectedAttendeesIds } = this.state;
        this.setState({
            pending: true,
        });

        const attendeesToDelete = attendees.filter(a => selectedAttendeesIds.includes(a.id));
        const attendeesCount = attendeesToDelete.length;

        try {
            await Promise.all(attendeesToDelete.map(a => removeAttendee(event.id, a)));

            if (attendeesCount > 2) {
                enqueueSnackbar(i18n.guests.removeConfirmation(`${attendeesCount} attendees`), {
                    variant: 'info',
                });
            } else {
                const emails = attendeesToDelete.map(a => a.email).join(', ');
                enqueueSnackbar(i18n.guests.removeConfirmation(emails), {
                    variant: 'info',
                });
            }
            this.handleDismissEdit();
        } catch (e) {
            console.error(e);
            enqueueSnackbar(i18n.guests.error.removeAttendees(event.id), {
                variant: 'error',
            });
        }
        this.setState({
            deleteIntent: {},
            pending: false,
            isDeleteSelectedAttendees: false,
        });
        this.refreshAttendees(event.id);
    }

    async handleDeleteConfirm() {
        const { enqueueSnackbar } = this.props;
        const { event } = this.context;
        const { deleteIntent } = this.state;
        this.setState({
            pending: true,
        });
        try {
            await removeAttendee(event.id, deleteIntent);
            enqueueSnackbar(i18n.guests.removeConfirmation(deleteIntent.email), {
                variant: 'info',
            });
            this.handleDismissEdit();
        } catch (e) {
            console.error(e);
            enqueueSnackbar(i18n.guests.error.removeAttendee(deleteIntent.id, event.id), {
                variant: 'error',
            });
        }
        this.setState({
            deleteIntent: {},
            pending: false,
        });
        this.refreshAttendees(event.id);
    }

    handleDismissEdit() {
        this.setState({
            editIntent: null,
            deleteIntent: {},
        });
    }

    async handleAttendeeSave(draft) {
        const { enqueueSnackbar } = this.props;
        const { event } = this.context;
        try {
            await updateAttendee(event.id, draft);
            await this.refreshAttendees(event.id);
            enqueueSnackbar(i18n.guests.updateConfirmation(draft.email), { variant: 'info' });
            this.setState({
                editIntent: null,
            });
        } catch (e) {
            enqueueSnackbar(i18n.guests.error.updateAttendee(draft.email), {
                variant: 'error',
            });
        }
    }

    handleSortChange(newValue) {
        this.setState({
            sort: newValue,
        });
    }

    handleTallyClick(label) {
        if (label === 'roomBlocks') {
            this.setState({ drawerOpen: true });
        }
    }

    exportCsv() {
        const { event } = this.context;
        if (event.roomBlockExportUrl) {
            downloadFromUrl(event.roomBlockExportUrl);
        }
    }

    isDeleteModalActive() {
        return this.state.isDeleteSelectedAttendees || this.state.deleteIntent.status;
    }

    handleDeleteAttendees() {
        if (this.state.isDeleteSelectedAttendees) {
            this.handleDeleteSelectedAttendees();
        } else {
            this.handleDeleteConfirm();
        }
    }

    handlePrompt() {
        if (this.state.isDeleteSelectedAttendees) {
            return i18n.guests.removeMultipleGuests;
        } else {
            return this.state.deleteIntent.status === 'attending'
                ? i18n.guests.removeAttendingGuestPrompt
                : i18n.guests.removeNotAttendingGuestPrompt;
        }
    }

    handleSetFilteredAttendeesRows = rows => {
        if (!isEqual(this.state.filteredAttendeesRows, rows)) {
            this.setState({ filteredAttendeesRows: rows });
        }
    };

    render() {
        const { event } = this.context;
        const { attendees, editIntent, pending, sort, summary, roomBlocks } = this.state;
        const { loaded, inquiry } = useCurrentInquiry.getState();
        const inquiryId = loaded ? String(inquiry?.id || 'new') : '';
        if (pending || !attendees) {
            return <Spinner />;
        }

        const attendeeRows = attendees
            .filter(a => !sort || sort === 'all' || a.status === sort)
            .map(a => ({
                id: a.id,
                name: a.firstName && a.lastName ? `${a.firstName} ${a.lastName}` : '',
                email: a.email,
                phone: a.phone,
                status: (
                    <StatusChip status={a.status} label={statusMap[a.status]} statusColor={statusColors[a.status]} />
                ),
            }));

        return (
            <>
                <Routes>
                    <Route
                        path={`add/by-email`}
                        element={
                            <AddAttendees
                                attendees={attendees}
                                setAttendees={attendees => {
                                    this.setState({ attendees });
                                    this.refreshAttendees(event.id);
                                    this.updateEventAttendeesCount(attendees);
                                }}
                                event={event}
                            />
                        }
                    />
                    <Route
                        path={`add/by-import`}
                        element={
                            <AddAttendeesImport
                                setAttendees={attendees => {
                                    this.setState({ attendees });
                                    this.refreshAttendees(event.id);
                                    this.updateEventAttendeesCount(attendees);
                                }}
                            />
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <AlignedColumn style={{ width: '100%' }}>
                                <DeleteConfirmationModal
                                    isActive={this.isDeleteModalActive()}
                                    onDismiss={() => this.handleModalDismiss()}
                                    onProceed={() => this.handleDeleteAttendees()}
                                    prompt={this.handlePrompt()}
                                />
                                <BaselineAlignedRow>
                                    <Column>
                                        <Row alignItems="center">
                                            <PageHeadline withDescription>
                                                {i18n.meetingDashboard.guestListSection.guestList}
                                            </PageHeadline>
                                            <HowToPopper sectionId="guest-lists" />
                                        </Row>
                                        <div style={{ width: contentWidth }}>
                                            <PageDescription large>{i18n.guests.subHeading}</PageDescription>
                                        </div>
                                    </Column>
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <Button
                                            onClick={() => this.handleExportClick()}
                                            style={{ marginRight: '16px' }}
                                            variant="outlined"
                                        >
                                            {i18n.guests.exportList}
                                        </Button>
                                        {event.editable && (
                                            <ButtonMenu label={i18n.guests.addPeople}>
                                                <Link to={`/event/${event.id}/guest-list/add/by-email`}>
                                                    <MenuItem>{i18n.guests.addByEmail}</MenuItem>
                                                </Link>
                                                <Link to={`/event/${event.id}/guest-list/add/by-import`}>
                                                    <MenuItem>{i18n.guests.importList}</MenuItem>
                                                </Link>
                                            </ButtonMenu>
                                        )}
                                    </Row>
                                </BaselineAlignedRow>
                                <Spacer largest />
                                <TallyDisplay
                                    flex
                                    tallies={[
                                        { label: 'total', tally: summary.total },
                                        { label: 'invited', tally: summary.invited },
                                        { label: 'not attending', tally: summary.notAttending },
                                        { label: 'attending', tally: summary.attending },
                                        {
                                            label: 'roomBlocks',
                                            tally: roomBlocks
                                                ? roomBlocks.reduce((total, roomBlock) => total + roomBlock.total, 0)
                                                : 0,
                                            onClick: label => this.handleTallyClick(label),
                                            description: `(${i18n.guests.clickable})`,
                                        },
                                    ]}
                                    itemSize={'auto'}
                                />
                                <Spacer />
                                <Spacer />
                                <GuestListTable
                                    onMultiDeleteAttendeesClick={() => this.handleConfirmDeleteSelectedAttendees()}
                                    onEditClick={id => this.handleEditClick(id)}
                                    handleSelectAttendees={ids => this.handleSelectAttendees(ids)}
                                    rows={attendeeRows}
                                    setFilteredAttendeesRows={rows => this.handleSetFilteredAttendeesRows(rows)}
                                />
                                <RightDrawer
                                    drawerOpen={this.state.drawerOpen}
                                    onClose={() => this.setState({ drawerOpen: false })}
                                    title={i18n.meetingDashboard.roomBlockSection.headline}
                                    footer={
                                        <Row itemSpacing="smallish">
                                            <Button onClick={() => this.exportCsv()}>
                                                {i18n.meetingDashboard.roomBlockSection.exportCsv}
                                            </Button>
                                        </Row>
                                    }
                                >
                                    <MaterialTable
                                        headers={[
                                            { id: 'night', label: i18n.datetime.date },
                                            {
                                                id: 'single',
                                                label: i18n.meetingDashboard.roomBlockSection.single,
                                                align: 'center',
                                            },
                                            {
                                                id: 'double',
                                                label: i18n.meetingDashboard.roomBlockSection.double,
                                                align: 'center',
                                            },
                                            {
                                                id: 'suite',
                                                label: i18n.meetingDashboard.roomBlockSection.suite,
                                                align: 'center',
                                            },
                                            {
                                                id: 'handicapAccessible',
                                                label: i18n.meetingDashboard.roomBlockSection.handicapAccessible,
                                                align: 'center',
                                            },
                                            { id: 'total', label: i18n.guests.total, align: 'right' },
                                        ]}
                                        rows={roomBlocks.map(roomBlock => ({
                                            id: roomBlock.night,
                                            data: {
                                                ...roomBlock,
                                                night: tzMoment(roomBlock.night).format('ll'),
                                            },
                                        }))}
                                    />
                                    <Spacer small />
                                    <TotalRow justifyContent="flex-end">
                                        <Copy>{i18n.guests.total}</Copy>
                                        <Spacer col largest />
                                        <Copy>
                                            {(roomBlocks || []).reduce(
                                                (total, roomBlock) => total + roomBlock.total,
                                                0
                                            ) || '0'}
                                        </Copy>
                                    </TotalRow>
                                    <Spacer large />
                                    <Row>
                                        <span>
                                            {i18n.meetingDashboard.roomBlockSection.importNote}
                                            <PrimaryLink to={`/event/${event.id}/venue/inquiries/${inquiryId}`}>
                                                {i18n.meetingDashboard.roomBlockSection.inquiryBuilderPage}
                                            </PrimaryLink>
                                            .
                                        </span>
                                    </Row>
                                </RightDrawer>
                                {editIntent && (
                                    <AttendeeDrawer
                                        editable={event.editable}
                                        attendee={editIntent}
                                        eventId={event.id}
                                        onDismiss={() => this.handleDismissEdit()}
                                        onSave={draft => this.handleAttendeeSave(draft)}
                                        onRemove={id => this.handleRemoveClick(id)}
                                    />
                                )}
                            </AlignedColumn>
                        }
                    />
                </Routes>
            </>
        );
    }
}

export const Attendees = withSnackbar(AttendeesClass);
