import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import MessageIcon from '@mui/icons-material/Message';
import SettingsIcon from '@mui/icons-material/Settings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VillaIcon from '@mui/icons-material/Villa';
import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { ElementType, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { i18n } from 'translation';

export const SIDE_NAV_WIDTH = 200;
export const SIDE_NAV_COLLAPSED_WIDTH = 68;
export const HEADER_HEIGHT = 60;

const Side = styled(Box)<{ isCollapsed: boolean }>(({ theme: { getColor, EColors }, isCollapsed }) => ({
    width: isCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH,
    minWidth: isCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH,
    boxSizing: 'border-box',
    backgroundColor: getColor(EColors.drWhite),
    zIndex: 200,
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 0.3s ease, min-width 0.3s ease',
}));

const SideBarContainer = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
});

const SideBarLinks = styled(Box)({
    padding: '1.25rem 0.9375rem',
});

const SidebarLink = styled('div')<{ active?: boolean }>(({ theme: { getColor, EColors }, active }) => ({
    color: getColor(EColors.pureBlack),
    cursor: 'pointer',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: active ? alpha(getColor(EColors.bizlyOSPrimary), 0.2) : 'transparent',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    '&:hover': {
        backgroundColor: active
            ? alpha(getColor(EColors.bizlyOSPrimary), 0.2)
            : alpha(getColor(EColors.bizlyOSPrimary), 0.1),
    },
}));

const SettingsLink = styled(SideBarLinks)({
    marginTop: 'auto',
});

const LinkContent = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const IconContainer = styled(Box)<{ isCollapsed: boolean }>(() => ({
    height: '1.25rem',
    width: '1.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '1rem',
}));

const NavTitle = styled('span')<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
    opacity: isCollapsed ? 0 : 1,
    transition: 'opacity 0.3s ease',
    fontSize: '.875rem',
}));

const SoonPill = styled('span')(({ theme: { getColor, EColors } }) => ({
    backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.2),
    color: getColor(EColors.pureBlack),
    fontSize: '0.75rem',
    padding: '0.125rem 0.5rem',
    borderRadius: '1rem',
}));

const HOME_PATH = '/os';
const OS_PATH = '/os';
const OS_INQUIRIES_PATH = `${OS_PATH}/inquiries`;
const OS_LISTINGS_PATH = `${OS_PATH}/listings`;
const OS_MESSAGES_PATH = `${OS_PATH}/messages`;
const OS_CALENDAR_PATH = `${OS_PATH}/calendar`;
const OS_ANALYTICS_PATH = `${OS_PATH}/analytics`;
const OS_SETTINGS_PATH = `${OS_PATH}/settings`;

const PATHS: ReadonlyArray<RouteType> = [
    {
        matches: [/^\/$/, /^\/os$/],
        path: HOME_PATH,
        icon: HomeIcon,
        title: i18n.proposals.home,
        featureFlag: 'toggleHomeComponent',
    },
    {
        matches: [/^\/os\/inquiries/],
        path: OS_INQUIRIES_PATH,
        icon: TrendingUpIcon,
        title: i18n.proposals.inquiries,
    },
    {
        matches: [/^\/os\/listings/],
        path: OS_LISTINGS_PATH,
        icon: VillaIcon,
        title: i18n.proposals.listings,
    },
    {
        matches: [/^\/os\/messages/],
        path: OS_MESSAGES_PATH,
        icon: MessageIcon,
        title: i18n.proposals.messages,
    },
    {
        matches: [/^\/os\/calendar/],
        path: OS_CALENDAR_PATH,
        icon: CalendarMonthIcon,
        title: i18n.proposals.calender,
    },
    {
        matches: [/^\/os\/analytics/],
        path: OS_ANALYTICS_PATH,
        icon: AssessmentIcon,
        title: i18n.proposals.anayltics,
    },
] as const;

const SETTINGS_PATH = {
    matches: [/^\/os\/settings/],
    path: OS_SETTINGS_PATH,
    icon: SettingsIcon,
    title: i18n.proposals.settings,
} as const;

type RouteType = {
    matches: RegExp[];
    path: string;
    icon: ElementType;
    title: string;
    featureFlag?: string;
};

interface RouteButtonProps {
    route: RouteType;
    isActive: boolean;
    isCollapsed: boolean;
}

const RouteButton = ({ route, isActive, isCollapsed }: RouteButtonProps) => {
    const isFeatureEnabled = useFeatureFlagEnabled(route.featureFlag || '');

    if (route.featureFlag && isFeatureEnabled === false) {
        return null;
    }

    return (
        <Link to={route.path}>
            <SidebarLink active={isActive}>
                <LinkContent>
                    <IconContainer isCollapsed={isCollapsed}>
                        <route.icon />
                    </IconContainer>
                    <NavTitle isCollapsed={isCollapsed}>{route.title}</NavTitle>
                </LinkContent>
                {(route.path === '/os/messages' || route.path === '/os/calendar' || route.path === '/os/analytics') &&
                    !isCollapsed && <SoonPill>Soon</SoonPill>}
            </SidebarLink>
        </Link>
    );
};

export const BizlyOSSideNav = ({
    children,
    isCollapsed,
}: Readonly<{
    children?: React.ReactNode;
    isCollapsed: boolean;
}>) => {
    const location = useLocation();
    const canViewSettingsFlag = useFeatureFlagEnabled('toggleSettings');

    const highlightedPath = useMemo(() => {
        const match = PATHS.find(path => path.matches.some(pattern => RegExp(pattern).exec(location.pathname)));
        return match?.path;
    }, [location.pathname]);

    return (
        <>
            <Side isCollapsed={isCollapsed}>
                <SideBarContainer>
                    <SideBarLinks>
                        <Box display="flex" flexDirection="column" gap={1.25} sx={{ width: '100%' }}>
                            {PATHS.map(route => (
                                <RouteButton
                                    key={route.path}
                                    route={route}
                                    isActive={highlightedPath === route.path}
                                    isCollapsed={isCollapsed}
                                />
                            ))}
                        </Box>
                    </SideBarLinks>
                    {canViewSettingsFlag && (
                        <SettingsLink>
                            <Link to={SETTINGS_PATH.path}>
                                <SidebarLink active={location.pathname.startsWith(SETTINGS_PATH.path)}>
                                    <LinkContent>
                                        <IconContainer isCollapsed={isCollapsed}>
                                            <SETTINGS_PATH.icon />
                                        </IconContainer>
                                        <NavTitle isCollapsed={isCollapsed}>{SETTINGS_PATH.title}</NavTitle>
                                    </LinkContent>
                                </SidebarLink>
                            </Link>
                        </SettingsLink>
                    )}
                </SideBarContainer>
            </Side>

            {children && (
                <Box
                    sx={{
                        marginLeft: isCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH,
                        transition: 'margin-left 0.3s ease',
                    }}
                >
                    {children}
                </Box>
            )}
        </>
    );
};
