import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { RichTextCopy, Spacer } from '../../ui';
import Button from '../ui/Button';
import { Headline as UIHeadline } from '../ui/Headline';
import StepsRow from './StepsRow';
import VPaymentModal from './VPaymentModal';

const Headline = styled(UIHeadline)`
    margin-top: 0;
    text-transform: none;
`;

const Copy = styled(RichTextCopy)`
    font-size: 15px;
    line-height: 1.47;

    ul {
        padding-inline-start: 1.2em;
    }
`;

const GreyBox = styled.div`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 8px;
`;

type VirtualPaymentPromptTypes = {
    booking: Bizly.Booking;
    paymentCardRules: Bizly.PaymentCardRules;
    openModal: () => void;
};

const DEFAULT_COPY = {
    PROMPT: i18n.venue.proposal.payment.prompt,
    MIN_SPEND: i18n.venue.proposal.payment.minSpend,
    MAX_SPEND: i18n.venue.proposal.payment.maxSpend,
    REQUEST_SIGNED_DOC: i18n.venue.proposal.payment.requestSignedDoc,
    REQUEST_PAYMENT: i18n.venue.proposal.payment.requestPayment,
};

const VirtualPaymentsPrompt = ({ booking, paymentCardRules, openModal }: VirtualPaymentPromptTypes) => {
    const { minSpend, maxSpend } = paymentCardRules;
    const { minSpendCopy, maxSpendCopy } = paymentCardRules;
    const estimatedTotal = booking.estimates.total;
    const hasContractAndEstimates = booking.contractUrl && estimatedTotal;
    const estimatedTotalNum = typeof estimatedTotal === 'string' ? parseFloat(estimatedTotal) : estimatedTotal ?? 0;
    const isLessThanMinSpend = estimatedTotalNum < minSpend && (minSpendCopy || DEFAULT_COPY.MIN_SPEND);
    const isGreaterThanMaxSpend = estimatedTotalNum > maxSpend && (maxSpendCopy || DEFAULT_COPY.MAX_SPEND);
    const estimateOutOfRange = isLessThanMinSpend || isGreaterThanMaxSpend;
    const canRequestVirtualPayment = hasContractAndEstimates && !estimateOutOfRange;
    const requestError =
        canRequestVirtualPayment || booking.paymentCard
            ? undefined
            : hasContractAndEstimates
            ? estimateOutOfRange
            : DEFAULT_COPY.REQUEST_SIGNED_DOC;

    return (
        <GreyBox>
            <Headline>{i18n.venue.proposal.payment.vPayment}</Headline>
            {requestError ? (
                <Copy>{requestError}</Copy>
            ) : (
                <>
                    {!booking.paymentCard && (
                        <>
                            <Copy>{DEFAULT_COPY.REQUEST_PAYMENT}</Copy>
                            <Spacer small />
                        </>
                    )}
                    <Button onClick={openModal}>
                        {booking.paymentCard ? i18n.button.viewCard : i18n.button.request}
                    </Button>
                </>
            )}
        </GreyBox>
    );
};

type PaymentDetailsProps = {
    number: number;
    venue: Bizly.VenueProposal;
    copy: string;
    paymentCardRules?: Bizly.PaymentCardRules;
    enableVirtualPayment: boolean;
    updatePaymentCard: (paymentCard: Bizly.PaymentCard) => void;
};

const PaymentDetails = forwardRef(
    (
        {
            number,
            venue,
            copy = DEFAULT_COPY.PROMPT,
            paymentCardRules,
            enableVirtualPayment,
            updatePaymentCard,
        }: PaymentDetailsProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const [showModal, setShowModal] = React.useState(false);

        return (
            <StepsRow number={number} headline={i18n.venue.proposal.payment.headline} ref={ref}>
                <Copy dangerouslySetInnerHTML={{ __html: copy }} />
                <Spacer small />
                {enableVirtualPayment && showModal && paymentCardRules && venue.booking && (
                    <VPaymentModal
                        paymentCardRules={paymentCardRules}
                        paymentCard={venue.booking.paymentCard}
                        estimates={venue.booking.estimates}
                        booking={venue.booking}
                        updatePaymentCard={updatePaymentCard}
                        onClose={() => setShowModal(false)}
                    />
                )}
                {enableVirtualPayment && paymentCardRules && venue.booking && (
                    <VirtualPaymentsPrompt
                        paymentCardRules={paymentCardRules}
                        booking={venue.booking}
                        openModal={() => setShowModal(true)}
                    />
                )}
            </StepsRow>
        );
    }
);

export default PaymentDetails;
