import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme: { getColor, EColors } }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: getColor(EColors.lighterGrey),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));

const Value = styled('span')(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.black),
}));

const Separator = styled('span')(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.black, 0.2),
    margin: '0 0.5em',
}));

const Total = styled('span')(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.black, 0.5),
}));

export const LinearProgressWithLabel = ({ value, totalSteps }: { value: number; totalSteps: number }) => {
    return (
        <Box>
            <Typography variant="body2">
                <Value variant="body2">{value}</Value>
                <Separator>/</Separator>
                <Total>{totalSteps}</Total>
            </Typography>
            <Box sx={{ width: '100%', mt: 1 }}>
                <BorderLinearProgress variant="determinate" value={Math.round((value / totalSteps) * 100)} />
            </Box>
        </Box>
    );
};
