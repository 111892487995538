import Communication from 'pages/Communication/Communication';

export default function PlaybookParcels({
    playbookId,
    asWizardModule = false,
    showCreateButton,
}: {
    playbookId: number | string;
    asWizardModule?: boolean;
    showCreateButton?: boolean;
}) {
    return (
        <Communication
            playbookId={playbookId}
            readonly
            asWizardModule={asWizardModule}
            showCreateButton={showCreateButton}
            hiddenColumns={['sendDate', 'actions']}
        />
    );
}
