import { Divider } from '@material-ui/core';
import Form, { SingleFieldForm } from 'components/Form';
import AccommodationsDetails, { ClearTextButton } from 'components/Planner/AccommodationsDetails';
import EventSpaceDetails from 'components/Planner/EventSpaceDetails';
import PlannerAccordionSummary from 'components/Planner/PlannerAccordionSummary';
import ScheduleDetails from 'components/Planner/ScheduleDetails';
import VirtualMeetingDetails from 'components/Planner/VirtualMeetingDetails';
import usePlanner from 'components/Planner/hooks/usePlanner';
import useVirtualMeeting from 'components/Planner/hooks/useVirtualMeeting';
import { accommodationStartEndDateFields, accommodationStartEndDateSchema } from 'components/Planner/plannerFormSchema';
import {
    TAccommodation,
    TEventSpace,
    TPlannerAccommodationDates,
    TPlannerAccommodationNights,
    TPlannerDataChange,
    TPlannerForm,
    TPlannerSections,
} from 'components/Planner/types';
import {
    ACCOMMODATIONS,
    EVENT_SPACES,
    SCHEDULE,
    VIRTUAL,
    addDayToAccommodations,
    formatAccommodationsSummary,
    formatEventSpacesSummary,
    formatScheduleSummary,
    formatVirtualMeetingsSummary,
    generateDateRange,
    generateNightsFromAccommodationNights,
    generateNumbers,
    mergeDates,
} from 'components/Planner/utils';
import { SpinnerOverlay } from 'components/Spinner';
import Accordion from 'components/ui/Accordion';
import { isEqual as isDateEqual } from 'date-fns';
import map from 'lodash/map';
import { useSnackbar } from 'notistack';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadAttendees, useAttendees } from 'stores/attendees';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Button, Copy, Spacer } from 'ui';
import { getDateRange } from 'utils/date_util';
import { tzMoment, userTimeZone } from 'utils/moment';
import { Column, H5Headline, Row, Subheadline } from './styles';
import { getAccommodationValidationError, getEventSpaceValidationError, getPlannerValidationError } from './utils';

const defaultData = {
    schedule: [{}],
    eventSpaces: [],
    accommodations: [],
    virtualMeeting: null,
};

enum SavingSection {
    EventSpaces = 'event-spaces',
    Accommodation = 'accommodation',
}

type TPlanner = {
    asInquiryModule?: boolean;
    disabled?: boolean;
    onLoad?: (planner: BizlyAPI.Planner) => void;
    onHasSaved?: (hasSaved: boolean) => void;
    queuedSave?: boolean;
    onQueuedSave?: (updatedPlanner: BizlyAPI.Planner) => void;
    onSaveFailed?: () => void; // Callback for validation failure
    options?: BizlyAPI.Complete.PlaybookOptions;
    playbookId?: number | string;
    readonly?: boolean;
};

export default function Planner({
    asInquiryModule,
    disabled,
    onLoad,
    onHasSaved,
    queuedSave,
    onQueuedSave,
    onSaveFailed,
    options,
    playbookId,
    readonly,
}: TPlanner) {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<TPlannerForm>({ ...defaultData });
    const [accommodationDates, setAccommodationDates] = useState<TPlannerAccommodationDates>({});
    const [accommodationNights, setAccommodationNights] = useState<TPlannerAccommodationNights>({
        value: 0,
        hasUpdated: false,
    });
    const [snackbarKey, setSnackbarKey] = React.useState<string | number | undefined>();
    const [hasSaved, setHasSaved] = useState<boolean>(true);

    const [savingSection, setSavingSection] = useState<SavingSection | null>(null);

    const { user, toggles } = useUser();
    const { event, refreshEvent } = useEvent();
    const { roomBlocks } = useAttendees();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { onSave, onDelete } = usePlanner({
        playbookId,
        event,
        loading,
        setLoading,
        onLoad,
        onQueuedSave,
        refreshEvent,
        enqueueSnackbar,
        accommodationDates,
        data,
        setData,
        setAccommodationDates,
        setHasSaved,
        defaultData,
    });
    const { setVirtualMeetingsData, addVirtualMeeting, onVirtualMeetingSave, onVirtualMeetingRemove } =
        useVirtualMeeting({
            data,
            setSnackbarKey,
            enqueueSnackbar,
            setLoading,
            event,
            setData,
            refreshEvent,
        });

    useEffect(
        () => () => {
            if (snackbarKey) closeSnackbar(snackbarKey);
        },
        [snackbarKey, closeSnackbar]
    );

    const editable = (event.editable || !!playbookId) && !disabled;

    React.useEffect(() => {
        onHasSaved?.(hasSaved);
    }, [hasSaved, onHasSaved]);

    // Set the accommodation dates on load
    const loadPlannerDates = (plannerAccommodations: TAccommodation[]) => {
        const startDate = tzMoment(plannerAccommodations.at(0)?.date).format('ll') || undefined;
        // Adds one day to the end date to handle check out
        const endDate = plannerAccommodations.at(-1)?.date
            ? tzMoment(plannerAccommodations.at(-1)?.date).add(1, 'days').format('ll')
            : undefined;

        setAccommodationDates({
            startDate,
            endDate,
            hasUpdated: false,
        });
    };

    useEffect(() => {
        if (data?.accommodations?.length && data.accommodations.every(a => a.date !== null)) {
            loadPlannerDates(data?.accommodations?.length ? data.accommodations : []);
        } else {
            setAccommodationNights({
                value: data?.accommodations?.length ? data.accommodations.length : 0,
                hasUpdated: false,
            });
        }
    }, [data.accommodations]);

    useEffect(() => {
        if (accommodationDates.hasUpdated && accommodationDates.startDate && accommodationDates.endDate) {
            const generatedAccommodations = generateDateRange(accommodationDates.startDate, accommodationDates.endDate);
            let accommodations: TAccommodation[] = generatedAccommodations;

            if (data.accommodations?.length) {
                accommodations = mergeDates(data.accommodations, generatedAccommodations);
            }

            setHasSaved(false);
            setData((prevStagedData: TPlannerForm) => ({ ...prevStagedData, accommodations }));
        }
    }, [accommodationDates, accommodationNights.value, data.accommodations]);

    // To create the accommodations from the nights
    useEffect(() => {
        if (accommodationNights.hasUpdated && playbookId) {
            const nights = generateNightsFromAccommodationNights(accommodationNights.value);
            const accommodations = addDayToAccommodations(nights);

            setHasSaved(false);
            setData((prevStagedData: TPlannerForm) => ({ ...prevStagedData, accommodations }));
        }
    }, [accommodationNights, playbookId]);

    const location = useLocation();
    const hasEventSpace = useMemo(() => data.eventSpaces?.some(space => space.date) ?? false, [data.eventSpaces]);
    const [expanded, setExpanded] = useState<string>('init');

    useEffect(() => {
        if (expanded === 'init' && !loading) {
            if (!hasEventSpace) {
                setExpanded(EVENT_SPACES);
            } else {
                setExpanded(location.state?.expanded);
            }
        }
    }, [location.state, hasEventSpace, loading]);

    // This will keep the virtualMeeting data fresh on every refreshEvent call
    useEffect(() => {
        setData(prevData => ({ ...prevData, virtualMeeting: event.virtualMeeting }));
    }, [event.virtualMeeting]);

    function handleAccordionChange(identity: string) {
        setExpanded(prev => (prev === identity ? '' : identity));
    }

    const scheduleSummary = useMemo(() => formatScheduleSummary(data.schedule), [data.schedule]);
    const eventSpaceSummary = useMemo(() => formatEventSpacesSummary(data.eventSpaces), [data.eventSpaces]);
    const accommodationsSummary = useMemo(
        () => formatAccommodationsSummary(data.accommodations),
        [data.accommodations]
    );
    const virtualMeetingSummary = useMemo(
        () => formatVirtualMeetingsSummary(data.virtualMeeting?.serviceProvider),
        [data.virtualMeeting]
    );
    const MAX_NUMBER_OF_ACCOMMODATION_NIGHTS = 101;
    const nightlyAccommodations = useMemo(() => generateNumbers(MAX_NUMBER_OF_ACCOMMODATION_NIGHTS), []);
    const accommodationsFields = useMemo(
        () => accommodationStartEndDateFields(accommodationDates.startDate),
        [accommodationDates.startDate]
    );

    const SINGLE_DATE_FORMAT = 'll';
    const START_RANGE_FORMAT = 'MMM DD, YYYY';
    const END_RANGE_FORMAT = 'MMM DD, YYYY';

    const [startDate, endDate] = getDateRange(map([...((data.eventSpaces as string[]) ?? [])], 'date'));

    const dateDisplay =
        startDate &&
        endDate &&
        (isDateEqual(startDate, endDate)
            ? tzMoment(startDate).format(SINGLE_DATE_FORMAT)
            : `${tzMoment(startDate).format(START_RANGE_FORMAT)} - ${tzMoment(endDate).format(END_RANGE_FORMAT)}`);

    const isValidStartAndEndTime = ({
        date,
        startTime,
        endTime,
    }: Partial<{
        date: string;
        startTime: string;
        endTime: string;
    }>) => {
        if (!date || !startTime || !endTime) return true;

        const TIME_SEPERATOR = 'T';
        const startDateTime = tzMoment(`${date}${TIME_SEPERATOR}${startTime}`);
        const endDateTime = tzMoment(`${date}${TIME_SEPERATOR}${endTime}`);

        if (endDateTime.isSameOrBefore(startDateTime)) {
            const ERROR_MESSAGE = i18n.venue.planner.invalidTimeRange;
            enqueueSnackbar(ERROR_MESSAGE, { variant: 'error' });
            return false;
        }

        return true;
    };

    function addEntry(targetSection: TPlannerSections, initialValues?: TEventSpace) {
        const currentData: TEventSpace[] | TAccommodation[] = data[targetSection] || [];
        const updatedSection = currentData.slice();
        const newEntry =
            initialValues ||
            (targetSection === EVENT_SPACES
                ? { spaceName: i18n.common.meetingSpaceIndex(currentData.length + 1) }
                : {});

        updatedSection.push(newEntry);

        setHasSaved(false);
        setData((prevData: TPlannerForm) => ({
            ...prevData,
            [targetSection]: updatedSection,
        }));
    }

    function updateEntry(updatedData: TPlannerDataChange, idx: number, targetSection: TPlannerSections) {
        const updatedSection = (data[targetSection] || []).slice() as TEventSpace[];
        updatedSection[idx] = updatedData.value;

        if (!isValidStartAndEndTime(updatedSection[idx])) {
            updatedSection[idx]?.endTime && delete updatedSection[idx]?.endTime;
        }

        setHasSaved(false);
        setData((prevData: TPlannerForm) => ({
            ...prevData,
            [targetSection]: updatedSection,
        }));
    }

    function removeEntry(idx: number, targetSection: TPlannerSections) {
        const updatedSection = (data[targetSection] || []).slice();
        updatedSection[idx] = { ...updatedSection[idx], delete: true };

        setHasSaved(false);
        setData((prevData: TPlannerForm) => ({
            ...prevData,
            [targetSection]: updatedSection,
        }));
    }

    function addScheduleDay(place: 'before' | 'after') {
        const updatedSched = (data.schedule || [{}]).slice();

        if (place === 'before') {
            updatedSched.unshift({});
        } else if (place === 'after') {
            updatedSched.push({});
        }

        setHasSaved(false);
        setData((prevData: TPlannerForm) => ({
            ...prevData,
            schedule: updatedSched,
        }));
    }

    const clearAccommodationsData = () => {
        const updatedAccommodations = (data.accommodations || [{}]).slice().map(accommodations => {
            return {
                ...accommodations,
                // Need the delete key in order to delete from API
                delete: true,
            };
        });

        onDelete({ accommodations: updatedAccommodations });

        setAccommodationDates((prevDates: TPlannerAccommodationDates) => ({
            ...prevDates,
            hasUpdated: true,
            startDate: undefined,
            endDate: undefined,
        }));
        setAccommodationNights({
            value: 0,
            hasUpdated: true,
        });
    };

    const handleSave = useCallback(
        (validation?: SavingSection) => {
            const { eventSpaces, accommodations } = data;
            let submissionError;
            if (validation === SavingSection.EventSpaces) {
                setSavingSection(SavingSection.EventSpaces);
                submissionError = getEventSpaceValidationError(eventSpaces, !!playbookId);
            } else if (validation === SavingSection.Accommodation) {
                setSavingSection(SavingSection.Accommodation);
                submissionError = getAccommodationValidationError(accommodations, user.team, !!playbookId);
            } else {
                submissionError = getPlannerValidationError(eventSpaces, accommodations, user.team, !!playbookId);
            }

            if (submissionError) {
                enqueueSnackbar(submissionError, { variant: 'error' });
                setSavingSection(null);
                return onSaveFailed?.();
            }
            onSave().finally(() => {
                setSavingSection(null);
            });
        },
        [onSave, data, enqueueSnackbar, user.team, onSaveFailed, playbookId]
    );

    useEffect(() => {
        if (!hasSaved && queuedSave) {
            handleSave();
        }
    }, [hasSaved, queuedSave, handleSave]);

    const handleAccommodationDates = ({
        value,
        field,
    }: {
        value: TPlannerAccommodationDates;
        field: keyof TPlannerAccommodationDates;
    }) => {
        // Check if the start date is after the end date
        if (field === 'startDate' && accommodationDates?.endDate && value[field]) {
            const startDate = tzMoment(value[field]);
            const endDate = tzMoment(accommodationDates.endDate);

            if (startDate.isAfter(endDate)) {
                // Reset the end date
                setAccommodationDates((prevDates: TPlannerAccommodationDates) => ({
                    ...prevDates,
                    [field]: value[field],
                    endDate: undefined,
                    hasUpdated: true,
                }));

                // Reset the accommodation nights
                setData((prevStagedData: TPlannerForm) => ({ ...prevStagedData, accommodations: [] }));

                return;
            }
        }

        setAccommodationDates((prevDates: TPlannerAccommodationDates) => {
            if (field === 'startDate' && accommodationNights.value && !accommodationDates.startDate) {
                const startDate = tzMoment(value[field], userTimeZone).format('ll');
                const endDate = tzMoment(value[field], userTimeZone)
                    .add(accommodationNights.value, 'days')
                    .format('ll');
                return {
                    ...prevDates,
                    startDate,
                    endDate,
                    hasUpdated: true,
                };
            }

            return {
                ...prevDates,
                [field]: value[field],
                hasUpdated: true,
            };
        });
    };

    const onRoomBlockImport = () => {
        if (roomBlocks && roomBlocks.length > 0) {
            const dates = roomBlocks.map(roomBlock => {
                const dayOfWeek = tzMoment(roomBlock.night).format('dddd');
                return { day: dayOfWeek, date: roomBlock.night, count: roomBlock.total };
            });
            setData((prevStagedData: TPlannerForm) => ({ ...prevStagedData, accommodations: dates }));
        }
    };

    return (
        <Column itemSpacing="default" noMargin={asInquiryModule || !!playbookId}>
            <LoadAttendees meetingId={event.id} />
            {!asInquiryModule && !playbookId && (
                <Column>
                    <H5Headline>{i18n.venue.planner.heading}</H5Headline>
                    <Subheadline>{i18n.venue.planner.subHeading}</Subheadline>
                </Column>
            )}

            {/* Schedule */}
            {!asInquiryModule && !playbookId && (
                <Row>
                    <Accordion
                        identity={SCHEDULE}
                        expanded={expanded === SCHEDULE}
                        summaryContent={
                            <PlannerAccordionSummary
                                headline={i18n.venue.planner.schedule}
                                description={i18n.venue.planner.scheduleDescription}
                                expanded={expanded === SCHEDULE}
                                label={i18n.venue.planner.scheduled}
                                summary={scheduleSummary}
                            />
                        }
                        detailContent={
                            <ScheduleDetails
                                editable={editable}
                                schedule={data.schedule || []}
                                addScheduleDay={addScheduleDay}
                                removeScheduleDay={removeEntry}
                                setScheduleData={updateEntry}
                                loading={loading}
                                onSave={onSave}
                            />
                        }
                        handleChange={handleAccordionChange}
                    />
                </Row>
            )}

            {/* Event Spaces */}
            <Row>
                <Accordion
                    identity={EVENT_SPACES}
                    expanded={expanded === EVENT_SPACES}
                    summaryContent={
                        <PlannerAccordionSummary
                            headline={i18n.proposalForm.sections.meetingSpaces}
                            description={
                                readonly ? (
                                    ''
                                ) : (
                                    <Column style={{ margin: 'auto' }}>
                                        <Copy style={{ marginBottom: '1rem' }}>
                                            {i18n.venue.planner.roomAccordionDescription}
                                        </Copy>
                                        <span>{dateDisplay}</span>
                                    </Column>
                                )
                            }
                            expanded={expanded === EVENT_SPACES}
                            label={i18n.proposalForm.sections.meetingSpaces}
                            summary={eventSpaceSummary}
                        />
                    }
                    detailContent={
                        <>
                            {(loading && expanded === 'init') || savingSection === SavingSection.EventSpaces ? (
                                <SpinnerOverlay />
                            ) : (
                                <EventSpaceDetails
                                    editable={editable}
                                    eventSpaces={data.eventSpaces || []}
                                    addEventSpace={addEntry}
                                    removeEventSpace={removeEntry}
                                    setEventSpaceData={updateEntry}
                                    loading={loading}
                                    onSave={() => handleSave(SavingSection.EventSpaces)}
                                    disableDates={!!playbookId}
                                    options={options}
                                    readonly={readonly}
                                />
                            )}
                        </>
                    }
                    handleChange={handleAccordionChange}
                />
            </Row>

            {/* Accommodations */}
            {!toggles.compliance.guestroomsDisabled && (
                <Row>
                    <Accordion
                        identity={ACCOMMODATIONS}
                        expanded={expanded === ACCOMMODATIONS}
                        summaryContent={
                            <PlannerAccordionSummary
                                headline={i18n.venue.planner.accommodations}
                                description={
                                    <>
                                        <Row>{i18n.venue.planner.guestRoomsDescription}</Row>
                                        {editable && !readonly && (
                                            <div onClick={e => e.stopPropagation()}>
                                                <Spacer />
                                                {playbookId ? (
                                                    <Row alignItems="center">
                                                        <Copy>{i18n.venue.planner.numberOfNightsNeed}</Copy>
                                                        <div style={{ width: '9.375rem' }}>
                                                            <SingleFieldForm
                                                                field={{
                                                                    type: 'select',
                                                                    options: {
                                                                        options: nightlyAccommodations,
                                                                        autoFocusOptionKey: 1,
                                                                    },
                                                                    optional: true,
                                                                }}
                                                                value={accommodationNights.value}
                                                                onChange={(value: number) => {
                                                                    setAccommodationNights(
                                                                        (prev: TPlannerAccommodationNights) => ({
                                                                            ...prev,
                                                                            value: value,
                                                                            hasUpdated: true,
                                                                        })
                                                                    );
                                                                    if (value > 0 && expanded !== ACCOMMODATIONS) {
                                                                        handleAccordionChange(ACCOMMODATIONS);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Row>
                                                ) : (
                                                    <>
                                                        {Boolean(roomBlocks?.length && roomBlocks.length > 0) && (
                                                            <Button onClick={onRoomBlockImport}>
                                                                {
                                                                    i18n.meetingDashboard.roomBlockSection
                                                                        .importFromRoomBlock
                                                                }
                                                            </Button>
                                                        )}
                                                        <Spacer />
                                                        <Form
                                                            disabled={!editable}
                                                            readonly={readonly}
                                                            fields={accommodationsFields}
                                                            schema={accommodationStartEndDateSchema}
                                                            value={accommodationDates}
                                                            isNested
                                                            onChange={({
                                                                value,
                                                                field,
                                                            }: {
                                                                value: TPlannerAccommodationDates;
                                                                field: keyof TPlannerAccommodationDates;
                                                            }) => {
                                                                handleAccommodationDates({ value, field });

                                                                if (
                                                                    accommodationDates.startDate &&
                                                                    field === 'endDate' &&
                                                                    value[field] &&
                                                                    expanded !== ACCOMMODATIONS
                                                                ) {
                                                                    handleAccordionChange(ACCOMMODATIONS);
                                                                }
                                                            }}
                                                        />
                                                        <Spacer small />
                                                        <ClearTextButton onClick={clearAccommodationsData}>
                                                            {i18n.button.clear}
                                                        </ClearTextButton>
                                                    </>
                                                )}
                                                <Spacer small />
                                                <Divider />
                                                <Spacer small />
                                                {user.team?.maxRoomsPerNightPerInquiry && (
                                                    <Copy regular $color={EColors.darkerGrey}>
                                                        {i18n.teamSettings.limitPerNight}:{' '}
                                                        {user.team.maxRoomsPerNightPerInquiry}
                                                    </Copy>
                                                )}
                                                {user.team?.maxTotalRoomNightsPerInquiry && (
                                                    <Copy regular $color={EColors.darkerGrey}>
                                                        {i18n.teamSettings.limitPerMeeting}:{' '}
                                                        {user.team.maxTotalRoomNightsPerInquiry}
                                                    </Copy>
                                                )}
                                            </div>
                                        )}
                                    </>
                                }
                                expanded={expanded === ACCOMMODATIONS}
                                label={i18n.venue.planner.guestRoomsLabel}
                                summary={accommodationsSummary}
                            />
                        }
                        detailContent={
                            <>
                                {(loading && expanded === 'init') || savingSection === SavingSection.Accommodation ? (
                                    <SpinnerOverlay />
                                ) : (
                                    <AccommodationsDetails
                                        editable={editable}
                                        accommodations={data.accommodations || []}
                                        setAccommodationsData={updateEntry}
                                        clearAccommodationsData={clearAccommodationsData}
                                        loading={loading}
                                        onSave={() => handleSave(SavingSection.Accommodation)}
                                        readonly={readonly}
                                    />
                                )}
                            </>
                        }
                        handleChange={handleAccordionChange}
                    />
                </Row>
            )}

            {/* Virtual Meetings */}
            {!asInquiryModule && !playbookId && (
                <Row>
                    <Accordion
                        identity={VIRTUAL}
                        expanded={expanded === VIRTUAL}
                        summaryContent={
                            <PlannerAccordionSummary
                                headline={i18n.venue.planner.virtualMeetings}
                                description={i18n.venue.planner.typeDescription}
                                expanded={expanded === VIRTUAL}
                                label={i18n.venue.planner.type}
                                summary={virtualMeetingSummary}
                            />
                        }
                        detailContent={
                            <VirtualMeetingDetails
                                editable={editable}
                                virtualSettings={toggles?.compliance?.virtual}
                                serviceProviders={user.team?.virtualMeetingServiceProviders || []}
                                virtualMeeting={!data?.virtualMeeting ? null : { ...data?.virtualMeeting }}
                                setVirtualMeetingsData={setVirtualMeetingsData}
                                loading={loading}
                                addVirtualMeeting={addVirtualMeeting}
                                onSave={onVirtualMeetingSave}
                                onRemove={onVirtualMeetingRemove}
                            />
                        }
                        handleChange={handleAccordionChange}
                    />
                </Row>
            )}
        </Column>
    );
}
