import { deleteCall, get, post, put } from '.';

export const getAddons = () => get(`marketplace/add-ons`) as Promise<{ addOns: BizlyAPI.Addon[] }>;

export const getSelectedAddons = (eventId: string | number) =>
    get(`events/${eventId}/add-ons`) as Promise<{ meetingAddOns: BizlyAPI.MeetingAddon[] }>;

export const addAddon = (eventId: string | number, addonId: string | number, linkUrl?: string, notes?: string) =>
    post(`events/${eventId}/add-ons`, { addOnId: addonId, linkUrl, notes }) as Promise<{
        success: boolean;
        meetingAddOn: BizlyAPI.MeetingAddon;
    }>;

export const updateAddon = (
    eventId: string | number,
    meetingAddonId: string | number,
    linkUrl?: string,
    notes?: string
) =>
    put(`events/${eventId}/add-ons/${meetingAddonId}`, { linkUrl, notes }) as Promise<{
        success: boolean;
        meetingAddOn: BizlyAPI.MeetingAddon;
    }>;

export const removeAddon = (eventId: string | number, meetingAddonId: string | number) =>
    deleteCall(`events/${eventId}/add-ons/${meetingAddonId}`) as Promise<{ success: boolean }>;
