import { H3Headline } from 'components/ui/Headline';
import React from 'react';
import styled from 'styled-components';
import { EColors } from 'theme';
import { Column, ExternalLink, Row, Spacer } from 'ui';

const VendorPopoverColumn = styled(Column)`
    width: 440px;
    max-width: 440px;

    padding: 32px;
    box-sizing: border-box;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Description = styled.div`
    font-size: 15px;
    line-height: 1.47;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const ChipsRow = styled(Row)`
    margin: -4px;
    & > * {
        margin: 4px;
    }

    flex-wrap: wrap;
`;

const Chip = styled.div`
    line-height: 21px;
    font-size: 11px;
    font-weight: 500;

    white-space: nowrap;

    padding: 0 11px;
    text-align: center;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    border-radius: 11px;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
`;

type TVendorPopover = {
    vendor: BizlyAPI.Addon;
};

export default function VendorPopover({ vendor }: TVendorPopover) {
    const { name, description, tags, linkUrl } = vendor;

    return (
        <VendorPopoverColumn>
            <H3Headline $color={EColors.accentedHeadline}>{name}</H3Headline>
            <Spacer xsmall />
            {tags && tags.length > 0 && (
                <ChipsRow>
                    {tags.map(({ id, name }) => (
                        <Chip key={id}>{name}</Chip>
                    ))}
                </ChipsRow>
            )}
            <Spacer />
            <Description>{description}</Description>
            {linkUrl && (
                <ExternalLink openInNewTab href={linkUrl}>
                    {linkUrl}
                </ExternalLink>
            )}
        </VendorPopoverColumn>
    );
}
