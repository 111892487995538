import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Tab, Tabs } from '../Tabs';
import { ProposalContent } from './ProposalContent';

const Container = styled(Box)(({ theme: { getColor, EColors, shape } }) => ({
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    borderRadius: shape.borderRadius,
    overflowY: 'auto',
    minWidth: '400px',
    maxWidth: '730px',
}));

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    boxSizing: 'border-box',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2.5),
    backgroundColor: getColor(EColors.drWhite),
    borderTopLeftRadius: shape.borderRadius,
    borderTopRightRadius: shape.borderRadius,
}));

const BodyContainer = styled(Box)(() => ({
    height: '60%',
}));

export default function ProposalDetails() {
    const [tabValue, setTabValue] = useState<string>('Proposal');
    return (
        <Container>
            <HeaderContainer>
                <Tabs
                    value={tabValue}
                    onChange={(_: React.SyntheticEvent, newValue: string) => {
                        setTabValue(newValue);
                    }}
                >
                    <Tab value="Proposal" label="Proposal" />
                    <Tab value="Internal Notes" label="Internal Notes" />
                    <Tab value="Activity Log" label="Activity Log" />
                </Tabs>
            </HeaderContainer>
            <BodyContainer>
                <ProposalContent />
            </BodyContainer>
        </Container>
    );
}
