import { getLookerUrl } from 'api/internalReports';
// Components
import SideNav from 'components/SideNav';
import SubscriptionPrompt from 'components/SubscriptionPrompt';
import useShowModal from 'hooks/useShowModal';
// Assets
// Api
import { useUser } from 'providers/user';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { i18n } from 'translation';
import { RowBase, Spacer } from 'ui';
// utils
import { downloadFromUrl } from '../../util';
import { DropdownStyle } from './style';

export default function ReportingPage() {
    const { user, toggles } = useUser();
    const navigate = useNavigate();
    const isPreview = !user.team?.features.enableReporting;
    const { modalShown: subscriptionModalShown, hideModal: hideSubscriptionModal } = useShowModal(isPreview);

    const [internalData, setInternalData] = useState<{
        url: string;
    }>({
        url: '',
    });

    const fetchData = useCallback(async () => {
        const lookerUrl = await getLookerUrl();

        setInternalData({
            url: lookerUrl.url,
        });
    }, []);

    // initial fetch
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSkipSubscription = () => {
        hideSubscriptionModal();
        navigate('/');
    };

    const handleDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dropdownValue = e.target.value;
        if (user.exportFileUrls) {
            switch (dropdownValue) {
                case 'exportAllMeetings':
                    downloadFromUrl(user.exportFileUrls['meetings']);
                    break;
                case 'exportAllBookings':
                    downloadFromUrl(user.exportFileUrls['bookings']);
                    break;
                case 'exportAllInquiries':
                    downloadFromUrl(user.exportFileUrls['inquiries']);
                    break;
                case 'exportPreferredVenues':
                    downloadFromUrl(user.exportFileUrls['preferredVenues']);
                    break;
                case 'exportActiveUsers':
                    downloadFromUrl(user.exportFileUrls['activeUsers']);
                    break;
            }
        }
    };

    const dropDownData = [
        { id: 'exportAllMeetings', value: 'Export All Meetings', name: i18n.reporting.exportAllMeetings },
        { id: 'exportAllBookings', value: 'Export All Bookings', name: i18n.reporting.exportAllBookings },
        { id: 'exportAllInquiries', value: 'Export All Venue Inquiries', name: i18n.reporting.exportAllInquiries },
        { id: 'exportPreferredVenues', value: 'Export Preferred Venues', name: i18n.reporting.exportPreferredVenues },
        { id: 'exportActiveUsers', value: 'Export Active Users', name: i18n.reporting.exportActiveUsers },
    ];

    return (
        <>
            <SideNav style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <RowBase justifyContent="space-between" alignItems="flex-end">
                    <DropdownStyle
                        className="export-dropdown"
                        field={''}
                        readonly={false}
                        disabled={false}
                        value={i18n.button.export}
                        placeholder={''}
                        options={dropDownData}
                        onChange={({ value: e }: { value: React.ChangeEvent<HTMLInputElement> }) =>
                            handleDropdownChange(e)
                        }
                    />
                </RowBase>
                <Spacer small />

                <RowBase style={{ width: '100%', height: `${toggles.gate.useFooter ? 'calc(85vh - 72px)' : '85vh'}` }}>
                    <iframe title={'reporting'} src={internalData.url} width="100%" height="100%" />
                </RowBase>

                {subscriptionModalShown && user.team && (
                    <SubscriptionPrompt currentTier={user.team.tier} onSkip={handleSkipSubscription} includeAllTiers />
                )}
            </SideNav>
        </>
    );
}
