import { ComingSoonMessage } from 'components/BizlyOS/ComingSoonMessage';
import { NewsletterSignUp } from 'components/BizlyOS/NewsletterSignUp';
import useShowModal from 'hooks/useShowModal';
import CalendarComingSoonSplash from 'images/bizlyOS/coming-soon/calendar.svg?react';

export const Calendar = () => {
    const { modalShown, showModal, hideModal } = useShowModal();

    return (
        <>
            <ComingSoonMessage
                pageTitle="Calendar"
                title="Get A Bird's-Eye View"
                description="Seamlessly manage your venue's calendar directly within BizlyOS. Block off dates, update availability in real-time, and get a clear overview of upcoming events - all in one convenient place. No more juggling multiple calendars or spreadsheets!"
                buttonText="Notify Me!"
                onButtonClick={showModal}
                Icon={CalendarComingSoonSplash}
            />
            <NewsletterSignUp
                open={modalShown}
                handleClose={hideModal}
                title="Be the first to try Bizly Calendar"
                description="Enter your email below to join the beta list and be the first to know when Calendar launches!"
                productName="Calendar"
                buttonText="Sign me up!"
            />
        </>
    );
};
