import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from 'translation';
import { currentInquiryActions } from './store';

export default function LoadCurrentInquiry() {
    const { eventId, id } = useParams<{ eventId: string; id: string }>();

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            currentInquiryActions.load(parseInt(eventId ?? id ?? ''));
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    }, [eventId, id, enqueueSnackbar]);

    return null;
}
