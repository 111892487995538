import Slider from '@material-ui/core/Slider';
import RotateCW from 'images/icons/rotate-clockwise.svg?react';
import RotateCCW from 'images/icons/rotate-counter-clockwise.svg?react';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Point } from 'react-easy-crop/types';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { Column, Row } from 'ui';

const RotateCounterClockwise = withInteractibleIconStyles(RotateCCW);
const RotateClockwise = withInteractibleIconStyles(RotateCW);

const CropContainer = styled(Column)`
    position: relative;
    height: 400px;
`;

const LogoEditorControls = styled(Row)`
    justify-content: flex-end;
    margin-top: 15px;
`;

const LogoEditorRotation = styled(Row)`
    justify-content: flex-end;

    .rotate-ccw {
        margin-right: 15px;
    }
`;

const LogoEditor = ({ image, rotation, setRotation, setCroppedAreaPixels }: any) => {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);

    const onCropComplete = useCallback(
        (_, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels);
        },
        [setCroppedAreaPixels]
    );

    return (
        <>
            <CropContainer>
                <Cropper
                    image={image?.url}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    minZoom={0.1}
                    restrictPosition={false}
                    cropSize={{ width: 200, height: 200 }}
                />
            </CropContainer>
            <LogoEditorControls>
                <Slider
                    value={zoom}
                    min={0.1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(Number(zoom))}
                    style={{ maxWidth: '75%' }}
                />
                <LogoEditorRotation>
                    <RotateCounterClockwise
                        className="rotate-ccw"
                        inverted
                        onClick={() => setRotation(rotation - 90)}
                    />
                    <RotateClockwise className="rotate-cw" inverted onClick={() => setRotation(rotation + 90)} />
                </LogoEditorRotation>
            </LogoEditorControls>
        </>
    );
};

export default LogoEditor;
