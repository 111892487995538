import { zodResolver } from '@hookform/resolvers/zod';
import { Add } from '@mui/icons-material';
import { Box, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { StaffRoleSchema, useInviteStaffMemberMutation, useStaffRoles } from 'hooks/queries/BizlyOS/useStaffQuery';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import { useState } from 'react';
import { Control, Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form';
import { BIZLYOS_CONTACT_TYPES, DEPARTMENT_OPTIONS } from 'shared';
import { EColors, getColor } from 'theme';
import * as z from 'zod';
import { Button } from './Button/Button';
import { InviteSuccessComponent } from './InviteSuccess/InviteSuccess';
import { SideDrawer } from './SideDrawer/SideDrawer';
import { Switch, ToggleSwitchBox } from './ToggleSwitch/ToggleSwitch';

interface ToggleSwitchProps {
    label: string;
    name: string;
    control: Control<FormsData>;
    index: number;
}

const ToggleSwitch = ({ label, control, index }: ToggleSwitchProps) => {
    return (
        <Controller
            name={`forms.${index}.receiveEmails`}
            control={control}
            render={({ field }) => (
                <ToggleSwitchBox isOn={field.value}>
                    <Typography>{label}</Typography>
                    <FormControlLabel
                        control={<Switch {...field} checked={field.value} />}
                        label=""
                        labelPlacement="start"
                        sx={{
                            margin: 0,
                            '& .MuiSwitch-root': {
                                marginLeft: 1,
                            },
                        }}
                    />
                </ToggleSwitchBox>
            )}
        />
    );
};

const StyledButtonBox = styled(Box)(({ theme }) => ({
    overflow: 'auto',
    paddingBottom: theme.spacing(2),
    marginLeft: 'auto',
}));

interface AddAnotherButtonProps {
    onClick: () => void;
}

// Left unused until we add multiple invite to API
const AddAnotherButton = ({ onClick }: AddAnotherButtonProps) => {
    return (
        <StyledButtonBox>
            <Button startIcon={<Add />} onClick={onClick}>
                Add Another
            </Button>
        </StyledButtonBox>
    );
};

const ActionButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
}));

interface DrawerActionButtonsProps {
    onCancel: () => void;
    onSubmit: () => void;
    isSubmitting: boolean;
    isValid: boolean;
}

const DrawerActionButtons = ({ onCancel, onSubmit, isSubmitting, isValid }: DrawerActionButtonsProps) => {
    return (
        <ActionButtonContainer>
            <Button variant="secondary" disableElevation onClick={onCancel}>
                Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting || !isValid} onClick={onSubmit}>
                {isSubmitting ? 'Submitting...' : 'Save'}
            </Button>
        </ActionButtonContainer>
    );
};

const formSchema = z.object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z.string().email('Invalid email address'),
    permission: z.number().min(1, 'Permission is required'),
    contactType: z.string().min(1, 'Contact type is required'),
    department: z.string().min(1, 'Department is required'),
    receiveEmails: z.boolean(),
});

const schema = z.object({
    forms: z.array(formSchema),
});

type FormsData = z.infer<typeof schema>;

const StyledTextField = styled(TextField)(({ theme }) => {
    const { getColor, EColors } = theme;

    return {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: alpha(getColor(EColors.pureBlack), 0.12),
            },
        },

        '& label.Mui-focused': {
            color: getColor(EColors.bizlyOSPrimary),
        },

        '& label.Mui-focused .MuiFormLabel-asterisk': {
            color: getColor(EColors.bizlyOSPrimary),
        },
    };
});

type StaffRole = z.infer<typeof StaffRoleSchema>;

export const InviteStaffDrawer = ({
    drawerOpen,
    setDrawerOpen,
}: {
    drawerOpen: boolean;
    setDrawerOpen: (toggle: boolean) => void;
}) => {
    const [inviteSuccess, setInviteSuccess] = useState(false);
    const { venueId } = useBizlyOSUser();

    const inviteStaffMemberMutation = useInviteStaffMemberMutation(venueId);
    const { roles } = useStaffRoles(venueId);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, touchedFields },
        trigger,
        reset,
    } = useForm<FormsData>({
        resolver: zodResolver(schema),
        defaultValues: {
            forms: [
                {
                    firstName: '',
                    lastName: '',
                    email: '',
                    permission: '',
                    contactType: '',
                    department: '',
                    receiveEmails: true,
                },
            ],
        },
        mode: 'onTouched',
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'forms',
    });

    const onSubmit = (data: FormsData) => {
        const EMPTY_STATE = '';

        data.forms.forEach(form => {
            const staffMember = {
                first_name: form.firstName,
                last_name: form.lastName,
                email: form.email,
                phone: EMPTY_STATE,
                image_url: EMPTY_STATE,
                role_id: form.permission,
                title: EMPTY_STATE,
                department: form.department,
                contact_type: form.contactType,
                notifications: {
                    inquiries: form.receiveEmails,
                },
            };

            inviteStaffMemberMutation.mutate(staffMember, {
                onSuccess: response => {
                    if (response.success) {
                        setInviteSuccess(true);
                        reset();
                    }
                },
            });
        });
    };

    // Unused for now until we setup an endpoint to invite multiple users.
    const handleAddAnother = async () => {
        const isValid = await trigger();
        if (isValid) {
            append({
                firstName: '',
                lastName: '',
                email: '',
                permission: '',
                contactType: '',
                department: '',
                receiveEmails: true,
            });
        } else {
            console.error('Please fill out all required fields before adding another.');
        }
    };

    return (
        <SideDrawer
            drawerOpen={drawerOpen}
            onClose={() => {
                if (inviteSuccess) {
                    setInviteSuccess(false);
                    setDrawerOpen(false);
                } else {
                    setDrawerOpen(false);
                }
            }}
            footer={
                !inviteSuccess ? (
                    <DrawerActionButtons
                        onCancel={() => setDrawerOpen(false)}
                        onSubmit={handleSubmit(onSubmit)}
                        isSubmitting={inviteStaffMemberMutation.isLoading}
                        isValid={isValid}
                    />
                ) : null
            }
            title="Invite Colleagues"
        >
            {inviteSuccess ? (
                <InviteSuccessComponent />
            ) : (
                <form style={{ height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        {/* Commenting this out until we extend the API to accept multiple invites */}
                        {/* <AddAnotherButton onClick={handleAddAnother} /> */}

                        {fields.map((field, index) => (
                            <FormFields
                                key={field.id}
                                index={index}
                                remove={() => remove(index)}
                                control={control}
                                errors={errors}
                                touchedFields={touchedFields}
                                roles={roles || []}
                            />
                        ))}
                    </Box>
                </form>
            )}
        </SideDrawer>
    );
};

type TouchedFields = {
    forms?: Array<{
        [K in keyof FormsData['forms'][number]]?: boolean;
    }>;
};

const FormFields = ({
    index,
    remove,
    control,
    errors,
    touchedFields,
    roles,
}: {
    index: number;
    remove: () => void;
    control: Control<FormsData>;
    errors: FieldErrors<FormsData>;
    touchedFields: TouchedFields;
    roles: StaffRole[];
}) => {
    return (
        <Box
            sx={{
                border: '1px solid',
                borderColor: getColor(EColors.bizlyOSBorder),
                borderRadius: 1,
                p: 3,
                mb: 2,
                position: 'relative',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Controller
                        name={`forms.${index}.firstName`}
                        control={control}
                        render={({ field }) => (
                            <StyledTextField
                                {...field}
                                label="First Name"
                                required
                                error={!!errors.forms?.[index]?.firstName}
                                helperText={errors.forms?.[index]?.firstName?.message}
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        name={`forms.${index}.lastName`}
                        control={control}
                        render={({ field }) => (
                            <StyledTextField
                                {...field}
                                label="Last Name"
                                required
                                error={!!errors.forms?.[index]?.lastName}
                                helperText={errors.forms?.[index]?.lastName?.message}
                                fullWidth
                            />
                        )}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Controller
                        name={`forms.${index}.email`}
                        control={control}
                        render={({ field }) => (
                            <StyledTextField
                                {...field}
                                label="Business Email"
                                required
                                error={!!touchedFields.forms?.[index]?.email && !!errors.forms?.[index]?.email}
                                helperText={
                                    touchedFields.forms?.[index]?.email && errors.forms?.[index]?.email?.message
                                }
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        name={`forms.${index}.permission`}
                        control={control}
                        render={({ field }) => (
                            <StyledTextField
                                select
                                {...field}
                                label="Permission"
                                required
                                error={!!errors.forms?.[index]?.permission}
                                helperText={errors.forms?.[index]?.permission?.message}
                                fullWidth
                            >
                                {roles.length > 0 ? (
                                    roles.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="">No roles available</MenuItem>
                                )}
                            </StyledTextField>
                        )}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Controller
                        name={`forms.${index}.contactType`}
                        control={control}
                        render={({ field }) => (
                            <StyledTextField
                                select
                                {...field}
                                label="Contact Type"
                                required
                                error={!!errors.forms?.[index]?.contactType}
                                helperText={errors.forms?.[index]?.contactType?.message}
                                fullWidth
                            >
                                {BIZLYOS_CONTACT_TYPES.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </StyledTextField>
                        )}
                    />

                    <Controller
                        name={`forms.${index}.department`}
                        control={control}
                        render={({ field }) => (
                            <StyledTextField
                                select
                                {...field}
                                label="Department"
                                required
                                error={!!errors.forms?.[index]?.department}
                                helperText={errors.forms?.[index]?.department?.message}
                                fullWidth
                            >
                                {DEPARTMENT_OPTIONS.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </StyledTextField>
                        )}
                    />
                </Box>

                <ToggleSwitch label="Receive event inquiry emails" name="forms" control={control} index={index} />
            </Box>

            {index > 0 && (
                <Box sx={{ pt: 2 }}>
                    <Button onClick={remove}>Remove</Button>
                </Box>
            )}
        </Box>
    );
};
