import { addAddon, getAddons, getSelectedAddons, removeAddon, updateAddon } from 'api/addons';
import { keyBy } from 'lodash';
import createStore from '../';

type SelectedAddon = BizlyAPI.Addon & { meetingAddonId: string | number; link?: string; notes?: string };

type State = {
    loadedCatalog?: boolean;
    loadingCatalog: boolean;
    addonsById: Partial<{
        [id: number]: BizlyAPI.Addon;
        [id: string]: BizlyAPI.Addon;
    }>;
    loadingSelected: boolean;
    savingSelected?: boolean;
    selectedAddonsById: Partial<{
        [id: number]: SelectedAddon;
        [id: string]: SelectedAddon;
    }>;
};

type Store = State;

const initialState: State = {
    loadingCatalog: false,
    addonsById: {},
    loadingSelected: false,
    selectedAddonsById: {},
};

export const useAddons = createStore<Store>(() => initialState);
const { setState, getState } = useAddons;

export const addonActions = {
    load: async () => {
        setState({ ...getState(), loadingCatalog: true });

        try {
            const { addOns: addons } = await getAddons();

            setState({
                loadingCatalog: false,
                addonsById: keyBy(addons, addon => addon.id),
                loadedCatalog: true,
            });
            return addons;
        } catch (e) {
            setState({
                loadingCatalog: false,
            });
            throw e;
        }
    },

    // TODO Should the next three functions be in the event store?

    loadSelected: async (eventId: string | number) => {
        setState({ ...getState(), loadingSelected: true });

        try {
            const { meetingAddOns } = await getSelectedAddons(eventId);
            const addons = meetingAddOns.map(meetingAddOn => ({
                ...meetingAddOn.addOn,
                meetingAddonId: meetingAddOn.id,
                link: meetingAddOn.linkUrl,
                notes: meetingAddOn.notes,
            }));

            setState({
                loadingSelected: false,
                selectedAddonsById: keyBy(addons, addon => addon.id),
            });
        } catch (e) {
            setState({ loadingSelected: false });
            throw e;
        }
    },

    selectAddon: async (eventId: string | number, addonId: string | number, link?: string, notes?: string) => {
        if (getState().savingSelected) return;
        const currState = getState().selectedAddonsById;

        try {
            const { success, meetingAddOn } = await addAddon(eventId, addonId, link, notes);
            if (success) {
                currState[meetingAddOn.addOn.id] = {
                    ...meetingAddOn.addOn,
                    meetingAddonId: meetingAddOn.id,
                    link: meetingAddOn.linkUrl,
                    notes: meetingAddOn.notes,
                };
                setState({
                    selectedAddonsById: currState,
                });
            }

            setState({
                savingSelected: false,
            });
        } catch (e) {
            setState({ savingSelected: false });
            throw e;
        }
    },

    updateAddon: async (eventId: string | number, addonId: string | number, link?: string, notes?: string) => {
        if (getState().savingSelected) return;

        setState({ ...getState(), savingSelected: true });

        const currState = getState().selectedAddonsById;
        const meetingAddonId = currState[addonId]?.meetingAddonId;
        try {
            if (meetingAddonId) {
                const { success, meetingAddOn } = await updateAddon(eventId, meetingAddonId, link, notes);
                if (success) {
                    currState[meetingAddOn.addOn.id] = {
                        ...meetingAddOn.addOn,
                        meetingAddonId: meetingAddOn.id,
                        link: meetingAddOn.linkUrl,
                        notes: meetingAddOn.notes,
                    };
                    setState({
                        selectedAddonsById: currState,
                    });
                }
                setState({
                    savingSelected: false,
                });
            }
        } catch (e) {
            setState({ savingSelected: false });
            throw e;
        }
    },

    removeAddon: async (eventId: string | number, addonId: string | number) => {
        if (getState().savingSelected) return;

        setState({ ...getState(), savingSelected: true });

        const currState = getState().selectedAddonsById;
        const meetingAddonId = currState[addonId]?.meetingAddonId;
        try {
            if (meetingAddonId) {
                const { success } = await removeAddon(eventId, meetingAddonId);
                if (success) {
                    delete currState[addonId];
                    setState({ ...getState(), selectedAddonsById: currState });
                }
            }
            setState({ savingSelected: false });
        } catch (e) {
            setState({ savingSelected: false });
            throw e;
        }
    },

    loadEverything: async (eventId: string | number) => {
        await Promise.all([addonActions.load(), addonActions.loadSelected(eventId)]);
    },
};

export const getAddon = (id?: string | number) => (state: State) =>
    id !== undefined ? state.addonsById?.[id] : undefined;

export const eventHasSelectedAddon = () => (state: State) => {
    return !(Object.keys(state.selectedAddonsById).length === 0);
};

export const isSelected = (id?: string | number) => (state: State) =>
    id !== undefined ? id in state.selectedAddonsById : false;

export const getSelectedMetaData = (id?: string | number) => (state: State) => {
    if (!id) return {};

    const selected = state.selectedAddonsById[id];
    if (selected) {
        return { link: selected.link, notes: selected.notes };
    }
    return {};
};

export const getAddonsAsList = (state: State) =>
    state.addonsById ? (Object.values(state.addonsById) as BizlyAPI.Addon[]) : [];

export const getAddonsByCategory = (category?: BizlyAPI.AddonCategory) => (state: State) =>
    category !== undefined && state.addonsById
        ? (Object.values(state.addonsById) as BizlyAPI.Addon[]).filter(addon => addon.category === category)
        : [];
