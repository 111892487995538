import { AdornmentPill } from 'components/ui/AutoComplete';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';

export type Permission = 'view' | 'edit';

type PermissionPillProps = {
    value: Permission;
    onChange?: (value: Permission) => void;
};

const ClickablePill = styled(AdornmentPill)<{ edit: boolean }>`
    user-select: none;
    background-color: ${({ backgroundColor, theme: { getColor, EColors }, edit }) =>
        backgroundColor || (edit ? getColor(EColors.canEditPillBackground) : getColor(EColors.canViewPillBackground))};
    box-shadow: 2px 2px 3px ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.3)};
`;

const PermissionPill = ({ value, onChange }: PermissionPillProps) => {
    const onClick = () => {
        if (onChange) {
            const newValue = value === 'view' ? 'edit' : 'view';
            onChange(newValue);
        }
    };
    return (
        <ClickablePill edit={value === 'edit'} onClick={onClick}>
            {value === 'edit'
                ? i18n.meetingDashboard.headerSection.collaborators.canEdit
                : i18n.meetingDashboard.headerSection.collaborators.canView}
        </ClickablePill>
    );
};

export default PermissionPill;
