import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import { styled as muiStyled } from '@mui/material/styles';
import { SkeletonGridCards } from 'components/Ui-V2/Skeleton/Skeleton';
import { SwitchGroup } from 'components/Ui-V2/SwitchGroup/SwitchGroup';
import { Tab, TabContainer } from 'components/Ui-V2/TabGroup/TabGroup';
import { useEventTemplates } from 'hooks/queries/useTemplateQueries';
import GridIcon from 'images/icons/event-tabs/grid.svg?react';
import ListIcon from 'images/icons/event-tabs/list.svg?react';
import { useUser } from 'providers/user';
import { useCallback, useMemo, useState } from 'react';
import { i18n } from 'translation';
import { TemplateFilterPopper } from './TemplateFilterPopper';
import { TemplateGrid } from './TemplateGrid';
import { TemplateListTable } from './TemplateListTable';
import { TemplateStateMessages } from './TemplateStateMessages';
import { ESECTION } from './utils';
import FiltersButton from 'components/FilterButton';

const BorderlessCard = muiStyled(Box)<{ flex?: boolean }>(({ flex }) => ({
    position: 'relative',
    flex: flex ? 1 : 'unset',
    boxSizing: 'border-box',
}));

const StyledGridIcon = muiStyled(GridIcon)(() => ({ marginRight: '.5rem' }));
const StyledListIcon = muiStyled(ListIcon)(() => ({ marginRight: '.5rem' }));

export type TemplateTabValue = 'all' | 'teamTemplates' | 'createdByOwner' | 'recommendedTemplates';

type Alignment = 'grid' | 'list';

interface TabConfigItem {
    icon?: React.ReactNode;
    label: string;
    filter: (playbook: Bizly.EventTemplate) => boolean;
}

export const TemplateTable = () => {
    const { user } = useUser();
    const { templates, isLoadingTemplates } = useEventTemplates();

    const [tab, setTab] = useState<TemplateTabValue>('all');
    const [alignment, setAlignment] = useState<Alignment>('grid');
    const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const [selectedTagsSet, setSelectedTagsSet] = useState<Set<number>>(new Set());

    const tabConfig = useMemo(() => {
        const baseConfig: Record<TemplateTabValue, TabConfigItem> = {
            all: {
                icon: <ViewHeadlineIcon />,
                label: i18n.homepage.eventTableActions.all,
                filter: () => true,
            },
            teamTemplates: {
                icon: <GroupOutlinedIcon sx={{ fontSize: '1.125rem' }} />,
                label: user?.team?.name
                    ? i18n.playbooks.sharedTitle(user.team.name)
                    : i18n.playbooks.sharedTitleGeneric,
                filter: (playbook: Bizly.EventTemplate) => playbook.category === ESECTION.SHARED,
            },
            createdByOwner: {
                icon: <AccountCircleOutlinedIcon />,
                label: i18n.homepage.eventTableActions.createdByYou,
                filter: (playbook: Bizly.EventTemplate) => playbook.category === ESECTION.PERSONAL,
            },
            recommendedTemplates: {
                icon: <RecommendOutlinedIcon sx={{ fontSize: '1.125rem' }} />,
                label: i18n.playbooks.globalTitle,
                filter: (playbook: Bizly.EventTemplate) => playbook.category === ESECTION.GLOBAL,
            },
        };
        return baseConfig;
    }, [user?.team?.name]);

    const applyFilter = useCallback(
        (templates: Bizly.EventTemplate[]) => {
            const currentTabFilter = tabConfig[tab].filter;

            return templates.filter(template => {
                const matchesCurrentTab = currentTabFilter(template);
                const matchesSelectedTags =
                    selectedTags.length === 0 || template.tags?.some(tag => selectedTags.includes(tag.id)) || false;

                return matchesCurrentTab && matchesSelectedTags;
            });
        },
        [tab, tabConfig, selectedTags]
    );

    const filteredTemplates = useMemo(() => {
        return templates ? applyFilter(templates) : [];
    }, [templates, applyFilter]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: TemplateTabValue) => {
        setTab(newValue);
    };

    const handleViewChange = (_: React.MouseEvent<HTMLElement>, newAlignment: Alignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setFilterAnchorEl(filterAnchorEl ? null : event.currentTarget);
    };

    const handleCloseFilter = () => {
        setFilterAnchorEl(null);
    };

    const handleTagChange = useCallback((tagId: number) => {
        setSelectedTagsSet(prevTags => {
            const newTags = new Set(prevTags);
            if (newTags.has(tagId)) {
                newTags.delete(tagId);
            } else {
                newTags.add(tagId);
            }
            return newTags;
        });
    }, []);

    const handleClearTags = useCallback(() => {
        setSelectedTagsSet(new Set());
    }, []);

    const handleApplyFilters = useCallback(() => {
        setSelectedTags(Array.from(selectedTagsSet));
        setFilterAnchorEl(null);
    }, [selectedTagsSet]);

    const renderContent = () => {
        if (isLoadingTemplates) {
            return <SkeletonGridCards />;
        }

        if (!filteredTemplates?.length) {
            return <TemplateStateMessages tab={tab} />;
        }

        if (alignment === 'grid') {
            return <TemplateGrid templates={filteredTemplates} />;
        }

        return <TemplateListTable templates={filteredTemplates} />;
    };

    return (
        <BorderlessCard flex={true}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <TabContainer value={tab} onChange={handleTabChange}>
                    {Object.entries(tabConfig).map(([value, { icon, label }]) => (
                        <Tab key={value} icon={icon} label={label} value={value} />
                    ))}
                </TabContainer>

                <Box display="flex" alignItems="center" gap={1}>
                    <FiltersButton toggleFiltersMenu={handleFilterClick} filterCount={selectedTags.length} />
                    <SwitchGroup value={alignment} exclusive onChange={handleViewChange}>
                        <ToggleButton value="grid">
                            <StyledGridIcon alignment />
                            {i18n.homepage.eventTableActions.grid}
                        </ToggleButton>
                        <ToggleButton value="list">
                            <StyledListIcon alignment />
                            {i18n.homepage.eventTableActions.list}
                        </ToggleButton>
                    </SwitchGroup>
                </Box>

                <TemplateFilterPopper
                    anchorEl={filterAnchorEl}
                    open={Boolean(filterAnchorEl)}
                    onClose={handleCloseFilter}
                    onApplyFilters={handleApplyFilters}
                    onClear={handleClearTags}
                    onTagChange={handleTagChange}
                    selectedTags={selectedTagsSet}
                />
            </Box>
            <Box sx={{ mt: '1rem', mb: '1rem', minHeight: '15rem' }}>{renderContent()}</Box>
        </BorderlessCard>
    );
};
