import InternalDetailsForm from 'pages/PlaybookWizard/InternalDetailsForm';
import React from 'react';

const PlaybookInteralDetails = ({ playbook }: { playbook: BizlyAPI.Complete.Playbook }) => {
    const value = {
        costCenter: playbook.costCenter,
        type: playbook.meetingType,
        cventId: playbook.internalId,
        budget: playbook.budget,
    };
    return <InternalDetailsForm value={value} readonly />;
};

export default PlaybookInteralDetails;
