import Form from 'components/Form';
import { Pane } from 'components/Pane';
import { H3Headline, H5Headline } from 'components/ui/Headline';
import { format, parseISO } from 'date-fns';
import useThemedColor from 'hooks/useThemedColor';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Button, Column, Row, Spacer, Copy as UICopy } from 'ui';
import { replaceObjInArray } from '../EventSpacesForm/utils';
import { TFormSectionProps, useRegisterValidator } from '../utils';
import { commissionableForm, dayForm } from './guestroomsFormSchema';
import {
    TGRFormBooking,
    TGuestRoomsFormValue,
    formDataToProposalForm,
    getErrorMessage,
    proposalFormToFormData,
    setFeeTaxOnAllGR,
} from './utils';

const Copy = styled.div`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
    white-space: pre-line;
`;

const BorderedContent = styled(Column)`
    padding: 12px 48px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
`;

type TCommissionableValue = { commissionable?: boolean | null };

const CommissionableField = ({
    value,
    onChange,
    disabled,
}: {
    value: TCommissionableValue;
    onChange: (update: { value: TCommissionableValue }) => void;
    disabled?: boolean;
}) => (
    <>
        <H3Headline $color={EColors.accentedHeadline}>{i18n.proposalForm.guestRoom.commissionHeading}</H3Headline>
        <UICopy>{i18n.proposalForm.guestRoom.commissionCopy}</UICopy>
        <Spacer small />
        <Form {...commissionableForm} value={value} onChange={onChange} disabled={disabled} />
    </>
);

const paneStyles = (background: Themed.Color, borderColor: Themed.Color) => ({
    background,
    border: `1px solid ${borderColor}`,
    borderTop: '0px',
    padding: 48,
});

const GuestroomsDayForm = ({
    dayIndex,
    isLastDay,
    currencyOptions,
    commissionable,
    bizlyCollectsCommissions,
    data,
    onChange: onChangeProp,
    onCopyRoomDetailsBelow,
    onCopyRoomDetailsAllBelow,
    disabled,
}: {
    dayIndex: number;
    isLastDay: boolean;
    currencyOptions: { id: string }[];
    commissionable: boolean;
    bizlyCollectsCommissions: boolean;
    data: TGRFormBooking;
    onChange: (dayIndex: number, update: { field: string; value: TGRFormBooking }) => void;
    onCopyRoomDetailsBelow: () => void;
    onCopyRoomDetailsAllBelow: () => void;
    disabled?: boolean;
}) => {
    const { pureWhite, lightGrey } = useThemedColor();

    const { fields, schemaRooms, schemaDetails } = dayForm;

    const onChange = React.useCallback(
        (update: { field: string; value: TGRFormBooking }) => onChangeProp(dayIndex, update),
        [dayIndex, onChangeProp]
    );

    const formBaseProps = useMemo(
        () => ({
            fields: fields({
                requestedGuests: data.requestedGuests || 0,
                currencyOptions,
                currencyCode: data.currencyCode,
            }),
            value: data,
            onChange,
            disabled,
        }),
        [currencyOptions, data, onChange, disabled, fields]
    );

    const formSchema = useMemo(
        () => schemaDetails({ commissionable, bizlyCollectsCommissions }),
        [commissionable, bizlyCollectsCommissions, schemaDetails]
    );

    const form = useMemo(
        () => (
            <Column>
                <Form {...formBaseProps} schema={schemaRooms} />
                {(data.proposedRoomCount || 0) > 0 && (
                    <>
                        <Spacer largest />
                        <Form {...formBaseProps} schema={formSchema} />
                    </>
                )}
            </Column>
        ),
        [data, formBaseProps, schemaRooms, formSchema]
    );

    return (
        <Pane
            label={i18n.common.dayIndex(dayIndex + 1)}
            secondaryLabel={format(parseISO(data.date), 'EEEE, MMMM dd, yyyy')}
            stackedLabels
            beginExpanded
            invert
            stickyHeader
            contentStyle={paneStyles(pureWhite, lightGrey)}
        >
            {() => (
                <>
                    {form}
                    <Spacer largest />
                    {!isLastDay && (
                        <Row itemSpacing="largest" justifyContent="center">
                            <Button onClick={onCopyRoomDetailsBelow} disabled={data.proposedRoomCount === null}>
                                {i18n.proposalForm.guestRoom.copyRoomDetails}
                            </Button>
                            <Spacer />
                            <Button onClick={onCopyRoomDetailsAllBelow} disabled={data.proposedRoomCount === null}>
                                {i18n.proposalForm.guestRoom.copyRoomDetailsToAll}
                            </Button>
                        </Row>
                    )}
                </>
            )}
        </Pane>
    );
};

export default function GuestroomsForm({ onChange, registerValidator, disabled, ...rest }: TFormSectionProps) {
    const [data, setData] = React.useState<TGuestRoomsFormValue>(proposalFormToFormData(rest));

    useRegisterValidator(data, registerValidator, getErrorMessage, formDataToProposalForm);

    const singleDayUpdater = React.useCallback(
        (index: number, { field, value: newGRBooking }: { field: string; value: TGRFormBooking }) => {
            setData(prevData => {
                let newGuestRooms = replaceObjInArray(prevData.guestRooms, index, newGRBooking);

                newGuestRooms = (() => {
                    const { resortFee, occupancyTax, currencyCode, commissionRate } = newGRBooking;
                    switch (field) {
                        case 'resortFee':
                            return setFeeTaxOnAllGR(newGuestRooms, { resortFee });
                        case 'occupancyTax':
                            return setFeeTaxOnAllGR(newGuestRooms, { occupancyTax });
                        case 'currencyCode':
                            return setFeeTaxOnAllGR(newGuestRooms, { currencyCode });
                        case 'commissionRate':
                            return setFeeTaxOnAllGR(newGuestRooms, { commissionRate });
                        default:
                            return newGuestRooms;
                    }
                })();

                onChange();
                return {
                    ...prevData,
                    guestRooms: newGuestRooms,
                };
            });
        },
        [setData, onChange]
    );

    const onCopyRoomDetailsBelow = (index: number) => {
        const sourceDay = data.guestRooms[index];
        const destinationIndex = index + 1;
        const destinationDay = data.guestRooms[destinationIndex];

        setData(prevData => {
            const newGuestRooms = [...prevData.guestRooms];
            newGuestRooms[destinationIndex] = {
                ...destinationDay,
                proposedRoomCount: sourceDay.proposedRoomCount,
                proposedImageUrl: sourceDay.proposedImageUrl,
                proposedRoomName: sourceDay.proposedRoomName,
                proposedRoomRate: sourceDay.proposedRoomRate,
            };

            return {
                ...prevData,
                guestRooms: newGuestRooms,
            };
        });

        onChange();
    };

    const onCopyRoomDetailsAllBelow = (index: number) => {
        const sourceDay = data.guestRooms[index];

        setData(prevData => {
            const newGuestRooms = [...prevData.guestRooms];

            for (let i = index + 1; i < newGuestRooms.length; i++) {
                newGuestRooms[i] = {
                    ...newGuestRooms[i],
                    proposedRoomCount: sourceDay.proposedRoomCount,
                    proposedImageUrl: sourceDay.proposedImageUrl,
                    proposedRoomName: sourceDay.proposedRoomName,
                    proposedRoomRate: sourceDay.proposedRoomRate,
                };
            }

            return {
                ...prevData,
                guestRooms: newGuestRooms,
            };
        });

        onChange();
    };

    const onCommissionableChange = ({ value }: { value: TCommissionableValue }) => {
        setData(prev => ({ ...prev, commissionable: value.commissionable }));
        onChange();
    };

    const hasRoomBooking = React.useMemo(
        () => data.guestRooms.filter(grBooking => (grBooking.proposedRoomCount || 0) > 0).length > 0,
        [data]
    );

    return (
        <Column>
            <H5Headline>{i18n.proposalForm.guestRoom.heading}</H5Headline>
            <Spacer small />
            <Copy>{i18n.proposalForm.guestRoom.description}</Copy>

            {hasRoomBooking && (
                <>
                    <Spacer largest />
                    <BorderedContent>
                        <CommissionableField value={data} onChange={onCommissionableChange} disabled={disabled} />
                    </BorderedContent>
                </>
            )}

            <Spacer largest />

            {(rest.guestRooms || []).length === 0 ? (
                <Copy>{i18n.proposalForm.guestRoom.noGuestRoomRequest}</Copy>
            ) : null}

            <Column itemSpacing="larger">
                {data.guestRooms.map((day, index) => (
                    <GuestroomsDayForm
                        key={index}
                        dayIndex={index}
                        isLastDay={index === data.guestRooms.length - 1}
                        currencyOptions={rest.options?.currencies?.map(({ code }) => ({ id: code })) ?? []}
                        data={day}
                        onChange={singleDayUpdater}
                        onCopyRoomDetailsBelow={() => onCopyRoomDetailsBelow(index)}
                        onCopyRoomDetailsAllBelow={() => onCopyRoomDetailsAllBelow(index)}
                        disabled={disabled}
                        commissionable={!!data.commissionable}
                        bizlyCollectsCommissions={!!data.bizlyCollectsCommissions}
                    />
                ))}
            </Column>
        </Column>
    );
}
