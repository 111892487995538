import Dialog from '@material-ui/core/Dialog';
import { captureException } from '@sentry/minimal';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { rejectProposal } from '../api';
import useKey from '../hooks/useKey';
import { AlignedRow, Column, Copy } from '../ui';
import TextButton from './ui/Button/TextButton';

const DialogContent = styled(Column)`
    padding: 20px;
    width: 400px;
`;

const DialogTitle = styled.h2`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};

    font-size: 25px;
    line-height: 1.28;
    letter-spacing: -0.5px;

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
`;

const ButtonRow = styled(AlignedRow)`
    justify-content: flex-end;
    margin-top: 20px;
`;

const ProposalRejectModal = ({
    proposalId,
    open,
    closeModal,
    eventId,
}: {
    eventId: number;
    proposalId?: number;
    open: boolean;
    closeModal: () => void;
}) => {
    useKey('Escape', closeModal);
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const location = useLocation();

    function alreadyOnInquiriesRoute() {
        return location.pathname === `/event/${eventId}/venue/inquiries`;
    }

    return (
        <Dialog open={open} maxWidth="md">
            <DialogContent>
                <DialogTitle>{i18n.modal.areYouSure}</DialogTitle>
                <Copy>{i18n.venue.proposal.rejectConfirmDescription}</Copy>
                <ButtonRow itemSpacing="smallish">
                    <TextButton secondary onClick={closeModal}>
                        {i18n.button.cancel}
                    </TextButton>
                    <TextButton
                        onClick={async () => {
                            if (proposalId) {
                                try {
                                    const { success } = await rejectProposal(proposalId);
                                    if (success && alreadyOnInquiriesRoute()) {
                                        closeModal();
                                        window.location.reload();
                                    } else {
                                        navigate(`/event/${eventId}/venue/inquiries`);
                                    }
                                } catch (error) {
                                    captureException(error);
                                    enqueueSnackbar(i18n.error.default, { variant: 'error' });
                                }
                            }
                        }}
                    >
                        {i18n.button.confirm}
                    </TextButton>
                </ButtonRow>
            </DialogContent>
        </Dialog>
    );
};

export default ProposalRejectModal;
