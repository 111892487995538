import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { teamsActions } from './store';

export default function LoadTeams({ userId }: { userId: string | number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await teamsActions.load(userId);
            } catch (e) {
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            }
        };

        load();
    }, [userId, enqueueSnackbar]);

    return null;
}
