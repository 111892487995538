import PlaybookBasicDetails from 'components/Playbook/PlaybookBasicDetails';
import { SpinnerOverlay } from 'components/Spinner';
import { H3Headline, H5Headline } from 'components/ui/Headline';
import isEqual from 'lodash/isEqual';
import PlaybookCollaboration from 'pages/Playbook/components/PlaybookCollaboration';
import PlaybookInquiry from 'pages/Playbook/components/PlaybookInquiry';
import PlaybookInternalDetails from 'pages/Playbook/components/PlaybookInternalDetails';
import PlaybookParcels from 'pages/Playbook/components/PlaybookParcels';
import PlaybookVenueFilters from 'pages/Playbook/components/PlaybookVenueFilters';
import React from 'react';
import {
    LoadPlaybookCollaborators,
    LoadPlaybookResources,
    selectCollaborators,
    selectPlaybook,
    selectResources,
    usePlaybooks,
} from 'stores/playbooks';
import { i18n } from 'translation';
import { Column, Row, Spacer } from 'ui';
import { useCreatePlaybook } from './store';

export default function Preview() {
    const { basicInfo, id: playbookId, playbook: playbookBase } = useCreatePlaybook();
    const collaborators = usePlaybooks(selectCollaborators(playbookId));
    const resources = usePlaybooks(selectResources(playbookId));

    const playbook = usePlaybooks(selectPlaybook(playbookId));

    if (!playbookId || !playbook) return <SpinnerOverlay />;

    const hasCollaboration = (collaborators && collaborators.length > 0) || (resources && resources.length > 0);
    const hasFilters = !isEqual(playbookBase?.venueSearchParameters, {
        radius: 24141,
        radiusKm: 24,
    });

    return (
        <>
            <Spacer smallish />
            <H5Headline>{basicInfo.name}</H5Headline>
            <Row itemSpacing="largest" alignItems="stretch">
                <Column itemSpacing="large">
                    <LoadPlaybookCollaborators id={playbookId} />
                    <LoadPlaybookResources id={playbookId} />
                    <PlaybookBasicDetails playbook={playbook} />
                    <PlaybookInternalDetails playbook={playbook} />
                    {hasFilters && (
                        <Column>
                            <H3Headline>{i18n.playbookWizard.preview.venueSearchFilters}</H3Headline>
                            <Spacer default />
                            <PlaybookVenueFilters playbook={playbook} />
                        </Column>
                    )}
                    <Column itemSpacing="xsmall">
                        <H3Headline>{i18n.playbookWizard.preview.inquiry}</H3Headline>
                        <PlaybookInquiry playbookId={playbookId} />
                    </Column>
                    <Column itemSpacing="xsmall">
                        <H3Headline>{i18n.playbookWizard.preview.communications}</H3Headline>
                        <PlaybookParcels playbookId={playbookId} asWizardModule />
                    </Column>
                    {hasCollaboration && (
                        <Column itemSpacing="large">
                            <H3Headline>{i18n.playbookWizard.preview.collaboration}</H3Headline>
                            <PlaybookCollaboration playbookId={playbookId} />
                        </Column>
                    )}
                </Column>
            </Row>
        </>
    );
}
