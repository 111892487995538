import { Box, IconButton, styled, Typography } from '@mui/material';
import {
    DataGridPro,
    GridColDef,
    GridDeleteIcon,
    GridRenderCellParams,
    GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { i18n } from 'translation';
import { Button } from 'ui';
import { GuestListTableToolbar } from './GuestListTableToolbar';

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
    flexGrow: 1,

    '&.MuiDataGrid-root': {
        maxHeight: '100%',
        width: '100%',
    },
    '.MuiDataGrid-cell': {
        border: 'none',
        outline: 'none',
        '&:focus': {
            outline: 'none',
        },
    },
    '& .MuiToggleButtonGroup-root': {
        margin: theme.spacing(0.5, 0),
    },
    '& .MuiDataGrid-columnHeader': {
        borderRight: 'none',
        backgroundColor: theme.palette.grey[100],

        '&:focus-within': {
            outline: 'none',
            border: 'none',
        },
    },
    '& .MuiDataGrid-cellCheckbox': {
        border: 'none',
        '&:focus-within': {
            outline: 'none',
            border: 'none',
        },
    },
    '& .MuiDataGrid-overlayWrapper': {
        height: 'auto',
    },
    '& .MuiDataGrid-main': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
    },
    '& .MuiDataGrid-footerContainer': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderTopWidth: 0,
    },
    '& .MuiDataGrid-row': {
        '--rowBorderColor': 'transparent',
    },
    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },

    '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: 'transparent',
    },
}));

const StyledHeaderToolbar = styled(Box)`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: transparent;
`;

const StyledIconButton = styled(IconButton)`
    padding-left: 0;
    padding-bottom: 0;
`;

const StyledActionButton = styled(Button)`
    &.MuiButton-root {
        text-transform: none;
    }
`;

type Status = {
    props: {
        status: string;
    };
};

type Row = {
    id: string;
    name: string;
    email: string;
    phone: string;
    status: Status;
};

type GuestListTableProps = {
    rows: Row[];
    onEdit: (id: string) => void;
    onSortChange: (newValue: string) => void;
    editable: boolean;
    onEditClick: (id: string) => void;
    handleSelectAttendees: (ids: string[]) => void;
    onMultiDeleteAttendeesClick: () => void;
    setFilteredAttendeesRows: (rows: Row[]) => void;
};

type CustomGuestListHeaderToolbarProps = {
    rowSelectionModel: GridRowSelectionModel;
    handleDelete: () => void;
    searchTerm: string;
    setSearchTerm: (value: string) => void;
    filter: string | null;
    setFilter: (value: string | null) => void;
};

const CustomGuestListHeaderToolbar = (props: CustomGuestListHeaderToolbarProps) => {
    const { rowSelectionModel, handleDelete, searchTerm, setSearchTerm, filter, setFilter } = props;
    const isDisabled = rowSelectionModel.length === 0;

    return (
        <StyledHeaderToolbar>
            <Box display="flex" alignItems="flex-end" justifyContent="center">
                {!isDisabled && (
                    <>
                        <StyledIconButton disabled={isDisabled} onClick={handleDelete}>
                            <GridDeleteIcon color="error" />
                        </StyledIconButton>
                        <Typography fontWeight={500} fontSize={14}>
                            {rowSelectionModel.length} selected
                        </Typography>
                    </>
                )}
            </Box>

            <Box>
                <GuestListTableToolbar
                    value={searchTerm}
                    handleSearch={setSearchTerm}
                    filter={filter}
                    setFilter={setFilter}
                />
            </Box>
        </StyledHeaderToolbar>
    );
};

export const GuestListTable = (props: GuestListTableProps) => {
    const { rows, onEditClick, handleSelectAttendees, onMultiDeleteAttendeesClick, setFilteredAttendeesRows } = props;

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                renderCell: ({ row }: GridRenderCellParams) => row.name,
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                renderCell: ({ row }: GridRenderCellParams) => row.email,
            },
            {
                field: 'phone',
                headerName: 'Phone number',
                flex: 1,
                renderCell: ({ row }: GridRenderCellParams) => row.phone,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: ({ row }: GridRenderCellParams) => row.status,
            },
            {
                field: 'action',
                headerName: 'Action',
                flex: 1,
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ row }: GridRenderCellParams) => (
                    <StyledActionButton
                        variant="text"
                        onClick={() => {
                            onEditClick(row.id);
                        }}
                    >
                        {i18n.button.view}
                    </StyledActionButton>
                ),
            },
        ],
        [onEditClick]
    );

    const [searchTerm, setSearchTerm] = useState('');
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [filter, setFilter] = useState<string | null>(null);

    const handleSelectionChange = useCallback(
        (ids: string[]) => {
            setRowSelectionModel(ids);
            handleSelectAttendees(ids);
        },
        [handleSelectAttendees]
    );

    const filteredRows = useMemo(
        () =>
            rows.filter(row => {
                const isSearchTermMatch =
                    row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.phone?.includes(searchTerm);

                const isStatusMatch = filter ? row.status.props.status.toLowerCase() === filter.toLowerCase() : true;

                return isSearchTermMatch && isStatusMatch;
            }),
        [rows, searchTerm, filter]
    );

    useEffect(() => {
        setFilteredAttendeesRows(filteredRows);
    }, [filteredRows, setFilteredAttendeesRows]);

    return (
        <StyledDataGridPro
            rows={filteredRows}
            columns={columns}
            autoHeight
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnResize
            disableColumnSelector
            hideFooter
            onRowSelectionModelChange={handleSelectionChange}
            rowSelectionModel={rowSelectionModel}
            slots={{
                toolbar: CustomGuestListHeaderToolbar,
            }}
            slotProps={{
                toolbar: {
                    rowSelectionModel: rowSelectionModel,
                    handleDelete: onMultiDeleteAttendeesClick,
                    searchTerm: searchTerm,
                    setSearchTerm: setSearchTerm,
                    filter: filter,
                    setFilter: setFilter,
                },
            }}
        />
    );
};
