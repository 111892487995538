import { Box, Divider, Grid, Stack } from '@mui/material';
import EventInquiryForm from 'components/EventInquiryForm';
import GalleryModal from 'components/GalleryModal';
import ThrustCarbonScore from 'components/ThrustCarbonScore';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import VenueFeaturesV2 from 'components/VenueListing/VenueFeaturesV2';
import { DEFAULT_PREFERENCE_COLOR, ERankColors } from 'components/VenueTile/VenuePill';
import { SwipeableImageGallery } from 'pages/SwipeableImageGallery';
import { useMemo, useState } from 'react';
import { withIconStyles } from 'shared';
import styled from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { getVenueFeatures } from 'utils/getVenueFeatures';
import DinovaLogo from '../../images/icons/dinova-logo.png';
import LocationPinIcon from '../../images/icons/location_pin.svg?react';
import { useUser } from '../../providers/user';
import { Column, Copy, ExternalLink, RichTextCopy, Row } from '../../ui';
import VenueMap from '../VenueMap';
import { H2Headline } from '../ui/Headline';
import FeaturedSpaces from './FeaturedSpaces';
import VenueTitle from './VenueTitle';

const PreferenceNotesBox = styled(Column)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
    border-radius: 8px;

    overflow: hidden;
`;
const PreferenceNotesHeader = styled(Row)<{ asBadge: boolean }>`
    height: 60px;
    padding: 0 16px;
    box-sizing: border-box;

    ${({ asBadge }) => (asBadge ? 'border-radius: 8px;' : '')}

    justify-content: space-between;
    align-items: center;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.teamPreferenceNotesHeader)};
`;
const PreferenceNotes = styled(Column)`
    padding: 32px;

    white-space: pre-wrap;

    p {
        line-height: 1.47;
        margin-top: 8px;
        margin-bottom: 8px;

        &:first-child {
            margin-top: 0;
        }
    }
    strong {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.preferenceNotesHighlightedText)};
    }
`;

const PreferenceInfo = ({ venue }: { venue: Bizly.Venue }) => {
    const { user } = useUser();
    const teamLogo = useMemo(() => (user && user.team ? user.team.imageUrlWhite : ''), [user]);

    return (
        <PreferenceNotesBox>
            <PreferenceNotesHeader asBadge={!venue.preferenceNotes}>
                <WhiteCopy>{venue.preferenceCategory.name}</WhiteCopy>
                {teamLogo && <img src={teamLogo} alt={i18n.common.teamLogoAlt} style={{ height: 26 }} />}
            </PreferenceNotesHeader>
            {venue.preferenceNotes && (
                <PreferenceNotes
                    dangerouslySetInnerHTML={{
                        __html: venue.preferenceNotes,
                    }}
                />
            )}
        </PreferenceNotesBox>
    );
};

const SpacedItemsRow = styled(Row)<{ spacing: number }>`
    align-items: center;
    & > *:not(:last-child) {
        margin-right: ${props => props.spacing || 0}px;
    }
`;
const Neighborhood = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    margin-left: 41px;
`; // 41px matches icon + 12px

const WhiteCopy = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Summary = styled(RichTextCopy)`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const LocationPin = styled(withIconStyles(LocationPinIcon))`
    max-height: 18px;
`;

const FittedVenueMap = styled(VenueMap)`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Chip = styled(Copy)`
    font-size: 11px;
    font-weight: 500;
    line-height: 1.18;
    text-transform: uppercase;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    padding: 4px 12px;
    border-radius: 14px;
`;
const TempClosedChip = styled(Chip)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.cancelledOrRejectedStatus)};
`;
const PreferenceChip = styled(Chip)<{ rank: number }>`
    background-color: ${({ rank, theme: { getColor } }) =>
        getColor(ERankColors[('r' + rank) as keyof typeof ERankColors] || DEFAULT_PREFERENCE_COLOR)};
`;
const TagChip = styled(Chip)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.tagColor)};
`;
const RestaurantTagChip = styled(Chip)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.restaurantTagColor)};
`;

type VenueListingInfoV2Props = {
    venue: Bizly.Venue;
    spaces: Bizly.Space[];
    venueAction: React.ReactNode;
    hideTopNavControl?: boolean;
    showCreateEventModal?: () => void;
};

const VenueListingInfoV2 = ({
    venue,
    spaces,
    hideTopNavControl = false,
    venueAction,
    showCreateEventModal,
}: VenueListingInfoV2Props) => {
    const [spaceImages, setSpaceImages] = useState<Bizly.SpaceImage[]>([]);
    const center = useMemo(() => ({ lat: venue.lat, lng: venue.lng }), [venue.lat, venue.lng]);
    const hasSpaces = useMemo(() => spaces.length > 0, [spaces]);
    const hasPreferenceCategory = useMemo(
        () => venue.preferred && venue.preferenceCategory,
        [venue.preferred, venue.preferenceCategory]
    );
    const hasFeatures = useMemo(() => getVenueFeatures(venue).length > 0, [venue]);

    const hasVenueDetails =
        hasPreferenceCategory ||
        venue.tempClosed ||
        venue.primaryDecor ||
        venue.secondaryDecor ||
        venue.primaryCuisine ||
        venue.secondaryCuisine ||
        venue.qualityLevel ||
        venue.dinova ||
        venue.type;

    // 2 is restaurant
    const ChipComponent = useMemo(
        () => (venue.type && venue.type.id === 2 ? RestaurantTagChip : TagChip),
        [venue.type]
    );

    const handleOpenFullscreen = (space: Bizly.Space) => {
        setSpaceImages(space.images);
    };

    if (!venue.id) {
        return null;
    }

    return (
        <>
            <Spacer />
            <Grid container spacing={4}>
                <Grid item sm={7}>
                    <VenueTitle venue={venue} hideTopNavControl={hideTopNavControl} />
                    {venue.externalUrl && (
                        <>
                            <Spacer />
                            <Copy $color={EColors.darkerGrey}>
                                {i18n.venue.externalUrlHelper}:{' '}
                                <ExternalLink href={venue.externalUrl} openInNewTab>
                                    {venue.externalUrl}
                                </ExternalLink>
                            </Copy>
                        </>
                    )}

                    {hasVenueDetails && (
                        <Box>
                            <Spacer small />
                            <SpacedItemsRow spacing={12}>
                                {venue.tempClosed && <TempClosedChip>{i18n.venue.temporarilyClosed}</TempClosedChip>}
                                {hasPreferenceCategory && (
                                    <PreferenceChip rank={venue.preferenceCategory.rank}>
                                        {venue.preferenceCategory.name}
                                    </PreferenceChip>
                                )}
                                {venue.primaryDecor && <ChipComponent>{venue.primaryDecor.name}</ChipComponent>}
                                {venue.secondaryDecor && <ChipComponent>{venue.secondaryDecor.name}</ChipComponent>}
                                {venue.primaryCuisine && <ChipComponent>{venue.primaryCuisine.name}</ChipComponent>}
                                {venue.secondaryCuisine && <ChipComponent>{venue.secondaryCuisine.name}</ChipComponent>}
                                {venue.qualityLevel && <ChipComponent>{venue.qualityLevel}</ChipComponent>}
                                {venue.type && <ChipComponent>{venue.type.name}</ChipComponent>}
                                <Spacer />
                                {venue.dinova && <img src={DinovaLogo} style={{ height: 21 }} alt="dinova-logo" />}
                            </SpacedItemsRow>
                        </Box>
                    )}

                    {venue.summary && (
                        <Summary
                            dangerouslySetInnerHTML={{
                                __html: venue.summary,
                            }}
                        />
                    )}

                    {venue.emissionsReport?.tons && (
                        <>
                            <Spacer small />
                            <Box sx={{ maxWidth: '31rem' }}>
                                <ThrustCarbonScore
                                    infoText={i18n.venue.carbonInfo}
                                    score={venue.emissionsReport?.tons}
                                    equivalents={venue.emissionsReport?.equivalents}
                                />
                            </Box>
                        </>
                    )}
                </Grid>

                <Grid item sm={5}>
                    <EventInquiryForm venueAction={venueAction} showCreateEventModal={showCreateEventModal} />
                </Grid>
            </Grid>

            <Divider sx={{ marginBlock: 4 }} />

            <Grid container spacing={2}>
                {hasPreferenceCategory && (
                    <Grid item xs={12} sm={12}>
                        <PreferenceInfo venue={venue} />
                        <Spacer large />
                    </Grid>
                )}
                {hasFeatures && (
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <H2Headline color={EColors.accentedHeadline}>{i18n.venue.featureHeadline}</H2Headline>
                            <Spacer />
                            <VenueFeaturesV2 venue={venue} narrow={!!hasPreferenceCategory} />
                        </Box>
                    </Grid>
                )}
            </Grid>

            {hasSpaces && (
                <Box>
                    <Divider sx={{ marginTop: 5, marginBottom: 3 }} />
                    <FeaturedSpaces spaces={spaces} onImageClick={handleOpenFullscreen} />
                </Box>
            )}

            <Stack>
                <GalleryModal isOpen={spaceImages.length > 0} onClose={() => setSpaceImages([])}>
                    <SwipeableImageGallery images={spaceImages} />
                </GalleryModal>

                <Stack spacing={2}>
                    <Spacer />
                    <FittedVenueMap center={center} venues={[{ ...venue, isCenter: true }]} blackPins fullMapScale />
                    {venue.neighborhood && (
                        <>
                            <Spacer larger />
                            <Neighborhood>{venue.neighborhood}</Neighborhood>
                        </>
                    )}
                    <Stack direction="row">
                        <LocationPin />
                        <Copy regular $color={EColors.darkestGrey}>
                            {venue.address}
                        </Copy>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default VenueListingInfoV2;
