import { TAccommodation, TEventSpace, TScheduleDay } from 'components/Planner/types';
import { tzMoment } from 'utils/moment';

export const SCHEDULE = 'schedule';
export const EVENT_SPACES = 'eventSpaces';
export const ACCOMMODATIONS = 'accommodations';
export const VIRTUAL = 'virtualMeetings';

export const formatScheduleSummary = (schedule: TScheduleDay[] = []) =>
    schedule
        .filter(day => day.date)
        .map(day => day.date && tzMoment(day.date).format('lll'))
        .join(', ');

export const formatEventSpacesSummary = (eventSpaces?: TEventSpace[]) =>
    eventSpaces?.length && eventSpaces?.some(space => space.date)
        ? eventSpaces.filter(space => space.date).length.toString()
        : '';

export const formatAccommodationsSummary = (accommodations: TAccommodation[] = []) => {
    const days = accommodations?.length;
    const rooms = accommodations?.reduce((acc, curr) => acc + (curr.count || 0), 0);

    if (!days && !rooms) return '';
    if (days && rooms) return `${days} / ${rooms}`;
    if (days) return `${days} / 0`;
};

export const formatVirtualMeetingsSummary = (serviceProvider?: { id: number; name?: string }) => serviceProvider?.name;

const DAY_FORMAT = 'dddd';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const getStartEndDate = (startDate?: string, endDate?: string) => {
    if (!startDate || !endDate) return { startDate: '', endDate: '' };

    const formattedStartDate = tzMoment(startDate).format(DATE_FORMAT);
    const formattedEndDate = tzMoment(endDate).format(DATE_FORMAT);

    return { startDate: formattedStartDate, endDate: formattedEndDate };
};

export const generateDateRange = (start?: string, end?: string) => {
    if (!start || !end) return [];

    const { startDate, endDate } = getStartEndDate(start, end);

    const dates = [];
    const currentDate = tzMoment(startDate);
    const targetEndDate = tzMoment(endDate);

    while (currentDate.isBefore(targetEndDate)) {
        const formattedDate = currentDate.format(DATE_FORMAT);
        const dayOfWeek = currentDate.format(DAY_FORMAT);

        dates.push({ day: dayOfWeek, date: formattedDate, count: 0 });

        currentDate.add(1, 'day');
    }

    return dates;
};

export const addDayToAccommodations = <T extends TAccommodation>(accommodations: T[]) => {
    return accommodations.map(accommodation => {
        let dayOfWeek = '';
        if (accommodation?.date) {
            dayOfWeek = tzMoment(accommodation.date).clone().format(DAY_FORMAT);
        }

        return {
            ...accommodation,
            day: dayOfWeek,
        };
    });
};

export const removeDeletedAccommodations = <T extends TAccommodation>(accommodations: T[]) => {
    return accommodations.map(accommodation => {
        if (accommodation?.delete) {
            delete accommodation?.delete;
            return { ...accommodation };
        }
        return accommodation;
    });
};

export const generateNumbers = (maxLength: number) =>
    Array.from({ length: maxLength }, (_, i) => ({ id: i, value: i.toString(), name: i.toString() }));

export const mergeDates = (datesArr: TAccommodation[], dateRange: TAccommodation[]) => {
    if (datesArr[0].date === null) {
        // If the event is created from a template that has accommodation settings
        return datesArr.map((dateObj, index) => {
            if (dateRange[index]) {
                dateObj.date = dateRange[index].date;
                dateObj.day = dateRange[index].day;
            }
            return dateObj;
        });
    } else {
        return dateRange.map(dateObj => {
            const existingDate = datesArr.find(oldDateObj => oldDateObj.date === dateObj.date);
            if (existingDate) {
                dateObj.count = existingDate.count;
            }
            return dateObj;
        });
    }
};

export const generateNightsFromAccommodationNights = (accommodationNights = 0) => {
    const accommodations = [];

    for (let n = 0; n < accommodationNights; n++) {
        accommodations.push({
            day: undefined,
            date: undefined,
            count: 0,
        });
    }

    return accommodations;
};
