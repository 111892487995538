import Divider from '@material-ui/core/Divider';
import SurveySection from 'components/Communication/SurveySection';
import { RichTextEditor } from 'components/Form/fields';
import { OptionList } from 'components/OptionList';
import { PageHeadline } from 'components/ui/Headline';
import { Uploader } from 'components/Uploader';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { i18n } from 'translation';
import { Prompted, PromptedTextField, Spacer } from 'ui';
import { EditParcelState, TEventParcelContext } from '../EditParcel';
import { Centered, FormLabel, Subheader } from './styles';

const SurveyPage = ({
    context,
    handleChange,
    state,
    setState,
    handleUploadChange,
    hasQuestions,
    setHasQuestions,
}: {
    context: TEventParcelContext;
    handleChange: (value: any, field: string) => void;
    state: EditParcelState;
    setState: (state: EditParcelState) => void;
    handleUploadChange: (response: any) => void;
    hasQuestions: boolean;
    setHasQuestions: (hasQuestions: boolean) => void;
}) => {
    const { attendees, parcel, recipients } = state;

    const eventId = context.id;

    return (
        <Centered>
            <PageHeadline>{i18n.communication.noteSurvey}</PageHeadline>
            <Subheader>{i18n.communication.subHeaderNote}</Subheader>

            <Spacer />
            <Spacer />

            {parcel.sent && parcel.name && parcel.name !== parcel.subject && (
                <>
                    <PromptedTextField
                        onChange={e => handleChange(e.target.value, 'name')}
                        disabled
                        prompt={i18n.communication.editNoteSurvey}
                        value={parcel.name || ''}
                        variant="outlined"
                        optional
                    />
                    <Spacer />
                </>
            )}

            {!context.asPlaybookParcel && (
                <>
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.recipients}</FormLabel>
                    <OptionList
                        eventId={eventId}
                        disableAll={parcel.sent || !context.editable}
                        prompt={i18n.communication.recipientsPrompt}
                        onChange={(selection: number[]) => {
                            setState({ ...state, recipients: selection }); // We track recipients seperately from the parcel, to maintain an up to date list.
                            handleChange(selection, 'recipients');
                        }}
                        optionFormatter={(attendee: BizlyAPI.Attendee) =>
                            attendee.firstName && attendee.lastName
                                ? `${attendee.firstName} ${attendee.lastName} - ${attendee.email}`
                                : attendee.email
                        }
                        options={attendees}
                        optionsLabel={i18n.communication.attendees}
                        selected={parcel.recipients || recipients}
                    />
                    <Spacer />
                </>
            )}
            <PromptedTextField
                onChange={e => handleChange(e.target.value, 'subject')}
                disabled={parcel.sent || !context.editable}
                placeholder={i18n.communication.subjectPlaceholder}
                prompt={i18n.communication.subject}
                value={parcel.subject || ''}
                variant="outlined"
            />
            <Spacer />

            <Prompted prompt={i18n.communication.message}>
                <RichTextEditor
                    field="content"
                    disabled={parcel.sent || !context.editable}
                    placeholder={i18n.communication.messagePlaceholder}
                    value={parcel.content || ''}
                    onChange={e => handleChange(e.value, 'content')}
                />
            </Prompted>

            <Spacer />
            {(!parcel.sent || parcel.customImage) && (
                <>
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.image}</FormLabel>
                    <Uploader
                        disabled={parcel.sent || !context.editable}
                        ctaLabel={i18n.communication.addImage}
                        description={i18n.communication.imageUploadDescription}
                        onChange={(newValue: any) => {
                            handleChange(newValue?.url, 'customImageUrl');
                            handleChange(newValue, 'customImage');
                        }}
                        prompt={i18n.communication.imageUploadPrompt}
                        resource={isEmpty(parcel.customImage) ? null : parcel.customImage}
                    />
                    <Spacer />
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.addLogo}</FormLabel>
                    <Uploader
                        disabled={parcel.sent || !context.editable}
                        ctaLabel={i18n.communication.addLogo}
                        description={i18n.communication.logoUploadDescription}
                        onChange={(response: any) => handleUploadChange(response)}
                        prompt={i18n.communication.imageUploadPrompt}
                        resource={isEmpty(parcel.customLogo) ? null : parcel.customLogo}
                    />
                    <Spacer />
                </>
            )}

            <Divider />
            <SurveySection
                eventId={context.id}
                parcel={parcel}
                attendees={attendees}
                handleChange={handleChange}
                editable={!!context.editable}
                hasQuestions={hasQuestions}
                setHasQuestions={setHasQuestions}
            />
        </Centered>
    );
};

export default SurveyPage;
