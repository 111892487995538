import colorFns from 'colorFns';
import fontFns from 'fontFns';
import React from 'react';
import styled from 'styled-components';

type StyledInputWrapperProps = {
    label?: string;
    children: React.ReactNode;
};

const StyledInputWrapper = styled('div')<StyledInputWrapperProps>`
    display: flex;
    flex-direction: column;
`;

const StyledLabel = styled.label`
    margin: 10px 0 15px;
    font-size: 15px;
    line-height: 1;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
`;

const StyledInput = styled.input`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border: solid 1px ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 3px;
    height: 12px;
    outline: none;
    font-size: 15px;
    padding: 15px 16px 14px 15px;

    &:focus {
        box-shadow: 0 2px 4px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.06)};
    }

    ::placeholder {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    }

    :-ms-input-placeholder {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    }

    ::-ms-input-placeholder {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    }

    &[disabled] {
        color: ${colorFns.grey};
    }
`;

type InputWithLabelProps = Pick<React.HTMLProps<HTMLInputElement>, 'name'> & StyledInputWrapperProps;

const InputWithLabelWrapper = (props: InputWithLabelProps) => (
    <StyledInputWrapper>
        {props.label && <StyledLabel htmlFor={props.name ? props.name : undefined}>{props.label}</StyledLabel>}
        {props.children}
    </StyledInputWrapper>
);

const Input = React.forwardRef(
    (
        { label, name, as, ...props }: { label?: string; color?: Themed.Color } & React.HTMLProps<HTMLInputElement>,
        ref: any
    ) => {
        return (
            <InputWithLabelWrapper label={label} name={name}>
                <StyledInput ref={ref} {...props} />
            </InputWithLabelWrapper>
        );
    }
);

export default Input;
