import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import FileIcon from '../../images/icons/file.svg?react';
import { ExternalLink, RichTextCopy } from '../../ui';
import pluckFileName from '../../utils/pluckFileName';
import Button from '../ui/Button';
import StepsRow from './StepsRow';
import { useEvent } from 'providers/event';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

const Copy = styled(RichTextCopy)<any>`
    font-size: 15px;
    line-height: 1.47;

    ul {
        padding-inline-start: 1.2em;
    }
`;

const DocContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 10px;
`;

const DocRow = styled.div<{ hasDoc: boolean }>`
    width: 70%;
    border-radius: 8px;
    margin: 8px 25px;
    height: 80px;

    div {
        display: flex;
        align-items: center;
        margin-left: 16px;

        p {
            font-size: 15px;
        }

        svg {
            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryDisplayIcon)};
        }
    }

    ${({ hasDoc }) =>
        hasDoc &&
        `
    button {
      margin-right: 16px;
    }
  `}
`;

const H4Headline = styled.h4`
    font-weight: 500;
    margin: 0;
`;

const ButtonContainer = styled(Box)`
    width: 30%;
    display: flex;
    justify-content: center;
    height: 100px;
`;

type UploadSignedDocsProps = {
    number: number;
    openUploadModal: () => void;
    existingDocs: {
        contract1?: string;
        contract2?: string;
    };
    copy: string;
    readonly: boolean;
    documentOptional?: boolean;
};

const defaultCopy = i18n.venue.proposal.sign.defaultUploadCopy;

const UploadSignedDocs = forwardRef(
    (
        {
            number,
            existingDocs,
            copy = defaultCopy,
            readonly,
            documentOptional = false,
        }: UploadSignedDocsProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const navigate = useNavigate();
        const { event } = useEvent();
        const uploadedFiles = Object.values(existingDocs).filter(Boolean);

        let buttonText = "Upload on Spend Tracker";
        if (uploadedFiles.length === 1) {
            buttonText = "Upload / Replace";
        } else if (uploadedFiles.length > 1) {
            buttonText = i18n.button.replace;
        }

        return (
            <StepsRow number={number} headline={i18n.venue.proposal.sign.uploadHeadline} ref={ref}>
                <Copy dangerouslySetInnerHTML={{ __html: copy }} />
                {!documentOptional && (
                    <>
                        <DocContainer>
                            {!readonly && (
                                <ButtonContainer>
                                    <Button
                                        width="100%"
                                        onClick={() => navigate(`/event/${event.id}/spend-tracker?tab=estimate`)}
                                        style={{ marginTop: '10px', padding: '0 1em' }}
                                    >
                                        {buttonText}
                                    </Button>
                                </ButtonContainer>
                            )}
                            <DocRow hasDoc={uploadedFiles.length > 0}>
                                {uploadedFiles.length > 0 && <H4Headline>{i18n.venue.proposal.previouslyUploadedFiles}</H4Headline>}
                                {uploadedFiles.map((file, index) => (
                                    <div key={index}>
                                        <FileIcon />
                                        <ExternalLink href={file} openInNewTab>
                                            {pluckFileName(file)}
                                        </ExternalLink>
                                    </div>
                                ))}
                            </DocRow>
                        </DocContainer>
                    </>
                )}
            </StepsRow>
        );
    }
);

export default UploadSignedDocs;
