import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack, styled, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { Card } from 'components/Ui-V2/Card/Card';
import Image from 'components/Ui-V2/Image/Image';
import { REJECTED_STATUSES } from 'components/VenueCard';
import useThemedColor from 'hooks/useThemedColor';
import React, { useCallback, useMemo } from 'react';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { i18n } from 'translation';
import { Copy } from '../../ui';
import { DEFAULT_PREFERENCE_COLOR, ERankColors } from '../VenueTile/VenuePill';

type TTileCardProps = {
    width?: string;
    padding?: string;
    clickable?: boolean;
    centered?: boolean;
    highlighted?: boolean;
};

const TileCard = styled(Card)<TTileCardProps>(({ theme, width, clickable, centered, padding, highlighted }) => ({
    gap: theme.spacing(1.25),

    ...(centered
        ? {
              alignItems: 'center',
              justifyContent: 'center',
          }
        : {}),
    ...(clickable
        ? {
              cursor: 'pointer',
          }
        : {}),
    ...(width
        ? {
              width: width,
          }
        : {}),
    ...(padding
        ? {
              padding: padding,
          }
        : {}),
    ...(highlighted
        ? {
              border: `4px solid ${theme.getColor(theme.EColors.primaryAction)}`,
              cursor: 'pointer',
              transform: 'scale(1.05)',
          }
        : {}),
}));

const VenueName = styled('div')(({ theme: { spacing } }) => ({
    fontWeight: '500',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    lineHeight: spacing(2.5),
}));

const VenueHeadlineWrapper = styled(Stack)(({ theme }) => ({
    minHeight: theme.spacing(5),
}));

const VenueHeadline = styled('div')(({ theme: { getColor, EColors, spacing } }) => ({
    fontSize: 14,
    lineHeight: spacing(2.5),
    color: getColor(EColors.grey3),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: 0,
    gap: theme.spacing(0.5),
    '&:last-child': {
        paddingBottom: 0,
    },
}));

const Chip = styled(Copy)`
    font-size: 0.563rem;
    font-weight: 500;
    line-height: 1.18;
    text-transform: uppercase;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 0.25rem 0.5rem;
    border-radius: 0.875rem;
`;

const PreferencePill = styled(Chip)<{ rank: number }>`
    background-color: ${({ rank, theme: { getColor } }) =>
        getColor(ERankColors[('r' + rank) as keyof typeof ERankColors] || DEFAULT_PREFERENCE_COLOR)};
`;

const TempClosedPill = styled(Chip)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.cancelledOrRejectedStatus)};
`;

const PillContainer = styled(Box)`
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    display: flex;
    gap: 0.5rem;
    z-index: 1;
`;

export type VenueTileProps = {
    className?: string;
    venue: Bizly.Venue;
    width?: string;
    minWidth?: string;
    padding?: string;
    highlighted?: boolean;
    disabledToolTipMessage?: string;
    selectionDisabled?: boolean;
    disableRejected?: boolean;
    onClick?: (venueId: number) => void;
    onMouseOver?: (venueId: number) => void;
    onMouseLeave?: () => void;
    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
    selected?: boolean;
    enableView?: boolean;
    hideActions?: boolean;
};

const VenueTile = ({
    venue,
    highlighted,
    onMouseLeave,
    onMouseOver,
    onClick,
    onSelect,
    onDeselect,
    disableRejected,
    selectionDisabled,
    disabledToolTipMessage,
    enableView,
    width,
    padding,
    hideActions,
    selected,
}: VenueTileProps) => {
    const { primaryAction, lightGrey } = useThemedColor();
    const disabled = useMemo(
        () => disableRejected && REJECTED_STATUSES.has(venue.status),
        [disableRejected, venue.status]
    );

    const isSelectableAndVenueSelected = !selectionDisabled && selected;

    const handleClick = useCallback(() => {
        if ((enableView || !disabled) && onClick) {
            onClick(venue.id);
        }
    }, [enableView, onClick, disabled, venue.id]);

    const handleMouseOver = useCallback(
        () => !disabled && onMouseOver && onMouseOver(venue.id),
        [onMouseOver, venue.id, disabled]
    );

    const handleMouseLeave = useCallback(() => !disabled && onMouseLeave && onMouseLeave(), [onMouseLeave, disabled]);

    const onSelectVenue = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            if (disabled || selectionDisabled) {
                return;
            }
            onSelect && onSelect(venue);
        },
        [onSelect, venue, disabled, selectionDisabled]
    );

    const onDeselectVenue = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            if (!disabled && onDeselect) {
                onDeselect(venue);
            }
        },
        [onDeselect, venue, disabled]
    );

    const preferenceCategory = useMemo(() => {
        const NO_RANK = 0;

        if (!venue.preferenceCategory) {
            return {
                rank: NO_RANK,
                name: i18n.venue.preferred,
            };
        } else {
            return venue.preferenceCategory;
        }
    }, [venue.preferenceCategory]);

    const renderImageWithStatus = () => (
        <Box position="relative">
            <PillContainer>
                {venue.preferred && (
                    <PreferencePill rank={preferenceCategory.rank}>{preferenceCategory.name}</PreferencePill>
                )}
                {venue.tempClosed && <TempClosedPill>{i18n.venue.temporarilyClosed}</TempClosedPill>}
            </PillContainer>
            <Image
                height="9.375em"
                width="100%"
                src={venue.imageUrl.replace('image/upload/', 'image/upload/w_300,q_auto:best/')}
                alt={venue.name || 'Venue image'}
            />
        </Box>
    );

    const renderContent = () => (
        <StyledCardContent>
            <VenueName>{venue.name}</VenueName>
            <VenueHeadlineWrapper direction="row" justifyContent="space-between" spacing={1}>
                <VenueHeadline>{venue.headline ?? ''}</VenueHeadline>
                {!hideActions && (
                    <Stack justifyContent="flex-end">
                        {isSelectableAndVenueSelected ? (
                            <CheckCircleIcon htmlColor={primaryAction} onClick={onDeselectVenue} />
                        ) : (
                            <Tooltip title={disabledToolTipMessage}>
                                <AddCircleOutlineIcon
                                    htmlColor={selectionDisabled || disabled ? lightGrey : primaryAction}
                                    onClick={onSelectVenue}
                                />
                            </Tooltip>
                        )}
                    </Stack>
                )}
            </VenueHeadlineWrapper>
        </StyledCardContent>
    );

    const cardContent = (
        <>
            {renderImageWithStatus()}
            {renderContent()}
        </>
    );

    const CardComponent = (
        <TileCard
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            width={width}
            padding={padding}
            highlighted={highlighted}
        >
            {cardContent}
        </TileCard>
    );

    return (
        <>
            {highlighted ? (
                <ScrollIntoViewIfNeeded
                    options={{
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                        scrollMode: 'if-needed',
                    }}
                >
                    {CardComponent}
                </ScrollIntoViewIfNeeded>
            ) : (
                CardComponent
            )}
        </>
    );
};

export default VenueTile;
