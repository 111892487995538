import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { newsLetterSubscribe } from 'api/newsLetterSubscribe';
import React, { useEffect, useRef, useState } from 'react';
import { emailIsValid } from 'utils';

const ModalContent = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    '&:focus-visible': {
        outline: 'none',
    },
}));

const SuccessMessage = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme: { getColor, EColors }, theme }) => ({
    marginTop: theme.spacing(2),
    minHeight: 36,
    backgroundColor: getColor(EColors.bizlyOSPrimary),
    '&:hover': {
        backgroundColor: getColor(EColors.bizlyOSPrimary),
        opacity: 0.9,
    },
}));

interface NewsletterSignUpProps {
    open: boolean;
    handleClose: () => void;
    title: string;
    description: string;
    productName: string;
    buttonText?: string;
}

export const NewsletterSignUp: React.FC<NewsletterSignUpProps> = ({
    open,
    handleClose,
    title,
    description,
    productName,
    buttonText = 'Sign me up!',
}) => {
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (open) {
            setEmail('');
            setEmailError('');
            setIsEmailValid(false);
            setIsSubmitting(false);
            setIsSubmitted(false);
            setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
        }
    }, [open]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setEmailError('');
        setIsEmailValid(emailIsValid(newEmail));
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isEmailValid && !isSubmitting) {
            setIsSubmitting(true);
            try {
                await newsLetterSubscribe(email);
                setIsSubmitted(true);
            } catch (error) {
                console.error('Newsletter signup error:', error);
                setEmailError('An error occurred. Please try again.');
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setEmailError('Please enter a valid email address');
        }
    };

    return (
        <Modal open={open} onClose={isSubmitting ? undefined : handleClose}>
            <ModalContent>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    disabled={isSubmitting}
                >
                    <CloseIcon />
                </IconButton>
                {!isSubmitted ? (
                    <>
                        <Typography variant="h6" component="h2" gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {description}
                        </Typography>
                        <form onSubmit={onSubmit}>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={handleEmailChange}
                                margin="normal"
                                required
                                type="email"
                                error={!!emailError}
                                helperText={emailError}
                                inputRef={inputRef}
                                disabled={isSubmitting}
                            />
                            <StyledButton
                                type="submit"
                                variant="contained"
                                fullWidth
                                disabled={!isEmailValid || isSubmitting}
                            >
                                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : buttonText}
                            </StyledButton>
                        </form>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            By joining the beta list, you agree to our Terms of Service and Privacy Policy.
                        </Typography>
                    </>
                ) : (
                    <SuccessMessage>
                        <CheckCircleOutlineIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
                        <Typography variant="h6" gutterBottom>
                            Thank you for signing up!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We've added {email} to our beta list. We'll notify you as soon as Bizly {productName} is
                            ready for you to try.
                        </Typography>
                        <StyledButton variant="contained" onClick={handleClose}>
                            Close
                        </StyledButton>
                    </SuccessMessage>
                )}
            </ModalContent>
        </Modal>
    );
};

export default NewsletterSignUp;
