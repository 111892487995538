import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { searchVenues } from './venues';

export default class VenueSearchFetcher {
    fetcherId: string;
    private eventId: string | number | undefined;
    private facets: BizlyAPI.VenueFacets;
    private perPage: number;

    private fetchedPreferred = false;
    private fetchedNonPreferred = false;
    private curPage = 1;
    private venues: Bizly.Venue[] = [];
    private nonPreferredPage = 1;

    constructor(
        eventId: string | number | undefined,
        { facets = {}, perPage = 10 }: Partial<{ facets: BizlyAPI.VenueFacets; perPage: number }>
    ) {
        this.eventId = eventId;
        this.facets = facets;
        this.perPage = perPage;
        this.fetcherId = Math.random().toString(36).slice(2, 7);

        // ignore non-preferred if search is only for preferred
        if (facets && facets.preferredOnly === true) {
            this.fetchedNonPreferred = true;
        }
    }

    hasMore = () => {
        const { fetchedPreferred, fetchedNonPreferred, venues, curPage, perPage } = this;

        return !fetchedPreferred || !fetchedNonPreferred || !!venues[(curPage - 1) * perPage];
    };

    getNextPage = (() => {
        let currentPromise: Promise<Bizly.Venue[]> | null = null;

        return () => {
            if (currentPromise) {
                return currentPromise;
            }

            currentPromise = this.fetchNextPage().then(venues => {
                currentPromise = null;
                return venues;
            });
            return currentPromise;
        };
    })();

    private fetchNextPage = async () => {
        // try preferred first
        if (!this.fetchedPreferred) {
            await this.fetchPreferred();
        }

        let page = this.venues.slice((this.curPage - 1) * this.perPage, this.curPage * this.perPage);
        // can fulfill?
        if (page.length === this.perPage) {
            this.curPage++;
            return page;
        }

        // need more?
        if (!this.fetchedNonPreferred) {
            await this.fetchNonPreferred();
        }

        page = this.venues.slice((this.curPage - 1) * this.perPage, this.curPage * this.perPage);
        this.curPage++;
        return page;
    };

    private appendNewVenues(newVenues: Bizly.Venue[]) {
        this.venues = this.venues.concat(newVenues);
    }

    // helper to search easily
    private search = async ({
        preferredOnly,
        perPage,
        page,
    }: {
        preferredOnly?: boolean;
        perPage?: number;
        page: number;
    }) =>
        await searchVenues(
            this.eventId,
            { ...this.facets, preferredOnly },
            {
                page,
                perPage: perPage || this.perPage,
            }
        );

    private fetchPreferred = async () => {
        // probe:
        const { venues, meta } = await this.search({
            preferredOnly: true,
            perPage: this.perPage,
            page: 1,
        });

        let newVenues: Bizly.Venue[] = [...venues];

        // otherwise, batch pull:
        if (meta.hasMorePages) {
            const pages = range(2, meta.total / this.perPage + 1); // skip the first page

            const allVenues: Bizly.Venue[][] = await Promise.all(
                pages.map(async page => {
                    const { venues } = await this.search({
                        preferredOnly: true,
                        perPage: this.perPage,
                        page,
                    });
                    return venues;
                })
            );

            // lodash sortBy is stable, the results are already sorted by distance,
            // so we shouldn't disturb distance order when stratifying by rank
            // Infinity will place rankless or regular venues at the bottom
            newVenues = sortBy([...newVenues, ...allVenues.flat()], v => v.preferenceCategory?.rank || Infinity);
        }

        this.fetchedPreferred = true;
        this.appendNewVenues(newVenues);
        return this.venues;
    };

    private fetchNonPreferred = (() => {
        return async () => {
            const { venues, meta } = await this.search({
                perPage: this.perPage,
                page: this.nonPreferredPage,
            });

            this.nonPreferredPage++;

            if (!meta.hasMorePages) {
                this.fetchedNonPreferred = true;
            }

            this.appendNewVenues(venues);
            return this.venues;
        };
    })();
}
