import { get, post, put } from 'api';
import moment from 'moment-timezone';

export type SearchMeetingsResponse = {
    meetings: BizlyAPI.Meeting[];
    searchMeta: { lastPage: number };
};

export const getMeetings = (startOfWeek?: Date, limit: number = 100): Promise<{ meetings: BizlyAPI.Meeting[] }> =>
    startOfWeek
        ? get(`meetings?date_start=${moment(startOfWeek).format('YYYY-MM-DD')}&limit=${limit}`)
        : get(`meetings?unscheduled_only&limit=${limit}`);

export const getActiveMeetings = (
    limit: number = 100,
    orderBy: BizlyAPI.MeetingSort | undefined = undefined
): Promise<{ meetings: BizlyAPI.Meeting[] }> =>
    get(
        `meetings?ends_after=${moment().add('days', -1).format('YYYY-MM-DD')}&limit=${limit}${
            orderBy ? `&order_by=${orderBy}` : ''
        }`
    );

export const getMeetingsPageBySchedule = (
    page: number = 1,
    limit: number = 100,
    scheduled = true
): Promise<{ meetings: BizlyAPI.Meeting[] }> =>
    get(`meetings?page=${page}&limit=${limit}${scheduled ? `&scheduled_only` : '&unscheduled_only'}`);

export const getMeetingsPageBySort = (
    page: number = 1,
    limit: number = 100,
    scheduled = true,
    orderBy: string = ''
): Promise<{ meetings: BizlyAPI.Meeting[] }> =>
    get(
        `meetings?page=${page}&limit=${limit}${scheduled ? `&scheduled_only` : '&unscheduled_only'}${
            orderBy ? `&order_by=${orderBy}` : ''
        }`
    );

export const getMeetingsPageAll = (
    page: number = 1,
    limit: number = 100,
    orderBy: string = ''
): Promise<{ meetings: BizlyAPI.Meeting[] }> =>
    get(`meetings?page=${page}&limit=${limit}${orderBy ? `&order_by=${orderBy}` : ''}`);

export type APISort = 'starts_at_utc' | 'updated_at' | 'status_rank' | 'name' | 'planner_name' | undefined;

export const searchMeetings = (
    query: string,
    page: number = 1,
    limit: number = 100,
    orderBy?: 'starts_at_utc' | 'updated_at' | 'status_rank' | 'name' | 'planner_name'
): Promise<SearchMeetingsResponse> =>
    get(`meetings/search?q=${query}&page=${page}&per_page=${limit}${orderBy ? `&order_by=${orderBy}` : ''}`);

export const getMeeting = (id?: string | number): Promise<{ meeting: BizlyAPI.Meeting }> => get(`meetings/${id}`);

export const createMeeting = (data: BizlyAPI.MeetingCreate): Promise<{ meeting: BizlyAPI.Meeting }> =>
    post(`meetings`, data);

export const updateMeeting = (data: BizlyAPI.MeetingUpdate): Promise<{ meeting: BizlyAPI.Meeting }> =>
    put(`meetings/${data.id}`, data);

export const publishMeeting = ({
    id,
    ...data
}: {
    id: number | string;
    inviteType: 'registration' | 'calendar' | 'none';
    inviteImageUrl?: string | null;
    inviteDescription?: string | null;
}): Promise<{ meeting: BizlyAPI.Meeting }> => post(`meetings/${id}/publish`, data);

export const cancelMeeting = ({
    id,
    ...data
}: {
    id: number | string;
    notes?: string;
}): Promise<{ meeting: BizlyAPI.Meeting }> => post(`meetings/${id}/cancel`, data);
