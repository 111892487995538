import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { createPlaybookActions } from './store';

export default function LoadPlaybook({ id }: { id?: string | number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const loadOnce = async () => {
            if (id) {
                try {
                    await createPlaybookActions.load(id);
                } catch (e) {
                    enqueueSnackbar(i18n.error.default, { variant: 'error' });
                }
            }
        };
        loadOnce();
    }, [id, enqueueSnackbar]);

    return null;
}
