import { TPlannerForm } from 'components/Planner/types';
import { get, put } from '.';

export const getEventPlannerData = (eventId: number | string) =>
    get(`events/${eventId}/planner`) as Promise<{ planner: BizlyAPI.Planner }>;

export const updateEventPlannerData = (eventId: number | string, data: TPlannerForm) =>
    put(`events/${eventId}/planner`, data) as Promise<{ planner: BizlyAPI.Planner }>;

export const getPlaybookPlannerData = (playbookId: number | string) =>
    get(`playbooks/${playbookId}/planner`) as Promise<{ planner: BizlyAPI.Planner }>;

export const updatePlaybookPlannerData = (playbookId: number | string, data: TPlannerForm) =>
    put(`playbooks/${playbookId}/planner`, data) as Promise<{ planner: BizlyAPI.Planner }>;
