import { loadEventOptions } from 'api';
import RightDrawer from 'components/Drawer/RightDrawer';
import { getAttendeeRoomBlockErrorMessge } from 'components/RSVP/utils';
import Button, { ContentWidthButton } from 'components/ui/Button';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import React, { useCallback, useMemo } from 'react';
import { AttendeeStatus, statusColors, statusMap } from 'shared';
import { i18n } from 'translation';
import { useAsync } from '../../hooks/useAsync';
import { Column, Copy, Row, StatusChip } from '../../ui';
import AttendeeForm from './AttendeeForm';

type TAttendeeDrawer = {
    attendee: Bizly.EventAttendee;
    onDismiss: () => void;
    onSave: (attendee: Bizly.EventAttendee) => void;
    onRemove: (id: number) => void;
    eventId: number;
};

export const AttendeeDrawer = ({ attendee, onDismiss, onSave: onSaveProp, eventId, onRemove }: TAttendeeDrawer) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isEditing, setIsEditing] = React.useState(false);
    const [draft, setDraft] = React.useState<Bizly.EventAttendee>(attendee);

    const { user } = useUser();

    const { data: eventOptions } = useAsync(React.useCallback(() => loadEventOptions(eventId), [eventId]));

    const { status, email, phone } = draft;

    const onSave = useCallback(
        (draft: Bizly.EventAttendee) => {
            const accommodationError = getAttendeeRoomBlockErrorMessge(draft);
            if (accommodationError) {
                enqueueSnackbar(accommodationError, { variant: 'error' });
                return;
            }

            if (
                (draft.status === AttendeeStatus.notInvited ||
                    draft.status === AttendeeStatus.notSent ||
                    draft.status === AttendeeStatus.notAttending) &&
                (draft.checkInDate || draft.checkOutDate || draft.preferredRoomType)
            ) {
                enqueueSnackbar(i18n.meetingDashboard.guestListSection.invalidRoomBlock, {
                    variant: 'info',
                });
            }

            onSaveProp(draft);
        },
        [onSaveProp, enqueueSnackbar]
    );

    const onCancelEdit = useCallback(() => {
        setDraft(attendee);
        setIsEditing(false);
    }, [attendee]);

    const readableStatus = statusMap[status];
    const readableStatusColor = statusColors[status];

    const footer = useMemo(
        () =>
            isEditing ? (
                <Row itemSpacing="smallish">
                    <Button secondary variant="outlined" onClick={onCancelEdit}>
                        {i18n.button.cancel}
                    </Button>
                    <ContentWidthButton onClick={() => onSave(draft)}>{i18n.button.saveChanges}</ContentWidthButton>
                </Row>
            ) : (
                <Row itemSpacing="smallish">
                    <ContentWidthButton warning onClick={() => onRemove(draft.id)}>
                        {i18n.meetingDashboard.guestListSection.deleteGuest}
                    </ContentWidthButton>
                    <Button secondary variant="outlined" onClick={() => setIsEditing(true)}>
                        {i18n.button.edit}
                    </Button>
                </Row>
            ),
        [isEditing, draft, onSave, onRemove, onCancelEdit]
    );

    return (
        <RightDrawer
            drawerOpen={!!attendee}
            onClose={onDismiss}
            title={i18n.meetingDashboard.guestListSection.guestDetails}
            footer={footer}
        >
            {isEditing ? (
                <AttendeeForm
                    attendee={draft}
                    eventOptions={eventOptions || {}}
                    externalIdFieldLabel={user.team?.externalIdFieldLabel}
                    onChange={newVal => {
                        setDraft(newVal);
                    }}
                />
            ) : (
                <Column itemSpacing="larger">
                    <Column itemSpacing="small">
                        {readableStatus && readableStatusColor && (
                            <StatusChip
                                style={{ width: 124 }}
                                label={readableStatus}
                                statusColor={readableStatusColor}
                            />
                        )}
                        {email && <Copy>{email}</Copy>}
                        {phone && <Copy>{phone}</Copy>}
                    </Column>
                    <AttendeeForm attendee={attendee} eventOptions={eventOptions || {}} readonly />
                </Column>
            )}
        </RightDrawer>
    );
};

export default AttendeeDrawer;
