import colorFns from 'colorFns';
import React from 'react';
import { OnlySingleLine } from 'shared';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';
import { InlineRow } from 'ui';

export const DEFAULT_PREFERENCE_COLOR = EColors.preferredVenue;

const Label = styled.div``;

// TODO: Implement this UI pill throughout the app where we repeat this theme
const StyledPill = styled.div<{
    backgroundColor?: Themed.Color;
    hoverColor?: Themed.Color;
    size?: string;
    maxWidth?: string;
    noShadow?: boolean;
    centerText?: boolean;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    squared?: boolean;
    onClick?: () => void;
}>`
    ${({ size }) => `
        font-size: ${size === 'small' ? 9 : 11}px;
        max-height: ${size === 'small' ? 9 : 11}px;
        padding: ${size === 'small' ? '4px 8px' : '4px 12px'};
    `}

    font-weight: 500;
    line-height: ${({ centerText }) => (centerText ? '1' : '1.18')};
    text-transform: uppercase;

    max-width: 100%;
    ${Label} {
        ${OnlySingleLine};
    }

    color: ${({ color, theme: { getColor, EColors } }) => color || getColor(EColors.pureWhite)};
    background-color: ${props => props.backgroundColor || DEFAULT_PREFERENCE_COLOR};

    transition: all 0.15s ease-out;

    &:hover {
        ${({ hoverColor }) => (hoverColor ? `background-color: ${hoverColor};` : '')}
    }

    ${({ startAdornment }) => startAdornment && `padding-left: 4px;`}
    ${({ endAdornment }) => endAdornment && `padding-right: 4px;`}
    ${({ squared }) => (squared ? `border-radius: 4px;` : `border-radius: 100px;`)}
    ${({ noShadow, ...props }) => (noShadow ? '' : `box-shadow: 0 2px 10px 0 ${colorFns.pureBlack.alpha(0.3)(props)}`)};
    ${({ onClick }) => onClick && `cursor: pointer;`}
`;

type PillTypes = {
    hoverColor?: Themed.Color;
    label: React.ReactNode;
    size?: string;
    noShadow?: boolean;
    centerText?: boolean;
    squared?: boolean;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    onClick?: () => void;
};

const Pill = React.forwardRef<
    HTMLDivElement,
    (({ color: Themed.Color; $color?: undefined } & PillTypes) | ({ color?: undefined; $color: EColors } & PillTypes)) &
        Pick<
            React.HTMLAttributes<HTMLDivElement>,
            | 'onBlur'
            | 'onFocus'
            | 'onMouseOver'
            | 'onMouseLeave'
            | 'onTouchStart'
            | 'onTouchEnd'
            | 'title'
            | 'className'
        >
>((props, ref) => {
    const {
        color,
        $color,
        hoverColor,
        label,
        size,
        noShadow,
        centerText,
        squared,
        onClick,
        startAdornment,
        endAdornment,
        ...rest
    } = props;
    return (
        <StyledPill
            backgroundColor={props.$color ? getColor(props.$color) : props.color}
            hoverColor={hoverColor}
            noShadow={noShadow}
            centerText={centerText}
            ref={ref}
            {...rest}
            onClick={onClick}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            size={size}
            squared={squared}
        >
            <InlineRow itemSpacing="xsmall">
                {startAdornment}
                <Label>{label}</Label>
                {endAdornment}
            </InlineRow>
        </StyledPill>
    );
});

export default Pill;
