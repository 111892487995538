import Form from 'components/Form';
import { TPlannerDataChange, TPlannerSections, TScheduleDay } from 'components/Planner/types';
import { SCHEDULE } from 'components/Planner/utils';
import Button from 'components/ui/Button';
import LongButton from 'components/ui/Button/LongButton';
import TrashIcon from 'images/icons/trash-can.svg?react';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Copy, Column as UIColumn } from 'ui';
import { scheduleFields, scheduleSchema } from './plannerFormSchema';

const Column = styled(UIColumn)`
    width: 100%;
`;

const DayCard = styled(UIColumn)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;
    padding: 16px;
`;

const SaveButton = styled(Button)`
    align-self: flex-end;
    margin-top: 24px;
`;

const AddDayCTA = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    font-size: 16px;
    font-weight: 500;
`;

const RemoveDayButton = styled(withInteractibleIconStyles(TrashIcon))`
    position: absolute;
    align-self: flex-end;
`;

type TSchedUpdate = TPlannerDataChange & { value: TScheduleDay };

type TScheduleDetailsProps = {
    editable: boolean;
    schedule: TScheduleDay[];
    addScheduleDay: (place: 'before' | 'after') => void;
    removeScheduleDay: (idx: number, targetSection: TPlannerSections) => void;
    setScheduleData: (schedFormData: TSchedUpdate, idx: number, targetSection: TPlannerSections) => void;
    loading: boolean;
    onSave: () => void;
};

const ScheduleDetails = ({
    editable,
    schedule,
    addScheduleDay,
    removeScheduleDay,
    setScheduleData,
    loading,
    onSave,
}: TScheduleDetailsProps) => {
    return (
        <Column itemSpacing="small">
            {editable && !!schedule?.length && (
                <LongButton onClick={() => addScheduleDay('before')} isSecondary>
                    <AddDayCTA>{i18n.venue.planner.addDayBefore}</AddDayCTA>
                </LongButton>
            )}

            {!!schedule.length &&
                schedule
                    .map((day, originIndex) => ({ ...day, originIndex }))
                    .filter(day => !day.delete)
                    .map((day, idx, filteredSchedule) => (
                        <div key={day.id || `day-${idx}`}>
                            <DayCard>
                                <Form
                                    fields={scheduleFields}
                                    schema={scheduleSchema}
                                    value={day}
                                    onChange={(e: TSchedUpdate) => setScheduleData(e, day.originIndex, SCHEDULE)}
                                    disabled={!editable}
                                />
                                {editable && filteredSchedule.length > 1 && (
                                    <RemoveDayButton onClick={() => removeScheduleDay(day.originIndex, SCHEDULE)} />
                                )}
                            </DayCard>
                        </div>
                    ))}

            {editable && (
                <LongButton onClick={() => addScheduleDay('after')} isSecondary>
                    <AddDayCTA>{i18n.venue.planner.addDay}</AddDayCTA>
                </LongButton>
            )}

            {editable && (
                <SaveButton onClick={onSave} disabled={loading} small width={68}>
                    {i18n.button.save}
                </SaveButton>
            )}
        </Column>
    );
};

export default ScheduleDetails;
