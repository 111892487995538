import { useCallback, useState } from 'react';

const useShowModal = (initialVisibility = false) => {
    const [visible, setVisible] = useState(initialVisibility);
    const showModal = useCallback(() => {
        setVisible(true);
    }, [setVisible]);
    const hideModal = useCallback(() => {
        setVisible(false);
    }, [setVisible]);

    return { modalShown: visible, showModal, hideModal };
};

export default useShowModal;
