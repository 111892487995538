import OutlinedInput from '@mui/material/OutlinedInput';
import { i18n } from 'translation';
import { Description, StepContainer, Title } from './styled';

type PropType = {
    value: number;
    onChange: (value: number) => void;
};

const Step5 = ({ value, onChange }: PropType) => {
    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.guestsTitle}</Title>
            <Description>{i18n.homepage.createMeetingModal.numberDescription}</Description>
            <OutlinedInput
                id="event-guest-number"
                type="number"
                value={String(value ?? 0)}
                onChange={e => onChange(Number(e.target.value))}
                sx={{ width: '100%' }}
            />
        </StepContainer>
    );
};

export default Step5;
