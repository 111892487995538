import {
    addCollaborators as addCollaboratorsAPI,
    isAddCollaboratorsPartialError,
    removeCollaborator as removeCollaboratorApi,
    updateCollaboratorPermission as updateCollaboratorPermissionApi,
} from 'api/collaborators';
import React from 'react';

type TEventCollaboratorsContext =
    | {
          collaborators: BizlyAPI.EventCollaboratorOrPending[];
          removeCollaborator: (collaboratorId: number) => Promise<void>;
          updateCollaboratorPermission: (collaboratorId: number, edit: boolean) => Promise<void>;
          addCollaborators: (newCollaborators: BizlyAPI.NewEventCollaborator[]) => Promise<void>;
          isAddCollaboratorsPartialError: typeof isAddCollaboratorsPartialError;
          isEditor: (user: Bizly.User) => boolean;
      }
    | undefined;

const EventCollaboratorsContext = React.createContext<TEventCollaboratorsContext>(undefined);

const PENDING_ADD_COLLABORATOR_ID = -1;
export const collaboratorIsPendingAdd = (collaborator: Pick<BizlyAPI.EventCollaboratorOrPending, 'id'>) =>
    collaborator.id === PENDING_ADD_COLLABORATOR_ID;

export default function EventCollaboratorsProvider({
    children,
    onChange,
    collaborators,
    eventId,
}: {
    children: React.ReactNode;
    onChange: (collaborators: React.SetStateAction<BizlyAPI.EventCollaboratorOrPending[]>) => void;
    collaborators: BizlyAPI.EventCollaboratorOrPending[];
    eventId: number;
}) {
    async function removeCollaborator(collaboratorId: number) {
        if (collaboratorId === PENDING_ADD_COLLABORATOR_ID) return;
        const collaboratorToRemove = collaborators.find(collaborator => collaborator.id === collaboratorId);

        onChange(collaborators => collaborators.filter(collaborator => collaboratorId !== collaborator.id));

        try {
            await removeCollaboratorApi(eventId, collaboratorId);
        } catch (error) {
            if (collaboratorToRemove) {
                onChange(collaborators => [...collaborators, collaboratorToRemove]);
            }

            throw error;
        }
    }

    const updateCollaboratorPermission = async (collaboratorId: number, edit: boolean) => {
        try {
            await updateCollaboratorPermissionApi(eventId, collaboratorId, edit);
            onChange(collaborators =>
                collaborators.map(c => {
                    if (c.id === collaboratorId) {
                        return {
                            ...c,
                            editor: edit,
                        };
                    }
                    return c;
                })
            );
        } catch (e) {
            throw e;
        }
    };

    const addCollaborators = async (newCollaborators: BizlyAPI.NewEventCollaborator[]) => {
        const currentCollaborators = collaborators;
        const currentEmails = new Set(currentCollaborators.map(c => c.email));

        const newAdditions = newCollaborators.filter(c => !currentEmails.has(c.email));

        const nextCollaborators = [
            ...currentCollaborators,
            ...newAdditions.map(c => ({ ...c, id: PENDING_ADD_COLLABORATOR_ID })),
        ];
        onChange(nextCollaborators);

        // The API returns with the event's collaborators, we replace the array wholesale, not worrying about partial failures
        try {
            const result = await addCollaboratorsAPI(newAdditions, eventId);
            // We only replace if no one else has attempted to optimistically update again collaborators by this point
            onChange(prevCollaborators =>
                prevCollaborators === nextCollaborators ? result.collaborators : prevCollaborators
            );
        } catch (e) {
            const allNewEmails = newAdditions.map(collaborator => collaborator.email);

            onChange(prevCollaborators => {
                if (isAddCollaboratorsPartialError(e)) {
                    // We only replace if no one else has attempted to optimistically update again collaborators by this point
                    return prevCollaborators === nextCollaborators ? e.raw.collaborators : prevCollaborators;
                } else {
                    const failedEmailsSet = new Set(allNewEmails);
                    // filter out our changes, we don't just revert because if there was another optimistic update, we don't want to lose those
                    return prevCollaborators.filter(collaborator => !failedEmailsSet.has(collaborator.email));
                }
            });

            throw e;
        }
    };

    const isEditor = (user: Bizly.User) => {
        const isCollaborator = collaborators.find(collaborator => collaborator.userId === user.id);
        return (isCollaborator && isCollaborator.editor) ?? false;
    };

    return (
        <EventCollaboratorsContext.Provider
            value={{
                collaborators,
                removeCollaborator,
                updateCollaboratorPermission,
                addCollaborators,
                isAddCollaboratorsPartialError,
                isEditor,
            }}
        >
            {children}
        </EventCollaboratorsContext.Provider>
    );
}

export function useEventCollaborators() {
    const context = React.useContext(EventCollaboratorsContext);
    if (context === undefined) {
        throw new Error('useEventCollaborators must be used within a EventProvider');
    }
    return context;
}
