import { Tooltip } from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { Button } from 'components/Ui-V2/Button/Button';
import { TabLabels } from 'constants/venueStatus';
import styled from 'styled-components';
import { i18n } from 'translation';
import { HEADER_HEIGHT } from 'utils/header';
import { tzMoment } from 'utils/moment';
import { BaseVenueTile, VenueTileProps } from './VenueTile';

const TileWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    padding: 8px;
    height: 100%;

    position: sticky;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    top: ${HEADER_HEIGHT}px;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const TileButtons = styled.div<{ rowReverse?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
    flex-direction: column;

    @media (min-width: 1490px) {
        flex-direction: row;
    }

    ${({ rowReverse }) =>
        rowReverse &&
        `
            flex-direction: row-reverse;
  
        `}

    & > .MuiButton-root:only-child {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }

    & > .MuiButton-root {
        margin-right: 0;
        flex: 1;
        &.MuiButton-containedPrimary {
            margin-right: 0;
        }
    }

    & > .MuiButton-root:nth-child(2) {
        background-color: ${({ theme: { getColor, EColors }, rowReverse }) =>
            rowReverse ? getColor(EColors.pureWhite) : getColor(EColors.primaryAction)};
        color: ${({ theme: { getColor, EColors }, rowReverse }) =>
            rowReverse ? getColor(EColors.primaryAction) : getColor(EColors.pureWhite)};
    }
`;

type TInquiryButton = { label: string; handler: () => void };

type TActions = {
    tabLabel: TabLabels;
    inquiryVenue?: BizlyAPI.Inquiry.Venue & { submittedAt?: string };
    viewInquiry: () => void;
    onNudge?: () => void;
    inquiryButton?: TInquiryButton;
};

type InquiryVenueTileProps = VenueTileProps & TActions;

const InquiryVenueTile = (props: InquiryVenueTileProps) => {
    const { inquiryVenue, viewInquiry, onNudge, inquiryButton, ...tileProps } = props;
    const tooltipContent = inquiryVenue?.nudgedAt
        ? i18n.venue.inquiry.lastNudged(tzMoment(inquiryVenue.nudgedAt).fromNow())
        : i18n.venue.inquiry.nudgeDescription;

    let nudgeDisabled = false;
    let showNudge = false;
    let shouldRowReverse = false;
    const venueStatus = tileProps.venue?.status;
    const hasMenuOptions = inquiryButton ? Object.keys(inquiryButton).length > 0 : false;

    if (venueStatus === 'Submitted') {
        const submittedAt = tzMoment(inquiryVenue?.submittedAt);
        showNudge = submittedAt.add(24, 'h').isBefore(tzMoment()); // check if the submission time is BEFORE 24 hours ago. If yes, "showNudge" should be false.
        const lastNudgedAt = tzMoment(inquiryVenue?.nudgedAt || inquiryVenue?.submittedAt);
        nudgeDisabled = lastNudgedAt.add(24, 'h').isAfter(tzMoment()); // check if the last nudge is BEFORE 24 hours ago. If yes, "nudgeDisabled" should be true.
        shouldRowReverse = showNudge;
    }

    return (
        <TileWrapper className="sticky">
            <BaseVenueTile {...tileProps} hideButtons />
            <TileButtons rowReverse={shouldRowReverse}>
                <Button variant={shouldRowReverse ? 'contained' : 'outlined'} onClick={viewInquiry}>
                    {i18n.venue.inquiry.viewInquiry}
                </Button>
                {showNudge ? (
                    <Tooltip title={tooltipContent} placement="bottom">
                        <Button
                            variant="outlined"
                            endIcon={<CallMadeIcon />}
                            disabled={nudgeDisabled}
                            onClick={onNudge}
                        >
                            {i18n.venue.inquiry.nudge}
                        </Button>
                    </Tooltip>
                ) : hasMenuOptions ? (
                    <Button onClick={inquiryButton?.handler} variant="contained">
                        {inquiryButton?.label}
                    </Button>
                ) : null}
            </TileButtons>
        </TileWrapper>
    );
};

export default InquiryVenueTile;
