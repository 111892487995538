import { getSurveyInfo, questionDefinitionIds, respondToSurvey } from 'api';
import { isEmptyString } from 'components/ProposalForm/utils';
import SurveyCard from 'components/RSVP/RSVPCard'; // <RSVPCard /> as <SurveyCard />
import ResponsiveMetaTag from 'components/ResponsiveMetaTag';
import { SpinnerOverlay as UISpinnerOverlay } from 'components/Spinner';
import { WhiteHeadliner } from 'components/ui/Headline';
import isDate from 'lodash/isDate';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import keyBy from 'lodash/keyBy';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Route, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Column, FixedBackground } from 'ui';
import { PageNotFound } from './PageNotFound';

const SpinnerOverlay = styled(UISpinnerOverlay)`
    position: fixed;
    height: 100vh;
    width: 100vw;
`;

const CenteredColumn = styled(Column)`
    padding: 112px 0;
    margin: auto;
`;

const ColumnCard = styled(Column)`
    width: 680px;
`;

type TSurveyResponse = Partial<{
    success: boolean;
    event: { name: string; imageUrl?: string; cancelledAt?: string };
    parcel: {
        content: string;
        headline?: string;
        id: number;
        name: string;
        subject: string;
        surveyTitle?: string;
    };
    responded: boolean;
    team: { color: string; imageUrl: string; name: string };
    formSettings?: Bizly.FormSetting;
    attendee?: Bizly.EventAttendee;
}>;

const isNoResponse = (response?: number | string | string[] | Date) =>
    typeof response === 'string'
        ? isEmptyString(response)
        : !isNumber(response) && isEmpty(response) && !isDate(response);

export const getSurveyQuestionError = (surveyQuestions: Bizly.SurveyQuestion[], responses?: Bizly.SurveyQuestion[]) => {
    const getError = (surveyQuestion?: Bizly.SurveyQuestion) =>
        surveyQuestion ? (
            <span>
                A response is required for the question:
                <br />
                <br />
                {surveyQuestion.prompt}
            </span>
        ) : (
            false
        );

    const { multipleChoiceMultipleAnswers } = questionDefinitionIds;

    const responsesById = keyBy(responses || surveyQuestions, 'id');

    return getError(
        surveyQuestions.find(
            ({ id, definition, responseRequired }) =>
                definition.id !== multipleChoiceMultipleAnswers &&
                responseRequired &&
                isNoResponse(responsesById[id]?.response)
        )
    );
};

export const getSurveyQuestionErrorMessage = (
    surveyQuestions: Bizly.SurveyQuestion[],
    responses?: Bizly.SurveyQuestion[]
) => {
    const getError = (surveyQuestion?: Bizly.SurveyQuestion) =>
        surveyQuestion ? `A response is required for the question: ${surveyQuestion.prompt}` : false;

    const { multipleChoiceMultipleAnswers } = questionDefinitionIds;

    const responsesById = keyBy(responses || surveyQuestions, 'id');

    return getError(
        surveyQuestions.find(
            ({ id, definition, responseRequired }) =>
                definition.id !== multipleChoiceMultipleAnswers &&
                responseRequired &&
                isNoResponse(responsesById[id]?.response)
        )
    );
};

const Survey = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [response, setResponse] = useState<TSurveyResponse>();
    const [surveyQuestions, setSurveyQuestions] = useState<Bizly.SurveyQuestion[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { eventId } = useParams<{ eventId: string }>();
    const { search: query } = useLocation();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const {
                    success,
                    questions,
                    message: errorMessage,
                    ...survey
                } = await getSurveyInfo(Number(eventId), query);
                if (success) {
                    setResponse(survey);
                    setSurveyQuestions(questions);
                } else {
                    throw new Error(errorMessage); // TODO: Use this error message in some way.
                }
            } catch {
                setError(true);
                setResponse({});
            } finally {
                setIsLoading(false);
            }
        })();
    }, [eventId, query]);

    const handleChange = ({ value }: { value: Bizly.SurveyQuestion[] }) => {
        setSurveyQuestions(value);
    };

    const handleSubmit = async () => {
        const surveyError = getSurveyQuestionError(surveyQuestions);

        if (surveyError) {
            enqueueSnackbar(surveyError, { variant: 'error' });
        } else {
            const responses = surveyQuestions.map(
                ({ id: questionId, response }: Pick<Bizly.SurveyQuestion, 'id' | 'response'>) => ({
                    questionId,
                    response,
                })
            );
            setIsLoading(true);
            try {
                const {
                    success,
                    questions,
                    message: errorMessage,
                    ...result
                } = await respondToSurvey(Number(eventId), query, { responses });

                if (success) {
                    setResponse(result);
                } else {
                    throw new Error(errorMessage);
                }
            } catch (saveError) {
                if (typeof saveError === 'string') enqueueSnackbar(saveError, { variant: 'error' });
            } finally {
                setIsLoading(false);
            }
        }
    };

    if (error) {
        return <Route element={<PageNotFound />} />;
    }

    if (response === undefined) {
        return <SpinnerOverlay />;
    }

    const surveyCardProps = {
        name: response.event?.name,
        team: response.team,
        image: response.event?.imageUrl,
        plannedBy: {},
        isNote: true,
        isPreview: false,
        cancelledAt: response.event?.cancelledAt,
        formSettings: response.formSettings,
        attending: true,
    };

    return (
        <>
            <ResponsiveMetaTag />
            <Column style={{ position: 'relative', display: 'block' }}>
                <CenteredColumn style={{ alignItems: 'center' }}>
                    <FixedBackground backgroundColor={response.team?.color || 'transparent'} />

                    <ColumnCard itemSpacing="large">
                        {response.responded && <WhiteHeadliner noDot>Thank you!</WhiteHeadliner>}
                        {response.responded ? (
                            <SurveyCard
                                {...surveyCardProps}
                                description="Your responses have been submitted."
                                submitted
                            />
                        ) : (
                            <SurveyCard
                                {...surveyCardProps}
                                headline={response.parcel?.headline}
                                description={response.parcel?.content}
                                surveyTitle={response.parcel?.surveyTitle}
                                surveyQuestions={surveyQuestions}
                                onChange={handleChange}
                                handleSubmit={handleSubmit}
                            />
                        )}
                    </ColumnCard>
                </CenteredColumn>
                {isLoading && <SpinnerOverlay />}
            </Column>
        </>
    );
};

export default Survey;
