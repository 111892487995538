import DialogContent from '@material-ui/core/DialogContent';
import Popover from '@material-ui/core/Popover';
import { InfoOutlined } from '@material-ui/icons';
import { SingleFieldForm } from 'components/Form';
import { RichTextDisplay } from 'components/Form/RichTextEditor';
import TextButton from 'components/ui/Button/TextButton';
import React, { useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { InlineRow, PreCopy, Spacer } from 'ui';

const MediumDialogContent = styled(DialogContent)`
    width: 320px;
    padding: 32px !important;
`;

type TDeleteModalForm = {
    cancellationNotes: string;
    setCancellationNotes: (value: string) => void;
    userCancellationMessage?: string | null;
    userTeamName?: string;
};

const DeleteModalForm = ({
    cancellationNotes,
    setCancellationNotes,
    userCancellationMessage,
    userTeamName,
}: TDeleteModalForm) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    return (
        <div>
            <Spacer small />
            <PreCopy>{i18n.meetingDashboard.deleteConfirmation}</PreCopy>
            <Spacer small />
            {userCancellationMessage && (
                <>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MediumDialogContent>
                            <RichTextDisplay value={userCancellationMessage} />
                        </MediumDialogContent>
                    </Popover>
                    <TextButton
                        onClick={e => {
                            setAnchorEl(e.currentTarget);
                        }}
                        warning
                        width="100%"
                    >
                        <InlineRow alignItems="center" justifyContent="center" itemSpacing="small">
                            <InfoOutlined />
                            {i18n.meetingDashboard.cancellationProtocolInfo(userTeamName || '')}
                        </InlineRow>
                    </TextButton>
                </>
            )}
            <Spacer default />

            <SingleFieldForm
                field={{
                    type: 'textarea',
                    prompt: i18n.meetingDashboard.cancellationNotes,
                    options: { rows: 2, placeholder: i18n.meetingDashboard.notesPlaceholder },
                }}
                value={cancellationNotes}
                onChange={setCancellationNotes}
            />
        </div>
    );
};

export default DeleteModalForm;
