import { default as FormBase } from 'components/Form';
import usePersistentSnackbar from 'hooks/usePersistentSnackbar';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column } from 'ui';
import Errors from './components/Errors';
import InternalDetailsForm from './InternalDetailsForm';
import { createPlaybookActions, useCreatePlaybook } from './store';
import TagsForm from './TagsForm';

const fields = {
    imageUrl: {
        type: 'image_uploader',
        prompt: i18n.playbookWizard.basicInfo.coverPhoto,
        options: {
            heightRatio: '40%',
        },
    },
    name: {
        prompt: i18n.playbookWizard.basicInfo.namePrompt,
        type: 'text',
        perRow: '2/3',
        options: {},
    },
    description: {
        prompt: i18n.playbookWizard.basicInfo.description,
        type: 'rich_text',
        options: {
            rows: 3,
            rowsMax: 5,
        },
    },
};

const schema = [
    {
        fields: ['name'],
        spacing: 'default',
    },
    {
        fields: ['description'],
        spacing: 'default',
    },
    { fields: ['imageUrl'], itemSpacing: 'larger', spacing: false },
];

export type TBasicInfoValue = Partial<{
    imageUrl: string;
    name: string;
    description: string;
}>;

const Form = styled(FormBase)`
    width: 100%;
`;

export default function BasicInfoForm() {
    const { basicInfo, basicInfoErrors, internalDetails, stepIdx } = useCreatePlaybook();

    const enqueueSnackbar = usePersistentSnackbar([basicInfoErrors, stepIdx]);
    React.useEffect(() => {
        const hasErrors = Object.values(basicInfoErrors).some(v => v);
        if (hasErrors) {
            enqueueSnackbar(<Errors errors={basicInfoErrors} />, { variant: 'error' });
        }
    }, [basicInfoErrors, enqueueSnackbar]);

    return (
        <Column itemSpacing="large">
            <Form
                fields={fields}
                schema={schema}
                value={basicInfo}
                onChange={({ value }: { value: TBasicInfoValue }) => {
                    createPlaybookActions.setBasicForm(value);
                    createPlaybookActions.setBasicFormErrors({});
                }}
            />
            <InternalDetailsForm value={internalDetails} />
            <TagsForm />
        </Column>
    );
}
