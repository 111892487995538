import { Tooltip } from '@material-ui/core';
import Button from 'components/ui/Button';
import { useUser } from 'providers/user';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';

export const AcceptRejectButton = styled(Button)`
    :disabled {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey2, 0.45)};
    }
`;
export const ProposalActionButton = ({
    proposal,
    expired,
    onAccept,
    eventBudget,
    showModal,
    hasRequested,
}: {
    proposal: Bizly.VenueProposal;
    expired?: boolean;
    onAccept: () => void;
    eventBudget?: string;
    showModal: () => void;
    hasRequested?: boolean;
}) => {
    const proposalRequest = proposal.approvalRequests[0];

    const isRejected = Boolean(proposalRequest?.rejectedAt);
    const isApproved = Boolean(proposalRequest?.approvedBy);

    const { user } = useUser();

    const isEventBudget =
        user.team?.authMeetingCreateFields?.includes('budget') || user?.team?.authMeetingCreateRedirect;
    const totalProposal = proposal.estimatedTotalCost;
    const hasBudget = (isEventBudget && eventBudget) || user?.team?.maximumBudget;

    const isAboveBudget = user?.team?.authMeetingCreateRedirect
        ? Number(totalProposal) > Number(eventBudget)
        : Number(totalProposal) > Number(user?.team?.maximumBudget);

    const budgetBasedApprovalFeatureFlag = user.featureFlags?.budgetBasedApprovals;

    if (isRejected) {
        return (
            <Tooltip title={i18n.venue.inquiry.requestRejectedInfo}>
                <span>
                    <AcceptRejectButton onClick={() => {}} disabled width={150}>
                        {i18n.venue.inquiry.requestRejected}
                    </AcceptRejectButton>
                </span>
            </Tooltip>
        );
    }

    if (budgetBasedApprovalFeatureFlag && hasBudget && isAboveBudget && !isApproved) {
        return (
            <AcceptRejectButton
                onClick={() => showModal()}
                width={hasRequested ? 160 : 150}
                disabled={hasRequested || expired}
            >
                {hasRequested ? i18n.venue.inquiry.approvalRequested : i18n.venue.inquiry.requestApproval}
            </AcceptRejectButton>
        );
    }

    return (
        <AcceptRejectButton onClick={() => onAccept()} disabled={expired}>
            {i18n.button.accept}
        </AcceptRejectButton>
    );
};
