import { parseISO } from 'date-fns';
import { findTimeZone, getZonedTime } from 'timezone-support';
import { formatZonedTime } from 'timezone-support/dist/parse-format';
import { tzMoment } from 'utils/moment';

export type TCalendarValues = {
    title: string;
    location: string;
    startsAt: string;
    endsAt: string;
    timeZone: string;
};

const isMobile = () =>
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(window.navigator.userAgent || window.navigator.vendor);

const calendarFormat = (ISOString: string) => tzMoment(ISOString).format('YYYYMMDDTHHmmss');

const formatICSDate = (date: string, timeZone?: string) =>
    `${timeZone ? `;TZID=${timeZone}` : ``}:${calendarFormat(date)}`;

export const escapeICSDescription = (description: string) => description.replace(/(\r?\n|<br ?\/?>)/g, '\\n');

const DESCRIPTION_TEXT =
    'For details and instructions for how to join, please click the link below or copy into your browser.';

const appendUrlToDescription = (documentUrlLine: string) => `${DESCRIPTION_TEXT}${documentUrlLine}`;

const getDSTParams = (ISOString: string, timeZone: string) => {
    const timeZoneData = findTimeZone(timeZone);
    const baseYear = parseISO(ISOString).getFullYear();
    const daylightStartDate = getZonedTime(parseISO(baseYear + '-03-08 12:00:00'), timeZoneData);
    const standardStartDate = getZonedTime(parseISO(baseYear + '-11-01 12:00:00'), timeZoneData);
    const ZONED_FORMAT = 'YYYYMMDD[T]HHmmss ZZ z';
    const [daylightStart, daylightOffset, daylightTzName] = formatZonedTime(daylightStartDate, ZONED_FORMAT).split(' ');
    const [standardStart, standardOffset, standardTzName] = formatZonedTime(standardStartDate, ZONED_FORMAT).split(' ');

    return {
        standardStart,
        standardOffset,
        standardTzName,
        daylightStart,
        daylightOffset,
        daylightTzName,
    };
};

export const formatCalendarData = ({ title, location, startsAt, endsAt, timeZone }: TCalendarValues) => {
    const { standardStart, standardOffset, standardTzName, daylightStart, daylightOffset, daylightTzName } =
        getDSTParams(startsAt, timeZone);

    const appendedDescription = appendUrlToDescription(`\n\n${document.URL}`);
    const content = [
        `BEGIN:VCALENDAR`,
        `PRODID:-//Bizly//bizly.com//EN`,
        `VERSION:2.0`,

        `BEGIN:VTIMEZONE`, // Instructions for Outlook to handle Daylight Saving Time (DST)
        `TZID:${timeZone}`,

        `BEGIN:STANDARD`,
        `DTSTART:${standardStart}`, // The date/time when DST ends
        `RRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU`,
        `TZOFFSETFROM:${standardOffset}`, // The old offset we are changing from
        `TZOFFSETTO:${daylightOffset}`, // The new offset we are changing to
        `TZNAME:${daylightTzName}`, // This is EST, PST, etc... for some reason?
        `END:STANDARD`,

        `BEGIN:DAYLIGHT`,
        `DTSTART:${daylightStart}`, // The date/time when DST begins
        `RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU`,
        `TZOFFSETFROM:${daylightOffset}`, // The old offset we are changing from
        `TZOFFSETTO:${standardOffset}`, // The new offset we are changing to
        `TZNAME:${standardTzName}`, // This is EDT, PDT, etc... for some reason?
        `END:DAYLIGHT`,
        `END:VTIMEZONE`,

        `BEGIN:VEVENT`,
        // `DTSTAMP:20200731T174639Z`, // Metadata: Timestamp of when this file was created
        `STATUS:CONFIRMED`,
        // `UID:1596217599addeventcom`, // Metadata: Unique ID of this file creation
        `SEQUENCE:0`,
        `DTSTART${formatICSDate(startsAt, timeZone)}`,
        `DTEND${formatICSDate(endsAt, timeZone)}`,
        `SUMMARY:${title}`,
        `DESCRIPTION:${escapeICSDescription(appendedDescription)}`,
        `LOCATION:${location}`,
        // `TRANSP:OPAQUE`, // https://www.kanzaki.com/docs/ical/transp.html
        `END:VEVENT`,
        `END:VCALENDAR`,
    ].join('\n');

    return isMobile() ? encodeURI(`data:text/calendar;charset=utf8,${content}`) : content;
};

export const formatGoogleUrl = ({ title, location, startsAt, endsAt, timeZone }: TCalendarValues) => {
    const appendedDescription = appendUrlToDescription(`\n<p>${document.URL}</p>`);
    const content = [
        `&dates=${calendarFormat(startsAt)}/${calendarFormat(endsAt)}`,
        timeZone ? `&ctz=${timeZone}` : '',
        `&location=${encodeURI(location)}`,
        `&text=${encodeURI(title)}`,
        `&details=${encodeURIComponent(appendedDescription)}`,
    ].join('');

    return `https://calendar.google.com/calendar/render?action=TEMPLATE${content}`;
};
