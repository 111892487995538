import Popover from '@material-ui/core/Popover';
import ConfirmationModal from 'components/ConfirmationModal';
import Form from 'components/Form';
import { H2Headline } from 'components/ui/Headline';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import { useEvent } from 'providers/event';
import React, { useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { timeOptions } from 'utils/date_util';
import { tzMoment, userTimeZone } from 'utils/moment';
import { Row, Spacer } from '../../ui';
import TextButton from '../ui/Button/TextButton';

const Container = styled.div`
    width: 650px;
    padding: 32px;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 100%;
    box-sizing: border-box;
    position: relative;
`;

const Content = styled.div`
    max-height: 479px;
    min-height: 20px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
`;

const ActionRow = styled(Row)`
    display: flex;
    margin-top: 32px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
`;

export type TScheduleForm = {
    scheduleDate: string;
    scheduleTime: string;
};

type ScheduleFlyoutProps = {
    anchor: Element;
    setAnchor: (anchor: any) => void;
    initialValues?: Partial<TScheduleForm>;
    onSubmit: (values: TScheduleForm) => void;
};

const ScheduleFlyout = ({ anchor, setAnchor, initialValues, onSubmit }: ScheduleFlyoutProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const { event } = useEvent();
    const [values, setValues] = useState<TScheduleForm>({
        scheduleDate: initialValues?.scheduleDate ?? '',
        scheduleTime: initialValues?.scheduleTime ?? '',
    });
    const [isEditMode, setIsEditMode] = useState(!initialValues);

    const { modalShown, showModal, hideModal } = useShowModal();

    const onFormUpdate = ({ value, field }: { value: TScheduleForm; field: keyof TScheduleForm }) => {
        if (field === 'scheduleDate' && value.scheduleDate === 'Invalid date') {
            setValues(prev => ({
                ...prev,
                [field]: '',
            }));
        } else {
            setValues(prev => ({
                ...prev,
                [field]: value[field],
            }));
        }
    };

    const handleSave = () => {
        if (!values.scheduleDate || !values.scheduleTime) {
            return;
        }
        const timezone = event.timeZone || userTimeZone;
        const selectedTime = tzMoment(`${values.scheduleDate} ${values.scheduleTime}`, timezone);
        if (selectedTime.isBefore(tzMoment())) {
            enqueueSnackbar(i18n.communication.error.schedulePast, {
                variant: 'error',
            });
            return;
        }

        onSubmit(values);
        handleClose();
    };

    const handleClose = () => setAnchor(null);

    const onReschedule = () => setIsEditMode(true);

    const onDelete = () => {
        showModal();
    };

    const handleDelete = () => {
        onSubmit({
            scheduleDate: '',
            scheduleTime: '',
        });
        handleClose();
    };

    return (
        <Popover
            anchorEl={anchor}
            open={!!anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={() => setAnchor(null)}
        >
            <Container>
                {isEditMode && (
                    <>
                        <H2Headline>
                            {initialValues ? i18n.communication.rescheduleFor : i18n.communication.scheduleFor}
                        </H2Headline>
                        <Spacer />
                    </>
                )}
                <Content>
                    <Form
                        fields={scheduleFormFields}
                        schema={scheduleFormSchema}
                        value={{ ...values, timezone: event.timeZone ?? 'UTC' }}
                        onChange={onFormUpdate}
                        disabled={!isEditMode}
                    />
                </Content>
                {isEditMode ? (
                    <ActionRow itemSpacing="smallish" justifyContent="flex-end">
                        <TextButton onClick={handleSave} disabled={!values.scheduleDate || !values.scheduleTime}>
                            {i18n.button.save}
                        </TextButton>
                        <TextButton onClick={handleClose} secondary>
                            {i18n.button.cancel}
                        </TextButton>
                    </ActionRow>
                ) : (
                    <ActionRow justifyContent="space-between">
                        <TextButton onClick={onReschedule} disabled={!values.scheduleDate || !values.scheduleTime}>
                            {i18n.communication.reschedule}
                        </TextButton>
                        <TextButton onClick={onDelete} secondary>
                            {i18n.button.delete}
                        </TextButton>
                    </ActionRow>
                )}
            </Container>
            <ConfirmationModal
                headline={<>{i18n.communication.confirmation}</>}
                prompt={<p>{i18n.communication.deleteScheduleConfirm}</p>}
                isActive={modalShown}
                onDismiss={() => hideModal()}
                onProceed={handleDelete}
                ctaLabel={i18n.button.confirm}
            />
        </Popover>
    );
};

export default ScheduleFlyout;

const scheduleFormFields = {
    scheduleDate: {
        prompt: '',
        type: 'date_outlined',
        options: {
            placeholder: 'Date',
            format: 'MMM do',
            minDate: Date.now(),
        },
    },
    scheduleTime: {
        prompt: '',
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: 'Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    timezone: {
        type: 'timezone_select',
        perRow: 'auto',
        optional: true,
        readonly: true,
    },
};

const scheduleFormSchema = [
    {
        key: 'schedule_time',
        fields: ['scheduleDate', 'scheduleTime', 'timezone'],
        spacing: false,
    },
];
