import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React, { forwardRef } from 'react';
import { i18n } from 'translation';

const FiltersButtonButton = styled(Button)`
    white-space: nowrap;
    width: fit-content;
    width: 110px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};

    &.MuiButton-root {
        padding: 5px 15px;
        border-radius: 4px;
        border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};

        &:hover {
            background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction, 0.1)};
        }
    }
`;

type TFiltersButton = {
    toggleFiltersMenu: (event: React.MouseEvent<HTMLElement>) => void;
    filterCount: number;
};

const FiltersButton = forwardRef(
    ({ toggleFiltersMenu, filterCount }: TFiltersButton, ref: React.Ref<HTMLButtonElement>) => {
        const hasFilters = filterCount > 0;
        return (
            <FiltersButtonButton
                onClick={toggleFiltersMenu}
                ref={ref}
                variant="outlined"
                startIcon={<FilterListIcon />}
            >
                {hasFilters ? i18n.venue.filtersWithCount(filterCount) : i18n.venue.filters}
            </FiltersButtonButton>
        );
    }
);

export default FiltersButton;
