import { ActionTableItem } from 'components/ActionItems/ActionItemsTable';
import maxBy from 'lodash/maxBy';
import { deleteCall, get, post, put } from '.';

export const getInquiriesForEvent = (eventId: number) =>
    get(`events/${eventId}/inquiries`) as Promise<{ inquiries: BizlyAPI.Inquiry[] }>;

export const getCurrentInquiry = async (eventId: number) => {
    const { inquiries: existingInquiries } = await getInquiriesForEvent(eventId);
    return maxBy(existingInquiries, 'id') ?? null;
};

export const createInquiryDraft = (eventId: number) =>
    post(`events/${eventId}/inquiries`) as Promise<{ inquiry: BizlyAPI.Inquiry }>;

export const updateBookingInquiry = (
    inquiryId: number,
    data: Partial<Pick<BizlyAPI.Inquiry, 'notes' | 'flexibleDates' | 'scheduleItemIds'>>
) => put(`booking-inquiries/${inquiryId}`, data) as Promise<{ inquiry: BizlyAPI.Inquiry }>;

export const removeVenueFromInquiry = (inquiryId: number, venueId: number) =>
    deleteCall(`booking-inquiries/${inquiryId}/venues/${venueId}`) as Promise<{
        inquiry: BizlyAPI.Inquiry;
    }>;

export const submitInquiry = (inquiryId: number) =>
    post(`booking-inquiries/${inquiryId}/submit`) as Promise<{
        inquiry: BizlyAPI.Inquiry;
    }>;

export const cancelAndCopyInquiry = (inquiryId: number | string) =>
    post(`booking-inquiries/${inquiryId}/copy`) as Promise<{ inquiry: BizlyAPI.Inquiry }>;

export const requestBudgetApproval = (proposalId: number, notes: string) =>
    post(`proposals/${proposalId}/request-budget-approval`, { notes }) as Promise<{
        success: boolean;
        message: string;
    }>;

export const getBudgetApprovalRequests = (all = true) =>
    get(`proposals/budget-approval-requests${all ? '' : '?hide_approved=true&hide_rejected=true'}`) as Promise<{
        budgetApprovalRequests: BizlyAPI.BudgetApproval[];
        success: boolean;
    }>;

export const budgetRequestAction = ({
    proposalId,
    budgetRequestId,
    actionType,
    approvedAmount,
    reason,
}: {
    proposalId: number;
    budgetRequestId: number;
    actionType: 'reject' | 'approve';
    approvedAmount?: number;
    reason?: string;
}) => {
    return post(
        `proposals/${proposalId}/budget-approval-requests/${budgetRequestId}/${actionType}`,
        actionType === 'approve' ? { approvedAmount } : { reason }
    ) as Promise<{
        budgetApprovalRequest: ActionTableItem;
        success: boolean;
    }>;
};
