import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { MAX_INQUIRY_VENUE_COUNT } from 'constants/inquiry';
import { useCallback, useMemo } from 'react';
import { Waypoint } from 'react-waypoint';
import { VenueTileEmpty } from '../VenueTile';
import VenueTile from '../VenueTile/VenueTileV2';

export const NoSearchGrid = () => (
    <Box style={{ flex: '1 1 51.75rem' }}>
        <Grid container spacing={2.5}>
            {new Array(12).fill('').map((_, idx) => (
                <Grid item key={idx}>
                    <Skeleton variant="rounded" animation="wave" sx={{ width: '16.25rem', height: '15.375rem' }} />
                </Grid>
            ))}
        </Grid>
    </Box>
);

type TInfiniteGrid = {
    data: Bizly.Venue[];
    selectedVenues?: { [venueId: number]: Bizly.Venue };

    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
    onVenueHover?: (venueId: number | null) => void;
    onVisit?: (venueId: number) => void;

    hasMore?: boolean;
    loadMore: () => void;
    paddingCount?: number;
    highlightedVenue?: number;
    pinHighlightedVenue?: number;
    hideActions: boolean;
    hasAcceptedProposal?: boolean;
};

export const InfiniteGrid = ({
    data,
    selectedVenues,
    onSelect,
    onDeselect,
    onVenueHover,
    onVisit,
    hasMore,
    loadMore,
    paddingCount = 10,
    pinHighlightedVenue,
    hideActions,
    hasAcceptedProposal,
}: TInfiniteGrid) => {
    const components = useMemo(
        () => [...data, ...(hasMore ? [null] : []), ...(hasMore ? new Array(paddingCount).fill('') : [])],
        [data, hasMore, paddingCount]
    );

    const mouseLeave = useCallback(() => onVenueHover && onVenueHover(null), [onVenueHover]);
    const _selectedVenues = useMemo(
        () => (hideActions || !selectedVenues ? [] : selectedVenues),
        [hideActions, selectedVenues]
    );

    const hasReachedMaxInquiryCount = selectedVenues && Object.keys(selectedVenues).length >= MAX_INQUIRY_VENUE_COUNT;
    const disableSelection = hasAcceptedProposal || hasReachedMaxInquiryCount;
    const disabledToolTipMessage = hasAcceptedProposal
        ? undefined
        : hasReachedMaxInquiryCount && `${MAX_INQUIRY_VENUE_COUNT} venues already selected`;

    return (
        <Grid container spacing={2.5}>
            {components.map((v, idx) => {
                if (v === null) {
                    // key is data.length because we should remount so it can retrigger
                    return <Waypoint key={data.length} onEnter={loadMore} bottomOffset="10%" />;
                }

                if (v === '') {
                    return (
                        <Grid item key={idx}>
                            <VenueTileEmpty width="16.25em" fixedRatio="calc(160 / 208 * 100%)" />
                        </Grid>
                    );
                }

                return (
                    <Grid item key={v.id}>
                        <VenueTile
                            venue={v}
                            width="16.25em"
                            padding="0.625em"
                            onMouseLeave={mouseLeave}
                            onMouseOver={onVenueHover}
                            highlighted={v.id === pinHighlightedVenue}
                            onClick={onVisit}
                            hideActions={hideActions}
                            disableRejected
                            selectionDisabled={disableSelection}
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                            selected={!!_selectedVenues[v.id]}
                            disabledToolTipMessage={disabledToolTipMessage || ''}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
