import carbonSupportedTimeZones from 'components/Form/TimeZonePicker/carbonSupportedTimeZones';
import { Moment, tz as momentTz } from 'moment-timezone';
import { i18n } from 'translation';

const guessedTimeZone = momentTz.guess();
export const userTimeZone = carbonSupportedTimeZones.includes(guessedTimeZone) ? guessedTimeZone : 'UTC';

export const tzMoment = (date?: string | Date | null | number, timeZone = 'UTC'): Moment =>
    date && timeZone
        ? momentTz(typeof date === 'number' ? new Date(date) : date, timeZone)
        : momentTz(undefined, timeZone);

export const userFormattedTimestamp = (isoTimestamp: string, addOnOrAt?: boolean) => {
    const inUserTz = tzMoment(isoTimestamp).tz(userTimeZone);
    const now = tzMoment('', userTimeZone);
    let format = '';
    let preposition = '';
    if (inUserTz.isSame(now, 'date')) {
        preposition = addOnOrAt ? i18n.common.at : '';
        format = 'h:mm a z';
    } else {
        preposition = addOnOrAt ? i18n.common.on : '';
        if (inUserTz.isSame(now, 'year')) {
            format = 'MMM D, h:mm a z';
        } else {
            format = 'MMM D, YYYY h:mm a z';
        }
    }

    return {
        preposition,
        formattedTime: inUserTz.format(format),
    };
};
