import { ClickAwayListener, PopperPlacementType } from '@mui/material';
import Popper from 'components/TypedPopper';
import Button from 'components/ui/Button';
import { useUser } from 'providers/user';
import React, { useRef } from 'react';
import styled from 'styled-components';

const InstrutionalContent = styled.div`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    width: 75%;
    padding: 0.5rem;
    font-size: 0.9375rem;
    max-width: 500px;
    margin-left: 15px;

    a {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        text-decoration: underline;
    }
`;

const PopperButton = styled(Button)`
    margin-left: 1rem;
    width: auto;
    font-weight: 500;
`;

const PopperContent = styled(Popper)`
    margin-left: 1rem;
    z-index: 6;
`;

export default function HowToPopper({
    sectionId,
    popperPlacement = 'right',
}: {
    sectionId: string;
    popperPlacement?: PopperPlacementType;
}) {
    const { user } = useUser();
    const [open, setOpen] = React.useState<boolean>(false);
    const popperButtonRef = useRef<HTMLButtonElement>(null);

    const HOW_IT_WORKS_SECTION = 'howItWorks';

    const tips = user?.team?.proTips.filter(
        ({ page, section }) => page == sectionId && section == HOW_IT_WORKS_SECTION
    )[0];

    return tips ? (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
                <PopperButton
                    secondary
                    variant="outlined"
                    onClick={() => setOpen(!open)}
                    ref={popperButtonRef}
                    buttonColor="primaryAction"
                >
                    {tips.name}
                </PopperButton>

                <PopperContent open={open} anchorEl={popperButtonRef.current} placement={popperPlacement}>
                    <InstrutionalContent dangerouslySetInnerHTML={{ __html: tips.content }} />
                </PopperContent>
            </div>
        </ClickAwayListener>
    ) : null;
}
