import SideNav from 'components/SideNav';
import { LargeHeadline } from 'components/ui/Headline';
import { Route, Routes } from 'react-router-dom';
import IntegrationCallback from './IntegrationCallback';
import IntegrationConnection from './IntegrationConnection';

export default function IntegrationsPage() {
    return (
        <SideNav>
            <LargeHeadline>Integrations</LargeHeadline>

            <Routes>
                <Route path={''}>
                    <IntegrationConnection />
                </Route>
                <Route path={'callback/:integration'}>
                    <IntegrationCallback />
                </Route>
            </Routes>
        </SideNav>
    );
}
