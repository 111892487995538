import { getNotifications, updateReadStatus } from 'api/notification';
import createStore from '..';

type State = {
    loadingNotifications: boolean;
    notifications: BizlyAPI.Notification[];
    total: number;
    unread: number;
};

const initialState: State = {
    loadingNotifications: false,
    notifications: [],
    total: 0,
    unread: 0,
};

export const useNotifications = createStore<State>(() => initialState);
const { getState, setState } = useNotifications;

export const notificationActions = {
    load: async () => {
        setState({ ...getState(), loadingNotifications: true });

        try {
            const { notifications, total, unread } = await getNotifications();
            setState({
                loadingNotifications: false,
                notifications,
                total,
                unread,
            });

            return notifications;
        } catch (e) {
            setState({ loadingNotifications: false });
            throw e;
        }
    },

    updateReadStatus: async (id: number, read: boolean) => {
        try {
            const { success, notifications, total, unread } = await updateReadStatus(id, read);
            if (success) {
                // mark notification as read
                setState({
                    notifications,
                    total,
                    unread,
                });
            }
        } catch (e) {
            console.error('notification update error: ', e);
            throw e;
        }
    },
};
