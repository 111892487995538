import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { getVenue } from 'api';
import { RowContainer } from 'components/BizlyOS/GridContainer';
import TaskDashboard from 'components/BizlyOS/Home/TaskDashboard';
import { ImageWithSkeleton } from 'components/BizlyOS/Image/ImageWithSkeleton';
import TeamWorkImage from 'images/bizlyOS/coming-soon/team-work.svg';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import { useUser } from 'providers/user';
import { useEffect, useState } from 'react';
import styledComponents from 'styled-components';
import { Row } from 'ui';

const BorderedContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

TipsAndUpdatesOutlinedIcon;

const TipsAndUpdatesIcon = styled(TipsAndUpdatesOutlinedIcon)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSPrimary),
    fontSize: '1.875rem',
}));

const LeftSection = styled(BorderedContainer)(() => ({
    flex: 1,
    alignItems: 'center',
}));

const SectionHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.25),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: '50px',
    boxSizing: 'border-box',
    width: '100%',
}));

const SectionBody = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    gap: theme.spacing(2.5),
    overflowY: 'auto',
    maxWidth: 1400,
}));

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius * 2,
}));

const Description = styled(Typography)({
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '24px',
});

const SecondaryContainer = styled(Container)(({ theme: { getColor, EColors, spacing } }) => ({
    backgroundColor: getColor(EColors.drWhite),
    gap: spacing(1.25),
}));

const PrimaryContainer = styled(Container)(({ theme: { getColor, EColors, spacing } }) => ({
    flexDirection: 'column',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    gap: spacing(1.25),
}));

const OutLineContainer = styled(Container)(({ theme }) => ({
    alignItems: 'flex-start',
    gap: theme.spacing(1.25),
    border: `1px solid ${theme.palette.divider}`,
}));

const StyledUl = styledComponents.ul`
  margin: 0;
  padding-left: 20px;
`;

const StyledLi = styledComponents.li`
  padding: 5px 0px;
`;

export function Home() {
    const { user } = useUser();
    const { venueId } = useBizlyOSUser();

    const [propertyName, setPropertyName] = useState<string>('Property Name');

    useEffect(() => {
        if (!venueId) return;

        const setVenueName = async () => {
            const { venue } = await getVenue(parseInt(venueId));
            setPropertyName(venue.name);
        };

        setVenueName();
    }, [venueId]);

    return (
        <RowContainer>
            <LeftSection>
                <SectionHeader>
                    <Typography fontWeight={600} variant="body1">
                        {propertyName}
                    </Typography>
                </SectionHeader>
                <SectionBody>
                    <SecondaryContainer>
                        <ImageWithSkeleton src={TeamWorkImage} alt="No events" width="100" height="100" />

                        <Box style={{ flex: 1 }}>
                            <Typography variant="body1" fontWeight={700} fontSize="large">
                                Good morning {user.firstName},
                            </Typography>
                            <Description>
                                Welcome to your Bizly command center. This dashboard will give you visibility of every
                                item that needs your attention. Let's start by completing the tasks on the right to get
                                started. You can always invite your colleagues to help out.
                            </Description>
                        </Box>
                    </SecondaryContainer>
                    <PrimaryContainer>
                        <Row itemSpacing="small">
                            <TipsAndUpdatesIcon />
                            <Typography variant="body1" fontWeight={700} fontSize="large">
                                Insights
                            </Typography>
                        </Row>
                        <StyledUl>
                            <StyledLi>
                                Responding to RFPs within 24 hours increases your chances of winning an inquiry by 80%.
                            </StyledLi>
                            <StyledLi>
                                Venues with completed profiles receive 8x more inquiries than incomplete ones.
                            </StyledLi>
                            <StyledLi>Venues with high-quality photos get 5x more inquiries.</StyledLi>
                            <StyledLi>
                                Detailed descriptions that highlight unique features drive 40% more engagement from
                                planners.
                            </StyledLi>
                            <StyledLi>Venues with unique highlights attract 3x more inquiries.</StyledLi>
                        </StyledUl>
                    </PrimaryContainer>
                    <OutLineContainer>
                        <HelpOutlineIcon />
                        <Box style={{ flex: 1 }}>
                            <Typography variant="body1" fontWeight={700} fontSize="large">
                                Have questions or feedback?
                            </Typography>
                            <Description>Contact us at venues@bizly.com. We’re here to help!</Description>
                        </Box>
                    </OutLineContainer>
                </SectionBody>
            </LeftSection>
            <TaskDashboard />
        </RowContainer>
    );
}
