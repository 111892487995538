import Button from 'components/ui/Button';
import styled from 'styled-components';

const SmallCircleButton = styled(Button).attrs(({ secondary }: { secondary?: boolean }) => ({
    secondary: secondary === undefined ? true : secondary,
}))`
    display: inline-flex;
    white-space: nowrap;
    padding: 0;
    width: 2.2em;
    height: 2.2em;
    box-sizing: border-box;

    border-radius: 50%;
    min-width: 0;
    align-items: center;
    justify-content: center;
`;

export default SmallCircleButton;
