import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import LinearScalePrompt from 'components/LinearScalePrompt';
import { withSnackbar } from 'notistack';
import { Component } from 'react';
import { i18n } from 'translation';
import { createQuestion, deleteQuestion, isQuestionValid, loadQuestions, updateQuestion } from '../api';
import { Button, Column, PromptedTextField, Row, SpacedRow, Spacer } from '../ui';
import { ButtonMenu } from './ButtonMenu';
import { Pane } from './Pane';
import { Spinner } from './Spinner';

const Q_TYPE_TO_NAME = {
    dietaryRestrictions: i18n.questions.dietaryNeeds,
    travelDates: i18n.questions.checkInOutDates,
    logistics: i18n.questions.logisticsTypes,
    attendanceType: i18n.questions.attendanceType,
    linearScale: i18n.questions.linearScale,
};

const seeded = ({ isNote, hideAttendanceType }) =>
    [
        ...(isNote
            ? []
            : [
                  {
                      id: 1,
                      type: 'dietaryRestrictions',
                  },
                  {
                      id: 3,
                      type: 'travelDates',
                  },
              ]),
        ...(hideAttendanceType || isNote
            ? []
            : [
                  {
                      id: 11,
                      type: 'attendanceType',
                  },
              ]),
    ].map(q => ({
        ...q,
        name: Q_TYPE_TO_NAME[q.type],
        isSeeded: true,
        label: '',
        required: true,
        value: null,
    }));

const custom = hasLinearScale => [
    {
        name: i18n.questions.textBox,
        type: 'text',
    },
    {
        name: i18n.questions.dateSelector,
        type: 'date',
    },
    {
        name: i18n.questions.singleSelect,
        type: 'multipleChoice',
    },
    {
        name: i18n.questions.multiSelect,
        type: 'multipleChoiceMultipleAnswers',
    },
    ...(hasLinearScale
        ? [
              {
                  name: i18n.questions.linearScale,
                  type: 'linearScale',
              },
          ]
        : []),
];

const QuestionFooter = ({ onRequiredChange, onSave, required, disabled = false }) => (
    <SpacedRow>
        <FormControlLabel
            control={<Switch color="primary" checked={required} onChange={e => onRequiredChange(e.target.checked)} />}
            label={i18n.common.required}
            labelPlacement="start"
        />
        <Button disabled={disabled} onClick={() => onSave()}>
            {i18n.button.save}
        </Button>
    </SpacedRow>
);

export class MultipleChoice extends Component {
    handleDeleteClick(indexToRemove) {
        const { options } = this.props;
        const { onOptionsChange } = this.props;
        onOptionsChange(options.filter((o, index) => index !== indexToRemove));
    }

    handleAddOptionClick() {
        const { options } = this.props;
        const { onOptionsChange } = this.props;
        onOptionsChange(options.concat(''));
    }

    handleOptionChange(index, newValue) {
        const { onOptionsChange } = this.props;
        const updated = this.props.options.slice();
        updated[index] = newValue;
        onOptionsChange(updated);
    }

    handleLabelChange(newValue) {
        const { onLabelChange } = this.props;
        onLabelChange(newValue);
    }

    render() {
        const { label, options, disabled } = this.props;
        return (
            <Column>
                <PromptedTextField
                    onChange={e => this.handleLabelChange(e.target.value)}
                    disabled={disabled}
                    placeholder={i18n.questions.questionPlaceholder}
                    prompt={i18n.questions.question}
                    value={label}
                />
                {options.map((o, index) => {
                    return (
                        <PromptedTextField
                            key={index}
                            onChange={e => this.handleOptionChange(index, e.target.value)}
                            disabled={disabled}
                            placeholder={i18n.questions.optionPlaceholder}
                            prompt={i18n.common.optionIndex(index + 1)}
                            value={o}
                            onDelete={options.length > 2 ? () => this.handleDeleteClick(index) : undefined}
                        />
                    );
                })}
                <Spacer small />
                <Row>
                    {!disabled && (
                        <Button fullWidth={false} variant="outlined" onClick={() => this.handleAddOptionClick()}>
                            {i18n.questions.addOption}
                        </Button>
                    )}
                </Row>
                <Spacer />
            </Column>
        );
    }
}

const { getNewUnsavedId, isIdUnsaved } = (() => {
    let unsavedId = 0; // refer to unPOSTED questions

    return {
        getNewUnsavedId: () => {
            unsavedId--;
            return unsavedId;
        },
        isIdUnsaved: id => id < 0, // keep it negative
    };
})();

class QuestionsComponent extends Component {
    state = {
        pending: false,
        questions: null,
        beingSaved: new Set(),
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate() {
        const { setHasQuestions } = this.props;
        const { questions } = this.state;

        const savedQuestions = questions?.filter(q => !isIdUnsaved(q.id));

        if (setHasQuestions) {
            setHasQuestions(savedQuestions?.length > 0);
        }
    }

    async load() {
        const { parcelId, setHasQuestions } = this.props;

        this.setState({
            pending: true,
        });

        let questions;
        try {
            questions = (await loadQuestions(parcelId)).map(q => ({ ...q, name: Q_TYPE_TO_NAME[q.type] || q.name }));
        } catch (e) {
            this.props.enqueueSnackbar(i18n.error.loadQuestion, {
                variant: 'error',
            });
        }

        if (setHasQuestions) {
            setHasQuestions(!!questions?.length);
        }

        this.setState({
            pending: false,
            questions: questions && questions.length > 0 ? questions : [], // seeded
        });
    }

    async handleAddQuestionSelect(type, name) {
        this.setState(({ questions: prevQuestions }) => ({
            questions: prevQuestions.concat({ type, name, id: getNewUnsavedId() }),
        }));
    }

    async handleRemoveQuestionClick(id) {
        const { parcelId } = this.props;
        const deleteFromState = () =>
            this.setState(({ questions: prevQuestions }) => ({
                questions: prevQuestions.filter(q => q.id !== id),
            }));

        if (isIdUnsaved(id)) {
            deleteFromState();
            return;
        }

        try {
            await deleteQuestion(parcelId, id);
            deleteFromState();
        } catch (e) {
            this.props.enqueueSnackbar(i18n.error.deleteQuestion, {
                variant: 'error',
            });
        }
    }

    async handleQuestionSaveClick(question, onSuccess) {
        const { parcelId } = this.props;
        const { id, ...questionToCreate } = question;
        const isNewQuestion = isIdUnsaved(id);
        const promise = isNewQuestion ? createQuestion(parcelId, questionToCreate) : updateQuestion(parcelId, question);
        try {
            this.setState(({ beingSaved: prevBeingSaved }) => ({
                beingSaved: new Set([...prevBeingSaved, id]),
            }));
            const returnedQuestion = await promise;
            this.setState(({ questions: prevQuestions, beingSaved: prevBeingSaved }) => ({
                questions: prevQuestions.map(q => (q.id === id ? returnedQuestion : q)),
                beingSaved: new Set([...prevBeingSaved].filter(savingId => savingId !== id)),
            }));
            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            this.props.enqueueSnackbar(i18n.error.saveQuestion, {
                variant: 'error',
            });
        }
    }

    modifyQuestion(id, key, newValue) {
        this.setState(({ questions: prevQuestions }) => ({
            questions: prevQuestions.map(q => (q.id === id ? { ...q, [key]: newValue } : { ...q })),
        }));
    }

    handleValueChange(id, newValue) {
        this.modifyQuestion(id, 'value', newValue);
    }

    handleRequiredChange(id, newValue) {
        this.modifyQuestion(id, 'required', newValue);
    }

    handleOptionsChange(id, newValue) {
        this.modifyQuestion(id, 'options', newValue);
    }

    render() {
        const { disabled, isNote, hideAttendanceType, hasLinearScale } = this.props;
        const { pending, questions } = this.state;
        const defaultValues = {
            dietaryRestrictions: i18n.questions.needDietaryRestriction,
            travelDates: i18n.communication.needOvernight,
            attendanceType: i18n.questions.howToAttend,
        };
        const existingDefaults = (questions || []).reduce((set, curQ) => {
            if (Q_TYPE_TO_NAME[curQ.type]) set.add(curQ.type);
            return set;
        }, new Set());
        if (pending || !questions) {
            return <Spinner />;
        }
        return (
            <Column>
                <Row>
                    {!disabled && (
                        <ButtonMenu variant="outlined" label={i18n.questions.addQuestion}>
                            {seeded({ isNote, hideAttendanceType })
                                .filter(seededQ => !existingDefaults.has(seededQ.type))
                                .concat(custom(hasLinearScale))
                                .map(q => (
                                    <MenuItem key={q.name} onClick={() => this.handleAddQuestionSelect(q.type, q.name)}>
                                        {q.name}
                                    </MenuItem>
                                ))}
                        </ButtonMenu>
                    )}
                </Row>
                <Spacer />
                {questions
                    .filter(q => (q.type === 'attendanceType' ? !this.props.hideAttendanceType : true))
                    .map(q => {
                        const labels =
                            q.value && !q.isSeeded
                                ? {
                                      label: q.value,
                                      secondaryLabel: q.name,
                                      stackedLabels: true,
                                  }
                                : { label: q.name };
                        return (
                            <Column key={q.id}>
                                <Pane
                                    id={q.id}
                                    collapsible={!disabled}
                                    showEditIcon={!disabled}
                                    {...labels}
                                    onRemove={!disabled && (id => this.handleRemoveQuestionClick(id))}
                                    beginExpanded={isIdUnsaved(q.id)}
                                >
                                    {toggle => (
                                        <>
                                            {q.type.match(/multipleChoice|multipleChoiceMultipleAnswers/) ? (
                                                <MultipleChoice
                                                    label={q.value || ''}
                                                    disabled={disabled}
                                                    options={q.options || ['', '']}
                                                    onLabelChange={value => this.handleValueChange(q.id, value)}
                                                    onOptionsChange={newOptions =>
                                                        this.handleOptionsChange(q.id, newOptions)
                                                    }
                                                />
                                            ) : q.type.match(/linearScale/) ? (
                                                <LinearScalePrompt
                                                    label={q.value || ''}
                                                    disabled={disabled}
                                                    options={q.options}
                                                    onLabelChange={value => this.handleValueChange(q.id, value)}
                                                    onOptionsChange={newOptions =>
                                                        this.handleOptionsChange(q.id, newOptions)
                                                    }
                                                />
                                            ) : (
                                                !q.isSeeded && (
                                                    <PromptedTextField
                                                        onChange={e => this.handleValueChange(q.id, e.target.value)}
                                                        disabled={disabled}
                                                        placeholder={i18n.questions.questionPlaceholder}
                                                        prompt={i18n.questions.Question}
                                                        value={
                                                            typeof q.value === 'string'
                                                                ? q.value
                                                                : defaultValues[q.type]
                                                        }
                                                    />
                                                )
                                            )}
                                            {!disabled && (
                                                <QuestionFooter
                                                    required={q.required}
                                                    onRequiredChange={newValue =>
                                                        this.handleRequiredChange(q.id, newValue)
                                                    }
                                                    disabled={!isQuestionValid(q) || this.state.beingSaved.has(q.id)}
                                                    onSave={() => {
                                                        this.handleQuestionSaveClick(q, toggle);
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                </Pane>
                                <Spacer />
                            </Column>
                        );
                    })}
            </Column>
        );
    }
}

export const Questions = withSnackbar(QuestionsComponent);
