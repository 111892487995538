import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { getEventPlannerData } from 'api';
import { TimeZonePicker } from 'components/Form/fields';
import { Spinner } from 'components/Spinner';
import map from 'lodash/map';
import { useEvent } from 'providers/event';
import React, { useEffect, useMemo, useState } from 'react';
import { withIconStyles } from 'shared';
import { LoadMeeting, selectMeeting, useMeetings } from 'stores/meetings';
import { LoadSchedule, useSchedule } from 'stores/schedule';
import styled from 'styled-components';
import { i18n } from 'translation';
import { CopyFaded, InlineRow, Row, Strike } from 'ui';
import { getDateRange } from 'utils/date_util';
import { tzMoment } from 'utils/moment';

const DateInfoIcon = styled(withIconStyles(InfoOutlinedIcon))`
    height: 17px;
    width: 17px;
    margin-left: 0.5rem;
`;

const DateText = styled.span`
    font-size: 16px;
    line-height: 19px;
    margin-right: 4px;
`;

const Block = styled.div`
    display: flex;
    align-items: center;
`;

const EventDateDisplay = () => {
    const { event } = useEvent();
    const meeting = useMeetings(selectMeeting(event.id));
    const [loading, setLoading] = useState(false);
    const [inquiryHasDates, setInquiryHasDates] = useState(false); // TODO: use proposal info not inquiry info to check
    const schedule = useSchedule();

    useEffect(() => {
        const loadPlanner = async () => {
            setLoading(true);
            try {
                const { planner } = await getEventPlannerData(event.id);
                const { eventSpaces = [], accommodations = [] } = planner;
                const [startDate] = getDateRange(map([...eventSpaces, ...accommodations], 'date'));
                if (startDate) {
                    setInquiryHasDates(true);
                }
            } finally {
                setLoading(false);
            }
        };

        loadPlanner();
    }, [event.id]);

    const sameDate = tzMoment(meeting?.startsAt).isSame(tzMoment(meeting?.endsAt), 'date');
    const sameYear = tzMoment(meeting?.startsAt).isSame(tzMoment(meeting?.endsAt), 'year');

    const startTime = meeting?.startsAt
        ? tzMoment(meeting?.startsAt).format(`ddd, ${sameYear && !sameDate ? 'MMM DD,' : 'll'} LT`)
        : null;
    const endTime = meeting?.endsAt ? tzMoment(meeting?.endsAt).format(`${sameDate ? '' : 'ddd, ll '}LT`) : null;

    const timeString = useMemo(() => {
        if (!startTime) {
            return null;
        }

        if (!endTime || startTime === endTime) {
            return startTime;
        }

        return `${startTime} - ${endTime}`;
    }, [endTime, startTime]);

    const timeInfo = () => {
        const useBooking = !!meeting?.venueBooking;
        const useInquiry = inquiryHasDates;
        const useAgenda = schedule?.items?.length;

        const infos = [
            useAgenda ? i18n.common.agenda : '',
            useInquiry ? i18n.common.inquiry : useBooking ? i18n.common.booking : '',
        ].filter(val => val);
        const infoString = i18n.common.concatWords(...infos);

        return (
            <>
                {i18n.datetime.datesCalculated(infoString)}
                {useInquiry || useBooking ? (
                    <>
                        <br />
                        {i18n.datetime.calculationTimeInfo}
                    </>
                ) : null}
            </>
        );
    };

    return (
        <InlineRow alignItems="stretch">
            <LoadMeeting id={event.id} />
            <LoadSchedule />
            {timeString && (
                <Row>
                    <CopyFaded style={{ display: 'flex' }}>
                        <DateText style={{ marginRight: '10px' }}>
                            {meeting?.cancelledAt ? <Strike>{timeString}</Strike> : timeString}
                        </DateText>
                        <Block>
                            {meeting && (
                                <TimeZonePicker
                                    date={meeting.startsAt || new Date().toISOString()}
                                    value={event.timeZone}
                                    overrideFormStyles
                                    readonly={!event.editable}
                                />
                            )}
                            <Tooltip title={!meeting || !event || loading ? <Spinner /> : timeInfo()}>
                                <DateInfoIcon />
                            </Tooltip>
                        </Block>
                    </CopyFaded>
                </Row>
            )}
        </InlineRow>
    );
};

export default EventDateDisplay;
