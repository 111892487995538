import SideNav from 'components/SideNav';
import { FormProvider, useForm } from 'react-hook-form';
import { Route, Routes } from 'react-router-dom';
import VenueDetails from './VenueDetails';
import Venues from './Venues';

export default function VenuesRoute() {
    const methods = useForm();

    return (
        <SideNav style={{ paddingTop: 24 }}>
            <FormProvider {...methods}>
                <Routes>
                    <Route path={'venue/listing/:venueId'} element={<VenueDetails />} />
                    <Route path="*" element={<Venues />} />
                </Routes>
            </FormProvider>
        </SideNav>
    );
}
