import { Dialog, DialogContent, TextField, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Row } from 'ui';
import { Button } from 'components/Ui-V2/Button/Button';
import { H2Headline } from 'components/ui/Headline';
import { PageDescription } from 'pages/BuildInquiry';
import { EColors } from 'theme';

const WideDialogContent = styled(DialogContent)`
    width: 30rem;
    padding: 1.5rem;
`;

const MenuActionButtons = styled(Button)<{ cancelButton?: boolean }>`
    flex: 1;
    border: ${({ theme, cancelButton }) => (cancelButton ? `1px solid ${theme.getColor(EColors.lighterGrey)}` : '')};
    color: ${({ theme, cancelButton }) => (cancelButton ? `${theme.getColor(EColors.darkGrey)}` : '')};

    &.MuiButton-outlinedPrimary {
        border: ${({ theme }) => `.0625rem solid ${theme.getColor(EColors.lighterGrey)}`};
        background-color: ${({ theme }) => theme.getColor(EColors.pureWhite)};
        color: ${({ theme }) => theme.getColor(EColors.darkGrey)};

        &:hover {
            background-color: ${({ theme }) => theme.getColor(EColors.lighterGrey)};
            color: ${({ theme }) => theme.getColor(EColors.darkerGrey)};
            border: ${({ theme }) => `.0625rem solid ${theme.getColor(EColors.lighterGrey)}`};
        }
    }
    
    &.MuiButton-containedPrimary {
        margin-right: 0;
    }
`;

interface TitleInputModalProps {
    type: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (title: string) => void;
}

const TitleInputModal: React.FC<TitleInputModalProps> = ({ type, open, onClose, onSubmit }) => {
    const [title, setTitle] = useState('');
    const [error, setError] = useState<string | null>(null);

    const getModalText = () => {
        switch (type) {
            case 'invite':
                return { title: "Add Invite Title", description: "Give your invite a title" };
            case 'registration_page':
                return { title: "Add Registration Title", description: "Give your registration a title" };
            case 'note':
                return { title: "Add Survey/Note Title", description: "Give your survey/note a title" };
            default:
                return { title: "", description: "" };
        }
    };

    const { title: modalTitle, description } = getModalText();

    const handleSubmit = () => {
        if (title.trim()) {
            onSubmit(title);
            onClose();
        } else {
            setError("Title cannot be empty.");
        }
    };

    const handleCloseSnackbar = () => {
        setError(null);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <WideDialogContent>
                <Column itemSpacing="small">
                    <H2Headline>{modalTitle}</H2Headline>
                    <PageDescription large>{description}</PageDescription>
                    <TextField
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Example: Senior Leadership Invite"
                        variant="outlined"
                        error={!!error}
                        helperText={error}
                    />
                    <Row itemSpacing="smallish" style={{ marginTop: '1rem' }}>
                        <MenuActionButtons variant="outlined" onClick={onClose} cancelButton>
                            {i18n.button.back}
                        </MenuActionButtons>
                        <MenuActionButtons
                            onClick={handleSubmit}
                            disabled={!title.trim()}
                        >
                            {i18n.button.continue}
                        </MenuActionButtons>
                    </Row>
                </Column>
            </WideDialogContent>
            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={error}
            />
        </Dialog>
    );
};

export default TitleInputModal;
