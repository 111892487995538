import { RichTextDisplay } from 'components/Form/fields';
import React from 'react';
import styled from 'styled-components';
import { Column, Copy, Row, TSpacerNames } from 'ui';

const AgendaBold = styled(Copy)`
    font-weight: 700;
`;

const AgendaDuration = styled(AgendaBold)`
    min-width: 80px;
`;

const AgendaTitle = AgendaBold;

export default function AgendaTimeTable({
    agenda,
    withBorderPadding,
}: {
    agenda?: BizlyAPI.ScheduleAgendaEntry[];
    withBorderPadding?: TSpacerNames;
}) {
    return agenda ? (
        <Column itemSpacing="medium" withBorderPadding={withBorderPadding}>
            {agenda?.map(({ title, duration, description }, idx) => (
                <Row key={`${title}-${idx}`} itemSpacing="default">
                    <AgendaDuration>{duration ? `${duration} min${duration !== 1 ? 's' : ''}` : '-'}</AgendaDuration>
                    <Column>
                        <AgendaTitle>{title}</AgendaTitle>
                        {description && <RichTextDisplay value={description} />}
                    </Column>
                </Row>
            ))}
        </Column>
    ) : null;
}
