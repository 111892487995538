import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { EColors } from 'theme';

export type EColorsKeys = keyof typeof EColors;

type ColorFnsDict = Record<EColorsKeys, Themed.Color>;

export default function useThemedColor(opacity: number = 1) {
    const theme = useContext(ThemeContext);
    const themedColors = (Object.keys(EColors) as EColorsKeys[]).reduce<Partial<ColorFnsDict>>(
        (dict, colorRef: EColorsKeys) => {
            Object.defineProperty(dict, colorRef, {
                get: () => theme.getColor(EColors[colorRef], opacity),
            });

            return dict;
        },
        {}
    ) as ColorFnsDict;

    return themedColors;
}
