import Form from 'components/Form';
import { SelectField, TextArea } from 'components/FormFields';
import React from 'react';
import { i18n } from 'translation';
import { Spacer } from 'ui';

const RejectionReasonForm = ({
    options,
    value,
    onChange,
    readonly,
}: {
    options?: BizlyAPI.InquiryRejectionReason[];
    value?: { reason: number | string; note: string };
    onChange?: ({ value }: { value: { reason: number; note: string } }) => void;
    readonly?: boolean;
}) => {
    const rejectionFields = {
        reason: {
            prompt: i18n.proposalForm.modal.rejectReason,
            type: SelectField,
            options: {
                // TODO: localize the reason options coming from the backend
                options: options ? options.map(({ id, reason }) => ({ id, name: reason })) : [],
                placeholder: i18n.proposalForm.modal.reasonPlaceholder,
            },
            perRow: '2/3',
        },
        note: {
            prompt: i18n.proposalForm.modal.notes,
            type: TextArea,
            options: {
                placeholder: i18n.proposalForm.modal.notesPlaceholder,
            },
            optional: !(value?.reason === 1 || value?.reason === 2 || value?.reason === 4),
        },
    };

    const rejectionSchema = [
        { fields: ['reason'], spacing: 'small' },
        { fields: ['note'], spacing: false },
    ];

    return (
        <>
            <Spacer small />
            <Form
                fields={rejectionFields}
                schema={rejectionSchema}
                onChange={onChange}
                value={value ?? {}}
                readonly={readonly}
                hideReadonlyEmpty
            />
        </>
    );
};

export default RejectionReasonForm;
