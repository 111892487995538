import React from 'react';
import { loadEventOptions } from '../api';
import { useAsync } from '../hooks/useAsync';

const initialState: Bizly.EventOptions = {
    attendeeQuestionDefinitions: [],
    avOptions: [],
    dietaryRestrictionOptions: [],
    fbOptions: [],
    foodAllergyOptions: [],
    setupOptions: [],
};

export const EventOptionsContext = React.createContext(initialState);

export function useEventOptions() {
    const context = React.useContext(EventOptionsContext);
    if (context === undefined) {
        throw new Error('useEventOptions must be used within a EventOptionsProvider');
    }
    return context;
}

type EventOptionsProviderProps = {
    eventId: string;
    children: React.ReactNode;
};

function EventOptionsProvider({ eventId, children }: EventOptionsProviderProps) {
    const { data: eventOptions } = useAsync(
        React.useCallback(() => loadEventOptions(eventId), [eventId]),
        initialState
    );

    return (
        <EventOptionsContext.Provider value={eventOptions as Bizly.EventOptions}>
            {children}
        </EventOptionsContext.Provider>
    );
}

export default EventOptionsProvider;
