import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { Box, styled, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import { type Editor } from '@tiptap/react';
import React from 'react';
import { EColors, getColor } from 'theme';

export const Container = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
    boxSizing: 'border-box',
    paddingBottom: spacing(1.25),
    marginBottom: spacing(1.25),
    gap: spacing(1),
}));

export const Divider = styled(Box)(({ theme: { getColor, EColors } }) => ({
    height: '20px',
    width: '1px',
    backgroundColor: getColor(EColors.bizlyOSBorder),
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme: { spacing, shape } }) => ({
    gap: spacing(0.6),
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        height: '20px',
        width: '20px',
        border: 0,
        borderRadius: shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
}));

type ToolbarProps = {
    editor: Editor | null;
    isAiWrite?: boolean;
};

function Toolbar({ editor, isAiWrite = false }: ToolbarProps) {
    const [formats, setFormats] = React.useState<string[]>([]);

    const handleFormat = (_: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
        setFormats(newFormats);
    };

    if (!editor) {
        return null;
    }

    return (
        <Container>
            <StyledToggleButtonGroup size="small" value={formats} onChange={handleFormat} aria-label="text formatting">
                <ToggleButton value="bold" onClick={() => editor.chain().focus().toggleBold().run()}>
                    <FormatBoldIcon sx={{ fontSize: 16 }} />
                </ToggleButton>
                <ToggleButton value="italic" onClick={() => editor.chain().focus().toggleItalic().run()}>
                    <FormatItalicIcon sx={{ fontSize: 16 }} />
                </ToggleButton>
                <ToggleButton value="underlined" onClick={() => editor.chain().focus().toggleUnderline().run()}>
                    <FormatUnderlinedIcon sx={{ fontSize: 16 }} />
                </ToggleButton>
            </StyledToggleButtonGroup>
            {isAiWrite && (
                <>
                    <Divider />
                    <AutoAwesomeIcon sx={{ fontSize: 16, color: getColor(EColors.bizlyOSPrimary) }} />
                    <Typography variant="body2" fontWeight="500">
                        Write with AI
                    </Typography>
                </>
            )}
        </Container>
    );
}

export default Toolbar;
