import useShowModal from 'hooks/useShowModal';
import { useUser } from 'providers/user';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCreateMeeting = () => {
    const { user } = useUser();
    const navigate = useNavigate();

    const { modalShown: createModalShown, showModal: showCreateModal, hideModal: hideCreateModal } = useShowModal();

    const {
        modalShown: protocolModalShown,
        showModal: showProtocolModal,
        hideModal: hideProtocolModal,
    } = useShowModal();

    const createEvent = useCallback(() => {
        if (user.team?.authMeetingCreateRedirect) return showProtocolModal();
        if (user?.featureFlags?.createMeetingsFlow) return navigate(`/events/new`);

        showCreateModal();
    }, [navigate, showProtocolModal, showCreateModal, user]);

    const closeCreateModal = useCallback(() => {
        hideCreateModal();
    }, [hideCreateModal]);

    return {
        createEvent,
        createModalShown,
        protocolModalShown,
        closeCreateModal,
        hideProtocolModal,
    };
};
