import { zodResolver } from '@hookform/resolvers/zod';
import { Avatar, Box, MenuItem, TextField, Typography, styled } from '@mui/material';
import {
    useDeleteStaffMemberMutation,
    useStaffRoles,
    useUpdateStaffMemberMutation,
} from 'hooks/queries/BizlyOS/useStaffQuery';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BIZLYOS_CONTACT_TYPES, DEPARTMENT_OPTIONS } from 'shared';
import { EColors, getColor } from 'theme';
import { tzMoment, userTimeZone } from 'utils/moment';
import * as z from 'zod';
import { Button } from './Button/Button';
import { SideDrawer } from './SideDrawer/SideDrawer';
import { Switch, ToggleSwitchBox } from './ToggleSwitch/ToggleSwitch';

const StyledTextField = styled(TextField)(() => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: getColor(EColors.bizlyOSBorder),
        },
    },
}));

export const StaffProfileSchema = z.object({
    id: z.number().or(z.string()),
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z.string().email('Invalid email address'),
    phone: z.string().min(1, 'Phone number is required'),
    title: z.string().min(1, 'Title is required'),
    department: z.string().min(1, 'Department is required'),
    contactType: z.string().min(1, 'Contact type is required'),
    permission: z.number().min(1, 'Permission is required'),
    receiveEmails: z.boolean(),
    imageUrl: z.string().optional(),
    joinedDate: z.string().optional(),
});

export type StaffProfileFormData = z.infer<typeof StaffProfileSchema>;

interface StaffProfileDrawerProps {
    open: boolean;
    onClose: () => void;
    staffMember: StaffProfileFormData;
    isCurrentUserAdmin?: boolean;
}

const RemoveUserConfirmation = ({
    onCancel,
    onConfirm,
    userName,
}: {
    onCancel: () => void;
    onConfirm: () => void;
    userName: string;
}) => (
    <Box
        sx={{
            p: 2,
            border: '1px solid',
            borderColor: getColor(EColors.warningBackground),
            borderRadius: 1,
            mb: 3,
            height: '8.125rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}
    >
        <Typography variant="h6">Remove {userName}?</Typography>
        <Typography variant="body2">They will permanently lose all Bizly access and cannot be undone.</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="outline" onClick={onCancel}>
                Cancel
            </Button>
            <Button color="error" onClick={onConfirm}>
                Delete
            </Button>
        </Box>
    </Box>
);

export const StaffProfileDrawer = ({ open, onClose, staffMember, isCurrentUserAdmin }: StaffProfileDrawerProps) => {
    const { venueId, user } = useBizlyOSUser();
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const isCurrentUser = user?.id === staffMember.id;

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        reset,
        watch,
    } = useForm<StaffProfileFormData>({
        resolver: zodResolver(StaffProfileSchema),
        defaultValues: staffMember,
        mode: 'onBlur',
    });

    useEffect(() => {
        reset(staffMember);
    }, [staffMember, reset]);

    const updateStaffMutation = useUpdateStaffMemberMutation(venueId, staffMember.id);
    const deleteStaffMember = useDeleteStaffMemberMutation(venueId);
    const { roles } = useStaffRoles(venueId);

    const onSubmit = (data: StaffProfileFormData) => {
        updateStaffMutation.mutate(
            {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone: data.phone,
                role_id: data.permission,
                title: data.title,
                department: data.department,
                contact_type: data.contactType,
                notifications: {
                    inquiries: data.receiveEmails,
                },
            },
            {
                onSuccess: () => onClose(),
            }
        );
    };

    const handleRemoveUser = () => {
        setShowRemoveConfirmation(true);
    };

    const handleCancelRemove = () => {
        setShowRemoveConfirmation(false);
    };

    const handleConfirmRemove = () => {
        if (staffMember.id) {
            deleteStaffMember.mutate(String(staffMember.id), {
                onSuccess: () => {
                    handleCancelRemove();
                    onClose();
                },
            });
        }

        onClose();
    };

    const watchedFields = watch();
    const isProfile = isCurrentUser ? 'Your Profile' : `${staffMember.firstName} ${staffMember.lastName}`;

    return (
        <SideDrawer
            drawerOpen={open}
            onClose={() => {
                onClose();
                handleCancelRemove();
            }}
            title={isProfile}
            footer={
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button variant="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || !isCurrentUserAdmin}>
                        Save
                    </Button>
                </Box>
            }
        >
            <Box>
                {showRemoveConfirmation && (
                    <RemoveUserConfirmation
                        onCancel={handleCancelRemove}
                        onConfirm={handleConfirmRemove}
                        userName={`${watchedFields.firstName} ${watchedFields.lastName}`}
                    />
                )}

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 3,
                        bgcolor: getColor(EColors.drWhite),
                        padding: '1.25rem',
                        justifyContent: 'space-between',
                        height: '5rem',
                        borderRadius: '4px',
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Avatar
                            // We need to update the default avatar image on cloudinary so I kept this ternary logic in place to show default Mui logo
                            src={watchedFields.imageUrl?.includes('default-avatar') ? '' : watchedFields.imageUrl}
                            alt={`${watchedFields.firstName} ${watchedFields.lastName}`}
                            sx={{ width: 64, height: 64, mr: 2 }}
                        />
                        <Box>
                            <Typography variant="h6">
                                {watchedFields.firstName} {watchedFields.lastName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {watchedFields.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Joined{' '}
                                {tzMoment(watchedFields.joinedDate, 'UTC').tz(userTimeZone).format('MMM D, YYYY')}
                            </Typography>
                        </Box>
                    </Box>
                    {!isCurrentUser && !showRemoveConfirmation && isCurrentUserAdmin && (
                        <Box sx={{ mb: 'auto' }}>
                            <Button variant="text" color="error" disableElevation onClick={handleRemoveUser}>
                                Remove User
                            </Button>
                        </Box>
                    )}
                </Box>

                <Box
                    sx={{
                        border: '1px solid',
                        borderColor: getColor(EColors.bizlyOSBorder),
                        borderRadius: 1,
                        p: 3,
                        mb: 2,
                    }}
                >
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field }) => (
                                <StyledTextField
                                    {...field}
                                    fullWidth
                                    label="First Name"
                                    required
                                    error={!!errors.firstName}
                                    helperText={errors.firstName?.message}
                                    disabled={!isCurrentUserAdmin}
                                />
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field }) => (
                                <StyledTextField
                                    {...field}
                                    fullWidth
                                    label="Last Name"
                                    required
                                    error={!!errors.lastName}
                                    helperText={errors.lastName?.message}
                                    disabled={!isCurrentUserAdmin}
                                />
                            )}
                        />
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <StyledTextField
                                    {...field}
                                    fullWidth
                                    label="Business Email"
                                    required
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    disabled={!isCurrentUserAdmin}
                                />
                            )}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => (
                                <StyledTextField
                                    {...field}
                                    fullWidth
                                    label="Phone Number"
                                    required
                                    error={!!errors.phone}
                                    helperText={errors.phone?.message}
                                    disabled={!isCurrentUserAdmin}
                                />
                            )}
                        />
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <StyledTextField
                                    {...field}
                                    fullWidth
                                    label="Job Title"
                                    required
                                    error={!!errors.title}
                                    helperText={errors.title?.message}
                                    disabled={!isCurrentUserAdmin}
                                />
                            )}
                        />
                        <Controller
                            name="department"
                            control={control}
                            render={({ field }) => (
                                <StyledTextField
                                    {...field}
                                    fullWidth
                                    select
                                    label="Department"
                                    required
                                    error={!!errors.department}
                                    helperText={errors.department?.message}
                                    disabled={!isCurrentUserAdmin}
                                >
                                    {DEPARTMENT_OPTIONS.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </StyledTextField>
                            )}
                        />
                        <Controller
                            name="contactType"
                            control={control}
                            render={({ field }) => (
                                <StyledTextField
                                    {...field}
                                    fullWidth
                                    select
                                    label="Contact Type"
                                    required
                                    error={!!errors.contactType}
                                    helperText={errors.contactType?.message}
                                    disabled={!isCurrentUserAdmin}
                                >
                                    {BIZLYOS_CONTACT_TYPES.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </StyledTextField>
                            )}
                        />
                        <Controller
                            name="permission"
                            control={control}
                            render={({ field }) => (
                                <StyledTextField
                                    {...field}
                                    fullWidth
                                    select
                                    label="Permission"
                                    required
                                    error={!!errors.permission}
                                    helperText={errors.permission?.message}
                                    disabled={!isCurrentUserAdmin}
                                >
                                    {roles && roles.length ? (
                                        roles.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="">No roles available</MenuItem>
                                    )}
                                </StyledTextField>
                            )}
                        />
                    </Box>

                    <Controller
                        name="receiveEmails"
                        control={control}
                        render={({ field }) => (
                            <ToggleSwitchBox isOn={field.value} sx={{ marginTop: '20px' }}>
                                <Typography>Receive event inquiry emails</Typography>
                                <Switch {...field} checked={field.value} disabled={!isCurrentUserAdmin} />
                            </ToggleSwitchBox>
                        )}
                    />
                </Box>
            </Box>
        </SideDrawer>
    );
};
