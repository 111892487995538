import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { RichTextCopy } from '../../ui';
import StepsRow from './StepsRow';

type CompleteAndSignProps = {
    number: number;
    copy: string;
};

const Copy = styled(RichTextCopy)<any>`
    font-size: 15px;
    line-height: 1.47;

    ul {
        padding-inline-start: 1.2em;
    }
`;

const defaultCopy = `<p>${i18n.venue.proposal.sign.helper}</p>
<p>${i18n.venue.proposal.sign.acknowledgement}</p>
<p>
    <ul>
        <li>${i18n.venue.proposal.sign.note1}</li>
        <li>${i18n.venue.proposal.sign.note2}</li>
        <li>${i18n.venue.proposal.sign.note3}</li>
        <li>${i18n.venue.proposal.sign.note4}</li>
    </ul>
</p>`;

const CompleteAndSign = forwardRef(
    ({ number, copy = defaultCopy }: CompleteAndSignProps, ref: React.Ref<HTMLDivElement>) => {
        return (
            <StepsRow number={number} headline={i18n.venue.proposal.sign.headline} ref={ref}>
                <Copy dangerouslySetInnerHTML={{ __html: copy }} />
            </StepsRow>
        );
    }
);

export default CompleteAndSign;
