import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy as UICopy, Row, Spacer } from '../../ui';

const Headline = styled.h3`
    font-size: 1.125em;
    margin: 0;
    font-weight: normal;
    line-height: 1.5em;
`;

const Copy = styled(UICopy)`
    font-size: 0.94em;
    line-height: 1.2em;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const AccentedCopy = styled(Copy)`
    font-weight: 500;
    margin-bottom: 0.5em;
`;

type VenueContactProps = {
    contact: {
        firstName: string;
        lastName?: string;
        title?: string;
        email: string;
        phone?: string;
    };
    venue: {
        name: string;
        address: string;
    };
    onSendMessage?: () => void;
    booking?: Bizly.Booking;
    bookingCancelled?: boolean;
};

const joinName = (firstName: string, lastName?: string) => (lastName ? `${firstName} ${lastName}` : firstName);

const parseAddress = (address: string) => {
    const [street, city, state]: Array<string> = address.split(', ');
    return [street, `${city}, ${state}`];
};

export default function VenueContact({ contact, venue, onSendMessage, booking, bookingCancelled }: VenueContactProps) {
    return (
        <Column>
            <Headline>{i18n.venue.yourVenueContact}</Headline>
            <Spacer small />
            <Row justifyContent="space-between" alignItems="stretch" itemSpacing="large">
                <Column>
                    <Row itemSpacing="largest">
                        <Column>
                            <AccentedCopy>{joinName(contact.firstName, contact.lastName)}</AccentedCopy>
                            {contact.title && <Copy>{contact.title}</Copy>}
                            <Copy>{contact.email}</Copy>
                            <Copy>{contact.phone}</Copy>
                        </Column>

                        <Column>
                            <AccentedCopy>{venue.name}</AccentedCopy>
                            {venue.address &&
                                parseAddress(venue.address).map((line, idx) => <Copy key={line + idx}>{line}</Copy>)}
                        </Column>
                    </Row>
                </Column>
            </Row>
        </Column>
    );
}
