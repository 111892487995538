import Popover from '@material-ui/core/Popover';
import Form from 'components/Form';
import { Spinner } from 'components/Spinner';
import TextButton from 'components/ui/Button/TextButton';
import HoverableIcon from 'components/ui/HoverableIcon';
import SettingsIconSVG from 'images/icons/gear.svg?react';
import { useSnackbar } from 'notistack';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useState } from 'react';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, CopyFaded, Row, Spacer } from 'ui';

const SettingsIcon = withInteractibleIconStyles(SettingsIconSVG);

const Pop = styled(Column)`
    width: 340px;

    box-sizing: border-box;
    padding: 32px;
`;

const Centered = styled(Column)`
    flex: 1;
    align-items: center;
    justify-content: center;
`;

interface EventSettingsFieldsConfig {
    internalReferencePrompt?: string;
    meetingCostCenterFieldLabel?: string;
    disabledFields?: string[];
    requiredFields?: Bizly.EventCreateInternalFields[];
    budgetRequired?: boolean;
    costCenterOptions?: Nullable<string[]>;
    departmentOptions?: Nullable<string[]>;
}
export const EventSettingsFields = (config: EventSettingsFieldsConfig) => ({
    type: {
        prompt: i18n.meetingDashboard.headerSection.settings.meetingType,
        type: 'radioselect',
        options: {
            options: [
                i18n.meetingDashboard.headerSection.settings.internal,
                i18n.meetingDashboard.headerSection.settings.external,
            ],
        },
        optional: !config.requiredFields?.includes('type'),
        disabled: config.disabledFields?.includes('type'),
    },
    costCenter: {
        prompt: config.meetingCostCenterFieldLabel
            ? config.meetingCostCenterFieldLabel
            : i18n.meetingDashboard.headerSection.settings.costCenter,
        type: config.costCenterOptions ? 'select' : 'text',
        ...(config.costCenterOptions
            ? {
                  options: {
                      options: ['', ...config.costCenterOptions].map(option => ({
                          id: option,
                          label:
                              option === '' ? <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded> : option,
                      })),
                  },
              }
            : {}),
        optional: !config.requiredFields?.includes('costCenter'),
        disabled: config.disabledFields?.includes('costCenter'),
    },
    cventId: {
        prompt: config.internalReferencePrompt ?? i18n.meetingDashboard.headerSection.settings.internalReference,
        type: 'text',
        optional: !config.requiredFields?.includes('cventId'),
        disabled: config.disabledFields?.includes('cventId'),
    },
    department: {
        prompt: i18n.meetingDashboard.headerSection.settings.department,
        type: config.departmentOptions ? 'select' : 'text',
        optional: !config.requiredFields?.includes('department'),
        disabled: config.disabledFields?.includes('department'),
        ...(config.departmentOptions
            ? {
                  options: {
                      options: ['', ...config.departmentOptions].map(option => ({
                          id: option,
                          label:
                              option === '' ? <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded> : option,
                      })),
                  },
              }
            : {}),
    },
    budget: {
        prompt: i18n.meetingDashboard.headerSection.settings.budget,
        type: 'text',
        optional: !config.budgetRequired,
        disabled: config.disabledFields?.includes('budget'),
    },
});

export default function EventSettingsHeaderSection() {
    const { event, onEventChange } = useEvent();
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const [eventSettingsAnchor, setEventSettingsAnchor] = useState<Nullable<Element>>(null);
    const [stagedEvent, setStagedEvent] = useState(event);
    const [updating, setUpdating] = useState(false);

    const schema = [
        ...(user.team?.features.hiddenInternalFields.includes('type')
            ? []
            : [
                  {
                      key: 'type',
                      fields: ['type'],
                      spacing: 'large',
                  },
              ]),
        ...(user.team?.features.hiddenInternalFields.includes('costCenter')
            ? []
            : [
                  {
                      key: 'costCenter',
                      fields: ['costCenter'],
                  },
              ]),
        ...(user.team?.features.hiddenInternalFields.includes('cventId')
            ? []
            : [{ key: 'cventId', fields: ['cventId'] }]),
    ];

    async function handleEventUpdate() {
        setUpdating(true);
        await onEventChange(stagedEvent);

        try {
            onEventChange(stagedEvent);
        } catch (err) {
            enqueueSnackbar(i18n.error.submission, {
                variant: 'error',
            });
        } finally {
            setUpdating(false);
            setEventSettingsAnchor(null);
        }
    }

    const handleEventChange = ({ value }: { value: Bizly.Event }) => setStagedEvent(value);

    const handleDiscardChanges = () => {
        setStagedEvent(event);
        setEventSettingsAnchor(null);
    };

    // if the schema is empty, it means all fields are hidden per team settings and we shouldn't render the icon
    return schema.length === 0 ? (
        <></>
    ) : (
        <>
            <HoverableIcon hoverText={i18n.meetingDashboard.headerSection.settings.meetingSettings}>
                <SettingsIcon
                    style={{ marginRight: 16 }}
                    onClick={e => {
                        if (e.target instanceof Element) {
                            setEventSettingsAnchor(eventSettingsAnchor ? null : e.target);
                        }
                    }}
                />
            </HoverableIcon>
            {eventSettingsAnchor && (
                <Popover
                    anchorEl={eventSettingsAnchor}
                    open={!!eventSettingsAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={handleDiscardChanges}
                >
                    <Pop>
                        <Copy large>{i18n.meetingDashboard.headerSection.settings.meetingSettings}</Copy>
                        <Spacer larger />
                        {updating ? (
                            <Centered>
                                <Spinner />
                            </Centered>
                        ) : (
                            <>
                                <Form
                                    fields={EventSettingsFields({
                                        meetingCostCenterFieldLabel: user.team?.meetingCostCenterFieldLabel,
                                        internalReferencePrompt: user.team?.meetingInternalReferenceFieldLabel,
                                        disabledFields: user.team?.authMeetingCreateFields,
                                        costCenterOptions: user.team?.costCenters,
                                        departmentOptions: user.team?.departments,
                                    })}
                                    schema={schema}
                                    value={stagedEvent}
                                    errors={{}}
                                    onChange={handleEventChange}
                                    disabled={!event.editable}
                                />
                                {event.editable && (
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <TextButton onClick={handleEventUpdate}>{i18n.button.save}</TextButton>
                                    </Row>
                                )}
                            </>
                        )}
                    </Pop>
                </Popover>
            )}
        </>
    );
}
