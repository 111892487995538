import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AddIcon from '@mui/icons-material/Add';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { Box, Stack, styled as muiStyled } from '@mui/material';
import { DataGridPro, GridNoRowsOverlay, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro';
import ActionItemsModal from 'components/ActionItems/ActionItemsModal';
import { TToken } from 'components/Tokenizer';
import { Button } from 'components/Ui-V2/Button/Button';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { Tab, TabContainer } from 'components/Ui-V2/TabGroup/TabGroup';
import { EventType, STATUS_LABELS } from 'constants/event';
import { useEventsQuery } from 'hooks/queries/useEventsQuery';
import { useBudgetApprovalRequests, useTeamMembersQuery } from 'hooks/queries/useQueries';
import useShowModal from 'hooks/useShowModal';
import EmptyStateImage from 'images/empty-pages-assets/empty_events.svg';
import { useUser } from 'providers/user';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from 'translation';
import { tzMoment, userTimeZone } from 'utils/moment';
import EventFilters, { TEventFilters } from './EventFilters';
import { EventTableToolbar } from './EventTableToolbar';
import { EventsTableColumns, getActionItemsTableColumns } from './tableColumns';

const BorderlessCard = muiStyled(Box)<{ flex?: boolean }>(({ flex }) => ({
    position: 'relative',
    flex: flex ? 1 : 'unset',
    boxSizing: 'border-box',
}));

const StyledDataGridPro = muiStyled(DataGridPro)(({ theme }) => ({
    flexGrow: 1,

    '&.MuiDataGrid-root': {
        maxHeight: '100%',
    },
    '& .MuiToggleButtonGroup-root': {
        margin: theme.spacing(0.5, 0),
    },
    '& .MuiDataGrid-columnHeader': {
        borderRight: 'none',
        backgroundColor: theme.palette.grey[100],
    },
    '& .MuiDataGrid-overlayWrapper': {
        height: 'auto',
        marginTop: '104px',
    },
    '& .MuiDataGrid-footerContainer': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderTopWidth: 0,
    },
    '& .MuiDataGrid-row': {
        '--rowBorderColor': 'transparent',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-overlayWrapperInner': {
        height: 'auto !important',
    },
}));

type EmptyStateText = {
    title: string;
    description: string;
};

const emptyStateTexts: Record<'admin' | 'user', Record<TabValue, EmptyStateText>> = {
    admin: {
        all: { title: 'Nothing planned yet', description: 'Any event you or your team creates will show here.' },
        upcoming: { title: 'No Upcoming Event', description: 'Any upcoming event will show here.' },
        createdByOwner: {
            title: 'Host Your Next Event',
            description: `You've not created any event yet. Let's change that!`,
        },
        actionItems: {
            title: 'Nothing planned yet',
            description: 'Any event you or your team creates will show here.',
        },
    },
    user: {
        all: { title: 'Nothing planned yet', description: 'Any event you create will show here.' },
        upcoming: { title: 'No Upcoming Event', description: 'Your upcoming event will show here' },
        createdByOwner: {
            title: 'Host Your Next Event',
            description: `You've not created any event yet. Let's change that!`,
        },
        actionItems: { title: 'Nothing planned yet', description: 'Any event you create will show here.' },
    },
};

type EventTableProps = {
    createEvent?: () => void;
};

export const EventTable = ({ createEvent }: EventTableProps) => (
    <BorderlessCard flex={true}>
        <EventTableContent createEvent={createEvent} />
    </BorderlessCard>
);

export type TabValue = 'all' | 'upcoming' | 'createdByOwner' | 'actionItems';

export type TabConfigItem = {
    icon: React.ReactNode;
    label: string;
};

const EventTableContent = ({ createEvent }: EventTableProps) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<TEventFilters>({} as TEventFilters);
    const [showFiltersMenu, setShowFiltersMenu] = useState(false);

    const [tab, setTab] = useState<TabValue>('upcoming');
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'startsAt', sort: 'desc' }]);
    const [selectedItem, setSelectedItem] = useState<BizlyAPI.BudgetApproval>();

    const { data: eventsData, isLoading: isLoadingEvents } = useEventsQuery({ include: ['collaborators'] });
    const { budgetApprovalRequests, isLoading: isLoadingBudgetApprovalRequests } = useBudgetApprovalRequests(false);
    const { data: membersData } = useTeamMembersQuery(user.team?.id ?? '');
    const { modalShown, showModal, hideModal } = useShowModal();

    const isBudgetApprover = user.id === user?.team?.budgetApprover?.id;

    const viewEvent = useCallback(
        (eventId: number) => {
            if (user.featureFlags?.createMeetingsFlow) {
                return navigate(`/events/${eventId}`);
            }
            return navigate(`/event/${eventId}`);
        },
        [navigate, user.featureFlags?.createMeetingsFlow]
    );

    const tabConfig = useMemo(() => {
        const baseConfig: Partial<Record<TabValue, TabConfigItem>> = {
            upcoming: {
                icon: <AccessTimeIcon fontSize="small" />,
                label: i18n.homepage.eventTableActions.upcoming,
            },
            createdByOwner: {
                icon: <AccountCircleOutlinedIcon fontSize="small" />,
                label: i18n.homepage.eventTableActions.createdByYou,
            },
            all: {
                icon: <ViewHeadlineIcon fontSize="small" />,
                label: i18n.homepage.eventTableActions.all,
            },
        };

        if (isBudgetApprover) {
            baseConfig.actionItems = {
                icon: <EventAvailableOutlinedIcon fontSize="small" />,
                label: i18n.homepage.eventTableActions.actionItems,
            };
        }

        return baseConfig;
    }, [isBudgetApprover]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: TabValue) => {
        setTab(newValue);
        if (newValue === 'createdByOwner') {
            const owner = membersData?.members.find(m => m.user.id === user.id);
            setFilters(prev => ({
                ...prev,
                owners: owner ? [owner] : [],
            }));
        }
    };

    useEffect(() => {
        switch (tab) {
            case 'all':
                setSortModel([{ field: 'status', sort: 'asc' }]);
                break;
            case 'upcoming':
                setSortModel([{ field: 'startsAt', sort: 'asc' }]);
                break;
            default:
                setSortModel([{ field: 'startsAt', sort: 'desc' }]);
        }
    }, [tab]);

    const toggleFiltersMenu = () => {
        setShowFiltersMenu(prev => !prev);
    };

    const tokens = useMemo(() => {
        const result: TToken[] = [];
        if (filters.types && filters.types.length > 0) {
            result.push(
                ...filters.types.map(type => ({
                    id: type,
                    label: type,
                    onRemove: () => {
                        const newTypes = filters.types.filter(t => t !== type);
                        setFilters(prev => ({
                            ...prev,
                            types: newTypes,
                        }));
                    },
                }))
            );
        }
        if (filters.status && filters.status.length > 0) {
            result.push(
                ...filters.status.map(statusFilter => ({
                    id: statusFilter,
                    label: statusFilter,
                    onRemove: () => {
                        const newStatus = filters.status.filter(s => s !== statusFilter);
                        setFilters(prev => ({
                            ...prev,
                            status: newStatus,
                        }));
                    },
                }))
            );
        }
        if (filters.startDate && filters.startDate.length === 2) {
            result.push({
                id: 'startDate',
                label: `${filters.startDate[0]?.format('LL')} - ${filters.startDate[1]?.format('LL')}`,
                onRemove: () => {
                    setFilters(prev => ({
                        ...prev,
                        startDate: undefined,
                    }));
                },
            });
        }
        if (filters.owners && filters.owners.length > 0) {
            result.push(
                ...filters.owners.map(owner => ({
                    id: owner.id,
                    label: `Owner: ${owner.user.firstName} ${owner.user.lastName}`,
                    onRemove: () => {
                        const newOwners = filters.owners.filter(o => o.id !== owner.id);
                        setFilters(prev => ({
                            ...prev,
                            owners: newOwners,
                        }));
                    },
                }))
            );
        }
        if (filters.collaborators && filters.collaborators.length > 0) {
            result.push(
                ...filters.collaborators.map(collaborator => ({
                    id: collaborator.id,
                    label: `Collaborator: ${collaborator.firstName} ${collaborator.lastName}`,
                    onRemove: () => {
                        const newCollaborators = filters.collaborators.filter(c => c.id !== collaborator.id);
                        setFilters(prev => ({
                            ...prev,
                            collaborators: newCollaborators,
                        }));
                    },
                }))
            );
        }
        return result;
    }, [filters]);

    const filteredEvents = useMemo(() => {
        if (!eventsData?.events) return [];

        let result = eventsData.events;

        // Filter by tab
        switch (tab) {
            case 'all':
                break;
            case 'upcoming': {
                const now = tzMoment().tz(userTimeZone);
                result = result.filter(event => {
                    if (!event.startsAt || event.cancelledAt) {
                        return false;
                    }
                    const meetingStartTime = tzMoment(event.startsAt, event.timeZone ?? userTimeZone).tz(userTimeZone);
                    return meetingStartTime.isAfter(now);
                });
                break;
            }
            case 'createdByOwner':
                result = result.filter(event => user.email === event.plannedBy.email);
                break;
            case 'actionItems':
                return [];
            default:
                return [];
        }

        // Filter by type
        if (filters.types && filters.types.length === 1) {
            // Apply filter only when one type is selected
            const filter = filters.types[0];
            result = result.filter(
                event =>
                    (filter === EventType.SCHEDULED && event.startsAt) ||
                    (filter === EventType.UNSCHEDULED && !event.startsAt)
            );
        }

        // Filter by status
        if (filters.status && filters.status.length > 0) {
            result = result.filter(event => filters.status.indexOf(STATUS_LABELS[event.status.code]) >= 0);
        }

        // Filter by start date
        if (filters.startDate && filters.startDate.length === 2) {
            const [fromDate, toDate] = filters.startDate;
            result = result.filter(event => {
                if (!event.startsAt) return false;
                const startsAt = tzMoment(event.startsAt);
                return startsAt.isAfter(fromDate) && startsAt.isBefore(toDate);
            });
        }

        // Filter by owners
        if (filters.owners && filters.owners.length > 0) {
            const ownerIds = filters.owners.map(owner => owner.user.email.toLowerCase());
            result = result.filter(event => ownerIds.indexOf(event.plannedBy.email.toLowerCase()) >= 0);
        }

        // Filter by owners
        if (filters.collaborators && filters.collaborators.length > 0) {
            const filterCollaboratorUserEmails = filters.collaborators.map(c => c.email.toLowerCase());
            result = result.filter(event => {
                const eventCollaboratorUserEmails = event.collaborators.map(c => c.email.toLowerCase());
                return eventCollaboratorUserEmails.some(
                    userEmail => filterCollaboratorUserEmails.indexOf(userEmail) >= 0
                );
            });
        }

        return result;
    }, [eventsData, tab, user.email, filters]);

    const actionItemsTableColumns = useMemo(() => {
        return getActionItemsTableColumns((item: BizlyAPI.BudgetApproval) => {
            showModal();
            setSelectedItem(item);
        });
    }, [showModal]);

    const handleRowClick = (params: GridRowParams<Bizly.Event>) => {
        viewEvent(params.row.id);
    };

    const rows = tab === 'actionItems' ? budgetApprovalRequests : filteredEvents;
    const rowCount = tab === 'actionItems' ? budgetApprovalRequests.length : eventsData?.events.length ?? 0;
    const isLoading = tab === 'actionItems' ? isLoadingBudgetApprovalRequests : isLoadingEvents;

    const { title, description } = useMemo(
        () => (isBudgetApprover ? emptyStateTexts.admin[tab] : emptyStateTexts.user[tab]),
        [tab, isBudgetApprover]
    );

    return (
        <Box>
            <TabContainer value={tab} onChange={handleTabChange}>
                {Object.entries(tabConfig).map(([value, { icon, label }]) => (
                    <Tab
                        key={value}
                        icon={icon}
                        label={label}
                        value={value}
                        showNotification={value === 'actionItems' && budgetApprovalRequests?.length > 0}
                    />
                ))}
            </TabContainer>
            <Spacer xsmall />
            <Stack>
                <StyledDataGridPro
                    pagination
                    autoHeight
                    disableColumnMenu
                    disableColumnResize
                    rows={rows}
                    columns={tab === 'actionItems' ? actionItemsTableColumns : EventsTableColumns}
                    loading={isLoading}
                    onRowClick={tab === 'actionItems' ? undefined : handleRowClick}
                    sortModel={sortModel}
                    onSortModelChange={(model: GridSortModel) => setSortModel(model)}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    rowCount={rowCount}
                    slots={{
                        toolbar: () => (
                            <EventTableToolbar
                                showEventFilters={tab !== 'actionItems'}
                                tokens={tokens}
                                onToggleFilters={toggleFiltersMenu}
                            />
                        ),
                        noRowsOverlay:
                            tab === 'actionItems'
                                ? GridNoRowsOverlay
                                : () => (
                                      <EmptyVisual
                                          image={<img src={EmptyStateImage} alt="No items" />}
                                          title={title}
                                          description={description}
                                          actionButton={
                                              <Button startIcon={<AddIcon />} onClick={createEvent}>
                                                  {i18n.homepage.eventButtons.newEvent}
                                              </Button>
                                          }
                                      />
                                  ),
                    }}
                />
            </Stack>

            {modalShown && selectedItem && (
                <ActionItemsModal actionTableItem={selectedItem} onClose={hideModal} setActionTableItems={() => {}} />
            )}

            <EventFilters
                collaborators={eventsData?.collaborators || []}
                filters={filters}
                onFilterChange={setFilters}
                open={showFiltersMenu}
                onClose={toggleFiltersMenu}
                ownerDisabled={tab === 'createdByOwner'}
            />
        </Box>
    );
};
