import * as Sentry from '@sentry/browser';
import { get, map, noop, zipObjectDeep } from 'lodash';
import mixpanelBrowser from 'mixpanel-browser';

let isMixpanelInitialized = false;

const MIXPANEL_TOKEN =
    import.meta.env.VITE_APP_ENV === 'prod'
        ? import.meta.env.VITE_APP_MIXPANEL_TOKEN_PRODUCTION
        : import.meta.env.VITE_APP_MIXPANEL_TOKEN;

if (MIXPANEL_TOKEN) {
    mixpanelBrowser.init(MIXPANEL_TOKEN);
    isMixpanelInitialized = true;
}

const actions = ['identify', 'reset', 'track', 'people.set'];

interface IMixpanel {
    identify: Function;
    reset: Function;
    track: Function;
    people: {
        set: Function;
    };
}

const mixpanel = zipObjectDeep(
    actions,
    map(actions, (action: string) => {
        const mixpanelFn = get(mixpanelBrowser, action, noop);

        const mixpanelFnScopePath = action.split('.').slice(0, -1).join('.');
        const mixpanelFnScope = get(mixpanelBrowser, mixpanelFnScopePath, mixpanelBrowser);

        return (...args: any[]) => {
            if (!isMixpanelInitialized) {
                return;
            }

            try {
                return mixpanelFn.apply(mixpanelFnScope, args);
            } catch (e) {
                Sentry.captureException(e);
            }
        };
    })
) as IMixpanel;

export default mixpanel;
