import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { i18n } from 'translation';
import PlaceholderImage from './PlaceholderImage';

type TImageContainerProps = {
    width?: string | number;
    height?: string | number;
};

const ImageContainer = styled(Box)<TImageContainerProps>(({ theme, width, height }) => ({
    width: width ?? '100%',
    height: height ?? '100%',
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
}));

const StyledImage = styled('img')({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
});

type TImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    placeholderSrc?: string;
};

const Image = ({ src, height, width, placeholderSrc, ...rest }: TImageProps) => {
    const [imgError, setImgError] = useState(false);

    const onImageError = () => {
        setImgError(true);
    };

    return (
        <ImageContainer width={width} height={height}>
            {!imgError && src ? (
                <StyledImage onError={onImageError} src={src} {...rest} />
            ) : placeholderSrc ? (
                <StyledImage src={placeholderSrc} {...rest} />
            ) : (
                <PlaceholderImage title={rest.alt || i18n.venue.imageNotAvailable} />
            )}
        </ImageContainer>
    );
};

export default Image;
