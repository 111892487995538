import { getExtension } from 'cloudinary';
import moment from 'moment-timezone';
import { i18n } from 'translation';
import { TProposalForm } from '../types';

type TFormAttachment = {
    title: string;
    format: string;
    bytes: number;
    cloudinaryId: string;
    resourceType: string;
    url: string;
};

export type TProposalNotesFormValues = {
    expiryDate?: string | Date | null;
    additionalDetails?: string;
    attachments: TFormAttachment[];
};

export const proposalFormToFormData = (proposal: TProposalForm['proposal']) => {
    return {
        ...proposal,
        attachments: (proposal.attachments || []).map(({ name, format, ...rest }) => ({
            title: name || '',
            cloudinaryId: '',
            resourceType: '',
            format: format || getExtension(rest.url),
            ...rest,
        })),
    };
};

export const formDataToProposalForm = (formData: TProposalNotesFormValues) => {
    return {
        proposal: {
            ...formData,
            expiryDate:
                typeof formData.expiryDate === 'string'
                    ? formData.expiryDate
                    : formData.expiryDate?.toISOString() || null,
            attachments: formData.attachments.map(({ title, format, bytes, url }) => ({
                name: title,
                format,
                bytes,
                url,
            })),
        },
    };
};

export const getErrorMessage = ({ expiryDate }: TProposalNotesFormValues, isContinue?: boolean) => {
    if (isContinue) {
        if (!expiryDate) return i18n.proposalForm.proposalNotes.error.spaceHeldEnd;

        if (moment(expiryDate).isSameOrBefore(moment(), 'day'))
            return i18n.proposalForm.proposalNotes.error.spaceHeldEndInvalid;
    }
};
