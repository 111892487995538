import { Popover } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Pill from 'components/ui/Pill';
import useThemedColor from 'hooks/useThemedColor';
import VendorPopover from 'pages/Vendors/components/VendorPopover';
import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { EColors } from 'types/themenum';
import { AlignedRow, Column, Copy } from 'ui';

const Tile = styled(Column)<{
    backgroundColor?: string;
    width?: string;
    large?: boolean;
    noPopover?: boolean;
}>`
    position: relative;

    width: ${props => props.width ?? '100%'};
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    padding: 12px;
    box-sizing: border-box;

    ${props => (props.onClick ? `cursor: pointer;` : ``)}

    border-radius: 4px;

    ${({ backgroundColor, theme: { getColor, EColors } }) =>
        backgroundColor
            ? `background-color: ${backgroundColor};`
            : `
            background-image: linear-gradient(
                to bottom,
                ${getColor(EColors.pureBlack, 0.2)} 17%,
                ${getColor(EColors.pureBlack, 0.8)}
            );
    
            opacity: 1; /* gradients can't be animated */
            transition: 0.2s all ease-out;
            `}

    ${({ large, noPopover }) =>
        !large &&
        !noPopover &&
        `
            &:hover::before {
            opacity: 0.8;
            }`}
`;

const PillContainer = styled(AlignedRow)`
    position: absolute;
`;

const TileImage = styled(Column)<{
    imgSrc?: string;
    fixedRatio?: string;
}>`
    width: 100%;
    flex: 1;

    border-radius: 4px;

    ${({ imgSrc }) =>
        imgSrc
            ? `
        background-image: url(${imgSrc});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 90%;
        `
            : ``};

    ${({ fixedRatio }) => (fixedRatio ? `padding-top:  ${fixedRatio}` : ``)};
`;

const TileCopy = styled(Column)`
    position: absolute;
    bottom: 12px;
    width: calc(100% - 24px);
`;

const WrappingCopy = styled(Copy)`
    word-break: break-word;
    font-size: 13px;
`;

const TilePopover = styled(Popover)`
    pointer-events: none;

    .MuiPopover-paper {
        border-radius: 8px;
        box-shadow: 0 2px 44px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.1)};

        pointer-events: all;
    }
`;

const CheckIcon = styled(CheckCircleOutlineIcon)`
    font-size: 15px;
    fill: ${({ theme: { getColor, EColors } }) => getColor(EColors.submittedInquiryStatus)};
`;

type TVendorTile = {
    vendor: BizlyAPI.Addon;
    team?: Bizly.Team;
    onClick?: (vendorId: number) => void;
    selected?: boolean;
    large?: boolean;
    width?: string;
    fixedRatio?: string;
    noPopover?: boolean;
    className?: string;
};

export default function VendorTile({
    vendor,
    team,
    onClick,
    selected,
    large,
    width,
    fixedRatio,
    noPopover,
    className,
}: TVendorTile) {
    const { name, iconUrl } = vendor;
    const { preferredVenue: preferredPill } = useThemedColor();

    const preferredVendorIds =
        team?.virtualMeetingServiceProviders.filter(provider => provider.preferred).map(provider => provider.id) ?? [];
    const isPreferred = (vendor: BizlyAPI.Addon) => {
        return preferredVendorIds.some(id => id === vendor.virtualMeetingServiceProvider?.id);
    };

    const tileRef = createRef<HTMLDivElement>();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const open = !!anchorEl;
    const showPopover = () => !open && setAnchorEl(tileRef.current);
    const hidePopover = () => open && setAnchorEl(null);

    return (
        <Tile
            ref={tileRef}
            {...(noPopover ? {} : { onMouseMove: showPopover, onMouseLeave: hidePopover })}
            onClick={onClick ? () => onClick(vendor.id) : undefined}
            backgroundColor={vendor.color}
            width={width}
            className={className}
            large={large ?? false}
            noPopover={noPopover ?? false}
        >
            <PillContainer itemSpacing="xsmall" justifyContent="space-between">
                <AlignedRow justifyContent="flex-start">
                    {isPreferred(vendor) && <Pill color={preferredPill} label={i18n.virtual.preferred} size="small" />}
                </AlignedRow>
                <AlignedRow justifyContent="flex-end">{selected && <CheckIcon />}</AlignedRow>
            </PillContainer>

            {!noPopover && (
                <TilePopover
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={hidePopover}
                    onClick={e => e.stopPropagation()}
                    PaperProps={{
                        // prevents user from hovering back onto a disappearing popover
                        style: { ...(anchorEl ? {} : { pointerEvents: 'none' }) },
                    }}
                    disableScrollLock
                >
                    <VendorPopover vendor={vendor} />
                </TilePopover>
            )}

            <TileImage imgSrc={iconUrl} fixedRatio={fixedRatio} />

            <TileCopy>
                <WrappingCopy $color={EColors.pureWhite}>{name}</WrappingCopy>
            </TileCopy>
        </Tile>
    );
}
