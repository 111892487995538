import { addTeamMember, delTeamMember, getTeamMembers, updateTeamMember } from 'api/settings';
import createStore from '../';

type State = {
    loading: boolean;
    adding: boolean;
    updating: boolean;
    deleting: boolean;
} & (
    | {
          loaded: false;
          teamId?: undefined;
          members?: undefined;
      }
    | {
          loaded: true;
          teamId: string | number;
          members: BizlyAPI.TeamMember[];
      }
);
type Store = State;

const initialState: State = {
    members: undefined,
    loaded: false,
    loading: false,
    adding: false,
    updating: false,
    deleting: false,
};

export const useTeam = createStore<Store>(() => initialState);

const { setState, getState } = useTeam;
export const teamActions = {
    load: async (teamId: string | number) => {
        if (getState().loading) return;
        setState({ loading: true });

        try {
            const { members } = await getTeamMembers(teamId);

            setState({
                loaded: true,
                teamId,
                members,
            });
        } catch (e) {
            setState({ loading: false });
            throw e;
        }
    },
    add: async (email: string) => {
        if (!getState().loaded) return;

        const teamId = getState().teamId;
        if (!teamId) return;

        setState({ adding: true });

        try {
            await addTeamMember(teamId, email);
            const { members } = await getTeamMembers(teamId);
            const curMembers = getState().members;

            setState({
                adding: false,
                members: curMembers ? members : curMembers,
            });
        } catch (e) {
            setState({ adding: false });
            throw e;
        }
    },
    delete: async (membershipId: string | number) => {
        if (!getState().loaded) return;

        const teamId = getState().teamId;
        if (!teamId) return;

        setState({ deleting: true });

        try {
            await delTeamMember(teamId, membershipId);
            const curMembers = getState().members;

            setState({
                deleting: false,
                ...(curMembers
                    ? {
                          members: curMembers.filter(member => member.id !== membershipId),
                      }
                    : {}),
            });
        } catch (e) {
            setState({ deleting: false });
            throw e;
        }
    },
    update: async (member: BizlyAPI.TeamMember) => {
        if (!getState().loaded) return;

        const teamId = getState().teamId;
        if (!teamId) return;

        setState({ updating: true });

        try {
            const { member: updatedMember } = await updateTeamMember(teamId, member);
            const curMembers = getState().members;
            setState({
                updating: false,
                ...(curMembers
                    ? {
                          members: curMembers.map(member => (member.id === updatedMember.id ? updatedMember : member)),
                      }
                    : {}),
            });
        } catch (e) {
            setState({ updating: false });
            throw e;
        }
    },
};
