import { groupBy, partition } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Column } from 'ui';
import FailedMessage from './FailedMessage';
import MessageBubble from './MessageBubble';

const Frame = styled.div`
    padding: 24px;
    overflow-y: auto;
`;

const MessageBox = styled(Column)`
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    min-height: 100%;
`;

type MessageWindowTypes = {
    messages: BizlyAPI.VenueMessage[];
    failed: boolean;
    className?: string;
};

type TAttachment = BizlyAPI.VenueMessage & BizlyAPI.VenueMessage.Attachment;

const MessageWindow = ({ messages, failed, className }: MessageWindowTypes) => {
    const frameRef = useRef<HTMLDivElement>(null);

    const [venueAttachments, noVenueAttachments] = partition(
        messages,
        msg => msg.contentType === 'attachment' && !!msg.mailgunId
    ) as [TAttachment[], BizlyAPI.VenueMessage[]];

    const venueAttachmentsByMailgunId = groupBy(venueAttachments, attachment => attachment.mailgunId);

    useEffect(() => {
        const { current: frame } = frameRef;
        if (frame) {
            frame.scrollTop = frame.scrollHeight;
        }
    }, [messages, failed]);

    return (
        <Frame ref={frameRef} className={className}>
            <MessageBox itemSpacing="default">
                {failed && <FailedMessage />}
                {noVenueAttachments.map(msg => (
                    <MessageBubble
                        key={nanoid()}
                        author={msg.author}
                        sentAt={msg.createdAt}
                        {...(msg.contentType === 'text'
                            ? {
                                  text: msg.text,
                                  attachments: msg.mailgunId ? venueAttachmentsByMailgunId[msg.mailgunId] : undefined,
                              }
                            : { attachments: [msg] })}
                    />
                ))}
            </MessageBox>
        </Frame>
    );
};

export default MessageWindow;
