import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { ButtonWithIcon } from 'components/Ui-V2/Button/Button';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import useShowModal from 'hooks/useShowModal';
import EmptyVisualImage from 'images/empty-pages-assets/empty_templates.svg';
import { PlaybookCreateModal } from 'pages/Playbooks';
import { i18n } from 'translation';
import { TemplateTabValue } from './TemplateTable';

const StyledAddIcon = muiStyled(AddIcon)`
    font-size: 1.125rem;
`;

const StyledEmptyVisualContainer = muiStyled(Box)`
    height: calc(100vh - 36vh);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TemplateStateMessages = ({ tab }: { tab: TemplateTabValue }) => {
    const {
        modalShown: playbookModalShown,
        showModal: showPlaybookModal,
        hideModal: hidePlaybookModal,
    } = useShowModal();

    return (
        <StyledEmptyVisualContainer>
            {tab === 'all' && (
                <EmptyVisual
                    title="No Templates Created"
                    description="Any template you create will show here."
                    image={<img src={EmptyVisualImage} alt="No Templates Created" />}
                />
            )}
            {tab === 'teamTemplates' && (
                <EmptyVisual
                    title="No Templates Created"
                    description="Any template your team publishes will show here."
                    actionButton={
                        <ButtonWithIcon leftIcon={StyledAddIcon} onClick={showPlaybookModal}>
                            {i18n.playbooks.playbookModalCreate}
                        </ButtonWithIcon>
                    }
                    image={<img src={EmptyVisualImage} alt="No Templates Created" />}
                />
            )}
            {tab === 'createdByOwner' && (
                <EmptyVisual
                    title="No Templates Created"
                    description="Any template you create will show here."
                    actionButton={
                        <ButtonWithIcon leftIcon={StyledAddIcon} onClick={showPlaybookModal}>
                            {i18n.playbooks.playbookModalCreate}
                        </ButtonWithIcon>
                    }
                    image={<img src={EmptyVisualImage} alt="No Templates Created" />}
                />
            )}
            {tab === 'recommendedTemplates' && (
                <EmptyVisual
                    title="No Recommended Templates Available"
                    description="There are no recommended templates added yet."
                    image={<img src={EmptyVisualImage} alt="No Templates Created" />}
                />
            )}

            {playbookModalShown && <PlaybookCreateModal onClose={hidePlaybookModal} />}
        </StyledEmptyVisualContainer>
    );
};
