import { TAB_HEIGHT } from 'components/ui/ScrollTabs';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from 'utils/header';
import { Column, InlineRow, Row } from '../../ui';

const StepRow = styled(Row)`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    padding: 2em 0;
    scroll-margin-top: ${HEADER_HEIGHT + TAB_HEIGHT}px;

    &:last-child {
        border-bottom: none;
    }

    p {
        margin: 0;
        margin-bottom: 16px;
    }

    ul,
    ol {
        padding-inline-start: 1.2em;
    }

    li {
        margin-bottom: 16px;
    }

    a {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        &:hover {
            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryActionHover)};
        }
    }
`;

const HeadlineRow = styled(InlineRow)`
    flex: 0.4;
    align-items: center;
    gap: 1em;
`;

const NumberCircle = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryDisplayIcon)};

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    font-size: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const AccentedHeadline = styled.h3`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    margin: 0;
    font-weight: normal;
`;

const RightColumn = styled(Column)`
    flex: 0.6;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

type StepsRowProps = React.PropsWithChildren<{
    number: number;
    headline: string;
    copy?: string;
    children: React.ReactNode;
}>;

const StepsRow = forwardRef(({ number, headline, children, copy }: StepsRowProps, ref: React.Ref<HTMLDivElement>) => {
    return (
        <StepRow ref={ref}>
            <HeadlineRow>
                <NumberCircle>
                    <div>{number}</div>
                </NumberCircle>
                <AccentedHeadline>{headline}</AccentedHeadline>
            </HeadlineRow>
            <RightColumn>
                {copy && <Column dangerouslySetInnerHTML={{ __html: copy }} />}
                {!copy && children}
            </RightColumn>
        </StepRow>
    );
});

export default StepsRow;
