import { get, isFunction } from 'lodash';
import { BaseLanguage, getTranslation } from 'stores/translations';

type TranslationFunctions = ((...params: string[]) => string) | ((...params: number[]) => string);

type TranslationValue = TextObject | string | TranslationFunctions;
type TextObject = {
    [key: string]: TranslationValue;
};

// the problem is that functions inside the language files take in strictly either a list of strings or a list of numbers as input props
// but it actually doesn't matter if it's a string or number when you render into a template literal
// so it should've always been string | number[], for now we'll just assume that's the case
type LooseTranslationFunction = (...params: (string | number)[]) => string;
const isFunctionType = (fn: TranslationValue | LooseTranslationFunction): fn is LooseTranslationFunction => {
    return isFunction(fn);
};

const getText = (path: string, params: (string | number)[] = []) => {
    const translation = getTranslation();

    const translatedText = get(translation, path) as TranslationValue;
    if (typeof translatedText === 'string') {
        return translatedText;
    }
    return isFunctionType(translatedText) ? translatedText(...params) : translatedText;
};

const translationsFn = <T extends TextObject>(obj: T, prevPath = ''): T => {
    const newObj = {} as T;
    const tuples = Object.entries(obj);

    tuples.forEach(([key, val]) => {
        const path = prevPath ? `${prevPath}.${key}` : key;

        if (typeof val === 'string') {
            Object.defineProperty(newObj, key, { get: () => getText(path) });
        } else if (typeof val === 'function') {
            Object.defineProperty(newObj, key, {
                get:
                    () =>
                    (...params: string[]) =>
                        getText(path, params),
            });
        } else {
            newObj[key as keyof T] = translationsFn(val, path) as T[keyof T];
        }
    });

    return newObj;
};

export const i18n = translationsFn(BaseLanguage);
