import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { i18n } from 'translation';
import { submittedProposal } from '../statusUtils';
import { TProposalForm } from '../types';
import { isEmptyString } from '../utils';

export type TContactFormValues = {
    firstName?: string;
    lastName?: string;
    title?: string;
    phone?: string;
    email?: string;
    agreementRead?: boolean;
};

export const proposalFormToFormData = ({ contact = {}, proposal = {} }: Partial<TProposalForm>) => {
    return {
        ...contact,
        ...(contact?.phone ? { phone: parsePhoneNumberFromString(contact?.phone)?.format('INTERNATIONAL') } : {}),
        agreementRead: submittedProposal(proposal) || !proposal.msaDocumentUrl ? true : false,
    };
};

export const formDataToProposalForm = ({ agreementRead, ...data }: TContactFormValues) => {
    return { contact: data };
};

export const getErrorMessage = (
    { firstName, lastName, title, phone, email, agreementRead }: TContactFormValues,
    isContinue?: boolean
) => {
    if (
        isEmptyString(firstName) ||
        isEmptyString(lastName) ||
        isEmptyString(title) ||
        isEmptyString(phone) ||
        phone === '+' ||
        isEmptyString(email)
    ) {
        return i18n.error.fillAll;
    }

    if (isContinue && !agreementRead) {
        return i18n.proposalForm.contactInformation.error.readClientAgreement;
    }
};
