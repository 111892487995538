import { TPlannerForm } from 'components/Planner/types';
import { SpinnerOverlay } from 'components/Spinner';
import { NotesField, TNotesValue } from 'pages/BuildInquiry';
import Planner from 'pages/Planner/';
import { LoadCurrentInquiry, useCurrentInquiry } from 'pages/Playbook/current-inquiry';
import { createPlaybookActions, useCreatePlaybook } from 'pages/PlaybookWizard/store';
import React from 'react';
import { usePlaybooks } from 'stores/playbooks';
import { Spacer } from 'ui';

export type TInquiryValue = TPlannerForm;

export default function InquiryForm() {
    const { playbookOptions } = usePlaybooks();
    const { savingInquiry, inquirySaved, id: playbookId, inquiry: stagedInquiry } = useCreatePlaybook();

    const currentInquiry = useCurrentInquiry();

    const inquiryFormValue = React.useMemo(
        () => ({ ...currentInquiry?.inquiry, ...stagedInquiry }),
        [currentInquiry, stagedInquiry]
    );

    const [queuedPlannerSave, setQueuedPlannerSave] = React.useState(false);
    const NONE = '',
        SAVE = 'save';
    type TCallback = typeof NONE | typeof SAVE;
    const [plannerSavedCallback, setPlannerSavedCallback] = React.useState<TCallback>(NONE);

    const [saving, setSaving] = React.useState(false);

    const onSave = React.useCallback(async () => {
        if (!inquirySaved) {
            setSaving(true);
            setPlannerSavedCallback(SAVE);
            return setQueuedPlannerSave(true);
        }
        if (plannerSavedCallback) {
            setSaving(false);
            setPlannerSavedCallback(NONE);
        }

        setSaving(true);
    }, [inquirySaved, plannerSavedCallback]);

    React.useEffect(() => {
        if (!queuedPlannerSave) {
            if (plannerSavedCallback === SAVE) onSave();
        }
    }, [queuedPlannerSave, plannerSavedCallback, onSave]);

    if (!playbookId) return null;

    return (
        <>
            <Planner
                options={playbookOptions}
                playbookId={playbookId}
                onHasSaved={createPlaybookActions.needSaveInquiry}
                queuedSave={savingInquiry}
                onQueuedSave={() => {
                    createPlaybookActions.savedInquiry(true);
                }}
                onSaveFailed={() => {
                    createPlaybookActions.savedInquiry(false);
                }}
            />
            <Spacer large />
            <LoadCurrentInquiry playbookId={playbookId} />
            <NotesField
                value={inquiryFormValue}
                onChange={({ value }: { value: TNotesValue }) =>
                    createPlaybookActions.setInquiry({
                        ...stagedInquiry,
                        ...value,
                    })
                }
            />
            {saving && <SpinnerOverlay />}
        </>
    );
}
