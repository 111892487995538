import * as Sentry from '@sentry/react';
import { deleteEventTemplate, getEventTemplates, isBlankTemplateId } from 'api/eventTemplates';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const eventTemplateKeys = {
    all: ['eventTemplates'] as const,
};

export const useEventTemplates = () => {
    const result = useQuery(eventTemplateKeys.all, getEventTemplates, {
        select: data => {
            if (!data || !data.templates) {
                console.error('Unexpected data structure from API:', data);
                return [];
            }
            // For context, blankTemplate is used for Event Creation if the user does not select a template
            return data.templates.filter(template => !isBlankTemplateId(template.id));
        },
        onError: (error: Error) => {
            console.error('Error fetching event templates:', error);
            Sentry.captureException(error, {
                tags: { component: 'useEventTemplates' },
            });
        },
    });

    return {
        ...result,
        templates: result?.data,
        isLoadingTemplates: result.isLoading,
    };
};

type EventTemplatesQueryData = {
    templates: Bizly.EventTemplate[];
};

export const useDeleteEventTemplate = () => {
    const queryClient = useQueryClient();

    return useMutation(deleteEventTemplate, {
        onSuccess: (_, templateId) => {
            queryClient.setQueryData<EventTemplatesQueryData | undefined>(eventTemplateKeys.all, oldData => {
                if (oldData && oldData.templates) {
                    return {
                        ...oldData,
                        templates: oldData.templates.filter(template => template.id !== Number(templateId)),
                    };
                }
                return oldData;
            });
        },
        onError: (error: Error) => {
            console.error('Error deleting event template:', error);
            Sentry.captureException(error, {
                tags: { component: 'useDeleteEventTemplate' },
            });
        },
    });
};
