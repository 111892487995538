import { z } from 'zod';
import { post } from './index';

export const generateInviteSubjectAndBody = (
    meetingName: string,
    meetingDescription: string,
    location: string,
    start: string,
    end: string,
    fromName: string,
    fromCompanyName: string
) =>
    post(`ai/generate-invite`, {
        meetingName,
        meetingDescription,
        location,
        start,
        end,
        fromName,
        fromCompanyName,
    });

const MetaDataResponseSchema = z.object({
    success: z.boolean(),
    eventMetadata: z
        .object({
            attendeeCount: z.number().nullable(),
            budget: z.number().nullable(),
            endsAt: z.string().nullable(),
            location: z.string().nullable(),
            startsAt: z.string().nullable(),
            requirements: z.array(z.string()),
        })
        .optional(),
});
type MetaDataResponse = z.infer<typeof MetaDataResponseSchema>;

export const generateEventMetaData = async (description: string): Promise<MetaDataResponse> => {
    const response = await post(`ai/generate-event-metadata`, {
        description,
    });
    const result = MetaDataResponseSchema.safeParse(response);

    if (!result.success || !result.data) {
        throw new Error(result.error.toString());
    }

    return result.data;
};
