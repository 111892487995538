import Avatar from 'components/ui/Avatar';
import React from 'react';
import styled from 'styled-components';
import { Column, Copy, Row, SpacedRow, SPACER_SPACES } from 'ui';
import PermissionPill, { Permission } from './PermissionPill';

type CollaboratorType = Pick<
    BizlyAPI.EventCollaboratorOrPending,
    'firstName' | 'lastName' | 'email' | 'imageUrl' | 'id' | 'editor'
>;

type CollaboratorProps = {
    collaborator: CollaboratorType;
    changePermission: (canEdit: boolean) => void;
};

const getUserName = ({ firstName = '', lastName = '' }: CollaboratorType) => [firstName, lastName].join(' ').trim();

const Container = styled(SpacedRow)`
    margin-bottom: ${SPACER_SPACES.small}px;
`;

const UserRow = styled(Row)`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px 0 8px;

    > *:last-child {
        margin-left: auto;
    }
`;

const NameCopy = styled(Copy)`
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const EmailCopy = styled(NameCopy)`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

const Collaborator = ({ collaborator, changePermission }: CollaboratorProps) => {
    const permission: Permission = collaborator.editor ? 'edit' : 'view';
    const onPermissionChange = (newPermission: Permission) => {
        changePermission(newPermission === 'edit');
    };
    return (
        <Container justifyContent="space-between">
            <UserRow>
                <Avatar user={collaborator} />
                <Column>
                    {getUserName(collaborator) && <NameCopy>{getUserName(collaborator)}</NameCopy>}
                    <EmailCopy>{collaborator.email}</EmailCopy>
                </Column>
                <PermissionPill value={permission} onChange={onPermissionChange} />
            </UserRow>
        </Container>
    );
};

export default Collaborator;
