import Dialog from '@material-ui/core/Dialog';
import { SpinnerOverlay } from 'components/Spinner';
import TextButton from 'components/ui/Button/TextButton';
import { H2Headline as UIH2Headline } from 'components/ui/Headline';
import fontFns from 'fontFns';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, Row, Spacer } from 'ui';

const Modal = styled(Column)`
    width: 400px;
    padding: 24px;
`;

const H2Headline = styled(UIH2Headline)<{ warning?: boolean }>`
    color: ${({ theme: { getColor, EColors }, warning }) =>
        warning ? getColor(EColors.warningText) : getColor(EColors.accentedHeadline)};
    text-transform: none;
    ${fontFns.heading}
`;

const EndRow = styled(Row)`
    justify-content: flex-end;
`;

const PreCopy = styled(Copy)`
    white-space: pre-line;
`;

type TConfirmationModal = {
    isActive?: boolean;
    warning?: boolean;

    onDismiss?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
        reason?: 'backdropClick' | 'escapeKeyDown'
    ) => any;
    onProceed?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;

    headline?: React.ReactNode;
    prompt?: React.ReactNode;
    ctaLabel?: React.ReactNode;
    dismissLabel?: React.ReactNode;

    loading?: boolean;
    children?: React.ReactNode;
};

export const ConfirmationModal = ({
    isActive,
    warning,
    onDismiss,
    onProceed,
    headline = warning ? i18n.modal.areYouSure : null,
    prompt,
    ctaLabel = warning ? i18n.button.delete : i18n.button.continue,
    dismissLabel = i18n.button.cancel,
    loading,
    children,
}: TConfirmationModal) => (
    <Dialog open={!!isActive} onClose={onDismiss}>
        <Modal>
            {headline && (
                <>
                    <H2Headline warning={warning}>{headline}</H2Headline>
                    <Spacer />
                </>
            )}
            {prompt && (
                <>
                    <PreCopy>{prompt}</PreCopy>
                    <Spacer larger />
                </>
            )}
            {children ? (
                <>{children}</>
            ) : (
                <EndRow itemSpacing="smallish">
                    {onDismiss && (
                        <TextButton secondary onClick={onDismiss}>
                            {dismissLabel}
                        </TextButton>
                    )}
                    {onProceed && (
                        <TextButton warning={warning} onClick={onProceed}>
                            {ctaLabel}
                        </TextButton>
                    )}
                </EndRow>
            )}
            {loading && <SpinnerOverlay />}
        </Modal>
    </Dialog>
);

export default ConfirmationModal;
