import { Dialog } from '@material-ui/core';
import colorFns from 'colorFns';
import SubscriptionFeatures, { getNextTier } from 'pages/TeamSettings/SubscriptionFeatures';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, Row } from 'ui';
import TextButton from './ui/Button/TextButton';
import { H2Headline } from './ui/Headline';

const PaddedDialog = styled(Dialog)`
    .MuiPaper-root > *:not(:last-child) {
        border-bottom: 1px solid ${colorFns.softBorder};
    }
`;

const ModalContent = styled.div`
    width: 600px;
    padding: 20px;
    box-sizing: border-box;
`;

const Header = styled(Row)`
    padding: 20px;
    box-sizing: border-box;
`;

const Footer = styled(Row)`
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
`;

export default function SubscriptionPrompt({
    title = i18n.subscription.needAccess,
    prompt,
    currentTier,
    includeAllTiers = false,
    onSkip,
    dismissLabel = i18n.button.notRightNow,
}: {
    title?: string;
    prompt?: string;
    currentTier: BizlyAPI.TeamTier;
    includeAllTiers?: boolean;
    onSkip: (forever?: boolean) => void;
    dismissLabel?: string;
}) {
    return (
        <PaddedDialog open>
            <Header>
                <div>
                    <Column itemSpacing="small">
                        <H2Headline>{currentTier === 'enterprise' ? i18n.subscription.sorryHeader : title}</H2Headline>
                        {prompt && <Copy>{prompt}</Copy>}
                    </Column>
                </div>
            </Header>
            <ModalContent>
                <SubscriptionFeatures tiers={getNextTier(currentTier, includeAllTiers)} />
            </ModalContent>
            <Footer alignItems="center" justifyContent="flex-end" itemSpacing="smallish">
                <TextButton onClick={() => onSkip()} secondary>
                    {currentTier === 'enterprise' ? i18n.button.dismiss : dismissLabel}
                </TextButton>
            </Footer>
        </PaddedDialog>
    );
}
