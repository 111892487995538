import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { DefaultTheme, ThemeProviderProps } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { JSX } from 'react/jsx-runtime';
import { useTheme } from 'stores/theme';
import { useTranslations } from 'stores/translations';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import {
    TStyledTheme,
    bizlyStyledTheme,
    getStyledTheme,
    materialUIThemeStyledFn,
    useBizlySnackbarStyles,
    useSnackbarStyles,
} from 'theme';
import { Column } from 'ui';
import App from './App';

const fontStyleFn = ({ theme: { getFontStyle, EFonts } }: { theme: TStyledTheme }) => ({
    '& *': getFontStyle(EFonts.body),
    '& h1, & h2, & h3, & h4, & h5, & h6, & h7': getFontStyle(EFonts.heading),
});

const FontProvider = createGlobalStyle(fontStyleFn);

const BizlyFontProvider = styled(Column)`
    width: 100%;
    ${fontStyleFn}
`;

const ThemedMuiThemeProvider = styled((props: JSX.IntrinsicAttributes & ThemeProviderProps<DefaultTheme>) => (
    <MuiThemeProvider {...props} />
)).attrs(materialUIThemeStyledFn)``;

export const ThemedSnackbarProvider = ({ children, isBizly }: { children?: React.ReactNode; isBizly?: boolean }) => {
    const snackbarClasses = useSnackbarStyles();
    const bizlySnackbarClasses = useBizlySnackbarStyles();
    return (
        <SnackbarProvider
            classes={isBizly ? bizlySnackbarClasses : snackbarClasses}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            children={children}
        />
    );
};

export const BizlyThemeOverride = ({ children }: { children?: React.ReactNode }) => {
    return (
        <ThemeProvider theme={{ ...bizlyStyledTheme() }}>
            <BizlyFontProvider>
                <ThemedMuiThemeProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemedSnackbarProvider isBizly>{children}</ThemedSnackbarProvider>
                    </StyledEngineProvider>
                </ThemedMuiThemeProvider>
            </BizlyFontProvider>
        </ThemeProvider>
    );
};

export default function ThemedApp() {
    // subscribe to stores so that the page will re-render when users select a new color or languages
    useTheme();
    const { language } = useTranslations();

    return (
        <ThemeProvider theme={{ ...getStyledTheme() }}>
            <FontProvider />
            <ThemedMuiThemeProvider>
                <StyledEngineProvider injectFirst>
                    <ThemedSnackbarProvider>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/*Using the language as a key forces the app to remount when a new language is selected*/}
                                <App key={language} />
                            </MuiPickersUtilsProvider>
                        </LocalizationProvider>
                    </ThemedSnackbarProvider>
                </StyledEngineProvider>
            </ThemedMuiThemeProvider>
        </ThemeProvider>
    );
}
