import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import colorFns from 'colorFns';
import styled from 'styled-components';
import { Button } from './Ui-V2/Button/Button';

const TokenButton = styled(Button)`
    background-color: ${({ onClick, ...props }) => (onClick ? colorFns.venueSearchToken(props) : colorFns.grey(props))};
    ${({ onClick }) => (onClick ? '' : 'cursor: not-allowed;')}

    &.MuiButton-containedPrimary {
        margin-right: 0px;
        border-radius: 60px;
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
        box-shadow: none;
        border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};

        &:hover {
            background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction, 0.1)};
        }
    }

    &:hover {
        background-color: ${({ onClick, theme: { getColor, EColors } }) =>
            onClick ? getColor(EColors.primaryAction, 0.1) : getColor(EColors.grey)};
    }
`;

const TokenLabel = styled.span`
    font-size: 13px;
    font-weight: 500;
`;

const TokenClose = styled(CloseIcon)`
    &.MuiSvgIcon-root {
        width: 18px;
        height: 18px;
        margin-left: 10px;
    }
`;

export type TToken = { id: string | number; label: string; onRemove?: () => void };

const Token = ({ label, onRemove }: Omit<TToken, 'id'>) => (
    <TokenButton {...(onRemove ? { onClick: () => onRemove() } : {})}>
        <TokenLabel>{label}</TokenLabel>
        {onRemove && <TokenClose />}
    </TokenButton>
);

// negative margin to negate individual margins
const WrappingRow = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0.5em;
    width: 100%;
`;

export default function Tokenizer({ tokens }: { tokens: TToken[] }) {
    return (
        <WrappingRow>
            {tokens.map(({ id, label, onRemove }) => (
                <Token key={id} label={label} onRemove={onRemove} />
            ))}
        </WrappingRow>
    );
}
