import { Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

type AvatarProps = {
    user: PartialExcept<Pick<Bizly.User, 'firstName' | 'lastName' | 'email' | 'imageUrl'>, 'email'>;
};

const AvatarImage = styled.img`
    height: 29px;
    width: 29px;
    border-radius: 50%;
    vertical-align: middle; /* remove white space under img and align it with rest of row https://stackoverflow.com/questions/7774814/remove-white-space-below-image */
`;

const GreyCircle = styled.div`
    height: 29px;
    width: 29px;
    border-radius: 50%;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 13px;
    text-transform: uppercase;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const NameContainer = styled.div`
    margin-bottom: 2px;
    user-select: none;
`;

const displayName = (email: string, firstName?: string, lastName?: string) => {
    if (firstName && lastName) {
        return firstName[0].toUpperCase() + lastName[0].toUpperCase();
    }

    if (firstName) {
        return firstName[0].toUpperCase();
    }

    if (lastName) {
        return lastName[0].toUpperCase();
    }

    return email[0].toUpperCase();
};

// Tooltip needs something that can have a ref attached to it, the div guarantees that condition
export const Avatar = React.forwardRef(
    (
        { user, ...props }: AvatarProps & React.DOMAttributes<HTMLElement>,
        ref: React.Ref<HTMLDivElement & HTMLImageElement>
    ) => {
        const { email, imageUrl } = user;

        const firstName = user.firstName ?? '';
        const lastName = user.lastName ?? '';

        return imageUrl && !imageUrl.includes('default-avatar.png') ? (
            <AvatarImage alt={firstName} src={imageUrl} ref={ref} {...props} />
        ) : (
            <GreyCircle {...props} ref={ref}>
                <NameContainer>{displayName(email, firstName, lastName)}</NameContainer>
            </GreyCircle>
        );
    }
);

const AvatarTooltip = styled(({ className, children, ...props }: TooltipProps & { color?: Themed.Color }) => (
    <Tooltip {...props} classes={{ popper: className }}>
        {children}
    </Tooltip>
))`
    .MuiTooltip-tooltip {
        padding: 10px 20px;

        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};

        border-radius: 8px;
        box-shadow: 0 2px 44px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.1)};
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }

    .MuiTooltip-tooltipPlacementTop,
    .MuiTooltip-tooltipPlacementBottom {
        margin: 8px 0px;
    }
`;

export const AvatarWithTooltip = ({ user, ...props }: AvatarProps & React.DOMAttributes<HTMLElement>) => {
    const name = [...(user.firstName ? [user.firstName] : []), ...(user.lastName ? [user.lastName] : [])].join(' ');

    return (
        <AvatarTooltip placement="top-start" title={name || user.email}>
            <Avatar user={user} {...props} />
        </AvatarTooltip>
    );
};

export default Avatar;
