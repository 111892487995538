import Button from 'components/ui/Button';
import { H5Headline } from 'components/ui/Headline';
import React from 'react';
import styled from 'styled-components';
import { Column, ExternalLink, Row } from 'ui';
import { getProposalFormStatus, getStatusAttributes, TAction } from './statusUtils';
import { TProposalForm } from './types';

const Width100Column = styled(Column)`
    width: 100%;
`;

const Copy = styled.div`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const LinkButton = styled(Button)`
    width: 140px;
`;

export default function ProposalFormStatus({
    userChangedStatus,
    action,
    ...rest
}: { userChangedStatus?: boolean; action?: TAction } & Partial<TProposalForm>) {
    const status = getProposalFormStatus(rest);
    const statusAttributes = getStatusAttributes(status, action, userChangedStatus);

    const LinkButtons = ({ links }: { links: { link: string; label: string }[] }) => {
        return (
            <Row itemSpacing="smallish">
                {links.map(linkObj => (
                    <ExternalLink href={linkObj.link} openInNewTab>
                        <LinkButton>{`${linkObj.label}`}</LinkButton>
                    </ExternalLink>
                ))}
            </Row>
        );
    };

    return (
        <Width100Column itemSpacing="small">
            <H5Headline>{statusAttributes.heading(rest)}</H5Headline>
            <Copy>
                {statusAttributes.copy(rest)}
                <LinkButtons links={statusAttributes.links(rest)} />
            </Copy>
        </Width100Column>
    );
}
