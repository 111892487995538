import { addAttendees, loadAttendees, removeAttendee } from 'api/attendees';
import { createMeeting, publishMeeting, updateMeeting } from 'api/meetings';
import { isEmptyString } from 'components/ProposalForm/utils';
import { MOVE_DOWN, MOVE_UP, TDirection } from 'components/Schedule/ManageAgenda';
import { TVMValue } from 'components/Schedule/VirtualMeetingField';
import moment from 'moment-timezone';
import createStore from 'stores';
import { meetingsActions } from 'stores/meetings';
import { emailIsValid } from 'utils';
import { userTimeZone } from 'utils/moment';
import { connectorToId, getServiceProviderId, idToConnector } from 'utils/virtualMeeting';
import { TBasicInfoValue } from '../BasicInfoForm';
import { TInviteValue } from '../InvitesForm';

export enum ESteps {
    basic,
    agenda,
    attendees,
    invites,
    inquiry,
    venues,
}

type TErrors<T> = Partial<Record<keyof T, string>>;
const hasErrors = <T extends Partial<Record<string, string>>>(errors: T) => Object.values(errors).some(v => v);

type TAgendaErrors = TErrors<BizlyAPI.ScheduleAgendaEntry> & { count?: string };
type TAttendeeErrors = { invalidEmails?: string; count?: string };

type State = {
    stepIdx: number;
    stepList: ESteps[];

    isPublished: boolean | null;

    basicInfo: TBasicInfoValue;
    basicInfoErrors: TErrors<TBasicInfoValue>;
    additionalRequired: (keyof TBasicInfoValue)[];

    curAgendaId: number;
    agenda: BizlyAPI.ScheduleAgendaEntry[];
    agendaErrors: TAgendaErrors;

    loadedAttendees: BizlyAPI.Attendee[];
    attendeesChanged: boolean;
    attendees: BizlyAPI.BasicAttendee[];
    attendeeErrors: TAttendeeErrors;

    invite: TInviteValue;
    inviteDescriptionChanged: boolean;

    loading?: boolean;
    loaded?: boolean;
    saving?: boolean;
    publishing?: boolean;
    changed?: boolean;

    playbookKey: number;
};
type Store = State;

const initialState: State = {
    stepIdx: 0,
    stepList: [ESteps.basic, ESteps.venues, ESteps.inquiry],

    isPublished: null,

    basicInfo: { timeZone: userTimeZone },
    basicInfoErrors: {},
    additionalRequired: [],

    curAgendaId: 0,
    agenda: [],
    agendaErrors: {},

    loadedAttendees: [],
    attendeesChanged: false,
    attendees: [],
    attendeeErrors: {},

    invite: {
        type: 'simple',
    },
    inviteDescriptionChanged: false,

    loading: false,
    saving: false,
    publishing: false,
    changed: false,

    playbookKey: 0,
};

export const useCreateMeeting = createStore<Store>(() => initialState);

const { setState, getState } = useCreateMeeting;

const agendaFormActions = {
    setAgenda: (agenda: BizlyAPI.ScheduleAgendaEntry[]) => {
        setState({ agenda, changed: true });
    },
    addAgendaItem: () => {
        setState(prevState => ({
            ...prevState,
            agenda: [...prevState.agenda, { id: prevState.curAgendaId }],
            curAgendaId: prevState.curAgendaId + 1,
            changed: true,
        }));
    },
    updateAgendaItem: (updatedAgendaItem: { value: BizlyAPI.ScheduleAgendaEntry }, targetIdx: number) => {
        setState(prevState => {
            const updatedAgenda = prevState.agenda.slice();
            updatedAgenda[targetIdx] = updatedAgendaItem.value;
            return {
                ...prevState,
                agenda: updatedAgenda,
                changed: true,
            };
        });
    },
    arrangeAgendaItem: (targetIdx: number, direction: TDirection) =>
        setState(prevState => {
            const { agenda = [] } = prevState;
            const updatedAgenda = agenda.slice();
            const targetEntry = { ...updatedAgenda[targetIdx] };

            if (direction === MOVE_UP && !!updatedAgenda[targetIdx - 1]) {
                const entryBefore = { ...updatedAgenda[targetIdx - 1] };
                updatedAgenda[targetIdx - 1] = targetEntry;
                updatedAgenda[targetIdx] = entryBefore;
            } else if (direction === MOVE_DOWN && !!updatedAgenda[targetIdx + 1]) {
                const entryAfter = { ...updatedAgenda[targetIdx + 1] };
                updatedAgenda[targetIdx + 1] = targetEntry;
                updatedAgenda[targetIdx] = entryAfter;
            }

            return {
                ...prevState,
                agenda: updatedAgenda,
                changed: true,
            };
        }),
    removeAgendaItem: (targetIdx: number) =>
        setState(prevState => {
            const { agenda = [] } = prevState;
            const updatedAgenda = agenda.filter((_, idx: number) => idx !== targetIdx);

            return {
                ...prevState,
                agenda: updatedAgenda,
                changed: true,
            };
        }),
    setAgendaErrors: (agendaErrors: TAgendaErrors) => {
        setState({ agendaErrors });
    },
    validateAgenda: () => {
        const agendaErrors: TAgendaErrors = {};
        const { agenda } = getState();

        agenda.forEach(item => {
            if (isEmptyString(item.title)) agendaErrors.title = 'Agenda title is required';
            if (!Number(item.duration)) agendaErrors.duration = 'Agenda duration is required';
            // if (isEmptyString(item.description)) agendaErrors.description = 'Agenda description is required';
        });

        if (!agenda.length) agendaErrors.count = 'Agenda is required';

        setState({ agendaErrors });
    },
};

const navActions = {
    setSteps: (stepList: ESteps[]) => {
        setState({ stepList });
    },
    prevStep: () => {
        setState({ stepIdx: Math.max(getState().stepIdx - 1, 0) });
    },
    nextStep: () => {
        const curStep = selCurStep(getState());
        const maxStep = selMaxStep(getState());
        stepToValidator[curStep]();
        if (!hasErrors(selectErrors(curStep))) {
            setState({ stepIdx: Math.min(getState().stepIdx + 1, maxStep) });
        }
    },
    goToStep: (toStep: ESteps) => {
        setState({ stepIdx: getState().stepList.findIndex(step => step === toStep) });
    },
    reset: () => setState(initialState),
};

export const selCurStep = (state: State) => state.stepList[state.stepIdx];
export const selMaxStep = (state: State) => state.stepList.length - 1;

const basicFormActions = {
    setBasicForm: (basicInfo: TBasicInfoValue, unchanged?: boolean) => {
        setState({ basicInfo, basicInfoErrors: {}, ...(!unchanged && { changed: true }) });
    },
    mergeBasicForm: (basicInfo: TBasicInfoValue) => {
        setState({ basicInfo: { ...getState().basicInfo, ...basicInfo }, changed: true });
    },
    setBasicFormErrors: (basicInfoErrors: TErrors<TBasicInfoValue>) => {
        setState({ basicInfoErrors });
    },
    validateBasicForm: () => {
        const basicInfoErrors: TErrors<TBasicInfoValue> = {};
        const data = getState().basicInfo;

        if (isEmptyString(data.name)) basicInfoErrors.name = 'Meeting name is required';
        if (isEmptyString(data.description)) basicInfoErrors.description = 'Meeting description is required';

        if (getState().additionalRequired.length > 0) {
            const errors: { [key in keyof TBasicInfoValue]: false | string } = {
                internalReference: isEmptyString(data.internalReference) && 'Internal reference is required',
                type: isEmptyString(data.type) && 'Internal/External type is required',
                costCenter: isEmptyString(data.costCenter) && 'Cost center is required',
                location: isEmptyString(data.location?.location) && 'Location is required',
            };

            getState().additionalRequired.forEach(field => {
                const error = errors[field];
                if (error) {
                    basicInfoErrors[field] = error;
                }
            });
        }

        setState({ basicInfoErrors });
    },
};

const attendeeFormActions = {
    setAttendees: (attendees: BizlyAPI.BasicAttendee[]) => {
        setState({
            attendees,
            attendeesChanged: false,
        });
    },
    addAttendee: (newAttendee: BizlyAPI.BasicAttendee) => {
        if (getState().attendees.some(attendee => attendee.email === newAttendee.email)) return;
        setState({
            attendees: [...getState().attendees, newAttendee],
            attendeesChanged: true,
        });
    },
    addAttendees: (newAttendees: BizlyAPI.BasicAttendee[]) => {
        const existing = new Set(getState().attendees.map(attendee => attendee.email));
        const newAdditions = newAttendees.filter(attendee => !existing.has(attendee.email));

        setState({
            attendees: [...getState().attendees, ...newAdditions],
            attendeesChanged: true,
        });
    },
    delAttendee: (email?: string) => {
        setState({
            attendees: getState().attendees.filter(a => a.email !== email),
            attendeesChanged: true,
        });
    },
    validateAttendees: () => {
        const attendeeErrors: TAttendeeErrors = {};
        const data = getState().attendees;

        if (!data || data.length === 0) {
            attendeeErrors.count = 'Your meeting has no attendees.';
        }
        const invalidEmails = data?.filter(attendee => !emailIsValid(attendee.email));
        if (invalidEmails?.length > 0) {
            attendeeErrors.invalidEmails = `Some attendee emails are invalid: ${invalidEmails
                .map(attendee => attendee.email)
                .join(', ')}.`;
        }

        setState({ attendeeErrors });
    },
};

const inviteActions = {
    setInvite: (invite?: TInviteValue, descriptionChanged?: boolean) => {
        setState({
            invite,
            changed: true,
            inviteDescriptionChanged: getState().inviteDescriptionChanged || descriptionChanged,
        });
    },
    setInviteType: (type?: TInviteValue['type']) => {
        setState({ invite: { ...getState().invite, type } });
    },
    copyPurpose: () => {
        setState({ invite: { ...getState().invite, description: getState().basicInfo.purpose } });
    },
};

export class ValidationError extends Error {}

const stepToValidator = {
    [ESteps.basic]: basicFormActions.validateBasicForm,
    [ESteps.agenda]: agendaFormActions.validateAgenda,
    [ESteps.attendees]: attendeeFormActions.validateAttendees,
    [ESteps.invites]: () => {},
    [ESteps.venues]: () => {},
    [ESteps.inquiry]: () => {},
};

const selectErrors = (step: ESteps) => {
    const stepToError = {
        [ESteps.basic]: getState().basicInfoErrors,
        [ESteps.agenda]: getState().agendaErrors,
        [ESteps.attendees]: getState().attendeeErrors,
        [ESteps.invites]: {},
        [ESteps.venues]: {},
        [ESteps.inquiry]: {},
    };

    return stepToError[step];
};

const loadVM = (virtualMeeting?: Bizly.VirtualMeeting | null) =>
    virtualMeeting &&
    virtualMeeting.serviceProvider?.id !== undefined &&
    !virtualMeeting.link &&
    idToConnector[virtualMeeting.serviceProvider?.id]
        ? {
              ...virtualMeeting,
              link: undefined,
              deferredService: idToConnector[virtualMeeting.serviceProvider?.id],
              notes: 'A link will be created when the meeting is published',
          }
        : virtualMeeting;

const encodeVM = (virtualMeeting?: TVMValue) => {
    if (virtualMeeting?.deferredService) {
        const id = connectorToId[virtualMeeting.deferredService];
        if (id) {
            return {
                serviceProvider: { id },
            };
        }
    }

    if (virtualMeeting?.link) {
        const id = getServiceProviderId(virtualMeeting.link);
        return {
            ...virtualMeeting,
            serviceProvider: { id },
        };
    }

    return virtualMeeting;
};

const meetingToBasicInfo = (meeting: BizlyAPI.Meeting): TBasicInfoValue => {
    const { startsAt, endsAt, location, googlePlaceId, virtualMeeting, ...meetingInfo } = meeting;

    const start = moment(startsAt);
    const end = moment(endsAt);

    return {
        ...meetingInfo,
        startDate: start.toDate(),
        endDate: end.toDate(),
        startTime: start.format('HH:mm:ss'),
        endTime: end.format('HH:mm:ss'),
        ...(location && {
            location: {
                location,
                googlePlaceId: googlePlaceId || undefined,
            },
        }),

        virtualMeeting: loadVM(virtualMeeting) || undefined,
    };
};

const basicInfoToMeeting = (basicInfo: TBasicInfoValue) => {
    const { startDate, endDate, startTime, endTime, location, virtualMeeting, ...basicInfoValues } = basicInfo;

    const start = moment(startDate).startOf('day').add(moment.duration(startTime));
    const end = moment(endDate).startOf('day').add(moment.duration(endTime));
    return {
        ...basicInfoValues,
        startsAt: start.format('L HH:mm:ss'),
        endsAt: end.format('L HH:mm:ss'),
        location: location?.location,
        googlePlaceId: location?.googlePlaceId || null,

        virtualMeeting: encodeVM(virtualMeeting),
    };
};

const splitAttendees = <T extends BizlyAPI.BasicAttendee, S extends BizlyAPI.Attendee>(
    attendees: T[],
    existingAttendees: S[]
) => {
    const attendeesSet = new Set(attendees.map(attendee => attendee.email));
    const existingAttendeesSet = new Set(existingAttendees.map(attendee => attendee.email));
    const deleteAttendees = existingAttendees.filter(attendee => !attendeesSet.has(attendee.email));
    const newAttendees = attendees.filter(attendee => !existingAttendeesSet.has(attendee.email));

    return { deleteAttendees, newAttendees };
};

export const createMeetingActions = {
    ...navActions,
    ...basicFormActions,
    ...agendaFormActions,
    ...attendeeFormActions,
    ...inviteActions,
    setAdditionalRequired: (additionalRequired: (keyof TBasicInfoValue)[]) => {
        setState({ ...getState(), additionalRequired });
    },

    load: async (id: string | number) => {
        if (getState().loading) {
            return;
        }
        setState({
            loading: true,
            loaded: false,
        });

        const lastStepIdx = loadStep(id);
        try {
            const meeting = await meetingsActions.loadSingle(id);
            const attendeesResponse = await loadAttendees(id);

            const basicInfo = meetingToBasicInfo(meeting);

            let curId = getState().curAgendaId;

            basicFormActions.setBasicForm(basicInfo);
            agendaFormActions.setAgenda(meeting.agenda.map(item => ({ ...item, id: curId++ })));
            attendeeFormActions.setAttendees(attendeesResponse.attendees);
            const newStepList = meeting.published
                ? [ESteps.basic, ESteps.attendees]
                : [ESteps.basic, ESteps.venues, ESteps.inquiry];
            setState({
                ...(lastStepIdx !== undefined ? { stepIdx: lastStepIdx > newStepList.length ? 0 : lastStepIdx } : {}),
                stepList: newStepList,
                isPublished: !!meeting.published,
                loadedAttendees: attendeesResponse.attendees,
                loading: false,
                loaded: true,
            });

            return meeting;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    },

    saveDraft: async (id?: string | number) => {
        return createMeetingActions.save(id);
    },

    savePublished: async (id: string | number) => {
        const stepList = getState().stepList;

        stepList.forEach(step => stepToValidator[step]());
        stepList.forEach(step => {
            if (Object.values(selectErrors(step)).some(v => v)) {
                navActions.goToStep(step);
                throw new ValidationError();
            }
        });

        return createMeetingActions.save(id);
    },

    save: async (id?: string | number) => {
        if (getState().saving || getState().loading) {
            return;
        }
        setState({ saving: true });

        const method = id === undefined ? createMeeting : updateMeeting;

        const data = {
            ...basicInfoToMeeting(getState().basicInfo),
            agenda: getState().agenda,
        };
        const { attendees, loadedAttendees } = getState();
        const { deleteAttendees, newAttendees } = splitAttendees(attendees, loadedAttendees);

        try {
            const { meeting } = await method(data);
            const newId = meeting.id;
            persistStep(
                newId,
                getState().stepList.findIndex(step => step === selCurStep(getState()))
            );
            await Promise.all(deleteAttendees.map(attendee => removeAttendee(newId, attendee)));
            const loadedAttendees = await addAttendees(newId, newAttendees);

            const basicInfo = meetingToBasicInfo(meeting);

            basicFormActions.setBasicForm(basicInfo);
            agendaFormActions.setAgenda(meeting.agenda);
            attendeeFormActions.setAttendees(loadedAttendees);

            setState({
                loadedAttendees,
                saving: false,
                loaded: true,
            });

            meetingsActions.merge(meeting);
            return meeting;
        } catch (e) {
            setState({
                saving: false,
            });
            throw e;
        }
    },

    applyPlaybook: (playbook: BizlyAPI.Complete.Playbook) => {
        const { id, description } = playbook;

        basicFormActions.mergeBasicForm({
            description,
            playbookId: id,
        });
        inviteActions.setInvite({
            ...getState().invite,
            image: playbook.imageUrl,
            description: playbook.purpose,
        });
        setState({ changed: false, playbookKey: getState().playbookKey + 1 });
    },

    publish: async (id?: number | string) => {
        if (getState().saving || getState().publishing) {
            return;
        }

        let meeting: BizlyAPI.Meeting | undefined;

        try {
            setState({ publishing: true });
            meeting = await createMeetingActions.save(id);

            if (meeting) {
                ({ meeting } = await publishMeeting({
                    id: meeting.id,
                    inviteType: 'none',
                }));

                meetingsActions.merge(meeting);
                eraseStep(meeting.id);
            }
            return meeting;
        } catch (e) {
            setState({ publishing: false });
            if (meeting) return meeting;
            else throw e;
        }
    },
};

type DraftsSteps = Partial<Record<string | number, number>>;

const localStorageStepKey = 'meetingDraftsSteps';

function loadStep(id: string | number) {
    const steps = JSON.parse(localStorage.getItem(localStorageStepKey) || '{}') as DraftsSteps;
    return steps[id];
}

function persistStep(id: string | number, stepIdx: number) {
    const steps = JSON.parse(localStorage.getItem(localStorageStepKey) || '{}') as DraftsSteps;
    steps[id] = stepIdx;
    localStorage.setItem(localStorageStepKey, JSON.stringify(steps));
}

function eraseStep(id: string | number) {
    const steps = JSON.parse(localStorage.getItem(localStorageStepKey) || '{}') as DraftsSteps;
    delete steps[id];
    localStorage.setItem(localStorageStepKey, JSON.stringify(steps));
}
