import {
    areIntervalsOverlapping,
    endOfWeek,
    isSameDay,
    isSameWeek,
    isWithinInterval,
    set,
    startOfDay,
    startOfHour,
    startOfWeek,
} from 'date-fns';
import { View } from 'react-big-calendar';

export const oneHourEarlier = (date: Date) => set(date, { hours: Math.max(date.getHours() - 1, 0) });

export const getScrollToTime = <TEvent extends { start: Date; end: Date; multiDay?: boolean }>(
    events: TEvent[],
    baseDate: Date,
    view?: View
) => {
    type TRange = { start: Date; end: Date; multiDay?: boolean };
    const base = startOfDay(baseDate);

    const earlyHourMultiday = (event: TRange) =>
        event.multiDay &&
        (view === 'week'
            ? areIntervalsOverlapping({ start: startOfWeek(base), end: startOfDay(endOfWeek(base)) }, event)
            : isWithinInterval(base, event));

    const Day8AM = set(base, { hours: 8 });
    const Day12AM = set(base, { hours: 0 });

    const getSortDate = (event: TRange) => (earlyHourMultiday(event) ? Day12AM : event.start);

    const earliestEventInView = events
        .filter(event => (view === 'week' ? isSameWeek : isSameDay)(base, event.start) || earlyHourMultiday(event))
        .sort((eventA, eventB) => getSortDate(eventA).getHours() - getSortDate(eventB).getHours())[0];
    const earliestEventStart = (earliestEventInView && oneHourEarlier(getSortDate(earliestEventInView))) || Day8AM;

    return startOfHour(earliestEventStart);
};
