import { deleteCall, get, patch, post } from '.';

export const BLANK_TEMPLATE_ID = 1;
export const isBlankTemplateId = (id?: number) => id === BLANK_TEMPLATE_ID;

export const getEventTemplates = () => get(`event-templates`) as Promise<{ templates: Bizly.EventTemplate[] }>;

export const getEventTemplateTags = () =>
    get(`event-templates/tags`) as Promise<{ tags: { id: number; name: string }[] }>;

export const createEventTemplate = (data: BizlyAPI.NewEventTemplate) =>
    post(`event-templates`, data) as Promise<{ template: BizlyAPI.NewEventTemplate }>;

export const shareEventTemplate = (templateId: number | string) =>
    post(`event-templates/${templateId}/share`) as Promise<{ template: Bizly.EventTemplate }>;

export const deleteEventTemplate = (templateId: number | string) =>
    deleteCall(`event-templates/${templateId}`) as Promise<{ success: boolean; message: string }>;

export const copyTemplateSchedule = (eventId: number | string, date: Date) =>
    patch(`events/${eventId}/date`, { date }) as Promise<{ event: Bizly.Event }>;
