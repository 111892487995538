import useThemedColor from 'hooks/useThemedColor';
import React, { useState } from 'react';
import EditIconSVG from '../images/icons/pencil.svg?react';
import DeleteIconSVG from '../images/icons/trash-can.svg?react';
import { withInteractibleIconStyles } from '../shared';
import { Column, Copy, ExpandedPane, Row, TopRounded, TruncatingSingleLineCopy } from '../ui';
import EditableContent from './EditableContent';

const EditIcon = withInteractibleIconStyles(EditIconSVG);
const DeleteIcon = withInteractibleIconStyles(DeleteIconSVG);

type PaneProps = {
    beginExpanded?: boolean;
    children: (onExpand: () => void) => JSX.Element;
    collapsible?: boolean;
    contentStyle?: React.CSSProperties;
    id?: string;
    invert?: boolean;
    nested?: boolean;
    label: string;
    onHeaderChange?: (newValue: any) => void;
    onRemove?: (id: string) => void;
    readOnly?: boolean;
    placeholder?: string;
    secondaryLabel?: string;
    showEditIcon?: boolean;
    style?: React.CSSProperties;
    stackedLabels?: boolean;
    stickyHeader?: boolean;
};

export const Pane = ({
    beginExpanded,
    children,
    collapsible = true,
    contentStyle,
    id,
    invert,
    nested,
    label,
    onHeaderChange,
    onRemove,
    placeholder,
    readOnly = false,
    secondaryLabel,
    showEditIcon,
    style,
    stackedLabels,
    stickyHeader,
}: PaneProps) => {
    const {
        agendaDayPane,
        agendaDayPaneText,
        pureBlack,
        pureWhite,
        darkGrey,
        paneListItemBackground,
        paneInsideListItemBackground,
    } = useThemedColor();

    const [isExpanded, setIsExpanded] = useState(beginExpanded);
    const showContent = isExpanded || !collapsible;
    const maxWidth = 380;
    const headerBackground = invert ? agendaDayPane : nested ? paneInsideListItemBackground : paneListItemBackground;
    const LabelsContainer = stackedLabels ? Column : React.Fragment;

    return (
        <Column style={style}>
            <TopRounded
                style={{
                    color: invert ? agendaDayPaneText : pureBlack,
                    minHeight: '29px', // when remove icon is not present, header would otherwise look shorter than ones that have it
                    cursor: 'pointer',
                    ...(stickyHeader ? { position: 'sticky', top: 0, zIndex: 10 } : {}),
                }}
                background={headerBackground}
                hasContentBelow={showContent}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <LabelsContainer>
                    {onHeaderChange ? (
                        <EditableContent
                            onChange={onHeaderChange}
                            headline={label}
                            placeholder={placeholder}
                            onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                        >
                            <TruncatingSingleLineCopy as="label" maxWidth={maxWidth}>
                                {label}
                            </TruncatingSingleLineCopy>
                        </EditableContent>
                    ) : (
                        <TruncatingSingleLineCopy as="label" maxWidth={maxWidth}>
                            {label}
                        </TruncatingSingleLineCopy>
                    )}
                    {secondaryLabel && (
                        <Copy
                            style={
                                stackedLabels
                                    ? { marginTop: 8, fontSize: 15, color: invert ? pureWhite : darkGrey }
                                    : {}
                            }
                        >
                            {secondaryLabel}
                        </Copy>
                    )}
                </LabelsContainer>
                <Row style={{ width: 'unset' }}>
                    {showEditIcon && collapsible && (
                        <EditIcon
                            style={{
                                marginRight: '16px',
                            }}
                            inverted={invert}
                            onClick={() => setIsExpanded(!isExpanded)}
                        />
                    )}
                    {!readOnly && onRemove && (
                        <DeleteIcon
                            inverted={invert}
                            onClick={e => {
                                e.stopPropagation();
                                id !== undefined && onRemove(id);
                            }}
                        />
                    )}
                </Row>
            </TopRounded>
            {showContent && (
                <ExpandedPane
                    style={{
                        backgroundColor: nested ? paneInsideListItemBackground : paneListItemBackground,
                        padding: '16px',
                        ...contentStyle,
                    }}
                >
                    {children(() => setIsExpanded(!isExpanded))}
                </ExpandedPane>
            )}
        </Column>
    );
};
