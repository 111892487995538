import moment from 'moment/moment';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Copy, ExternalLink, Row } from 'ui';

const HELP_URL = 'https://help.bizly.com';
const PRIV_POLICY_URL = import.meta.env.VITE_APP_PRIV_POLICY_URL;

const Bottom = styled(Row)<{ pageOffset: number }>`
    height: 72px;
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    bottom: 0;
    right: 0;
    z-index: 100;
    padding-left: ${({ pageOffset }) => (pageOffset > 0 ? `${36 + pageOffset}` : '36')}px;
    ${({ theme: { getColor, EColors } }) => `
    background: ${getColor(EColors.lightGrey)};
    border-top: 1px solid ${getColor(EColors.grey)};
    `}

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const SubtleCopy = styled(Copy)`
    ${({ theme: { getColor, EColors } }) => `
    color: ${getColor(EColors.darkGrey)};
    `}
`;

const SubtleExternalLink = styled(ExternalLink)`
    ${({ theme: { getColor, EColors } }) => `
    color: ${getColor(EColors.darkGrey)};
    `}
`;

const Shadow = styled.div`
    height: 72px;
    width: 100%;
    display: block;
`;

const Footer = ({ pageOffset = 0 }: { pageOffset?: number }) => {
    return (
        <>
            <Bottom pageOffset={pageOffset} itemSpacing={'small'}>
                <SubtleCopy>{i18n.footer.copyright(moment().year())}</SubtleCopy>
                <SubtleCopy>•</SubtleCopy>
                <SubtleExternalLink href={PRIV_POLICY_URL} openInNewTab>
                    {i18n.footer.privacy}
                </SubtleExternalLink>
                <SubtleCopy>•</SubtleCopy>
                <SubtleExternalLink href={HELP_URL} openInNewTab>
                    {i18n.footer.helpCenter}
                </SubtleExternalLink>
            </Bottom>
            <Shadow />
        </>
    );
};

export default Footer;
