import { createVirtualMeeting, deleteVirtualMeeting, updateVirtualMeeting } from 'api';
import { TPlannerDataChange, TPlannerForm } from 'components/Planner/types';
import { i18n } from 'translation';
import * as URI from 'uri-js';

const useVirtualMeeting = ({
    data,
    setSnackbarKey,
    enqueueSnackbar,
    setLoading,
    event,
    setData,
    refreshEvent,
}: any) => {
    function setVirtualMeetingsData(vmFormData: TPlannerDataChange & { value: TPlannerForm['virtualMeeting'] }) {
        setData((prevData: TPlannerForm) => ({
            ...prevData,
            virtualMeeting: {
                ...(prevData?.virtualMeeting || {}),
                ...vmFormData.value,
            },
        }));
    }

    function addVirtualMeeting() {
        setData((prevData: any) => {
            const { deleted, ...stagedVM } = prevData.virtualMeeting || {};
            return {
                ...prevData,
                virtualMeeting: stagedVM,
            };
        });
    }

    function getErrorVirtualMeeting(data: Partial<Bizly.VirtualMeeting>) {
        if (!data.link) {
            return i18n.venue.inquiry.error.linkRequired;
        }

        const parsedLink = URI.parse(data.link);
        if (!parsedLink.scheme) {
            return i18n.venue.inquiry.error.protocolRequired;
        }

        return false;
    }

    async function onVirtualMeetingSave() {
        const payload = { ...(data.virtualMeeting || {}) };

        if (payload.deleted && payload.id) {
            return removeVirtualMeeting();
        }

        const validationError = getErrorVirtualMeeting(payload);
        if (validationError) {
            setSnackbarKey(enqueueSnackbar(validationError, { variant: 'error', persist: true }) || undefined);
            return;
        }

        setLoading(true);
        try {
            let updatedVirtualMeeting: Bizly.VirtualMeeting;

            if (payload.id) {
                const { virtualMeeting: vmResponse } = await updateVirtualMeeting(event.id, payload, payload.id);
                updatedVirtualMeeting = vmResponse;
            } else {
                const { virtualMeeting: vmResponse } = await createVirtualMeeting(event.id, payload);
                updatedVirtualMeeting = vmResponse;
            }

            setData((prevData: TPlannerForm) => ({
                ...prevData,
                virtualMeeting: updatedVirtualMeeting,
            }));
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        } finally {
            setLoading(false);
            refreshEvent();
        }
    }

    async function removeVirtualMeeting() {
        const vmId = data.virtualMeeting?.id;
        if (!vmId) return;

        setLoading(true);
        try {
            await deleteVirtualMeeting(event.id, vmId);

            // refreshEvent must happen before setStaged to not show old data
            await refreshEvent();
            setData({});
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            await refreshEvent();
        } finally {
            setLoading(false);
        }
    }

    function onVirtualMeetingRemove() {
        setData((prev: any) => ({
            ...prev,
            virtualMeeting: { ...prev.virtualMeeting, deleted: true },
        }));
    }

    return { setVirtualMeetingsData, addVirtualMeeting, onVirtualMeetingSave, onVirtualMeetingRemove };
};

export default useVirtualMeeting;
