import RSVPForm from 'components/RSVP/RSVPForm/RSVPForm';
import { Spinner } from 'components/Spinner';
import Pill from 'components/ui/Pill';
import useThemedColor from 'hooks/useThemedColor';
import { ATTENDING_STATUSES } from 'pages/RSVP';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Button, Column, Line, PaddedCard, Row, Spacer } from '../../ui';
import { H2Headline, WhiteHeadliner } from '../ui/Headline';
import { RSVPCardContent } from './RSVPCardContent';
import { CustomQuestionsForm } from './RSVPForm';

const PREVIEW_CARD_WIDTH = 600;
const CARD_WIDTH = 680;
const IMAGE_HEIGHT = 400;

const Banner = styled.div`
    position: relative;
`;

// vertical-align bottom remove space under image
const FittedImage = styled.img`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    object-fit: cover;
    vertical-align: bottom;
`;

const ImageBackgroundGradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: url('https://res.cloudinary.com/hdd626jg7/image/upload/t_email_overlay_gradient/emails/email_image_gradient.png');
    background-size: cover;
`;

const InviteTitle = styled(Column)`
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 32px 19px 32px;
    max-width: calc(100% - 64px);

    ${WhiteHeadliner} {
        word-break: break-word;
    }
`;

const LogoWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-bottom-right-radius: 8px;
`;

const CompanyLogo = styled('div')<{ src: string }>`
    width: 5.25rem;
    height: 5.25rem;
    background-image: url(${props => props.src || 'none'});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`;

const Container = styled('div')`
    padding: 2rem;
`;

const RSVPCard = ({
    team,
    image,
    name,
    description,
    startsAt,
    endsAt,
    schedule,
    plannerSchedule,
    plannedBy,
    parcelDates = null,
    useParcelDates,
    timezone,
    location,
    address,
    cityState,
    vmSharingMethod,
    virtualMeeting,
    isNote,
    surveyTitle,
    surveyQuestions,
    hasQuestions,
    isPreview = true,
    attendee,
    formSettings,
    attending,
    submitted,
    onChange,
    handleSubmit,
    saveButtonDisabled,
    cancelledAt,
}: any) => {
    const { firstName, lastName } = plannedBy;
    const { color, imageUrl } = team as { color: Themed.Color; imageUrl: string };
    const { warningAction } = useThemedColor();

    if (!formSettings && !attending) return <Spinner />;

    return (
        <PaddedCard padding={0} style={{ position: 'relative', wordWrap: 'break-word' }}>
            <Banner>
                {image ? (
                    <FittedImage
                        src={image}
                        width={isPreview ? PREVIEW_CARD_WIDTH : CARD_WIDTH}
                        height={IMAGE_HEIGHT}
                    />
                ) : (
                    <div
                        style={{
                            width: isPreview ? PREVIEW_CARD_WIDTH : CARD_WIDTH,
                            height: IMAGE_HEIGHT,
                            backgroundColor: color,
                        }}
                    />
                )}
                <ImageBackgroundGradient />
                <InviteTitle alignItems="flex-start">
                    {cancelledAt && (
                        <Pill color={warningAction} label={i18n.search.meetingList.cancelled} noShadow centerText />
                    )}
                    <WhiteHeadliner noDot>{name}</WhiteHeadliner>
                </InviteTitle>
            </Banner>
            <LogoWrapper>
                <CompanyLogo src={imageUrl} />
            </LogoWrapper>

            <Container>
                <RSVPCardContent
                    color={color}
                    firstName={firstName}
                    lastName={lastName}
                    name={name}
                    description={description}
                    startsAt={startsAt}
                    endsAt={endsAt}
                    schedule={schedule}
                    plannerSchedule={plannerSchedule}
                    parcelDates={parcelDates}
                    useParcelDates={useParcelDates}
                    timezone={timezone}
                    location={location}
                    address={address}
                    cityState={cityState}
                    vmSharingMethod={vmSharingMethod}
                    virtualMeeting={virtualMeeting}
                    isNote={isNote}
                    hasQuestions={hasQuestions}
                    isPreview={isPreview}
                    attending={attending}
                    attendee={attendee}
                    submitted={submitted}
                    formSettings={formSettings}
                />

                {!isPreview && attending && !submitted && attendee && !cancelledAt && (
                    <>
                        <RSVPForm
                            attendee={attendee}
                            formSettings={formSettings}
                            onChange={onChange}
                            eventRegistration={false}
                            timezone={timezone}
                            parcelDates={parcelDates}
                        />

                        <Spacer />

                        <Row>
                            <Button
                                onClick={handleSubmit}
                                style={{ backgroundColor: color }}
                                disabled={saveButtonDisabled}
                            >
                                {attendee.status === ATTENDING_STATUSES.ATTENDING
                                    ? i18n.button.submit
                                    : i18n.button.register}
                            </Button>
                        </Row>
                    </>
                )}

                {!isPreview && !!surveyQuestions?.length && !submitted && (
                    <>
                        <Line />
                        <Column>
                            <Column itemSpacing="largest">
                                {surveyTitle && <H2Headline>{surveyTitle}</H2Headline>}
                                <CustomQuestionsForm
                                    onChange={onChange}
                                    questions={surveyQuestions}
                                    value={surveyQuestions}
                                    field=""
                                />
                            </Column>
                            <Row>
                                <Button
                                    onClick={handleSubmit}
                                    style={{ backgroundColor: color }}
                                    disabled={saveButtonDisabled}
                                >
                                    {i18n.button.submit}
                                </Button>
                            </Row>
                        </Column>
                    </>
                )}
            </Container>
        </PaddedCard>
    );
};

export default RSVPCard;
