import { Spinner } from 'components/Spinner';
import copy from 'copy/login';
import React from 'react';
import { BizlyLogoPageWithCard } from './ui';
import { useAuthenticate } from './util';

export default function ActivateNewUser() {
    useAuthenticate(true);

    return (
        <BizlyLogoPageWithCard heading={copy.acceptInvite.heading}>
            <Spinner />
        </BizlyLogoPageWithCard>
    );
}
