import { i18n } from 'translation';

export default {
    login: {
        heading: i18n.signIn.loginHeading,
        subheading: i18n.signIn.loginSubheading,
        email: i18n.authFields.email,
        button: i18n.signIn.submitButtonLabel,
        invalidEmail: i18n.signIn.invalidEmail,
    },
    acceptInvite: {
        heading: i18n.signIn.acceptInviteHeading,
    },
};
