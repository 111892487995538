import { Headliner } from 'components/ui/Headline';
import React from 'react';
import styled from 'styled-components';
import { Column, Copy } from 'ui';

const Container = styled(Column)`
    width: 423px;
    margin: 96px auto 0;
`;

const Card = styled(Column)`
    position: relative;
    border-radius: 8px;

    padding: 30px;
    box-sizing: border-box;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const FooterCopy = styled(Copy)`
    margin-left: 32px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

type TContentCard = {
    headlineCopy?: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
};

export default function ContentCard({ headlineCopy, children, footer }: TContentCard) {
    return (
        <Container itemSpacing="medium">
            {headlineCopy && <Headliner>{headlineCopy}</Headliner>}
            <Card itemSpacing="default">{children}</Card>
            {footer && <FooterCopy>{footer}</FooterCopy>}
        </Container>
    );
}
