import { uploadCroppedImage } from 'cloudinary';
import { useCallback, useState } from 'react';
import { getCroppedImg } from '../components/LogoEditor/cropImage';

const useUploadLogo = () => {
    const [showLogoEditor, setShowLogoEditor] = useState<boolean>(false);
    const [logo, setLogo] = useState<{ url: string; title: string }>({
        url: '',
        title: '',
    });
    const [rotation, setRotation] = useState<number>(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const uploadLogo = useCallback(
        async (logo: { url: string; title: string }) => {
            try {
                const imageBlob = await getCroppedImg(logo, croppedAreaPixels, rotation);

                if (imageBlob) {
                    const result = await uploadCroppedImage(imageBlob, logo?.title);

                    return {
                        url: result?.url,
                        title: logo?.title,
                    };
                }
            } catch (e) {
                console.error(e);
            }
        },
        [croppedAreaPixels, rotation]
    );

    const handleLogoUpload = async () => {
        try {
            setIsLoading(true);

            const response = await uploadLogo(logo);

            setIsLoading(false);
            setShowLogoEditor(false);

            return response;
        } catch (error) {
            console.log(error);
        }
    };

    return {
        handleLogoUpload,
        showLogoEditor,
        setShowLogoEditor,
        logo,
        setLogo,
        isLoading,
        setRotation,
        rotation,
        setCroppedAreaPixels,
    };
};

export default useUploadLogo;
