import { styled } from '@mui/system';
import CircleBackIcon from 'images/icons/circle_back.svg?react';
import { useNavigate } from 'react-router-dom';
import { i18n } from 'translation';
import { AlignedRow, Column, Copy, Row, Spacer } from 'ui';

const BackIcon = styled(CircleBackIcon)`
    fill: transparent;
    margin-right: 0.625rem;
`;

const BackButtonWrapper = styled(AlignedRow)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    cursor: pointer;
    width: auto;
`;

const VenueHeadline = styled(Copy)`
    font-size: 0.938rem;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;
const Brand = styled(Copy)`
    font-size: 0.813rem;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const VenueName = styled('h2')({
    fontSize: '2rem',
    fontWeight: '500',
    marginBlock: '0',
});

type TVenueTitleProps = {
    venue: Bizly.Venue;
    hideTopNavControl?: boolean;
};

const VenueTitle = ({ venue, hideTopNavControl = false }: TVenueTitleProps) => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            {!hideTopNavControl && (
                <>
                    <AlignedRow>
                        <BackButtonWrapper onClick={goBack}>
                            <BackIcon />
                            <Copy>{i18n.button.back}</Copy>
                        </BackButtonWrapper>
                    </AlignedRow>
                    <Spacer />
                </>
            )}

            <Row>
                <VenueName>{venue.name}</VenueName>
            </Row>
            <Spacer small />
            <Row>
                <Row justifyContent={'flex-start'} alignItems={'flex-end'}>
                    <Column>
                        {venue.headline && (
                            <>
                                <VenueHeadline>{`${venue.headline}.`}</VenueHeadline>
                            </>
                        )}
                    </Column>
                </Row>
                <Row justifyContent={'flex-end'} alignItems={'flex-end'}>
                    {venue.brand && (
                        <>
                            <Brand>{`${i18n.common.brand}: ${venue.brand.name}`}</Brand>
                        </>
                    )}
                </Row>
            </Row>
        </>
    );
};

export default VenueTitle;
