import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { currentInquiryActions } from './store';

export default function LoadCurrentInquiry({ playbookId }: { playbookId: number | string }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            currentInquiryActions.load(playbookId);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    }, [playbookId, enqueueSnackbar]);

    return null;
}
