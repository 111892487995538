import Form from 'components/Form';
import { TAccommodation, TPlannerDataChange, TPlannerSections } from 'components/Planner/types';
import Button from 'components/ui/Button';
import TextButton from 'components/ui/Button/TextButton';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column as UIColumn, Copy, Row } from 'ui';
import { accommodationsSchema, defaultAccommodationsFields } from './plannerFormSchema';
import { ACCOMMODATIONS } from './utils';

const Column = styled(UIColumn)`
    width: 100%;

    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Body = styled(UIColumn)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    overflow: hidden;

    div[class*='FormFields__CounterInputField'] {
        justify-content: flex-end;
    }
`;

const Head = styled(Row)`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    padding: 16px 0 15px 0;
    justify-content: space-between;
    align-items: flex-start;
`;

export const ClearTextButton = styled(TextButton)`
    margin: 0 16px 16px 0;
    font-weight: 500;
`;

const SaveButton = styled(Button)`
    align-self: flex-end;
    margin-top: 24px;
    font-weight: 400;
`;

type TAccommodationUpdate = TPlannerDataChange & { value: TAccommodation };

type TAccommodationsDetailsProps = {
    editable: boolean;
    accommodations: TAccommodation[];
    setAccommodationsData: (
        accommodationFormData: TAccommodationUpdate,
        idx: number,
        targetSection: TPlannerSections
    ) => void;
    clearAccommodationsData: () => void;
    loading: boolean;
    onSave: () => void;
    readonly?: boolean;
};

const AccommodationsDetails = ({
    editable,
    accommodations,
    setAccommodationsData,
    clearAccommodationsData,
    loading,
    onSave,
    readonly = false,
}: TAccommodationsDetailsProps) => {
    const filteredaccommodations = useMemo(
        () =>
            accommodations
                .map((accommodation, originIndex) => {
                    return { ...accommodation, originIndex };
                })
                .filter(accommodation => !accommodation.delete)
                .map((accommodation, idx: number) => {
                    return (
                        <Form
                            key={accommodation.id || `accommodation-${idx}`}
                            fields={defaultAccommodationsFields}
                            schema={accommodationsSchema}
                            value={accommodation}
                            onChange={(e: TAccommodationUpdate) => {
                                setAccommodationsData(e, accommodation.originIndex, ACCOMMODATIONS);
                            }}
                            disabled={!editable}
                            readonly={readonly}
                        />
                    );
                }),
        [accommodations, editable, readonly, setAccommodationsData]
    );
    return (
        <Column itemSpacing="small">
            <Copy>{i18n.common.guestRoomNeeded}</Copy>
            <Head>
                <Copy>{i18n.venue.planner.date}</Copy>
                <Copy>{i18n.venue.planner.noOfRooms}</Copy>
            </Head>
            {filteredaccommodations.length > 0 ? (
                <>
                    <Body>{filteredaccommodations}</Body>
                    {editable && !readonly && (
                        <Row justifyContent="flex-end">
                            <ClearTextButton onClick={clearAccommodationsData}>{i18n.button.clear}</ClearTextButton>
                        </Row>
                    )}
                </>
            ) : (
                <Copy>{i18n.venue.planner.noDatesSelected}</Copy>
            )}

            {editable && !readonly && (
                <SaveButton onClick={onSave} disabled={!editable || loading} small width={68}>
                    {i18n.button.save}
                </SaveButton>
            )}
        </Column>
    );
};

export default AccommodationsDetails;
