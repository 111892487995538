import { BizlyThemeOverride } from 'ThemedApp';
import EventDetail from 'components/Events/EventDetail';
import { SpinnerOverlay } from 'components/Spinner';
import TeamSelector from 'components/TeamSelector';
import { format } from 'date-fns';
import { Approvals } from 'pages/Approvals';
import { Authentication, ExternalRedirect } from 'pages/Authentication/Authentication';
import HealthSafety from 'pages/HealthSafety';
import Home from 'pages/Home/Home';
import Integrations from 'pages/Integrations';
import ActivateNewUser from 'pages/Login/ActivateNewUser';
import { Login } from 'pages/Login/Login';
import ProfileForm from 'pages/Login/Profile';
import Meetings from 'pages/Meetings';
import { PageNotFound } from 'pages/PageNotFound';
import RSVP from 'pages/RSVP';
import ReportingPage from 'pages/Reporting';
import SearchPage from 'pages/Search';
import Survey from 'pages/Survey';
import TeamSettingsPage from 'pages/TeamSettings/TeamSettingsPage';
import VenuesRoute from 'pages/Venues';
import { useUser } from 'providers/user';
import { parse } from 'query-string';
import Intercom from 'react-intercom';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { LoadEventTemplates } from 'stores/eventTemplates';
import { Column } from 'ui';
import { takeFirstIfArray } from '../util';
import { BizlyOS } from './BizlyOS';
import TemplatesRoute from './Templates';

const INTERCOM_APP_ID =
    import.meta.env.VITE_APP_ENV === 'prod'
        ? import.meta.env.VITE_APP_INTERCOM_APP_ID_PRODUCTION
        : import.meta.env.VITE_APP_INTERCOM_APP_ID;

const RespectfulRedirect = () => {
    const location = useLocation();
    const { redirect } = takeFirstIfArray(parse(location.search));

    return <Navigate to={redirect ?? '/'} />;
};

const AuthBlockPassed = () => {
    const { toggles, user, isTeamAdmin } = useUser();

    return (
        <Column style={{ height: '100%', minWidth: 1200 }} key={user.team?.id}>
            <Routes>
                {['/', '/team-picker'].map(route => (
                    <Route
                        path={route}
                        key={route}
                        element={
                            <>
                                <LoadEventTemplates />
                                <Home />
                                {!user.isSsoUser && (
                                    <Routes>
                                        <Route path="/team-picker" element={<TeamSelector initOpen />} />
                                    </Routes>
                                )}
                            </>
                        }
                    />
                ))}
                <Route path="/profile" element={<ProfileForm />} />
                <Route
                    path="/login/*"
                    element={user.email ? <RespectfulRedirect /> : <Authentication page="sign-in" />}
                />
                <Route
                    path="/sign-in/*"
                    element={user.email ? <RespectfulRedirect /> : <Authentication page="sign-in" />}
                />
                <Route path="/health-safety" element={<HealthSafety />} />

                <Route path="/event/:eventId/*" element={<EventDetail />} />
                <Route
                    path="/events/:eventId/rsvp"
                    element={
                        <BizlyThemeOverride>
                            <RSVP />
                        </BizlyThemeOverride>
                    }
                />
                <Route
                    path="/events/:eventId/survey"
                    element={
                        <BizlyThemeOverride>
                            <Survey />
                        </BizlyThemeOverride>
                    }
                />
                <Route path="/events/*" element={<Meetings />} />
                <Route path="/search/*" element={<SearchPage />} />

                <Route path="/playbooks/*" element={<TemplatesRoute />} />

                <Route path="/venues/*" element={<VenuesRoute />} />
                {isTeamAdmin && <Route path="/reporting" element={<ReportingPage />} />}
                {toggles.gate.integrations && <Route path="/integrations/*" element={<Integrations />} />}
                {isTeamAdmin && <Route path="/approvals" element={<Approvals />} />}
                <Route path="/settings/team" element={<TeamSettingsPage />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Column>
    );
};

const RedirectToSignIn = () => {
    const location = useLocation();

    const curPath = encodeURIComponent(location.pathname + location.search);
    return <Navigate to={`/sign-in?redirect=${curPath}`} />;
};

const AuthBlocked = () => (
    <Column style={{ height: '100%' }}>
        <Routes>
            <Route path="/accept-team-invite" element={<ActivateNewUser />} />
            <Route path="/login" element={<Login />} />
            <Route path="/events/:eventId/rsvp" element={<RSVP userAnonymous />} />
            <Route path="/events/:eventId/survey" element={<Survey />} />
            <Route path="/sign-in/*" element={<Authentication page="sign-in" />} />
            <Route path="/sign-up/*" element={<ExternalRedirect to="https://www.bizly.com/pricing/" />} />
            <Route path="*" element={<RedirectToSignIn />} />
        </Routes>
    </Column>
);

export const AppWithUser = () => {
    const { user } = useUser();
    const intercomSettings =
        user && user.id
            ? {
                  user_id: user.id,
                  name: user.firstName + ' ' + user.lastName,
                  email: user.email,
                  created_at: format(new Date(), 't'),
                  user_hash: user.intercomIdentityVerificationHash,
                  ...(user.team && {
                      company: {
                          id: user.team.id,
                          name: user.team.name,
                      },
                  }),
              }
            : {};

    return user.loaded ? (
        <>
            <Routes>
                <Route path="/os/*" element={<BizlyOS />} />
                <Route path="*" element={user.token ? <AuthBlockPassed /> : <AuthBlocked />} />
            </Routes>
            <Intercom appID={INTERCOM_APP_ID || ''} {...intercomSettings} />
        </>
    ) : (
        <SpinnerOverlay />
    );
};
