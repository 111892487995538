import { ComingSoonMessage } from 'components/BizlyOS/ComingSoonMessage';
import { NewsletterSignUp } from 'components/BizlyOS/NewsletterSignUp';
import useShowModal from 'hooks/useShowModal';
import AnalyticsComingSoonSplash from 'images/bizlyOS/coming-soon/analytics.svg?react';

export const Analytics = () => {
    const { modalShown, showModal, hideModal } = useShowModal();

    return (
        <>
            <ComingSoonMessage
                pageTitle="Analytics"
                title="Unlock the Power of Data"
                description="Gain valuable insights into your venue's performance and make informed decisions to grow your business. Bizly's upcoming Analytics dashboard will equip you with the data you need to succeed. Track key metrics like views, inquiries, bookings, and revenue, all in one place."
                buttonText="Notify Me!"
                onButtonClick={showModal}
                Icon={AnalyticsComingSoonSplash}
            />
            <NewsletterSignUp
                open={modalShown}
                handleClose={hideModal}
                title="Be the first to try Bizly Analytics"
                description="Enter your email below to join the beta list and be the first to know when Analytics launches!"
                productName="Analytics"
                buttonText="Sign me up!"
            />
        </>
    );
};
