import { CONNECTOR_TYPES } from 'api/connectors';
import Button from 'components/ui/Button';
import { H3Headline } from 'components/ui/Headline';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import { useLocation } from 'react-router-dom';
import { integrationsActions, useIntegrations } from 'stores/integrations';
import styled from 'styled-components';
import { Column, Copy, ExternalLink, Row } from 'ui';

const AutoButton = styled(Button)`
    margin-right: auto;
`;

const ConnectorsCol = styled(Column)`
    margin-left: 16px;
`;

const ConnectorNames = {
    [CONNECTOR_TYPES.ZOOM]: 'Zoom',
    [CONNECTOR_TYPES.MS]: 'Microsoft',
    [CONNECTOR_TYPES.GOOGLE]: 'Google',
} as const;

const Benefits = {
    [CONNECTOR_TYPES.ZOOM]: ['Generate Zoom links'],
    [CONNECTOR_TYPES.MS]: [
        'Generate Teams links',
        'Invite Microsoft contacts',
        'Synchronize Bizly meetings to your Microsoft Calendar',
    ],
    [CONNECTOR_TYPES.GOOGLE]: [
        'Generate Meet links',
        'Invite Google contacts',
        'Synchronize Bizly meetings to your Google Calendar',
    ],
};

const defaultConnectors: BizlyAPI.Connector[] = ['zoom', 'ms-graph', 'google'];

export default function IntegrationConnection({
    connectors = defaultConnectors,
    benefits = {},
    withRedirect,
    preAuth,
}: {
    connectors?: BizlyAPI.Connector[];
    benefits?: Partial<Record<BizlyAPI.Connector, string[]>>;
    withRedirect?: boolean;
    preAuth?: () => Promise<void>;
}) {
    const { enqueueSnackbar } = useSnackbar();

    const { user, delConnector } = useUser();

    const { isAuthLoading, isDisconnecting } = useIntegrations();
    const connected = (type: BizlyAPI.Connector) => user.connectors?.some(c => c.type === type);

    const location = useLocation();
    const handleConnect = async (connector: BizlyAPI.Connector) => {
        if (preAuth) {
            await preAuth?.();
        }

        try {
            await integrationsActions.auth(connector, withRedirect ? location.pathname : undefined);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const handleDisconnect = async (connector: BizlyAPI.Connector) => {
        try {
            await integrationsActions.disconnect(connector);
            delConnector(connector);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const connectionHandler = (type: BizlyAPI.Connector) => () =>
        connected(type) ? handleDisconnect(type) : handleConnect(type);
    const buttonText = (type: BizlyAPI.Connector) =>
        `${connected(type) ? 'Disconnect' : 'Connect'} ${ConnectorNames[type]}`;

    const showConnector = {
        [CONNECTOR_TYPES.ZOOM]: connectors.includes(CONNECTOR_TYPES.ZOOM) && user?.featureFlags?.zoomIntegration,
        [CONNECTOR_TYPES.MS]: connectors.includes(CONNECTOR_TYPES.MS) && user?.featureFlags?.msGraphIntegration,
        [CONNECTOR_TYPES.GOOGLE]: connectors.includes(CONNECTOR_TYPES.GOOGLE),
    };

    const buttonsDisabled = isAuthLoading || isDisconnecting;

    return (
        <Column itemSpacing="small">
            <Copy large>Connect with these service providers to:</Copy>

            <ConnectorsCol itemSpacing="larger">
                {showConnector[CONNECTOR_TYPES.ZOOM] && (
                    <Column itemSpacing="small">
                        <H3Headline>Zoom</H3Headline>
                        <ul>
                            {(benefits[CONNECTOR_TYPES.ZOOM] ?? Benefits[CONNECTOR_TYPES.ZOOM]).map(point => (
                                <li key="item">{point}</li>
                            ))}
                        </ul>
                        <Row itemSpacing="larger" alignItems="center">
                            <AutoButton
                                onClick={connectionHandler(CONNECTOR_TYPES.ZOOM)}
                                width="auto"
                                warning={connected(CONNECTOR_TYPES.ZOOM)}
                                disabled={buttonsDisabled}
                            >
                                {buttonText(CONNECTOR_TYPES.ZOOM)}
                            </AutoButton>
                            {connected(CONNECTOR_TYPES.ZOOM) && (
                                <ExternalLink href="https://zoom.us/profile/setting" openInNewTab>
                                    Manage Zoom Settings
                                </ExternalLink>
                            )}
                        </Row>
                    </Column>
                )}

                {showConnector[CONNECTOR_TYPES.MS] && (
                    <Column>
                        <H3Headline>Microsoft Calendar</H3Headline>
                        <ul>
                            {(benefits[CONNECTOR_TYPES.MS] ?? Benefits[CONNECTOR_TYPES.MS]).map(point => (
                                <li key="item">{point}</li>
                            ))}
                        </ul>
                        <AutoButton
                            onClick={connectionHandler(CONNECTOR_TYPES.MS)}
                            width="auto"
                            warning={connected(CONNECTOR_TYPES.MS)}
                            disabled={buttonsDisabled}
                        >
                            {buttonText(CONNECTOR_TYPES.MS)}
                        </AutoButton>
                    </Column>
                )}

                {showConnector[CONNECTOR_TYPES.GOOGLE] && (
                    <Column>
                        <H3Headline>Google Calendar</H3Headline>
                        <ul>
                            {(benefits[CONNECTOR_TYPES.GOOGLE] ?? Benefits[CONNECTOR_TYPES.GOOGLE]).map(point => (
                                <li key="item">{point}</li>
                            ))}
                        </ul>
                        <AutoButton
                            onClick={connectionHandler(CONNECTOR_TYPES.GOOGLE)}
                            width="auto"
                            warning={connected(CONNECTOR_TYPES.GOOGLE)}
                            disabled={buttonsDisabled}
                        >
                            {buttonText(CONNECTOR_TYPES.GOOGLE)}
                        </AutoButton>
                    </Column>
                )}
            </ConnectorsCol>
        </Column>
    );
}
