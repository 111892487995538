import { ComingSoonMessage } from 'components/BizlyOS/ComingSoonMessage';
import { NewsletterSignUp } from 'components/BizlyOS/NewsletterSignUp';
import useShowModal from 'hooks/useShowModal';
import MessagesComingSoonSplash from 'images/bizlyOS/coming-soon/messages.svg?react';

export const Messages = () => {
    const { modalShown, showModal, hideModal } = useShowModal();

    return (
        <>
            <ComingSoonMessage
                pageTitle="Messages"
                title="Your Bizly Inbox is on its Way!"
                description="You'll be able to handle all your client communication in one convenient place, right here on Bizly. Share files, exchange messages, and track every interaction, all tied to specific events and inquiries. No more digging through endless email threads or worrying about missed messages. Just smooth, efficient communication that helps you close deals faster."
                buttonText="Notify Me!"
                onButtonClick={showModal}
                Icon={MessagesComingSoonSplash}
            />
            <NewsletterSignUp
                open={modalShown}
                handleClose={hideModal}
                title="Be the first to try Bizly Inbox"
                description="Enter your email below to join the beta list and be the first to know when Inbox launches!"
                productName="Inbox"
                buttonText="Sign me up!"
            />
        </>
    );
};
