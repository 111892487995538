import { i18n } from 'translation';
import { timeOptions } from 'utils/date_util';
import CurrencyField from '../CurrencyField';
import { EVENT_SPACES_REQUEST_ITEM_SPACING, EVENT_SPACES_REQUEST_WIDTH, TOption, TSpace } from './utils';
import VenueSpacesModal from './VenueSpacesModal';

const standardizedTimeOptions = timeOptions.map(o => ({
    ...o,
    name: o.name.replace(' ', '').toLowerCase(),
    id: o.name.replace(' ', '').toLowerCase(),
}));

export const timeAndSpaceFields = (
    onlyShowRequest?: boolean,
    spaces?: TSpace[],
    onUpdateVenueSpaces?: (spaces: any) => void
) => ({
    requestedGuests: {
        type: 'display',
        fixedWidth: EVENT_SPACES_REQUEST_WIDTH,
        options: { formatter: i18n.proposalForm.eventSpaces.guestsFormatter },
    },
    proposedVenueSpaceId: {
        type: VenueSpacesModal,
        options: {
            spaces,
            onUpdateVenueSpaces,
        },
    },
    requestedStartTime: {
        type: 'display',
        perRow: 'auto',
    },
    requestedEndTime: {
        type: 'display',
        perRow: 'auto',
    },
    proposedStartTime: {
        type: 'select',
        perRow: 4,
        disabled: onlyShowRequest,
        options: {
            options: standardizedTimeOptions,
        },
    },
    proposedEndTime: {
        type: 'select',
        perRow: 4,
        disabled: onlyShowRequest,
        options: {
            options: standardizedTimeOptions,
        },
    },
});

const dash = { type: 'display', options: { label: '-' } };
const requestedTimesSchema = {
    type: 'nested',
    fixedWidth: EVENT_SPACES_REQUEST_WIDTH,
    schema: [
        {
            fields: ['requestedStartTime', dash, 'requestedEndTime'],
            itemSpacing: 'small',
            spacing: false,
        },
    ],
};
const proposedTimesSchema = {
    type: 'nested',
    schema: [
        {
            fields: ['proposedStartTime', dash, 'proposedEndTime'],
            itemSpacing: 'small',
            spacing: false,
        },
    ],
};

export const timeAndSpaceSchema = (eventSpaceName: string) => [
    {
        key: 'space',
        fields: ['requestedGuests', 'proposedVenueSpaceId'],
        itemSpacing: EVENT_SPACES_REQUEST_ITEM_SPACING,
        spacing: 'xsmall',
        header: eventSpaceName,
        headerSpacing: 'small',
    },
    {
        key: 'time',
        fields: [requestedTimesSchema, proposedTimesSchema],
        itemSpacing: EVENT_SPACES_REQUEST_ITEM_SPACING,
        spacing: false,
    },
];

/*


SETUP


*/

export const setupFields = (options: TOption[]) => ({
    requestedSetupId: {
        type: 'select',
        readonly: true,
        fixedWidth: EVENT_SPACES_REQUEST_WIDTH,
        prompt: i18n.proposalForm.eventSpaces.seating,
        options: {
            options,
        },
    },
    proposedSetupId: {
        type: 'select',
        perRow: 3,
        prompt: ' ',
        options: {
            options,
        },
    },
});

export const setupSchema = (onlyShowRequest?: boolean) => [
    {
        key: 'setup',
        fields: onlyShowRequest ? ['requestedSetupId'] : ['requestedSetupId', 'proposedSetupId'],
        itemSpacing: EVENT_SPACES_REQUEST_ITEM_SPACING,
        spacing: false,
    },
];

/*


COSTS


*/

export const costsFields = (currencyOptions: { id: string }[], currencyCode?: string | null) => ({
    currencyCode: {
        type: CurrencyField,
        prompt: ' ',
        perRow: 'auto',
        options: { options: currencyOptions },
    },
    proposedRoomRate: {
        type: 'text',
        prompt: i18n.proposalForm.eventSpaces.dailyRentalRate,
        perRow: 'auto',
        options: { numberType: 'currency', currency: currencyCode },
    },
    proposedFbMinimum: {
        type: 'text',
        prompt: i18n.proposalForm.eventSpaces.dailyFB,
        perRow: 'auto',
        options: { numberType: 'currency', currency: currencyCode },
    },
    proposedRatePerPerson: {
        type: 'text',
        prompt: i18n.proposalForm.eventSpaces.perPersonRate,
        perRow: 'auto',
        options: { numberType: 'currency', currency: currencyCode },
    },
    proposedMinGuests: { type: 'counter', prompt: i18n.proposalForm.eventSpaces.minGuestsNo, perRow: 'auto' },
    salesTax: {
        type: 'text',
        prompt: i18n.proposalForm.eventSpaces.salesTax,
        perRow: 3,
        options: { numberType: 'percentage' },
    },
    serviceCharge: {
        type: 'text',
        prompt: i18n.proposalForm.eventSpaces.serviceCharge,
        perRow: 3,
        options: { numberType: 'percentage' },
    },
    gratuity: {
        type: 'text',
        prompt: i18n.proposalForm.eventSpaces.gratuity,
        perRow: 3,
        options: { numberType: 'percentage' },
    },
    useDdr: {
        type: 'radioselect',
        fixedWidth: 440,
        options: {
            options: [
                { id: false, label: i18n.proposalForm.eventSpaces.dailyRate },
                { id: true, label: i18n.proposalForm.eventSpaces.perPersonRate },
            ],
        },
    },
});

const roomRateSchema = {
    type: 'nested',
    schema: [
        {
            key: 'rateAndCurrency',
            fields: ['proposedRoomRate', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};

const fbSchema = {
    type: 'nested',
    schema: [
        {
            key: 'fbAndCurrency',
            fields: ['proposedFbMinimum', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};

const perPersonSchema = {
    type: 'nested',
    perRow: 3,
    schema: [
        {
            key: 'perPersonAndCurrency',
            fields: ['proposedRatePerPerson', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};

export const costsSchema = (ddr?: boolean | null) => [
    {
        header: 'Pricing',
        fields: ['useDdr'],
        spacing: 'smallish',
    },
    ...(ddr
        ? [
              {
                  fields: [perPersonSchema, 'proposedMinGuests'],
                  spacing: 'largest',
              },
          ]
        : [
              {
                  fields: [roomRateSchema, fbSchema],
                  spacing: 'largest',
              },
          ]),
    {
        header: i18n.proposalForm.eventSpaces.feesAndTaxes,
        fields: ['salesTax', 'serviceCharge', 'gratuity'],
        spacing: false,
    },
];

export { AVFields, AVSchema } from './eventSpacesAVField';
export { FBFields, FBSchema } from './eventSpacesFBField';

export const commissionsForm = {
    fields: {
        eventSpacesCommission: {
            type: 'text',
            prompt: i18n.proposalForm.eventSpaces.roomRatesCommissionable,
            nullSwitch: true,
            options: {
                numberType: 'percentage',
            },
            perRow: 2,
        },
        fbCommission: {
            type: 'text',
            prompt: i18n.proposalForm.eventSpaces.fbCommissionable,
            nullSwitch: true,
            options: {
                numberType: 'percentage',
            },
            perRow: 2,
        },
    },
    schema: [
        {
            key: 'eventSpacesCommission',
            fields: ['eventSpacesCommission'],
            spacing: 'smallish',
        },
        {
            key: 'fbCommission',
            fields: ['fbCommission'],
            spacing: false,
        },
    ],
};
