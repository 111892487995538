import AddToCalendarButton from 'components/ui/AddToCalendar';
import React from 'react';
import styled from 'styled-components';
import { EColors } from 'theme';
import { tzMoment } from 'utils/moment';
import { Button, Column, Row, Spacer } from '../../ui';
import { H2Headline, WhiteHeadliner } from '../ui/Headline';

const WhiteButton = styled(Button)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const RSVPHeader = ({
    name,
    startsAt,
    endsAt,
    cancelledAt,
    description,
    location,
    virtualMeeting,
    timeZone,
    attending,
    submitted,
    editResponse,
    cardWidth = 680,
}: {
    name: string;
    startsAt: string;
    endsAt: string;
    cancelledAt?: string;
    description: string;
    location?: string;
    virtualMeeting?: {
        link: string;
        notes: string;
    };
    timeZone: string;
    attending: boolean;
    submitted: boolean;
    editResponse?: () => void;
    cardWidth: number;
}) => {
    const headline = cancelledAt
        ? 'This event has been cancelled'
        : submitted
        ? 'Registration Confirmed'
        : attending
        ? 'Register to Attend'
        : 'Thanks for the response.';
    const message = cancelledAt
        ? ''
        : `You're ${attending ? '' : 'not '}attending ${name || ''} on ${
              startsAt ? tzMoment(startsAt).format('MMM Do') : ''
          }.`;
    const hasDescription = submitted || !attending;

    return (
        <Column style={{ width: cardWidth }}>
            <WhiteHeadliner noDot>{headline}</WhiteHeadliner>
            <Spacer large />

            {hasDescription && (
                <>
                    {message && <H2Headline $color={EColors.pureWhite}>{message}</H2Headline>}
                    {submitted && (
                        <>
                            <Spacer />
                            <Row itemSpacing="smallish">
                                {editResponse && <WhiteButton onClick={editResponse}>Edit Response</WhiteButton>}
                                {startsAt && endsAt && (
                                    <AddToCalendarButton
                                        title={name}
                                        location={location || ''}
                                        timeZone={timeZone}
                                        startsAt={startsAt}
                                        endsAt={endsAt}
                                        fileName={name}
                                    />
                                )}
                            </Row>
                        </>
                    )}
                    <Spacer larger />
                </>
            )}

            <Spacer small />
        </Column>
    );
};

export default RSVPHeader;
