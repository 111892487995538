import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { isLoading, meetingsActions, selectMeeting, useMeetings } from './store';

export default function LoadMeeting({ id }: { id?: string | number }) {
    const { enqueueSnackbar } = useSnackbar();
    const meeting = useMeetings(selectMeeting(id));
    const isLoadingMeeting = useMeetings(isLoading(id ?? ''));

    React.useEffect(() => {
        const load = async () => {
            if (id) {
                try {
                    await meetingsActions.loadSingle(id);
                } catch (e) {
                    enqueueSnackbar(i18n.error.default, { variant: 'error' });
                }
            }
        };
        if (!meeting && !isLoadingMeeting) {
            load();
        }
    }, [id, enqueueSnackbar, meeting, isLoadingMeeting]);

    return null;
}
