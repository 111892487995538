import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { eventTemplatesActions } from './store';

export default function LoadEventTemplates() {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            eventTemplatesActions.load();
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    }, [enqueueSnackbar]);

    return null;
}
