import { Table as MUITable, TableBody, TableCell, TableHead, TableRow as MUITableRow } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const TableRow = styled(MUITableRow)`
    > :first-child {
        padding-left: 0;
    }
`;

const Table = <
    TRowValue extends undefined | string | number | boolean | null | object,
    TRowValueKey extends string,
    TRowValues extends { [key in TRowValueKey]: TRowValue },
    TDynamicKeys extends string
>({
    columnTitles,
    rowValues,
    dynamicColumns,
    placeholder,
    className,
}: {
    rowValues?: TRowValues[];
    columnTitles: Readonly<
        {
            rowKey: (keyof ArrayType<typeof rowValues> | TDynamicKeys) & string;
            label: string;
            width?: number;
        }[]
    >;
    dynamicColumns?: Partial<{
        [key in ArrayType<typeof columnTitles>['rowKey']]: (
            value: key extends TRowValueKey ? ArrayType<typeof rowValues>[key] : TRowValue,
            rowValue: TRowValues
        ) => React.ReactNode;
    }>;
    placeholder?: string;
    className?: string;
}) => {
    const parseValue = (value?: TRowValue) =>
        (value === undefined || value === null) && placeholder
            ? placeholder
            : typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean'
            ? value
            : placeholder;

    return (
        <MUITable className={className}>
            <TableHead>
                <TableRow>
                    {columnTitles.map(({ rowKey, label, width }, idx) => (
                        <TableCell key={`${rowKey}${idx}`} align="left" style={{ width }}>
                            {label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rowValues &&
                    rowValues.map((rowData, idx) => (
                        <TableRow key={idx}>
                            {columnTitles.map(({ rowKey, width }) => {
                                const dynamicValue =
                                    dynamicColumns &&
                                    (dynamicColumns[rowKey] as (
                                        value: TRowValue,
                                        rowValue: TRowValues
                                    ) => React.ReactNode);
                                const rowValue = rowData[rowKey as TRowValueKey];

                                return (
                                    <TableCell key={rowKey} style={{ width }}>
                                        {dynamicValue
                                            ? dynamicValue(rowValue, rowData) ?? placeholder
                                            : parseValue(rowValue)}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
            </TableBody>
        </MUITable>
    );
};

export default Table;
