import keyBy from 'lodash/keyBy';
import createStore from 'stores';
import { searchMeetingsActions } from 'stores/meetings/searchStore';

export const sortOptions = [
    { id: 'default', value: 'default', name: 'Meeting Date' },
    { id: 'updated_at', value: 'updated_at', name: 'Recent Activity' },
] as const;

export const sortOptionsStatuses = [...sortOptions, { id: 'status', value: 'status', name: 'Status' }] as const;

export const sortValues = keyBy(sortOptionsStatuses.map(option => option.value)) as {
    [key in FilterOptions]: key;
};

export const isFilterOption = (option: string): option is FilterOptions => {
    return sortValues[option as FilterOptions] !== undefined;
};
export type FilterOptions = ArrayType<typeof sortOptionsStatuses>['value'];

type State = {
    sort: FilterOptions;
};

type Store = State;

const initialState: State = {
    sort: 'default',
};

export const useFilters = createStore<Store>(() => initialState);

const { setState, getState } = useFilters;

const LOCAL_STORAGE_KEY = 'meetingFilters';

const isState = (parsed: object): parsed is State => {
    const stateKeys = new Set(Object.keys(initialState));

    return Object.keys(parsed).some(key => stateKeys.has(key));
};

export const filtersActions = {
    load: () => {
        const loadedFilters = localStorage.getItem(LOCAL_STORAGE_KEY);
        const parsedFilters = loadedFilters && JSON.parse(loadedFilters);
        if (parsedFilters && isState(parsedFilters)) {
            setState(parsedFilters);
        }
    },

    setSort: (sortBy: FilterOptions) => {
        setState({ sort: sortBy });
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(getState()));
        searchMeetingsActions.setSort(sortBy);
    },
};
