import { Box, styled } from '@mui/material';
import { getProposalForVenue } from 'api/bizlyOS';
import InquiryDetails from 'components/BizlyOS/Proposals/InquiryDetails';
import ProposalDetails from 'components/BizlyOS/Proposals/ProposalDetails';
import ProposalInquryHeader from 'components/BizlyOS/Proposals/ProposalInquryHeader';
import { Spinner } from 'components/Spinner';
import sortBy from 'lodash/sortBy';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const Container = styled(Box)(({}) => ({
    height: '100%',
    boxSizing: 'border-box',
    overflowX: 'auto',
}));

const HEADER_HEIGHT = '75px';

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    boxSizing: 'border-box',
    height: HEADER_HEIGHT,
    minWidth: '840px',
}));

const BodyContainer = styled(Box)(({ theme }) => ({
    height: `calc(100% - ${HEADER_HEIGHT})`,
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    gap: theme.spacing(2.5),
    padding: theme.spacing(2.5),
    maxWidth: '1520px',
}));

export function ProposalInqury(): React.JSX.Element {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };

    const { data: proposalInquryForm, isLoading } = useQuery({
        queryKey: ['proposal_inqury', venueId, proposalId],
        queryFn: () => getProposalForVenue(venueId, proposalId),
        onSettled(data) {
            if (!data) return {};
            data.options.currencies = data.options.currencies && sortBy(data.options.currencies, 'code');
            return data;
        },
    });

    if (isLoading || !proposalInquryForm) {
        return (
            <Box p={10} display="flex" alignItems="center" justifyContent="center">
                <Spinner />
            </Box>
        );
    }

    return (
        <Container height="100%">
            <HeaderContainer>
                <ProposalInquryHeader proposalInquryForm={proposalInquryForm} />
            </HeaderContainer>
            <BodyContainer>
                <InquiryDetails proposalInquryForm={proposalInquryForm} />
                <ProposalDetails />
            </BodyContainer>
        </Container>
    );
}
