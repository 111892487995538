import SideNav from 'components/SideNav';
import { Tab, Tabs } from 'components/Tabs';
import { PageHeadline } from 'components/ui/Headline';
import { useUser } from 'providers/user';
import React from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { InlineRow, Spacer } from 'ui';
import MembersTable from './MembersTable';
import TeamSettings from './TeamSettings';

const LimitedMembersTable = styled(MembersTable)`
    max-width: 800px;
`;

export default function TeamSettingsPage() {
    const [tab, setTab] = React.useState('profile');
    const { user, isTeamAdmin } = useUser();

    if (!isTeamAdmin) {
        return <Navigate to={`/`} />;
    }

    return (
        <SideNav>
            <PageHeadline>{i18n.teamSettings.header}</PageHeadline>
            <Spacer />
            <InlineRow>
                <Tabs value={tab}>
                    <Tab value="profile" label={i18n.teamSettings.profile} onClick={() => setTab('profile')} />
                    <Tab value="members" label={i18n.teamSettings.members} onClick={() => setTab('members')} />
                </Tabs>
            </InlineRow>

            {tab === 'profile' && (
                <>
                    <Spacer larger />
                    <TeamSettings />
                </>
            )}
            {tab === 'members' && <LimitedMembersTable disableInvites={!user.team?.features.canInviteTeamMembers} />}
        </SideNav>
    );
}
