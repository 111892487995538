import * as Sentry from '@sentry/react';
import { getEvents } from 'api';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { GetEventsFilters } from 'stores/events/eventsStore';

export type TEventsResponse = {
    success: boolean;
    events: Bizly.Event[];
    collaborators?: Bizly.EventCollaborator[];
};

export const useEventsQuery = (query: Partial<GetEventsFilters>) => {
    const queryString = useMemo(() => {
        const queryObject = {} as Record<string, string>;
        if (query.include) {
            queryObject['include'] = query.include.join(',');
        }
        return new URLSearchParams(queryObject).toString();
    }, [query]);

    return useQuery<TEventsResponse>({
        queryKey: ['events', JSON.stringify(query)],
        queryFn: () => getEvents(queryString),
        onError: (error: unknown) => {
            console.error('Error fetching events:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useEventsQuery',
                },
            });
        },
    });
};
