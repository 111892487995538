import { EColors, ETeams } from 'types/themenum';
import createStore from '../';

export type ColorsMapping = Record<EColors, string>;

type Store = {
    theme: ETeams;
    colorOverrides: Partial<ColorsMapping>;
};

const initialState: Store = {
    theme: ETeams.bizly,
    colorOverrides: {},
};

export const useTheme = createStore<Store>(() => initialState);

const { setState, getState } = useTheme;

export const themeActions = {
    setTheme: (newTheme: ETeams = ETeams.bizly) => setState({ ...getState(), theme: newTheme }),
    setColorOverrides: (overrides: Partial<ColorsMapping>) => setState({ ...getState(), colorOverrides: overrides }),
    unsetColorOverrides: (colorLabels: EColors[]) => {
        const newOverrides = { ...getState().colorOverrides };
        colorLabels.forEach(color => delete newOverrides[color]);
        setState({
            ...getState(),
            colorOverrides: newOverrides,
        });
    },
};

export const getThemeState = getState;
