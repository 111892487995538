import {
    getAttendeeBaseError,
    getAttendeeCustomQuestionsError,
    getAttendeeDefaultsError,
    getAttendeeRoomBlockErrorMessge,
} from 'components/RSVP/utils';
import ResponsiveMetaTag from 'components/ResponsiveMetaTag';
import Button from 'components/ui/Button';
import { withSnackbar } from 'notistack';
import withRouter from 'providers/withRouter';
import qs from 'query-string';
import { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import styled from 'styled-components';
import { mergeDateTime } from 'utils/date_util';
import { getRSVPInfo, updateRSVPInfo } from '../api';
import RSVPCard from '../components/RSVP/RSVPCard';
import RSVPHeader from '../components/RSVP/RSVPHeader';
import { Spinner } from '../components/Spinner';
import WhiteBizmark from '../images/icons/white_bizmark.svg?react';
import { Column, FixedBackground, Button as UIButton } from '../ui';
import { PageNotFound } from './PageNotFound';

const CARD_WIDTH = 680;

const CenteredColumn = styled(Column)`
    padding: 112px 0;
    margin: auto;
`;

const SignInButton = styled(UIButton)`
    background-color: transparent;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const SignInLink = styled(Link)`
    position: absolute;
    top: 36px;
    right: 36px;
`;

const FullScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const SigninAndLogo = ({ showSignIn }) => (
    <FullScreen>
        {showSignIn && (
            <SignInLink to="/login">
                <SignInButton variant="outlined">Sign in to Bizly</SignInButton>
            </SignInLink>
        )}
        <WhiteBizmark style={{ position: 'absolute', bottom: 36, right: 36 }} />
    </FullScreen>
);

const ParcelRSVP = ({
    userAnonymous,
    attending,
    team,
    imageUrl: image,
    name,
    description,
    startsAt,
    endsAt,
    cancelledAt,
    startDate,
    startTime,
    endDate,
    endTime,
    timeZone,
    schedule,
    agenda,
    location,
    address,
    cityState,
    virtualMeeting,
    vmSharingMethod,
    parcelDates,
    attendee,
    formSettings,
    submitted,
    onChange,
    handleSubmit,
    editResponse,

    saveButtonDisabled,
}) => {
    return (
        <Column style={{ position: 'relative', display: 'block' }}>
            <SigninAndLogo showSignIn={userAnonymous && (submitted || !attending)} />
            <CenteredColumn style={{ alignItems: 'center' }}>
                <FixedBackground backgroundColor={team ? team.color : 'transparent'} />
                <RSVPHeader
                    name={name}
                    startsAt={startDate && startTime ? mergeDateTime(startDate, startTime) : startsAt}
                    endsAt={endDate && endTime && mergeDateTime(endDate, endTime)}
                    cancelledAt={cancelledAt}
                    description={description}
                    location={location}
                    virtualMeeting={virtualMeeting}
                    timeZone={timeZone}
                    attending={attending}
                    submitted={submitted}
                    editResponse={editResponse}
                />
                <Column style={{ width: CARD_WIDTH }}>
                    <RSVPCard
                        team={team}
                        image={image}
                        name={name}
                        description={description}
                        startsAt={startsAt}
                        endsAt={endsAt}
                        schedule={schedule}
                        plannerSchedule={agenda}
                        parcelDates={parcelDates}
                        timezone={timeZone}
                        location={location}
                        address={address}
                        cityState={cityState}
                        vmSharingMethod={vmSharingMethod}
                        virtualMeeting={virtualMeeting}
                        plannedBy={{}}
                        isPreview={false}
                        attendee={attendee}
                        formSettings={formSettings}
                        attending={attending}
                        submitted={submitted}
                        onChange={onChange}
                        handleSubmit={handleSubmit}
                        saveButtonDisabled={saveButtonDisabled}
                        cancelledAt={cancelledAt}
                    />
                </Column>
            </CenteredColumn>
        </Column>
    );
};

export const ATTENDING_STATUSES = {
    ATTENDING: 'attending',
    NOT_ATTENDING: 'not attending',
};

class RSVP extends Component {
    state = { viewportWidth: 0 };

    getQuery = () => {
        const { params, location } = this.props;
        const { eventId } = params;
        const { attending, ...restQuery } = qs.parse(location.search, {
            parseBooleans: true,
        });
        const query = '?' + qs.stringify(restQuery);
        const rsvpStatus = {
            true: ATTENDING_STATUSES.ATTENDING,
            false: ATTENDING_STATUSES.NOT_ATTENDING,
        }[attending];

        return { eventId, query, attending, rsvpStatus };
    };

    async componentDidMount() {
        const { eventId, query, rsvpStatus } = this.getQuery();

        let rsvpInfo = {};
        try {
            rsvpInfo = await getRSVPInfo(eventId, query);
            let { event, team, attendee, formSettings } = rsvpInfo;

            if (!event.cancelledAt && rsvpStatus === ATTENDING_STATUSES.NOT_ATTENDING) {
                attendee = { ...attendee, status: rsvpStatus };
                await updateRSVPInfo(eventId, query, attendee);
            }

            // Updates the default team logo to customLogoUrl from Parcel if it exist
            if (event?.logoUrl) {
                team = { ...team, imageUrl: event.logoUrl };
            }

            this.setState({
                event,
                team,
                attendee,
                submitted: attendee.status === ATTENDING_STATUSES.ATTENDING,
                formSettings,
            });
        } catch (e) {
            console.error(`Could not fetch data for meeting ${eventId}`);
            this.setState({ error: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.snackbarKey && prevState.snackbarKey !== this.state.snackbarKey) {
            this.props.closeSnackbar(prevState.snackbarKey);
        }
    }

    handleSubmit = async () => {
        const { eventId, query, rsvpStatus } = this.getQuery();
        const { attendee, formSettings } = this.state;

        const error =
            getAttendeeBaseError(attendee) ||
            getAttendeeDefaultsError(attendee, formSettings) ||
            getAttendeeCustomQuestionsError(attendee) ||
            getAttendeeRoomBlockErrorMessge(attendee);
        if (error) {
            return this.setState({
                snackbarKey: this.props.enqueueSnackbar(error, {
                    variant: 'error',
                    persist: true,
                    action: (
                        <Button warning onClick={() => this.setState({ snackbarKey: null })}>
                            Dismiss
                        </Button>
                    ),
                }),
            });
        }

        this.setState({ saving: true });
        try {
            const updatedAttendee = { ...attendee, status: rsvpStatus || ATTENDING_STATUSES.ATTENDING };
            await updateRSVPInfo(eventId, query, updatedAttendee);
            this.setState(updatedAttendee);
        } catch (e) {
            this.props.enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
        this.setState({ submitted: true, saving: false });
    };

    editResponse = () => this.setState({ submitted: false });

    handleChange = ({ value }) => {
        this.setState({ attendee: value, snackbarKey: null });
    };

    render() {
        const { attending = true } = this.getQuery();
        const { event, error, attendee, formSettings, team, saving, submitted } = this.state;
        const { userAnonymous } = this.props;

        if (error) {
            return <Route component={PageNotFound} />;
        } else if (!attendee) {
            return <Spinner />;
        }

        const { startDate, startTime, endDate, endTime } = event;
        const parcelDates = startDate && startTime && endDate && endTime && { startDate, startTime, endDate, endTime };

        return (
            <>
                <ResponsiveMetaTag />
                <ParcelRSVP
                    userAnonymous={userAnonymous}
                    attending={attending}
                    {...event}
                    parcelDates={parcelDates}
                    team={team}
                    saveButtonDisabled={saving}
                    attendee={attendee}
                    formSettings={formSettings}
                    submitted={submitted}
                    onChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    editResponse={this.editResponse}
                />
            </>
        );
    }
}

export default withRouter(withSnackbar(RSVP));
