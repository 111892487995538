import { parse, stringify } from 'query-string';
import { Location, NavigateFunction } from 'react-router-dom';
import { i18n } from 'translation';

export const ESECTION = {
    PERSONAL: 'personal',
    SHARED: 'shared',
    GLOBAL: 'global',
} as const;
// TODO: this is intentional to mimick enums but with better properties and may be fixed by a higher version of eslint 4.29.0
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ESECTION = Bizly.EventTemplateCategory;

const { PERSONAL, SHARED, GLOBAL } = ESECTION;

export const sectionToTitle = (section: ESECTION, user: Bizly.User) =>
    ({
        [PERSONAL]: i18n.playbooks.personalTitle,
        [SHARED]: user?.team?.name ? i18n.playbooks.sharedTitle(user.team.name) : i18n.playbooks.sharedTitleGeneric,
        [GLOBAL]: i18n.playbooks.globalTitle,
    })[section];

export const sectionToSubheading = (section: ESECTION) =>
    ({
        [PERSONAL]: i18n.playbooks.personalSubheading,
        [SHARED]: i18n.playbooks.sharedSubheading,
        [GLOBAL]: i18n.playbooks.globalSubheading,
    })[section];

export type TTemplateFilterValue = Partial<{
    section: ESECTION;
    tags: number[];
}>;

export const getTemplatesUrlFilters = (location: Location): TTemplateFilterValue => {
    const parsedFilters = parse(location.search, { parseNumbers: true });
    return {
        ...parsedFilters,
        ...(parsedFilters.tags
            ? {
                  tags: ([] as (string | number)[]).concat(parsedFilters.tags).map(id => parseInt('' + id)),
              }
            : {}),
    };
};

export const setTemplatesUrlFilters = (
    navigate: NavigateFunction,
    location: Location,
    merger: (curFilterValue: TTemplateFilterValue) => TTemplateFilterValue
) => {
    const currentFilters = getTemplatesUrlFilters(location);
    navigate(
        { pathname: location.pathname, search: stringify(merger(currentFilters)) },
        {
            replace: true,
            state: location.state,
        }
    );
};
