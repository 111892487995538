import { exportParcelResponses, loadQuestions } from 'api';
import SurveyeeModal from 'components/Attendees/SurveyeeModal';
import { Questions } from 'components/Questions';
import { Spinner } from 'components/Spinner';
import { Tab, Tabs } from 'components/Tabs';
import Button from 'components/ui/Button';
import TextButton from 'components/ui/Button/TextButton';
import { H2Headline as UIH2Headline } from 'components/ui/Headline';
import Table from 'components/ui/Table';
import TallyDisplay from 'components/ui/TallyDisplay';
import keyBy from 'lodash/keyBy';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { statusColors } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, PromptedTextField, SpacedRow, StatusChip } from 'ui';
import { downloadFromUrl } from '../../util';

const Container = styled(Column)`
    width: 100%;
`;

const TABS_CONTAINER_STYLES = {
    width: 176,
};

const TAB_STYLES = {
    padding: 0,
};

const H2Headline = styled(UIH2Headline)`
    line-height: 2em;
`;

const SubHeadline = styled(Copy)`
    line-height: 1.375em;
`;

const QUESTIONS = 'questions';
const RESPONSES = 'responses';

const RESPONSE_COL = 'responded';
const NAME_COL = 'name';
const EMAIL_COL = 'email';
const VIEW_COL = 'view';

const SurveySection = ({
    eventId,
    parcel,
    attendees,
    hasQuestions,
    setHasQuestions,
    handleChange,
    editable,
}: {
    eventId: number | string;
    parcel: Bizly.Parcel;
    attendees: BizlyAPI.Attendee[];
    hasQuestions: boolean;
    setHasQuestions: (hasQuestions: boolean) => void;
    handleChange: (value: any, key: string) => void;
    editable: boolean;
}) => {
    const [activeTab, setActiveTab] = useState<string>('');
    const [currentSurveyee, setCurrentSurveyee] = useState<
        (Bizly.SurveyRespondent & Pick<BizlyAPI.Attendee, 'customQuestions'>) | null
    >(null);
    const [parcelQuestions, setParcelQuestions] = useState<Set<number> | null>(null);

    const attendeesByEmail = keyBy(attendees, attendee => attendee.email);

    const setSurveyee = (respondent: Bizly.SurveyRespondent) => {
        const { customQuestions } = attendeesByEmail[respondent.email] || {};
        setCurrentSurveyee({
            ...respondent,
            customQuestions: customQuestions.filter(question =>
                parcelQuestions ? parcelQuestions.has(question.id) : true
            ),
        });
    };

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        (async () => {
            if (!parcel.id) return;
            try {
                setActiveTab('');
                // type when serializing questions is typed
                const loadedQuestions = (await loadQuestions(parcel.id)) as any[];

                const hasLoadedQuestions = loadedQuestions.length > 0;
                setHasQuestions(hasLoadedQuestions);
                setParcelQuestions(new Set(loadedQuestions.map(lq => lq.id)));
                setActiveTab(parcel.sent && hasLoadedQuestions ? RESPONSES : QUESTIONS);
            } catch (e) {
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            }
        })();
    }, [parcel.id, setHasQuestions, setParcelQuestions, parcel.sent, setActiveTab, enqueueSnackbar]);

    const downloadExport = async () => {
        const { url } = await exportParcelResponses(eventId, parcel.id);
        downloadFromUrl(url);
    };

    const responsesEnabled = parcel.sent && hasQuestions;

    return (
        <Container itemSpacing="large">
            {activeTab ? (
                <Tabs value={activeTab} style={TABS_CONTAINER_STYLES}>
                    <Tab
                        label={i18n.questions.questions}
                        value={QUESTIONS}
                        onClick={() => setActiveTab(QUESTIONS)}
                        style={TAB_STYLES}
                    />
                    <Tab
                        label={i18n.modal.responses}
                        value={RESPONSES}
                        onClick={() => setActiveTab(RESPONSES)}
                        style={TAB_STYLES}
                        disabled={!responsesEnabled}
                    />
                </Tabs>
            ) : (
                <Spinner />
            )}

            {activeTab === QUESTIONS && (
                <>
                    <Column itemSpacing="default">
                        <H2Headline>{i18n.questions.addSurveyQuestions}</H2Headline>
                        <SubHeadline>{i18n.questions.addSurveyQuestionsDescription}</SubHeadline>
                    </Column>
                    {parcel.sent && parcel.surveyTitle && parcel.surveyTitle !== parcel.subject && (
                        <PromptedTextField
                            onChange={e => handleChange(e.target.value, 'surveyTitle')}
                            disabled
                            prompt={i18n.questions.surveyTitle}
                            value={parcel.surveyTitle}
                            variant="outlined"
                            optional
                        />
                    )}
                    {parcel.id && (
                        <Questions
                            disabled={parcel.sent || !editable}
                            parcelId={parcel.id}
                            setHasQuestions={setHasQuestions}
                            isNote
                            hasLinearScale
                        />
                    )}
                </>
            )}

            {activeTab === RESPONSES && (
                <>
                    <Column itemSpacing="default">
                        <H2Headline>
                            <SpacedRow>
                                {i18n.modal.responses}
                                <Button secondary onClick={downloadExport}>
                                    {i18n.button.export}
                                </Button>
                            </SpacedRow>
                        </H2Headline>
                        <SubHeadline>{i18n.questions.responseDescription}</SubHeadline>
                    </Column>
                    <TallyDisplay
                        tallies={[
                            { label: 'sent', tally: parcel.surveyStats?.total },
                            { label: 'pending', tally: parcel.surveyStats?.pending },
                            { label: 'responded', tally: parcel.surveyStats?.responded },
                        ]}
                        itemSize={3}
                    />
                    <Table
                        columnTitles={[
                            { rowKey: RESPONSE_COL, label: i18n.questions.status },
                            { rowKey: NAME_COL, label: i18n.questions.name },
                            { rowKey: EMAIL_COL, label: i18n.questions.email },
                            { rowKey: VIEW_COL, label: '' },
                        ]}
                        rowValues={parcel.surveyRespondents}
                        dynamicColumns={{
                            [RESPONSE_COL]: value => (
                                <StatusChip
                                    style={{ width: '118px' }}
                                    label={value ? i18n.modal.responded : i18n.modal.pending}
                                    statusColor={statusColors[value ? 'responded' : 'pending']}
                                />
                            ),
                            [VIEW_COL]: (_, respondent) => (
                                <TextButton
                                    onClick={() => {
                                        respondent && setSurveyee(respondent);
                                    }}
                                >
                                    {i18n.button.view}
                                </TextButton>
                            ),
                        }}
                        placeholder="-"
                    />
                </>
            )}
            {currentSurveyee && <SurveyeeModal surveyee={currentSurveyee} onDismiss={() => setCurrentSurveyee(null)} />}
        </Container>
    );
};

export default SurveySection;
