import Divider from '@material-ui/core/Divider';
import { styled as muiStyled } from '@mui/material/styles';
import { roomTypeOptions } from 'components/Communication/editParcelSchema';
import Form from 'components/Form';
import { CustomQuestionsForm, DietaryQuestionsField } from 'components/RSVP/RSVPForm';
import { Button } from 'components/Ui-V2/Button/Button';
import { useMemo } from 'react';
import { attendanceTypeOptions, statusOptions } from 'shared';
import { i18n } from 'translation';

const StyledButton = muiStyled(Button)(({ theme: { getColor, EColors } }) => ({
    '&.MuiButton-root': {
        lineHeight: '1.188rem',
        boxShadow: 'none',
        backgroundColor: getColor(EColors.pureWhite),
        color: getColor(EColors.primaryAction),
        margin: '0.75rem -12.5rem 0.25rem 0',
        '&:hover': {
            backgroundColor: getColor(EColors.pureWhite),
            boxShadow: 'none',
        },
    },
}));

const ButtonField = ({ label, onClick: onClearAccommodations }: { label: string; onClick: () => void }) => {
    return <StyledButton onClick={onClearAccommodations}>{label}</StyledButton>;
};

export type AttendeeFormType = BizlyAPI.Attendee &
    Partial<{
        dietaryRestrictions: unknown;
        foodAllergies: unknown;
        checkInDate: Nullable<string | Date>;
        checkOutDate: Nullable<string | Date>;
        hotelConfirmationNumber: string;
    }>;

type AttendeeFormProps<TReadonly extends boolean | undefined = false> = {
    attendee: AttendeeFormType;
    eventOptions: { dietaryRestrictionOptions: unknown; foodAllergyOptions: unknown };
    readonly?: TReadonly;
} & (true extends TReadonly
    ? { readonly: true }
    : {
          readonly?: false;
          externalIdFieldLabel?: string;
          onChange: (value: Bizly.EventAttendee) => void;
      });
function AttendeeForm(props: AttendeeFormProps<false> | AttendeeFormProps<true> | AttendeeFormProps<undefined>) {
    const {
        attendee,
        eventOptions,
        readonly,
        externalIdFieldLabel = '',
        onChange: onChangeProp,
    } = props as AttendeeFormProps;

    const { dietaryRestrictionOptions, foodAllergyOptions } = eventOptions;
    const formValue = useMemo(
        () => ({
            ...attendee,
            dietary: {
                dietaryRestrictions: attendee.dietaryRestrictions,
                foodAllergies: attendee.foodAllergies,
            },
            accommodation: attendee.checkInDate
                ? i18n.guests.overnightAccommodationRequired
                : i18n.guests.noOvernightAccommodationRequired,
            hotelConfirmationNumber: attendee.hotelConfirmationNumber ?? (readonly ? i18n.common.emptyValue : ''),
        }),
        [attendee, readonly]
    );

    const fields = useMemo(
        () => ({
            firstName: {
                prompt: i18n.authFields.firstName,
                type: 'text',
                perRow: 2,
                disabled: !!attendee.activeUser,
            },
            lastName: {
                prompt: i18n.authFields.lastName,
                type: 'text',
                perRow: 2,
                disabled: !!attendee.activeUser,
            },
            phone: {
                prompt: i18n.authFields.phoneNumber,
                type: 'phone',
                perRow: 2,
                disabled: !!attendee.activeUser,
            },
            externalId: {
                prompt: externalIdFieldLabel,
                type: 'text',
                perRow: 2,
                optional: true,
            },
            hotelConfirmationNumber: {
                prompt: i18n.common.hotelConfirmationNumber,
                type: 'text',
                perRow: 2,
                optional: true,
            },
            checkInDate: {
                prompt: i18n.datetime.checkInDate,
                type: 'date',
                perRow: 2,
                optional: true,
            },
            checkOutDate: {
                prompt: i18n.datetime.checkOutDate,
                type: 'date',
                perRow: 2,
                optional: true,
            },
            preferredRoomType: {
                prompt: i18n.meetingDashboard.roomBlockSection.preferredRoomType,
                placeholder: i18n.meetingDashboard.roomBlockSection.roomTypePlaceholder,
                type: 'select',
                options: {
                    options: roomTypeOptions,
                },
                optional: !formValue.checkInDate || !formValue.checkOutDate,
            },
            status: {
                prompt: i18n.questions.status,
                type: 'select',
                perRow: 2,
                options: {
                    options: statusOptions,
                },
            },
            dietary: {
                type: DietaryQuestionsField,
                readonly,
                options: {
                    prompt: i18n.questions.dietaryNeeds,
                    dietaryRestrictionOptions,
                    foodAllergyOptions,
                },
                optional: true,
            },
            rsvpComments: {
                prompt: i18n.venue.inquiry.notes,
                type: 'textarea',
                readonly,
                options: {
                    placeholder: readonly ? `${i18n.common.noNotes}.` : i18n.guests.addNoteAboutAttendee,
                },
                optional: true,
            },
            attendanceType: {
                prompt: i18n.questions.attendanceType,
                type: 'radioselect',
                perRow: 2,
                options: {
                    options: attendanceTypeOptions,
                },
            },
            customQuestions: {
                type: CustomQuestionsForm,
                options: {
                    questions: formValue.customQuestions,
                },
            },
            accommodationLabel: {
                type: 'display',
                prompt: '',
                perRow: 2,
                options: {
                    label: i18n.venue.planner.accommodations,
                },
                optional: true,
            },
            clearAccommodation: {
                type: ButtonField,
                perRow: 2,
                options: {
                    label: i18n.button.clear,
                    onClick: () => onClearAccommodations(formValue),
                },
            },
            accommodation: {
                prompt: i18n.common.accommodation,
                type: 'text',
                perRow: 2,
            },
            divider: {
                type: Divider,
                prompt: ' ',
                optional: true,
            },
        }),
        [formValue, attendee.activeUser, dietaryRestrictionOptions, externalIdFieldLabel, foodAllergyOptions, readonly]
    );

    const schema = [
        {
            key: 'name',
            fields: ['firstName', 'lastName'],
            spacing: 'small',
        },
        {
            key: 'phoneExternalId',
            fields: ['phone', 'externalId'],
            spacing: 'small',
        },
        {
            key: 'status',
            fields: ['status', 'hotelConfirmationNumber'],
            spacing: 'small',
        },
        {
            key: 'divider1',
            fields: ['divider'],
            spacing: false,
        },
        {
            key: 'accommodationLabel',
            fields: ['accommodationLabel', 'clearAccommodation'],
            spacing: 'small',
        },
        {
            key: 'dates',
            fields: ['checkInDate', 'checkOutDate'],
            spacing: 'smallish',
        },
        {
            key: 'roomType',
            fields: ['preferredRoomType'],
            spacing: 'default',
        },
        {
            key: 'rsvpComments',
            fields: ['rsvpComments'],
            spacing: 'default',
        },
        {
            key: 'divider2',
            fields: ['divider'],
        },
        {
            key: 'dietary',
            fields: ['dietary'],
        },
        {
            key: 'attendanceType',
            fields: ['attendanceType'],
        },
        {
            key: 'customQuestions',
            header: 'Custom RSVP Questions',
            fields: ['customQuestions'],
        },
    ];

    const readOnlySchema = [
        {
            key: 'name',
            fields: ['firstName', 'lastName'],
            spacing: 'small',
        },
        {
            key: 'accommodation',
            fields: ['accommodation', 'hotelConfirmationNumber'],
        },
        ...(formValue.checkInDate
            ? [
                  {
                      key: 'checkDates',
                      fields: ['checkInDate', 'checkOutDate'],
                  },
                  {
                      key: 'roomType',
                      fields: ['preferredRoomType'],
                  },
                  {
                      key: 'rsvpComments',
                      fields: ['rsvpComments'],
                  },
              ]
            : []),
        {
            key: 'dietary',
            fields: ['dietary'],
        },
        {
            key: 'attendanceType',
            fields: ['attendanceType'],
        },
        {
            key: 'customQuestions',
            header: 'Custom RSVP Questions',
            fields: ['customQuestions'],
        },
    ];

    const onChange = ({ value: { dietary, ...newValue } }: { value: AttendeeFormType & { dietary: any } }) => {
        onChangeProp({ ...newValue, ...dietary });
    };

    const onClearAccommodations = async (formValue: any) => {
        onChangeProp({ ...formValue, checkInDate: null, checkOutDate: null, preferredRoomType: null });
    };

    return (
        <Form
            fields={fields}
            schema={readonly ? readOnlySchema : schema}
            value={formValue}
            readonly={readonly}
            onChange={onChange}
        />
    );
}

export default AttendeeForm;
