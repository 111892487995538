import React from 'react';

const AppConfigContext = React.createContext<Bizly.AppConfig>({
    transparentHeader: false,
    setTransparentHeader: (collapsed: boolean) => {},
});

type TAppConfigProvider = {
    children?: React.ReactNode;
};

export const AppConfigProvider = ({ children }: TAppConfigProvider) => {
    const [transparentHeader, setTransparentHeader] = React.useState(false);

    return (
        <AppConfigContext.Provider
            value={{
                transparentHeader,
                setTransparentHeader,
            }}
        >
            {children}
        </AppConfigContext.Provider>
    );
};

export function useAppConfigContext() {
    const context = React.useContext(AppConfigContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}
