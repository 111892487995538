import { useMediaQuery } from '@material-ui/core';
import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'components/BizlyOS/BizlyOSSideNav';
import { TStatusAttributes } from 'components/ProposalForm/statusUtils';
import Button from 'components/ui/Button';
import { useOSLayout } from 'providers/bizly-os';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Row, SpacedRow } from 'ui';
import { SCROLLBAR_WIDTH, WIDE_PAGE_WIDTH, contentPadding, sideWidth } from './utils';

const WidthAutoRow = styled(Row)`
    width: auto;
`;

const ButtonsRow = styled(WidthAutoRow)<{ left: number }>`
    position: fixed;
    left: ${({ left }) => left - SCROLLBAR_WIDTH}px;
    right: 0;
    bottom: 0;

    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-top: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

    z-index: 20;
`;

const ButtonsRowContent = styled(SpacedRow)`
    box-sizing: border-box;
    padding: 24px ${contentPadding}px;
    max-width: 1280px;
    margin: auto;
`;

const NoWrapButton = styled(Button)`
    white-space: nowrap;
`;

const FooterButtons = ({
    disabled,

    onBack,
    onSave,
    onContinue,
    onRestart,
    onCancel,
    onSubmit,
    onReject,

    isSubmitStep,
    isStatusStep,
    statusAttributes,
}: {
    disabled: boolean;

    onBack?: () => void;
    onSave: () => void;
    onContinue: () => void;
    onRestart: () => void;
    onSubmit: () => void;
    onCancel: () => void;
    onReject: () => void;

    isSubmitStep: boolean;
    isStatusStep: boolean;
    statusAttributes: TStatusAttributes;
}) => {
    const { canView, canCreate, canSubmit, isResubmit, canTurnDown, canWithdraw } = statusAttributes;
    const { navCollapsed } = useOSLayout();

    const renderInProgress = () => (
        <>
            {(canSubmit || canCreate) && (
                <NoWrapButton onClick={onSave} secondary disabled={disabled}>
                    {i18n.button.save}
                </NoWrapButton>
            )}
            {isSubmitStep && (canSubmit || canCreate) ? (
                <NoWrapButton onClick={onSubmit} disabled={disabled}>
                    {isResubmit ? i18n.button.resubmit : i18n.button.submit}
                </NoWrapButton>
            ) : (
                <NoWrapButton onClick={onContinue} disabled={disabled}>
                    {i18n.button.continue}
                </NoWrapButton>
            )}
        </>
    );

    const renderStatus = () => (
        <>
            {canWithdraw && (
                <NoWrapButton onClick={onCancel} disabled={disabled} secondary width={150} warning>
                    {i18n.proposalForm.button.withdrawProposal}
                </NoWrapButton>
            )}

            {(canView || canCreate || canSubmit) && (
                <NoWrapButton onClick={onRestart} disabled={disabled} width={140}>
                    {canSubmit || canCreate
                        ? canCreate
                            ? i18n.proposalForm.button.createProposal
                            : i18n.proposalForm.button.editProposal
                        : i18n.proposalForm.button.viewProposal}
                </NoWrapButton>
            )}
        </>
    );

    const isWidthConstrained = useMediaQuery(`(max-width: ${WIDE_PAGE_WIDTH}px)`);
    const leftPadding =
        (navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH) + (isWidthConstrained ? 0 : sideWidth);

    return (
        <ButtonsRow left={leftPadding}>
            <ButtonsRowContent>
                <span>
                    {!isStatusStep &&
                        (onBack ? (
                            <Button key="back" onClick={onBack} secondary disabled={disabled}>
                                {i18n.button.back}
                            </Button>
                        ) : (
                            canTurnDown && (
                                <Button key="reject" onClick={onReject} warning disabled={disabled} width="auto">
                                    {i18n.proposalForm.button.turnDownInquiry}
                                </Button>
                            )
                        ))}
                </span>

                <WidthAutoRow itemSpacing="default">{isStatusStep ? renderStatus() : renderInProgress()}</WidthAutoRow>
            </ButtonsRowContent>
        </ButtonsRow>
    );
};

export default FooterButtons;
