import Box from '@mui/material/Box';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { TGoogleAuth } from 'pages/Authentication/types';

const GOOGLE_CLIENT_ID =
    (import.meta.env.VITE_APP_ENV === 'prod'
        ? import.meta.env.VITE_APP_GOOGLE_OAUTH_CLIENT_ID_PRODUCTION
        : import.meta.env.VITE_APP_GOOGLE_OAUTH_CLIENT_ID) || '';

type TGoogleAuthenticationProps = {
    onSuccess: (resp: TGoogleAuth) => void;
};

export const GoogleAuthentication = ({ onSuccess }: TGoogleAuthenticationProps) => {
    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '1rem 0',
                }}
            >
                <GoogleLogin
                    onSuccess={credentialResponse => onSuccess(credentialResponse)}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                    useOneTap
                />
            </Box>
        </GoogleOAuthProvider>
    );
};
