import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { Box, styled, Typography } from '@mui/material';
import { EColors, getColor } from 'theme';

const Container = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    gap: spacing(1.25),
    height: 'auto',
}));

type InsightsContainerProps = {
    title: string;
    descriptions: { text: string; fontWeight?: '400' | '500' | '600' }[];
};

export const InsightsContainer = ({ descriptions, title }: InsightsContainerProps) => {
    return (
        <Container>
            <Box display="flex" gap={1.25}>
                <TipsAndUpdatesOutlinedIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                <Typography variant="body1" fontWeight={700}>
                    {title}
                </Typography>
            </Box>
            {descriptions.map((description, index) => (
                <Typography variant="body2" key={index} fontWeight={description?.fontWeight || '400'}>
                    {description.text}
                </Typography>
            ))}
        </Container>
    );
};
