import { RichTextEditor } from 'components/Form/fields';
import { OptionList } from 'components/OptionList';
import { Uploader } from 'components/Uploader';
import { PageHeadline } from 'components/ui/Headline';
import { isEmpty } from 'lodash';
import { i18n } from 'translation';
import { Prompted, PromptedTextField, Spacer, TextField } from 'ui';
import { EditParcelState, TEventParcelContext } from '../EditParcel';
import { Centered, FormLabel } from './styles';

const HotelConfirmationEmail = ({
    context,
    handleChange,
    state,
    setState,
    handleUploadChange,
}: {
    context: TEventParcelContext;
    handleChange: (value: any, field: string) => void;
    state: EditParcelState;
    setState: (state: EditParcelState) => void;
    handleUploadChange: (response: any) => void;
}) => {
    const { attendees, parcel, recipients } = state;

    const eventId = context.id;

    return (
        <Centered>
            <PageHeadline>{i18n.communication.hotel_conf}</PageHeadline>

            {!context.asPlaybookParcel && (
                <>
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.recipients}</FormLabel>
                    <OptionList
                        eventId={eventId}
                        disableAll={parcel.sent || !context.editable}
                        prompt={i18n.communication.recipientsPrompt}
                        onChange={(selection: number[]) => {
                            setState({ ...state, recipients: selection }); // We track recipients seperately from the parcel, to maintain an up to date list.
                            handleChange(selection, 'recipients');
                        }}
                        optionFormatter={(attendee: BizlyAPI.Attendee) =>
                            attendee.firstName && attendee.lastName
                                ? `${attendee.firstName} ${attendee.lastName} - ${attendee.email}`
                                : attendee.email
                        }
                        options={attendees}
                        optionsLabel={i18n.communication.attendees}
                        selected={parcel.recipients || recipients}
                    />
                    <Spacer />
                </>
            )}
            <PromptedTextField
                onChange={() => {}}
                disabled
                placeholder={i18n.communication.subjectPlaceholder}
                prompt={i18n.communication.subject}
                value={parcel.subject || ''}
                variant="outlined"
            />
            <Spacer />

            <Prompted prompt={i18n.communication.message}>
                <RichTextEditor
                    field="content"
                    disabled
                    placeholder={i18n.communication.messagePlaceholder}
                    value={parcel.content || ''}
                    onChange={() => {}}
                />
            </Prompted>

            {(!parcel.sent || parcel.customImage) && (
                <>
                    <Spacer />
                    <FormLabel>{i18n.communication.image}</FormLabel>
                    <Spacer smallish />
                    <Uploader
                        disabled={parcel.sent || !context.editable}
                        ctaLabel={i18n.communication.addImage}
                        description={i18n.communication.imageUploadDescription}
                        onChange={(newValue: any) => {
                            handleChange(newValue?.url, 'customImageUrl');
                            handleChange(newValue, 'customImage');
                        }}
                        prompt={i18n.communication.hotelBannerUploadPrompt}
                        resource={isEmpty(parcel.customImage) ? null : parcel.customImage}
                    />
                    <Spacer />
                    <FormLabel>{i18n.communication.addLogo}</FormLabel>
                    <Spacer smallish />
                    <Uploader
                        disabled={parcel.sent || !context.editable}
                        ctaLabel={i18n.communication.addLogo}
                        description={i18n.communication.logoUploadDescription}
                        onChange={(response: any) => handleUploadChange(response)}
                        prompt={i18n.communication.hotelLogoUploadPrompt}
                        resource={isEmpty(parcel.customLogo) ? null : parcel.customLogo}
                    />
                </>
            )}

            {!context.asPlaybookParcel && (
                <>
                    <Spacer />
                    <PromptedTextField
                        onChange={e => handleChange(e.target.value, 'locationName')}
                        disabled={parcel.sent || !context.editable}
                        placeholder={i18n.communication.venueName}
                        optional
                        prompt={i18n.communication.venue}
                        value={parcel.locationName || ''}
                        variant="outlined"
                    />
                    <TextField
                        onChange={e => handleChange(e.target.value, 'locationAddress')}
                        disabled={parcel.sent || !context.editable}
                        placeholder={i18n.communication.streetAddress}
                        value={parcel.locationAddress || ''}
                        variant="outlined"
                        fullWidth={true}
                    />
                    <TextField
                        onChange={e => handleChange(e.target.value, 'locationCityState')}
                        disabled={parcel.sent || !context.editable}
                        placeholder={i18n.communication.cityState}
                        value={parcel.locationCityState || ''}
                        variant="outlined"
                        fullWidth={true}
                    />
                </>
            )}
        </Centered>
    );
};

export default HotelConfirmationEmail;
