import { Component } from 'react';
import styled from 'styled-components';

import { Column, Copy, CopyFaded, ExternalLink, Row, Spacer, TopRounded } from '../ui';
import { CloudinaryUploader } from './CloudinaryUploader';
import SmallCircleButton from './ui/SmallCircleButton';

import colorFns from 'colorFns';
import { withIconStyles, withInteractibleIconStyles } from 'shared';
import FileIcon from '../images/icons/file.svg?react';
import PlusIcon from '../images/icons/plus.svg?react';
import DeleteIcon from '../images/icons/trash-can.svg?react';

import { getExtension, getFileName } from 'cloudinary';

const RoundedRow = styled(TopRounded).attrs(props => ({
    ...(props.nested ? { background: colorFns.listItemNestedBackground(props) } : {}),
}))`
    padding: 12px;
    border-radius: 8px;
    border: 1px solid transparent;
`;

const ConstrainedImage = styled.img`
    max-width: ${props => props.width};
    max-height: ${props => props.height};
    object-fit: contain;
`;

const Width100Column = styled(Column)`
    width: 100%;
`;
const CenteredRow = styled(Row)`
    align-items: center;
`;
const BlueFileIcon = styled(withIconStyles(FileIcon)).attrs(() => ({
    viewBox: '-6 0 29 29',
}))`
    height: 28px;
`;
const BlueDeleteIcon = styled(withInteractibleIconStyles(DeleteIcon))`
    height: 28px;
`;

const PaddedCopy = styled(Copy)`
    margin-left: 8px;
`;
const PaddedLink = styled(ExternalLink)`
    margin-left: 8px;
`;
const ConstrainedRow = styled(CenteredRow)`
    max-height: 28px;
`;

export class Uploader extends Component {
    state = {
        resource: this.props.resource,
    };

    static getDerivedStateFromProps(newProps, currentState) {
        return newProps.resource !== currentState.resource ? { resource: newProps.resource } : null;
    }

    handleUploadStart() {
        this.setState({
            error: null,
        });
    }

    handleUploadSuccess(cloudinaryResponse) {
        const { onChange } = this.props;
        this.setState({
            resource: cloudinaryResponse,
        });
        onChange && onChange(cloudinaryResponse);
    }

    handleUploadError(error) {
        this.setState({
            error,
        });
    }

    handleCustomImageDeleteClick() {
        const { onChange } = this.props;
        this.setState({
            resource: null,
        });
        onChange && onChange(null);
    }

    render() {
        const { ctaLabel, description, prompt, disabled, readonly, nested, formButton } = this.props;
        const { resource } = this.state;

        const Content = (
            <>
                {resource ? (
                    <>
                        <CenteredRow>
                            {resource.resourceType === 'image' ? (
                                <ConstrainedImage
                                    alt="Custom Image Preview"
                                    src={resource.url}
                                    width="60px"
                                    height="40px"
                                />
                            ) : (
                                <BlueFileIcon />
                            )}
                            <PaddedLink href={resource.url} openInNewTab>
                                {resource.title
                                    ? `${resource.title}.${resource.format || getExtension(resource.url)}`
                                    : getFileName(resource.url)}
                            </PaddedLink>
                        </CenteredRow>
                        {!disabled && !readonly && (
                            <BlueDeleteIcon onClick={() => this.handleCustomImageDeleteClick()} />
                        )}
                    </>
                ) : (
                    !disabled &&
                    !readonly &&
                    (ctaLabel || formButton ? (
                        <>
                            <CopyFaded>{prompt}</CopyFaded>
                            <CloudinaryUploader
                                ctaLabel={ctaLabel}
                                onUploadStart={() => this.handleUploadStart()}
                                onUploadSuccess={document => this.handleUploadSuccess(document)}
                                onUploadError={error => this.handleUploadError(error)}
                                formButton={formButton}
                            />
                        </>
                    ) : (
                        <ConstrainedRow>
                            <CloudinaryUploader
                                onUploadStart={() => this.handleUploadStart()}
                                onUploadSuccess={document => this.handleUploadSuccess(document)}
                                onUploadError={error => this.handleUploadError(error)}
                            >
                                <SmallCircleButton
                                    onClick={
                                        e => e.preventDefault()
                                        // There's a <form> inside CloudinaryUploader
                                    }
                                >
                                    <PlusIcon />
                                </SmallCircleButton>
                            </CloudinaryUploader>
                            <PaddedCopy>{prompt}</PaddedCopy>
                        </ConstrainedRow>
                    ))
                )}
            </>
        );

        return (
            <Width100Column>
                {!readonly ? <RoundedRow nested={nested}>{Content}</RoundedRow> : Content}
                {description && (
                    <>
                        <Spacer />
                        <CopyFaded>{description}</CopyFaded>
                    </>
                )}
            </Width100Column>
        );
    }
}
