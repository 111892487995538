import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Button } from 'components/Ui-V2/Button/Button';
import Icon from 'images/bizlyOS/coming-soon/newInquiry.svg?react';
import { EColors } from 'theme';

const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(4),
    height: '100%',
}));

export const ProposalContent = () => {
    return (
        <ContentBox>
            <Icon />
            <Typography variant="h5" component="h1" gutterBottom fontWeight="700">
                New Inquiry
            </Typography>
            <Typography variant="body1" paragraph sx={{ maxWidth: '30rem' }}>
                Review and accept the inquiry details on the left to build your proposal. The client is considering 3
                other venues. Submitting your proposal first boosts your chances of winning by 80%.
            </Typography>
            <Box display="flex" gap={1.25}>
                <Button variant="outlined" sx={{ width: 140, height: 40 }} color="error">
                    Decline
                </Button>
                <Button variant="contained" sx={{ width: 140, height: 40 }} colorKey={EColors.bizlyOSPrimary}>
                    Build Proposal
                </Button>
            </Box>
        </ContentBox>
    );
};
