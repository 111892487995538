import React, { useCallback } from 'react';
import { usePlaybooks } from 'stores/playbooks';
import { kmToMi, miToKm } from 'utils';
import { getFilterTypeIds } from 'utils/venue';
import { createPlaybookActions, useCreatePlaybook } from './store';
import VenueFiltersForm from './VenueFiltersForm';

export default function VenueFilters() {
    const { venueFilters } = useCreatePlaybook();
    const { playbookOptions: { venueBrands = [], venueTypes = [] } = {} } = usePlaybooks();

    const onFormValueChange = useCallback(
        ({ field, value }) => {
            const newVal = { ...value };

            // deselect already selected brands if they are greyd out
            if (field === 'types' && newVal.types.length > 0) {
                const ids = getFilterTypeIds(newVal.types, venueTypes);
                const newBrandIds = newVal.brandIds?.filter((id: number) => {
                    const brandForId = venueBrands.find(brand => brand.id === id);
                    return brandForId && ids.indexOf(brandForId.venueTypeId) >= 0;
                });
                newVal.brandIds = newBrandIds ?? [];
            }

            if (field === 'radius') {
                newVal.radiusKm = miToKm(newVal.radius);
            }

            if (field === 'radiusKm') {
                newVal.radius = kmToMi(newVal.radiusKm);
            }

            createPlaybookActions.updateVenueFilters({ value: newVal });
        },
        [venueBrands, venueTypes]
    );

    return <VenueFiltersForm value={venueFilters} onChange={onFormValueChange} />;
}
