import moment from 'moment-timezone';
// We need to import any Moment locales we need explicitly - just line them up 1:1 with our own language files.
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/es-mx';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/pt';
import ReactGA from 'react-ga';
import german from 'translations/de';
import english from 'translations/en';
import spanish from 'translations/es';
import spanishLatinAmerica from 'translations/es-mx';
import french from 'translations/fr';
import frenchCanada from 'translations/fr-ca';
import italian from 'translations/it';
import dutch from 'translations/nl';
import portuguese from 'translations/pt';
import zeLanguage from 'translations/zz';
import createStore from '../';

const LOCAL_STORAGE_KEY = 'language';

// type IENavigator = Navigator & { userLanguage?: Navigator['language'] };
const locale = ''; // ((window.navigator as IENavigator).userLanguage || window.navigator.language).slice(0, 2).toLowerCase();
const isState = (parsed: object): parsed is Store => {
    const stateKeys = new Set(Object.keys(initialState));

    return Object.keys(parsed).some(key => stateKeys.has(key));
};

export const LANGUAGE_MAPPING: Record<Translations.Language, typeof english> = {
    'fr-ca': frenchCanada,
    de: german,
    en: english,
    es: spanish,
    fr: french,
    it: italian,
    'es-mx': spanishLatinAmerica,
    nl: dutch,
    pt: portuguese,
    zz: zeLanguage,
};

export const BaseLanguage = english;

const validLanguage = (val: string): val is Translations.Language => {
    return val in LANGUAGE_MAPPING;
};

type Store = {
    language: Translations.Language;
};

const load = (): Translations.Language | undefined => {
    moment.locale('en'); // default Moment to English to start with
    const loadedLanguage = localStorage.getItem(LOCAL_STORAGE_KEY);
    try {
        const language = loadedLanguage && JSON.parse(loadedLanguage);

        if (language && isState(language)) {
            moment.locale(language.language); // set the moment locale to be the same language as our translation
            return language.language;
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const initialState: Store = {
    language: load() || (validLanguage(locale) ? locale : 'en'),
};

export const useTranslations = createStore<Store>(() => initialState);

const { setState, getState } = useTranslations;

export const translationsActions = {
    save: (language: Partial<Store>) => {
        setState(language);

        moment.locale(language.language !== 'zz' ? language.language : 'en'); // set the moment locale to be the same language as our translation
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(getState()));

        // Send a custom event to Google Analytics to track language changes
        try {
            ReactGA.initialize('UA-164225485-2');
            ReactGA.event({
                category: 'Language',
                action: 'Switched language',
                label: language.language,
            });
        } catch (error) {
            console.error('Error initializing ReactGA:', error);
            // Handle the error or show a message to the user
        }
    },
};

// load language pre-render of the entire app
setState({ language: load() || (validLanguage(locale) ? locale : 'en') });

export const getTranslation = () => LANGUAGE_MAPPING[getState().language];
