import { Popover as MUIPopover } from '@material-ui/core';
import colorFns from 'colorFns';
import React, { useRef, useState } from 'react';
import { withIconStyles, withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import LightBulb from '../images/icons/lightbulb.svg?react';
import LightBulbCircle from '../images/icons/lightbulb_circle.svg?react';
import { Column, Spacer } from '../ui';
import { IconHeadline } from './ui/Headline';

const LightBulbIcon = styled(withIconStyles(LightBulb)).attrs(({ theme: { EColors } }) => ({
    colorOverride: EColors.protipsIcon,
}))``;

const LightBulbCircleIcon = styled(withInteractibleIconStyles(LightBulbCircle))`
    circle {
        fill: ${colorFns.protipsBubble};
    }
`;

type ProTipProps = {
    classNames?: string;
    message: string;
    children?: React.ReactNode;
    asNote?: boolean;
};

const Container = styled(Column)`
    flex-shrink: 0;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

export const ProTip = ({ classNames, message, children, asNote }: ProTipProps) => (
    <Container className={`pro-tip ${classNames}`}>
        <IconHeadline
            headline={asNote ? i18n.proTip.note : i18n.proTip.proTip}
            description={message}
            icon={<LightBulbIcon />}
        />
        {children && (
            <>
                <Spacer />
                {children}
            </>
        )}
    </Container>
);

const Popover = styled(MUIPopover)`
    .MuiPopover-paper {
        margin-top: 21px;
        background-color: transparent;
        box-shadow: none;
        overflow: inherit;

        ${Column} {
            max-width: 400px;

            ${Container} {
                border: none;
                box-shadow: 0 4px 12px ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
            }
        }
    }
`;

export const filterProtips = (
    proTips: Bizly.ProTip[],
    targetPage: Bizly.ProTip['page'],
    targetSection: Bizly.ProTip['section']
) => proTips.filter(({ page, section }) => page === targetPage && section === targetSection);

export const ProTipsDropdown = ({ protips = [] }: { protips?: Bizly.ProTip[] }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const anchorRef = useRef<SVGSVGElement | null>(null);

    const handleToggle = () => setIsOpen(prevState => !prevState);

    return protips?.length > 0 ? (
        <>
            <LightBulbCircleIcon ref={anchorRef} onClick={handleToggle} />
            <Popover
                anchorEl={anchorRef.current}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={() => setIsOpen(false)}
            >
                <Column itemSpacing="smallish">
                    {protips?.map(protip => <ProTip key={protip.id} message={protip.content} />)}
                </Column>
            </Popover>
        </>
    ) : null;
};
