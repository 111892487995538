import keyBy from 'lodash/keyBy';

const TeamIds = ['bizly', 'apple'] as const;

export type ETeams = (typeof TeamIds)[number];
// TODO: this is intentional to mimick enums but with better properties and may be fixed by a higher version of eslint 4.29.0
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ETeams = keyBy(TeamIds) as { [key in ETeams]: key };

const ColorIds = [
    'brand',

    /* Buttons */
    'primaryAction',
    'primaryActionHover',

    'warningAction',
    'warningActionHover',

    'secondaryTextAction',
    'secondaryTextActionHover',
    'warningTextAction',
    'warningTextActionHover',
    'alertWarning',

    'disabledAction',

    /* Icons */
    'defaultIconColor',
    'primaryDisplayIcon',
    'invertedDisplayIcon',

    'protipsIcon',
    'protipsBubble',
    'tilePinIcon',

    'amenitiesIconBackground',

    /* Attendee Status */
    'totalAttendee',
    'invitedAttendee',
    'notInvitedAttendee',
    'attendingAttendee',
    'notAttendingAttendee',
    'roomBlocks',
    'totalPrice',

    /* Survey Response Status */
    'surveysSentTotal',
    'surveysPending',
    'surveysRespondedTo',

    /* Event Status */
    'uneditedEvent',
    'activeEvent',
    'activeEventHover',
    'completedEvent',
    'completedEventHover',

    /* Dashboard */
    'dashboardCard',
    'dashboardHeader',

    /* Parcel Status */
    'parcelInvite',
    'parcelInviteHover',
    'parcelInviteSent',
    'parcelInviteSentHover',

    'parcelNote',
    'parcelNoteHover',
    'parcelNoteSent',
    'parcelNoteSentHover',

    /* Inquiry Status */
    'submittedInquiryStatus',
    'receivedProposalStatus',
    'cancelledOrRejectedStatus',
    'contractPendingStatus',
    'bookedVenueStatus',

    /* Collaborators */
    'canViewPillBackground',
    'canViewPillText',
    'canEditPillBackground',

    /* Team Related */
    'homeScreenBanner',
    'bannerLinkText',
    'officePillColor',
    'teamTemplatePill',
    'teamPreferenceNotesHeader',
    'teamPicker',
    'teamPickerText',

    /*
        Venues
    */

    /* Venue Search */
    'venueSearchToken',
    'venueSearchTokenHover',

    /* Venue Map Pins */
    'venuePinOffice',
    'venuePinHighlight',
    'venuePinDining',
    'venuePinHotel',
    'venuePinActivityUnique',
    'venuePinMuseumTheater',
    'venuePinEventMeetingConference',
    'venuePinWorkspace',
    'venuePinSearchLocation',

    /* Venue Space OR Listing */
    'featuredVenueOrSpaceName',
    'tagColor',
    'restaurantTagColor',
    'venueListingHeaders',
    'preferenceNotesHighlightedText',
    'displayPricing',

    /* Forms */
    'formLabel',
    'formHeading',
    'optionalSpecifier',

    /* Panes */
    'agendaDayPane',
    'agendaDayPaneText',
    'paneListItemBackground',
    'paneInsideListItemBackground',

    /* snackbars */
    'snackbarSuccess',
    'snackbarError',
    'snackbarInfo',

    /* dropdowns */
    'dropdownCheck',
    'dropdownItem',
    'dropdownItemHover',
    'dropdownDescription',
    'dropdownTokenBackground',
    'dropdownTokenText',

    /* progress bars */
    'progressBarForeground',
    'progressBarBackground',

    /* misc UI */
    'accentedHeadline',
    'highlightedText',

    'softAccentedBackground',
    'softAccentedBackgroundNested',
    'strongAccentedBackground',

    'warningBackground',
    'warningText',

    'bizlyHeadlineDot',

    'placeholderBackground',
    'strongCarouselBackground',
    'strongCarouselText',

    'softBorder',

    'listItemBackground',
    'listItemNestedBackground',

    'imagePlaceholderBackground',
    'imagePlaceholderIcon',

    'containerBackground',
    'fieldBorder',

    /* Graphs */
    'graphFontColorVariant1',
    'graphFontColorVariant2',
    'graphColorVariant1',
    'graphColorVariant2',
    'graphColorVariant3',
    'graphColorVariant4',
    'graphColorVariant5',
    'graphColorVariant6',
    'graphColorVariant7',
    'graphColorVariant8',

    'graphUserTotalColor',
    'graphUserActiveColor',
    'graphUserAcceptedColor',
    'graphUserBookedColor',

    'graphMeetingsCreatedColor',
    'graphMeetingsInquiredColor',
    'graphMeetingsAcceptedColor',
    'graphMeetingsBookedColor',
    'graphMeetingsCancelledColor',

    'graphTotalInquiry',
    'graphTotalContract',

    /* Preference Pill */
    'preferredVenue',
    'preferredRank10',
    'preferredRank20',
    'preferredRank30',
    'preferredRank40',
    'preferredRank50',

    /* neutrals */
    'black',
    'darkestGrey',
    'darkerGrey',
    'grey2',
    'grey3',
    'darkGrey',
    'grey',
    'lightGrey',
    'lighterGrey',

    /* pures */
    'pureWhite',
    'pureBlack',

    /* branding */
    'sidenavForeground',
    'subNavBackground',

    /* theme colors */
    'pink',

    /* Carbon */
    'carbonWhite',
    'carbonGreen',

    /* Misc */
    'blushPink',
    'fuchsiaPink',

    'red',
    'green',

    /* Redesign Palette */
    'aliceBlue',
    'platinum',
    'vividFuchsia',
    'drWhite',
    'coldWind',

    /* Bizly OS Colors - Need to take this out into it's own theme file */
    'bizlyOSPrimary',
    'bizlyOSBorder',
    'bizlyOSYellow',
    'bizlyOSWarning',
    'bizlyOSGray20',
] as const;

export type EColors = (typeof ColorIds)[number];
// TODO: this is intentional to mimick enums but with better properties and may be fixed by a higher version of eslint 4.29.0
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EColors = keyBy(ColorIds) as { [key in EColors]: key };

const FontIds = ['body', 'heading', 'formLabel', 'formHeading'] as const;

export type EFonts = (typeof FontIds)[number];
// TODO: this is intentional to mimick enums but with better properties and may be fixed by a higher version of eslint 4.29.0
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EFonts = keyBy(FontIds) as { [key in EFonts]: key };
