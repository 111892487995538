import { BudgetApprovalsTable } from 'components/BudgetApprovals/BudgetApprovalsTable';
import SideNav from 'components/SideNav';
import { PageHeadline } from 'components/ui/Headline';
import { i18n } from 'translation';

export default function Approvals() {
    return (
        <SideNav>
            <PageHeadline>{i18n.approvals.header}</PageHeadline>
            <BudgetApprovalsTable />
        </SideNav>
    );
}
