import { APISort, searchMeetings } from 'api/meetings';
import createStore from 'stores';
import { FilterOptions } from 'stores/meetings/filterStore';
import { meetingsActions } from './store';

const SORT_TO_API: { [key in FilterOptions]: APISort } = {
    default: undefined,
    status: 'status_rank',
    updated_at: 'updated_at',
};

type State = {
    loadingByPage: {
        [page: number]: boolean;
    };
    idsByPage: {
        [page: number]: number[] | undefined;
    };
    pageLimit: number;
    lastPage: number | null;
    query: string;
    sort: FilterOptions;
};
type Store = State;

const initialState: State = {
    loadingByPage: {},
    idsByPage: {},
    pageLimit: 20,
    lastPage: null,
    query: '',
    sort: 'default',
};
export const useSearchMeetings = createStore<Store>(() => initialState);

const { setState, getState } = useSearchMeetings;

export const searchMeetingsActions = {
    search: async (query: string, page = 1) => {
        if (query !== getState().query) {
            setState(prevState => ({
                ...initialState,
                sort: prevState.sort,
            }));
        }

        setState({
            loadingByPage: { ...getState().loadingByPage, [page]: true },
        });

        const pullCount = getState().pageLimit;

        try {
            const { meetings, searchMeta } = await searchMeetings(query, page, pullCount, SORT_TO_API[getState().sort]);

            setState({
                loadingByPage: { ...getState().loadingByPage, [page]: false },
                idsByPage: {
                    ...getState().idsByPage,
                    [page]: meetings.map(m => m.id),
                },
                lastPage: searchMeta.lastPage,
                query,
            });

            meetingsActions.add(meetings);

            return meetings;
        } catch (e) {
            setState({
                loadingByPage: { ...getState().loadingByPage, [page]: false },
            });

            throw e;
        }
    },

    setSort: (sortBy: FilterOptions) => {
        setState({
            sort: sortBy,
        });
    },

    setQuery: (query: string) => {
        setState({
            query,
        });
    },

    reset: () => setState(initialState),
};

export const selectMeetingIds = (page?: number) => (state: State) =>
    page
        ? state.idsByPage[page]
        : (Object.values(state.idsByPage)
              .flat()
              .filter(id => id) as number[]);

export function isLoadingPage(page: number = 1) {
    return (state: State) => state.loadingByPage[page];
}
