export const STATUS_LABELS: { [key in BizlyAPI.MeetingStatusCode]: string } = {
    new: 'Plan Event',
    inquiry_draft: 'Submit Inquiry',
    inquiry_submitted: 'Inquiry Submitted',
    inquiry_sent: 'Inquiry Submitted',
    inquiry_response: 'Review Responses',
    proposal_accepted: 'Upload Contract',
    booked: 'Booked',
    booking_over: 'Update Final Spend',
    booking_tracked: 'Meeting Complete',
    cancelled: 'Cancelled',
};

export enum EventType {
    SCHEDULED = 'Scheduled',
    UNSCHEDULED = 'Unscheduled',
}
