import Popover from '@material-ui/core/Popover';
import colorFns from 'colorFns';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { useEvent } from '../../providers/event';
import { useEventCollaborators } from '../../providers/event-collaborators';
import { AlignedRow, Column, Spacer } from '../../ui';
import TextButton from '../ui/Button/TextButton';
import Collaborator from './Collaborator';
import SearchCollaborators from './SearchCollaborators';

const Container = styled(Column)`
    width: 460px;
    padding: 32px;
    max-height: 679px;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
`;

const AccentedHeadline = styled.h2`
    margin-top: 0;

    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: ${colorFns.accentedHeadline};
`;

const Footer = styled(AlignedRow)`
    margin-top: 32px;
    gap: 20px;
`;

const CollaboratorsFlyout = ({ anchor, onClose }: { anchor: Nullable<Element>; onClose: () => void }) => {
    const [newCollaborators, setNewCollaborators] = React.useState<BizlyAPI.NewEventCollaborator[]>([]);
    const [newEditor, setEditorPermission] = React.useState<boolean>(false);
    const { event } = useEvent();
    const {
        collaborators: existingCollaborators,
        updateCollaboratorPermission,
        addCollaborators,
        isAddCollaboratorsPartialError,
    } = useEventCollaborators();
    const { enqueueSnackbar } = useSnackbar();
    const {
        user: { isSsoUser },
    } = useUser();

    const closeFlyout = () => {
        setNewCollaborators([]);
        onClose();
    };

    const updateNewCollaboratorsPermission = (collaborators: BizlyAPI.NewEventCollaborator[], editor: boolean) => {
        const _newCollaborators = collaborators.map(c => ({
            ...c,
            editor,
        }));
        setNewCollaborators(_newCollaborators);
    };

    const onCollaboratorsChange = (collaborators: BizlyAPI.NewEventCollaborator[]) => {
        updateNewCollaboratorsPermission(collaborators, newEditor);
    };

    const onPermissionChange = (edit: boolean) => {
        setEditorPermission(edit);
        updateNewCollaboratorsPermission(newCollaborators, edit);
    };

    const handleSave = async () => {
        closeFlyout();
        try {
            await addCollaborators(newCollaborators);
            enqueueSnackbar(
                !isSsoUser
                    ? i18n.meetingDashboard.headerSection.collaborators.collaboratorsInvited
                    : i18n.meetingDashboard.headerSection.collaborators.collaboratorsAdded,
                {
                    variant: 'info',
                }
            );
        } catch (e) {
            const { failedEmails = [] } = isAddCollaboratorsPartialError(e) ? e.raw : {};
            const message = [
                !isSsoUser
                    ? i18n.meetingDashboard.headerSection.collaborators.inviteError
                    : i18n.meetingDashboard.headerSection.collaborators.addError,
                ...(failedEmails.length > 0
                    ? [
                          !isSsoUser
                              ? i18n.meetingDashboard.headerSection.collaborators.emailInviteError
                              : i18n.meetingDashboard.headerSection.collaborators.emailAddError,
                      ]
                    : []),
                i18n.error.tryAgain,
            ].join(' ');

            enqueueSnackbar(message, { variant: 'error' });
        }
    };

    const changePermission = async (collaboratorId: number, canEdit: boolean) => {
        try {
            await updateCollaboratorPermission(collaboratorId, canEdit);
            enqueueSnackbar(
                canEdit
                    ? i18n.meetingDashboard.headerSection.collaborators.collaboratorCanEdit
                    : i18n.meetingDashboard.headerSection.collaborators.collaboratorCanView,
                {
                    variant: 'info',
                }
            );
        } catch (e) {
            const message = [
                i18n.meetingDashboard.headerSection.collaborators.changePermissionError,
                i18n.error.tryAgain,
            ].join(' ');
            enqueueSnackbar(message, { variant: 'error' });
        }
    };

    return (
        <Popover
            anchorEl={anchor}
            open={!!anchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={closeFlyout}
        >
            <Container>
                <AccentedHeadline>
                    {!isSsoUser
                        ? i18n.meetingDashboard.headerSection.collaborators.invite
                        : i18n.meetingDashboard.headerSection.collaborators.addCollaborators}
                </AccentedHeadline>
                <SearchCollaborators
                    eventId={event.id}
                    existingCollaboratorEmails={[
                        ...existingCollaborators.map(collaborator => collaborator.email),
                        event.plannedBy.email,
                    ]}
                    onChange={onCollaboratorsChange}
                    editor={newEditor}
                    onPermissionChange={onPermissionChange}
                    allowNewEmails={!isSsoUser}
                />
                <Spacer col />
                {existingCollaborators.map(collaborator => (
                    <Collaborator
                        key={collaborator.email}
                        collaborator={collaborator}
                        changePermission={(canEdit: boolean) => changePermission(collaborator.id, canEdit)}
                    />
                ))}
                <Footer justifyContent="flex-end">
                    <TextButton onClick={closeFlyout} secondary>
                        {i18n.button.cancel}
                    </TextButton>

                    <TextButton onClick={handleSave} disabled={newCollaborators.length === 0}>
                        {!isSsoUser ? i18n.button.invite : i18n.button.add}
                    </TextButton>
                </Footer>
            </Container>
        </Popover>
    );
};

export default CollaboratorsFlyout;
