import Box from '@mui/material/Box';
import RightDrawer from 'components/Drawer/RightDrawer';
import Form from 'components/Form';
import { Button } from 'components/Ui-V2/Button/Button';
import { useEvent } from 'providers/event';
import { useCallback, useMemo } from 'react';
import { units, useMeasurementUnits } from 'stores/measurement-units';
import { usePlaybooks } from 'stores/playbooks';
import { i18n } from 'translation';
import { kmToMi, miToKm } from 'utils';
import { getBrandOptions, getFilterTypeIds } from 'utils/venue';
import { fields, schema } from './filtersSchema';

const errors = {};

export type TFilterValueBase = {
    dinovaOnly: boolean;
    types: BizlyAPI.Venue.Types[];
    preferredOnly: boolean;
    radius: Distance.Mile;
    radiusKm: Distance.Kilometer;
    brandIds: number[];
    guests: number;
};
export type TFilterValue = Partial<TFilterValueBase>;

export type TFilterValueInitial = TFilterValueBase & Omit<TFilterValue, 'types'>;

type TProps = {
    filters: TFilterValue;
    onFilterChange: (filterVal: TFilterValue) => void;
    open: boolean;
    onClose: () => void;
    teamName: string;
};
const baseFormProps = {
    isNested: undefined,
    readonly: undefined,
    densePadding: undefined,
};
export default function VenueSearchFilters({ filters, onFilterChange, open, onClose, teamName }: TProps) {
    const { playbookOptions: { venueBrands = [], venueTypes: playbookVenueTypes = [] } = {} } = usePlaybooks();
    const { event } = useEvent();
    const brandRestrictions = useMemo(
        () => (event.venueSearchParameters?.brandIds ? new Set(event.venueSearchParameters.brandIds) : undefined),
        [event.venueSearchParameters]
    );
    const brands = useMemo(
        () =>
            brandRestrictions && event.venueSearchParameters?.isLocked
                ? venueBrands.filter(({ id }) => brandRestrictions.has(id))
                : venueBrands,
        [venueBrands, brandRestrictions, event.venueSearchParameters]
    );

    // set "disabled" attribute of the venue brand option
    const brandOptions = useMemo(
        () => getBrandOptions(filters.types, playbookVenueTypes, brands),
        [filters.types, playbookVenueTypes, brands]
    );

    const fieldsWithTeam = useMemo(
        () =>
            fields(teamName, brandOptions, {
                disabledBrands: !!brandRestrictions && event.venueSearchParameters?.isLocked,
            }),
        [teamName, brandOptions, brandRestrictions, event.venueSearchParameters]
    );
    const handleEventChange = ({
        field,
        value,
    }: {
        field: keyof typeof fieldsWithTeam;
        value: TFilterValueInitial;
    }) => {
        const newVal = { ...value };

        // deselect already selected brands if they are greyd out
        if (field === 'types' && newVal.types.length > 0) {
            const ids = getFilterTypeIds(newVal.types, playbookVenueTypes);
            const newBrandIds = newVal.brandIds?.filter(id => {
                const brandForId = brands.find(brand => brand.id === id);
                return brandForId && ids.indexOf(brandForId.venueTypeId) >= 0;
            });
            newVal.brandIds = newBrandIds ?? [];
        }

        if (field === 'radius') {
            newVal.radiusKm = miToKm(newVal.radius);
        }

        if (field === 'radiusKm') {
            newVal.radius = kmToMi(newVal.radiusKm);
        }

        onFilterChange(newVal);
    };

    const onClear = useCallback(() => {
        onFilterChange({
            dinovaOnly: false,
            types: [],
            preferredOnly: false,
            radius: 15 as Distance.Mile,
            radiusKm: miToKm(15 as Distance.Mile),
        });
    }, [onFilterChange]);

    const { distance: distanceUnit } = useMeasurementUnits();

    return (
        <RightDrawer drawerOpen={open} onClose={onClose} title={i18n.venue.filterBy}>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={onClear}>
                    {i18n.button.reset}
                </Button>
            </Box>
            <Form
                fields={fieldsWithTeam}
                schema={schema(distanceUnit === units.meter ? units.kilometer : units.mile, true)}
                value={filters}
                errors={errors}
                onChange={handleEventChange}
                {...baseFormProps}
            />
        </RightDrawer>
    );
}
