import { useSnackbar } from 'notistack';
import { createContext, useContext, useEffect, useState } from 'react';
import { isLoadingActive, meetingsActions, useMeetings } from './store';

const LoadingContext = createContext({ isLoading: true });

export const useLoadingContext = () => useContext(LoadingContext);

export default function LoadActiveMeetings({
    limit,
    orderBy,
    children,
}: {
    limit?: number;
    orderBy?: BizlyAPI.MeetingSort;
    children: React.ReactNode;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const isLoadingFromStore = useMeetings(isLoadingActive());

    useEffect(() => {
        const loadMeetings = async () => {
            setIsLoading(true);
            try {
                await meetingsActions.loadActive({ limit, orderBy });
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            } finally {
                setIsLoading(false);
            }
        };

        loadMeetings();
    }, [limit, orderBy, enqueueSnackbar]);

    return (
        <LoadingContext.Provider value={{ isLoading: isLoading || isLoadingFromStore }}>
            {children}
        </LoadingContext.Provider>
    );
}
