import { MEETINGS_NAV_WIDTH } from 'components/MeetingsNav';
import { SIDE_SMALL_NAV_WIDTH } from 'components/SideNav';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { withIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { HEADER_HEIGHT } from 'utils/header';
import CalculatorIcon from '../../images/icons/calculator.svg?react';
import { Copy as UICopy } from '../../ui';
import Button from '../ui/Button';

const Container = styled.div`
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    margin-top: ${HEADER_HEIGHT}px;
    height: 88px;
    width: calc(100% - ${SIDE_SMALL_NAV_WIDTH}px - ${MEETINGS_NAV_WIDTH}px);
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.strongAccentedBackground)};

    div {
        display: flex;
        align-items: center;
    }
`;

const Bubble = styled.div`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 48px;
    width: 48px;
    margin-left: 24px;
    margin-right: 40px;
`;

const CalcIcon = withIconStyles(CalculatorIcon);

const Copy = styled(UICopy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    font-size: 15px;
    margin-right: 12px;
`;

const WhiteButton = styled(Button)`
    margin-right: 27px;
`;

const Shadow = styled.div`
    height: 88px;
    visiblility: hidden;
`;

const FinalSpendBanner = () => {
    const navigate = useNavigate();
    const { event } = useEvent();
    const { user } = useUser();
    const documentsOptional = user.team?.optionalEventDocumentUploads ?? false;

    const onButtonClick = useCallback(() => {
        if (!event) {
            return;
        }
        if (documentsOptional) {
            navigate(`/event/${event.id}/venue/contracting`);
        } else {
            navigate(`/event/${event.id}/spend-tracker?tab=final`)
        }
    }, [documentsOptional, event, navigate]);

    return (
        <>
            <Container>
                <div>
                    <Bubble>
                        <CalcIcon />
                    </Bubble>
                    <Copy>
                        {documentsOptional
                            ? i18n.meetingDashboard.completeMeetingDescription
                            : i18n.meetingDashboard.uploadFinalSpendDescription}
                    </Copy>
                </div>
                <WhiteButton onClick={onButtonClick} width={191} secondary>
                    {documentsOptional ? i18n.meetingDashboard.goToContracting : i18n.meetingDashboard.goToSpendTracker}
                </WhiteButton>
            </Container>
            <Shadow />
        </>
    );
};

export default FinalSpendBanner;
