export function sortVendors(
    vendors: BizlyAPI.Addon[],
    isPreferred: (vendor: BizlyAPI.Addon) => boolean,
    isSelected: (vendor: BizlyAPI.Addon) => boolean,
    showPreferredAddOnsOnly?: boolean
) {
    const getVendorRank = (vendor: BizlyAPI.Addon) => {
        let rank = 0;

        if (isSelected(vendor)) {
            rank -= 2;
        }

        if (isPreferred(vendor)) {
            rank -= 1;
        }

        return rank;
    };

    return showPreferredAddOnsOnly
        ? [...vendors]
              .filter(vendor => isPreferred(vendor) || isSelected(vendor))
              .sort((vendor1, vendor2) => getVendorRank(vendor1) - getVendorRank(vendor2))
        : [...vendors].sort((vendor1, vendor2) => getVendorRank(vendor1) - getVendorRank(vendor2));
}
