import React from 'react';
import styled from 'styled-components';
import { Column, RichTextCopy, Row } from 'ui';

const Content = styled(Column)`
    min-width: 500px;
`;

const PlaybookTags = styled(Row)`
    margin: -6px;
    > * {
        margin: 6px;
    }
    flex-wrap: wrap;
`;

const PlaybookTag = styled.div`
    border-radius: 3px;
    padding: 5px 20px;
    cursor: default;
    font-size: 14px;
    font-weight: 600;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Purpose = styled.p`
    padding: 16px 18px;
    margin: 0;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

interface CoverImageProps {
    src: string;
}

const CoverImage = styled.div<CoverImageProps>`
    border-radius: 8px;
    width: 100%;
    height: 0;
    padding: 60% 0 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: scroll;
    background-size: cover;

    background-image: url(${props => props.src || 'none'});
`;

export default function PlaybookBasicDetail({ playbook }: { playbook: BizlyAPI.Complete.Playbook }) {
    return (
        <Content itemSpacing="large">
            {playbook.tags && playbook.tags.length > 0 && (
                <PlaybookTags>
                    {playbook.tags.map(({ id, name }) => (
                        <PlaybookTag key={id}>{name}</PlaybookTag>
                    ))}
                </PlaybookTags>
            )}
            {playbook.purpose && <Purpose>{playbook.purpose}</Purpose>}

            {playbook.imageUrl && <CoverImage src={playbook.imageUrl} />}
            <RichTextCopy dangerouslySetInnerHTML={{ __html: playbook.description || '' }} />
        </Content>
    );
}
