import { Button as MuiButton, ButtonProps as MuiButtonProps, alpha, styled } from '@mui/material';
import React from 'react';

type BizlyOSVariant = 'primary' | 'secondary' | 'outline' | 'text';
type BizlyOSColor = 'default' | 'error';

interface BizlyOSButtonProps extends Omit<MuiButtonProps, 'variant' | 'color'> {
    variant?: BizlyOSVariant;
    color?: BizlyOSColor;
}

const StyledBizlyOSButton = styled(MuiButton, {
    shouldForwardProp: prop => prop !== 'variant' && prop !== 'color',
})<BizlyOSButtonProps>(({ theme, variant = 'primary', color = 'default' }) => {
    const { getColor, EColors } = theme;

    const baseStyles = {
        padding: '.25rem .75rem',
        fontSize: '.875rem',
        fontWeight: 500,
        borderRadius: '4px',
    };

    const primaryStyles = {
        backgroundColor: getColor(EColors.bizlyOSPrimary),
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.8),
        },
    };

    const secondaryStyles = {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.1),
        },
    };

    const outlineStyles = {
        backgroundColor: theme.palette.common.white,
        color: getColor(EColors.bizlyOSPrimary),
        border: `1px solid ${getColor(EColors.bizlyOSPrimary)}`,
        '&:hover': {
            backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.1),
        },
    };

    const textStyles = {
        backgroundColor: 'transparent',
        color: getColor(EColors.bizlyOSPrimary),
        '&:hover': {
            backgroundColor: alpha(getColor(EColors.bizlyOSPrimary), 0.1),
        },
    };

    const errorStyles = {
        color: getColor(EColors.warningText),
        ...(variant === 'primary' && {
            backgroundColor: getColor(EColors.warningText),
            color: theme.palette.common.white,
            '&:hover': {
                backgroundColor: alpha(getColor(EColors.warningText), 0.8),
            },
        }),
        ...(variant === 'outline' && {
            border: `1px solid ${getColor(EColors.warningText)}`,
            '&:hover': {
                backgroundColor: alpha(getColor(EColors.warningText), 0.1),
            },
        }),
        ...(variant === 'text' && {
            '&:hover': {
                backgroundColor: alpha(getColor(EColors.warningText), 0.1),
            },
        }),
    };

    const disabledStyles = {
        '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        },
    };

    const variantStyleMap: Record<BizlyOSVariant, React.CSSProperties> = {
        primary: primaryStyles,
        secondary: secondaryStyles,
        outline: outlineStyles,
        text: textStyles,
    };

    const variantStyles = variantStyleMap[variant as BizlyOSVariant];

    const colorStyles = color === 'error' ? errorStyles : {};

    return {
        ...baseStyles,
        ...variantStyles,
        ...colorStyles,
        ...disabledStyles,
    };
});

export const Button = React.forwardRef<HTMLButtonElement, BizlyOSButtonProps>(
    ({ variant = 'primary', color = 'default', ...props }, ref) => {
        return <StyledBizlyOSButton ref={ref} variant={variant} color={color} disableElevation {...props} />;
    }
);
