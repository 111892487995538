import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ClickAwayListener, IconButton, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal';
import EventCreateModal, { EventCreateProtocolModal } from 'components/EventCreateModal';
import { useDeleteEventTemplate } from 'hooks/queries/useTemplateQueries';
import { useCreateMeeting } from 'hooks/useCreateMeeting';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from 'translation';
import { ESECTION } from './utils';

interface TemplateActionPopperProps {
    template: Bizly.EventTemplate;
}

export const TemplateActionPopper = ({ template }: TemplateActionPopperProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const navigate = useNavigate();
    const deleteTemplateEvent = useDeleteEventTemplate();
    const { createEvent, createModalShown, protocolModalShown, closeCreateModal, hideProtocolModal } =
        useCreateMeeting();

    const open = Boolean(anchorEl);

    const openPopper = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const closePopper = () => {
        setAnchorEl(null);
    };

    const handleMenuAction = (actionCallback: () => void) => {
        actionCallback();
        closePopper();
    };

    const handleCreateMeeting = () => {
        createEvent();
        closePopper();
    };

    const handleDeleteConfirmation = () => {
        setDeleteModalOpen(true);
        closePopper();
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteTemplateEvent.mutateAsync(template.id);
        } finally {
            setDeleteModalOpen(false);
        }
    };

    const handleTemplateEventEdit = () => {
        navigate(`/playbooks/${template.id}/edit`);
    };

    const handleTemplateEventView = () => {
        navigate(`/playbooks/${template.id}`);
    };

    return (
        <>
            <IconButton onClick={openPopper}>
                <MoreHorizIcon />
            </IconButton>

            <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorEl} placement="bottom-start">
                <Paper>
                    <ClickAwayListener onClickAway={closePopper}>
                        <MenuList>
                            <MenuItem onClick={() => handleMenuAction(handleTemplateEventView)}>
                                {i18n.playbook.view}
                            </MenuItem>
                            <MenuItem onClick={handleCreateMeeting}>{i18n.playbook.createEvent}</MenuItem>
                            {template.category !== ESECTION.GLOBAL && template.editable && (
                                <>
                                    <MenuItem onClick={() => handleMenuAction(handleTemplateEventEdit)}>
                                        {i18n.playbook.edit}
                                    </MenuItem>
                                    <MenuItem onClick={handleDeleteConfirmation}>{i18n.playbook.delete}</MenuItem>
                                </>
                            )}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {createModalShown && <EventCreateModal template={template} onClose={closeCreateModal} fromPlaybook />}
            {protocolModalShown && <EventCreateProtocolModal onBackdropClick={hideProtocolModal} />}
            {deleteModalOpen && (
                <ConfirmationModal
                    warning
                    headline={i18n.playbook.deletePlaybook}
                    onDismiss={() => setDeleteModalOpen(false)}
                    onProceed={handleConfirmDelete}
                    prompt={i18n.playbook.deletePrompt}
                    isActive={deleteModalOpen}
                />
            )}
        </>
    );
};
