import GooglePlacesAutocomplete from 'components/Ui-V2/AutoComplete/PlaceInput';
import { i18n } from 'translation';
import { EventCreateForm } from '../formSchema';
import { Description, StepContainer, Title } from './styled';

type PropType = {
    value: EventCreateForm['location'];
    onChange: (value: EventCreateForm['location'] | null) => void;
};

const Step2 = ({ value, onChange }: PropType) => {
    const onPlaceChange = (location: string, googlePlaceId: string) =>
        onChange({
            location,
            googlePlaceId,
        });

    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.locationTitle}</Title>
            <Description>{i18n.homepage.createMeetingModal.locationDescription}</Description>
            <GooglePlacesAutocomplete
                locationValue={value?.location}
                placeId={value?.googlePlaceId}
                onChange={onPlaceChange}
                onRemove={() => onChange(null)}
                clearable
                required
            />
        </StepContainer>
    );
};

export default Step2;
