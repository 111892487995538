import { Dialog } from '@material-ui/core';
import Form from 'components/Form';
import TextButton from 'components/ui/Button/TextButton';
import { H2Headline } from 'components/ui/Headline';
import React from 'react';
import {
    areaUnits,
    distanceUnits,
    measurementUnitsActions,
    UnitKeys,
    useMeasurementUnits,
} from 'stores/measurement-units';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, InlineRow, Row, Spacer } from 'ui';

const ModalContent = styled(Column)`
    padding: 24px;
    width: 400px;
`;

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
    white-space: pre-line;
`;

const ConstrainedForm = styled(Form)``;

const options = {
    area: areaUnits,
    distance: distanceUnits,
} as const;

type Options = typeof options;

export default function UnitField<UnitType extends UnitKeys>({
    type,
    field,
    onChange,
    inline,
    disabled,
}: {
    type: UnitType;
    field: string;
    onChange: (update: { field: string; value: ArrayType<Options[UnitType]> }) => void;
    inline?: boolean;
    disabled?: boolean;
}) {
    const currentVal = useMeasurementUnits(state => state[type]) as ArrayType<Options[UnitType]>;
    const [unit, setUnit] = React.useState<ArrayType<Options[UnitType]> | null>(null);

    const selectOptions = options[type];

    return (
        <InlineRow alignItems="center" style={inline ? {} : { minHeight: 40, margin: '8px 0 4px 0' }}>
            <TextButton
                onClick={() => {
                    setUnit(currentVal);
                }}
                disabled={disabled}
            >
                {currentVal}
            </TextButton>

            <Dialog open={!!unit} onClose={() => setUnit(null)}>
                <ModalContent>
                    <Column itemSpacing="small">
                        <H2Headline>{i18n.proposalForm.eventSpaces.changeUnitHeading}</H2Headline>
                        <SpacedCopy>{i18n.proposalForm.eventSpaces.changeUnitDescription}</SpacedCopy>
                    </Column>
                    <Spacer smallish />
                    <ConstrainedForm
                        fields={{
                            unit: {
                                type: 'select',
                                options: {
                                    options: selectOptions,
                                },
                            },
                        }}
                        schema={[{ fields: ['unit'], spacing: false }]}
                        value={{ unit }}
                        onChange={({ value: { unit } }: { value: { unit: ArrayType<Options[UnitType]> } }) => {
                            setUnit(unit);
                        }}
                    />
                    <Spacer large />
                    <Row justifyContent="flex-end">
                        <InlineRow itemSpacing="smallish">
                            <TextButton
                                onClick={() => {
                                    setUnit(null);
                                }}
                                secondary
                            >
                                {i18n.button.cancel}
                            </TextButton>
                            <TextButton
                                onClick={() => {
                                    if (unit) {
                                        onChange({ field, value: unit });
                                        measurementUnitsActions.save({ [type]: unit });
                                    }
                                    setUnit(null);
                                }}
                            >
                                {i18n.button.set}
                            </TextButton>
                        </InlineRow>
                    </Row>
                </ModalContent>
            </Dialog>
        </InlineRow>
    );
}
