import styled from 'styled-components';
import { Column } from 'ui';

const DashboardCard = styled(Column)<{ scrollable?: boolean; small?: boolean; frameless?: boolean }>`
    position: relative;
    height: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
    padding-top: 0;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dashboardCard)};

    ${({ scrollable }) => scrollable && 'overflow: auto;'}

    ${({ frameless, theme: { getColor, EColors } }) =>
        frameless
            ? `
        height: auto;
        
        padding: 0;

        background-color: ${getColor(EColors.pureWhite)};
    `
            : ''}
`;

export default DashboardCard;
