import { Box, Typography } from '@mui/material';
import { HTMLInputTypeAttribute } from 'react';
import { FieldError } from 'react-hook-form';
import styledComponents from 'styled-components';
import { EColors, getColor } from 'theme';

const StyledInputField = styledComponents.input<{ error: boolean }>`
    box-sizing: border-box;
    width: 100%;
    font-size:0.875rem;
    height: 50px;
    padding: 14.5px 10px;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid;
    border-color: ${({ error }) => (error ? 'red' : getColor(EColors.bizlyOSBorder))};
    color: ${getColor(EColors.pureBlack)};
    &:focus{
        outline:none;
        border: 1px solid ${getColor(EColors.bizlyOSPrimary, 0.6)};
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    &:focus::placeholder{
        color: ${getColor(EColors.pureBlack)};
        opacity: 0.5;
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ error }) => (error ? 'red' : getColor(EColors.pureBlack))};
        opacity: 0.5;
    }
`;

type InputFieldProps = {
    label: string;
    placeholder?: string;
    error: FieldError | undefined;
    onChange: (...event: any[]) => void;
    onBlur?: () => void;
    value: string | number | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
    ref?: (instance: any) => void;
    type?: HTMLInputTypeAttribute;
};

export default function InputField({
    label,
    error,
    type = 'text',
    placeholder,
    required = false,
    ...field
}: InputFieldProps) {
    return (
        <Box>
            <Box display="flex" gap={0.5} marginBottom={1}>
                <Typography variant="body2" fontWeight={500}>
                    {label}
                </Typography>
                {required && (
                    <Typography variant="body2" color={getColor(EColors.bizlyOSPrimary)} fontWeight={500}>
                        *
                    </Typography>
                )}
            </Box>
            <StyledInputField type={type} placeholder={placeholder ? placeholder : label} {...field} error={!!error} />
            {!!error && (
                <Typography color="red" variant="caption" fontWeight={500} marginBottom={1}>
                    {error.message}
                </Typography>
            )}
        </Box>
    );
}
