import Button from 'components/ui/Button';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { RichTextCopy, Spacer } from '../../ui';
import StepsRow from './StepsRow';

const Copy = styled(RichTextCopy)<any>`
    font-size: 15px;
    line-height: 1.47;
    white-space: pre-line;

    ul {
        padding-inline-start: 1.2em;
    }
`;

type FinalizeDetailsProps = {
    number: number;
    copy: string;
    documentOptional?: boolean;
    onCompleteEvent: () => void;
    eventEnded: boolean;
};

const defaultCopy = i18n.venue.proposal.finalizeSubHeadline;

const FinalizeDetails = forwardRef(
    (
        { number, eventEnded, copy = defaultCopy, documentOptional = false, onCompleteEvent }: FinalizeDetailsProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        return (
            <StepsRow number={number} headline={i18n.venue.proposal.finalizeHeadline} ref={ref}>
                <Copy dangerouslySetInnerHTML={{ __html: copy }} />

                {documentOptional && (
                    <>
                        <Spacer />
                        <Button width={180} onClick={onCompleteEvent} disabled={!eventEnded}>
                            {i18n.venue.proposal.markAsComplete}
                        </Button>
                    </>
                )}
            </StepsRow>
        );
    }
);

export default FinalizeDetails;
