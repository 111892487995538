import ConfirmationModal from 'components/ConfirmationModal';
import Form from 'components/Form';
import { filterProtips, ProTip, ProTipsDropdown } from 'components/ProTip';
import { defaultVMFields, VMSchemaLink, VMSchemaNotes } from 'components/Schedule/scheduleFormSchema';
import { TVMValue } from 'components/Schedule/VirtualMeetingField';
import { SpinnerOverlay } from 'components/Spinner';
import TextButton from 'components/ui/Button/TextButton';
import { Headline, PageHeadline } from 'components/ui/Headline';
import usePersistentSnackbar from 'hooks/usePersistentSnackbar';
import { PageNotFound } from 'pages/PageNotFound';
import VendorTile from 'pages/Vendors/components/VendorTile';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    addonActions,
    eventHasSelectedAddon,
    getAddon,
    getSelectedMetaData,
    isSelected,
    useAddons,
} from 'stores/addons';
import LoadAddons from 'stores/addons/LoadAddons';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Card, Column, Copy, ExternalLink, Row, Spacer } from 'ui';
import * as URI from 'uri-js';

const FormCard = styled(Card)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
    padding: 32px;
    width: 480px;
`;

const FormContent = styled(Column)`
    width: 100%;
    position: relative;
`;

const TileColumn = styled(Column)`
    width: 400px;
`;

const Bottom = styled(Row)`
    width: auto;
    overflow: hidden;
    justify-content: right;
    z-index: 1;
`;

const AddButton = styled(TextButton)`
    border-radius: 4px;
    padding: 2px;
`;

const RemoveButton = styled(TextButton)`
    border-radius: 4px;
    padding: 2px;
`;

type VMData = { link?: string; notes?: string };

const SelectVendorForm = ({
    eventId,
    vendor,
    selected,
    meetingHasSelectedVendor,
    initialData,
    onSave,
    disabled,
}: {
    eventId: string | number;
    vendor: BizlyAPI.Addon;
    selected: boolean;
    meetingHasSelectedVendor: boolean;
    initialData: VMData;
    onSave?: (data: VMData) => void;
    disabled?: boolean;
}) => {
    const { savingSelected } = useAddons();
    const { selectAddon, updateAddon, removeAddon } = addonActions;
    const [stagedVal, setStagedVal] = useState<TVMValue>(initialData);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showRemovalModal, setShowRemovalModal] = useState(false);
    const updateStagedValue = ({ value }: { value: VMData }) => setStagedVal(value);
    const enqueueSnackbar = usePersistentSnackbar([stagedVal?.link]);

    const getErrorVirtualMeeting = () => {
        if (!stagedVal?.link) {
            return i18n.virtual.error.linkRequired;
        }

        const parsedLink = URI.parse(stagedVal?.link);
        if (!parsedLink.scheme) {
            return i18n.virtual.error.linkProtocol;
        }

        return false;
    };

    const onApply = async () => {
        const error = getErrorVirtualMeeting();
        if (error) {
            return enqueueSnackbar(error, { variant: 'error' });
        }
        try {
            if (selected) {
                await updateAddon(eventId, vendor.id, stagedVal?.link, stagedVal?.notes);
            } else {
                await selectAddon(eventId, vendor.id, stagedVal?.link, stagedVal?.notes);
            }
            onSave?.(stagedVal);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
        setShowConfirmationModal(false);
    };

    const onRemove = async () => {
        try {
            if (selected) {
                await removeAddon(eventId, vendor.id);
            }
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
        setShowRemovalModal(false);
    };

    return (
        <>
            <Form
                fields={defaultVMFields()}
                schema={VMSchemaLink}
                value={stagedVal}
                onChange={updateStagedValue}
                disabled={disabled}
            />
            <Form
                fields={defaultVMFields()}
                schema={VMSchemaNotes}
                value={stagedVal}
                onChange={updateStagedValue}
                disabled={disabled}
            />
            {!disabled && (
                <Bottom itemSpacing="smallish">
                    <AddButton
                        onClick={!selected && meetingHasSelectedVendor ? () => setShowConfirmationModal(true) : onApply}
                    >
                        {selected ? i18n.button.save : i18n.button.add}
                    </AddButton>
                    {selected && (
                        <RemoveButton warning onClick={() => setShowRemovalModal(true)}>
                            {i18n.button.remove}
                        </RemoveButton>
                    )}
                </Bottom>
            )}
            {showConfirmationModal && (
                <ConfirmationModal
                    isActive
                    prompt={i18n.virtual.addConfirmation}
                    onDismiss={() => setShowConfirmationModal(false)}
                    onProceed={onApply}
                    loading={savingSelected}
                />
            )}
            {showRemovalModal && (
                <ConfirmationModal
                    isActive
                    warning
                    prompt={i18n.virtual.removeConfirmation(vendor.name)}
                    ctaLabel={i18n.button.remove}
                    onDismiss={() => setShowRemovalModal(false)}
                    onProceed={onRemove}
                    loading={savingSelected}
                />
            )}
            {savingSelected && <SpinnerOverlay />}
        </>
    );
};

const CALENDAR_PAGE = 'planner', // TODO: Update this to match what the database stores for this new page once that is ready
    VM_SECTION = 'virtualMeetings';

export default function Vendor() {
    const { vendorId, eventId: paramEventId } = useParams<{ vendorId: string; eventId: string }>();
    const eventId = useMemo(() => paramEventId ?? '', [paramEventId]);
    const { loadedCatalog, loadingCatalog, loadingSelected } = useAddons();
    const vendor = useAddons(getAddon(vendorId));

    const { event, template, refreshEvent } = useEvent();

    const { user } = useUser();

    const teamNote = user.team?.virtualMeetingServiceProviders.find(
        provider => provider.id === vendor?.virtualMeetingServiceProvider?.id
    )?.notes;
    const selected = useAddons(isSelected(vendorId));
    const meetingHasSelectedVendor = useAddons(eventHasSelectedAddon());
    const initialFormData = useAddons(getSelectedMetaData(vendorId));

    const vmProTips = React.useMemo(
        () => filterProtips([...(user.team?.proTips ?? []), ...(template?.proTips ?? [])], CALENDAR_PAGE, VM_SECTION),
        [user.team, template]
    );

    if (!loadedCatalog) {
        return (
            <div>
                <LoadAddons eventId={eventId} />
                <SpinnerOverlay />
            </div>
        );
    }

    const preferredVendorIds =
        user.team?.virtualMeetingServiceProviders.filter(provider => provider.preferred).map(provider => provider.id) ??
        [];
    const showPreferredAddOnsOnly = user.team?.showPreferredAddOnsOnly || false;
    const isPreferred = preferredVendorIds.some(id => id === vendor?.virtualMeetingServiceProvider?.id);

    if (!vendor || (showPreferredAddOnsOnly && !isPreferred)) {
        return <PageNotFound eventId={+eventId} />;
    }

    return (
        <div>
            <LoadAddons eventId={eventId} />

            <PageHeadline>{vendor.name}</PageHeadline>
            <Spacer />
            <Row itemSpacing="large">
                <TileColumn itemSpacing="large">
                    <VendorTile
                        vendor={vendor}
                        team={user.team}
                        width="400px"
                        fixedRatio={(286 / 400) * 100 + '%'}
                        selected={loadingSelected ? false : !!selected}
                        noPopover
                    />
                    {vendor.description && <Copy>{vendor.description}</Copy>}
                    {vendor.linkUrl && (
                        <ExternalLink openInNewTab href={vendor.linkUrl}>
                            {vendor.linkUrl}
                        </ExternalLink>
                    )}
                    {teamNote && <ProTip message={teamNote} asNote />}
                </TileColumn>
                <Column>
                    <FormCard>
                        <FormContent itemSpacing="large">
                            <Column fillWidth>
                                <Row alignItems="center" justifyContent="space-between">
                                    <Headline>{i18n.virtual.addToMeeting}</Headline>
                                    <ProTipsDropdown protips={vmProTips} />
                                </Row>
                                <Copy>{i18n.virtual.shareDescription}</Copy>
                            </Column>
                            <SelectVendorForm
                                eventId={eventId}
                                vendor={vendor}
                                selected={selected}
                                meetingHasSelectedVendor={meetingHasSelectedVendor}
                                initialData={initialFormData}
                                onSave={() => refreshEvent()}
                                disabled={!event.editable}
                            />
                        </FormContent>
                    </FormCard>
                </Column>
            </Row>

            {(loadingCatalog || loadingSelected) && <SpinnerOverlay />}
        </div>
    );
}
