import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { TProposalForm } from 'components/ProposalForm/types';
import IataIcon from 'images/bizlyOS/icons/iataIcon.svg';
import { useState } from 'react';
import { EColors, getColor } from 'theme';
import { ExternalLink } from 'ui';
import { tzMoment } from 'utils/moment';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const BorderedBottomContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    paddingBottom: spacing(2.5),
    paddingTop: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const InfoContainer = styled(BorderedContainer)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: spacing(1.25),
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    border: 'none',
}));

const AgreementContainer = styled(BorderedContainer)(({ theme: { getColor, EColors, spacing } }) => ({
    padding: '10px',
    display: 'flex',
    gap: spacing(2.125),
    backgroundColor: getColor(EColors.bizlyOSPrimary),
    border: 'none',
    marginTop: spacing(1.25),
    color: getColor(EColors.pureWhite),
}));

const TextButton = styled(Box)(({ theme: { getColor, EColors } }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    color: getColor(EColors.bizlyOSPrimary),
    cursor: 'pointer',
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&::before': {
            display: 'none',
        },
        borderRadius: theme.shape.borderRadius,
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: getColor(EColors.pureBlack), fontSize: 22 }} />}
        {...props}
    />
))(({ theme }) => ({
    height: '64px',
    padding: theme.spacing(2.5),
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
    '& .MuiAccordionSummary-content > p': {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '24px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2.5),
    paddingTop: 0,
}));

function TextCard({
    title,
    description,
    borderLeft,
}: {
    title: string;
    description: string | undefined;
    borderLeft?: boolean;
}) {
    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            gap={0.5}
            sx={borderLeft ? { paddingLeft: '20px', borderLeft: '0.5px solid ' + getColor(EColors.bizlyOSBorder) } : {}}
        >
            <Typography variant="body2" fontWeight={500} lineHeight="1.313rem">
                {title}
            </Typography>
            <Typography variant="body2" lineHeight="1.313rem">
                {description || '-'}
            </Typography>
        </Box>
    );
}

function DateCard({ title, date, borderLeft }: { title: string; date: string; borderLeft?: boolean }) {
    const _date = tzMoment(date);

    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            gap={0.5}
            sx={borderLeft ? { paddingLeft: '20px', borderLeft: '0.5px solid ' + getColor(EColors.bizlyOSBorder) } : {}}
        >
            <Typography variant="body2" fontWeight={500} lineHeight="1.313rem">
                {title}
            </Typography>
            <Box display="flex" justifyContent="space-between" sx={borderLeft ? {} : { paddingRight: '20px' }}>
                <Typography variant="body2" lineHeight="1.313rem">
                    {_date.format('MMM D, YYYY')}
                </Typography>
                <Typography variant="body2" lineHeight="1.313rem">
                    {_date.format('LT')}
                </Typography>
            </Box>
        </Box>
    );
}

type Props = {
    proposalInquryForm: Partial<TProposalForm>;
};

export default function InquiryOverview({
    proposalInquryForm: { client, proposal, event, eventSpaces, inquiry },
}: Props) {
    const [expanded, setExpanded] = useState<string | false>('clientDetails');

    const handleChange = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const calcGuestsCount = () => {
        const sumOgGuests =
            eventSpaces?.reduce(function (accumulator, currentValue) {
                if (currentValue.requestedGuests) {
                    return accumulator + currentValue.requestedGuests;
                }
                return accumulator;
            }, 0) || 0;
        const eventSpacesCount = eventSpaces?.length || 0;

        return `${Math.ceil(sumOgGuests / eventSpacesCount)}`;
    };

    return (
        <Box display="flex" flexDirection="column" gap="10px">
            {proposal?.commissionable && (
                <InfoContainer>
                    <img src={IataIcon} alt="IATA Icon" />
                    <Box flex={1}>
                        <Typography variant="body1" fontWeight={600}>
                            Commissionable
                        </Typography>
                        <Typography variant="body1" fontWeight={600}>
                            IATA Number: <span style={{ fontWeight: '400' }}>33-7 4226 2</span>
                        </Typography>
                    </Box>
                    <TextButton>Terms</TextButton>
                </InfoContainer>
            )}

            <Accordion expanded={expanded === 'clientDetails'} onChange={handleChange('clientDetails')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Client Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BorderedContainer>
                        <Box display={'flex'}>
                            <TextCard title="Name" description={`${client?.firstName} ${client?.lastName}`} />
                            <TextCard title="Company" description={client?.companyName} borderLeft />
                        </Box>
                    </BorderedContainer>
                    {proposal?.msaDocumentUrl && (
                        <AgreementContainer>
                            <AttachFileOutlinedIcon sx={{ fontSize: 22 }} />
                            <Typography variant="body2" fontWeight={600} flex={1}>
                                Client Agreement
                            </Typography>
                            <ExternalLink href={proposal.msaDocumentUrl}>
                                <Typography variant="body2" color={getColor(EColors.pureWhite)} fontWeight={500}>
                                    View
                                </Typography>
                            </ExternalLink>
                        </AgreementContainer>
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'eventOverview'} onChange={handleChange('eventOverview')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Event Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BorderedContainer>
                        <BorderedBottomContainer sx={{ paddingTop: 0 }}>
                            <TextCard title="Title" description={event?.name} />
                        </BorderedBottomContainer>
                        <BorderedBottomContainer>
                            <TextCard title="No. of Guests" description={calcGuestsCount()} />
                        </BorderedBottomContainer>
                        <BorderedBottomContainer>
                            <DateCard title="Start Date" date={event?.startsAt || ''} />
                            <DateCard title="End Date" date={event?.endsAt || ''} borderLeft />
                        </BorderedBottomContainer>
                        <BorderedBottomContainer>
                            {!!proposal?.totals?.guestRoomNight && (
                                <TextCard title="Room Nights" description={`${proposal?.totals?.guestRoomNight}`} />
                            )}
                            {!!eventSpaces?.length && (
                                <TextCard
                                    title="Meeting/Event Spaces"
                                    description={`${eventSpaces?.length || 0}`}
                                    borderLeft={!!proposal?.totals?.guestRoomNight}
                                />
                            )}
                        </BorderedBottomContainer>
                    </BorderedContainer>
                </AccordionDetails>
            </Accordion>
            {inquiry?.notes && (
                <Accordion expanded={expanded === 'inquiryNotes'} onChange={handleChange('inquiryNotes')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Inquiry Notes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BorderedContainer>
                            <Typography variant="body2" lineHeight="1.313rem">
                                {inquiry?.notes || '-'}
                            </Typography>
                        </BorderedContainer>
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
}
