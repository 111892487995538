import Form from 'components/Form';
import CategoriesField from 'components/Templates/CategoriesField';
import usePersistentSnackbar from 'hooks/usePersistentSnackbar';
import React from 'react';
import Errors from './components/Errors';
import { createPlaybookActions, useCreatePlaybook } from './store';

const fields = {
    tags: {
        type: CategoriesField,
        prompt: 'Tags',
        options: {
            perRow: 2,
            circleIcon: true,
        },
    },
};

const schema = [
    {
        fields: ['tags'],
        spacing: false,
    },
];

export type TTagsValue = number[];

export default function TagsForm() {
    const { tags, tagsErrors, stepIdx } = useCreatePlaybook();

    const enqueueSnackbar = usePersistentSnackbar([tagsErrors, stepIdx]);
    React.useEffect(() => {
        const hasErrors = Object.values(tagsErrors).some(v => v);
        if (hasErrors) {
            enqueueSnackbar(<Errors errors={tagsErrors} />, { variant: 'error' });
        }
    }, [tagsErrors, enqueueSnackbar]);

    return <Form fields={fields} schema={schema} value={{ tags }} onChange={createPlaybookActions.updateTags} />;
}
