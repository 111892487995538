import { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { useState } from 'react';

export const useTrackAutocompleteFocus = ({
    initialFocus = false,
    onFocus: onFocusProp,
    onBlur: onBlurProp,
}: {
    initialFocus?: boolean;
} & Pick<AutocompleteProps, 'onFocus' | 'onBlur'>) => {
    const [focus, setFocus] = useState(initialFocus);
    return {
        focus,
        onFocus: e => {
            setFocus(true);
            onFocusProp?.(e);
        },
        onBlur: e => {
            setFocus(false);
            onBlurProp?.(e);
        },
    } as {
        focus: boolean;
    } & Pick<AutocompleteProps, 'onFocus' | 'onBlur'>;
};

export const useTrackAndSimplifyAutocompleteInputChange = ({
    initialInput,
    onInputChange: onInputChangeProp,
}: {
    initialInput?: string;
    onInputChange?: (val: string) => void;
}) => {
    const [input, setInput] = useState(initialInput);
    return {
        input,
        onInputChange: (e, val, reason) => {
            const newVal = reason === 'reset' ? undefined : val;
            setInput(newVal);
            onInputChangeProp?.(newVal);
        },
    } as {
        input?: string;
    } & Pick<AutocompleteProps, 'onInputChange'>;
};
