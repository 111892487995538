import MUIAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { EColors } from 'types/themenum';
import UIArrowIcon from '../../../images/icons/down-arrow.svg?react';

const Panel = styled(MUIAccordion)<{ bgColor?: EColors }>`
    &.MuiAccordion-root {
        border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
        border-radius: 4px;
        box-shadow: none;
        overflow: hidden;
        background-color: ${({ bgColor, theme: { getColor } }) =>
            bgColor ? getColor(bgColor) : getColor(EColors.softAccentedBackground)};
    }

    &::before {
        display: none;
    }

    &:last-child {
        border-radius: 4px;
    }
`;

const PanelSummary = styled(AccordionSummary)<{ bgColor?: EColors }>`
    background-color: ${({ bgColor, theme: { getColor } }) => (bgColor ? getColor(bgColor) : 'transparent')};

    &.Mui-focused {
        background-color: transparent;
    }

    &.MuiAccordionSummary-root {
        padding: 0 1.5rem;
    }
`;

const PanelDetails = styled(AccordionDetails)<{ hasBorder: boolean; bgColor?: EColors; noPadding?: boolean }>`
    border-top: solid
        ${({ hasBorder, theme: { getColor, EColors } }) => (hasBorder ? `1px ${getColor(EColors.lightGrey)}` : `0px`)};

    &.MuiAccordionActions-root {
        padding: ${({ noPadding }) => (noPadding ? 0 : '1.5rem')};
    }
`;

const ArrowIcon = styled(withInteractibleIconStyles(UIArrowIcon))`
    height: 15px;
    width: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

type TAccordion = {
    identity: string;
    classNames?: string;
    expanded?: boolean;
    summaryColor?: EColors;
    summaryContent?: any;
    hasBorder?: boolean;
    detailContent?: any;
    handleChange: (identity: string) => void;
    detailColor?: EColors;
    noDetailPadding?: boolean;
    elevation?: number;
};

const Accordion = ({
    identity,
    classNames,
    expanded,
    summaryColor,
    summaryContent,
    hasBorder = true,
    detailContent,
    handleChange,
    detailColor,
    noDetailPadding,
    elevation = 1,
}: TAccordion) => {
    return (
        <Panel
            className={`accordion ${classNames}`}
            expanded={expanded}
            onChange={() => handleChange(identity)}
            elevation={elevation}
            {...(detailColor ? { bgColor: detailColor } : {})}
        >
            <PanelSummary
                aria-label={`${identity}-expand`}
                aria-controls={`${identity}-content`}
                id={`${identity}-header`}
                expandIcon={<ArrowIcon />}
                {...(summaryColor ? { bgColor: summaryColor } : {})}
            >
                {summaryContent}
            </PanelSummary>
            <PanelDetails
                hasBorder={hasBorder}
                {...(detailColor ? { bgColor: detailColor } : {})}
                noPadding={noDetailPadding}
            >
                {detailContent}
            </PanelDetails>
        </Panel>
    );
};

export default Accordion;
