import { useSnackbar } from 'notistack';
import React from 'react';
import { playbooksActions } from 'stores/playbooks';
import { i18n } from 'translation';

export default function LoadPlaybookOptions() {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await playbooksActions.loadPlaybookOptions();
            } catch (e) {
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            }
        };
        load();
    }, [enqueueSnackbar]);

    return null;
}
