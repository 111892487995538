import { Typography, styled } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import { H5Headline } from 'pages/Planner/styles';

interface HeadlineProps extends TypographyProps {
    thin?: boolean;
    large?: boolean;
    small?: boolean;
    color?: string;
}

export const Headline = styled(Typography, {
    shouldForwardProp: prop => prop !== 'thin' && prop !== 'large' && prop !== 'small' && prop !== 'color',
})<HeadlineProps>(({ theme, thin, large, small, color }) => ({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    fontWeight: thin ? 400 : 600,
    fontSize: large ? '1.5em' : small ? '1em' : '1.25em',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    marginBlockStart: '0.5em',
    marginBlockEnd: '0.5em',
    color: color || theme.palette.text.primary,
}));

interface PageHeadlineProps extends HeadlineProps {
    withDescription?: boolean;
}

export const PageHeadline = styled(H5Headline)<PageHeadlineProps>(({ withDescription, bold }) => ({
    fontSize: '2rem',
    fontWeight: bold ? 500 : 400,
    marginBottom: withDescription ? '0' : '2rem',
}));

interface H2HeadlineProps extends TypographyProps {
    color?: string;
}

export const H2Headline = styled(Typography)<H2HeadlineProps>(({ theme, color }) => ({
    fontSize: '1.375rem;',
    fontWeight: '500',
    lineHeight: 1.28,
    letterSpacing: '-0.5px',
    margin: 0,
    color: color || theme.palette.text.primary,
}));
