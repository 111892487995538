import Dialog from '@material-ui/core/Dialog';
import { Headline } from 'components/ui/Headline';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, Row, Spacer } from 'ui';
import { SpinnerOverlay } from './Spinner';
import TextButton from './ui/Button/TextButton';

const Modal = styled(Column)`
    width: ${({ wide }: { wide?: boolean }) => (wide ? '480' : '400')}px;
    padding: 24px;
`;

const WarningHeadline = styled(Headline)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.warningText)};
    text-transform: none;
`;

const EndRow = styled(Row)`
    justify-content: flex-end;
`;

export type TDeleteConfirmationModal = {
    isActive?: boolean;
    wide?: boolean;

    onDismiss: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
        reason?: 'backdropClick' | 'escapeKeyDown'
    ) => any;
    onProceed: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;

    title?: React.ReactNode;
    prompt?: React.ReactNode;
    ctaLabel?: React.ReactNode;
    dismissLabel?: React.ReactNode;

    loading?: boolean;
};

export const DeleteConfirmationModal = ({
    isActive,
    onDismiss,
    onProceed,
    title = i18n.modal.areYouSure,
    prompt = '',
    ctaLabel = i18n.button.delete,
    dismissLabel = i18n.button.cancel,
    loading,
    wide,
}: TDeleteConfirmationModal) => {
    return (
        <Dialog open={!!isActive} onClose={onDismiss}>
            <Modal wide={wide}>
                <WarningHeadline>{title}</WarningHeadline>
                <Spacer />
                <Copy>{prompt}</Copy>
                <Spacer largest />
                <EndRow itemSpacing="smallish">
                    <TextButton secondary onClick={onDismiss}>
                        {dismissLabel}
                    </TextButton>
                    <TextButton warning onClick={onProceed}>
                        {ctaLabel}
                    </TextButton>
                </EndRow>
                {loading && <SpinnerOverlay />}
            </Modal>
        </Dialog>
    );
};

export default DeleteConfirmationModal;
