import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled as muiStyled } from '@mui/styles';
import { Card } from 'components/Ui-V2/Card/Card';
import Image from 'components/Ui-V2/Image/Image';
import { StatusPill } from 'components/Ui-V2/StatusPill/StatusPill';
import useThemedColor from 'hooks/useThemedColor';
import TemplatePlaceholderImage from 'images/placeholders/template-placeholder.png';
import { useUser } from 'providers/user';
import { useNavigate } from 'react-router-dom';
import { i18n } from 'translation';
import { TemplateActionPopper } from './TemplateActionPopper';

const StyledCreatorInfo = muiStyled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
});

const StyledCardContent = muiStyled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    height: '6.25rem',
    '&:last-child': {
        padding: '0 1rem 1rem',
    },
});

const TemplateCard = ({ template }: { template: Bizly.EventTemplate }) => {
    const { user } = useUser();
    const { teamTemplatePill } = useThemedColor();
    const navigate = useNavigate();

    const handleTemplateEventView = (templateId: number) => {
        navigate(`/playbooks/${templateId}`);
    };

    return (
        <Card>
            <Box
                sx={{ position: 'relative', padding: '.5rem', cursor: 'pointer' }}
                onClick={() => handleTemplateEventView(template.id)}
            >
                <Image
                    src={template?.briefImageSmall}
                    alt={template.name || 'Template image'}
                    width="100%"
                    height="12.5rem"
                    style={{ objectFit: 'cover', borderRadius: '.25rem' }}
                    placeholderSrc={TemplatePlaceholderImage}
                />
            </Box>
            <StyledCardContent>
                <Typography sx={{ fontWeight: 500 }}>{template.name}</Typography>
                <StyledCreatorInfo>
                    {template?.teamId && (
                        <StatusPill teamTemplatePill={teamTemplatePill} sx={{ cursor: 'default' }}>
                            {i18n.playbooks.playbookForTag(user?.team?.name || 'Team')}
                        </StatusPill>
                    )}
                    <Box marginLeft="auto">
                        <TemplateActionPopper template={template} />
                    </Box>
                </StyledCreatorInfo>
            </StyledCardContent>
        </Card>
    );
};

export const TemplateGrid = ({ templates }: { templates: Bizly.EventTemplate[] }) => {
    return (
        <Grid container spacing={2} sx={{ paddingRight: '4rem' }}>
            {templates.map((template: Bizly.EventTemplate) => (
                <Grid item md={4} lg={3} key={template.id}>
                    <TemplateCard template={template} />
                </Grid>
            ))}
        </Grid>
    );
};
