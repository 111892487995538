import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { addonActions } from './store';

export default function LoadAddons({ eventId }: { eventId: string | number }) {
    const { enqueueSnackbar } = useSnackbar();
    React.useEffect(() => {
        try {
            addonActions.loadEverything(eventId);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    }, [enqueueSnackbar, eventId]);

    return null;
}
