import { styled } from '@mui/material/styles';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

export const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(({ theme }) => ({
    backgroundColor: theme.getColor(theme.EColors.pureWhite),
    border: '1px solid ' + theme.getColor(theme.EColors.bizlyOSBorder),
    width: '300px',
    padding: '2px 10px',
    borderRadius: 6,
    '& .MuiInputBase-root': {
        fontSize: '0.8rem',
        content: 'none',
    },
    '& .MuiInputBase-root:after': {
        content: 'none',
    },
    '& .MuiInputBase-root:before': {
        content: 'none',
    },
}));
