import { QueryParamEnum } from 'constants/queryParams';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useVenueDialog = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openVenueDialog, setOpenVenueDialog] = useState<boolean>(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const venueId = params.get(QueryParamEnum.VENUE_ID);

        if (venueId) {
            setOpenVenueDialog(true);
        } else {
            setOpenVenueDialog(false);
        }
    }, [location.search]);

    const handleClose = () => {
        const params = new URLSearchParams(location.search);
        params.delete(QueryParamEnum.VENUE_ID);

        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });

        setOpenVenueDialog(false);
    };

    const viewVenueListingInPopup = (venueId: number) => {
        const params = new URLSearchParams(location.search);
        params.set(QueryParamEnum.VENUE_ID, venueId.toString());
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
        setOpenVenueDialog(true);
    };

    return {
        openVenueDialog,
        handleClose,
        viewVenueListingInPopup,
    };
};
