import { useCallback, useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { uploadFile } from 'cloudinary';
import Box, { BoxProps } from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { SingleFieldForm } from 'components/Form';
import { LanguageSelector } from 'components/LanguageSelector';
import { useUser } from 'providers/user';
import { DEPARTMENT_OPTIONS, ROLE_OPTIONS } from 'shared';
import { nudgesActions } from 'stores/nudges';
import { i18n } from 'translation';
import DownArrow from 'images/icons/down-arrow.svg?react';
import { Column, Spacer } from 'ui';
import { Dropdown, UncasedHeadline } from './ui';
import ProfileImage from 'images/authentication/complete-profile.jpg';
import SignInImage from 'images/authentication/sign-in.jpeg';
import ManageAvatar from 'components/Profile/ManageAvatar';
import { EColors, getColor } from 'theme';
import styled from 'styled-components';

interface RightSectionProps extends BoxProps {
    sectionImage: string;
}

// Styled components
const RootContainer = styled(Box)({
    display: 'flex',
    height: '100vh',
});

const LeftSection = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    gap: theme.spacing(4),
    padding: theme.spacing(8),
    maxWidth: '37.5rem',
    backgroundColor: getColor(EColors.pureWhite),
}));

const RightSection = styled(Box)<RightSectionProps>(({ sectionImage }) => ({
    flex: 1,
    backgroundImage: `url(${sectionImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}));

const TopRight = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(1, 0),
    fontSize: '1em',
    lineHeight: '1.25em',
    color: theme.palette.text.black,
    fontWeight: 500,
}));

const EmptyRoleText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.disabled,
}));

const StyledTextField = styled(TextField)`
    margin: 0.5rem 0;

    .MuiInputBase-fullWidth {
        padding: 0.5em 1em;
    }
    
    .MuiInputBase-formControl {
        height: 52px;
    }
`;

const Container = styled(Column)`
    width: 37.5rem;
    position: relative;
    border-radius: 4px;
    padding: 1.25rem;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border: 0.031rem solid #D6D6D5;
    gap: 1.25rem;
`;

const CenteredColumn = styled(Column)`
    height: 100%;
    box-sizing: border-box;
    justify-content: flex-start;
    margin: 0 auto;
`;

const LogoImage = styled.img`
    width: fit-content;
    height: 2.313rem;
    margin-bottom: 2em;
`;

const FieldOptionalLabel = styled.span`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.optionalSpecifier)};
`;

export default function ProfileForm() {
    const { user, updateUser } = useUser();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const isValidPhoneNumber = (phone: any) => /^\d{4,}$/.test(phone);
    const [stagedImage, setStagedImage] = useState<File | null>(null);
    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [phone, setPhone] = useState(
        user.phone && isValidPhoneNumber(user.phone) ? user.phone : ''
    );
    const [role, setRole] = useState(user.role || '');
    const [title, setTitle] = useState(user.title || '');
    const [toHomePage, setToHomePage] = useState(false);
    const sectionImage = user.isVenueUser ? ProfileImage : SignInImage;

    const handlePhoneChange = (event: any) => {
        const value = event;
        const numericValue = value.replace(/\D/g, '');
      
        if (numericValue.length > 3) {
            setPhone(numericValue);
        } else {
            setPhone('');
        }
    };
    const handleSubmit = useCallback(async () => {
        try {
            let newImageUrl;
            if (stagedImage) {
                const uploadedImage = await uploadFile(stagedImage);
                newImageUrl = uploadedImage.url;
            }
            const imageUrl = newImageUrl || user.imageUrl;
            await updateUser({ firstName, lastName, phone, role, title, imageUrl });
            nudgesActions.mergeNudges({ showFirstTimeIntegrations: false });
            setToHomePage(true);
        } catch (e) {
            enqueueSnackbar(i18n.userProfile.errorSavingProfile, {
                variant: 'error',
            });
        }
    }, [firstName, lastName, phone, role, title, stagedImage, user, updateUser, enqueueSnackbar]);

    const renderRoleOptions = () => {
        const options = user?.isVenueUser ? DEPARTMENT_OPTIONS : ROLE_OPTIONS;

        return options.map(role => (
            <MenuItem key={role.id} value={role.id}>
                {role.name}
            </MenuItem>
        ));
    };

    if (toHomePage) {
        return <Navigate to={location.state?.redirectTo ?? '/'} />;
    }

    return (
        <RootContainer>
            <LeftSection>
                <TopRight>
                    {import.meta.env.VITE_APP_LOCALIZATION === 'true' && <LanguageSelector />}
                </TopRight>
                <CenteredColumn>
                    <LogoImage src={user.team?.imageUrl ? user.team?.imageUrl : ''} />
                    <Container itemSpacing="medium">
                        <UncasedHeadline fontSize={25} fontWeight={600}>
                            {user.team?.name
                                ? i18n.userProfile.completeYourProfile(user.team.name)
                                : i18n.userProfile.setUpYourProfile}
                        </UncasedHeadline>
                        <Box component="form" onSubmit={(event) => {
                            event.preventDefault();
                            handleSubmit();
                        }}>
                            <Box>
                                {/* <ManageAvatar imageUrl={user.imageUrl} setStagedImage={setStagedImage} />
                                <Spacer medium /> */}
                                <StyledLabel id="role-select-label">{i18n.authFields.firstName} <FieldOptionalLabel>(required)</FieldOptionalLabel></StyledLabel>
                                <StyledTextField
                                    fullWidth
                                    type="text"
                                    placeholder={i18n.authFields.placeholder(i18n.authFields.firstName)}
                                    onChange={e => setFirstName(e.currentTarget.value)}
                                    value={firstName}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <StyledLabel id="role-select-label">{i18n.authFields.lastName} <FieldOptionalLabel>(required)</FieldOptionalLabel></StyledLabel>
                                <StyledTextField
                                    fullWidth
                                    type="text"
                                    placeholder={i18n.authFields.placeholder(i18n.authFields.lastName)}
                                    onChange={e => setLastName(e.currentTarget.value)}
                                    value={lastName}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <Spacer small />
                                <SingleFieldForm
                                    field={{
                                        type: 'phone',
                                        prompt: `${i18n.authFields.phoneNumber}`,
                                        options: {},
                                    }}
                                    value={phone || ''}
                                    onChange={handlePhoneChange}
                                />

                                <StyledLabel id="role-select-label">{i18n.authFields.jobTitle} <FieldOptionalLabel>(required)</FieldOptionalLabel></StyledLabel>
                                <StyledTextField
                                    fullWidth
                                    type="text"
                                    placeholder={i18n.authFields.placeholder(i18n.authFields.jobTitle)}
                                    onChange={e => setTitle(e.currentTarget.value)}
                                    value={title}
                                    margin="normal"
                                    variant="outlined"
                                />

                                <StyledLabel id="role-select-label">{i18n.authFields.department}</StyledLabel>
                                <Dropdown
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={role}
                                    onChange={e => setRole(e.target.value as string)}
                                    IconComponent={DownArrow}
                                    displayEmpty
                                    renderValue={value =>
                                        value ? (
                                            <Typography>{value as string}</Typography>
                                        ) : (
                                            <EmptyRoleText>{i18n.userProfile.select}</EmptyRoleText>
                                        )
                                    }
                                    style={{ height: '52px' }}
                                >
                                    {renderRoleOptions()}
                                </Dropdown>
                                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={(!firstName || !lastName || !role || !phone || !title)}
                                        size='large'
                                    >
                                        {i18n.button.save}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </CenteredColumn>
            </LeftSection>
            <RightSection sectionImage={sectionImage}>
                {/* Add any background or additional content if needed */}
            </RightSection>
        </RootContainer>
    );
}
