import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TSpacePOST } from 'api';
import colorFns from 'colorFns';
import { SpinnerOverlay } from 'components/Spinner';
import Button from 'components/ui/Button';
import { Headline } from 'components/ui/Headline';
import useUnsavedPrompt from 'hooks/useUnsavedPrompt';
import ForwardArrowSVG from 'images/icons/forward_arrow.svg?react';
import BizlyLogo from 'images/logo.svg?react';
import { useSnackbar } from 'notistack';
import { parse } from 'query-string';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { withIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, Row, Spacer } from 'ui';
import { enableTurnDown, takeFirstIfArray } from 'utils';
import ContactInformationForm from './ContactInformationForm';
import EventSpacesForm from './EventSpacesForm';
import { TSpace } from './EventSpacesForm/utils';
import FooterButtons from './FooterButtons';
import GuestroomsForm from './GuestroomsForm';
import { IntroHeader, ProposalDetailsHeader } from './MainFormHeaders';
import ProgressTracker from './ProgressTracker';
import ProposalFormStatus from './ProposalFormStatus';
import ProposalNotesForm from './ProposalNotesForm';
import RejectionReasonForm from './RejectionReasonForm';
import { TAction, getProposalFormStatus, getStatusAttributes } from './statusUtils';
import { FORM_SECTIONS, TProposal, TProposalForm, TProposalFormUpdate } from './types';
import {
    WIDE_PAGE_WIDTH,
    contentPadding,
    sidePadding,
    sideWidth,
    useConfirmModal,
    useFormSectionsHistory,
    useFormValidator,
} from './utils';

const SECTIONS = [
    FORM_SECTIONS.CONTACT,
    FORM_SECTIONS.GUEST_ROOMS,
    FORM_SECTIONS.EVENT_SPACES,
    FORM_SECTIONS.NOTES,
    FORM_SECTIONS.PROPOSAL_STATUS,
];
const PROGRESS_SECTIONS = SECTIONS.slice(0, -1);

const SECTION_TO_COMPONENT = {
    [FORM_SECTIONS.CONTACT]: ContactInformationForm,
    [FORM_SECTIONS.GUEST_ROOMS]: GuestroomsForm,
    [FORM_SECTIONS.EVENT_SPACES]: EventSpacesForm,
    [FORM_SECTIONS.NOTES]: ProposalNotesForm,
    [FORM_SECTIONS.PROPOSAL_STATUS]: ProposalFormStatus,
};

type TFormManager = {
    proposalForm: Partial<TProposalForm>;
    onSave: (newData: Partial<TProposalFormUpdate>) => Promise<boolean>;
    onSubmit: () => Promise<boolean>;
    onReject: () => Promise<boolean>;
    onCancel: () => Promise<boolean>;
    onRejectExtension: () => Promise<boolean>;
    onUpdateVenueSpaces: (space: TSpacePOST) => Promise<TSpace[] | false>;
    inquiryRejectionReasons: BizlyAPI.InquiryRejectionReason[];
    rejectionValues?: { reason: number; note: string };
    handleRejectionValueChange: ({ value }: { value: { reason: number; note: string } }) => void;
};

const Shell = styled.div<{ vertical: boolean }>`
    display: flex;
    flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
`;

const Side = styled.div`
    width: ${sideWidth}px;
    /* TODO: Revamping this page, will change these colors to match */
    /* color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)}; */
    /* background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)}; */
    color: white;
    background-color: black;
    padding: ${sidePadding}px;
    box-sizing: border-box;
    z-index: 100;
`;

const Top = styled(Row)`
    width: 100%;
    align-items: center;
    /* 
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    */
    color: white;
    background-color: black;
    padding: ${sidePadding}px;
    box-sizing: border-box;
    z-index: 100;
`;

const Banner = styled(Row)`
    padding: 8px 0;
    ${({ ...props }) => `
    background: ${colorFns.strongAccentedBackground(props)};
    color: ${colorFns.pureWhite(props)};`}
    align-items: center;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    box-sizing: border-box;
`;

const Main = styled(Column)`
    position: relative;
    flex: 1;
    align-items: center;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const PaddedColumn = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: ${contentPadding}px;
    padding-bottom: 6rem;
    min-height: 100vh;
    box-sizing: border-box;
    width: 100%;
`;

const ForwardArrow = styled(withIconStyles(ForwardArrowSVG))`
    height: 16px;
    width: 16px;
    padding-top: 4px;
    color: ${colorFns.pureWhite};
`;

const StickyWrapper = styled(Column)`
    position: sticky;
    top: ${sidePadding}px;
    max-height: calc(100vh - ${sidePadding * 2}px);
    overflow: auto;
`;

const FixedSpinnerOverlay = styled(SpinnerOverlay)`
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
`;

const FormColumn = styled(Column)`
    flex-grow: 1;
    max-width: 1080px;
`;

const SECTION_TO_LABEL: Record<FORM_SECTIONS, string> = {
    [FORM_SECTIONS.CONTACT]: i18n.proposalForm.sections.contactInformation,
    [FORM_SECTIONS.GUEST_ROOMS]: i18n.proposalForm.sections.guestRooms,
    [FORM_SECTIONS.EVENT_SPACES]: i18n.proposalForm.sections.meetingSpaces,
    [FORM_SECTIONS.NOTES]: i18n.proposalForm.sections.finishingTouches,
    [FORM_SECTIONS.PROPOSAL_STATUS]: i18n.proposalForm.sections.proposalStatus,
};

export default function FormManager({
    proposalForm = {},
    onSave: onSaveProp,
    onSubmit: onSubmitProp,
    onUpdateVenueSpaces,
    onReject,
    onCancel,
    onRejectExtension,
    inquiryRejectionReasons,
    rejectionValues,
    handleRejectionValueChange,
}: Readonly<TFormManager>) {
    const [loading, setLoading] = React.useState(false);
    const [hasSaved, setHasSaved] = React.useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const [userChangedStatus, setUserChangedStatus] = React.useState(false);

    const action = takeFirstIfArray(parse(useLocation().search)).action || '';
    const userAction = (action as TAction) || 'accept';

    const status = getProposalFormStatus(proposalForm);
    const statusAttributes = getStatusAttributes(status, userAction, userChangedStatus);
    const pendingExtensionRequest =
        proposalForm &&
        !!proposalForm.proposal?.extensionRequestedAt &&
        !proposalForm.proposal.extensionRejectedAt &&
        statusAttributes.canSubmit;

    const {
        sectionIndex,
        goBack: onBack,
        nextSection: onContinueProp,
        restart: onRestartProp,
        goToDateStep,
        goToEnd,
    } = useFormSectionsHistory(
        SECTIONS,
        userAction === 'accept-extension' && pendingExtensionRequest ? 3 : statusAttributes.skipToStatus ? 4 : 0
    );
    const section = SECTIONS[sectionIndex];

    const mainRef = useRef<HTMLDivElement>(null);

    const scrollToTop = () => {
        if (mainRef.current) {
            mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const FormSection = SECTION_TO_COMPONENT[section];
    const onSectionChange = React.useCallback(() => setHasSaved(false), [setHasSaved]);

    const [headerProposalData, setHeaderProposalData] = React.useState<Partial<TProposal>>();
    const onHeaderChange = React.useCallback(
        (newProposalData: Partial<TProposal>) => {
            setHasSaved(false);
            setHeaderProposalData(newProposalData);
        },
        [setHeaderProposalData]
    );

    React.useEffect(() => {
        setHasSaved(true);
        setHeaderProposalData(undefined);
    }, [FormSection, setHeaderProposalData]);

    const renderPrompt = useUnsavedPrompt(!hasSaved);

    const { validationFn, registerValidator } = useFormValidator(section);

    const onSave = React.useCallback(
        async (isContinue?: boolean) => {
            const skipSaving = statusAttributes.readonly;
            if (skipSaving) return true;

            let newData = validationFn(isContinue);
            if (!newData) return false;
            if (headerProposalData) newData = { ...newData, proposal: { ...newData.proposal, ...headerProposalData } };

            if (!hasSaved || !isContinue) {
                setLoading(true);
                const success = await onSaveProp(newData);
                if (success) {
                    setHasSaved(true);
                }
                if (!isContinue) setLoading(false);
                return success;
            }

            return true;
        },
        [validationFn, hasSaved, onSaveProp, headerProposalData, statusAttributes.readonly]
    );

    const buttonOnSave = React.useCallback(() => onSave(), [onSave]);

    const onContinue = React.useCallback(async () => {
        const saved = await onSave(true);
        setLoading(false);

        if (saved) {
            onContinueProp();
            scrollToTop();
        }
    }, [onSave, onContinueProp]);

    const onSubmit = React.useCallback(async () => {
        const saved = await onSave(true);
        if (saved) {
            setLoading(true);
            const submitted = await onSubmitProp();
            setLoading(false);

            if (submitted) onContinueProp();
        }
    }, [onSave, onSubmitProp, onContinueProp]);

    const onSuccess = React.useCallback(() => {
        goToEnd();
        setUserChangedStatus(true);
    }, [goToEnd, setUserChangedStatus]);

    const rejectModalProps = React.useMemo(
        () => ({
            showOnLoad: userAction === 'reject' && status === 'Inquiry Submitted',
            prompt: enableTurnDown ? (
                <>
                    <Copy>{i18n.proposalForm.modal.rejectInquiryTitle}</Copy>
                    <Spacer small />
                    <RejectionReasonForm
                        onChange={handleRejectionValueChange}
                        value={rejectionValues}
                        options={inquiryRejectionReasons}
                    />
                </>
            ) : (
                i18n.proposalForm.modal.rejectInquiryTitle
            ),
            onConfirm: () => {
                return new Promise<boolean>(resolve => {
                    const reason = rejectionValues?.reason;

                    if ((reason === 1 || reason === 2 || reason === 4) && !rejectionValues?.note) {
                        enqueueSnackbar(i18n.proposalForm.modal.notesRequired, {
                            variant: 'error',
                        });
                        resolve(false);
                    } else {
                        onReject().then(result => {
                            resolve(result);
                        });
                    }
                });
            },
            onDismiss: onRestartProp,
            onSuccess,
        }),
        [
            userAction,
            status,
            handleRejectionValueChange,
            rejectionValues,
            inquiryRejectionReasons,
            onReject,
            onRestartProp,
            onSuccess,
            enqueueSnackbar,
        ]
    );

    const {
        renderModal: renderRejectModal,
        loading: rejectLoading,
        openModal: showRejectModal,
    } = useConfirmModal(rejectModalProps);

    const extensionRequestModalProps = React.useMemo(
        () => ({
            showOnLoad: userAction === 'reject-extension' && status === 'Proposal Submitted' && pendingExtensionRequest,
            prompt: i18n.proposalForm.modal.rejectExtensionTitle,
            onConfirm: onRejectExtension,
            onDismiss: () => {},
            onSuccess,
        }),
        [onRejectExtension, onSuccess, status, userAction, pendingExtensionRequest]
    );

    const {
        renderModal: renderRejectExtensionModal,
        loading: rejectExtensionLoading,
        openModal: showRejectExtensionModal,
    } = useConfirmModal(extensionRequestModalProps);

    const cancelModalProps = React.useMemo(
        () => ({
            showOnLoad: userAction === 'reject' && status === 'Proposal Submitted',
            prompt: i18n.proposalForm.modal.cancelProposalTitle,
            onConfirm: onCancel,
            onDismiss: goToEnd,
            onSuccess,
        }),
        [userAction, status, onCancel, goToEnd, onSuccess]
    );

    const {
        renderModal: renderCancelModal,
        loading: cancelLoading,
        openModal: showCancelModal,
    } = useConfirmModal(cancelModalProps);

    const loadingAsync = loading || rejectLoading || rejectExtensionLoading || cancelLoading;

    const isWidthConstrained = useMediaQuery(`(max-width: ${WIDE_PAGE_WIDTH}px)`);

    return (
        <Shell vertical={isWidthConstrained}>
            {isWidthConstrained ? (
                <Top>
                    <BizlyLogo />
                    <Column alignItems="center" justifyContent="center" fillWidth>
                        {proposalForm.venue?.name && <Headline>{proposalForm.venue.name}</Headline>}
                        {!(statusAttributes.noProposal && section === FORM_SECTIONS.PROPOSAL_STATUS) && (
                            <ProgressTracker
                                sections={PROGRESS_SECTIONS.map(section => ({
                                    key: section,
                                    label: SECTION_TO_LABEL[section],
                                }))}
                                currentSection={sectionIndex}
                                horizontal
                            />
                        )}
                    </Column>
                </Top>
            ) : (
                <Side>
                    <IntroHeader {...proposalForm} />
                    <StickyWrapper>
                        {!(statusAttributes.noProposal && section === FORM_SECTIONS.PROPOSAL_STATUS) && (
                            <ProgressTracker
                                sections={PROGRESS_SECTIONS.map(section => ({
                                    key: section,
                                    label: SECTION_TO_LABEL[section],
                                }))}
                                currentSection={sectionIndex}
                            />
                        )}
                    </StickyWrapper>
                </Side>
            )}
            {renderPrompt()}
            <Main ref={mainRef}>
                {pendingExtensionRequest && status === 'Proposal Submitted' && (
                    <Banner justifyContent="center" itemSpacing="small" withBorderPadding="small">
                        <Copy large>{i18n.proposalForm.banner.extensionRequestCopy}</Copy>
                        <Button width={200} onClick={goToDateStep}>
                            <Row justifyContent="center" itemSpacing="small" alignItems="center">
                                <Copy>{i18n.proposalForm.banner.editExpirationDate}</Copy>
                                <ForwardArrow />
                            </Row>
                        </Button>
                        <Button onClick={showRejectExtensionModal} warning width={130}>
                            {i18n.proposalForm.banner.rejectRequest}
                        </Button>
                    </Banner>
                )}
                <PaddedColumn>
                    <ProposalDetailsHeader
                        {...proposalForm}
                        data={headerProposalData}
                        onChange={onHeaderChange}
                        disabled={statusAttributes.readonly || section === FORM_SECTIONS.PROPOSAL_STATUS}
                    />

                    <FormColumn>
                        {FormSection && (
                            <FormSection
                                {...proposalForm}
                                onUpdateVenueSpaces={onUpdateVenueSpaces}
                                onChange={onSectionChange}
                                registerValidator={registerValidator}
                                action={userAction}
                                userChangedStatus={userChangedStatus}
                                disabled={statusAttributes.readonly}
                            />
                        )}
                    </FormColumn>
                </PaddedColumn>
                {loadingAsync && <FixedSpinnerOverlay />}

                <FooterButtons
                    onBack={onBack}
                    onSave={buttonOnSave}
                    onContinue={onContinue}
                    onSubmit={onSubmit}
                    onCancel={showCancelModal}
                    onRestart={onRestartProp}
                    onReject={showRejectModal}
                    disabled={loadingAsync}
                    isSubmitStep={section === FORM_SECTIONS.NOTES}
                    isStatusStep={section === FORM_SECTIONS.PROPOSAL_STATUS}
                    statusAttributes={statusAttributes}
                />

                {renderRejectModal()}
                {renderRejectExtensionModal()}
                {renderCancelModal()}
            </Main>
        </Shell>
    );
}
