import React from 'react';
import { OnlySingleLine } from 'shared';
import styled from 'styled-components';
import CloseIcon from '../../../images/icons/close.svg?react';

const TagPill = styled.a`
    display: flex;
    align-items: center;

    font-size: 13px;
    line-height: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dropdownTokenText)};

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dropdownTokenBackground)};

    padding: 6.5px 12px;

    border-radius: 100px;

    ${props => (props.onClick ? 'cursor: pointer;' : '')}
`;

export const AdornmentPill = styled(TagPill)<{
    backgroundColor?: Themed.Color;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}>`
    font-size: 11px;
    line-height: 11px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.canViewPillText)};
    text-transform: uppercase;

    max-height: 11px;
    ${OnlySingleLine};

    background-color: ${({ backgroundColor, theme: { getColor, EColors } }) =>
        backgroundColor || getColor(EColors.canViewPillBackground)};

    padding: 7.5px 12px;
    margin: 0;
    border-radius: 100px;

    cursor: ${props => (props.onClick ? `pointer` : `default`)};
`;

const Close = styled(CloseIcon)`
    width: 18px;
    height: 18px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};

    margin: -5.5px -5.5px -5.5px 0;
`;

const AutoCompleteTag = ({ label, onRemove }: { label: string; onRemove?: () => void }) => (
    <TagPill onClick={onRemove}>
        {label}
        <Close />
    </TagPill>
);

export default AutoCompleteTag;
