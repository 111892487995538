import colorFns from 'colorFns';
import Button from 'components/ui/Button';
import PlusIcon from 'images/icons/plus.svg?react';
import styled from 'styled-components';
import { Column, Row } from 'ui';

const _PlusButtonIcon = ({ className }: { className?: string }) => (
    <div className={className}>
        <PlusIcon />
    </div>
);

export const PlusButtonIcon = styled(_PlusButtonIcon)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    border-radius: 100px;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconButton = styled(Button)`
    height: auto;
    padding: 10px 18px;
    border-radius: 14px;

    & ${PlusButtonIcon} {
        margin-right: 18px;
    }
`;

export const Footer = styled(Row)`
    position: fixed;
    bottom: 0;
    z-index: 1;
    padding-top: 16px;
    padding-bottom: 48px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    align-items: center;

    > * {
        margin-right: 20px;
    }

    ${IconButton} {
        margin-right: 48px;
    }
`;

export const Card = styled(Column)`
    position: relative;
    width: 455px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    box-sizing: border-box;

    > * {
        padding: 24px;

        &:not(:last-child) {
            border-bottom: 1px solid ${colorFns.softBorder};
        }
    }
`;
