import { SpinnerOverlay as UISpinnerOverlay } from 'components/Spinner';
import { H5Headline as UIH5Headline } from 'components/ui/Headline';
import styled from 'styled-components';
import { Column as UIColumn, Row as UIRow } from 'ui';

export const ACCORDION_WIDTH = 672;

export const Column = styled(UIColumn)<{ noMargin?: boolean }>`
    ${({ noMargin }) => (noMargin ? '' : `margin: 50px auto 0;`)}

    width: 100%;
    max-width: 1092px;
`;

export const Row = styled(UIRow)`
    justify-content: space-between;

    .accordion {
        width: 100%;
        max-width: ${ACCORDION_WIDTH}px;
        box-sizing: border-box;
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .pro-tip {
        margin-bottom: 24px;
        animation: fadeIn 1s ease;
    }
`;

export const H5Headline = styled(UIH5Headline)`
    line-height: 1.8em;
`;

export const Subheadline = styled.p`
    line-height: 1.4em;
    font-size: 18px;
    margin-top: 0;
    font-weight: 400;
`;
export const SpinnerOverlay = styled(UISpinnerOverlay)`
    z-index: 100;
`;
