import { SpinnerOverlay } from 'components/Spinner';
import { PageHeadline } from 'components/ui/Headline';
import useUnsavedPrompt from 'hooks/useUnsavedPrompt';
import EditParcel from 'pages/EditParcel/EditParcel';
import { PageNotFound } from 'pages/PageNotFound';
import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { LoadPlaybookOptions } from 'stores/playbooks';
import styled from 'styled-components';
import { Column, Spacer } from 'ui';
import BasicInfoForm from './BasicInfoForm';
import Collaboration from './Collaboration';
import CommunicationForm from './CommunicationForm';
import InquiryForm from './InquiryForm';
import Preview from './Preview';
import VenueFilters from './VenueFilters';
import Header from './components/Header';
import { ESteps, createPlaybookActions, selCurStep, stepToTitle, useCreatePlaybook } from './store';
import LoadPlaybook from './store/LoadPlaybook';

const Sticky = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
`;

const Container = styled(Column)`
    padding: 0 32px;
    box-sizing: border-box;
`;

const stepToForm = {
    [ESteps.basic]: <BasicInfoForm />,
    [ESteps.venues]: <VenueFilters />,
    [ESteps.inquiry]: <InquiryForm />,
    [ESteps.communication]: <CommunicationForm />,
    [ESteps.collaboration]: <Collaboration />,
    [ESteps.preview]: <Preview />,
};

const stepToWidth = {
    [ESteps.basic]: 640,
    [ESteps.venues]: 640,
    [ESteps.inquiry]: '100%',
    [ESteps.communication]: '100%',
    [ESteps.collaboration]: 640,
    [ESteps.preview]: '100%',
};

const GoTo = ({ step }: { step: ESteps }) => {
    React.useEffect(() => {
        createPlaybookActions.goToStep(step);
    }, [step]);

    return null;
};

export default function PlaybookWizard() {
    React.useEffect(() => createPlaybookActions.reset, []);

    const curStep = useCreatePlaybook(selCurStep);
    const { loading, saving, savingInquiry, hasSaved } = useCreatePlaybook();

    const { playbookId } = useParams<{ playbookId?: string }>();
    const renderUnsavedPrompt = useUnsavedPrompt(!hasSaved);

    if (!playbookId) {
        return <PageNotFound />;
    }

    return (
        <>
            <LoadPlaybook id={playbookId} />
            <LoadPlaybookOptions />

            {/* Highlight the correct sub nav item */}
            <Routes>
                <Route path={'communication/edit/*'} element={<GoTo step={ESteps.communication} />} />
                <Route path={'communication/:type'} element={<GoTo step={ESteps.preview} />} />
            </Routes>

            <Routes>
                <Route
                    path={'communication/edit/:type/:parcelId'}
                    element={
                        <EditParcel
                            context={{ id: playbookId, editable: true, asPlaybookParcel: true }}
                            onParcelSave={createPlaybookActions.triggerParcelsLoad}
                        />
                    }
                />
                <Route
                    path="*"
                    element={
                        <>
                            {renderUnsavedPrompt()}

                            <Sticky>
                                <Header />
                            </Sticky>
                            <Container fillWidth>
                                <Spacer larger />
                                <PageHeadline>{stepToTitle[curStep]}</PageHeadline>
                                <Spacer medium />
                                <Column style={{ width: stepToWidth[curStep] }}>{stepToForm[curStep]}</Column>

                                {(loading || saving || savingInquiry) && <SpinnerOverlay delay={1} />}
                            </Container>
                        </>
                    }
                />
            </Routes>
        </>
    );
}
