import { getTeamMembers, updateTeamSettings } from 'api/settings';
import colorFns from 'colorFns';
import ConfirmationModal from 'components/ConfirmationModal';
import Form from 'components/Form';
import { FieldDescription, LabeledTextField } from 'components/FormFields';
import SubscriptionPrompt from 'components/SubscriptionPrompt';
import Button from 'components/ui/Button';
import { H3Headline, SmallHeadline } from 'components/ui/Headline';
import usePersistentSnackbar from 'hooks/usePersistentSnackbar';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { nudgesActions } from 'stores/nudges';
import styled, { css } from 'styled-components';
import { i18n } from 'translation';
import { Column, Row, Spacer, Switch } from 'ui';

const FormCol = styled(Column)`
    min-width: 600px;
`;

const TRANSPARENT = 'transparent';

const ColorBubbleColor = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 2px solid transparent;
`;

const transparentSlash = css`
    transform: rotate(45deg);

    &::after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        margin-left: calc(50% - 1px);
        background: ${colorFns.softBorder};
    }
`;

const ColorBubble = styled.div<{ fill: string }>`
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 50%;
    border: 2px solid ${colorFns.softBorder};

    > ${ColorBubbleColor} {
        border-radius: 28px;

        background: ${({ fill }) => fill};

        ${({ fill }) => fill === TRANSPARENT && transparentSlash}
    }

    flex: 0 0 auto;
`;

const InlineSwitchContainer = styled.div`
    display: flex;
    align-items: center;

    .MuiSwitch-root {
        margin-left: 1rem;
    }
`;

const ColorHex = ({
    field,
    value,
    onChange,
    readonly,
    disabled,
    onClick,
}: {
    field: string;
    value?: Themed.Color;
    onChange: (update: { field: string; value: string }) => void;
    readonly?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}) => {
    return (
        <Column onClick={onClick}>
            <Row alignItems="center" itemSpacing="small">
                {!disabled && !readonly && (
                    <HexColorPicker
                        color={value}
                        onChange={newColor => onChange({ field: 'color', value: newColor })}
                    />
                )}
            </Row>
            <Row alignItems="center" itemSpacing="small">
                <LabeledTextField
                    field={field}
                    value={value}
                    onChange={onChange}
                    maxLength={7}
                    readonly={readonly}
                    disabled={disabled}
                />
                <ColorBubble fill={value && /#[A-Fa-f0-9]{6}/.test(value ?? '') ? value : TRANSPARENT}>
                    <ColorBubbleColor />
                </ColorBubble>
            </Row>
        </Column>
    );
};

const CancellationEmailField = ({
    field,
    value,
    onChange,
    readonly,
}: {
    field: string;
    value: Bizly.SpecialCancellationEmail;
    onChange: (update: { field: string; value: Bizly.SpecialCancellationEmail }) => void;
    readonly?: boolean;
}) => {
    return (
        <Form
            fields={{
                mandrillTemplate: {
                    type: 'text',
                    prompt: i18n.teamSettings.cancellationEmail.emailName,
                    readonly: true,
                    perRow: 2,
                },
                invoicingInstructions: {
                    type: 'text',
                    prompt: i18n.teamSettings.cancellationEmail.buttonLabel,
                    readonly,
                    perRow: 1,
                },
            }}
            schema={[{ fields: ['mandrillTemplate', 'invoicingInstructions'] }]}
            value={value}
            onChange={({ value }: { value: Bizly.SpecialCancellationEmail }) => {
                onChange({ field, value });
            }}
        />
    );
};

export const defaultTeamSettings: Partial<Bizly.Team> = {
    imageUrl: 'https://res.cloudinary.com/hdd626jg7/image/upload/v1605123872/bizly-logos/bizly-logo-b.png',
    imageUrlWhite:
        'https://res.cloudinary.com/hdd626jg7/image/upload/e_colorize,co_white/v1605123872/bizly-logos/bizly-logo-b.png',
    color: '#F5F5F5',
    bulletinMessage: undefined,
    meetingInternalReferenceFieldLabel: i18n.teamSettings.defaultInternalReference,
    meetingCostCenterFieldLabel: i18n.teamSettings.defaultCostCenterReference,
    authMeetingCreateRedirect: undefined,
    cancellationInformationMessage: undefined,
    specialCancellationEmail: [],
};
type AdminOptions = {
    id: string | number;
    name: string;
    value: string;
};

export default function TeamSettings() {
    const { user, isTeamAdmin, setTeam } = useUser();
    const {
        modalShown: subscriptionModalShown,
        showModal: showSubscriptionModal,
        hideModal: hideSubscriptionModal,
    } = useShowModal();

    const hasSpecialCancellationEmail = function (
        setting?: Bizly.Team['specialCancellationEmail']
    ): setting is Exclude<Bizly.Team['specialCancellationEmail'], []> {
        return !!setting && !Array.isArray(setting);
    };

    const [toggleBudgetBaseApproval, setToggleBudgetBaseApproval] = useState(user.team?.budgetApprover ? true : false);
    const [adminEmails, setAdminEmails] = useState<BizlyAPI.TeamMember[]>([]);
    const [adminOptions, setAdminOptions] = useState<AdminOptions[]>([]);
    const [internalReferenceFieldLabel, setInternalReferenceFieldLabel] = useState(
        user.team?.meetingInternalReferenceFieldLabel || i18n.teamSettings.defaultInternalReference
    );
    const [costCenterFieldLabel, setCostCenterFieldLabel] = useState(
        user.team?.meetingCostCenterFieldLabel || i18n.teamSettings.defaultCostCenterReference
    );

    const [meetingCreationRedirect, setMeetingCreationRedirect] = useState(
        Boolean(user.team?.authMeetingCreateRedirect?.length)
    );
    const [hasMaxAccommodationLimit, setHasMaxAccommodationLimit] = useState(
        user.team?.maxRoomsPerNightPerInquiry || user.team?.maxTotalRoomNightsPerInquiry ? true : false
    );

    useEffect(() => {
        const load = async () => {
            let activeAdmins: BizlyAPI.TeamMember[] = [];
            let adminOptions: AdminOptions[] = [];

            if (user.team?.id) {
                const team = await getTeamMembers(user.team.id);

                const ADMIN_ROLE = 100;
                const ACTIVE_STATUS = 1;

                activeAdmins = team.members.filter(
                    (user: BizlyAPI.TeamMember) => user.role.id === ADMIN_ROLE && user.status.int === ACTIVE_STATUS
                );

                adminOptions = activeAdmins.map((teamMember: BizlyAPI.TeamMember) => {
                    return {
                        id: teamMember.user.id,
                        name: teamMember.user.email,
                        value: teamMember.user.email,
                    };
                });
            }

            setAdminEmails(activeAdmins);
            setAdminOptions(adminOptions);

            setInternalReferenceFieldLabel(
                user.team?.meetingInternalReferenceFieldLabel || i18n.teamSettings.defaultInternalReference
            );
            setCostCenterFieldLabel(
                user.team?.meetingCostCenterFieldLabel || i18n.teamSettings.defaultCostCenterReference
            );
        };
        load();
    }, [user.team?.id, user.team?.meetingInternalReferenceFieldLabel, user.team?.meetingCostCenterFieldLabel]);

    const fields = {
        imageUrl: {
            type: 'image_uploader',
            prompt: i18n.teamSettings.teamLogo,
            fixedWidth: 200,
            options: {
                accessDenied: !user.team?.features.canEditLogo,
                onClickAccessDenied: showSubscriptionModal,
            },
        },
        name: {
            type: 'text',
            prompt: i18n.teamSettings.teamName,
            fixedWidth: 320,
        },
        color: {
            type: ColorHex,
            prompt: i18n.teamSettings.teamColor,
            fixedWidth: 200,
            options: {
                disabled: !user.team?.features.canEditLogo,
                onClick: !user.team?.features.canEditLogo ? showSubscriptionModal : undefined,
            },
        },
        bulletinMessage: {
            type: 'rich_text',
            prompt: i18n.teamSettings.homepageBannerMessage,
            nullSwitch: true,
            options: {
                disabled: !user.team?.features.canEditLogo,
                onClick: !user.team?.features.canEditLogo ? showSubscriptionModal : undefined,
            },
        },
        meetingInternalReferenceFieldLabel: {
            type: 'text',
            prompt: i18n.teamSettings.internalReferenceLabel,
        },
        meetingInternalReferenceFileField: {
            type: 'csv_uploader',
            prompt: `${internalReferenceFieldLabel} ${i18n.common.file}`,
            optional: true,
        },
        meetingCostCenterFieldLabel: {
            type: 'text',
            prompt: i18n.teamSettings.costCenterFieldLabel,
        },
        meetingCostCenterFileField: {
            type: 'csv_uploader',
            prompt: `${costCenterFieldLabel} ${i18n.common.file}`,
            optional: true,
        },
        authMeetingCreateRedirect: {
            type: 'rich_text',
            prompt: i18n.teamSettings.redirectMessage,
            nullSwitch: true,
            nullSwitchCallback: () => setMeetingCreationRedirect(!meetingCreationRedirect),
            options: {
                disabled: !user.team?.features.canEditLogo,
            },
        },
        cancellationInformationMessage: {
            type: 'rich_text',
            prompt: i18n.teamSettings.cancellationMessage,
            nullSwitch: true,
            options: {
                disabled: !user.team?.features.canEditLogo,
            },
        },
        ...(hasSpecialCancellationEmail(user.team?.specialCancellationEmail) && {
            specialCancellationEmail: {
                type: CancellationEmailField,
            },
        }),
    };

    const schema = [
        { fields: ['imageUrl'] },
        { fields: ['name'] },
        { fields: ['color'] },
        { fields: ['bulletinMessage'] },
        ...(user.team?.features.hiddenInternalFields.includes('cventId')
            ? []
            : [
                  {
                      fields: user.team?.features.costCenterDd
                          ? ['meetingInternalReferenceFieldLabel', 'meetingInternalReferenceFileField']
                          : ['meetingInternalReferenceFieldLabel'],
                  },
              ]),
        {
            fields: user.team?.features.costCenterDd
                ? ['meetingCostCenterFieldLabel', 'meetingCostCenterFileField']
                : ['meetingCostCenterFieldLabel'],
        },
        { fields: ['authMeetingCreateRedirect'] },
        { fields: ['cancellationInformationMessage'] },
        ...(hasSpecialCancellationEmail(user.team?.specialCancellationEmail)
            ? [{ fields: ['specialCancellationEmail'], header: i18n.teamSettings.cancellationEmails }]
            : []),
    ];

    const budgetBaseApprovalFields = {
        budgetApprover: {
            type: 'select',
            fixedWidth: 320,
            prompt: i18n.teamSettings.approversEmail,
            options: {
                options: adminOptions,
            },
            optional: true,
        },
        maximumBudget: meetingCreationRedirect
            ? {}
            : {
                  type: 'text',
                  fixedWidth: 320,
                  prompt: i18n.teamSettings.maximumBudgetLimit,
                  options: { numberType: 'currency', currency: 'USD' },
                  optional: true,
              },
    };

    const budgetBaseApprovalSchema = [
        { fields: ['budgetApprover'], spacing: 'default' },
        meetingCreationRedirect ? {} : { fields: ['maximumBudget'] },
    ];

    const accommodationLimitFormFields = {
        maxRoomsPerNightPerInquiry: {
            type: 'text',
            prompt: i18n.teamSettings.limitPerNight,
            nullSwitch: true,
            inline: true,
            options: {
                numberType: 'integer',
            },
            optional: true,
        },
        maxRoomsPerNightPerInquiryDescription: {
            type: () => <FieldDescription value={i18n.teamSettings.limitPerNightDescription} />,
        },

        maxTotalRoomNightsPerInquiry: {
            type: 'text',
            prompt: i18n.teamSettings.limitPerMeeting,
            nullSwitch: true,
            inline: true,
            options: {
                numberType: 'integer',
            },
            optional: true,
        },
        maxTotalRoomNightsPerInquiryDescription: {
            type: () => <FieldDescription value={i18n.teamSettings.limitPerMeetingDescription} />,
        },
    };
    const accommodationLimitFormSchema = [
        { fields: ['maxRoomsPerNightPerInquiry'], spacing: false },
        { fields: ['maxRoomsPerNightPerInquiryDescription'], spacing: 'default' },
        { fields: ['maxTotalRoomNightsPerInquiry'], spacing: false },
        { fields: ['maxTotalRoomNightsPerInquiryDescription'] },
    ];

    const [value, setValue] = useState<Partial<Bizly.Team>>(user.team ?? {});
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (user.team) {
            setValue({
                ...user.team,
                maxRoomsPerNightPerInquiry:
                    String(user.team.maxRoomsPerNightPerInquiry) === '' ? null : user.team.maxRoomsPerNightPerInquiry,
                maxTotalRoomNightsPerInquiry:
                    String(user.team.maxTotalRoomNightsPerInquiry) === ''
                        ? null
                        : user.team.maxTotalRoomNightsPerInquiry,
            });
        }
    }, [user.team]);

    const getValidationError = () => {
        if (!value.name?.trim()) {
            return i18n.teamSettings.error.teamNameRequired;
        }

        if (!value.imageUrl?.trim()) {
            return i18n.teamSettings.error.teamLogoRequired;
        }

        if (value.color !== '' && !/#[A-Fa-f0-9]{6}/.test(value.color ?? '')) {
            return i18n.teamSettings.error.teamColorValid;
        }

        if (toggleBudgetBaseApproval && !value.budgetApprover) {
            return i18n.teamSettings.error.budgetApproverRequired;
        }

        if (user.team?.features.canEditLogo) {
            if (toggleBudgetBaseApproval && !meetingCreationRedirect && !value.maximumBudget) {
                return i18n.teamSettings.error.maximumBudgetRequired;
            }
        }

        if (hasMaxAccommodationLimit) {
            if (!value.maxRoomsPerNightPerInquiry && !value.maxTotalRoomNightsPerInquiry) {
                return i18n.teamSettings.error.roomLimitRequired;
            }
        }
    };

    const isFormChanged = () => {
        if (!user.team || !value) {
            return false;
        }
        const fieldNames = Object.keys(fields);
        return fieldNames.some(field => value[field as keyof Bizly.Team] !== user.team![field as keyof Bizly.Team]);
    };

    const { modalShown: defaultModalShown, showModal: showDefaultModal, hideModal: hideDefaultModal } = useShowModal();

    const onDefaultConfirm = () => {
        setValue({
            ...(user.team ?? {}),
            ...defaultTeamSettings,
        });
        hideDefaultModal();
    };

    const setToDefault = () => {
        if (user.team?.features.canEditLogo) {
            showDefaultModal();
        } else {
            showSubscriptionModal();
        }
    };

    const reset = () => {
        setValue(user.team ?? {});
    };

    const enqueuePersistentSnackbar = usePersistentSnackbar([value]);

    const { enqueueSnackbar } = useSnackbar();
    const update = async () => {
        if (!user.team?.id) return;
        const error = getValidationError();

        if (error) {
            return enqueuePersistentSnackbar(error, { variant: 'error' });
        }

        if (!toggleBudgetBaseApproval) {
            delete value.budgetApprover;
            delete value.maximumBudget;
        }

        if (meetingCreationRedirect) {
            delete value.maximumBudget;
        }

        try {
            setSaving(true);
            const { team } = await updateTeamSettings(user.team.id, value);
            if (team.name) {
                nudgesActions.mergeNudges({ showTeamSettings: false });
            }
            setTeam(team);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        } finally {
            setSaving(false);
        }
    };

    const findBudgetApproveUser = (value: Partial<Bizly.Team>) =>
        adminEmails.find((admin: BizlyAPI.TeamMember) => admin.user.id === value.budgetApprover)?.user;

    if (!user.team) return null;

    return (
        <>
            <FormCol>
                {!isTeamAdmin && (
                    <>
                        <H3Headline>{i18n.teamSettings.contactTeamAdmin}</H3Headline>
                        <Spacer medium />
                    </>
                )}

                <Form
                    fields={fields}
                    schema={schema}
                    value={value}
                    onChange={({ value }: { value: Bizly.Team }) => {
                        setValue(value);
                    }}
                    readonly={!isTeamAdmin}
                    hideReadonlyEmpty
                />

                {user.featureFlags?.budgetBasedApprovals && (
                    <>
                        <InlineSwitchContainer>
                            <SmallHeadline>{i18n.teamSettings.requireBudgetApproval}</SmallHeadline>
                            <Switch
                                checked={toggleBudgetBaseApproval}
                                onChange={() => setToggleBudgetBaseApproval(!toggleBudgetBaseApproval)}
                            />
                        </InlineSwitchContainer>

                        <Spacer />

                        {toggleBudgetBaseApproval && (
                            <Form
                                fields={budgetBaseApprovalFields}
                                schema={budgetBaseApprovalSchema}
                                value={value}
                                onChange={({ value, field }: { value: Bizly.Team; field: string }) => {
                                    setValue({
                                        ...value,
                                        ...(field === 'budgetApprover'
                                            ? { budgetApprover: findBudgetApproveUser(value) }
                                            : {}),
                                    });
                                }}
                                readonly={!isTeamAdmin}
                            />
                        )}
                    </>
                )}

                <>
                    <InlineSwitchContainer>
                        <SmallHeadline>{i18n.teamSettings.maximumAccommodationRooms}</SmallHeadline>
                        <Switch
                            checked={hasMaxAccommodationLimit}
                            onChange={() => {
                                setHasMaxAccommodationLimit(!hasMaxAccommodationLimit);
                                if (hasMaxAccommodationLimit) {
                                    setValue(prev => ({
                                        ...prev,
                                        maxRoomsPerNightPerInquiry: null,
                                        maxTotalRoomNightsPerInquiry: null,
                                    }));
                                }
                            }}
                        />
                    </InlineSwitchContainer>
                    <FieldDescription value={i18n.teamSettings.limitDescription} />

                    <Spacer />

                    {hasMaxAccommodationLimit && (
                        <Form
                            fields={accommodationLimitFormFields}
                            schema={accommodationLimitFormSchema}
                            value={value}
                            onChange={({ value }: { value: Bizly.Team }) => {
                                setValue(value);
                            }}
                            readonly={!isTeamAdmin}
                        />
                    )}
                </>
            </FormCol>
            {isTeamAdmin && (
                <Row itemSpacing="smallish" justifyContent="flex-end">
                    {user.team?.features.canEditLogo && (
                        <Button onClick={setToDefault} disabled={saving} secondary>
                            {i18n.button.defaultLiteral}
                        </Button>
                    )}
                    <Button onClick={reset} disabled={!isFormChanged || saving} secondary>
                        {i18n.button.reset}
                    </Button>
                    <Button onClick={update} disabled={saving}>
                        {i18n.button.save}
                    </Button>
                </Row>
            )}
            {subscriptionModalShown && user.team && (
                <SubscriptionPrompt currentTier={user.team.tier} onSkip={hideSubscriptionModal} />
            )}
            <ConfirmationModal
                isActive={defaultModalShown}
                onDismiss={hideDefaultModal}
                headline={i18n.modal.areYouSure}
                prompt={i18n.teamSettings.removeCustomizationWarning}
                ctaLabel={i18n.button.confirm}
                onProceed={onDefaultConfirm}
            />
        </>
    );
}
