import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';

import EventSpaceIcon from 'images/icons/amenities/event-space.svg?react';
import HotelRoomBlockIcon from 'images/icons/amenities/hotel-room-block.svg?react';
import OfficeIcon from 'images/icons/amenities/office-coworking.svg?react';
import PrivateDiningIcon from 'images/icons/amenities/private-dining.svg?react';
import { i18n } from 'translation';
import { Description, StepContainer, Title } from './styled';

const ItemBox = styled(Box)(({ selected, theme: { spacing, getColor, EColors } }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: `${spacing(1.75)} ${spacing(1.5)}`,
    gap: 8,
    cursor: 'pointer',
    height: '100%',
    boxSizing: 'border-box',

    border: '1px solid',
    borderColor: selected ? getColor(EColors.primaryAction) : getColor(EColors.black, 0.25),
    borderRadius: '4px',

    backgroundColor: selected ? getColor(EColors.primaryAction, 0.1) : 'transparent',
}));

const IconBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
});

const initialState = {
    'Event Space': false,
    'Hotel Room Block': false,
    'Private Dining': false,
    'Group Activity': false,
    'Conference Room': false,
    'Office / Coworking': false,
} as const;

type PropType = {
    value: string[];
    onChange: (value: string[]) => void;
};

const Step3 = ({ value, onChange }: PropType) => {
    const selection = useMemo(() => {
        const newSelection: Record<string, boolean> = { ...initialState };
        value.forEach(key => {
            newSelection[key] = true;
        });
        return newSelection;
    }, [value]);

    const Amenities = [
        {
            icon: <EventSpaceIcon />,
            label: i18n.homepage.createMeetingModal.eventSpace,
            key: 'Event Space',
        },
        {
            icon: <HotelRoomBlockIcon />,
            label: i18n.homepage.createMeetingModal.hotelRoomBlock,
            key: 'Hotel Room Block',
        },
        {
            icon: <PrivateDiningIcon />,
            label: i18n.homepage.createMeetingModal.privateDining,
            key: 'Private Dining',
        },
        // {
        //     icon: <GroupActivityIcon />,
        //     label: i18n.homepage.createMeetingModal.groupActivity,
        //     key: 'Group Activity',
        // },
        // {
        //     icon: <ConferenceRoomIcon />,
        //     label: i18n.homepage.createMeetingModal.conferenceRoom,
        //     key: 'Conference Room',
        // },
        {
            icon: <OfficeIcon />,
            label: i18n.homepage.createMeetingModal.office,
            key: 'Office / Coworking',
        },
    ];

    const onClick = (item: string) => {
        if (selection[item]) {
            onChange([...value].filter(type => type != item));
        } else {
            onChange([...value, item]);
        }
    };

    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.bookTitle}</Title>
            <Description>{i18n.homepage.createMeetingModal.selectAllThatApply}</Description>
            <Grid container spacing={2}>
                {Amenities.map(amenity => (
                    <Grid key={amenity.key} item xs={6} onClick={() => onClick(amenity.key)}>
                        <AmenityItem icon={amenity.icon} label={amenity.label} selected={selection[amenity.key]} />
                    </Grid>
                ))}
            </Grid>
        </StepContainer>
    );
};

export default Step3;

const AmenityItem = ({ icon, label, selected }: { icon: ReactNode; label: string; selected: boolean }) => (
    <ItemBox selected={selected}>
        <IconBox>{icon}</IconBox>
        <Typography variant="body2">{label}</Typography>
    </ItemBox>
);
