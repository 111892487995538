import { RichTextDisplay } from 'components/Form/fields';
import { Spinner } from 'components/Spinner';
import AgendaTimeTable from 'components/ui/AgendaTimeTable';
import React from 'react';
import { isLoading, selectPlaybook, usePlaybooks } from 'stores/playbooks';
import { Card } from './ui';

export default function PlaybookAgenda({ playbookId }: { playbookId: string | number }) {
    const playbookFull = usePlaybooks(selectPlaybook(playbookId));
    const loadingAgenda = usePlaybooks(isLoading(playbookId));

    const renderedAgendaDescription = RichTextDisplay({ value: playbookFull?.agendaDescription || '' });
    const hasAgendaCard = (playbookFull?.agenda && playbookFull?.agenda.length > 0) || renderedAgendaDescription;

    return loadingAgenda ? (
        <Spinner />
    ) : (
        hasAgendaCard && (
            <Card>
                {renderedAgendaDescription && <div>{renderedAgendaDescription}</div>}
                {playbookFull?.agenda && <AgendaTimeTable agenda={playbookFull.agenda} />}
            </Card>
        )
    );
}
