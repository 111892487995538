import BizlyLogo from 'images/logo.svg?react';
import { useUser } from 'providers/user';
import React, { ReactNode, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ETeams } from 'theme';

// useUser can throw an error
class DefaultLogo extends React.Component<{ className?: string; children: ReactNode }> {
    state = {
        useDefault: false,
    };

    static getDerivedStateFromError() {
        return { useDefault: true };
    }

    render() {
        return (
            <div className={this.props.className}>{this.state.useDefault ? <BizlyLogo /> : this.props.children}</div>
        );
    }
}

const Logo = styled.div<{ src?: string, isMinimized?: boolean }>`
    width: 100%;
    height: 36px;

    background-image: url('${({ src }) => src}');
    background-size: contain;
    background-repeat: no-repeat;
    ${({ isMinimized }) => isMinimized ? `background-position: center;` : `background-position: left;`}
`;

const UserThemedLogo = ({isMinimized}: {isMinimized?: boolean;}) => {
    const theme = useContext(ThemeContext);
    // using theme from styled theme instead of user context respects nested overrided themes
    const usingTheme = theme?.theme !== ETeams.bizly;
    const { user } = useUser();

    // use brand logo from team settings
    const brandingEnabled = user?.team?.features?.enableBranding;
    const imgUrl = theme?.isThemeBright
        ? user?.team?.imageUrl || user.team?.imageUrlWhite
        : user.team?.imageUrlWhite || user.team?.imageUrl;

    return usingTheme || brandingEnabled ? <Logo src={imgUrl} isMinimized={isMinimized} /> : <BizlyLogo />;
};

export default function ThemedLogo({ className, isMinimized }: { className?: string, isMinimized?: boolean }) {
    return (
        <DefaultLogo className={className}>
            <UserThemedLogo isMinimized={isMinimized} />
        </DefaultLogo>
    );
}
