import { useState } from 'react';
import { fields, schema, schemaWithoutGuestrooms } from 'components/FinalSpendTracking/finalSpendFormSchema';
import Form from 'components/Form';
import { H2Headline, H3Headline } from 'components/ui/Headline';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Copy, ExternalLink, Spacer } from 'ui';
import pluckFileName from 'utils/pluckFileName';
import EmptyEstimatesImage from 'images/empty-pages-assets/empty-estimates.png';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import Stack from '@mui/material/Stack';
import { EColors } from 'theme';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import isEmpty from 'lodash/isEmpty';

export type TTotalSpendForm = {
    av?: number;
    catering?: number;
    guestrooms?: number;
    misc?: number;
    roomRental?: number;
    total?: number;
    existingDocs: {
        invoice1?: string;
        invoice2?: string;
    };
    stagedDocs?: {
        invoiceUrls?: File[];
    };
    currency?: string | null;
};

const DropzoneContainer = styled.div`
    border: 2px dashed ${({ theme }) => theme.getColor(EColors.grey)};
    background-color: #F5F5F5;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;

    &:hover {
        border-color: ${({ theme }) => theme.getColor(EColors.darkerGrey)};
    }
`;

const UploadIcon = styled(UploadFileOutlinedIcon)`
    color: ${({ theme }) => theme.getColor(EColors.primaryAction)};
`;

const PlaceholderText = styled.p`
    font-size: 0.938rem;
    color: ${({ theme }) => theme.getColor(EColors.pureBlack)};
`;

const FileList = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 16px;
`;

const FileItem = styled.li`
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
`;

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

const SmallCopy = styled(Copy)`
    font-size: 0.813rem;
`;

const LinkItem = styled.div`
    padding: 0.5em;
    border-radius: 4px;
    width: 100%;
`;

const NewItems = styled.div`
    padding: 0.75em;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.getColor(EColors.lightGrey, 0.3)};
    width: 100%;
    display: flex;
`;

const FullWidthLabel = styled.span`
    width: 100%;
`;

const H4Headline = styled.h4`
    font-weight: 500;
    margin: 0;
`;

const UploadContainer = styled(Stack)`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    padding: 1em;
    border-radius: 4px;
`;

const NoticeLabel = styled.span`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
    font-size: 0.75rem;
    margin-top: 5px;

    &.label {
        margin-top: 5px;
    }
`;

type UploadBoxProps = {
    files: File[];
    target: string;
    onUpload: (files: File[], target: string) => void;
    link?: string[];
};

const UploadBox = ({ files, target, onUpload, link }: UploadBoxProps) => {
    const { enqueueSnackbar } = useSnackbar();
    
    const onDrop = (acceptedFiles: File[]) => {
        const MAX_FILES = 2;
        const currentFileCount = 
            files && acceptedFiles 
                ? files.length + acceptedFiles.length 
                : 0;

        if (currentFileCount > MAX_FILES) {
            enqueueSnackbar(i18n.error.maxFilesExceeded, {
                variant: 'error',
            });
            return;
        }
        onUpload([...files, ...acceptedFiles], target);
    };

    const handleRemoveFile = (file: File) => {
        const updatedFiles = files.filter(f => f !== file);
        onUpload(updatedFiles, target);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

    return (
        <>
            <DropzoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <PlaceholderText>
                    <UploadIcon />
                    <Spacer />
                    {i18n.venue.proposal.dropHere}
                    <SmallCopy>{i18n.venue.proposal.maxFileUploadSize}</SmallCopy>
                </PlaceholderText>
            </DropzoneContainer>
            <NoticeLabel className='label'>
                {i18n.venue.proposal.uploadNotice}
            </NoticeLabel>
            {files && files.length > 0 && (
                <FileList>
                    <H4Headline>{i18n.venue.proposal.newlyUploadedFiles}</H4Headline>
                    {files.map(file => (
                        <FileItem key={file.name}>
                            <NewItems>
                                <FullWidthLabel>{file.name}</FullWidthLabel>
                                <IconButton onClick={() => handleRemoveFile(file)} aria-label="delete" color="error" sx={{ fontSize: '0.75rem', padding: 0 }}>
                                    <DeleteOutlinedIcon />
                                </IconButton>
                            </NewItems>
                        </FileItem>
                    ))}
                </FileList>
            )}

            {link && link.length > 0 && (
                <FileList>
                    <H4Headline>{i18n.venue.proposal.previouslyUploadedFiles}</H4Headline>
                    {link.map(fileLink => (
                        <FileItem key={fileLink}>
                            <LinkItem>
                                <ExternalLink href={fileLink} openInNewTab>
                                    {pluckFileName(fileLink)}
                                </ExternalLink>
                            </LinkItem>
                        </FileItem>
                    ))}
                </FileList>
            )}
        </>
    );
};

export default function TotalSpendForm({
    booking,
    formValues,
    onFormUpdate,
    teamCompliance,
    readonly,
    disabled,
    hideHeader,
    hasBookedVenue,
}: Readonly<{
    booking: Bizly.Booking;
    formValues: TTotalSpendForm;
    onFormUpdate: (finalSpendForm: TTotalSpendForm) => void;
    teamCompliance: Bizly.TeamCompliance;
    readonly?: boolean;
    disabled?: boolean;
    hideHeader?: boolean;
    hasBookedVenue?: boolean;
}>) {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { existingDocs, currency, stagedDocs = {} } = formValues;

    const handleStagingDoc = async (files: File[], target: string) => {
        const MAX_MB = 41943040; // 40mb
        const isFileTooLarge = files.filter(file => file.size > MAX_MB);
        if (isFileTooLarge.length > 0) {
            isFileTooLarge.forEach(file => {
                enqueueSnackbar(i18n.error.tooLargeFile(file.name), {
                    variant: 'error',
                });
            });
        } else {
            onFormUpdate({
                ...formValues,
                stagedDocs: { ...stagedDocs, [target]: files }
            });

            setUploadedFiles([]);
        }
    };

    const invoices = ['invoice1', 'invoice2'] as const;
    const uploadedDocs = invoices
        .map(invoice => existingDocs[invoice as keyof typeof existingDocs])
        .filter((doc): doc is string => !isEmpty(doc));

    return (
        <>
            {!hideHeader && <Headline>{i18n.meetingDashboard.headerSection.spendTracking.finalSpendTracker}</Headline>}
            {hasBookedVenue ? (
                <>
                    <Form
                        fields={fields(currency ?? 'USD')}
                        schema={teamCompliance?.guestroomsDisabled ? schemaWithoutGuestrooms : schema}
                        value={{ ...formValues, currencyCode: currency ?? 'USD' }}
                        onChange={({ value }: { value: TTotalSpendForm }) => onFormUpdate(value)}
                        isNested={false}
                        densePadding={false}
                        readonly={readonly}
                        disabled={disabled}
                    />

                    <Spacer large />

                    <UploadContainer spacing={2}>
                        <H3Headline>{i18n.meetingDashboard.headerSection.spendTracking.uploadFinalInvoice} (required)</H3Headline>
                        <UploadBox
                            onUpload={(files) => {
                                handleStagingDoc(files, 'invoiceUrls');
                                setUploadedFiles(files);
                            }}
                            files={uploadedFiles}
                            target={'invoiceUrls'}
                            link={uploadedDocs.length ? uploadedDocs : []}
                        />
                    </UploadContainer>
                </>
            ) : (
                <>
                    <H2Headline>{i18n.meetingDashboard.headerSection.spendTracking.finalSpend}</H2Headline>
                    <Spacer />
                    <EmptyVisual
                        image={<img src={EmptyEstimatesImage} alt="Empty Estimates" />}
                        title={i18n.venue.proposal.noVenueBookedYet}
                        description={i18n.venue.proposal.totalSpendHelper}
                    />
                </>
            )}
        </>
    );
}
