import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const StepContainer = styled(Box)({
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

export const Title = styled(Typography)(({ theme }) => ({
    fontWeight: '500',
    fontSize: '1.75em',
    marginBottom: theme.spacing(3),
}));

export const FieldTitle = styled(Typography)<{ thin?: boolean }>(({ theme, thin }) => ({
    fontWeight: thin ? '400' : '600',
    marginBottom: theme.spacing(1),
}));

export const Description = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

export const VerbiageAlert = styled(Alert)(({ theme: { getColor, EColors } }) => ({
    '&.MuiPaper-root': {
        width: 'auto',
        height: '2.25rem',
        borderRadius: '0.313rem',
        margin: '1.25rem 0',
        justifyContent: 'left',
        backgroundColor: '#E1ECF8',

        '& .MuiAlert-icon': {
            color: `${getColor(EColors.primaryAction)}`,
        },
    },
}));

export const FieldLabel = styled(Typography)(({ theme: { getColor, EColors } }) => ({
    padding: '0.7em',
    fontSize: '0.813rem',
    color: `${getColor(EColors.darkGrey)}`,
}));
