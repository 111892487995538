import { Paper } from '@material-ui/core';
import colorFns from 'colorFns';
import AgendaPhaseOutWarning from 'components/AgendaPhaseOutWarning';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import { RichTextEditor } from 'components/Form/fields';
import { UploadList } from 'components/FormFields';
import AgendaTimeTable from 'components/ui/AgendaTimeTable';
import { H2Headline } from 'components/ui/Headline';
import EditIconSVG from 'images/icons/pencil.svg?react';
import DeleteIconSVG from 'images/icons/trash-can.svg?react';
import { useEvent } from 'providers/event';
import React from 'react';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { Column, Copy, ExternalLink, Row, Spacer } from 'ui';
import { getShortTimeZone } from 'utils/date_util';
import { tzMoment } from 'utils/moment';

const EventDetails = styled(Column)`
    width: 460px;
    max-height: 70vh;

    word-break: break-word;

    > * {
        &:first-child {
            padding-top: 32px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid ${colorFns.softBorder};
        }

        &:last-child {
            padding-bottom: 32px;
        }
    }
`;

const Header = styled(Column)`
    padding: 0 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${colorFns.softBorder};
`;

const EditIcon = styled(withInteractibleIconStyles(EditIconSVG)).attrs({ viewBox: '1.5 2.5 18 21.5' })`
    height: 21px;
    width: 21px;
`;
const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG)).attrs({ viewBox: '2 4 15 21' })`
    height: 21px;
    width: 21px;
`;

const Details = styled(Column)`
    overflow: auto;

    > * {
        padding-left: 32px;
        padding-right: 32px;

        &:not(:last-child) {
            border-bottom: 1px solid ${colorFns.softBorder};
        }
    }
`;

const FullWidthColumn = styled(Column)`
    width: 100%;
`;

const AutoRow = styled(Row)`
    width: auto;
`;

const LocationRow = styled(Row)`
    > * {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        min-width: 0;
    }
`;

const Label = styled(Copy)`
    color: ${colorFns.formLabel};
`;

const TimeZoneText = styled.span`
    color: ${colorFns.darkGrey};
    margin: 0 8px;
`;

type TBaseBlock = Omit<BizlyAPI.ScheduleBlock, 'date' | 'startTime' | 'endTime' | 'colorId'> & {
    start: Date;
    end: Date;
    multiDay?: boolean;
    loading?: boolean;
};

type TBlockSummaryPopover = {
    block: TBaseBlock;
    editable?: boolean;
    onEdit?: () => void;
    onDelete?: () => void;
    lastBlockWarning?: boolean;
};

const BlockSummaryPopover = React.forwardRef(function (
    { block, editable, onEdit, onDelete, lastBlockWarning }: TBlockSummaryPopover,
    ref: React.Ref<HTMLDivElement>
) {
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showLastBlockWarning, setShowLastBlockWarning] = React.useState(false);

    const { event } = useEvent();

    const { name, start, end, location, description, attachments, agenda, virtualMeeting } = block;

    const renderedDescription =
        description && RichTextEditor({ field: '', value: description, onChange: () => {}, readonly: true });

    const hasLocationRow = location || virtualMeeting;
    const hasAttachments = !!(attachments && attachments.length);

    const hasBasicDetails = hasLocationRow || hasAttachments || renderedDescription !== null;
    const hasAgenda = !!(agenda && agenda.length);

    const hasDetails = hasBasicDetails || hasAgenda;

    const LocationDetailsRow = hasLocationRow && (
        <LocationRow itemSpacing="default">
            {location && (
                <Column itemSpacing="small">
                    <Label>Location</Label>
                    <Copy>{location}</Copy>
                </Column>
            )}
            {virtualMeeting && (
                //TODO: fix Column within Column itemSpacing
                <Column>
                    <Column itemSpacing="small">
                        <Label>Virtual Meeting</Label>
                        <ExternalLink href={virtualMeeting.link} openInNewTab>
                            {virtualMeeting.link}
                        </ExternalLink>
                    </Column>
                    {virtualMeeting.notes && (
                        <>
                            <Spacer smallish />
                            <RichTextEditor field="" value={virtualMeeting.notes} onChange={() => {}} readonly />
                        </>
                    )}
                </Column>
            )}
        </LocationRow>
    );

    const DescriptionRow = renderedDescription && (
        <FullWidthColumn itemSpacing="small">
            <Label>Description</Label>
            {renderedDescription}
        </FullWidthColumn>
    );

    const AttachmentsRow = !!(attachments && attachments.length) && (
        <FullWidthColumn>
            <Label>Attached Documents</Label>
            <Spacer small />
            <UploadList
                field=""
                value={attachments}
                onChange={() => {}}
                readonly
                prompt=""
                description=""
                disabled={false}
            />
        </FullWidthColumn>
    );

    const AgendaDetailsSection = hasAgenda && <AgendaTimeTable withBorderPadding="default" agenda={agenda} />;

    const timeZone = getShortTimeZone(start, event.timeZone ?? 'UTC');

    return (
        <Paper elevation={3} ref={ref}>
            <EventDetails>
                <Header>
                    <Column itemSpacing="small">
                        <Row justifyContent="space-between" alignItems="center">
                            <H2Headline>{name || <em>Untitled</em>}</H2Headline>

                            <AutoRow itemSpacing="small">
                                {onEdit && <EditIcon onClick={onEdit} />}
                                {editable && onDelete && <DeleteIcon onClick={() => setShowDeleteModal(true)} />}
                            </AutoRow>
                        </Row>
                        <Copy>
                            {[tzMoment(start).format('LT'), tzMoment(end).format('LT')].join(' to ')}
                            <TimeZoneText>{timeZone}</TimeZoneText>
                        </Copy>
                    </Column>
                </Header>

                {hasDetails && (
                    <Details>
                        {hasBasicDetails && (
                            <Column itemSpacing="large" withBorderPadding="default">
                                {LocationDetailsRow}
                                {DescriptionRow}
                                {AttachmentsRow}
                            </Column>
                        )}
                        {AgendaDetailsSection}
                    </Details>
                )}
            </EventDetails>

            {onDelete && (
                <>
                    <DeleteConfirmationModal
                        onDismiss={() => setShowDeleteModal(false)}
                        onProceed={
                            lastBlockWarning
                                ? () => {
                                      setShowDeleteModal(false);
                                      // to prevent user from accidentally skipping this second warning
                                      setTimeout(() => setShowLastBlockWarning(true), 1000);
                                  }
                                : onDelete
                        }
                        prompt="Deleting this item will remove it from your meeting. This process cannot be undone."
                        isActive={showDeleteModal}
                    />

                    {lastBlockWarning && (
                        <AgendaPhaseOutWarning
                            onDismiss={() => setShowLastBlockWarning(false)}
                            onProceed={onDelete}
                            isActive={showLastBlockWarning}
                        />
                    )}
                </>
            )}
        </Paper>
    );
});

export default BlockSummaryPopover;
