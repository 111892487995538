import colorFns from 'colorFns';
import { SpinnerOverlay } from 'components/Spinner';
import UIButton from 'components/ui/Button';
import TextButton from 'components/ui/Button/TextButton';
import { H3Headline } from 'components/ui/Headline';
import ArrowLeftIcon from 'images/icons/path_arrow_left.svg?react';
import ArrowRightIcon from 'images/icons/path_arrow_right.svg?react';
import chunk from 'lodash/chunk';
import clamp from 'lodash/clamp';
import { useUser } from 'providers/user';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Column, Copy, Row } from 'ui';

const Container = styled(Column)`
    position: relative;
    width: 455px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    padding: 24px;
    box-sizing: border-box;
`;

const Title = styled(H3Headline)`
    font-weight: 700;
`;

const SubHeading = styled(Copy)`
    font-size: 12px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

const ArrowBack = styled(ArrowLeftIcon)``;
const ArrowForward = styled(ArrowRightIcon)``;
const ArrowControls = styled.div`
    display: flex;
    width: 79px;
    font-size: 12px;

    ${ArrowBack}, ${ArrowForward} {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
        height: auto;
        width: 4px;
        cursor: pointer;
        padding: 2px 10px;
    }
`;

const Button = styled(UIButton)``;
const Controls = styled(Row)`
    width: auto;
    align-items: center;

    ${Button} {
        height: 21px;
        width: fit-content;

        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        background-color: ${colorFns.brand};

        padding: 0 10px;
        border: none;
        border-radius: 6px;

        font-size: 12px;
        font-weight: 400;
    }
`;

type TPagedPlaybooksProps = {
    playbooks: Bizly.EventTemplate[];
    title?: string;
    subheading?: string;
    hideMenu?: boolean;
    showAll?: boolean;
    onShowAll?: () => void;
    onBack?: () => void;
    isSummary?: boolean;
    isLoading?: boolean;
};

const ImageCircle = styled.img`
    min-height: 36px;
    min-width: 36px;
    max-height: 36px;
    max-width: 36px;
    border-radius: 36px;
    overflow: hidden;
`;

const ColorCircle = styled.div`
    min-height: 36px;
    min-width: 36px;
    max-height: 36px;
    max-width: 36px;
    border-radius: 36px;
    overflow: hidden;
    ${({ backgroundColor }: { backgroundColor: string }) =>
        backgroundColor ? `background-color:${backgroundColor};` : ''}
`;

const HoverRow = styled(Row)`
    border-radius: 8px;
    padding: 12px;

    &:hover {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.listItemBackground)};
    }
`;

const PlaybookEntry = styled(Row)`
    justify-content: space-between;
    cursor: pointer;
    background-color: transparent;

    ${ArrowForward} {
        height: auto;
        width: 8px;
        justify-self: flex-end;
    }

    transition: all 0.2s ease-out;
`;

const MAX_ENTRIES = 5;

const BACK = -1,
    FORWARD = 1;

type TPageDirection = typeof BACK | typeof FORWARD;

export default function PagedPlaybooks({
    playbooks,
    title,
    subheading,
    showAll,
    onShowAll,
    onBack,
    isSummary,
    isLoading,
}: TPagedPlaybooksProps) {
    const [page, setPage] = useState<number>(1);
    const navigate = useNavigate();
    const { user } = useUser();

    const totalPages = Math.ceil(playbooks.length / MAX_ENTRIES);

    const goToPlaybooks = () => navigate(`/playbooks`);

    const handlePageChange = (direction: TPageDirection) =>
        setPage(prevPage => clamp(prevPage + direction, 1, totalPages));

    const playbooksChunk = useMemo(() => chunk(playbooks, MAX_ENTRIES)[page - 1], [playbooks, page]);
    return (
        <Container itemSpacing="small">
            {(title || onShowAll) && (
                <Row justifyContent="space-between" alignItems="flex-start">
                    <Column>
                        {title && <Title>{title}</Title>}
                        {subheading && <SubHeading>{subheading}</SubHeading>}
                    </Column>
                    <Controls itemSpacing="xsmall">
                        {!isSummary && !showAll && playbooks.length > MAX_ENTRIES && (
                            <>
                                <ArrowControls>
                                    <ArrowBack onClick={() => handlePageChange(BACK)} />
                                    {page} / {totalPages}
                                    <ArrowForward onClick={() => handlePageChange(FORWARD)} />
                                </ArrowControls>
                                {onShowAll && (
                                    <Button secondary small onClick={onShowAll}>
                                        Show All
                                    </Button>
                                )}
                            </>
                        )}
                        {!isSummary && showAll && onBack && (
                            <Button onClick={onBack} secondary small>
                                Back
                            </Button>
                        )}
                    </Controls>
                </Row>
            )}
            <Column itemSpacing="small">
                {!!playbooks.length &&
                    (showAll ? playbooks : playbooksChunk).map(template => (
                        <PlaybookEntry
                            key={template.id}
                            alignItems="center"
                            onClick={() => navigate(`/playbooks/${template.id}`)}
                        >
                            <HoverRow alignItems="center" justifyContent="space-between">
                                <Row alignItems="center" itemSpacing="large">
                                    {template.briefImageSmall ? (
                                        <ImageCircle alt="playbook graphic" src={template.briefImageSmall} />
                                    ) : (
                                        <ColorCircle backgroundColor={user.team?.color ?? 'transparent'} />
                                    )}
                                    {template.name}
                                </Row>
                                <ArrowForward />
                            </HoverRow>
                        </PlaybookEntry>
                    ))}
            </Column>

            {isSummary && (
                <Row justifyContent="center">
                    <TextButton onClick={goToPlaybooks} asLink>
                        View all playbooks
                    </TextButton>
                </Row>
            )}
            {isLoading && <SpinnerOverlay transparent />}
        </Container>
    );
}
