import { Step, StepLabel, Stepper as StepperMui } from '@material-ui/core';
import colorFns from 'colorFns';
import { EventHeaderIconsFill, EventHeaderLeftFill } from 'components/EventHeader';
import Button from 'components/ui/Button';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Fill, Slot } from 'react-slot-fill';
import styled from 'styled-components';
import { Row } from 'ui';
import { ESteps, ValidationError, createMeetingActions, selCurStep, selMaxStep, useCreateMeeting } from '../store';

const HeaderRow = styled(Row)<{ noBackground?: boolean }>`
    ${({ noBackground, ...theme }) =>
        noBackground
            ? `
    background: transparent;
    pointer-events: none;

    & ${Button} {
        pointer-events: all;
    }
    `
            : `
    background: ${colorFns.pureWhite(theme)};`};
`;

const AutoRow = styled(Row)`
    width: auto;
`;

const Stepper = styled(StepperMui)`
    min-width: 200px;
    padding-left: 0;
    background: transparent;

    .MuiStep-root,
    .MuiStepLabel-iconContainer {
        padding: 0;
    }

    .MuiStepIcon-root.MuiStepIcon-active {
        color: ${colorFns.brand};
    }
`;

const MeetingButtonsHeaderName = 'Create.Meeting.Header.Right.Slot';
const MeetingHeaderRightSlot = styled(Slot).attrs({
    name: MeetingButtonsHeaderName,
})``;
export const MeetingHeaderRightFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={MeetingButtonsHeaderName}>{children}</Fill>
);

export default function Header({ noBackground, className }: { noBackground?: boolean; className?: string }) {
    const { stepIdx, stepList, isPublished } = useCreateMeeting();
    const maxStep = useCreateMeeting(selMaxStep);
    const curStep = useCreateMeeting(selCurStep);

    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const save = async () => {
        try {
            if (isPublished && id) {
                return await createMeetingActions.savePublished(id);
            }

            const resultMeeting = await createMeetingActions.saveDraft(id);
            if (id === undefined && resultMeeting) {
                navigate(`/events/${resultMeeting.id}/edit`, {
                    replace: true,
                    state: { skipLoading: true, step: ESteps.venues },
                });
            } else if (!isPublished) {
                createMeetingActions.goToStep(ESteps.venues);
            }
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const saveable = true;

    return (
        <HeaderRow alignItems="center" justifyContent="space-between" noBackground={noBackground} className={className}>
            <Routes>
                <Route
                    path={'venue'}
                    element={
                        <EventHeaderLeftFill>
                            <AutoRow>
                                <Stepper>
                                    {stepList.length > 1 &&
                                        stepList.map((_, idx) => (
                                            <Step key={idx} completed={false} active={idx <= stepIdx}>
                                                <StepLabel>{}</StepLabel>
                                            </Step>
                                        ))}
                                </Stepper>
                            </AutoRow>
                        </EventHeaderLeftFill>
                    }
                />
                <Route
                    path="*"
                    element={
                        <AutoRow>
                            <Stepper>
                                {stepList.length > 1 &&
                                    stepList.map((_, idx) => (
                                        <Step key={idx} completed={false} active={idx <= stepIdx}>
                                            <StepLabel>{}</StepLabel>
                                        </Step>
                                    ))}
                            </Stepper>
                        </AutoRow>
                    }
                />
            </Routes>

            <Routes>
                <Route path={'venue/listing'} element={null} />
                <Route
                    path={'venue'}
                    element={
                        <EventHeaderIconsFill>
                            <AutoRow itemSpacing="smallish">
                                {stepIdx > 0 && (
                                    <Button width="60px" secondary onClick={createMeetingActions.prevStep}>
                                        Prev
                                    </Button>
                                )}
                                {stepIdx < maxStep && (
                                    <Button width="80px" onClick={createMeetingActions.nextStep}>
                                        Next
                                    </Button>
                                )}
                            </AutoRow>
                        </EventHeaderIconsFill>
                    }
                />
                <Route
                    element={
                        curStep !== ESteps.inquiry ? (
                            <AutoRow itemSpacing="smallish">
                                {stepIdx > 0 && (
                                    <Button width="60px" secondary onClick={createMeetingActions.prevStep}>
                                        Prev
                                    </Button>
                                )}
                                {saveable && (
                                    <Button width="140px" onClick={save}>
                                        {!isPublished ? 'Next' : 'Save'}
                                    </Button>
                                )}
                                {id && isPublished && stepIdx < maxStep && (
                                    <Button width="80px" onClick={createMeetingActions.nextStep}>
                                        Next
                                    </Button>
                                )}
                            </AutoRow>
                        ) : (
                            <AutoRow itemSpacing="smallish">
                                {stepIdx > 0 && (
                                    <Button width="60px" secondary onClick={createMeetingActions.prevStep}>
                                        Prev
                                    </Button>
                                )}
                                <MeetingHeaderRightSlot />
                            </AutoRow>
                        )
                    }
                />
            </Routes>
        </HeaderRow>
    );
}
