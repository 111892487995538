import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import colorFns from 'colorFns';
import Form from 'components/Form';
import Pill from 'components/ui/Pill';
import fontFns from 'fontFns';
import useThemedColor from 'hooks/useThemedColor';
import CalendarIconSVG from 'images/icons/calendar.svg?react';
import DocumentIconSVG from 'images/icons/document.svg?react';
import PersonIconSVG from 'images/icons/person.svg?react';
import BizlyLogo from 'images/logo.svg?react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EColors, colorStyledFn } from 'theme';
import { i18n } from 'translation';
import { tzMoment } from 'utils/moment';
import { withIconStyles } from '../../shared';
import { AlignedRow, Card, Column, Copy, ExternalLink, LabeledCheckbox, Row, Spacer } from '../../ui';
import Button from '../ui/Button';
import { Headline, IconHeadline } from '../ui/Headline';
import { TProposal, TProposalForm } from './types';

const HeadingRow = styled(Row)`
    margin-bottom: 70px;
    min-width: 164px;
`;

const CompatibleCol = styled(Column)`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
    width: inherit;
`;

const CardsHeading = styled(HeadingRow)`
    box-sizing: border-box;
    gap: 36px;
`;

const StaticCard = styled(Card)`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    max-width: 240px;
    height: 100%;
    box-sizing: border-box;
    margin-right: 0;
    margin-bottom: 0;

    justify-content: flex-start;
    align-items: stretch;
`;

const FlexCard = styled(Card)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    height: 100%;
    box-sizing: border-box;
    margin-right: 0;
    margin-bottom: 0;

    justify-content: flex-start;
    align-items: stretch;
`;

const FadedCopy = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.55)};
`;

const Color = styled.span`
    ${colorStyledFn}
`;

const ClientLogo = styled.img`
    height: 48px;
    width: 48px;
    object-position: center;
    object-fit: contain;
    border-radius: 24px;
`;

const SidebarHeadline = styled(Headline)`
    white-space: initial;
`;

const StyledLink = styled(Link)`
    color: ${colorFns.primaryAction};
    text-decoration: underline;
    white-space: nowrap;
`;

const DividerWhite = styled(Divider)`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.1)};
`;

export const IntroHeader = ({ client = {}, venue = {}, proposal = {} }: Partial<TProposalForm>) => {
    const { firstName, lastName, companyName } = client;
    const { name, headline } = venue;
    const IATA_ID = `33-7 4226-2`;

    return (
        <HeadingRow>
            <CompatibleCol>
                <BizlyLogo />
                <Spacer smallish />
                <SidebarHeadline thin>{name}</SidebarHeadline>
                <FadedCopy>{headline}</FadedCopy>

                <Spacer />
                <DividerWhite />
                <Spacer small />

                <SidebarHeadline thin>
                    {i18n.proposalForm.header.createProposal}
                    <Color $color={EColors.bizlyHeadlineDot}>.</Color>
                </SidebarHeadline>
                <FadedCopy>
                    {i18n.proposalForm.header.greetingsCopy([firstName, lastName].join(' '), companyName ?? '')}
                </FadedCopy>

                {!!proposal.bizlyCollectsCommissions && (
                    <>
                        <Spacer />
                        <DividerWhite />
                        <Spacer small />

                        <Copy $color={EColors.pureWhite}>{i18n.proposalForm.header.commissionableEvent}</Copy>
                        <Spacer xsmall />
                        <FadedCopy small>
                            {i18n.proposalForm.header.bizlyIATANumber}
                            <br />
                            <Color $color={EColors.pureWhite}>{IATA_ID}</Color>
                        </FadedCopy>
                        {proposal.msaDocumentUrl && (
                            <>
                                <Spacer xsmall />
                                <FadedCopy small>
                                    {i18n.proposalForm.header.commissionPayout}{' '}
                                    <StyledLink
                                        to={{ pathname: proposal.msaDocumentUrl }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {i18n.proposalForm.header.contract}
                                    </StyledLink>
                                </FadedCopy>
                            </>
                        )}
                    </>
                )}
            </CompatibleCol>
        </HeadingRow>
    );
};

const PersonIcon = withIconStyles(PersonIconSVG);
const CalendarIcon = withIconStyles(CalendarIconSVG);
const DocumentIcon = withIconStyles(DocumentIconSVG);

const MsaAnchor = styled(ExternalLink)`
    margin-top: auto;
`;

const InquiryNotes = styled(Copy)`
    overflow: auto;
    flex: 1;
`;

const DatesFlexibleCheckbox = styled(LabeledCheckbox)`
    max-height: 19px;
    pointer-events: none;
    > .MuiIconButton-root {
        padding-top: 0;
        padding-bottom: 0;
    }
`;

const SingleLineRow = styled(Row)`
    white-space: nowrap;
`;

const Hidden = styled.span`
    visibility: hidden;
`;

const datesFlexibleForm = {
    fields: {
        datesFlexibleNotes: {
            type: 'textarea',
            prompt: i18n.proposalForm.header.alternativeDates,
            options: {
                rows: 2,
                rowsMax: 2,
                maxLength: 275,
                placeholder: i18n.proposalForm.header.alternativeDatesPlaceholder,
            },
        },
    },
    schema: [{ key: 'datesFlexibleNotes', fields: ['datesFlexibleNotes'], spacing: false }],
};

const FormLabel = styled(Copy)`
    color: ${colorFns.formLabel};
    ${fontFns.formLabel}
`;

type TDatesFlexibleValue = Pick<TProposalForm['proposal'], 'datesFlexibleNotes'>;

const FlexibleNotesField = ({
    value = {},
    onChange,
    disabled,
}: {
    value?: TDatesFlexibleValue;
    onChange: (update: { value: TDatesFlexibleValue }) => void;
    disabled?: boolean;
}) => <Form {...datesFlexibleForm} value={value} onChange={onChange} disabled={disabled} />;

export const ProposalDetailsHeader = ({
    client = {},
    inquiry = {},
    event = {},
    proposal = {},
    onChange,
    data,
    disabled,
}: Partial<TProposalForm> & {
    data?: Partial<TProposal>;
    onChange: (newProposalData: Partial<TProposal>) => void;
    disabled?: boolean;
}) => {
    const { firstName, lastName, companyName, logoUrl } = client;
    const { notes, datesFlexible } = inquiry;
    const { name, cancelledAt } = event;
    const { datesFlexibleNotes, msaDocumentUrl } = proposal;

    const { warningAction } = useThemedColor();

    const startDate = event?.startsAt;
    const endDate = event?.endsAt;

    const dateDisplay =
        startDate && endDate && `${tzMoment(startDate).format('lll')} - ${tzMoment(endDate).format('lll')}`;

    const isBelow1281 = useMediaQuery('(max-width:1280px)');

    return (
        <CardsHeading>
            <StaticCard>
                <IconHeadline icon={<PersonIcon />} headline={i18n.proposalForm.header.clientDetails} />
                <Spacer small />
                <AlignedRow itemSpacing="small">
                    {logoUrl && <ClientLogo src={logoUrl} alt={companyName + '-logo'} />}
                    <Column itemSpacing="xsmall">
                        <Copy>{[firstName, lastName].join(' ')}</Copy>
                        <Copy>{companyName}</Copy>
                    </Column>
                </AlignedRow>
                {msaDocumentUrl && (
                    <MsaAnchor href={msaDocumentUrl} openInNewTab>
                        <Button width="100%">{i18n.proposalForm.header.clientAgreement}</Button>
                    </MsaAnchor>
                )}
            </StaticCard>

            <FlexCard>
                <IconHeadline icon={<CalendarIcon />} headline={i18n.proposalForm.header.meetingDetails} />

                <Spacer small />

                <Column itemSpacing="small">
                    <Column alignItems="flex-start">
                        {cancelledAt && (
                            <Pill
                                color={warningAction}
                                label={i18n.proposalForm.header.cancelled}
                                noShadow
                                centerText
                            />
                        )}
                        <FormLabel>{name}</FormLabel>
                    </Column>

                    <Column>
                        {cancelledAt && (
                            <Hidden>
                                <Pill
                                    color={warningAction}
                                    label={i18n.proposalForm.header.cancelled}
                                    noShadow
                                    centerText
                                />
                            </Hidden>
                        )}
                        <SingleLineRow itemSpacing="small">
                            <FormLabel>{dateDisplay}</FormLabel>
                            {!isBelow1281 && datesFlexible && (
                                <DatesFlexibleCheckbox
                                    label={i18n.proposalForm.header.datesAreFlexible}
                                    onChange={() => {}}
                                    isChecked={!!datesFlexible}
                                    disabled={false}
                                />
                            )}
                        </SingleLineRow>
                    </Column>

                    {isBelow1281 && datesFlexible && (
                        <DatesFlexibleCheckbox
                            label={i18n.proposalForm.header.datesAreFlexible}
                            onChange={() => {}}
                            isChecked={!!datesFlexible}
                            disabled={false}
                        />
                    )}
                    {datesFlexible && (
                        <FlexibleNotesField
                            value={data || { datesFlexibleNotes }}
                            onChange={({ value }: { value: TDatesFlexibleValue }) => onChange(value)}
                            disabled={disabled}
                        />
                    )}
                </Column>
            </FlexCard>

            <StaticCard>
                <IconHeadline icon={<DocumentIcon />} headline={i18n.proposalForm.header.clientNotes} />
                <Spacer small />
                <InquiryNotes regular>{notes}</InquiryNotes>
            </StaticCard>
        </CardsHeading>
    );
};
