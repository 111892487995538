import { useSnackbar } from 'notistack';
import React from 'react';
import { eventTemplatesActions } from './store';

export default function LoadPlaybook({ id }: { id: number | string }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            eventTemplatesActions.load(id);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [id, enqueueSnackbar]);

    return null;
}
