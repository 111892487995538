import { Avatar, Box, Tab as MuiTab, Tabs as MuiTabs, Typography, styled } from '@mui/material';
import { DataGridPro, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { GridContainer } from 'components/BizlyOS/GridContainer';
import { SettingsToolBar } from 'components/BizlyOS/SettingsToolBar';
import { StaffProfileDrawer, StaffProfileFormData } from 'components/BizlyOS/StaffProfileDrawer';
import { PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { StaffMemberSchema, useStaffQuery } from 'hooks/queries/BizlyOS/useStaffQuery';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import React, { useMemo, useState } from 'react';
import { EColors, getColor } from 'theme';
import { tzMoment, userTimeZone } from 'utils/moment';
import { z } from 'zod';

const Tabs = styled(MuiTabs)(() => ({
    '& .MuiTabs-indicator': {
        backgroundColor: getColor(EColors.pureBlack),
    },
}));

const Tab = styled(MuiTab)(({ theme }) => ({
    color: theme.palette.common.black,

    '&.Mui-selected': {
        color: getColor(EColors.pureBlack),
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
    flexGrow: 1,

    '& .MuiDataGrid-root': {
        border: 'none',
    },

    '& .MuiToggleButtonGroup-root': {
        margin: theme.spacing(0.5, 0),
    },

    '& .MuiDataGrid-columnHeader': {
        borderRight: 'none',
        backgroundColor: theme.palette.grey[100],
    },
}));

const columns: GridColDef[] = [
    {
        field: 'member',
        headerName: 'Members',
        flex: 2,
        renderCell: params => {
            return (
                <Box display="flex" alignItems="center" height="100%">
                    <Avatar src={params.row.imageUrl.includes('default-avatar') ? '' : params.row.imageUrl} />
                    <Box ml={2}>
                        <Typography variant="body1">{`${params.row.firstName} ${params.row.lastName}`}</Typography>
                        <Typography variant="body2" color="textSecondary">
                            {params.row.email}
                        </Typography>
                    </Box>
                </Box>
            );
        },
    },
    {
        field: 'title',
        headerName: 'Title',
        flex: 1.5,
        valueGetter: params => params || 'N/A',
    },
    {
        field: 'role',
        headerName: 'Contact Type',
        flex: 1,
        valueGetter: params => params,
    },
    {
        field: 'admin',
        headerName: 'Permission',
        flex: 1,
        valueGetter: params => {
            return params ? 'Admin' : 'Member';
        },
    },
    {
        field: 'createdAt',
        headerName: 'Joined Date',
        flex: 1,
        valueGetter: params => {
            return tzMoment(params, userTimeZone).format('MMM D, YYYY');
        },
    },
    {
        field: 'lastActiveAt',
        headerName: 'Last Active',
        flex: 1,
        valueGetter: params => {
            if (params) {
                return tzMoment(params, userTimeZone).format('MMM D, YYYY');
            }
            return 'Never';
        },
    },
];

type StaffMember = z.infer<typeof StaffMemberSchema>;

const DEFAULT_STATE: StaffProfileFormData = {
    id: 10,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: '',
    department: '',
    contactType: '',
    permission: 0,
    receiveEmails: true,
    imageUrl: '',
    joinedDate: '',
};

const SettingsTab = () => {
    const [mainTabValue, setMainTabValue] = useState('users');
    const [userStatusTab, setUserStatusTab] = useState('active');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<StaffProfileFormData>(DEFAULT_STATE);

    const { venueId, user } = useBizlyOSUser();
    const { staffMembers, isLoading } = useStaffQuery(venueId || '');

    const isCurrentUserAdmin = useMemo(() => {
        if (staffMembers && user) {
            return staffMembers.find(staffMember => staffMember.id === user.id)?.admin ?? false;
        }
    }, [user, staffMembers]);

    const handleMainTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setMainTabValue(newValue);
    };

    const handleUserStatusTabChange = (_: React.MouseEvent<HTMLElement>, newTab: string | null) => {
        if (newTab !== null) {
            setUserStatusTab(newTab);
        }
    };

    const handleRowClick = (user: StaffMember) => {
        const transformedUser: StaffProfileFormData = {
            ...DEFAULT_STATE,
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone || '',
            title: user.title || '',
            department: user.department || '',
            contactType: user.contactType || '',
            permission: user.roleId || 0,
            receiveEmails: user.notifications.inquiries,
            imageUrl: user.imageUrl || '',
            joinedDate: user.createdAt,
        };

        setSelectedUser(transformedUser);
        setDrawerOpen(true);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                <Tabs value={mainTabValue} onChange={handleMainTabChange}>
                    <Tab label="General" value="general" />
                    <Tab label="Users" value="users" />
                    <Tab label="Plan & Billing" value="planAndBilling" />
                </Tabs>
            </Box>

            {!isLoading && mainTabValue === 'users' && (
                <StyledDataGridPro
                    rows={staffMembers}
                    columns={columns}
                    rowHeight={50}
                    density="comfortable"
                    onRowClick={(params: GridRowParams<StaffMember>) => handleRowClick(params.row)}
                    slots={{
                        toolbar: SettingsToolBar,
                    }}
                    slotProps={{
                        toolbar: {
                            onUserStatusChange: handleUserStatusTabChange,
                            totalUsers: staffMembers?.length,
                            onInviteClick: () => {
                                setSelectedUser(DEFAULT_STATE);
                                setDrawerOpen(true);
                            },
                        },
                    }}
                />
            )}

            <StaffProfileDrawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                staffMember={selectedUser}
                isCurrentUserAdmin={isCurrentUserAdmin}
            />
        </>
    );
};

export const Settings = () => {
    return (
        <GridContainer>
            <PageHeadline withDescription bold>
                Settings
            </PageHeadline>
            <SettingsTab />
        </GridContainer>
    );
};
