import { Grid, GridSize } from '@material-ui/core';
import React from 'react';
import { statusColors } from 'shared';
import styled from 'styled-components';
import { colorStyledFn, EColors, getColor, TColorStyledFn } from 'theme';
import { i18n } from 'translation';
import { Column, Row } from 'ui';

const GridContainer = styled(Grid)`
    flex: 1;
`;

const GridItem = styled(Grid)`
    display: flex;
    flex-direction: column;
    min-height: 110px;
`;

const Digit = styled.span`
    font-size: 32px;
    font-weight: 300;
`;

const TallyColumn = styled(Column)<TColorStyledFn & { clickable: boolean }>`
    flex: 1;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 1em 0;
    aspect-ratio: 1/1;
    cursor: ${props => (props.clickable ? 'pointer' : 'default')};
    position: relative;

    ${colorStyledFn}

    ${({ $color }) => ($color ? `background-color: ${getColor($color as EColors, 0.12)};` : ``)}
`;

const TallyLabel = styled('span')`
    text-align: center;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 400;
`;

const TallyDescription = styled('span')`
    text-align: center;
    font-size: 0.6rem;
    font-weight: 400;
    position: absolute;
    padding: 0 1rem;
    width: 100%;
    top: calc(50% + 2rem);
    box-sizing: border-box;
`;

export const statusLabel: Record<string, string> = {
    // This is a workaround to bypass attendee statuses being enumerable values and not easily modified
    total: i18n.guests.total,
    invited: i18n.guests.invited,
    attending: i18n.guests.attending,
    'not attending': i18n.guests.declined,
    roomBlocks: i18n.guests.roomBlocks,
};

type TTally = {
    label: keyof typeof statusColors;
    tally?: number;
    onClick?: (label: string) => void;
    description?: string;
};

const Tally = ({ label, tally = 0, onClick, description }: TTally) => (
    <TallyColumn
        $color={statusColors[label] || EColors.pureBlack}
        onClick={() => {
            onClick && onClick(label);
        }}
        clickable={!!onClick}
    >
        <Digit>{tally}</Digit>
        <TallyLabel>{statusLabel[label]}</TallyLabel>
        {description && <TallyDescription>{description}</TallyDescription>}
    </TallyColumn>
);

const TallyDisplay = ({
    tallies = [],
    itemSize = 6,
    flex = false,
    ...props
}: {
    tallies?: TTally[];
    itemSize?: GridSize;
    flex?: boolean;
    style?: React.CSSProperties;
}) =>
    flex ? (
        <Row justifyContent="space-between" itemSpacing="small">
            {tallies.map(tally => (
                <Tally key={tally.label} {...tally} />
            ))}
        </Row>
    ) : (
        <GridContainer container spacing={2} {...props}>
            {tallies.map(tally => (
                <GridItem item xs={itemSize} key={tally.label}>
                    <Tally {...tally} />
                </GridItem>
            ))}
        </GridContainer>
    );

export default TallyDisplay;
