import fontFns from 'fontFns';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Copy, Row, Spacer } from '../../ui';
import { H2Headline } from '../ui/Headline';

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
`;

const SpacedRow = styled(Row)`
    align-items: flex-start;
`;

const Label = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
    margin-right: 12px;
    white-space: nowrap;
`;

const TextRowText = styled(Copy)`
    text-align: right;
`;

const TextRow = ({ label, value }: { label: string; value?: React.ReactText }) => (
    <SpacedRow>
        <Label>{label}</Label>
        <TextRowText>{value}</TextRowText>
    </SpacedRow>
);

const GreyBox = styled.div`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
    padding: 20px;
    border-radius: 8px;
`;

const ErrorContainer = styled.div`
    text-align: center;
    margin-top: 25%;
`;

const ErrorBox = () => (
    <ErrorContainer>
        <h1>{i18n.error.somethingWentWrong}</h1>
        <p>{i18n.error.refreshAndTry}</p>
    </ErrorContainer>
);

type TCredentials = {
    start: string;
    end: string;
    expDate: string;
    cardNumber: string;
    securityCode: string;
    zipCode: string;
};

const ViewPaymentCard = ({ credentials, error }: { credentials?: TCredentials; error: boolean }) =>
    !error ? (
        <>
            <H2Headline>{i18n.venue.proposal.payment.cardHeadline}</H2Headline>
            <Spacer />

            <SpacedCopy>{i18n.venue.proposal.payment.cardSubheadline}</SpacedCopy>
            <Spacer />

            <SpacedCopy>{i18n.venue.proposal.payment.accountActiveSince}</SpacedCopy>
            <Spacer small />

            <TextRow label={i18n.common.start} value={credentials?.start} />
            <Spacer xsmall />
            <TextRow label={i18n.common.end} value={credentials?.end} />
            <Spacer />

            <GreyBox>
                <TextRow label={i18n.venue.proposal.payment.number} value={credentials?.cardNumber} />
                <Spacer xsmall />
                <TextRow label={i18n.venue.proposal.payment.expDate} value={credentials?.expDate} />
                <Spacer xsmall />
                <TextRow label={i18n.venue.proposal.payment.cvc} value={credentials?.securityCode} />
                <Spacer xsmall />
                <TextRow label={i18n.venue.proposal.payment.billingZip} value={credentials?.zipCode} />
            </GreyBox>
        </>
    ) : (
        <ErrorBox />
    );

export default ViewPaymentCard;
