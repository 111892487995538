import { get, post } from './index';

export const copyParcel = (parcelId: number) => post(`parcels/${parcelId}/copy`);

export const getRSVPInfo = (eventId: number, query: string) =>
    get(`events/${eventId}/rsvp${query}`, { requireToken: false });

export const getPlaybookRSVPPreview = (playbookId: string, parcelId: number) =>
    get(`playbooks/${playbookId}/rsvp-preview/${parcelId}`, { requireToken: false });

export const getRSVPPreview = (eventId: number, query?: string) =>
    get(`events/${eventId}/rsvp-preview${query}`, { requireToken: false });

export const updateRSVPInfo = (eventId: number, query: string, response: any) =>
    post(`events/${eventId}/rsvp/${query}`, response, { requireToken: false });

export const getSurveyInfo = (eventId: number, query: string) =>
    get(`events/${eventId}/survey${query}`, { requireToken: false });

export const respondToSurvey = (eventId: number, query: string, responses: any) =>
    post(`events/${eventId}/survey${query}`, responses, { requireToken: false });
