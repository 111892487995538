import Divider from '@material-ui/core/Divider';
import SurveySection from 'components/Communication/SurveySection';
import { RichTextEditor } from 'components/Form/fields';
import { OptionList } from 'components/OptionList';
import { PageHeadline } from 'components/ui/Headline';
import { Uploader } from 'components/Uploader';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { i18n } from 'translation';
import { Prompted, PromptedTextField, Spacer, TextField } from 'ui';
import { EditParcelState, TEventParcelContext } from '../EditParcel';
import { Centered, FormLabel, Subheader } from './styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
    width: 100%;

    .MuiFormControl-root {
        width: 100%;
    }

    & .MuiInputBase-root {
        font-size: 2rem;
        width: 100%;
    }
`;

const TitleContainer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const EditButton = styled(IconButton)<{ isEditable: boolean | undefined }>`
    font-size: 1.5rem;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    display: ${({ isEditable }) => isEditable ? `block` : `none`};
`;

const NotePage = ({
    context,
    handleChange,
    state,
    setState,
    handleUploadChange,
    hasQuestions,
    setHasQuestions,
}: {
    context: TEventParcelContext;
    handleChange: (value: any, field: string) => void;
    state: EditParcelState;
    setState: (state: EditParcelState) => void;
    handleUploadChange: (response: any) => void;
    hasQuestions: boolean;
    setHasQuestions: (hasQuestions: boolean) => void;
}) => {
    const { attendees, parcel, recipients } = state;

    const eventId = context.id;
    const [isEditingTitle, setIsEditingTitle] = useState(false);

    return (
        <Centered>
            <PageHeadline>
                {isEditingTitle ? (
                    <Box style={{ width: '100%' }}>
                        <StyledTextField
                            onChange={e => handleChange(e.target.value, 'name')}
                            value={parcel.name || ''}
                            variant="outlined"
                        />
                    </Box>
                ) : (
                    <TitleContainer>
                        <span>{parcel.name || i18n.communication.inviteTitle}</span>
                        <EditButton
                            onClick={() => setIsEditingTitle(true)}
                            isEditable={!parcel.sent && context.editable}
                        >
                            <EditIcon fontSize="inherit" />
                        </EditButton>
                    </TitleContainer>
                )}
            </PageHeadline>
            <Subheader>{i18n.communication.subHeaderNote}</Subheader>

            <Spacer />

            {!context.asPlaybookParcel && (
                <>
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.recipients}</FormLabel>
                    <OptionList
                        eventId={eventId}
                        disableAll={parcel.sent || !context.editable}
                        prompt={i18n.communication.recipientsPrompt}
                        onChange={(selection: number[]) => {
                            setState({ ...state, recipients: selection }); // We track recipients seperately from the parcel, to maintain an up to date list.
                            handleChange(selection, 'recipients');
                        }}
                        optionFormatter={(attendee: BizlyAPI.Attendee) =>
                            attendee.firstName && attendee.lastName
                                ? `${attendee.firstName} ${attendee.lastName} - ${attendee.email}`
                                : attendee.email
                        }
                        options={attendees}
                        optionsLabel={i18n.communication.attendees}
                        selected={parcel.recipients || recipients}
                    />
                    <Spacer />
                </>
            )}
            <PromptedTextField
                onChange={e => handleChange(e.target.value, 'subject')}
                disabled={parcel.sent || !context.editable}
                placeholder={i18n.communication.subjectPlaceholder}
                prompt={i18n.communication.subject}
                value={parcel.subject || ''}
                variant="outlined"
            />
            <Spacer />

            <Prompted prompt={i18n.communication.message}>
                <RichTextEditor
                    field="content"
                    disabled={parcel.sent || !context.editable}
                    placeholder={i18n.communication.messagePlaceholder}
                    value={parcel.content || ''}
                    onChange={e => handleChange(e.value, 'content')}
                />
            </Prompted>

            <Spacer />
            {(!parcel.sent || parcel.customImage) && (
                <>
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.image}</FormLabel>
                    <Uploader
                        disabled={parcel.sent || !context.editable}
                        ctaLabel={i18n.communication.addImage}
                        description={i18n.communication.imageUploadDescription}
                        onChange={(newValue: any) => {
                            handleChange(newValue?.url, 'customImageUrl');
                            handleChange(newValue, 'customImage');
                        }}
                        prompt={i18n.communication.imageUploadPrompt}
                        resource={isEmpty(parcel.customImage) ? null : parcel.customImage}
                    />
                    <Spacer />
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.addLogo}</FormLabel>
                    <Uploader
                        disabled={parcel.sent || !context.editable}
                        ctaLabel={i18n.communication.addLogo}
                        description={i18n.communication.logoUploadDescription}
                        onChange={(response: any) => handleUploadChange(response)}
                        prompt={i18n.communication.imageUploadPrompt}
                        resource={isEmpty(parcel.customLogo) ? null : parcel.customLogo}
                    />
                    <Spacer />
                </>
            )}

            <Divider />
            <SurveySection
                eventId={context.id}
                parcel={parcel}
                attendees={attendees}
                handleChange={handleChange}
                editable={!!context.editable}
                hasQuestions={hasQuestions}
                setHasQuestions={setHasQuestions}
            />
        </Centered>
    );
};

export default NotePage;
