import { Avatar } from 'components/ui/Avatar';
import FileIconSVG from 'images/icons/file.svg?react';
import { tz as momentTz } from 'moment-timezone';
import { withIconStyles } from 'shared';
import styled from 'styled-components';
import { EColors } from 'types/themenum';
import { Column, Copy, Row } from 'ui';

const Bubble = styled.div`
    width: 262px;
    padding: 18px 16px 17px 16px;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.listItemBackground)};
    font-size: 13px;
`;

const AttachmentText = styled.a`
    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
`;

const TextRow = styled(Row)`
    white-space: pre-wrap;
`;

const FileIcon = withIconStyles(FileIconSVG);

type TBaseAttachment = {
    id: number;
    fileName: string;
    url: string;
};

type TMessageBubbleProps = {
    author: {
        firstName: string;
        lastName: string;
        imageUrl: string;
    };
    sentAt: string;
    text?: string;
    attachments?: TBaseAttachment[];
};

const formatTimeStamp = (sentAt: string) => {
    const lastSeen = momentTz(sentAt, 'UTC');
    const now = momentTz();

    if (now.diff(lastSeen, 'days') < 1) {
        return lastSeen.fromNow();
    }

    if (lastSeen.month() === now.month() && lastSeen.year() === now.year()) {
        return lastSeen.format('MMMM Do');
    }

    return lastSeen.format('MMMM Do, YYYY');
};

const MessageBubble = ({ author, text, attachments, sentAt }: TMessageBubbleProps) => {
    return (
        <Column>
            <Row itemSpacing="small" alignItems="center">
                <Avatar user={{ ...author, email: '' }} />
                <Bubble>
                    <Column itemSpacing="small">
                        {text && <TextRow>{text}</TextRow>}
                        {attachments?.map(attachment => (
                            <Row key={attachment.id} alignItems="center">
                                <FileIcon />
                                <AttachmentText href={attachment.url} target="_blank">
                                    {attachment.fileName}
                                </AttachmentText>
                            </Row>
                        ))}
                    </Column>
                </Bubble>
            </Row>
            <Row itemSpacing="small" justifyContent={'flex-end'}>
                <Copy small $color={EColors.grey}>
                    {formatTimeStamp(sentAt)}
                </Copy>
            </Row>
        </Column>
    );
};

export default MessageBubble;
