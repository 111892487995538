import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@mui/styles';

const tabStyles = ({ getColor, EColors }) => ({
    root: {
        minWidth: 'unset',
        fontWeight: '400',
    },
    labelContainer: {
        padding: '0',
    },
    selected: {
        fontWeight: '500',
        borderBottomColor: getColor(EColors.pureBlack),
    },
});

const styledTab = withStyles(tabStyles)(Tab);

const tabsStyles = ({ getColor, EColors }) => ({
    flexContainer: {
        justifyContent: 'space-between',
    },
    indicator: {
        backgroundColor: getColor(EColors.primaryAction),
    },
});

const styledTabs = withStyles(tabsStyles)(Tabs);

export { styledTab as Tab, styledTabs as Tabs };
