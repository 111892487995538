import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import TextButton from '../Button/TextButton';

const TextParagraphWrapper = styled.p<{ expanded?: boolean }>`
    ${props =>
        !props.expanded
            ? `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `
            : ``}
`;

const TextWrapper = styled.div<{ expanded?: boolean }>`
    ${props =>
        !props.expanded
            ? `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `
            : ``}
`;

const ButtonWrapper = styled.div`
    text-align: right;
`;

const Button = styled(TextButton)`
    font-size: 0.8em;
`;

type ExpandableTextProp = {
    content?: string;
    html?: string;
};

const ExpandableText = ({ content, html }: ExpandableTextProp) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef<HTMLParagraphElement>(null);
    const [contentOverflowing, setContentOverflowing] = useState(false);

    const handleToggle = () => setIsExpanded(!isExpanded);

    useLayoutEffect(() => {
        const contentElement = contentRef.current;
        if (!contentElement) return;

        const overflowing = contentElement.offsetWidth < contentElement.scrollWidth;
        setContentOverflowing(overflowing);
    }, [contentRef]);

    if (!html && !content) return null;

    return (
        <>
            {content && (
                <TextParagraphWrapper ref={contentRef} expanded={isExpanded}>
                    {content}
                </TextParagraphWrapper>
            )}
            {html && <TextWrapper ref={contentRef} expanded={isExpanded} dangerouslySetInnerHTML={{ __html: html }} />}
            {contentOverflowing && (
                <ButtonWrapper>
                    <Button onClick={handleToggle}>
                        {isExpanded ? i18n.approvals.showLess : i18n.approvals.showMore}
                    </Button>
                </ButtonWrapper>
            )}
        </>
    );
};

export default ExpandableText;
