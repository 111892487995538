import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, styled } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';

const StyledSelect = styled(Select)(({ theme: { getColor, EColors, shape } }) => ({
    height: '50px',
    width: '100%',
    fontSize: '0.875rem',
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid ' + getColor(EColors.bizlyOSBorder),
        borderRadius: shape.borderRadius,
    },
}));

const StyledInputLabel = styled(InputLabel)(({ theme: { getColor, EColors } }) => ({
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    '&.MuiFormLabel-root': {
        top: '2px',
    },
    '&.Mui-focused': {
        color: getColor(EColors.pureBlack),
    },
}));

type SelectFieldProps = {
    options: { value: number | string; label: string }[];
    label: string;
    placeholder?: string;
    emptyMessage?: string;
    error: FieldError | undefined;
    onChange: (...event: any[]) => void;
    onBlur: () => void;
    value: string | undefined | number;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
    ref: (instance: any) => void;
};

export default function SelectField({
    options = [],
    label,
    error,
    onChange,
    emptyMessage = '',
    required = false,
    ...field
}: SelectFieldProps) {
    return (
        <Box>
            <Box display="flex" gap={0.5} marginBottom={1}>
                <Typography variant="body2" fontWeight={500}>
                    {label}
                </Typography>
                {required && (
                    <Typography variant="body2" color={getColor(EColors.bizlyOSPrimary)} fontWeight={500}>
                        *
                    </Typography>
                )}
            </Box>
            <FormControl fullWidth>
                {!field.value && (
                    <StyledInputLabel shrink={false} sx={{ top: '-3px', color: '#000' }}>
                        Select
                    </StyledInputLabel>
                )}
                <StyledSelect IconComponent={KeyboardArrowDownIcon} onChange={onChange} {...field}>
                    {options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}

                    {options.length === 0 && !!emptyMessage ? (
                        <MenuItem value={'empty_message'} disabled>
                            {emptyMessage}
                        </MenuItem>
                    ) : null}
                </StyledSelect>
            </FormControl>
            {!!error && (
                <Typography color="red" variant="caption" fontWeight={500} marginBottom={1}>
                    {error.message}
                </Typography>
            )}
        </Box>
    );
}
