import { isAddCollaboratorsPartialError } from 'api';
import { isBlankTemplateId } from 'api/eventTemplates';
import {
    addPlaybookCollaborators,
    addPlaybookResource,
    createPlaybook,
    deletePlaybookResource,
    getPlaybook,
    getPlaybookCollaborators,
    getPlaybookOptions,
    getPlaybookResources,
    getPlaybooks,
    removePlaybookCollaborator,
    updatePlaybookCollaborator,
} from 'api/playbooks';
import keyBy from 'lodash/keyBy';
import createStore from '../';

const PENDING_ADD_COLLABORATOR_ID = -1;

type State = {
    loadingAll: boolean;
    loadingPlaybookOptions?: boolean;
    playbookOptions?: BizlyAPI.Complete.PlaybookOptions;

    playbooksById: Partial<{
        [id: number]: BizlyAPI.Complete.Playbook;
        [id: string]: BizlyAPI.Complete.Playbook;
    }>;
    loadingById: Partial<{ [id: number]: boolean; [id: string]: boolean }>;

    collaboratorsById: Partial<{
        [id: number]: BizlyAPI.EventCollaboratorOrPending[];
        [id: string]: BizlyAPI.EventCollaboratorOrPending[];
    }>;
    loadingCollaboratorsById: Partial<{ [id: number]: boolean; [id: string]: boolean }>;

    resourcesById: Partial<{
        [id: number]: Bizly.EventResource[];
        [id: string]: Bizly.EventResource[];
    }>;
    loadingResourcesById: Partial<{ [id: number]: boolean; [id: string]: boolean }>;
};

type Store = State;

const initialState: State = {
    loadingAll: false,
    loadingPlaybookOptions: false,
    playbooksById: {},
    loadingById: {},
    collaboratorsById: {},
    loadingCollaboratorsById: {},
    resourcesById: {},
    loadingResourcesById: {},
};

export const usePlaybooks = createStore<Store>(() => initialState);

const { setState, getState } = usePlaybooks;

const collaborationFormActions = {
    loadPlaybookCollaborators: async (playbookId: number | string) => {
        if (getState().loadingCollaboratorsById[playbookId]) return;
        setState({ loadingCollaboratorsById: { ...getState().loadingCollaboratorsById, [playbookId]: true } });

        try {
            const { collaborators } = await getPlaybookCollaborators(playbookId);
            setState({
                loadingCollaboratorsById: { ...getState().loadingCollaboratorsById, [playbookId]: false },
                collaboratorsById: { ...getState().collaboratorsById, [playbookId]: collaborators },
            });
            return collaborators;
        } catch (e) {
            setState({
                loadingCollaboratorsById: { ...getState().loadingCollaboratorsById, [playbookId]: false },
            });

            throw e;
        }
    },
    loadPlaybookResources: async (playbookId: number | string) => {
        if (getState().loadingResourcesById[playbookId]) return;
        setState({ loadingResourcesById: { ...getState().loadingResourcesById, [playbookId]: true } });

        try {
            const { resources } = await getPlaybookResources(playbookId);
            setState({
                loadingResourcesById: { ...getState().loadingResourcesById, [playbookId]: false },
                resourcesById: { ...getState().resourcesById, [playbookId]: resources },
            });
            return resources;
        } catch (e) {
            setState({ loadingResourcesById: { ...getState().loadingResourcesById, [playbookId]: false } });

            throw e;
        }
    },
    addPlaybookCollaborators: async (
        newCollaborators: BizlyAPI.NewEventCollaborator[],
        playbookId: number | string
    ) => {
        const currentCollaborators = selectCollaborators(playbookId)(getState());
        if (!currentCollaborators) return;
        const currentEmails = new Set(currentCollaborators.map(c => c.email));

        const newAdditions = newCollaborators.filter(c => !currentEmails.has(c.email));

        // -1 is the "pending add collaborator ID", according to the constant set in the original event-collaborators provider
        const nextCollaborators = [
            ...currentCollaborators,
            ...newAdditions.map(c => ({ ...c, id: PENDING_ADD_COLLABORATOR_ID })),
        ];
        setState({ collaboratorsById: { ...getState().collaboratorsById, [playbookId]: nextCollaborators } });

        // The API returns with the event's collaborators, we replace the array wholesale, not worrying about partial failures
        try {
            const { collaborators } = await addPlaybookCollaborators(newAdditions, playbookId);
            const prevCollaborators = selectCollaborators(playbookId)(getState());
            // We only replace if no one else has attempted to optimistically update again collaborators by this point
            setState({
                collaboratorsById: {
                    ...getState().collaboratorsById,
                    [playbookId]: prevCollaborators === nextCollaborators ? collaborators : prevCollaborators,
                },
            });
        } catch (e) {
            const allNewEmails = newAdditions.map(collaborator => collaborator.email);
            const prevCollaborators = selectCollaborators(playbookId)(getState());

            if (isAddCollaboratorsPartialError(e)) {
                // We only replace if no one else has attempted to optimistically update again collaborators by this point
                setState({
                    collaboratorsById: {
                        ...getState().collaboratorsById,
                        [playbookId]: prevCollaborators === nextCollaborators ? e.raw.collaborators : prevCollaborators,
                    },
                });
            } else {
                const failedEmailsSet = new Set(allNewEmails);
                // filter out our changes, we don't just revert because if there was another optimistic update, we don't want to lose those
                if (prevCollaborators)
                    setState({
                        collaboratorsById: {
                            ...getState().collaboratorsById,
                            [playbookId]: prevCollaborators.filter(
                                collaborator => !failedEmailsSet.has(collaborator.email)
                            ),
                        },
                    });
            }

            throw e;
        }
    },
    updatePlaybookCollaborator: async (playbookId: number | string, collaboratorId: number, editor: boolean) => {
        if (collaboratorId === PENDING_ADD_COLLABORATOR_ID) return;
        const currentCollaborators = selectCollaborators(playbookId)(getState());
        if (!currentCollaborators) return;
        const collaboratorToUpdate = currentCollaborators.find(collaborator => collaborator.id === collaboratorId);

        setState({
            collaboratorsById: {
                ...getState().collaboratorsById,
                [playbookId]: currentCollaborators.map(collaborator =>
                    collaborator.id !== collaboratorId
                        ? collaborator
                        : { ...collaborator, editor: !collaborator.editor }
                ),
            },
        });

        try {
            await updatePlaybookCollaborator(playbookId, collaboratorId, editor);
        } catch (error) {
            // refetch in case there has been another change to the state
            const prevCollaborators = selectCollaborators(playbookId)(getState());

            if (collaboratorToUpdate) {
                setState({
                    ...getState().collaboratorsById,
                    [playbookId]: prevCollaborators,
                });
            }

            throw error;
        }
    },
    removePlaybookCollaborator: async (playbookId: number | string, collaboratorId: number) => {
        if (collaboratorId === PENDING_ADD_COLLABORATOR_ID) return;
        const currentCollaborators = selectCollaborators(playbookId)(getState());
        if (!currentCollaborators) return;
        const collaboratorToRemove = currentCollaborators.find(collaborator => collaborator.id === collaboratorId);

        setState({
            collaboratorsById: {
                ...getState().collaboratorsById,
                [playbookId]: currentCollaborators.filter(collaborator => collaboratorId !== collaborator.id),
            },
        });

        try {
            await removePlaybookCollaborator(playbookId, collaboratorId);
        } catch (error) {
            if (collaboratorToRemove) {
                setState({
                    collaboratorsById: {
                        ...getState().collaboratorsById,
                        [playbookId]: [...currentCollaborators, collaboratorToRemove],
                    },
                });
            }

            throw error;
        }
    },
    addPlaybookResources: async (playbookId: number | string, document: Bizly.EventResource) => {
        const { resource } = await addPlaybookResource(playbookId, document);
        setState({
            resourcesById: {
                ...getState().resourcesById,
                [playbookId]: [...(selectResources(playbookId)(getState()) ?? []), resource],
            },
        });
        return resource;
    },
    removePlaybookResource: async (playbookId: number | string, resourceId: number) => {
        await deletePlaybookResource(playbookId, resourceId);
        const currentResources = selectResources(playbookId)(getState());
        if (currentResources)
            setState({
                resourcesById: {
                    ...getState().resourcesById,
                    [playbookId]: currentResources.filter(resource => resource.id !== resourceId),
                },
            });
        return;
    },
};

export const playbooksActions = {
    ...collaborationFormActions,
    load: async () => {
        setState({
            ...getState(),
            loadingAll: true,
        });

        try {
            const { playbooks } = await getPlaybooks();

            setState({
                loadingAll: false,
                playbooksById: keyBy(
                    playbooks.filter(playbook => !isBlankTemplateId(playbook.id)),
                    playbook => playbook.id
                ),
            });

            return playbooks;
        } catch (e) {
            setState({
                loadingAll: false,
            });
            throw e;
        }
    },

    loadSingle: async (id: number | string) => {
        if (getState().loadingById[id]) return;

        setState({ loadingById: { ...getState().loadingById, [id]: true } });

        try {
            const { playbook } = await getPlaybook(id);
            setState({
                loadingById: { ...getState().loadingById, [id]: false },
                playbooksById: {
                    ...getState().playbooksById,
                    [id]: playbook,
                },
            });
            return playbook;
        } catch (e) {
            setState({ loadingById: { ...getState().loadingById, [id]: false } });
            throw e;
        }
    },

    loadPlaybookOptions: async () => {
        if (getState().loadingPlaybookOptions) return;
        setState({ loadingPlaybookOptions: true });

        const playbookOptions = await getPlaybookOptions();
        setState({
            playbookOptions,
            loadingPlaybookOptions: false,
        });
        return playbookOptions;
    },

    create: async (data: BizlyAPI.Complete.PlaybookCreate) => {
        const { playbook } = await createPlaybook(data);
        setState({
            playbooksById: {
                ...getState().playbooksById,
                [playbook.id]: playbook,
            },
        });
        return playbook;
    },

    merge: (playbook: BizlyAPI.Complete.Playbook) => {
        setState({
            playbooksById: {
                ...getState().playbooksById,
                [playbook.id]: playbook,
            },
        });
    },
};

export const isLoading = (id: number | string) => (state: State) => state.loadingById[id];

export const selectPlaybook = (id?: string | number) => (state: State) =>
    id !== undefined ? state.playbooksById?.[id] : undefined;

export const selectPlaybooksAsList = (state: State) =>
    state.playbooksById ? (Object.values(state.playbooksById) as BizlyAPI.Complete.Playbook[]) : [];

export const selectCollaborators = (id?: string | number) => (state: State) =>
    id !== undefined ? state.collaboratorsById?.[id] : undefined;

export const selectResources = (id?: string | number) => (state: State) =>
    id !== undefined ? state.resourcesById?.[id] : undefined;
