import Tooltip from '@material-ui/core/Tooltip';
import MuiInfoIcon from '@material-ui/icons/InfoOutlined';
import LeafImage from 'images/icons/leaf.svg?react';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { AlignedRow, Column, Copy, ExternalLink, PreCopy } from 'ui';

const ScoreContainer = styled(AlignedRow)`
    border: 2px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.carbonGreen)};
    padding: 12px;
    border-radius: 4px;
    width: auto;

    @media only screen and (max-width: 1500px) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
    }
`;

const ScoreCopy = styled(AlignedRow)`
    @media only screen and (max-width: 1500px) {
        justify-content: center;
    }
`;

const DecoratedLeafImage = styled(LeafImage)`
    margin-right: 5px;
    height: 1em;
`;

const ColorCopy = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.carbonGreen)};
    white-space: nowrap;
`;

const InfoIcon = styled(MuiInfoIcon)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.carbonGreen)};
    width: 0.7em;
    height: 0.7em;
    margin-left: 5px;
`;

const ScoreMeasurement = styled(Copy)<{ noDecor?: boolean }>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.carbonGreen)};
    white-space: nowrap;
    display: flex;
    ${({ noDecor }) => !noDecor && 'justify-content: flex-end;'}
    align-items: center;
`;

type ThrustCarbonScoreProps = {
    score: number | undefined;
    infoText?: string | React.ReactNode;
    equivalents?: Array<Bizly.Equivalent>;
    noDecor?: boolean;
};

const ThrustCarbonScore = ({ score, infoText, equivalents, noDecor }: ThrustCarbonScoreProps) => {
    const subtext = useMemo(
        () => (equivalents ? equivalents[Math.floor(Math.random() * equivalents.length)].values[0] : null),
        [equivalents]
    );

    const KILO_METRIC = 1000;

    const toolTipText = infoText ? (
        <PreCopy>
            {infoText}
            <br />
            <br />
            {i18n.venue.goToCarbonHelp}
            <ExternalLink href="https://help.bizly.com/en/articles/6662463-what-is-a-carbon-score" openInNewTab>
                {i18n.venue.carbonHelpLink}
            </ExternalLink>
        </PreCopy>
    ) : null;

    const renderScore = () => (
        <ScoreMeasurement noDecor>
            {score === undefined ? (
                i18n.venue.noData
            ) : (
                <>
                    <b>{score * KILO_METRIC}</b>&nbsp;kg CO2
                    {toolTipText && (
                        <Tooltip title={toolTipText} interactive>
                            <InfoIcon />
                        </Tooltip>
                    )}
                </>
            )}
        </ScoreMeasurement>
    );

    return noDecor ? (
        renderScore()
    ) : (
        <Column fillWidth itemSpacing="small">
            <ScoreContainer justifyContent="space-between">
                <ScoreCopy>
                    <DecoratedLeafImage />
                    <ColorCopy>{i18n.venue.carbonMeasurement}</ColorCopy>
                </ScoreCopy>
                {renderScore()}
            </ScoreContainer>
            {subtext && (
                <Copy small faded>
                    * {subtext}
                </Copy>
            )}
        </Column>
    );
};

export default ThrustCarbonScore;
