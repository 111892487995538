import { EventSettingsFields } from 'components/Events/EventSettingsHeaderSection';
import { default as FormBase } from 'components/Form';
import { useUser } from 'providers/user';
import React from 'react';
import styled from 'styled-components';
import { createPlaybookActions } from './store';

const schema = [
    {
        fields: ['costCenter', 'cventId'],
        spacing: 'default',
    },
    {
        fields: ['budget', 'department'],
        spacing: 'default',
    },
    {
        fields: ['type'],
        spacing: false,
    },
];

export type TInternalDetailsValue = Partial<
    Pick<Bizly.Event, 'type' | 'costCenter' | 'cventId' | 'department'> & { budget?: string }
>;

const Form = styled(FormBase)`
    width: 100%;
`;

export default function InternalDetailsForm({ value, readonly }: { value: TInternalDetailsValue; readonly?: boolean }) {
    const { user } = useUser();

    const config = {
        meetingCostCenterFieldLabel: user.team?.meetingCostCenterFieldLabel,
        internalReferencePrompt: user.team?.meetingInternalReferenceFieldLabel,
        requiredFields: user.team?.features.requiredInternalFields,
        costCenterOptions: user.team?.costCenters,
        departmentOptions: user.team?.departments,
    };

    const fields = EventSettingsFields(config);

    return (
        <Form
            fields={fields}
            schema={schema}
            value={value}
            onChange={({ value }: { value: TInternalDetailsValue }) => {
                createPlaybookActions.setInternalForm(value);
                createPlaybookActions.setInternalFormErrors({});
            }}
            readonly={readonly}
            hideReadonlyEmpty
        />
    );
}
