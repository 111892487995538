import { useSnackbar } from 'notistack';
import React from 'react';
import { teamActions } from './store';

export default function LoadTeamMembers({ teamId }: { teamId: string | number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await teamActions.load(teamId);
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };

        load();
    }, [teamId, enqueueSnackbar]);

    return null;
}
