import {
    Box,
    Tab as MuiTab,
    TabProps as MuiTabProps,
    Tabs as MuiTabs,
    TabsProps as MuiTabsProps,
    styled as muiStyled,
    tabClasses,
    tabsClasses,
} from '@mui/material';
import { forwardRef } from 'react';
import { NotificationDot } from '../NotificationDot/NotificationDot';

const StyledTabsContainer = muiStyled(MuiTabs)(({ theme: { getColor, EColors } }) => ({
    [`& .${tabsClasses.indicator}`]: {
        backgroundColor: getColor(EColors.vividFuchsia),
    },
}));

const StyledTab = muiStyled(MuiTab)(({ theme: { getColor, EColors } }) => ({
    [`&.${tabClasses.selected}`]: {
        color: getColor(EColors.pureBlack),
    },
}));

interface TabContainerProps extends MuiTabsProps {}

export const TabContainer = forwardRef<HTMLDivElement, TabContainerProps>((props, ref) => {
    return <StyledTabsContainer {...props} ref={ref} />;
});

interface TabProps extends Omit<MuiTabProps, 'icon'> {
    icon?: React.ReactNode;
    label: string;
    showNotification?: boolean;
}

export const Tab = forwardRef<HTMLDivElement, TabProps>((props, ref) => {
    const { icon, label, showNotification, ...other } = props;
    return (
        <StyledTab
            ref={ref}
            icon={<TabLabel icon={icon} label={label} showNotification={showNotification} />}
            {...other}
        />
    );
});

export const TabLabel = ({
    icon,
    label,
    showNotification,
}: {
    icon?: React.ReactNode;
    label: string;
    showNotification?: boolean;
}) => (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
        <Box display="flex" alignItems="center">
            {icon && (
                <Box display="flex" alignItems="center" mr={1}>
                    {icon}
                </Box>
            )}
            <Box display="flex" alignItems="center">
                {label}
            </Box>
            {showNotification && (
                <Box display="flex" alignItems="center" ml={0.75}>
                    <NotificationDot />
                </Box>
            )}
        </Box>
    </Box>
);
