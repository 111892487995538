import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Spinner } from './Spinner';
import { Button, Column, CopyFaded, ExpandedPane, LabeledCheckbox, Row, ShadedRow, TopRounded } from '../ui';

const ShadedRowWithBorder = styled(ShadedRow)`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
`;

export class OptionList extends Component {
    state = {
        isEditing: false,
        options: null,
        pending: false,
        revertPoint: null,
        selected: null,
    };

    componentDidMount() {
        this.load();
    }

    async load() {
        const { options, selected } = this.props;

        this.setState({
            pending: true,
        });

        const newState = {
            options,
            pending: false,
            selected: selected || options.map(a => a.id),
        };
        this.setState(newState);
    }

    handleEditClick() {
        this.setState({
            isEditing: true,
            revertPoint: this.state.selected,
        });
    }

    handleSaveClick() {
        this.props.onChange(this.state.selected);
        this.setState({
            isEditing: false,
        });
    }

    handleCancelClick() {
        this.props.onChange(this.state.revertPoint);
        this.setState({
            isEditing: false,
            selected: this.state.revertPoint,
        });
    }

    handleSelectionChange(id, isChecked) {
        // TODO: Once we commit to using contexts, relook at how we couple data through form helper components like this
        const { onChange } = this.props;
        const { selected } = this.state;
        const updatedSelected = isChecked // TODO: Let's consider a more effcient way to pluck selections from an array; a very large attendee list will be a more expensive array traversal
            ? selected.concat(id)
            : selected.filter(attendeeId => attendeeId !== id);

        onChange(updatedSelected, id);
        this.setState({ selected: updatedSelected });
    }

    handleAllSelectionChange() {
        const { onChange } = this.props;
        const { selected, options } = this.state;
        const updatedSelected = options.length === selected.length ? [] : options.map(o => o.id);
        onChange(updatedSelected);
        this.setState({ selected: updatedSelected });
    }

    render() {
        const { eventId, optionFormatter, optionsLabel, prompt, disableAll } = this.props;
        const { isEditing, options, pending, selected } = this.state;
        if (pending) {
            return <Spinner />;
        }

        const label =
            selected && options
                ? selected.length === options.length
                    ? `All ${optionsLabel} (${options.length})`
                    : `Selected ${optionsLabel} (${selected.length})`
                : '';
        return (
            <Column>
                <TopRounded hasContentBelow={isEditing}>
                    {options && options.length > 0 ? (
                        isEditing ? (
                            <>
                                <LabeledCheckbox
                                    disabled={disableAll}
                                    onChange={() => this.handleAllSelectionChange()}
                                    isChecked={selected.length === options.length}
                                    indeterminate={selected.length > 0 && selected.length !== options.length}
                                    label={label}
                                />
                                <Row style={{ width: 'unset' }}>
                                    <Button
                                        onClick={() => this.handleCancelClick()}
                                        variant="outlined"
                                        style={{ marginRight: '8px' }}
                                    >
                                        Cancel
                                    </Button>
                                    {!disableAll && <Button onClick={() => this.handleSaveClick()}>Save</Button>}
                                </Row>
                            </>
                        ) : (
                            <>
                                <span>{label}</span>
                                <Button variant="outlined" onClick={() => this.handleEditClick()}>
                                    {!disableAll ? 'Edit' : 'View'}
                                </Button>
                            </>
                        )
                    ) : (
                        <>
                            <CopyFaded>{prompt}</CopyFaded>
                            <Link to={`/event/${eventId}/guest-list`}>
                                <Button variant="outlined">Add {optionsLabel}</Button>
                            </Link>
                        </>
                    )}
                </TopRounded>
                <ExpandedPane constrain={true}>
                    {isEditing &&
                        options.map(o => (
                            <ShadedRowWithBorder key={o.id}>
                                <LabeledCheckbox
                                    disabled={disableAll}
                                    onChange={e => this.handleSelectionChange(o.id, e.target.checked)}
                                    isChecked={selected.includes(o.id)}
                                    label={optionFormatter(o)}
                                />
                            </ShadedRowWithBorder>
                        ))}
                </ExpandedPane>
            </Column>
        );
    }
}
