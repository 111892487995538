import React from 'react';
import { PopoverContainer, StyledPopover } from 'ui';

const HoverableIcon = ({ children, hoverText }: { children: JSX.Element; hoverText: string }) => {
    const [hoverAnchor, setHoverAnchor] = React.useState<Nullable<Element>>(null);

    return (
        <>
            <StyledPopover
                open={!!hoverAnchor}
                anchorEl={hoverAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <PopoverContainer>{hoverText}</PopoverContainer>
            </StyledPopover>
            {React.cloneElement(children, {
                onMouseEnter: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
                    setHoverAnchor(event.currentTarget),
                onMouseLeave: () => setHoverAnchor(null),
            })}
        </>
    );
};

export default HoverableIcon;
