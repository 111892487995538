import { SelectField } from 'components/FormFields';
import styled, { css } from 'styled-components';

export const ReactDatePickerWrapper = styled.div`
    position: relative;
    .react-datepicker__current-month .react-datepicker__current-month--hasMonthYearDropdown {
        display: none;
    }
    .react-datepicker__month-year-read-view--selected-month-year {
        font-size: 22px !important;
        margin: 10px 0 !important;
    }

    .react-datepicker__header__dropdown--scroll {
        margin: 7.5px 0px !important;
    }

    .react-datepicker__month-year-read-view--down-arrow {
        margin-top: 8px !important;
        margin-right: -10px !important;
    }

    .react-datepicker-popper > div > div > button {
        height: auto;
        margin-top: 10px;
    }

    .react-datepicker__current-month--hasMonthYearDropdown {
        display: none;
    }
    .react-datepicker__month-year-dropdown {
        margin-top: -25px;
    }
    .datePicker input {
        height: 61px;
        border-radius: 10px;
        border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant4)};
        width: 260px;
        padding: 0 20px 0 60px;
        font-size: 15px;
        font-weight: 400;
        background: transparent;
    }
    .react-datepicker__header {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant1)};
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        font-size: 16px;
    }
    .react-datepicker__day-name {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        font-weight: bold;
    }
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
        border-bottom-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant1)};
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }
    .react-datepicker__input-container {
        width: inherit;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker-popper {
        width: 100%;
    }
    .react-datepicker__week {
        display: flex;
        justify-content: space-around;
    }
    .react-datepicker__day-names {
        display: flex;
        justify-content: space-around;
        margin: 0 5px;
    }
    .react-datepicker__navigation-icon,
    .react-datepicker__navigation-icon--next {
        margin-top: 10px;
    }

    .react-datepicker__day {
        height: 1.9rem;
        width: 2.1rem;
        font-size: 15px;
    }

    .react-datepicker__close-icon {
        margin-right: 5px;
        ::after {
            font-size: 15px;
            padding: 3px 4px 5px 4px;
        }
    }
    .react-datepicker__month-year-read-view--selected-month-year {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        font-size: 14px;
        margin: 0 5px;
    }
    .react-datepicker__month-year-read-view--down-arrow {
        margin-top: 3px;
    }

    .react-datepicker {
        width: 100%;
    }
    .react-datepicker__month-container {
        width: 100%;
    }
    .react-datepicker__day--in-range {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant1)};
    }
    .react-datepicker__day--keyboard-selected {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant1)};
    }
    .react-datepicker__day--in-selecting-range {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant1)};
    }
    .react-datepicker__day--in-range:hover {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    }
    .react-datepicker__month-year-dropdown {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }

    .react-datepicker__month-year-option:hover {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant1)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }
    .calendarSvg {
        position: absolute;
        display: flex;
        align-content: center;
        height: 100%;
        justify-content: center;
        left: 25px;
        z-index: 0;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant1)};
    }

    .react-datepicker__close-icon::after {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant1)};
    }
`;

export const ResponsiveRowColumn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 1500px) {
        flex-direction: column;
    }
`;

type TGraphStyle = {
    width: string;
    margin?: string;
    maxWidth?: string;
};

export const GraphStyle = styled.div<TGraphStyle>`
    width: ${props => (props.width ? props.width : '50%')};
    ${({ maxWidth }) =>
        maxWidth &&
        css`
            max-width: ${maxWidth};
        `};

    @media only screen and (max-width: 1500px) {
        width: 100%;
        margin: ${props => (props.margin ? props.margin : '0')};
    }
    .funnel-container {
        max-width: 400px;
        margin: auto;
        .recharts-default-tooltip {
            border-radius: 10px;
        }
    }
`;

export const DropdownStyle = styled(SelectField)`
    fieldset {
        border: none;
    }
    margin-bottom: 0;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant4)};
    border-radius: 10px;
    .MuiOutlinedInput-input {
        margin-right: 20px;
    }
`;

export const TooltipWrapper = styled.div`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.graphColorVariant4)};
    border-radius: 10px;
    padding: 20px;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    margin: 10px 0;
`;

export const SpinnerBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
