import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ColorModel from 'color';
import { CSSProperties } from 'react';
import { ColorsMapping, getThemeState } from 'stores/theme';
import { AppleColors, BizlyColors } from 'themes';
import { BizlyMuiTheme } from 'types/cssprop';
import { EColors, EFonts, ETeams } from 'types/themenum';
import { isColorBright } from 'utils/colors';

export { EColors, EFonts, ETeams };

/*

    DEFINITIONS

*/

const THEMED_COLORS: Record<ETeams, ColorsMapping> = {
    [ETeams.bizly]: BizlyColors,
    [ETeams.apple]: AppleColors,
};

type FontsMapping = Record<EFonts, CSSProperties>;

const FALLBACK_FONTS = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Roboto"',
    '"Oxygen"',
    '"Ubuntu"',
    '"Cantarell"',
    '"Fira Sans"',
    '"Droid Sans"',
    '"Helvetica Neue"',
    'sans-serif',
];

const THEMED_FONTS: Record<ETeams, FontsMapping> = {
    [ETeams.bizly]: {
        body: {
            fontFamily: ['"IBM Plex Sans"', ...FALLBACK_FONTS].join(', '),
        },
        heading: {
            fontFamily: ['"IBM Plex Serif"', ...FALLBACK_FONTS].join(', '),
        },
        formLabel: {
            fontFamily: ['"IBM Plex Sans"', ...FALLBACK_FONTS].join(', '),
        },
        formHeading: {
            fontFamily: ['"IBM Plex Sans"', ...FALLBACK_FONTS].join(', '),
        },
    },
    [ETeams.apple]: {
        body: {
            fontFamily: ['"SF Text"', ...FALLBACK_FONTS].join(', '),
        },
        heading: {
            fontFamily: ['"SF Display"', ...FALLBACK_FONTS].join(', '),
        },
        formLabel: {
            fontFamily: ['"SF Text"', ...FALLBACK_FONTS].join(', '),
            fontWeight: 600,
        },
        formHeading: {
            fontFamily: ['"SF Text"', ...FALLBACK_FONTS].join(', '),
        },
    },
};

/*

    HELPERS

*/

export function getColor(
    color: EColors,
    opacity: number = 1,
    { themeOverride, asSolidHex }: { themeOverride?: ETeams; asSolidHex?: boolean } = {}
) {
    const colorObj = ColorModel(
        themeOverride
            ? THEMED_COLORS[themeOverride][color]
            : getThemeState().colorOverrides[color] ??
                  (THEMED_COLORS[getThemeState().theme][color] || THEMED_COLORS.bizly.pureBlack)
    );

    // Color library may support alpha hex (#RGBA or #ARGB) eventually but we only need solid hex for ie11
    if (asSolidHex) return colorObj.alpha(1).hex() as Themed.Color;

    return `${colorObj.rgb().alpha(opacity).string()}` as Themed.Color;
}

export const getFontStyle = (font: EFonts, themeOverride?: ETeams) =>
    THEMED_FONTS[themeOverride || getThemeState().theme][font] as Themed.Font;

export const getStyledTheme = () => ({
    getColor,
    getFontStyle,
    EColors,
    EFonts,
    theme: getThemeState().theme,
    isThemeBright: isColorBright(getColor(EColors.brand)),
});

export type TStyledTheme = ReturnType<typeof getStyledTheme>;

type TGetColor = typeof getColor;
type TGetFontStyle = typeof getFontStyle;

export const bizlyStyledTheme = () => {
    const getBizlyColor: TGetColor = (color, opacity = 1, options) => {
        return getColor(color, opacity, { ...options, themeOverride: ETeams.bizly });
    };

    const getBizlyFontStyle: TGetFontStyle = font => {
        return getFontStyle(font, ETeams.bizly);
    };

    return {
        getColor: getBizlyColor,
        EColors,
        getFontStyle: getBizlyFontStyle,
        EFonts,
        theme: ETeams.bizly,
        isThemeBright: isColorBright(getBizlyColor(EColors.brand)),
    };
};

function isThemedColor(color: string | Themed.Color): color is Themed.Color {
    if (typeof color !== 'string') return false;

    try {
        ColorModel(color);
        return true;
    } catch (e) {
        return false;
    }
}

export type TColorStyledFn = {
    $color?: EColors | Themed.Color | undefined;
    $colorAlpha?: number;
};

export const colorStyledFn = ({ $color, $colorAlpha }: TColorStyledFn) => {
    return $color
        ? `
    color: ${isThemedColor($color) ? $color : getColor($color, $colorAlpha)};
`
        : '';
};

export const materialUIThemeStyledFn = ({ theme }: { theme: TStyledTheme }) => {
    const { getColor, EColors, getFontStyle, EFonts } = theme;
    return {
        theme: createTheme({
            palette: {
                primary: {
                    main: getColor(EColors.primaryAction),
                    contrastText: getColor(EColors.pureWhite),
                },
                secondary: {
                    main: getColor(EColors.primaryAction),
                },
            },
            components: {
                // Per designs with larger text than MUI default
                MuiTableCell: {
                    styleOverrides: {
                        body: {
                            fontSize: '15px',
                        },
                        head: {
                            fontSize: '15px',
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderRadius: '8px',
                        },
                        root: {
                            '&.Mui-focused': {
                                '&& > fieldset': {
                                    // TODO: Maximum hax. This cannot be the normal way we override Material UI styles
                                    borderColor: `${getColor(EColors.pureBlack, 0.23)} !important`,
                                    borderWidth: '1px !important',
                                    boxShadow: `0 2px 4px 0 ${getColor(EColors.pureBlack, 0.06)}`,
                                },
                            },
                            '&:hover': {
                                '&& > fieldset': {
                                    borderColor: `${getColor(EColors.pureBlack, 0.23)} !important`,
                                    boxShadow: `0 2px 4px 0 ${getColor(EColors.pureBlack, 0.06)}`,
                                },
                            },
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        outlinedPrimary: {
                            borderColor: getColor(EColors.primaryAction),
                            '&:hover': {
                                borderColor: getColor(EColors.primaryAction),
                            },
                        },
                    },
                },
                MuiSnackbarContent: {
                    styleOverrides: {
                        root: {
                            '&[class*="variantInfo"]': {
                                backgroundColor: getColor(EColors.snackbarInfo),
                            },
                            '&[class*="variantError"]': {
                                backgroundColor: getColor(EColors.snackbarError),
                            },
                            '&[class*="variantSuccess"]': {
                                backgroundColor: getColor(EColors.snackbarSuccess),
                            },
                        },
                    },
                },
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            fontSize: '.85rem',
                        },
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: true,
                    },
                },
            },
            typography: {
                button: {
                    textTransform: 'none',
                },
                ...getFontStyle(EFonts.body),
            },
            ...theme,
        }),
    };
};

export const useSnackbarStyles = makeStyles<BizlyMuiTheme>(({ getColor, EColors }) => ({
    variantSuccess: {
        backgroundColor: getColor(EColors.snackbarSuccess),
    },
    variantError: {
        backgroundColor: getColor(EColors.snackbarError),
    },
    variantInfo: {
        backgroundColor: getColor(EColors.snackbarInfo),
    },
}));

export const useBizlySnackbarStyles = makeStyles<BizlyMuiTheme>(({ getColor, EColors }) => ({
    variantSuccess: {
        backgroundColor: getColor(EColors.snackbarSuccess, 1, { themeOverride: ETeams.bizly }),
    },
    variantError: {
        backgroundColor: getColor(EColors.snackbarError, 1, { themeOverride: ETeams.bizly }),
    },
    variantInfo: {
        backgroundColor: getColor(EColors.snackbarInfo, 1, { themeOverride: ETeams.bizly }),
    },
}));
