import { Box, Divider, styled, Typography } from '@mui/material';

const FlexTypography = styled(Typography)({
    flex: 1,
});

type LabeledContentProps = {
    label: string;
    value1: string;
    value2?: string;
};

const LabeledContent = (props: LabeledContentProps) => {
    const { label, value1, value2 } = props;

    return (
        <Box>
            <Typography variant="caption" color="textSecondary" component="p">
                {label}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <FlexTypography variant="body1">{value1}</FlexTypography>
                {value2 && (
                    <>
                        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                        <FlexTypography variant="body1">{value2}</FlexTypography>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default LabeledContent;
