import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { Box, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { TFBRequest } from 'components/ProposalForm/EventSpacesForm/utils';
import { TESBooking, TProposalForm } from 'components/ProposalForm/types';
import map from 'lodash/map';
import size from 'lodash/size';
import { useState } from 'react';
import { EColors, getColor } from 'theme';

const NUMBER_NAMES_BELOW_TWENTY = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
];

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const BorderedBottomContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    paddingBottom: spacing(2.5),
    paddingTop: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&::before': {
            display: 'none',
        },
        borderRadius: theme.shape.borderRadius,
    })
);

const InsightsContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    gap: spacing(1.25),
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: getColor(EColors.pureBlack), fontSize: 22 }} />}
        {...props}
    />
))(({ theme }) => ({
    height: '64px',
    padding: theme.spacing(2.5),
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
    '& .MuiAccordionSummary-content > p': {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '24px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2.5),
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
}));

function TextCard({
    title,
    description,
    borderLeft,
}: {
    title: string;
    description: string | undefined;
    borderLeft?: boolean;
}) {
    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            gap={0.5}
            sx={borderLeft ? { paddingLeft: '20px', borderLeft: '0.5px solid ' + getColor(EColors.bizlyOSBorder) } : {}}
        >
            <Typography variant="body2" fontWeight={500} lineHeight="1.313rem">
                {title}
            </Typography>
            <Typography variant="body2" lineHeight="1.313rem">
                {description || '-'}
            </Typography>
        </Box>
    );
}

const EventInsights = ({ description }: { description: string }) => {
    return (
        <InsightsContainer>
            <Box alignItems="center" gap={1.25}>
                <TipsAndUpdatesOutlinedIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                <Typography variant="body1" fontWeight={700}>
                    Event Insights
                </Typography>
            </Box>
            <Typography variant="body2">{description}</Typography>
        </InsightsContainer>
    );
};

function formatRequest(requests: string[]) {
    if (requests.length === 0) return '';
    if (requests.length === 1) return requests[0];
    if (requests.length === 2) return requests.join(' & ');

    return requests.slice(0, -1).join(', ') + ' & ' + requests[requests.length - 1];
}

type InquiryEventRoomProps = {
    proposalInquryForm: Partial<TProposalForm>;
};

export default function InquiryEventRoom({ proposalInquryForm: { options, eventSpaces } }: InquiryEventRoomProps) {
    const [expanded, setExpanded] = useState<string | false>('');

    const handleChange = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getRoomSetup = (id: number | undefined | null) => {
        if (!id) return '-';

        const roomSetup = options?.spaceSetups.find(rs => rs.id === id);

        if (roomSetup) return roomSetup.name;

        return '-';
    };

    const getAvRequests = (ids: number[] | undefined | null) => {
        if (!ids || ids.length === 0) return '';

        let requests: string[] = [];

        ids.forEach(id => {
            const avOption = options?.avOptions.find(av => av.id === id);
            if (!avOption?.name) return;
            requests.push(avOption?.name);
        });

        return formatRequest(requests);
    };

    const getFbRequests = (fbRequests: TFBRequest[] | undefined) => {
        if (!fbRequests || fbRequests.length === 0) return '';

        let requests: string[] = [];

        fbRequests.forEach(request => {
            const fbOption = options?.fbOptions.find(av => av.id === request.fbOptionId);
            if (!fbOption?.name) return;
        });

        return formatRequest(requests);
    };

    const eventSpacesGroupedByDate = eventSpaces?.reduce(
        (
            acc: {
                [key: string]: TESBooking[];
            },
            item
        ) => {
            const date = item.requestedDate;
            if (!acc[date]) {
                acc[date] = []; // Create a new array if the date doesn't exist
            }
            acc[date].push(item); // Push the current item to the corresponding date
            return acc;
        },
        {}
    );

    if (eventSpaces?.length === 0) {
        return <EventInsights description="The client doesn't need any event rooms" />;
    }

    return (
        <Box display="flex" flexDirection="column" gap="10px">
            <EventInsights
                description={`The client needs ${NUMBER_NAMES_BELOW_TWENTY[eventSpaces?.length || 0]} meeting spaces for 
                    ${size(eventSpacesGroupedByDate)} days, with slightly different capacities and timings. They'll need
                    A/V support and catering for both rooms. Specific details for each day and room are available below.`}
            />

            {map(eventSpacesGroupedByDate, (rooms, date) => {
                return (
                    <Accordion key={date} expanded={expanded === date} onChange={handleChange(date)}>
                        <AccordionSummary>
                            <Typography>{`${date} (${rooms.length} Rooms)`}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {rooms?.map((eventSpace, index) => {
                                const avRequests = getAvRequests(eventSpace.requestedAvIds);
                                const fbRequests = getFbRequests(eventSpace.requestedFb);
                                return (
                                    <BorderedContainer key={index}>
                                        <BorderedBottomContainer>
                                            <TextCard title="Date" description={eventSpace.requestedDate} />
                                            <TextCard
                                                title="Time"
                                                description={`${eventSpace?.requestedStartTime} - ${eventSpace?.requestedEndTime}`}
                                                borderLeft
                                            />
                                        </BorderedBottomContainer>
                                        <BorderedBottomContainer>
                                            <TextCard
                                                title="Guests"
                                                description={`${eventSpace?.requestedGuests} Rounds` || ''}
                                            />
                                            <TextCard
                                                title="Room Setup"
                                                description={getRoomSetup(eventSpace.requestedSetupId)}
                                                borderLeft
                                            />
                                        </BorderedBottomContainer>
                                        {avRequests && (
                                            <BorderedBottomContainer>
                                                <TextCard title="A/V Requests" description={avRequests} />
                                            </BorderedBottomContainer>
                                        )}

                                        {fbRequests && (
                                            <BorderedBottomContainer>
                                                <TextCard title="F&B Requests" description={fbRequests} />
                                            </BorderedBottomContainer>
                                        )}
                                    </BorderedContainer>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
}
