import colorFns from 'colorFns';
import AddedCheckIcon from 'images/icons/added_check.svg?react';
import styled from 'styled-components';

type TCircleCheckIcon = {
    inverted?: boolean;
    onClick?: (e: React.SyntheticEvent) => void;
};
const CircleCheckIcon = styled(AddedCheckIcon)<TCircleCheckIcon>`
    width: 16px;
    height: 16px;
    padding: 6px;
    border-radius: 14px;
    background-color: ${({ inverted, theme: { getColor, EColors } }) =>
        inverted ? getColor(EColors.pureWhite) : getColor(EColors.primaryAction)};
    color: ${({ inverted, theme: { getColor, EColors } }) =>
        inverted ? getColor(EColors.primaryAction) : getColor(EColors.pureWhite)};

    ${props =>
        props.onClick &&
        `
    &:hover,
    &:focus {
        background-color: ${props.inverted ? colorFns.pureWhite(props) : colorFns.primaryActionHover(props)};
        color: ${props.inverted ? colorFns.primaryActionHover(props) : colorFns.pureWhite(props)};
    }
    `}

    flex: 0 0 auto;
    cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

export default CircleCheckIcon;
