import Form from 'components/Form';
import { H5Headline } from 'components/ui/Headline';
import React, { useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, ExternalLink, Spacer } from 'ui';
import { TFormSectionProps, useRegisterValidator } from '../utils';
import { fields, schema } from './contactFormSchema';
import { formDataToProposalForm, getErrorMessage, proposalFormToFormData, TContactFormValues } from './utils';

const ONE_SHEETER_URL =
    'https://bizly-cdn.nyc3.cdn.digitaloceanspaces.com/venue-instructions/One-Sheet-Bizly-for-Venues.pdf';

const Copy = styled.div`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
    white-space: pre-line;
`;

const AssymmetricalLeftCol = styled(Column)`
    margin-left: 48px;
    margin-right: ${48 * 3}px;
`;

const ContactInformationForm = ({ onChange, registerValidator, disabled, ...rest }: TFormSectionProps) => {
    const [contactValues, setContactValues] = useState<TContactFormValues>(proposalFormToFormData(rest));

    const updateContactValues = ({ value }: { value: TContactFormValues }) => {
        setContactValues(value);
        onChange();
    };

    useRegisterValidator(contactValues, registerValidator, getErrorMessage, formDataToProposalForm);

    return (
        <Column>
            <Copy>
                {i18n.proposalForm.contactInformation.feeDescription}
                <ExternalLink
                    onClick={
                        e => e.stopPropagation() // buttons in a form will be treated as submit
                    }
                    href={ONE_SHEETER_URL}
                    openInNewTab
                >
                    {i18n.proposalForm.contactInformation.clickHere}
                </ExternalLink>
                .
            </Copy>
            <Spacer large />
            <H5Headline>{i18n.proposalForm.contactInformation.heading}</H5Headline>
            <Spacer small />
            <Copy>{i18n.proposalForm.contactInformation.description}</Copy>

            <Spacer largest />

            <AssymmetricalLeftCol>
                <Form
                    fields={fields(rest.proposal?.msaDocumentUrl, rest.venue?.country?.code)}
                    schema={schema(rest.proposal?.msaDocumentUrl)}
                    value={contactValues}
                    onChange={updateContactValues}
                    disabled={disabled}
                />
            </AssymmetricalLeftCol>
        </Column>
    );
};

export default ContactInformationForm;
