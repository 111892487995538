import { getEventPlannerData, getPlaybookPlannerData, updateEventPlannerData, updatePlaybookPlannerData } from 'api';
import { TPlannerForm } from 'components/Planner/types';
import { addDayToAccommodations, removeDeletedAccommodations } from 'components/Planner/utils';
import { useCallback, useEffect } from 'react';
import { i18n } from 'translation';

const usePlanner = ({
    playbookId,
    event,
    loading,
    setLoading,
    onLoad,
    onQueuedSave,
    refreshEvent,
    enqueueSnackbar,
    data,
    setData,
    setAccommodationDates,
    setHasSaved,
    defaultData,
}: any) => {
    const getPlannerData = playbookId ? getPlaybookPlannerData : getEventPlannerData;
    const updatePlannerData = playbookId ? updatePlaybookPlannerData : updateEventPlannerData;
    const id = playbookId ?? event.id;

    const loadPlanner = useCallback(async () => {
        setLoading(true);
        try {
            const { planner = {} } = await getPlannerData(id);
            onLoad?.(planner);

            setData((prevData: any) => ({
                ...prevData,
                schedule: planner?.schedule?.length ? planner.schedule : defaultData.schedule,
                eventSpaces: planner?.eventSpaces?.length
                    ? planner.eventSpaces.map((eventSpace, idx) =>
                          !eventSpace.spaceName
                              ? { ...eventSpace, spaceName: i18n.common.meetingSpaceIndex(idx + 1) }
                              : eventSpace
                      )
                    : defaultData.eventSpaces,
                accommodations: planner?.accommodations?.length
                    ? addDayToAccommodations(planner.accommodations)
                    : defaultData.accommodations,
                virtualMeeting: prevData.virtualMeeting,
            }));
        } finally {
            setLoading(false);
        }
    }, [getPlannerData, id, setLoading, onLoad, setData, defaultData]);

    useEffect(() => {
        loadPlanner();
    }, [event.id, getPlannerData, id, setLoading, loadPlanner]);

    const onSave = useCallback(async () => {
        if (loading) return;

        setLoading(true);
        try {
            const { planner = {} } = await getPlannerData(id);

            const updated = {
                eventSpaces: [
                    ...(planner.eventSpaces?.length
                        ? planner.eventSpaces.map(eventSpace => ({
                              ...eventSpace,
                              delete: true,
                          }))
                        : []),
                    ...(data.eventSpaces || []),
                ],
                accommodations: [
                    // Delete old accommodations
                    ...(planner.accommodations?.length
                        ? planner.accommodations.map(accommodation => {
                              return {
                                  ...accommodation,
                                  delete: true,
                              };
                          })
                        : []),
                    // Replace with new accommodations
                    ...(data.accommodations || []),
                ],
            };

            const { planner: updatedPlanner = {} } = await updatePlannerData(id, updated);
            updatedPlanner.accommodations = updatedPlanner.accommodations?.length
                ? addDayToAccommodations(updatedPlanner.accommodations)
                : [];
            updatedPlanner.accommodations = removeDeletedAccommodations(updatedPlanner.accommodations);
            setAccommodationDates((prev: any) => ({
                ...prev,
                hasUpdated: false,
            }));
            setData((prevData: TPlannerForm) => ({
                ...prevData,
                ...updatedPlanner,
            }));

            setHasSaved(true);
            onQueuedSave?.(updatedPlanner);

            enqueueSnackbar(i18n.venue.planner.savedSuccessfully, { variant: 'success' });
        } catch ({ message }: any) {
            enqueueSnackbar(message, { variant: 'error' });
        } finally {
            setLoading(false);
            refreshEvent();
        }
    }, [
        enqueueSnackbar,
        id,
        loading,
        onQueuedSave,
        refreshEvent,
        setData,
        setLoading,
        updatePlannerData,
        setAccommodationDates,
        data,
        getPlannerData,
        setHasSaved,
    ]);

    const onDelete = useCallback(
        async (accommodations: TPlannerForm) => {
            setLoading(true);
            try {
                const { planner } = await updatePlannerData(id, accommodations);

                setData((prevData: TPlannerForm) => ({
                    ...prevData,
                    ...planner,
                }));
                onQueuedSave?.(planner);
            } catch ({ message }: any) {
                enqueueSnackbar(message, { variant: 'error' });
            } finally {
                setLoading(false);
                refreshEvent();
            }
        },
        [enqueueSnackbar, id, refreshEvent, setLoading, updatePlannerData, onQueuedSave, setData]
    );

    return { onSave, onDelete };
};

export default usePlanner;
