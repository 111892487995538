import { getCventEvents } from 'api/cvent';
import { keyBy } from 'lodash';
import createStore from '../';

type State = {
    loadingCventEvents: boolean;
    cventEvents: BizlyAPI.CventEvent[];

    cventEventsById: Partial<{
        [id: number]: BizlyAPI.CventEvent;
        [id: string]: BizlyAPI.CventEvent;
    }>;
    selected: Partial<{
        [id: number]: BizlyAPI.CventEvent;
        [id: string]: BizlyAPI.CventEvent;
    }>;
};

const initialState: State = {
    loadingCventEvents: false,
    cventEvents: [],
    cventEventsById: {},
    selected: {},
};

export const useCvent = createStore<State>(() => initialState);
const { getState, setState } = useCvent;

export const cventActions = {
    load: async () => {
        setState({ ...getState(), loadingCventEvents: true });

        try {
            const { cventEvents } = await getCventEvents();
            setState({
                loadingCventEvents: false,
                cventEvents,
                cventEventsById: keyBy(cventEvents, cventEvent => cventEvent.cventId),
            });

            return cventEvents;
        } catch (e) {
            setState({ loadingCventEvents: false });
            throw e;
        }
    },
};

export const getCventEvent = (id?: string | number) => (state: State) =>
    id !== undefined ? state.cventEventsById?.[id] : undefined;
