import React, { useMemo } from 'react';
import styled from 'styled-components';

import { roomBlockFields, roomBlockSchema } from 'components/Communication/editParcelSchema';
import { i18n } from 'translation';
import { Column, Copy, Line, Spacer } from 'ui';
import { tzMoment } from 'utils/moment';
import Form from '../../Form';
import { H2Headline, H3Headline } from '../../ui/Headline';
import { CustomQuestionsForm } from './CustomQuestionsForm';
import { DietaryQuestionsField } from './DietaryQuestionsField';

const ExpiredInstruction = styled.div`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.venuePinEventMeetingConference)};
    padding: 16px;
`;

const ExpiredTitle = styled(H3Headline)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.alertWarning)};
    font-weight: 600;
    margin-bottom: 4px;
`;

const ExpiredHelperCopy = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.alertWarning)};
`;

const ATTENDANCE_TYPE_VALUES = {
    // if typescripting, enum this
    PHYSICAL: i18n.communication.inPerson,
    VIRTUAL: i18n.communication.virtual,
};

export const DEFAULT_QUESTION_TO_ID = {
    DIETARY_RESTRICTIONS: 1,
    TRAVEL_ACCOMMODATIONS: 3,
    LOGISTICS: 4,
    ATTENDANCE_TYPE: 11,
};

export default function RSVPForm({
    attendee = {},
    formSettings = {},
    onChange: onChangeProp,
    eventRegistration,
    timezone = 'UTC',
    parcelDates,
}) {
    const { dietaryRestrictions, foodAllergies, checkInDate, checkOutDate, ...formValue } = attendee;
    formValue.dietary = { dietaryRestrictions, foodAllergies };
    formValue.customQuestionResponses =
        formValue.customQuestionResponses ?? formValue?.customQuestions?.map(({ id, response }) => ({ id, response }));

    const dueDate = tzMoment(attendee.rsvpDueAt, timezone);
    const dueDateString = onChangeProp ? dueDate.format('LLLL z') : `<${i18n.datetime.date}>`;
    const isPastDue = tzMoment(undefined, timezone).isAfter(dueDate);

    const defaultFieldsEnabledSet = useMemo(
        () => new Set(formSettings.defaultFieldsEnabled?.map(d => d.id)),
        [formSettings.defaultFieldsEnabled]
    );

    const detailsFields = useMemo(
        () => ({
            firstName: {
                prompt: i18n.communication.firstName,
                type: 'text',
                perRow: 2,
                options: {
                    placeholder: i18n.communication.firstNamePlaceholder,
                },
            },
            lastName: {
                prompt: i18n.communication.lastName,
                type: 'text',
                perRow: 2,
                options: {
                    placeholder: i18n.communication.lastNamePlaceholder,
                },
            },
            phone: {
                type: 'phone',
                prompt: i18n.communication.phone,
                perRow: 2,
            },
            email: {
                prompt: i18n.communication.email,
                type: 'text',
                perRow: eventRegistration ? 0 : 2,
                readonly: !eventRegistration,
            },
            dietary: {
                type: DietaryQuestionsField,
                optional: true,
                options: {
                    ...(defaultFieldsEnabledSet?.has(DEFAULT_QUESTION_TO_ID.DIETARY_RESTRICTIONS)
                        ? {
                              dietaryRestrictionOptions: formSettings.dietaryRestrictionOptions,
                              foodAllergyOptions: formSettings.foodAllergyOptions,
                          }
                        : {}),
                },
            },
            attendanceType: {
                prompt: i18n.communication.attendanceType,
                type: 'radioselect',
                options: {
                    options: Object.values(ATTENDANCE_TYPE_VALUES),
                    perRow: 4,
                },
                optional: false,
            },
            customQuestions: {
                type: CustomQuestionsForm,
                options: {
                    questions: formValue.customQuestions,
                },
            },
        }),
        [defaultFieldsEnabledSet, formSettings, eventRegistration, formValue.customQuestions]
    );

    const detailsSchema = useMemo(
        () => [
            {
                key: 'name',
                fields: ['firstName', 'lastName'],
            },
            {
                key: 'basic',
                fields: eventRegistration ? ['email'] : ['phone', 'email'],
            },
            ...(defaultFieldsEnabledSet.has(DEFAULT_QUESTION_TO_ID.DIETARY_RESTRICTIONS)
                ? [
                      {
                          key: 'dietary',
                          fields: ['dietary'],
                      },
                  ]
                : []),
            ...(defaultFieldsEnabledSet.has(DEFAULT_QUESTION_TO_ID.ATTENDANCE_TYPE)
                ? [
                      {
                          key: 'attendanceType',
                          fields: ['attendanceType'],
                      },
                  ]
                : []),
        ],
        [defaultFieldsEnabledSet, eventRegistration]
    );

    const onChange = ({ value: { dietary, ...newValue }, errors: newErrors, field }) => {
        onChangeProp?.({
            value: {
                ...newValue,
                ...dietary,
            },
            errors: newErrors,
            field,
        });
    };

    const detailsFormProps = { fields: detailsFields, schema: detailsSchema, value: formValue, errors: {}, onChange };

    const onRoomBlockFormChange = values => {
        if (typeof onChangeProp === 'function') {
            onChangeProp(values);
        }
    };

    const onCustomQuestionChange = ({ value, errors: newErrors, field }) => {
        const customQuestionResponses = value.map(({ id, response }) => ({ id, response }));
        onChangeProp?.({
            value: {
                ...attendee,
                customQuestionResponses,
            },
            errors: newErrors,
            field,
        });
    };

    return (
        <Column>
            <Line />
            <Spacer />
            <Column>
                <H2Headline>{i18n.communication.yourDetails}</H2Headline>
                <Spacer largest />
                <Form {...detailsFormProps} />
            </Column>
            {!!formValue.roomBlockEnabled && (
                <>
                    <Line />
                    <Spacer />
                    <Column>
                        <H2Headline>{i18n.communication.accommodationRequirements}</H2Headline>
                        <Spacer xsmall />
                        <Copy small>{i18n.communication.accommodationDescription}</Copy>
                        <Spacer />
                        <Form
                            fields={roomBlockFields(
                                attendee.checkInDate && attendee.checkOutDate,
                                parcelDates,
                                typeof onChangeProp !== 'function'
                            )}
                            schema={roomBlockSchema}
                            value={attendee}
                            onChange={onRoomBlockFormChange}
                            disabled={isPastDue}
                        />
                        <Spacer />
                        {isPastDue ? (
                            <ExpiredInstruction>
                                <ExpiredTitle>{i18n.communication.expired}</ExpiredTitle>
                                <ExpiredHelperCopy>
                                    {eventRegistration
                                        ? i18n.communication.contactSenderOnExpiration(dueDateString)
                                        : i18n.communication.contactEmailOnExpiration(dueDateString, formValue.email)}
                                </ExpiredHelperCopy>
                            </ExpiredInstruction>
                        ) : (
                            <ExpiredInstruction>
                                <ExpiredTitle>{i18n.communication.expiration}</ExpiredTitle>
                                <ExpiredHelperCopy>
                                    {i18n.communication.expirationCopy(dueDateString)}
                                </ExpiredHelperCopy>
                            </ExpiredInstruction>
                        )}
                    </Column>
                </>
            )}
            {!!formValue.customQuestions?.length && (
                <>
                    <Line />
                    <Spacer />
                    <Column>
                        <H2Headline>{i18n.communication.additionalInformation}</H2Headline>
                        <Spacer />
                        <CustomQuestionsForm
                            questions={formValue.customQuestions}
                            value={formValue.customQuestionResponses}
                            onChange={onCustomQuestionChange}
                            field={'customQuestions'}
                        />
                    </Column>
                </>
            )}
        </Column>
    );
}
