import { appendRedirect, disconnect, getAuthUrl, postCallback } from 'api/connectors';
import createStore from '../';

type State = {
    isAuthLoading: boolean;
    isCallbackLoading: boolean;
    isDisconnecting: boolean;
};
type Store = State;

const initialState: State = {
    isAuthLoading: false,
    isCallbackLoading: false,
    isDisconnecting: false,
};

export const useIntegrations = createStore<Store>(() => initialState);

const { setState, getState } = useIntegrations;

export const integrationsActions = {
    auth: async (connectorType: BizlyAPI.Connector, redirect?: string) => {
        if (getState().isAuthLoading) {
            return;
        }

        setState({ isAuthLoading: true });

        const { data } = await getAuthUrl(connectorType);

        if (data) {
            const appendedLink = appendRedirect(data, redirect);
            window.location.href = appendedLink;
        } else {
            throw new Error('Unable to connect, please try again later.');
        }
    },

    callback: async (connectorType: BizlyAPI.Connector, code: string) => {
        if (getState().isCallbackLoading) {
            return;
        }

        setState({ isCallbackLoading: true });
        try {
            await postCallback(connectorType, code);

            return true;
        } catch (e) {
            console.error('integration error: ', e);
            throw e;
        } finally {
            setState({ isCallbackLoading: false });
        }
    },

    disconnect: async (connector: BizlyAPI.Connector) => {
        if (getState().isDisconnecting) {
            return;
        }

        setState({ isDisconnecting: true });

        try {
            await disconnect(connector);

            return true;
        } catch (e) {
            console.error('disconnect error: ', e);
            throw e;
        } finally {
            setState({ isDisconnecting: false });
        }
    },
};
