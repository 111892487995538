import { Dialog } from '@material-ui/core';
import Form from 'components/Form';
import Button from 'components/ui/Button';
import TextButton from 'components/ui/Button/TextButton';
import { addDays, isBefore } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { Column, Row } from 'ui';
import { API_TIME_FORMAT as FORM_TIME_FORMAT, datetimeStrToLocalDate, timeOptions } from 'utils/date_util';
import { tzMoment } from 'utils/moment';

const fields = (start?: string, end?: string) => ({
    name: {
        type: 'text',
        prompt: 'Title',
        options: {
            placeholder: 'Enter title',
            autoFocus: true,
        },
    },
    startTime: {
        prompt: 'Start Time',
        type: 'select',
        options: {
            options: timeOptions.filter(timeOption => timeOption.value !== end),
        },
    },
    endTime: {
        prompt: 'End Time',
        type: 'select',
        options: {
            options: timeOptions.filter(timeOption => timeOption.value !== start),
        },
    },
});

const schema = [
    {
        fields: ['name'],
        spacing: 'default',
    },
    {
        fields: ['startTime', 'endTime'],
    },
];

type TBaseBlock = {
    name?: string;
    start: Date;
    end: Date;
};

const blockToForm = <TBlock extends TBaseBlock>(block: TBlock) => ({
    ...block,
    date: tzMoment(block.start).format('L'),
    startTime: tzMoment(block.start).format(FORM_TIME_FORMAT),
    endTime: tzMoment(block.end).format(FORM_TIME_FORMAT),
});

type TBaseForm = {
    date: string;
    startTime: string;
    endTime: string;
};

const formToBlock = <TForm extends TBaseForm>(form: TForm) => {
    const start = datetimeStrToLocalDate(form.date, form.startTime);
    const end = datetimeStrToLocalDate(form.date, form.endTime);

    return {
        ...form,
        start,
        end: isBefore(end, start) ? addDays(end, 1) : end,
    };
};

const Modal = styled(Column)`
    padding: 36px;
    width: 360px;
`;

const DialogVisibleBackground = styled(Dialog)`
    .MuiDialog-container {
        align-items: flex-start;
    }

    .MuiBackdrop-root {
        opacity: 0 !important;
    }
`;

type TAddBlockModal<TBlock extends TBaseBlock> = {
    block?: TBlock;
    onChange: (newValue: TBlock) => void;
    onAdd: () => void;
    onMoreOptions?: () => void;
    onClose: () => void;
};

export default function AddBlockModal<TBlock extends TBaseBlock>({
    block,
    onChange,
    onAdd,
    onMoreOptions,
    onClose,
}: TAddBlockModal<TBlock>) {
    const formValue = React.useMemo(() => (block ? blockToForm(block) : undefined), [block]);

    const formFields = React.useMemo(() => fields(formValue?.startTime, formValue?.endTime), [formValue]);

    return formValue ? (
        <DialogVisibleBackground open onClose={onClose}>
            <Modal>
                <Form
                    fields={formFields}
                    schema={schema}
                    value={formValue}
                    onChange={({ value }: { value: typeof formValue }) => {
                        onChange(formToBlock(value));
                    }}
                />
                <Row alignItems="center" justifyContent="space-between">
                    {onMoreOptions ? <TextButton onClick={onMoreOptions}>More Options</TextButton> : <span />}
                    <Button onClick={onAdd}>Add</Button>
                </Row>
            </Modal>
        </DialogVisibleBackground>
    ) : null;
}
