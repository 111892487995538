import Button from 'components/ui/Button';
import { IntercomAPI } from 'react-intercom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Copy, Row, Spacer } from 'ui';

const AutoButton = styled(Button)`
    margin-right: auto;
`;

const ConnectorsCol = styled(Column)`
    margin-left: 16px;
`;

export const TIERS = {
    FREE: 'free',
    SMALL_BUSINESS: 'corporate',
    ENTERPRISE: 'enterprise',
    RESTRICTED: 'restricted',
} as const;

const Benefits = {
    [TIERS.SMALL_BUSINESS]: [i18n.subscription.proPerk1, i18n.subscription.proPerk2, i18n.subscription.proPerk3],
    [TIERS.ENTERPRISE]: [
        i18n.subscription.enterprisePerk1,
        i18n.subscription.enterprisePerk2,
        i18n.subscription.enterprisePerk3,
    ],
    [TIERS.RESTRICTED]: [],
};

const NEXT_TIER = {
    free: 'corporate',
    corporate: 'enterprise',
    enterprise: 'restricted',
    restricted: undefined,
} as const;

export const getNextTier = (tier: BizlyAPI.TeamTier, includeAllTiers?: boolean) => {
    if (includeAllTiers) {
        return Object.values(NEXT_TIER).slice(Object.keys(NEXT_TIER).indexOf(tier));
    }
    return [NEXT_TIER[tier]];
};

export default function SubscriptionFeatures({
    tiers,
    benefits = {},
}: {
    tiers: ReturnType<typeof getNextTier>;
    benefits?: Partial<Record<BizlyAPI.TeamTier, string[]>>;
}) {
    const showTier = {
        [TIERS.SMALL_BUSINESS]: tiers.includes(TIERS.SMALL_BUSINESS),
        [TIERS.ENTERPRISE]: tiers.includes(TIERS.ENTERPRISE),
        [TIERS.RESTRICTED]: tiers.includes(TIERS.RESTRICTED),
    };

    return (
        <Column itemSpacing="small">
            <Copy large>
                {showTier[TIERS.RESTRICTED] ? i18n.subscription.noAccess : i18n.subscription.upgradeAccount}
            </Copy>
            <ConnectorsCol itemSpacing="larger">
                {showTier[TIERS.SMALL_BUSINESS] && (
                    <Column itemSpacing="small">
                        <ul>
                            {(benefits[TIERS.SMALL_BUSINESS] ?? Benefits[TIERS.SMALL_BUSINESS]).map(point => (
                                <li key="item">{point}</li>
                            ))}
                        </ul>
                        <Spacer />
                        <Row itemSpacing="larger" alignItems="center">
                            <AutoButton onClick={() => IntercomAPI('show')} width="auto">
                                {i18n.subscription.upgradeToPro}
                            </AutoButton>
                        </Row>
                    </Column>
                )}
                {showTier[TIERS.ENTERPRISE] && (
                    <Column itemSpacing="small">
                        <ul>
                            {(benefits[TIERS.ENTERPRISE] ?? Benefits[TIERS.ENTERPRISE]).map(point => (
                                <li key="item">{point}</li>
                            ))}
                        </ul>
                        <Spacer />
                        <Row itemSpacing="larger" alignItems="center">
                            <AutoButton onClick={() => IntercomAPI('show')} width="auto">
                                {i18n.subscription.upgradeToEnterprise}
                            </AutoButton>
                        </Row>
                    </Column>
                )}
            </ConnectorsCol>
        </Column>
    );
}
