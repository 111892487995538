import styled from 'styled-components';
import DinovaLogo from '../../images/icons/dinova-logo.png';
import { Column, Row } from '../../ui';
import VenuePill, { TPillTypes } from './VenuePill';
import { TTileFormats } from './VenueTile';

const FloatingRow = styled(Row)`
    z-index: 5;
`;

const InfoColumn = styled(Column)<{ format?: TTileFormats }>`
    flex: 1 0 0;
    overflow: hidden;
    gap: 5px;
    ${({ format }) => format === 'list' && `padding: 8px 0 0 8px;`}
`;

export function tileTextPropsByFormat(
    venue: Bizly.Venue,
    format?: TTileFormats
): Partial<{
    title: string;
    subtext: string;
    caps: string;
    superCaps: boolean;
    subtextRight: boolean;
    subtextUnderCaps: boolean;
}> {
    const { name, description, headline, address, qualityLevel, primaryDecor, type, neighborhood } = venue;

    const decor = primaryDecor ? primaryDecor.name + ' ' : '';
    const typeName = type ? type.name + ' ' : '';

    const fullBasics =
        headline ||
        `A ${[
            ...(decor ? [decor] : []),
            ...(qualityLevel ? [qualityLevel] : []),
            ...(typeName ? [typeName] : []),
            ...(neighborhood ? ['in ', neighborhood] : []),
        ].join(' ')}`;

    const shortBasics = `${qualityLevel ? qualityLevel + ' ' : ''}${typeName}`;
    const base = { title: name };

    switch (format) {
        case 'tile':
            return {
                ...base,
                subtext: neighborhood,
                caps: shortBasics,
            };
        case 'thumb':
            return {
                ...base,
                caps: shortBasics,
            };
        case 'proposalThumb':
            return {
                ...base,
                subtext: address,
                caps: fullBasics,
                subtextUnderCaps: true,
            };
        case 'proposal':
            return {
                ...base,
                subtext: address,
                caps: fullBasics,
                superCaps: true,
                subtextRight: true,
            };
        case 'featured':
            return {
                ...base,
                subtext: description,
                caps: fullBasics,
                superCaps: true,
            };
        case 'default':
        default:
            return {
                ...base,
                caps: fullBasics,
            };
    }
}

const DinovoImageContainer = styled.div`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-radius: 5;
`;

type TTileInfoProps = {
    venue: Bizly.Venue;
    format?: TTileFormats;
    pillType?: TPillTypes;
};

export default function TileInfo({ venue, format, pillType }: TTileInfoProps) {
    return (
        <InfoColumn format={format}>
            {pillType === 'inquiryStatus' && (
                <FloatingRow>
                    <Column>
                        <VenuePill
                            venue={venue}
                            pillType="inquiryStatus"
                            {...(format === 'tile' || format === 'list' ? { size: 'small' } : {})}
                        />
                    </Column>
                </FloatingRow>
            )}

            {venue.tempClosed && (
                <FloatingRow>
                    <Column>
                        <VenuePill
                            venue={venue}
                            pillType={'temporarilyClosed'}
                            {...(format === 'tile' || format === 'list' ? { size: 'small' } : {})}
                        />
                    </Column>
                </FloatingRow>
            )}

            {venue.dinova && (
                <FloatingRow>
                    <DinovoImageContainer>
                        <img src={DinovaLogo} style={{ height: 10 }} alt="dinova-logo" />
                    </DinovoImageContainer>
                </FloatingRow>
            )}

            {pillType === 'preferenceCategory' && (
                <FloatingRow>
                    <VenuePill
                        venue={venue}
                        pillType="preferenceCategory"
                        {...(format === 'tile' || format === 'list' ? { size: 'small' } : {})}
                    />
                </FloatingRow>
            )}
        </InfoColumn>
    );
}
