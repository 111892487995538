import colorFns from 'colorFns';
import React, { forwardRef } from 'react';
import { withIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import DocumentIconSVG from '../../images/icons/document.svg?react';
import { RichTextCopy, Spacer } from '../../ui';
import pluckFileName from '../../utils/pluckFileName';
import Button from '../ui/Button';
import StepsRow from './StepsRow';

const DocumentIcon = withIconStyles(DocumentIconSVG);

const Copy = styled(RichTextCopy)`
    font-size: 15px;
    line-height: 1.47;
`;

const MSADocument = styled('div')`
    font-size: 15px;
    letter-spacing: -0.1px;
    color: ${colorFns.highlightedText};

    display: flex;
    align-items: center;

    svg,
    label {
        cursor: auto;
    }

    & > * {
        cursor: pointer;
    }
`;

const WarningCopy = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.warningText)};
`;

type VenueDocsProps = {
    number: number;
    venue: Bizly.VenueProposal;
    copy: string;
    altCopy: string;
    redText: string;
};

const defaultCopy = i18n.venue.proposal.defaultVenueDocCopy;
const defaultAltCopy = i18n.venue.proposal.defaultVenueDocAltCopy;

const VenueDocs = forwardRef(
    (
        { number, venue, copy = defaultCopy, altCopy = defaultAltCopy, redText }: VenueDocsProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const { msaDocumentUrl } = venue;
        const hasMSADocument = !!msaDocumentUrl;
        return (
            <StepsRow number={number} headline={i18n.venue.proposal.contracting} ref={ref}>
                <Copy dangerouslySetInnerHTML={{ __html: hasMSADocument ? copy : altCopy }} />
                <Spacer />
                {hasMSADocument && (
                    <>
                        <MSADocument>
                            <DocumentIcon />
                            <label>{pluckFileName(msaDocumentUrl)}</label>
                        </MSADocument>
                        <Spacer />
                        <Button width={119} onClick={() => window.open(msaDocumentUrl, '_blank')}>
                            {i18n.button.download}
                        </Button>
                    </>
                )}
                {redText && (
                    <>
                        <Spacer />
                        <WarningCopy dangerouslySetInnerHTML={{ __html: redText }} />
                    </>
                )}
            </StepsRow>
        );
    }
);

export default VenueDocs;
