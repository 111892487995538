import Box from '@mui/material/Box';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { Moment } from 'moment';
import { EFonts, getFontStyle } from 'theme';
import { i18n } from 'translation';
import { EventCreateForm } from '../formSchema';
import { Description, FieldTitle, StepContainer, Title } from './styled';

type PropType = {
    value: EventCreateForm['eventDate'];
    onChange: (value: EventCreateForm['eventDate']) => void;
};

const Step4 = ({ value, onChange }: PropType) => {
    const onStartDateChange = (newValue: Moment | null) => {
        onChange({
            ...value,
            start: newValue,
        });
    };

    const onEndDateChange = (newValue: Moment | null) => {
        onChange({
            ...value,
            end: newValue,
        });
    };

    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.dateTitle}</Title>
            <Description>{i18n.homepage.createMeetingModal.youCanEditThisLater}</Description>
            <Spacer />
            <Box display="flex" gap={3}>
                <Box>
                    <FieldTitle thin>Start date and time</FieldTitle>
                    <DateTimePicker
                        value={value?.start}
                        onChange={onStartDateChange}
                        disablePast
                        slotProps={{
                            textField: {
                                InputProps: {
                                    style: { ...getFontStyle(EFonts.body) },
                                },
                            },
                        }}
                    />
                </Box>
                <Box>
                    <FieldTitle thin>End date and time</FieldTitle>
                    <DateTimePicker
                        value={value?.end}
                        onChange={onEndDateChange}
                        shouldDisableDate={day => day.isBefore(value?.start)}
                        slotProps={{
                            textField: {
                                InputProps: {
                                    style: { ...getFontStyle(EFonts.body) },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
        </StepContainer>
    );
};

export default Step4;
