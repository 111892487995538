import debounce from 'awesome-debounce-promise';
import maxBy from 'lodash/maxBy';
import { deleteCall, get, post, put } from '.';

export const getPlaybook = (id: number | string) =>
    get(`playbooks/${id}`) as Promise<{ playbook: BizlyAPI.Complete.Playbook }>;

export const createPlaybook = (data: BizlyAPI.Complete.PlaybookCreate) =>
    post(`playbooks/`, data) as Promise<{ playbook: BizlyAPI.Complete.Playbook }>;

export const getPlaybookOptions = () => get(`playbooks/options`) as Promise<BizlyAPI.Complete.PlaybookOptions>;

// todo: need to triple-check this
export const updatePlaybook = (id: number | string, data: BizlyAPI.Complete.PlaybookUpdate) =>
    put(`playbooks/${id}`, data) as Promise<{ playbook: BizlyAPI.Complete.Playbook }>;

export const getPlaybooks = () => get(`playbooks?limit=1000`) as Promise<{ playbooks: BizlyAPI.Complete.Playbook[] }>;

export const getPlaybookParcels = (playbookId: number | string) =>
    get(`playbooks/${playbookId}/parcels`) as Promise<{ parcels: BizlyAPI.BasicParcel[] }>;

export const createPlaybookParcel = (id: number | string, parcel: Bizly.Parcel) =>
    post(`playbooks/${id}/parcels`, parcel);

export const updatePlaybookParcel = (id: number | string, parcel: Bizly.Parcel) =>
    put(`playbooks/${id}/parcels/${parcel.id}`, parcel);

export const deletePlaybookParcel = (playbookId: number | string, parcel: Bizly.Parcel) =>
    deleteCall(`playbooks/${playbookId}/parcels/${parcel.id}`);

export const getPlaybookCollaborators = (playbookId: number | string) =>
    get(`playbooks/${playbookId}/collaborators`) as Promise<{ collaborators: Bizly.EventCollaborator[] }>;

export const searchPlaybookCollaborators = debounce(
    (query: string, playbookId?: number | string) =>
        get(`collaborators/suggested?query=${query}${playbookId ? `&playbook_id=${playbookId}` : ''}`) as Promise<{
            suggestions: BizlyAPI.EventCollaboratorSuggestion[];
        }>,
    200
);

export const addPlaybookCollaborators = (
    collaboratorsArr: BizlyAPI.NewEventCollaborator[],
    playbookId: number | string
) => post(`playbooks/${playbookId}/collaborators/batch`, { collaborators: collaboratorsArr }, { showErrorCode: true });

export const updatePlaybookCollaborator = (
    playbookId: number | string,
    collaboratorId: number | string,
    editor: boolean
) => put(`playbooks/${playbookId}/collaborators/${collaboratorId}`, { editor }, { showErrorCode: true });

export const removePlaybookCollaborator = (playbookId: number | string, collaboratorId: number | string) =>
    deleteCall(`playbooks/${playbookId}/collaborators/${collaboratorId}`);

export const getPlaybookResources = (id: number | string) =>
    get(`playbooks/${id}/resources`) as Promise<{ resources: Bizly.EventResource[] }>;

export const addPlaybookResource = (id: number | string, document: Bizly.EventResource) =>
    post(`playbooks/${id}/resources`, document) as Promise<{ resource: Bizly.EventResource }>;

export const deletePlaybookResource = (playbookId: number | string, resourceId: number | string) =>
    deleteCall(`playbooks/${playbookId}/resources/${resourceId}`);

export const getInquiriesForPlaybook = (playbookId: number | string) =>
    get(`playbooks/${playbookId}/inquiries`) as Promise<{ inquiries: BizlyAPI.Inquiry[] }>;

export const getCurrentInquiry = async (playbookId: number | string) => {
    const { inquiries: existingInquiries } = await getInquiriesForPlaybook(playbookId);
    return maxBy(existingInquiries, 'id') ?? null;
};

export const createInquiryDraft = (playbookId: number | string) =>
    post(`playbooks/${playbookId}/inquiries`) as Promise<{ inquiry: BizlyAPI.Inquiry }>;

export const updateBookingInquiry = (
    inquiryId: number,
    data: Partial<Pick<BizlyAPI.Inquiry, 'notes' | 'flexibleDates' | 'scheduleItemIds'>>
) => put(`booking-inquiries/${inquiryId}`, data) as Promise<{ inquiry: BizlyAPI.Inquiry }>;
