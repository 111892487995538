import { VenueStatus } from 'constants/venueStatus';
import { useMemo } from 'react';
import styled from 'styled-components';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { OnlySingleLine } from '../../shared';
import { BookedState, bookedStateToBackgroundColor } from '../VenueCard';

export const DEFAULT_PREFERENCE_COLOR = EColors.preferredVenue;

export const ERankColors = {
    r10: EColors.preferredRank10,
    r20: EColors.preferredRank20,
    r30: EColors.preferredRank30,
    r40: EColors.preferredRank40,
    r50: EColors.preferredRank50,
};

export type TPillTypes = 'preferenceCategory' | 'inquiryStatus';

function getPillProps(venue: Bizly.Venue, pillType?: TPillTypes | 'temporarilyClosed') {
    if (pillType === 'temporarilyClosed') {
        return {
            label: i18n.venue.temporarilyClosed,
            color: EColors.cancelledOrRejectedStatus,
        };
    }

    if (pillType === 'preferenceCategory') {
        const { preferred, preferenceCategory } = venue;
        if (preferenceCategory) {
            return {
                label: preferenceCategory.name,
                color:
                    ERankColors[('r' + preferenceCategory.rank) as keyof typeof ERankColors] ||
                    DEFAULT_PREFERENCE_COLOR,
            };
        }
        if (preferred) {
            return {
                label: i18n.venue.preferred,
                color: DEFAULT_PREFERENCE_COLOR,
            };
        }
    }

    if (pillType === 'inquiryStatus') {
        const { status } = venue;
        return {
            label: status,
            color: bookedStateToBackgroundColor[status as BookedState],
        };
    }

    return {};
}

const Pill = styled.div<{ size: string; backgroundColor?: EColors }>`
    ${({ size }) => `
        font-size: ${size === 'small' ? 9 : 11}px;
        max-height: ${size === 'small' ? 9 : 11}px;
        padding: ${size === 'small' ? '4px 8px' : '4px 12px'};
    `}

    font-weight: 500;
    line-height: 1.18;
    text-transform: uppercase;

    ${OnlySingleLine};

    color: ${({ color, theme: { getColor, EColors } }) => color || getColor(EColors.pureWhite)};
    background-color: ${({ backgroundColor, theme: { getColor } }) =>
        getColor(backgroundColor || DEFAULT_PREFERENCE_COLOR)};
    border-radius: 100px;
    box-shadow: 0 2px 10px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.3)};
`;

type TPillProps = {
    venue: Bizly.Venue;
    pillType?: TPillTypes | 'temporarilyClosed';
    size?: 'default' | 'small';
};
export default function VenuePill({ venue, pillType, size = 'default' }: TPillProps) {
    const { label, color } = useMemo(() => {
        if (!venue) return {};

        if (venue.status === VenueStatus.Added) {
            return {
                label: 'Added',
                color: EColors.preferredVenue,
            };
        }

        return getPillProps(venue, pillType);
    }, [venue, pillType]);

    if (!label && !color) {
        return null;
    }

    return (
        <Pill size={size} backgroundColor={color}>
            {label}
        </Pill>
    );
}
