import colorFns from 'colorFns';
import pick from 'lodash/pick';
import React from 'react';
import styled, { css } from 'styled-components';
import { Row } from 'ui';

const TRANSPARENT = 'transparent';

const COLORS = {
    1: '#ffffff',
    2: '#ff3642',
    3: '#1f3fff',
    4: '#6236a3',
    5: '#ff7c03',
    6: '#04c6bc',
} as const;
type TCOLORS = typeof COLORS;
type TCOLOR_KEY = keyof TCOLORS;

const ALLOWED_COLORS_LIST: Readonly<Partial<TCOLOR_KEY>[]> = [1, 2, 3, 4, 5, 6];
const ALLOWED_COLORS = pick(COLORS, ALLOWED_COLORS_LIST);

export const getColor = <TColor extends TCOLOR_KEY | number | null>(color: TColor) =>
    (color ? COLORS[color as TCOLOR_KEY] ?? TRANSPARENT : TRANSPARENT) as TColor extends TCOLOR_KEY
        ? TCOLORS[TColor]
        : TCOLORS[TCOLOR_KEY] | typeof TRANSPARENT;

const ColorsRow = styled(Row)`
    min-height: 40px; /* match form fields */
`;

const ColorBubbleColor = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    border: 1px solid ${colorFns.softBorder};
`;

const transparentSlash = css`
    transform: rotate(45deg);

    &::after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        margin-left: calc(50% - 1px);
        background: ${colorFns.softBorder};
    }
`;

const ColorBubble = styled.div<{ selected?: boolean; fill: string; disabled?: boolean }>`
    width: 28px;
    height: 28px;
    padding: 1px;
    box-sizing: border-box;

    border-radius: 28px;
    border: 1px solid ${props => (props.selected ? colorFns.primaryAction(props) : 'transparent')};

    > ${ColorBubbleColor} {
        border-radius: 26px;

        background: ${({ fill }) => fill};

        ${({ fill }) => fill === TRANSPARENT && transparentSlash}
    }

    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

type TColorPickerValue = null | number;

type TColorPicker = {
    field: string;
    value?: TColorPickerValue;
    onChange: (update: { field?: string; value: TColorPickerValue }) => void;

    readonly?: boolean;
    disabled?: boolean;
};

export default function ColorPicker({ field, value, onChange, readonly, disabled }: TColorPicker) {
    const selectedColor = getColor(value ?? null);

    return (
        <ColorsRow itemSpacing="small" alignItems="center">
            {readonly ? (
                <ColorBubble fill={selectedColor} selected disabled>
                    <ColorBubbleColor />
                </ColorBubble>
            ) : (
                <>
                    <ColorBubble
                        fill={TRANSPARENT}
                        selected={selectedColor === TRANSPARENT}
                        onClick={disabled ? undefined : () => onChange({ field, value: null })}
                        disabled={disabled}
                    >
                        <ColorBubbleColor />
                    </ColorBubble>
                    {Object.entries(ALLOWED_COLORS).map(([id, color]) => (
                        <ColorBubble
                            key={id}
                            fill={color}
                            selected={selectedColor === color}
                            onClick={disabled ? undefined : () => onChange({ field, value: parseInt(id) })}
                            disabled={disabled}
                        >
                            <ColorBubbleColor />
                        </ColorBubble>
                    ))}
                </>
            )}
            {disabled && typeof value === 'number' && !(ALLOWED_COLORS_LIST as number[]).includes(value) && (
                <ColorBubble fill={getColor(value)} selected disabled>
                    <ColorBubbleColor />
                </ColorBubble>
            )}
        </ColorsRow>
    );
}
