import _TrashIcon from 'images/icons/trash-can.svg?react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { i18n } from 'translation';
import Button from './ui/Button';

const UploaderContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const CsvFileUploadField = styled.input`
    display: none;
`;

const CsvFileInputLabel = styled.label`
    cursor: pointer;
    margin-right: 10px;
`;

const FileButton = styled(Button)`
    min-width: 120px;
    padding: 8px 12px;
`;

const TrashIcon = withInteractibleIconStyles(_TrashIcon);

export const CsvFileUploader = ({ className, disabled, onChange }) => {
    const [fileName, setFileName] = useState('');
    const fileInputRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleFileChange = event => {
        const csvFile = event.target.files && event.target.files[0];

        if (csvFile) {
            const isCSVFile = csvFile.name.endsWith('.csv');

            if (isCSVFile) {
                setFileName(csvFile.name);
                onChange(csvFile);
            } else {
                return enqueueSnackbar(i18n.teamSettings.error.invalidFile, {
                    variant: 'error',
                });
            }
        }
    };

    const handleUploadCsvClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDeleteClick = () => {
        setFileName('');
        onChange(null);
        fileInputRef.current && (fileInputRef.current.value = null);
    };

    return (
        <UploaderContainer className={className}>
            <CsvFileInputLabel onClick={handleUploadCsvClick}>
                <CsvFileUploadField type="file" onChange={handleFileChange} ref={fileInputRef} disabled={disabled} />
                <FileButton disabled={disabled}>{fileName ? i18n.button.replaceCsv : i18n.button.uploadCsv}</FileButton>
            </CsvFileInputLabel>
            {fileName && (
                <>
                    <TrashIcon onClick={handleDeleteClick} />
                    <CsvFileInputLabel>{fileName}</CsvFileInputLabel>
                </>
            )}
        </UploaderContainer>
    );
};
