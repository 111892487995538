import colorFns from 'colorFns';
import { RichTextEditor } from 'components/Form/fields';
import RSVPForm from 'components/RSVP/RSVPForm/RSVPForm';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { i18n } from 'translation';
import _VideoConferenceIcon from '../../images/icons/Video_Conference.svg?react';
import Bizmark from '../../images/icons/bizmark.svg?react';
import _CalendarIcon from '../../images/icons/calendar.svg?react';
import _VenueIcon from '../../images/icons/venue_pin.svg?react';
import { Button, Column, Copy, ExternalLink, Row, Spacer } from '../../ui';
import { RSVPDetailSection } from './RSVPDetailSection';

const VenueIcon = styled(_VenueIcon)`
    color: ${colorFns.primaryDisplayIcon.themeOverride('bizly')};
`;

const CalendarIcon = styled(_CalendarIcon)`
    color: ${colorFns.primaryDisplayIcon.themeOverride('bizly')};
`;

const VideoConferenceIcon = styled(_VideoConferenceIcon)`
    margin-top: 5px;
    margin-right: 6px;
`;

const PreWrapText = styled(Copy)`
    white-space: pre-wrap;
`;

const StyledHTML = styled.div`
    a {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        text-decoration: underline;
    }
`;

const formatWithTimezone = (date: string, format: string, timezone?: string, tzFormat?: string) =>
    timezone ? moment.tz(date, timezone).format(tzFormat ?? format) : moment(date).format(format);

const renderParcelDateTimes = (
    {
        startDate,
        startTime,
        endDate,
        endTime,
    }: {
        startDate: string;
        startTime: string;
        endDate: string;
        endTime: string;
    },
    timezone?: string
) => [
    `${formatWithTimezone(startDate + ' ' + startTime, `MMM Do, yyyy, h:mma${String.fromCharCode(160)}-`, timezone)}`,
    `${formatWithTimezone(endDate + ' ' + endTime, 'MMM Do, yyyy, h:mma', timezone, 'MMM Do, yyyy, h:mma z')}`,
];

const renderPlannerScheduleDateTimes = ({
    date,
    startTime,
    endTime,
    timezone,
}: BizlyAPI.ScheduleBlock & Partial<{ timezone?: string }>) => {
    return [
        date && formatWithTimezone(date + ' ' + startTime, 'ddd, MMM Do, yyyy', timezone),
        startTime &&
            endTime &&
            `${formatWithTimezone(`${date} ${startTime}`, 'h:mma', timezone)} - 
        ${formatWithTimezone(`${date} ${endTime}`, 'h:mma', timezone, 'h:mma z')}`,
    ].filter(str => str);
};

const renderScheduleDateTimes = (start?: string, end?: string, timezone?: string) => {
    return [
        start && formatWithTimezone(start, 'ddd, ll', timezone),
        start &&
            end &&
            `${formatWithTimezone(start, 'LT', timezone)} - ${formatWithTimezone(end, 'LT', timezone, 'LT z')}`,
    ].filter(str => str);
};

export const RSVPCardContent = ({
    color,
    description,
    startsAt,
    endsAt,
    schedule = [],
    plannerSchedule,
    parcelDates,
    useParcelDates,
    timezone,
    location,
    address,
    cityState,
    vmSharingMethod,
    virtualMeeting,
    submitted,
    isNote,
    hasQuestions,
    isPreview,
    attendee,
    formSettings,
}: any) => {
    const parcelDatesText = parcelDates && renderParcelDateTimes(parcelDates, timezone);
    const scheduleDetailsText = plannerSchedule
        ? plannerSchedule.map(renderPlannerScheduleDateTimes).flat()
        : schedule.length
          ? schedule
                .map(({ start, end }: { start: string; end: string }) => renderScheduleDateTimes(start, end, timezone))
                .flat()
          : renderScheduleDateTimes(startsAt, endsAt, timezone);

    const vmServiceName = virtualMeeting?.serviceProvider.name;
    const unknownVMService = vmServiceName === 'Other';
    const virtualMeetingDetailsText = i18n.communication.virtualMeetingDetailsText(
        !unknownVMService ? vmServiceName : ''
    );

    const shareVMDetails = virtualMeeting && vmSharingMethod === 'share';
    const showVMLink = !isPreview && !isNote && submitted && shareVMDetails && virtualMeeting.link;

    const renderedDescription =
        description && RichTextEditor({ field: '', value: description, onChange: () => {}, readonly: true });

    return (
        <Column style={{ margin: 0 }} itemSpacing="largest" paddingSpacing>
            <Column itemSpacing="large">
                {description && (
                    <Column itemSpacing="small">
                        {renderedDescription && <StyledHTML>{renderedDescription}</StyledHTML>}
                    </Column>
                )}

                {!isNote && (
                    <Row>
                        {parcelDatesText ? (
                            <RSVPDetailSection icon={<CalendarIcon />} textArray={parcelDatesText} />
                        ) : (
                            !useParcelDates &&
                            scheduleDetailsText.length > 0 && (
                                <RSVPDetailSection icon={<CalendarIcon />} textArray={scheduleDetailsText} />
                            )
                        )}

                        {location && (
                            <RSVPDetailSection icon={<VenueIcon />} textArray={[location, address, cityState]} />
                        )}

                        {shareVMDetails && (
                            <RSVPDetailSection icon={<VideoConferenceIcon />} text={virtualMeetingDetailsText} />
                        )}
                    </Row>
                )}

                {showVMLink && (
                    <Column>
                        <Copy>{i18n.communication.clickVMLink}</Copy>
                        <Spacer small />
                        <ExternalLink href={virtualMeeting.link} openInNewTab>
                            {virtualMeeting.link}
                        </ExternalLink>
                        {virtualMeeting.notes && (
                            <PreWrapText>
                                <Spacer small />
                                <RichTextEditor
                                    readonly
                                    field="virtualMeetingNotes"
                                    value={virtualMeeting.notes}
                                    onChange={() => null}
                                />
                            </PreWrapText>
                        )}
                    </Column>
                )}
            </Column>

            {isPreview && (
                <RSVPForm
                    attendee={attendee}
                    formSettings={formSettings}
                    onChange={null}
                    eventRegistration={false}
                    timezone={timezone}
                    parcelDates={null}
                />
            )}

            {isPreview && (
                <Row>
                    {isNote && hasQuestions && (
                        <Button style={{ backgroundColor: color, marginRight: 20 }}>
                            {i18n.communication.clickToRespond}
                        </Button>
                    )}
                    {!isNote && (
                        <Button
                            style={{
                                backgroundColor: color,
                            }}
                        >
                            {i18n.button.submit}
                        </Button>
                    )}
                    <Bizmark style={{ marginLeft: 'auto' }} />
                </Row>
            )}
        </Column>
    );
};
