import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React from 'react';
import { i18n } from 'translation';
import { meetingsActions } from './store';

export default function LoadMeetings({ date, limit }: { date?: Date; limit?: number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            meetingsActions.load(date ? moment(date).startOf('week').toDate() : undefined, limit);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        }
    }, [date, limit, enqueueSnackbar]);

    return null;
}
